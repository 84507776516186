<template>
    <editor
        :is="editorType"
        ref="editor"
        :options="options"
        :propValue="propValue"
        :dataId="dataId"
        :class="classList"
        :rowIndex="rowIndex"
        :propReadonly="readonly"
        @change="changeHandle"
    ></editor>
</template>

<script>
import selectEditor from '@/gikam/js/components/template/fields/select/select';
import SelectSource from '@/gikam/js/core/selectSource';
import { mapState } from 'vuex';
import { GridUtils } from '../../../js/grid-utils';
import RemoteSearchSelect from '@/gikam/js/components/template/fields/select/remote-search-select.vue';

export default {
    props: {
        options: Object,
        row: Object,
        propValue: [Number, String],
        rowIndex: Number
    },

    components: {
        selectEditor,
        RemoteSearchSelect
    },

    data() {
        return {
            readonly: this.options.readonly ?? false,
            items: [],
            rootClass: 'grid-cell-select'
        };
    },

    computed: {
        ...mapState(['grid']),

        dataId() {
            return GridUtils.getRowVueState(this.row, 'key');
        },

        classList() {
            const classList = [this.rootClass];
            classList.push(`${this.rootClass}-${this.options.contentAlign}`);
            if (this.options.readonly) {
                classList.push(`${this.rootClass}-readonly`);
            }
            return classList;
        },

        editorType() {
            return this.options.remoteSearch ? 'RemoteSearchSelect' : 'selectEditor';
        }
    },

    created() {
        this.initItems();
    },

    watch: {
        items(value) {
            this.$set(this.options, 'items', value);
        }
    },

    methods: {
        async initItems() {
            this.items = await SelectSource.getItems(this.options);
        },

        changeHandle(...args) {
            this.$emit.apply(this, ['change', ...args]);
        }
    }
};
</script>

<style scoped lang="scss">
$rootClass: 'grid-cell-select';

@mixin textAlign($align) {
    &.#{$rootClass}-#{$align} {
        > ::v-deep .readonly-text {
            display: flex;
            justify-content: $align;
        }
    }
}

.#{$rootClass} {
    &-readonly {
        > ::v-deep .readonly-text {
            background: transparent;
            border: none;
            padding: 0;
        }

        @include textAlign(left);

        @include textAlign(center);

        @include textAlign(right);
    }
}

::v-deep {
    .ant-select-selection {
        height: 20px;

        .ant-select-selection__rendered {
            line-height: 20px;
        }

        &:active {
            box-shadow: none;
            margin-top: 0px;
        }
    }
}
</style>
