<template>
    <year-field :options="options" @change="changeHandle" :rowIndex="rowIndex" />
</template>

<script>
export default {
    props: {
        options: Object,
        row: Object,
        rowIndex: Number
    },

    methods: {
        changeHandle(field, value) {
            this.$emit('change', field, value);
        }
    }
};
</script>
