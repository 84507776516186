<template>
    <drag-resize class="chat-wrapper" ref="wrapper" :options="dragOptions" :stickSize="16">
        <div :class="chatClass" role="chat">
            <header>
                <span :class="headerTitleClass">{{ title }}</span>
                <transition name="new-message">
                    <a-badge count="new" v-if="newMessage && minimize" />
                </transition>
                <span :class="headerToolbarClass">
                    <span :class="toolbarButtonClass" role="button" v-if="minimize" @click="recoveryHandle">
                        <i>
                            <icon-recovery />
                        </i>
                    </span>
                    <span :class="toolbarButtonClass" role="button" v-else @click="minimizeHandle">
                        <i>
                            <icon-minimize />
                        </i>
                    </span>
                </span>
            </header>
            <main>
                <iframe :src="src"></iframe>
            </main>
        </div>
    </drag-resize>
</template>

<script>
import { Badge } from 'ant-design-vue';
import Vue from 'vue';
import DragResize from '@/sunway/drag-resize/drag-resize';

const baseClass = 'sw-chat';

Vue.use(Badge);

export default {
    components: {
        DragResize
    },

    props: {
        defaultMinimize: {
            type: Boolean,
            default: true
        },

        src: {
            type: String,
            default: ''
        },

        title: {
            type: String,
            default: ''
        },

        width: {
            type: Number,
            default: 1000
        },

        height: {
            type: Number,
            default: 700
        }
    },

    data() {
        return {
            minimize: this.defaultMinimize ?? true,
            newMessage: false,
            dragOptions: {
                width: `${this.width}px`,
                height: `${this.height}px`,
                left: null,
                right: null
            }
        };
    },

    computed: {
        chatClass() {
            const classList = [baseClass];
            return classList;
        },

        headerToolbarClass() {
            const classList = [`${baseClass}-toolbar`];
            return classList;
        },

        headerTitleClass() {
            const classList = [`${baseClass}-title`];
            return classList;
        },

        chatStyle() {
            if (this.minimize) {
                return {
                    width: '250px',
                    height: '30px',
                    right: 0,
                    bottom: 0
                };
            } else {
                return {
                    width: `${this.width}px`,
                    height: `${this.height}px`
                    // left: '50%',
                    // top: '50%',
                    // transform: 'translate(-50%,-50%)'
                };
            }
        },

        toolbarButtonClass() {
            const classList = [`${this.headerToolbarClass}-button`];
            return classList;
        }
    },

    created() {
        this.initPosition();
    },

    mounted() {
        this.initMessageEvent();
    },

    methods: {
        recoveryHandle() {
            this.minimize = false;
            this.newMessage = false;
            this.initPosition();
        },

        minimizeHandle() {
            this.minimize = true;
            this.initPosition();
        },

        initMessageEvent() {
            window.addEventListener('message', event => {
                this.newMessage = event.data?.newMessage;
            });
        },

        initPosition() {
            const bodyPosition = document.body.getBoundingClientRect();
            if (this.minimize) {
                this.dragOptions.left = bodyPosition.width - 250;
                Object.assign(this.dragOptions, {
                    left: bodyPosition.width - 250,
                    top: bodyPosition.height - 30,
                    width: 250,
                    height: 30
                });
            } else {
                Object.assign(this.dragOptions, {
                    left: (bodyPosition.width - this.width) / 2,
                    top: (bodyPosition.height - this.height) / 2,
                    width: this.width,
                    height: this.height
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$baseClass: sw-chat;

.chat-wrapper {
    position: absolute;
    z-index: 10000;
    animation: slideInUp 0.3s;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    transition: all 0.3s;

    ::v-deep {
        .vdr-stick {
            opacity: 0;
        }
    }
}

.#{$baseClass} {
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    &-title {
        padding: 0 8px 0 16px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: bold;
        line-height: 14px;
    }

    &-toolbar {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 16px;
        color: #999;

        &-button {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
    }

    > header {
        height: 30px;
        display: flex;
        align-items: center;

        ::v-deep > .ant-badge {
            &.new-message-enter-active {
                animation: bounceIn 0.5s;
            }

            &.new-message-leave-active {
                animation: bounceOut 0.5s;
            }
        }
    }

    > main {
        flex: 1;
        overflow: hidden;

        > iframe {
            border: none;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
