import Base from './base';
import Gikam from '../core/gikam-core';
import Vue from 'vue';
import Upload from './template/upload/upload';
import jQuery from 'jquery';
import { I18N } from '@/gikam/i18n/I18N.js';

let defaultOptions = {
    id: void 0,
    readonly: false,
    renderTo: void 0,
    multiple: true,
    server: void 0,
    deleteUrl: void 0,
    dbTable: void 0,
    bizId: void 0,
    bizCategory: void 0,
    searchUrl: void 0,
    gridSaveService: 'coreFileServiceImpl',
    search: false,
    accept: void 0,
    onBeforeUpload: Gikam.emptyFunction,
    onBeforeRefresh: Gikam.emptyFunction,
    onGridRowActive: Gikam.emptyFunction,
    gridFilter: false,
    gridPage: true,
    autoClose: false,
    //允许的最大宽度，由父组件传值
    allowWidth: 0,
    fill: false,
    //定制Grid中的列
    formatterGridColumns: columns => columns,
    //文档模式 ( view:查看 | edit:编辑 )
    fileViewType: 'view',
    //定制Grid上方工具栏
    formatterGridToolbar: toolbar => toolbar,
    //显示哪些按钮
    gridToolbar: [],
    //是否展示下载按钮属性控制
    download: true,
    //是否展示预览按钮属性控制
    preview: true,
    // 是否显示操作记录
    fileOperationLog: false,
    // 点击操作记录, 打开模态框
    createModal: Gikam.emptyFunction,
    //控制列是否充满整个grid(默认true)
    gridColumnsFill: true,
    // 是否展示下载按钮
    // beforeDownloadRender() { return true },
    // 是否展示预览按钮
    // beforePreviewRender() { return true },
    otherParam: {
        imageRotate: 0
    },
    // 弹出框是否模态框
    popupIsModal: true,
    // 预览方式
    viewType: 'modal',
    //是否直接上传(false为不直接上传)
    immediateUpload: false,
    // 批量下载按钮显示
    batchDownload: false,
    // 批量预览按钮显示
    batchPreview: false,
    gridGeneralButtonGroup: false,
    // 是否启用通过url获取数据
    enableGetDataByUrl: true,
    scope: void 0,
    // grid列表属性
    gridOptions: {}
};

function getDefaultColumns(instance) {
    window.createFileOperationLogModal = instance.createFileOperationLogModal;
    window.downloadFile = instance.download;
    let defaultColumns = [
        {
            checkbox: true
        },
        {
            index: true
        },
        {
            field: 'name',
            title: 'GIKAM.FILE.FILENAME',
            search: true,
            width: 250
        },
        {
            field: 'createdByName',
            title: 'GIKAM.FILE.CREATEDBYNAME',
            search: true,
            width: 180
        },
        {
            field: 'createdTime',
            title: 'GIKAM.FILE.CREATEDTIME'
        },
        {
            width: 170,
            title: I18N.prop('upload.title.operate'),
            field: 'operation',
            sort: false,
            formatter: function(i, v, r) {
                let str = '<div style= "display: flex; justify-content: space-between ">';
                if (
                    this.options.preview &&
                    Gikam.trigger(this.options, 'beforePreviewRender', this, i, v, r) !== false
                ) {
                    const readOnly =
                        Gikam.isNotEmpty(instance.options.fileViewType) && instance.options.fileViewType === 'view'
                            ? true
                            : false;
                    instance.options.otherParam.viewType = instance.options.viewType;
                    const otherParam = JSON.stringify(instance.options.otherParam);

                    str += Gikam.printf(
                        `<a href="javascript:;" onclick="Gikam.openFile({id}, {readonly}, {otherParam}, {popupIsModal})" style="flex: 1;text-align: center;">${I18N.prop(
                            'upload.content.preview'
                        )}</a>`,
                        {
                            id: r.id,
                            readonly: readOnly,
                            otherParam: otherParam ? otherParam.replace(/"/g, "'") : 'null',
                            popupIsModal: instance.options.popupIsModal
                        }
                    );
                }
                if (
                    this.options.download &&
                    Gikam.trigger(this.options, 'beforeDownloadRender', this, i, v, r) !== false
                ) {
                    const fileUrl = Gikam.getFileContextUrl(r.downloadUrl);
                    str += `<a href="javascript:;" onclick="downloadFile('${fileUrl}','${r.name}', '${r.id}', '${
                        this.options.fileOperationLog
                    }')" key = "download" style="flex: 1;text-align: center;">${I18N.prop(
                        'upload.content.download'
                    )}</a>`;
                }
                if (this.options.fileOperationLog) {
                    str += `<a href="javascript:;" onclick="createFileOperationLogModal('${r.id}', '${
                        instance.options.popupIsModal
                    }')" key = "records" style="flex: 2;text-align: center;">${I18N.prop(
                        'upload.content.operation'
                    )}</a>`;
                }
                str += '</div>';
                return str;
            }
        }
    ];

    return defaultColumns;
}

export default class Uploader extends Base {
    constructor(options) {
        super(options);
        this.async = [];
        this.listeners = {
            beforeUpload: Gikam.emptyFunction,
            beforeRefresh: Gikam.emptyFunction,
            beforeDelete: Gikam.emptyFunction,
            afterClose: Gikam.emptyFunction,
            uploadSuccess: Gikam.emptyFunction,
            gridRowActive: Gikam.emptyFunction,
            afterDelete: Gikam.emptyFunction,
            beforeFilesUpload: () => true,
            beforeFileUpload: () => true
        };
        this.uploadData = {};
        this.requestData = {};
        this.initialize(options, defaultOptions).init();
    }

    createGrid() {
        let _this = this;
        Gikam.extend(this.requestData, {
            targetId: this.options.dbTable + '$' + this.options.bizId
        });
        if (this.options.bizCategory) {
            this.requestData.bizCategory_SEQ = this.options.bizCategory;
        }
        return Gikam.create(
            'grid',
            Gikam.extend(
                {
                    $window: this.$window,
                    $parent: this,
                    renderTo: this.root,
                    id: this.options.gridId,
                    url: this.options.bizId ? this.options.searchUrl : null,
                    requestData: this.requestData,
                    toolbar: this.getGridToolbar(),
                    columns: this.getGridColumns(),
                    service: this.options.gridSaveService,
                    filter: this.options.gridFilter,
                    allowWidth: this.options.allowWidth - 20,
                    noScroll: this.options.noScroll ? this.options.noScroll : false,
                    fill: this.options.fill,
                    page: false,
                    generalButtonGroupShow: this.options.gridGeneralButtonGroup,
                    columnsFill: this.options.gridColumnsFill,
                    enableGetDataByUrl: this.options.enableGetDataByUrl,
                    deleteFormatter: row => row.name,
                    onBeforeRefresh: function(requestData) {
                        _this.trigger('beforeRefresh', requestData);
                    },
                    onRowActive: function(index, row) {
                        _this.trigger('gridRowActive', index, row);
                    },
                    data: this.options.data
                },
                this.options.gridOptions
            )
        );
    }

    /**
     * @description 获取表格列
     * @private
     * @returns
     * @memberof Uploader
     */
    getGridColumns() {
        let defaultColumns = getDefaultColumns(this);
        if (!this.options.columns) {
            for (let index = 0, length = defaultColumns.length; index < length; index++) {
                if (defaultColumns[index].formatter) {
                    defaultColumns[index].formatter = defaultColumns[index].formatter.bind(this);
                }
            }
        }
        let columns = this.options.formatterGridColumns.call(
            this,
            Gikam.deepExtend(this.options.columns || defaultColumns)
        );
        this.options.readonly &&
            columns.forEach(function(item) {
                if (Gikam.isNotEmpty(item.editor)) {
                    item.editor = false;
                }
            });
        return columns;
    }

    getDefaultGridToolbar() {
        const _this = this;
        const toolbar = [
            {
                type: 'button',
                text: 'GIKAM.FILE.BUTTON.UPLOAD_FILE',
                icon: 'upload',
                onClick: function() {
                    if (_this.options.multiple === false && _this.grid.getData().length > 0) {
                        Gikam.alert('GIKAM.FILE.TIP.UPLOAD_ONE_ONLY');
                        return;
                    }
                    if (!_this.options.bizId || _this.trigger('beforeUpload') === false) return;
                    _this.showUploadModal();
                }
            },
            {
                type: 'button',
                text: 'GIKAM.FILE.BUTTON.DELETE_FILE',
                icon: 'file-remove',
                onClick: function() {
                    if (_this.trigger('beforeDelete') === false) {
                        return;
                    }
                    _this.grid.deleteRows(_this.options.deleteUrl).done(() => {
                        _this.trigger('afterDelete');
                    });
                }
            },
            ...this.getBatchDownload(),
            ...this.getBatchPreview(),
            ...this.getOtherGridToolbar()
        ];
        return this.options.formatterGridToolbar.call(this, toolbar);
    }

    getBatchPreview() {
        const _this = this;
        if (!this.options.batchPreview) {
            return [];
        }
        return [
            {
                type: 'button',
                text: I18N.prop('upload.button.batchPreview'),
                icon: 'batch-view',
                onClick() {
                    const selections = _this.grid.getSelections();
                    if (selections.length === 0) {
                        Gikam.alert(I18N.prop('upload.tip.choose.data.batch.preview'));
                        return;
                    }
                    const selectionIds = selections.map(row => {
                        return row.id;
                    });
                    const modal = Gikam.create('modal', {
                        toolbarTop: true,
                        isModal: false
                    });

                    Gikam.create('batchPreview', {
                        id: 'core-file-batch-preview',
                        renderTo: modal.window.$dom,
                        fileIds: selectionIds,
                        modal: modal,
                        imageRotate: _this.options.otherParam.imageRotate
                    });
                }
            }
        ];
    }

    getGridToolbar() {
        let toolbar = this.getDefaultGridToolbar();
        if (Gikam.isNotEmpty(this.options.gridToolbar)) {
            toolbar = toolbar.filter(item => this.options.gridToolbar.includes(item.icon));
        }
        if (this.options.readonly && Gikam.isEmpty(this.options.gridToolbar)) {
            toolbar = [];
        }
        return toolbar;
    }

    getBatchDownload() {
        const _this = this;
        if (!this.options.batchDownload) {
            return [];
        }
        return [
            {
                type: 'button',
                text: I18N.prop('upload.button.batchDownload'),
                icon: 'batch-download',
                onClick() {
                    const selections = _this.grid.getSelections();
                    if (selections.length === 0) {
                        Gikam.alert(I18N.prop('upload.tip.choose.data.batch.download'));
                        return;
                    }
                    const selectionIds = selections.map(row => {
                        return { id: row.id };
                    });
                    Gikam.postText(
                        Gikam.IFM_CONTEXT + '/core/module/item/files/action/pack',
                        Gikam.getJsonWrapper({}, ['', selectionIds])
                    ).done(function(downloadUrl) {
                        Gikam.download(Gikam.getFileContextUrl(downloadUrl));
                    });
                }
            }
        ];
    }

    getOtherGridToolbar() {
        return [];
    }

    showUploadModal() {
        let _this = this;
        this.upload = new Vue({
            el: jQuery('<Upload ref="upload" :options="options"></Upload>').appendTo('body')[0],
            components: {
                Upload
            },
            provide: {
                upload: this
            },
            data() {
                return {
                    options: _this.options
                };
            }
        });
    }

    createRoot() {
        this.root = jQuery('<div class="uploader">')
            .css({
                width: '100%',
                height: '100%'
            })
            .attr('id', this.options.id || '')
            .appendTo(this.options.renderTo)[0];
        this.bindInstance(this.root);
    }

    setRequestData(data) {
        Gikam.extend(this.requestData, data, {
            targetId: this.options.dbTable + '$' + this.options.bizId
        });
        this.grid?.refresh({
            requestData: this.requestData,
            url: this.options.bizId ? this.options.searchUrl : null
        });
    }

    setOptions(param) {
        Gikam.jQuery.extend(true, this.options, param);
        this.grid && (this.grid.options.enableGetDataByUrl = this.options.enableGetDataByUrl);
        this.setRequestData(param);
    }

    setUploadData(data) {
        this.uploadData = data;
    }

    setDefaultOptions(options) {
        Gikam.extend(true, defaultOptions, options.defaultOptions);
    }

    createFileOperationLogModal(fileId, popupIsModal) {
        let modal = Gikam.create('modal', {
            id: 'file-operation-log-modal',
            title: '查看文件操作记录',
            width: 600,
            height: 400,
            toolbarTop: true,
            isModal: popupIsModal
        });
        Gikam.create('layout', {
            renderTo: modal.window.$dom,
            center: {
                items: [
                    {
                        type: 'grid',
                        fill: true,
                        rootClass: 'uploader',
                        url: `${Gikam.IFM_CONTEXT}/core/module/item/files/${fileId}/logs/queries/searchable`,
                        columns: [
                            {
                                index: true
                            },
                            {
                                field: 'operationCategory',
                                title: 'T_CORE_FILE_LOG.OPERATIONCATEGORY',
                                type: 'select',
                                category: 'sys',
                                param: {
                                    codeCategoryId: 'fileLogOperationCategory'
                                },
                                genericQuery: true
                            },
                            {
                                field: 'createdById',
                                title: 'T_CORE_FILE_LOG.CREATEDBYID'
                            },
                            {
                                field: 'createdByName',
                                title: 'T_CORE_FILE_LOG.CREATEDBYNAME'
                            },
                            {
                                field: 'createdTime',
                                title: 'T_CORE_FILE_LOG.CREATEDTIME'
                            }
                        ]
                    }
                ]
            }
        });
    }

    download(downloadUrl, fileName, fileId, fileOperationLog) {
        downloadUrl = Gikam.addUrlParam(downloadUrl, { id: fileId });
        if (fileOperationLog) {
            const logUrl = Gikam.printf(
                Gikam.IFM_CONTEXT + '/core/module/item/files/{id}/logs?operationCategory={operationCategory}',
                {
                    id: fileId,
                    operationCategory: 'DOWNLOAD'
                }
            );
            Gikam.postText(logUrl).done(() => {
                Gikam.download(downloadUrl, fileName);
            });
        } else {
            Gikam.download(downloadUrl, fileName);
        }
    }

    /**
     * @public
     * @description 关闭上传弹框
     * @returns Uploader
     * @memberof Uploader
     */
    closeModal() {
        if (!this.upload) {
            return;
        }
        Gikam.removeDom(this.upload.$el);
        this.upload.$destroy();
        return this;
    }

    onResize() {
        this.$width = parseInt(this.$parent.width);
        this.grid.onResize && this.grid.onResize();
    }

    /**
     * @public
     * @description 切换顶部按钮显示状态
     * @returns Uploader
     * @memberof Uploader
     */
    setToolbarVisible(state = true) {
        this.grid.options.toolbarHidden = !state;
        return this;
    }

    /**
     * @description 设置默认参数
     * @static
     * @param {*} options
     * @memberof Uploader
     */
    static setDefaultOptions(options) {
        Gikam.extend(true, defaultOptions, options.defaultOptions);
    }

    /**
     * @description 获取默认参数
     * @static
     * @returns
     * @memberof Uploader
     */
    static getDefaultOptions() {
        return defaultOptions;
    }

    init() {
        this.createRoot();
        this.options.createModal = this.createModal;
        this.grid = this.createGrid();
        this.grid.$window = this.$window;
        this.notify();
    }
}
