<script>
import Vue from 'vue';
import GikamCore from 'gikam';
import select from '../select/select';

const Gikam = top === window ? GikamCore : top.Gikam || GikamCore;

const defaultOptions = {
    //添加到系统并回填
    setSelectValue: false,
    showAddButton: false
};

export default {
    name: 'insertableSelectField',
    extends: select,
    methods: {
        //复写原select组件弹框方法
        showItems() {
            Gikam.insertablePicker && Gikam.insertablePicker.close();
            Gikam.selectPicker && Gikam.selectPicker.close();
            Gikam.textareaPicker && Gikam.textareaPicker.close();
            Gikam.datePicker && Gikam.datePicker.destroy();
            Gikam.yearPicker && Gikam.yearPicker.destroy();
            Gikam.timePicker && Gikam.timePicker.destroy();
            Gikam.comboboxPicker && Gikam.comboboxPicker.combobox.cleanPicker();
            this.$refs.input && this.$refs.input.focus();
            const _this = this;
            this.$refs.input && this.$refs.input.focus();
            this.$emit('saveCoordinate');
            this.isActive = true;
            const target = this.$refs.select;
            Gikam.insertablePicker = new Vue({
                el: Gikam.createDom('div', window.top.document.body),
                components: {
                    picker: () => import('./picker')
                },
                data() {
                    return {
                        search: _this.options.search || true,
                        setSelectValue: Gikam.isNotEmpty(_this.options.setSelectValue)
                            ? _this.options.setSelectValue
                            : defaultOptions.setSelectValue,
                        showAddButton: Gikam.isNotEmpty(_this.options.showAddButton)
                            ? _this.options.showAddButton
                            : defaultOptions.showAddButton
                    };
                },
                methods: {
                    close() {
                        this.$refs.vm.$refs.basePicker.clean();
                        _this.isActive = false;
                    }
                },
                render() {
                    const height = _this.items.length * 32 > 200 ? 200 : _this.items.length * 32;
                    return (
                        <picker
                            target={target}
                            propOption={_this.options}
                            propItems={_this.items}
                            propSearch={this.search}
                            propValue={_this.value}
                            propHeight={height}
                            onChangeItems={items => _this.changeItems(items)}
                            onselectItem={item => _this.selectHandle(item)}
                            setSelectValue={this.setSelectValue}
                            showAddButton={this.showAddButton}
                            ref={'vm'}
                        />
                    );
                }
            });
        },

        selectHandle(item) {
            this.value = item.value;
        },

        changeItems(items) {
            this.items = items;
        },

        dumpActiveCell() {
            if (!this.invisible) {
                this.focusFlag = false;
                this.$refs.input && this.$refs.input.blur();
                Gikam.insertablePicker && Gikam.insertablePicker.close();
            }
        },

        override(props) {
            Gikam.extend(defaultOptions, props);
        }
    }
};
</script>
