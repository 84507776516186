<template>
    <td :colspan="columns.length">
        <div :class="rootClass">
            <div v-if="group.groupCheckbox" :class="checkboxWrapperClass">
                <checkbox
                    ref="checkbox"
                    @change="checkboxHandle"
                    :propChecked="checked"
                    :propHalfChecked="halfChecked"
                ></checkbox>
            </div>
            <div :class="switchWrapperClass" @click="toggleClickHandle">
                <i :is="swtichIcon" v-bind="iconOptions" />
            </div>
            <div :class="contentWrapperClass" @click="contentWrapperHandle" v-html="content"></div>
        </div>
    </td>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { GridUtils } from '../../../js/grid-utils';
import Gikam from 'gikam';

export default {
    props: {
        rowData: Object
    },

    data() {
        return {
            rootClass: 'grid-cell-group',
            iconOptions: { color: '#007aff', width: '12px', height: '12px' }
        };
    },

    computed: {
        ...mapState(['columns', 'group', 'grid', 'allChecked']),

        checkboxWrapperClass() {
            const classList = [`${this.rootClass}-checkbox-wrapper`];
            return classList;
        },

        switchWrapperClass() {
            const classList = [`${this.rootClass}-switch-wrapper`];
            return classList;
        },

        contentWrapperClass() {
            const classList = [`${this.rootClass}-content-wrapper`];
            return classList;
        },

        content() {
            if (this.group.formatter) {
                return this.group.formatter.call(this.grid, this.rowData);
            }
            return this.group.fields.reduce((total, fieldName, index) => {
                const result = total + Gikam.getFieldValue(this.rowData, fieldName);
                return index > 0 ? `,${result}` : result;
            }, '');
        },

        swtichIcon() {
            const groupExpand = GridUtils.getRowVueState(this.rowData, 'groupExpand');
            return groupExpand ? 'roundMinusImg' : 'roundAddImg';
        },

        checked() {
            if (this.allChecked) {
                return true;
            }
            return GridUtils.getRowVueState(this.rowData, 'checked');
        },

        halfChecked() {
            if (this.allChecked) {
                return false;
            }
            return GridUtils.getRowVueState(this.rowData, 'halfChecked');
        }
    },

    methods: {
        ...mapMutations(['checkRow', 'unCheckRow']),

        toggleClickHandle() {
            const groupExpand = !GridUtils.getRowVueState(this.rowData, 'groupExpand');
            GridUtils.setRowVueState(this.rowData, 'groupExpand', groupExpand);
            const groupChildren = GridUtils.getRowVueState(this.rowData, 'groupChildren');
            const dataMapper = this.grid.dataMapper;
            groupChildren.forEach(childKey => {
                GridUtils.setRowVueState(dataMapper[childKey], 'visible', groupExpand);
            });
        },

        checkboxHandle(checked, value, event) {
            event.stopPropagation();
            GridUtils.setRowVueState(this.rowData, 'checked', checked);
            const groupChildren = GridUtils.getRowVueState(this.rowData, 'groupChildren');
            const dataMapper = this.grid.dataMapper;
            groupChildren.forEach(childKey => {
                const options = { rowData: dataMapper[childKey], triggerEvent: false };
                if (checked) {
                    this.checkRow(options);
                } else {
                    this.unCheckRow(options);
                }
            });
        },
        contentWrapperHandle(e) {
            this.$refs.checkbox.clickHandle && this.$refs.checkbox.clickHandle(e);
        }
    }
};
</script>

<style lang="scss" scoped>
$rootClass: grid-cell-group;

.#{grid-cell-group} {
    display: flex;

    &-checkbox-wrapper {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-switch-wrapper {
        font-size: 12px;
        color: #007aff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
    }

    &-content-wrapper {
        font-size: 12px;
        color: #007aff;
        cursor: pointer;
        flex: 1;
        display: flex;
        align-items: center;
        height: 100%;
    }
}
</style>
