<template>
    <div class="search-form">
        <div
            class="search-form-item"
            v-for="(item, index) in toolbarFormList"
            :key="item.field + index"
            :style="{ width: item.width + 'px' }"
        >
            <span class="label" v-if="item.title">{{ item.title }}</span>
            <div class="search-form-item-field">
                <div class="" :is="(item.type || 'text') + 'Field'" :options="item" @change="changeHandler"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {},

    computed: {
        ...mapGetters(['toolbarFormList'])
    },

    methods: {
        changeHandler(field, val) {
            const fieldOptions = this.toolbarFormList.filter(item => item.field === field)[0] || {};
            if (fieldOptions.refreshGrid !== false) {
                this.$store.commit('refreshGrid', {
                    [field]: val
                });
            }
            fieldOptions.onChange(...arguments);
        }
    }
};
</script>

<style scoped lang="scss">
.search-form {
    display: flex;
    height: 26px;
    padding-left: 4px;
    .search-form-item {
        display: flex;
        align-items: center;
        margin-right: 14px;
        width: 150px;
        height: 100%;
        .label {
            color: rgba(0, 0, 0, 0.85);
            font-family: 'Microsoft YaHei', serif;
            font-size: 12px;
            padding-right: 8px;
            position: relative;
            &::after {
                content: ':';
                position: absolute;
                right: 4px;
                top: 0;
            }
        }
        .search-form-item-field {
            flex: 1;
            display: inline-flex;
            align-items: center;
            height: 100%;
        }
    }
}
</style>
