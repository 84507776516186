<template>
    <div class="checkbox-group-input">
        <checkbox :propReadonly="propReadonly" :propChecked="propChecked"></checkbox>
        <span class="checkbox-group-input-title">{{ $i18n('checkbox.other.input') }}</span>
        <input @change="inputChangeHandle" :value="value" :readonly="propReadonly" />
    </div>
</template>

<script>
export default {
    props: {
        propReadonly: Boolean,
        propChecked: Boolean,
        value: [String, Number]
    },

    methods: {
        inputChangeHandle(event) {
            this.$emit('change', event);
        }
    }
};
</script>

<style lang="scss" scoped>
.checkbox-group-input {
    display: flex;
    align-items: center;

    > .checkbox {
        margin-right: 8px;
    }

    > .checkbox-group-input-title {
        margin-right: 8px;
        white-space: nowrap;
    }

    input {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.85);
        letter-spacing: 0;
        line-height: 18px;
        border: none;
        border-bottom: 1px solid #d9d9d9;
        flex: 0.9;
        transition: all 0.3s;
        background-color: transparent;

        &:focus {
            border-bottom: 1px solid rgba(0, 122, 255, 0.5);
        }
    }
}
</style>
