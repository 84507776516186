<template>
    <div class="sunway-ketcher" :style="{ height: height + 'px' }" :class="{ readonly: readonly }">
        <div class="svg" v-html="value.svg"></div>
        <div class="ketcher-editor" v-if="!editing && !readonly" @click="ketcherEditor">
            <icon-edit></icon-edit>
        </div>
    </div>
</template>

<script>
import Gikam from 'gikam';
import BaseField from '../baseField/baseField.vue';

export default {
    props: ['options', 'propValue'],
    data() {
        return {
            height: this.options.height,
            iframe: null,
            contentWindow: null,
            value: this.propValue ? JSON.parse(this.propValue) : {},
            oldValue: '',
            editing: false
        };
    },
    extends: BaseField,
    methods: {
        getSrc() {
            return Gikam.IFM_CONTEXT + '/static/plugin/ketcher/index.html';
        },

        ketcherEditor() {
            this.editing = true;
            const _this = this;
            const modal = Gikam.create('modal', {
                id: 'modal-ketcher',
                title: 'ketcher',
                onAfterClose() {
                    _this.editing = false;
                }
            });

            Gikam.create('layout', {
                renderTo: modal.window.$dom,
                north: {
                    height: 5,
                    items: [
                        {
                            type: 'btnToolbar',
                            items: [
                                {
                                    type: 'button',
                                    text: this.$i18n('core.confirm'),
                                    class: 'blue',
                                    onClick: async function() {
                                        await _this.ketcherSave();
                                        Gikam.getLastModal().close();
                                    }
                                },
                                {
                                    type: 'button',
                                    text: this.$i18n('core.cancel'),
                                    onClick: function() {
                                        Gikam.getLastModal().close();
                                    }
                                }
                            ]
                        }
                    ]
                }
            });

            this.iframe = document.createElement('iframe');
            this.iframe.setAttribute('src', Gikam.IFM_CONTEXT + '/static/plugin/ketcher/index.html');
            this.iframe.style.width = 'calc(100% - 20px)';
            this.iframe.style.height = 'calc(100% - 20px)';
            this.iframe.style.border = 'none';
            this.iframe.style.margin = '10px';
            this.iframe.onload = () => {
                this.contentWindow = this.iframe.contentWindow;
                this.contentWindow.hasKetcher(() => {
                    _this.contentWindow.setData(this.value.graph);
                });
            };
            modal.window.$dom.appendChild(this.iframe);
        },

        async ketcherSave() {
            this.editing = false;
            const newVal = await this.ketcherGetData();
            if (newVal.graph === this.value.graph) {
                return;
            }
            this.value = JSON.stringify(newVal);
            this.$emit('change', this.options.field, this.value, this.rowIndex, {oldValue: this.oldValue});
        },

        async ketcherGetData() {
            const res = {};
            await this.$nextTick(() => {
                const box = this.iframe.contentDocument.getElementsByClassName('StructEditor-module_canvas__3PrVC')[0];
                res.svg = box.getElementsByTagName('svg')[0].outerHTML;
            });
            res.graph = await this.contentWindow.ketcher.getGraphAsync();
            return res;
        }
    },

    watch: {
        propValue(val, oldVal) {
            this.oldValue = oldVal
            this.value = JSON.parse(val);
            this.contentWindow && this.contentWindow.setData(this.value.graph);
        }
    },

    computed: {
        readonly() {
            return this.options.readonly;
        }
    }
};
</script>

<style lang="scss" scoped>
.sunway-ketcher {
    width: 100%;
    height: 100%;
    min-height: 32px;
    position: relative;
    border: 1px solid #d9d9d9;
    border-radius: 4px;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }

    .ketcher-editor {
        font-size: 20px;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 1px;
        right: 3px;
        cursor: pointer;
    }
}
</style>
