<template>
    <div class="date-picker" @click.stop @mousedown.stop @dblclick.stop :id="options.id">
        <div class="ym-select">
            <div class="year" @dblclick.stop>
                <button :title="$i18n('date.lastYear')" v-on:click="setLastYear()" class="last-year-btn"></button>
                <div class="ym-container">
                    <input
                        class="year-input"
                        v-model="year"
                        v-on:input="refreshYear"
                        @change="changeYear"
                        autocomplete="off"
                    />
                    {{ $i18n('date.year') }}
                </div>
                <button :title="$i18n('date.nextYear')" v-on:click="setNextYear()" class="next-year-btn"></button>
            </div>

            <div class="month" @dblclick.stop>
                <button :title="$i18n('date.lastMonth')" v-on:click="setLastMonth()" class="last-month-btn"></button>
                <div class="ym-container">
                    <input
                        class="month-input"
                        v-model="month"
                        v-on:input="refreshMonth"
                        @change="changeMonth"
                        autocomplete="off"
                    />
                    {{ $i18n('date.month') }}
                </div>
                <button v-on:click="setNextMonth()" :title="$i18n('date.nextMonth')" class="next-month-btn"></button>
            </div>
        </div>

        <div class="date-select-container">
            <table>
                <thead>
                    <tr>
                        <td v-for="item in week" :key="item">{{ item }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(weekList, trIndex) in showDays" :key="weekList.key">
                        <td
                            v-for="(day, tdIndex) in weekList"
                            :key="tdIndex"
                            class="date-picker-days"
                            :class="day.class"
                            @click="chooseDate($event, trIndex, tdIndex)"
                            @dblclick.stop="handleTimeDblClick($event, trIndex, tdIndex)"
                        >
                            <div class="text">{{ day.dayOfMonth }}</div>
                            <div class="icon" v-if="day.notAllowed"></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="time-container" v-if="options.type === 'dateTime'">
            <div :class="timeClass">
                <DateNumberInput
                    ref="minuteInput"
                    class="time-hour"
                    max="23"
                    v-model="activeHour"
                    @input="hourInputHandle"
                />
                <DateNumberInput
                    ref="minuteInput"
                    class="time-minute"
                    max="59"
                    v-model="activeMinute"
                    @input="minuteInputHandle"
                />
                <DateNumberInput
                    v-if="options.enableSecond"
                    ref="secondInput"
                    class="time-second"
                    max="59"
                    v-model="second"
                />
            </div>
        </div>
        <div class="select-today">
            <a
                href="javascript:"
                class="select-today-btn"
                :class="{ 'hidden-today-btn': !showToday }"
                v-on:click="selectToday()"
                >{{ $i18n('date.today') }}</a
            >
            <div class="btn-container">
                <div class="clean-btn" @click="clean">{{ $i18n('date.clean') }}</div>
                <div class="confirm-btn" @click="confirm">{{ $i18n('date.confirm') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Gikam from 'gikam';
import jQuery from 'jquery';
import DateNumberInput from './date-picker-number-input.vue';

export default {
    components: {
        DateNumberInput
    },

    props: {
        options: Object
    },

    data() {
        return {
            week: ['日', '一', '二', '三', '四', '五', '六'],
            Gikam: Gikam,
            year: void 0,
            month: void 0,
            activeDate: void 0,
            activeHour:
                !this.options.chooseDate && this.options.defaultHour
                    ? this.options.defaultHour
                    : Gikam.DateUtils.formatter(this.options.chooseDate || this.options.date, 'hh'),
            activeMinute:
                !this.options.chooseDate && this.options.defaultMinute
                    ? this.options.defaultMinute
                    : Gikam.DateUtils.formatter(this.options.chooseDate || this.options.date, 'mm'),
            initDate: Gikam.DateUtils.formatter(this.options.date, 'yyyy-MM-dd'),
            showDays: [],
            showToday: Gikam.isFalse(this.options.dateInput.showToday) ? false : true,
            second: Gikam.DateUtils.formatter(this.options.date, 'ss'),
            yearTimer: null,
            monthTimer: null
        };
    },

    computed: {
        timeClass() {
            const classList = ['time'];
            if (!this.options.enableSecond) {
                classList.push('second-disabled');
            }
            return classList;
        }
    },

    created() {
        this.refreshAll();
    },

    beforeDestroy() {
        this.options.close && this.options.close();
    },

    methods: {
        createDays() {
            let dateTimeArray = [];
            let year = parseInt(this.year);
            let month = parseInt(this.month);
            let firstDate = new Date(year, month - 1, 1);
            let firstDay = firstDate.getDay();
            let lastDate = new Date(year, month, 0);
            let lastDay = lastDate.getDay();

            firstDate.setDate(firstDate.getDate() - firstDay);
            lastDate.setDate(lastDate.getDate() + (7 - lastDay));

            this.options
                .onBeforeDaysChange(
                    Gikam.DateUtils.formatter(firstDate, 'yyyy-MM-dd'),
                    Gikam.DateUtils.formatter(lastDate, 'yyyy-MM-dd')
                )
                // eslint-disable-next-line complexity
                .done(date => {
                    let totalDays = (lastDate.getTime() - firstDate.getTime()) / (24 * 60 * 60 * 1000);

                    let week = 0;
                    for (let i = 0; i < totalDays; i++) {
                        if (i % 7 === 0) {
                            dateTimeArray.push([]);
                            dateTimeArray[week].key = `${Gikam.DateUtils.formatter(firstDate, 'yyyy-MM')}-w${week}`;
                        }

                        //计算是否为当前月
                        let beginDisabled = !!(this.options.beginDate && firstDate < this.options.beginDate);
                        let endDisabled = !!(this.options.endDate && firstDate > this.options.endDate);
                        let disabled = beginDisabled === true || endDisabled === true ? 'disabled' : '';
                        let notAllowed = '';

                        let firstDateStr = Gikam.DateUtils.formatter(firstDate, 'yyyy-MM-dd');

                        date &&
                            date.forEach(item => {
                                if (item.day === firstDateStr && item.status === 'disabled') {
                                    notAllowed = 'notAllowed';
                                }
                            });

                        //计算展示时间集合
                        dateTimeArray[week].push({
                            time: firstDateStr,
                            notAllowed: notAllowed === 'notAllowed',
                            class: [
                                //是否当前月
                                disabled,
                                notAllowed === 'notAllowed' ? 'not-allowed' : '',
                                //其他月日期
                                ~~firstDate.getMonth() === ~~month - 1 ? '' : 'other-month-days',
                                //是否为指定时间区间外的时间
                                this.otherRangeDays(firstDateStr) ? 'other-range-days' : '',
                                //是否今天
                                firstDateStr === Gikam.DateUtils.formatter(new Date(), 'yyyy-MM-dd') ? 'today ' : '',
                                //是否为最后选中时间
                                firstDateStr === Gikam.DateUtils.formatter(this.options.chooseDate, 'yyyy-MM-dd')
                                    ? 'lastSelectedDay'
                                    : ''
                            ],
                            dayOfMonth: firstDate.getDate()
                        });
                        if ((i + 1) % 7 === 0) week++;
                        firstDate.setDate(firstDate.getDate() + 1);
                    }

                    this.showDays = dateTimeArray;
                });
        },

        handleTimeDblClick(e, tr, td) {
            const activeDate = this.showDays[tr][td];
            if (this.otherRangeDays(activeDate.time) || activeDate.notAllowed) {
                return;
            }
            this.confirm();
        },

        // 时间双击 - 已废弃
        bindDateDblclickEvent() {
            let _this = this;
            jQuery('.date-picker-days').dblclick(function() {
                let date = jQuery(this).attr('value');
                if (_this.otherRangeDays(date)) {
                    return;
                }
                _this.confirm();
            });
        },

        refreshYear(e) {
            let Reg = /\D+/g;
            if (Reg.test(this.year)) {
                this.year = this.year.replace(Reg, '');
                return;
            } else {
                if (this.yearTimer) {
                    clearTimeout(this.yearTimer);
                    this.yearTimer = null;
                }
                if (this.year === '' || Number(this.year) < 100) {
                    return;
                }
                // 重置定时器
                let startIndex = e.target.selectionStart;
                this.yearTimer = setTimeout(
                    startIndex => {
                        if (startIndex >= 5) {
                            this.year = this.year.substr(-4);
                        } else {
                            this.year = this.year.substr(0, 4);
                        }
                        this.options.date.setFullYear(~~this.year);
                        this.refreshAll();
                    },
                    800,
                    startIndex
                );
            }
        },

        changeYear() {
            if (!this.year || Number(this.year) < 100) {
                this.year = Gikam.DateUtils.formatter(this.options.date, 'yyyy');
            }
        },

        refreshMonth(e) {
            let Reg = /\D+/g;
            if (Reg.test(this.month)) {
                this.month = this.month.replace(Reg, '');
                return;
            }
            if (this.monthTimer) {
                clearTimeout(this.monthTimer);
                this.monthTimer = null;
            }
            if (this.month === '') {
                return;
            }
            let startIndex = e.target.selectionStart;
            this.monthTimer = setTimeout(
                startIndex => {
                    if (startIndex >= 3) {
                        this.month = this.month.substr(-2);
                    } else {
                        this.month = this.month.substr(0, 2);
                    }
                    this.month > 12 && (this.month = 12);
                    this.month < 1 && (this.month = 1);
                    this.options.date.setMonth(~~this.month - 1);
                    this.month = Gikam.DateUtils.formatter(this.options.date, 'MM');
                    this.createDays();
                },
                800,
                startIndex
            );
        },

        changeMonth() {
            if (!this.month) {
                this.month = Gikam.DateUtils.formatter(this.options.date, 'MM');
            }
        },

        refreshAll() {
            this.year = Gikam.DateUtils.formatter(this.options.date, 'yyyy');
            this.month = Gikam.DateUtils.formatter(this.options.date, 'MM');
            this.createDays();
        },

        setNextMonth() {
            this.options.date.setMonth(this.options.date.getMonth() + 1);
            this.refreshAll();
        },

        setLastMonth() {
            this.options.date.setMonth(this.options.date.getMonth() - 1);
            this.refreshAll();
        },

        setLastYear() {
            this.options.date.setFullYear(this.options.date.getFullYear() - 1);
            this.refreshAll();
        },

        setNextYear() {
            this.options.date.setFullYear(this.options.date.getFullYear() + 1);
            this.refreshAll();
        },

        selectToday() {
            const date = new Date();
            this.activeDate = Gikam.DateUtils.formatter(date, 'yyyy-MM-dd');
            this.activeHour = Gikam.DateUtils.formatter(date, 'hh');
            this.activeMinute = Gikam.DateUtils.formatter(date, 'mm');
            this.confirm();
        },

        chooseDate(e, tr, td) {
            const activeDate = this.showDays[tr][td];
            if (this.otherRangeDays(activeDate.time) || activeDate.notAllowed) {
                return;
            }
            const activeCell = this.$el.querySelector('td.active');
            if (activeCell) {
                activeCell.classList.remove('active');
            }
            const activeDom = e.target.tagName === 'TD' ? e.target : e.target.parentNode;
            activeDom.classList.add('active');
            this.activeDate = activeDate.time;
        },

        confirm() {
            let value = this.activeDate;
            if (!value) {
                value = Gikam.DateUtils.formatter(this.options.chooseDate || new Date(), 'yyyy-MM-dd');
            }
            if (this.options.type === 'dateTime') {
                value += ` ${this.activeHour}:${this.activeMinute}`;
                if (this.options.enableSecond) {
                    value += `:${this.second}`;
                }
            }
            this.options.dateInput.pickerValue = value;
            this.options.closeEvent();
            this.destroy();
        },

        clean() {
            this.options.dateInput.pickerValue = '';
            this.options.closeEvent();
            this.destroy();
        },

        destroy() {
            jQuery(this.$el).remove();
            this.$destroy();
            this.options.dateInput.datePicker = void 0;
        },

        // 时间选择范围
        // eslint-disable-next-line complexity
        otherRangeDays(date) {
            if (!this.options.dateInput.dateRange) {
                return false;
            }

            if (this.options.dateInput.dateRange[0] && this.options.dateInput.dateRange[1]) {
                if (
                    new Date(date).getTime() < new Date(this.options.dateInput.dateRange[0]).getTime() ||
                    new Date(date).getTime() > new Date(this.options.dateInput.dateRange[1]).getTime()
                ) {
                    return true;
                }
                return false;
            } else if (this.options.dateInput.dateRange[0] && !this.options.dateInput.dateRange[1]) {
                if (new Date(date).getTime() < new Date(this.options.dateInput.dateRange[0]).getTime()) {
                    return true;
                }
                return false;
            } else if (!this.options.dateInput.dateRange[0] && this.options.dateInput.dateRange[1]) {
                if (new Date(date).getTime() > new Date(this.options.dateInput.dateRange[1]).getTime()) {
                    return true;
                }
                return false;
            }
        },

        hourInputHandle() {
            this.$refs.minuteInput.selectText();
        },

        minuteInputHandle() {
            if (this.options.enableSecond) {
                this.$refs.secondInput.selectText();
            }
        }
    }
};
</script>

<style scoped lang="scss">
@keyframes date-slide-bottom {
    from {
        transform: translate3d(0, 20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes date-slide-top {
    from {
        transform: translate3d(0, -20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

button::-moz-focus-inner {
    border: none;
}

.date-picker {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    font-family: 'Microsoft YaHei', serif;
    width: 248px;
    position: absolute;
    z-index: 10000;
    animation: date-slide-bottom 0.3s;
    color: #000;
}

.date-picker.top {
    animation: date-slide-top 0.3s;
}

.date-picker:before {
    content: '';
    position: absolute;
    top: -7px;
    left: 30px;
    width: 10px;
    height: 10px;
    background-color: #fff;
    transform: rotate(45deg);
    border: 1px solid #e5e5e5;
    border-bottom: none;
    border-right: none;
}

.date-picker.top:before {
    bottom: -7px;
    top: auto;
    transform: rotate(-135deg);
}

.date-picker > .ym-select {
    height: 36px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    justify-content: space-between;
    padding: 0 16px;
}

.date-picker > .ym-select > .year,
.date-picker > .ym-select > .month {
    display: flex;
}

.date-picker > .ym-select button {
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    background-repeat: no-repeat;
    height: 100%;
    padding: 0;
}

.last-year-btn,
.last-month-btn {
    background-image: url('../../../../../img/date/left.png');
    width: 24px;
    background-position: 0 center;
}

.next-year-btn,
.next-month-btn {
    background-image: url('../../../../../img/date/right.png');
    width: 24px;
    background-position: 20px center;
}

.ym-container {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.date-picker > .ym-select .ym-container > input {
    height: 30px;
    border: none;
    font-size: 14px;
    color: #000;
    text-align: center;
    font-family: 'Microsoft YaHei', serif;
}

.year-input {
    width: 37px;
}

.month-input {
    width: 20px;
}

.date-picker > .ym-select > .ym-container > input:focus {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}

.date-picker > .date-select-container {
    font-size: 12px;
    padding: 0 5px 5px;
}

.date-picker > .date-select-container > table {
    width: 100%;
}

.date-picker > .date-select-container > table thead td {
    text-align: center;
    color: #000;
    padding: 10px 0 16px 0;
}

.date-picker > .date-select-container > table >>> tbody > tr > td {
    cursor: pointer;
    font-size: 12px;
    vertical-align: middle;
    text-align: center;
}

.date-picker > .date-select-container > table >>> tbody > tr > td > .text {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    transition: all ease-out 0.3s;
    border-radius: 50%;
}

.date-picker > .date-select-container > table >>> tbody > tr > td > .icon::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    background-color: #666666;
    border-radius: 50%;
    margin: auto;
}

.date-picker > .date-select-container > table >>> tbody > tr > td.other-month-days {
    color: rgba(0, 0, 0, 0.5);
}

.date-picker > .date-select-container > table >>> tbody > tr > td.other-range-days {
    color: rgba(0, 0, 0, 0.5);
}

.date-picker > .date-select-container > table >>> tbody > tr > td.today > .text {
    background-color: #d6d6d6;
    border-radius: 50%;
    color: #007aff;
}

.date-picker > .date-select-container > table >>> tbody > tr > td.lastSelectedDay > .text {
    border-radius: 50%;
    border: 2px solid #007aff;
}

.date-picker > .date-select-container > table >>> tbody > tr > td.active > .text {
    background-color: #007aff;
    border-radius: 50%;
    color: #fff;
}

.date-picker > .date-select-container > table >>> tbody > tr > td:hover > .text {
    background-color: #007aff;
    color: #fff;
}

.date-picker > .date-select-container > table >>> tbody > tr > td.other-range-days:hover > .text {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.5);
    cursor: default;
}

.date-picker > .date-select-container > table >>> tbody > tr > td.not-allowed:hover > .text {
    background-color: transparent;
    color: rgba(0, 0, 0);
    cursor: not-allowed;
}

.date-picker > .select-today {
    border-top: 1px solid rgba(217, 217, 217, 0.5);
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
}

.date-picker > .select-today > a {
    color: #007aff;
    font-size: 12px;
    text-decoration: none;
}

.confirm-btn,
.clean-btn {
    height: 24px;
    width: 52px;
    background-color: #007aff;
    color: #fff;
    border: 1px solid #d9d9d9;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}

.clean-btn {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.65);
}

.btn-container {
    display: flex;
    align-items: center;
}

.hidden-today-btn {
    visibility: hidden;
}

.time-container {
    height: 38px;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(217, 217, 217, 0.5);
    justify-content: center;

    > .time {
        height: 24px;
        display: flex;
        align-items: center;
        overflow: hidden;
        background-color: #ffffff;

        ::v-deep {
            > .time-hour {
                > input {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }

            > .date-picker-number-input:last-child {
                > .date-picker-number-input-switch {
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                }
            }

            > .time-minute::before,
            .time-second::before {
                content: ':';
                display: block;
                position: absolute;
                left: 2px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.65);
            }
        }
    }
}
</style>
