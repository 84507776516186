<template>
    <div class="modal" :class="classObject" :id="options.id" v-observe-size="sizeChangeHandle">
        <drag-resize
            class="modal-wrapper"
            :options="dragOptions"
            ref="wrapper"
            @dragBegin="dragBeginHandle"
            @dragStop="dragStopHandle"
            @resizeStop="resizeStopHandle"
            @ready="dragResizeReadyHandle"
        >
            <transition @before-leave="beforeLeaveHandle" @after-leave="afterLeaveHandle">
                <div v-if="visible" :class="modalContainerClass" ref="container">
                    <div :class="headerClass" v-if="!options.closeHeader" @mousedown="bodyMousedownHandle">
                        <div class="modal-title" :style="options.titleStyle">{{ propI18N(title) }}</div>
                        <div class="header-center" v-html="headerCenter"></div>
                        <div class="modal-toolbar" @touchstart.stop @mousedown.stop>
                            <button
                                class="page-edit"
                                v-if="pageEditBtnVisible"
                                :title="$i18n('modal.page.edit')"
                                @click="pageEditClickHandle"
                            >
                                <icon-edit />
                            </button>
                            <button
                                v-if="showMinBtn && !isMinimize && !isFullScreen"
                                class="min"
                                :title="$i18n('modal.min')"
                                @click="minHandle"
                            >
                                <icon-minimize />
                            </button>
                            <button
                                v-if="showMaxBtn && !isFullScreen && !isMinimize"
                                class="max"
                                :title="$i18n('modal.max')"
                                @click="max"
                            >
                                <icon-maximize />
                            </button>
                            <button
                                v-if="isFullScreen || isMinimize"
                                class="restore"
                                :title="$i18n('modal.restore')"
                                @click="restore"
                            >
                                <icon-recovery />
                            </button>
                            <button v-if="showCloseBtn" :title="$i18n('modal.close')" @click="close()">
                                <icon-close />
                            </button>
                        </div>
                    </div>
                    <div
                        class="modal-body"
                        :style="options.bodyStyle"
                        :comp-id="options.id"
                        @touchstart.stop
                        @mousedown.stop="bodyMousedownHandle"
                        @click.stop="modalClickHandle"
                    >
                        <slot name="modal-body"></slot>
                        <iframe v-if="options.src" :src="options.src" ref="iframe"></iframe>
                        <slot></slot>
                    </div>
                </div>
            </transition>
        </drag-resize>
    </div>
</template>

<script>
import Gikam from 'gikam';
import DragResize from '@/sunway/drag-resize/drag-resize';
import { MouseUtils } from '@/gikam/js/utils/mouseUtils';

export default {
    props: {
        options: Object
    },

    components: {
        DragResize
    },

    data() {
        return {
            isFullScreen: this.options.isFullScreen ?? false,
            showCloseBtn: this.options.showCloseBtn ?? true,
            showMaxBtn: this.options.showMaxBtn ?? true,
            showMinBtn: this.options.showMinBtn,
            headerCenter: '',
            isModal: this.options.isModal ?? true,
            moving: false,
            visible: true,
            lastDragOptions: null,
            dragOptions: {
                width: null,
                height: null,
                top: null,
                left: null,
                isActive: true,
                parentLimitation: true,
                parentW: 0,
                parentH: 0,
                isDraggable: true,
                isResizable: true
            },
            isMinimize: false,
            title: this.options.title,
            returnDataAfterClose: null
        };
    },

    computed: {
        classObject() {
            return [this.isModal ? 'modal' : 'not-modal', { moving: this.moving }];
        },

        pageEditBtnVisible() {
            return !this.isMinimize && Gikam?.loginUser?.isAdmin && this.options.url;
        },

        headerClass() {
            const classList = ['modal-header'];
            if (!this.isResizable || this.isFullScreen) {
                classList.push('modal-header-drag-prevent');
            }
            return classList;
        },

        modalContainerClass() {
            const classList = ['modal-container'];
            if (!this.options.animation) {
                classList.push('modal-container-no-animation');
            }
            return classList;
        }
    },

    created() {
        this.initModalPosition();
    },

    mounted() {
        this.initAnimation();
        this.initTransformOrigin();
    },

    methods: {
        modalClickHandle() {
            Gikam.generalGroupPanel && Gikam.generalGroupPanel.destroy();
        },

        async dragBeginHandle() {
            document.body.style.userSelect = 'none';
        },

        async dragStopHandle(param) {
            this.lastDragOptions = param;
            Object.assign(this.dragOptions, param);
            this.limitPosition();
            document.body.style.userSelect = '';
        },

        async resizeStopHandle(param) {
            this.lastDragOptions = param;
            Object.assign(this.dragOptions, param);
            this.limitPosition();
            this.$emit('modalResize');
        },

        setHeaderCenterDom(htmlString) {
            this.headerCenter = htmlString;
        },

        async close(data) {
            const result = this.options.modal?.trigger('beforeClose', data);
            const closeAction = () => {
                this.returnDataAfterClose = data;
                this.visible = false;
            };
            if (result !== false) {
                if (result === undefined || result === true) {
                    closeAction();
                } else {
                    result.done(res => {
                        res === true && closeAction();
                    });
                }
            }
        },

        propI18N(text) {
            return Gikam.propI18N(text);
        },

        addAnimation() {
            if (!this.options.animation) {
                return;
            }
            const wrapper = this.$refs.wrapper.$el;
            wrapper.style.transition = 'all 0.2s cubic-bezier(0.4, 0, 1, 1)';
            getComputedStyle(wrapper).width;
        },

        initAnimation() {
            const wrapper = this.$refs.wrapper.$el;
            wrapper.addEventListener('transitionend', () => {
                wrapper.style.transition = '';
            });
        },

        async max() {
            this.lastDragOptions = Gikam.deepExtend(this.dragOptions);
            Object.assign(this.dragOptions, {
                left: 0,
                top: 0,
                width: document.body.offsetWidth,
                height: document.body.offsetHeight,
                isDraggable: false,
                isResizable: false
            });
            this.isFullScreen = true;
            this.addAnimation();
            this.$emit('modalResize');
        },

        restore() {
            Object.assign(this.dragOptions, this.lastDragOptions);
            this.isFullScreen = false;
            this.isMinimize = false;
            this.dragOptions.isActive = true;
            this.dragOptions.isDraggable = true;
            this.dragOptions.isResizable = true;
            this.addAnimation();
            this.$emit('modalResize');
        },

        initModalPosition() {
            if (this.isMinimize) {
                this.dragOptions = this.getMinDragOptions();
                return;
            }
            this.setDragOptions(this.dragOptions, this.isFullScreen);
            if (this.isFullScreen) {
                this.setDragOptions(this.lastDragOptions, false);
            } else {
                this.lastDragOptions = Gikam.deepExtend(this.dragOptions);
            }
        },

        setDragOptions(options, flag) {
            const bodyPosition = document.body.getBoundingClientRect();
            let { width, height } = this.options;
            width = flag ? '100%' : width || '80%';
            height = flag ? '100%' : height || '80%';
            options.width = this.formatterSize(width, bodyPosition.width);
            options.height = this.formatterSize(height, bodyPosition.height);
            if (Gikam.isEmpty(options.left)) {
                options.left = (bodyPosition.width - this.dragOptions.width) / 2;
            }
            if (Gikam.isEmpty(options.top)) {
                options.top = (bodyPosition.height - this.dragOptions.height) / 2;
            }
            options.parentW = bodyPosition.width;
            options.parentH = bodyPosition.height;
        },

        formatterSize(size, consult) {
            return Gikam.isString(size) && size.includes('%') ? consult * (parseFloat(size) / 100) : parseFloat(size);
        },

        limitPosition() {
            const { top, left, width } = this.dragOptions;
            const { offsetHeight: bodyHeight, offsetWidth: bodyWidth } = document.body;
            if (top < 0) {
                this.addAnimation();
                this.lastDragOptions.top = 0;
                this.dragOptions.top = 0;
            }
            if (top > bodyHeight - 50) {
                this.addAnimation();
                this.lastDragOptions.top = bodyHeight - 50;
                this.dragOptions.top = bodyHeight - 50;
            }
            if (left < -width + 100) {
                this.addAnimation();
                this.lastDragOptions.left = 100 - width;
                this.dragOptions.left = 100 - width;
            }
            if (left > bodyWidth - 100) {
                this.addAnimation();
                this.lastDragOptions.left = bodyWidth - 100;
                this.dragOptions.left = bodyWidth - 100;
            }
        },

        sizeChangeHandle() {
            this.addAnimation();
            this.initModalPosition();
            this.$emit('modalResize');
        },

        setSize(width, height, left, top) {
            Object.assign(this.options, { width, height });
            this.dragOptions.left = left ?? this.dragOptions.left;
            this.dragOptions.top = top ?? this.dragOptions.top;
            this.addAnimation();
            this.initModalPosition();
        },

        getIframeDom() {
            if (this.options.src) {
                return this.$refs.iframe;
            }
        },

        minHandle() {
            Object.assign(this.dragOptions, this.getMinDragOptions(), { isDraggable: false, isResizable: false });
            this.isMinimize = true;
            this.addAnimation();
            this.options.modal.trigger('minBtnClick');
            this.dragOptions.isDraggable = false;
            this.dragOptions.isResizable = false;
            this.$emit('modalResize');
        },

        getMinDragOptions() {
            const modalDom = document.querySelectorAll('.modal');
            let index = 0;
            for (let i = 0; i < modalDom.length; i++) {
                if (modalDom[i] === this.$el) {
                    index = i;
                    break;
                }
            }
            return {
                width: 250,
                height: 30,
                left: document.body.clientWidth - 250,
                top: document.body.clientHeight - 30 * (index + 1)
            };
        },

        dragResizeReadyHandle() {
            this.$emit('ready');
        },

        bodyMousedownHandle() {
            Gikam.simulatedEvent(document, 'mousedown');
        },

        setTitle(title) {
            this.title = title;
        },

        initTransformOrigin() {
            const mousePosition = MouseUtils.getMousePosition();
            if (mousePosition) {
                const offsetX = mousePosition.x - this.dragOptions.left;
                const offsetY = mousePosition.y - this.dragOptions.top;
                this.$refs.container.style.transformOrigin = `${offsetX}px ${offsetY}px`;
            }
        },

        beforeLeaveHandle() {
            this.$refs.container.classList.add('zoom-out');
        },

        afterLeaveHandle() {
            Gikam.simulatedEvent(document, 'mousedown');
            Gikam.removeDom(this.$el);
            this.options.modal?.trigger('afterClose', this.returnDataAfterClose);
            this.$emit('close');
            this.$destroy();
        },

        pageEditClickHandle() {
            this.$emit('pageEditClick');
        }
    }
};
</script>

<style scoped lang="scss">
@import '@/gikam/css/gikam-animation.scss';

@mixin modal-container() {
    .modal-container {
        position: relative;
        border-radius: 4px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
        height: 100%;
        animation: zoomIn 0.2s;

        &.modal-container-no-animation {
            animation: none !important;
        }

        &.zoom-out {
            animation: zoomOut 0.2s;
        }

        > .modal-header {
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 14px 0 16px;
            font-size: 14px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: bold;
            cursor: all-scroll;

            &-drag-prevent {
                cursor: unset;
            }

            > div {
                z-index: 2;
            }

            > .modal-title {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            button {
                background-color: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                color: #999;
                font-size: 14px;
                display: flex;
                align-items: center;

                &.page-edit {
                    font-size: 18px;

                    ::v-deep path {
                        fill: #999;
                    }
                }
            }

            > .modal-toolbar {
                display: flex;
                align-items: center;
            }

            .header-center {
                width: 100%;
                height: 32px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);
                position: absolute;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 0;
            }
        }

        > .modal-body {
            flex: 1;
            overflow: hidden;
            height: 0;
            position: relative;

            > iframe {
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    animation: fadeIn 0.2s;

    &.not-modal {
        width: 0;
        height: 0;
    }

    ::v-deep > .vdr {
        > .vdr-stick {
            opacity: 0;
        }

        &.active::before {
            outline: none;
        }
    }

    @include modal-container();
}
</style>
