import "core-js/modules/es.number.constructor.js";
//
//
//
//
export default {
  props: {
    options: Object,
    propValue: [String, Number]
  },
  methods: {
    changeHandle: function changeHandle(fieldName, value) {
      this.$emit('change', {
        value: value,
        match: 'CISC'
      });
    },
    inputHandle: function inputHandle(event) {
      var value = event.target.value;
      this.$emit('input', {
        value: value,
        match: 'CISC'
      });
    }
  }
};