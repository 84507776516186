<template>
    <select-field :options="filterOptions" :propValue="propValue" @change="changeHandle"></select-field>
</template>

<script>
import CheckboxGroupHelper from '@/gikam/js/core/checkboxGroupHelper';
import Gikam from 'gikam';

export default {
    props: {
        options: Object,
        propValue: [String, Number]
    },

    data() {
        return {
            filterOptions: Gikam.deepExtend(this.options, {
                multiple: true,
                search: true
            })
        };
    },

    async created() {
        await this.initItems();
    },

    methods: {
        async initItems() {
            const { items } = await CheckboxGroupHelper.getItems(this.filterOptions);
            this.$set(this.filterOptions, 'items', items);
        },

        changeHandle(fieldName, value, rowIndex, oldValue, text) {
            this.$emit('change', {
                value,
                text,
                match: 'CBM'
            });
        }
    }
};
</script>
