<template>
    <div class="form-checkbox-group">
        <checkbox-group
            ref="editor"
            :options="checkboxGroupOptions"
            :dataId="dataId"
            :validateArg="validateArg"
            @change="changeHandle"
        >
        </checkbox-group>
    </div>
</template>

<script>
import checkboxGroup from '@/gikam/js/components/template/checkbox/checkboxGroup.vue';
import { mapState } from 'vuex';

export default {
    props: {
        options: Object,
        propValue: [Number, String],
        validateArg: Object,
        dataId: [Number, String]
    },

    components: {
        checkboxGroup
    },

    data() {
        return {
            checkboxGroupOptions: this.options
        };
    },

    computed: {
        ...mapState(['form'])
    },

    mounted() {
        this.form.editorManager[this.options.field] = this.$refs.editor;
    },

    watch: {
        propValue: {
            immediate: true,
            handler(value) {
                this.$set(this.checkboxGroupOptions, 'value', value);
            }
        },

        'options.category': {
            handler(val) {
                this.$set(this.checkboxGroupOptions, 'category', val);
            }
        }
    },

    methods: {
        changeHandle(...args) {
            this.$emit.apply(this, ['change', ...args]);
        }
    }
};
</script>

<style scoped lang="scss">
.form-checkbox-group {
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}
</style>
