import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Gikam from 'gikam';
import node from './node.vue';
export default {
  props: {
    options: Object
  },
  inject: ['tree'],
  data: function data() {
    return {
      nodeList: this.options.data,
      checkbox: this.options.checkbox,
      showInsertButton: false,
      id: this.options.id,
      isLastNodes: [],
      dragType: null // 往上滑动还是往下滑动

    };
  },
  watch: {
    'options.data': function optionsData(value) {
      this.nodeList = value;
    }
  },
  components: {
    node: node
  },
  computed: {
    name: function name() {
      return Gikam.propI18N(this.options.name);
    }
  },
  methods: {
    mouseenterHandle: function mouseenterHandle() {
      this.showInsertButton = true;
    },
    mouseleaveHandle: function mouseleaveHandle() {
      this.showInsertButton = false;
    },
    insertRootNode: function insertRootNode() {
      if (this.tree.editing) {
        return;
      }

      var newNode = {
        text: '新建节点',
        id: null,
        editable: true,
        toolbar: false,
        value: '',
        childQty: 0
      };
      this.nodeList.push(newNode);
      this.tree.editing = true;
    },
    headClick: function headClick() {
      this.$emit('headClick');
    },
    // 拖动事件，向上/向下 滚动。
    allowDrop: function allowDrop(type) {
      this.dragType = type;

      if (type === 'top') {
        this.$refs['vs'] && this.$refs['vs'].scrollBy({
          dy: -100
        }, 300);
      }

      if (type === 'bottom') {
        this.$refs['vs'] && this.$refs['vs'].scrollBy({
          dy: 100
        }, 300);
      }
    },
    dragLeave: function dragLeave() {
      this.dragType = null;
    }
  }
};