//
//
//
//
//
//
import btn from '@/gikam/js/components/button/vue/button.vue';
export default {
  props: {
    options: Object,
    window: Object
  },
  components: {
    btn: btn
  }
};