import _objectSpread from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import timeInput from '@/gikam/js/components/template/fields/time/timeInput.vue';
import { mapState } from 'vuex';
export default {
  props: {
    options: Object,
    propValue: String,
    dataId: [Number, String],
    validateArg: Object,
    propInvisible: Boolean
  },
  components: {
    timeInput: timeInput
  },
  data: function data() {
    var _this$options$readonl;

    return {
      readonly: (_this$options$readonl = this.options.readonly) !== null && _this$options$readonl !== void 0 ? _this$options$readonl : false
    };
  },
  computed: _objectSpread({}, mapState(['form'])),
  mounted: function mounted() {
    this.form.editorManager[this.options.field] = this.$refs.editor;
  },
  methods: {
    changeHandle: function changeHandle() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this.$emit.apply(this, ['change'].concat(args));
    },
    dblclickHandle: function dblclickHandle() {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      this.$emit.apply(this, ['dblclick'].concat(args));
    },
    clickHandle: function clickHandle() {
      for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }

      this.$emit.apply(this, ['click'].concat(args));
    }
  }
};