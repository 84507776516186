<template>
    <div class="body">
        <div
            class="body-col"
            v-for="(col, colIndex) in leafColumns"
            :key="col | getColKey(columnMergeMap)"
            :style="col.field | getWidth(fieldWidthMap, colIndex)"
        >
            <template v-for="(row, rowIndex) in data">
                <body-cell
                    class="body-row"
                    :key="row.id || rowIndex"
                    @click="changeActiveIndex({ rowIndex, colIndex, type: 'row' })"
                    :class="[
                        {
                            activeRow: activeRowIndex === rowIndex && colIndex === 0,
                            couldActiveCell: activeCell,
                            activeField: rowIndex + '_' + colIndex === currentCell
                        }
                    ]"
                    :propsFeild="col"
                    :propsRow="row"
                    :rowIndex="rowIndex"
                    :colIndex="colIndex"
                    :ref="rowIndex + '_' + colIndex"
                    v-cell-merge:[{rowIndex,col}]="cellMerge"
                ></body-cell>
            </template>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import bodyCell from './bodyCell';
export default {
    name: 'sunway-body',
    computed: {
        ...mapGetters(['leafColumns', 'activeCell']),
        ...mapState(['data', 'fieldWidthMap']),
        ...mapState('columnsMerge', ['columnMergeMap']),
        ...mapState('gridBody/', ['activeRowIndex']),
        ...mapGetters('moveCursor/', ['currentCell'])
    },

    inject: ['triggerEvent'],

    filters: {
        getWidth(field, fieldWidthMap) {
            const width = fieldWidthMap[field];
            return {
                width: width + 'px'
            };
        },
        getColKey(col, columnMergeMap, colIndex) {
            return columnMergeMap[col.field] && columnMergeMap[col.field].time
                ? columnMergeMap[col.field].time + colIndex
                : col.feild || colIndex;
        }
    },

    methods: {
        ...mapMutations('gridBody/', ['changeActiveRowIndex']),
        ...mapMutations('selectRow/', ['changeCheckAll']),

        clickRowEvent(col, row, rowIndex, colIndex = 0) {
            this.changeActiveRowIndex({ rowIndex, colIndex, type: 'row' });
        },

        getCell(rowIndex, colIndex) {
            const _ref = rowIndex + '_' + colIndex;
            return this.$refs[_ref][0] ? this.$refs[_ref][0].getCell() : null;
        },

        cellMerge({ rowIndex, col }) {
            const map = this.columnMergeMap[col.field];
            if (map) {
                const _map = map[rowIndex];
                if (_map) {
                    return {
                        isMerge: true,
                        hasTopBorder: true,
                        height: _map.len
                    };
                } else {
                    return {
                        isMerge: true,
                        hasTopBorder: false
                    };
                }
            } else {
                return { isMerge: false };
            }
        }
    },

    watch: {
        activeRowIndex(val) {
            const rowData = this.data[val];
            this.triggerEvent('rowActive', val, rowData);
        },

        data(val) {
            const selectLength = val.filter(row => row.isSelect === true).length;
            if (selectLength === val.length) {
                return this.changeCheckAll(true);
            } else {
                return this.changeCheckAll(false);
            }
        }
    },

    components: {
        bodyCell: bodyCell
    },

    directives: {
        'cell-merge': {
            bind: function(el, bind, vnode) {
                const { isMerge, hasTopBorder, height } = vnode.context[bind.expression](bind.arg);
                //不合并直接返回
                if (isMerge === false) {
                    return;
                }
                //相同值得单元格去除值及边框
                if (hasTopBorder === false) {
                    el.style['border-top'] = 'none';
                    el.style.height = 0;
                    el.style['minHeight'] = 0;
                    el.classList.value += ' isEmpty';
                    el.innerHTML = '';
                    return;
                }
                //需要定位的单元格 去除内容
                if (height !== 1) {
                    el.style.height = height * 31 - 1 + 'px';
                    el.style['lineHeight'] = height * 31 - 1 + 'px';
                    el.firstChild.classList.value += ' marge';
                }
            },
            update: function(el, bind, vnode) {
                const { isMerge, hasTopBorder, height } = vnode.context[bind.expression](bind.arg);
                //不合并直接返回
                if (isMerge === false) {
                    return;
                }
                //相同值得单元格去除值及边框
                if (hasTopBorder === false) {
                    el.style['border-top'] = 'none';
                    el.style.height = 0;
                    el.style['minHeight'] = 0;
                    el.classList.value += ' isEmpty';
                    el.innerHTML = '';
                    return;
                }
                //需要定位的单元格 去除内容
                if (height !== 1) {
                    el.style.height = height * 31 - 1 + 'px';
                    el.style['lineHeight'] = height * 31 - 1 + 'px';
                    el.firstChild.classList.value += ' marge';
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
.body {
    display: flex;
    align-items: flex-start;
}
.body-col {
    display: inline-block;
    position: relative;
}

.body-col::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    border-right: 1px solid #d9d9d9;
}

.body-col:first-child {
    border-left: 1px solid #d9d9d9;
}

.body-row {
    border-top: 1px solid #d9d9d9;
    box-sizing: content-box;

    ::v-deep {
        .number-input {
            background: transparent;
            > input {
                border-radius: 4px;
                height: 90%;
                margin-right: 5px;
                color: rgba(0, 0, 0, 85);
                background: transparent;
            }
        }
    }
}

.body-row:last-child {
    border-bottom: 1px solid #d9d9d9;
}

.body-row.activeRow {
    background-color: rgba(0, 122, 255, 0.16);
}

.body-row.couldActiveCell.activeField {
    border: 1px solid #007aff;
    background-color: rgba(0, 122, 255, 0.16);

    z-index: 10;

    ::v-deep {
        .editor.number-input {
            > input {
                background: #fff;
            }
        }
    }
}
</style>
