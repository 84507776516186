class MouseUtils {
    static mousePosition = null;

    static getMousePosition() {
        return this.mousePosition;
    }
}

if (window !== undefined && window.document && window.document.documentElement) {
    document.documentElement.addEventListener(
        'click',
        e => {
            MouseUtils.mousePosition = { x: e.pageX, y: e.pageY };
            setTimeout(() => {
                MouseUtils.mousePosition = null;
            }, 300);
        },
        true
    );
}

export { MouseUtils };
