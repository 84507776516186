import { getFieldRelationshipMap, initColumnMergeMap } from '../utils';

export function columnsMergeStore() {
    return {
        namespaced: true,
        state: {
            columnGroup: null,
            fieldRelationshipMap: {},
            columnMergeMap: {}
        },
        mutations: {
            changeColumnGroup(state, columnGroup) {
                state.columnGroup = columnGroup;
                columnGroup && (state.fieldRelationshipMap = getFieldRelationshipMap(columnGroup));
            },

            initColumnMergeMap(state, data) {
                state.columnMergeMap = initColumnMergeMap(state.fieldRelationshipMap, data);
            }
        }
    };
}
