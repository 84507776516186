import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    propModelValue: [String, Number],
    max: String
  },
  data: function data() {
    var _this$propModelValue, _this$propModelValue2;

    return {
      baseClass: 'date-picker-number-input',
      value: (_this$propModelValue = this.propModelValue) !== null && _this$propModelValue !== void 0 ? _this$propModelValue : '00',
      inputValue: (_this$propModelValue2 = this.propModelValue) !== null && _this$propModelValue2 !== void 0 ? _this$propModelValue2 : ''
    };
  },
  model: {
    event: 'valueInput',
    prop: 'propModelValue'
  },
  computed: {
    classList: function classList() {
      var classList = [this.baseClass];
      return classList;
    },
    switchClass: function switchClass() {
      return "".concat(this.baseClass, "-switch");
    },
    switchSplitClass: function switchSplitClass() {
      var classList = ["".concat(this.switchClass, "-split")];
      return classList;
    },
    switchUpClass: function switchUpClass() {
      var classList = ["".concat(this.switchClass, "-arrow"), "".concat(this.switchClass, "-up")];
      return classList;
    },
    switchDownClass: function switchDownClass() {
      var classList = ["".concat(this.switchClass, "-arrow"), "".concat(this.switchClass, "-down")];
      return classList;
    }
  },
  watch: {
    value: function value(_value) {
      var numberValue = parseInt(_value);
      var numberMax = parseInt(this.max);

      var valueLength = _value.toString().length;

      if (isNaN(numberValue)) {
        this.value = '00';
        return;
      }

      if (numberValue < 0) {
        this.value = 0;
        return;
      }

      if (numberValue > numberMax) {
        this.value = numberMax;
        return;
      }

      if (numberValue < 10 && valueLength !== 2) {
        this.value = "0".concat(numberValue);
        return;
      }

      if (numberValue >= 10 && valueLength !== 2) {
        this.value = numberValue;
        return;
      }

      this.$emit('valueInput', _value);
    }
  },
  methods: {
    upMouseDownHandle: function upMouseDownHandle() {
      var _this = this;

      this.value = parseInt(this.value) + 1;
      this.interval = setInterval(function () {
        _this.value = parseInt(_this.value) + 1;
      }, 300);
    },
    downMouseDownHandle: function downMouseDownHandle() {
      var _this2 = this;

      this.value = parseInt(this.value) - 1;
      this.interval = setInterval(function () {
        _this2.value = parseInt(_this2.value) - 1;
      }, 300);
    },
    mouseupHandle: function mouseupHandle() {
      this.interval && clearInterval(this.interval);
    },
    mouseleaveHandle: function mouseleaveHandle() {
      this.mouseupHandle();
    },
    inputHandle: function inputHandle(e) {
      var value = e.target.value.replace(/[^0-9]/g, '');

      if (/[0-9]/.test(e.data)) {
        this.inputValue = value.length > 2 ? this.inputValue + e.data : value;
      } else if (e.inputType === 'deleteContentBackward') {
        this.inputValue = this.inputValue.length > 1 ? value : '';
      }

      if (this.inputValue.length >= 2) {
        this.$emit('input');
      }
    },
    selectText: function selectText() {
      this.$refs.input.select();
    }
  }
};