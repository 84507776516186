<template>
    <div
        class="combo-box-picker"
        :style="{ top: top + 'px', left: left + 'px', width: width + 'px', height: height }"
        @mousedown.stop
        @mousewheel.stop
        @DOMMouseScroll.stop
    >
        <scroll :distance="30" :innerPanel="true" :scrollingX="false" ref="scroll">
            <ul>
                <li
                    v-for="(item, index) in propItems"
                    :key="item.value + getNextTime()"
                    @click.stop="clickHandle(item)"
                    :class="[
                        { 'active-value': activeRow === index },
                        {
                            'current-value': item.text + '' === value
                        }
                    ]"
                    :ref="item.value"
                >
                    <checkbox :propChecked="item.checked" class="checkbox" v-if="propMultiple" />
                    {{ item.text }}
                </li>
            </ul>
        </scroll>
    </div>
</template>

<script>
import Gikam from 'gikam';
import checkbox from '../../checkbox/checkbox';
import { Sequence } from '@/gikam/js/core/sequence';

export default {
    components: { checkbox },

    props: {
        propItems: Array,
        propTop: Number,
        propLeft: Number,
        propWidth: Number,
        propMultiple: Boolean,
        propValue: String,
        propDelimiter: String
    },

    computed: {
        height() {
            return this.items.length * 32 + 2 + 'px';
        }
    },

    inject: {
        combobox: {
            default: null
        }
    },

    created() {
        this.initCheckStatus();
    },

    mounted() {
        Gikam.comboboxPicker = this;
        const item = this.$refs[this.value];
        if (item) {
            this.$refs['scroll'].scrollTo({
                y: item[0].offsetTop - 3 * item[0].offsetHeight
            });
        }

        this.propItems.forEach((item, index) => {
            if (this.value && this.value === item.text + '') {
                this.activeRow = index;
            }
        });

        document.addEventListener('keydown', this.keydownHandle);
    },

    watch: {
        propItems(value) {
            this.items = Gikam.isEmpty(value) ? [{ text: '', value: '' }] : value;
        },

        propTop(top) {
            this.top = top;
        }
    },

    data() {
        return {
            items: this.propItems,
            top: this.propTop,
            left: this.propLeft,
            width: this.propWidth,
            value: this.propValue,
            activeRow: 0,
            delimiter: this.propDelimiter
        };
    },

    beforeDestroy() {
        document.removeEventListener('keydown', this.keydownHandle);
    },

    methods: {
        initCheckStatus() {
            let value = Gikam.isNotEmpty(this.combobox.value) ? this.combobox.value.split(this.delimiter) : [];
            this.propItems.forEach(item => {
                if (value.indexOf(item.text) > -1) {
                    item.checked = true;
                } else {
                    item.checked = false;
                }
            });
        },

        clickHandle(item) {
            if (this.propMultiple) {
                item.checked = !item.checked;
                this.$forceUpdate();
                let value = Gikam.isNotEmpty(this.combobox.value) ? this.combobox.value.split(this.delimiter) : [];
                if (item.checked) {
                    if (value.length === 1 && value[0] === item.text) {
                        return;
                    } else {
                        value.push(item.text);
                        this.combobox.value = value.join(this.delimiter);
                    }
                } else {
                    this.combobox.value = value.filter(text => text !== item.text).join(this.delimiter);
                }
            } else {
                this.combobox.value = item.text;
                this.combobox.cleanPicker();
            }
        },

        keydownHandle(e) {
            if (e.keyCode === 13) {
                this.combobox.value = this.propItems[this.activeRow].text;
                this.combobox.cleanPicker();
            } else if (e.keyCode === 38) {
                this.activeRow = this.activeRow > 0 ? this.activeRow - 1 : 0;
            } else if (e.keyCode === 40) {
                this.activeRow =
                    this.activeRow < this.propItems.length - 1 ? this.activeRow + 1 : this.propItems.length - 1;
            }
            if (this.propItems.length > 0) {
                const item = this.$refs[this.propItems[this.activeRow].value];
                if (item) {
                    this.$refs['scroll'].scrollTo({
                        y: item[0].offsetTop - 3 * item[0].offsetHeight
                    });
                }
            }
        },

        getNextTime() {
            return Sequence.nextWithTime().toString();
        }
    }
};
</script>

<style scoped>
@keyframes slide-bottom {
    from {
        transform: translate3d(0, 20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes slide-top {
    from {
        transform: translate3d(0, -20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
.combo-box-picker {
    position: absolute;
    z-index: 11;
    margin-top: 1px;
    animation: slide-bottom 0.2s;
    max-height: 200px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.08);
    overflow: hidden;
}

.combo-box-picker ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: white;
    min-height: 32px;
}

.combo-box-picker ul li {
    height: 32px;
    line-height: 32px;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    padding-left: 10px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.combo-box-picker ul li.current-value {
    color: #000;
    background-color: #eeeeee;
}

.combo-box-picker ul li.active-value {
    background-color: rgba(0, 122, 255, 0.08);
}

.combo-box-picker ul li .checkbox {
    display: inline-block;
    vertical-align: text-bottom;
}

.combo-box-picker ul li.current-value {
    color: #000;
}

.combo-box-picker ul li:hover {
    background-color: rgba(0, 122, 255, 0.08);
}
</style>
