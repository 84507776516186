import Gikam from 'gikam';

export class CellOperation {
    /**
     * @description 右键复制
     * @param {*} _this
     * @memberof CellOperation
     */

    // eslint-disable-next-line complexity
    static copy(_this) {
        if (_this.selectionAll) {
            _this.copyData = _this.selectionAll;
            _this.selectionAll = '';
            return;
        }
        if (_this.selection) {
            _this.copyData = _this.selection;
        } else {
            Gikam.alert('请选择要复制文本!');
        }
    }

    /**
     * @description 右键全选
     * @param {*} _this
     * @param {*} ele
     * @memberof CellOperation
     */

    static selectAll(_this, ele) {
        const selection = window.getSelection();
        _this.range.selectNode(ele);
        selection.removeAllRanges();
        selection.addRange(_this.range);
        _this.selectionAll = ele.value || ele.innerText;
    }

    /**
     * @description 右键粘贴
     * @param {*} _this
     * @param {*} ele
     * @param {*} start
     * @param {*} end
     * @memberof CellOperation
     */

    static paste(_this, ele, start, end) {
        ele.value = ele.value.slice(0, start) + _this.copyData + ele.value.slice(end);
    }
}
