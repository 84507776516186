import Vue from 'vue';
import Gikam from 'gikam';
import { Sequence } from '@/gikam/js/core/sequence';

//焦点没有在选中的grid上的上下键让其失效 防止表单中大文本上下键与grid的冲突
function ignoreKeyUpDown() {
    return document.activeElement.closest('.grid') === top.Gikam.activeGrid.model.$el ||
        document.activeElement.nodeName === 'BODY'
        ? false
        : true;
}

// 上下键切换激活行
document.addEventListener(
    'keydown',
    // eslint-disable-next-line complexity
    function(e) {
        if (e.keyCode === 9 && e.target.tagName === 'BODY' && top.Gikam) {
            e.preventDefault();
            return;
        }
        if ([38, 40].indexOf(e.keyCode) >= 0) {
            if (
                !top.Gikam ||
                !top.Gikam.activeGrid ||
                !top.Gikam.activeGrid.options.enableKeySwitchEditor ||
                ignoreKeyUpDown()
            ) {
                return;
            }
            e.preventDefault();
            const grid = top.Gikam.activeGrid;
            const maxLength = grid.getData().length;
            if (maxLength <= 0) {
                return;
            }
            const activeRow = grid.getActivedRow();
            if (maxLength === 1 && activeRow) {
                return;
            }
            if (!activeRow) {
                grid.activeRowByIndex(0);
                return;
            }
            const currentIndex = GridUtils.getRowVueState(activeRow, 'rowIndex');
            if ((currentIndex === 0 && e.keyCode === 38) || (currentIndex === maxLength - 1 && e.keyCode === 40)) {
                return;
            }
            let rowIndex;
            if (e.keyCode === 38) {
                rowIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : 0;
            } else if (e.keyCode === 40) {
                rowIndex = currentIndex + 1 < maxLength ? currentIndex + 1 : maxLength - 1;
            }
            grid.activeRowByIndex(rowIndex);
        }
    },
    true
);

/**
 * @description Grid工具类
 * @export
 * @class GridUtils
 */
export class GridUtils {
    /**
     * @description 将行相关的vue状态封装到vueState属性中
     * @static
     * @param {*} rowData
     * @param {*} state
     * @param {*} value
     * @memberof GridUtils
     */
    static setRowVueState(rowData, state, value) {
        Vue.set(rowData._vueState, state, value);
    }

    /**
     * @description 将行相关的vue状态封装到vueState属性中
     * @static
     * @param {*} rowData
     * @param {*} state
     * @param {*} value
     * @memberof GridUtils
     */
    static getRowVueState(rowData, state) {
        if (!rowData || !rowData._vueState) {
            return null;
        }
        return rowData._vueState[state];
    }

    static removeAllRowVueState(rowData) {
        if (rowData._vueState) {
            delete rowData._vueState;
        }
    }

    static initRowVueState(rowData, key, grid) {
        Vue.set(rowData, '_vueState', { active: false, checked: false });
        if (grid.options.checkContinuous && grid.dataMapper) {
            const lastRowData = grid.dataMapper[key];
            if (lastRowData) {
                const checked = this.getRowVueState(lastRowData, 'checked');
                if (checked) {
                    rowData._vueState.checked = true;
                }
            }
        }
    }

    static copyRowVueState(toRowData, formRowData) {
        toRowData._vueState = Gikam.deepExtend(formRowData._vueState);
        toRowData.gridIndex = formRowData.gridIndex;
    }

    static validateSameRow(formRowData, toRowData) {
        const checkedRowKey = this.getRowVueState(formRowData, 'key');
        const key = this.getRowVueState(toRowData, 'key');
        return key === checkedRowKey;
    }

    static getTreeNodeCheckedState(rowData) {
        const treeNodeChecked = this.getRowVueState(rowData, 'treeNodeChecked');
        const treeNodeHalfChecked = this.getRowVueState(rowData, 'treeNodeHalfChecked');
        if (treeNodeChecked && !treeNodeHalfChecked) {
            return 'checked';
        }
        if (treeNodeChecked && treeNodeHalfChecked) {
            return 'halfChecked';
        }
        if (!treeNodeChecked && !treeNodeHalfChecked) {
            return 'unChecked';
        }
    }

    static toggleTreeNodeChecked(rowData, checked, grid, param = { isSingleCheck: false }) {
        const key = this.getRowVueState(rowData, 'key');
        const treeCheckedRowKeys = grid.store.state.treeCheckedRowKeys;
        if (checked) {
            if (param.isSingleCheck && treeCheckedRowKeys.length) {
                this.toggleTreeNodeChecked(grid.dataMapper[treeCheckedRowKeys[0]], false, grid);
            }
            treeCheckedRowKeys.push(key);
            GridUtils.setRowVueState(rowData, 'treeNodeChecked', true);
            GridUtils.setRowVueState(rowData, 'treeNodeHalfChecked', false);
        } else {
            const index = treeCheckedRowKeys.indexOf(key);
            if (index > -1) {
                treeCheckedRowKeys.splice(index, 1);
            }
            GridUtils.setRowVueState(rowData, 'treeNodeChecked', false);
            GridUtils.setRowVueState(rowData, 'treeNodeHalfChecked', false);
        }
        grid.options.onNodeSelected?.call(grid, rowData, checked);
        grid.options.nodeSelected?.call(grid, rowData, checked);
    }

    static setTreeNodeHalfChecked(rowData) {
        GridUtils.setRowVueState(rowData, 'treeNodeChecked', true);
        GridUtils.setRowVueState(rowData, 'treeNodeHalfChecked', true);
    }

    static toggleNodeChecked(row, checked, grid, param = { isSingleCheck: false }) {
        this.toggleTreeNodeChecked(row, checked, grid, param);
        if (param.isSingleCheck || !grid.options.cascadeCheck) {
            return;
        }
        // 所有子节点勾选或者取消勾选
        if (Gikam.isNotEmpty(row.children)) {
            if (grid.options.cascadeChildrenCheck) {
                Gikam.eachTree(row.children, rowData => this.toggleTreeNodeChecked(rowData, checked, grid));
            }
        }
        // 级联父节点
        if (!Gikam.isFalse(grid.options.cascadeParentCheck)) {
            const parentKeys = GridUtils.getRowVueState(row, 'treeParentNodeKeys');
            if (Gikam.isNotEmpty(parentKeys)) {
                parentKeys.forEach(key => {
                    const parentRowData = grid.dataMapper[key];
                    let existsHalfChecked = false;
                    const checkedChildList = [];
                    for (const childRowData of parentRowData.children) {
                        const state = this.getTreeNodeCheckedState(childRowData);
                        if (state === 'halfChecked') {
                            existsHalfChecked = true;
                            break;
                        }
                        if (state === 'checked') {
                            checkedChildList.push(childRowData);
                        }
                    }
                    if (existsHalfChecked) {
                        this.setTreeNodeHalfChecked(parentRowData);
                        return;
                    }
                    if (Gikam.isEmpty(checkedChildList)) {
                        this.toggleTreeNodeChecked(parentRowData, false, grid);
                        return;
                    }
                    if (checkedChildList.length === parentRowData.children.length) {
                        this.toggleTreeNodeChecked(parentRowData, true, grid);
                        return;
                    }
                    if (checkedChildList.length < parentRowData.children.length) {
                        this.setTreeNodeHalfChecked(parentRowData, true);
                    }
                });
            }
        }
    }

    static createRowDataKey(rowData, grid) {
        let key = '';
        const dataMapper = grid.dataMapper;
        grid.options.dataKeyFields.forEach((field, index) => {
            if (index > 0) {
                key += '-';
            }
            key += Gikam.getFieldValue(rowData, field) ?? '';
        });
        // id可能不设置值
        if (Gikam.isEmpty(key)) {
            key = Sequence.nextWithTime();
        }
        // 同一页中，id可能会重复
        if (Gikam.isNotEmpty(dataMapper[key])) {
            // 选择连续时，必须有id值，允许重复
            if (grid.options.checkContinuous) {
                return key;
            }
            return Sequence.nextWithTime();
        }
        return key;
    }

    static initRowGroup(rowData, rowDataList, grid) {
        if (Gikam.isEmpty(grid.groupValueMapper)) {
            grid.groupValueMapper = {};
        }
        const fields = grid.options.group.fields;
        const value = fields.reduce((total, item) => (total += Gikam.getFieldValue(rowData, item) ?? ''), '');
        const key = this.getRowVueState(rowData, 'key');
        if (Gikam.isEmpty(grid.groupValueMapper[value])) {
            // 生成分组行
            const groupRowData = Gikam.deepExtend(rowData);
            const defaultGroupExpand = grid.options.group.expand;
            const groupRowKey = `${key}-group`;
            grid.groupValueMapper[value] = groupRowData;
            this.setRowVueState(groupRowData, 'key', groupRowKey);
            this.setRowVueState(groupRowData, 'rowType', 'group');
            this.setRowVueState(groupRowData, 'groupChildren', [key]);
            this.setRowVueState(groupRowData, 'groupExpand', defaultGroupExpand);
            this.setRowVueState(groupRowData, 'checked', false);
            this.setRowVueState(groupRowData, 'halfChecked', false);
            grid.dataMapper[`${key}-group`] = groupRowData;
            rowDataList.push(groupRowData);
        }
        const parentGroupRowData = grid.groupValueMapper[value];
        const groupParentKey = this.getRowVueState(parentGroupRowData, 'key');
        const groupParentExpand = this.getRowVueState(parentGroupRowData, 'groupExpand');
        this.setRowVueState(rowData, 'groupParentKey', groupParentKey);
        this.getRowVueState(parentGroupRowData, 'groupChildren').push(key);
        this.setRowVueState(rowData, 'groupParentKey', groupParentKey);
        GridUtils.setRowVueState(rowData, 'visible', groupParentExpand);
    }

    static getRowType(rowData) {
        return this.getRowVueState(rowData, 'rowType');
    }

    static cleanCheckedRows(grid) {
        grid.store.state.checkedRowKeys.forEach(key => {
            const rowData = grid.dataMapper[key];
            this.setRowVueState(rowData, 'checked', false);
            this.setParentRowGroupState(rowData, grid);
        });
        grid.store.commit('setCheckedRowKeys', []);
    }

    /**
     * @description 激活行
     * @static
     * @param {*} rowData
     * @param {*} grid
     * @memberof GridUtils
     */
    static activeRow(rowData, grid) {
        const key = this.getRowVueState(rowData, 'key');
        const { activeRowDataKey } = grid.store.state;
        if (activeRowDataKey !== key) {
            grid.store.commit('activeRow', { rowData });
        }
        // 激活复选框操作
        const gridOptions = grid.options;
        const rowType = this.getRowVueState(rowData, 'rowType');
        if (gridOptions.checkOnActive === false) {
            return;
        }
        if (rowType === 'group' && gridOptions.checkOnActiveGroupRow === false) {
            return;
        }
        const toggleCheckOnActive = gridOptions.toggleCheckOnActive;
        const currentChecked = GridUtils.getRowVueState(rowData, 'checked');
        // 点击行时是否切换复选框选中状态
        if (toggleCheckOnActive) {
            const checkedRowKey = grid.store.state.checkedRowKeys.filter(ele => ele !== key);
            // 如果checkOneOnActive：true,并且目前已选中的有当前行以外的行，则需要取消其他行选中，并选中当前行
            if (grid.options.checkOneOnActive && checkedRowKey.length) {
                this.cleanCheckedRows(grid);
                this.toggleCheckRow(rowData, true, grid, { relateActive: false });
            } else {
                this.toggleCheckRow(rowData, !currentChecked, grid, { relateActive: false });
            }
        } else {
            if (currentChecked) {
                return;
            }
            if (grid.options.checkOneOnActive) {
                this.cleanCheckedRows(grid);
            }
            this.checkRow(rowData, grid);
        }
    }

    /**
     * @description 激活分组行
     * @static
     * @param {*} rowData
     * @param {*} grid
     * @memberof GridUtils
     */
    static activeRowGroup(rowData, grid) {
        this.activeRow(rowData, grid);
        if (grid.options.checkOnActiveGroupRow === false) {
            return;
        }
        const checked = this.getRowVueState(rowData, 'checked');
        const groupChildren = GridUtils.getRowVueState(rowData, 'groupChildren');
        groupChildren.forEach(childKey => {
            const rowData = grid.dataMapper[childKey];
            const options = { rowData, triggerEvent: false };
            grid.store.commit(checked ? 'checkRow' : 'unCheckRow', options);
        });
    }

    /**
     * @description
     * @static
     * @memberof GridUtils
     */
    static initRowDataList(grid) {
        const data = grid.options.data;
        if (Gikam.isNotEmpty(data)) {
            grid.store.commit('setData', grid.initFormatterRowData(data));
        }
    }

    /**
     * @description 切换节点展开关闭状态
     * @static
     * @memberof GridUtils
     */
    static toggleExpandTreeNode(rowData) {
        const expand = GridUtils.getRowVueState(rowData, 'expand');
        if (expand) {
            this.shrinkTreeNode(rowData);
        } else {
            this.expandTreeNode(rowData);
        }
    }

    /**
     * @description 展开节点
     * @static
     * @param {*} rowData
     * @memberof GridUtils
     */
    static expandTreeNode(rowData) {
        GridUtils.setRowVueState(rowData, 'expand', true);
        if (Gikam.isEmpty(rowData.children)) {
            return;
        }
        Gikam.eachTree(rowData.children, childRow => GridUtils.setRowVueState(childRow, 'visible', true));
    }

    /**
     * @description 折叠节点
     * @static
     * @param {*} rowData
     * @memberof GridUtils
     */
    static shrinkTreeNode(rowData) {
        GridUtils.setRowVueState(rowData, 'expand', false);
        if (Gikam.isEmpty(rowData.children)) {
            return;
        }
        Gikam.eachTree(rowData.children, childRow => GridUtils.setRowVueState(childRow, 'visible', false));
    }

    /**
     * @description 替换视图层指定行
     * @memberof GridUtils
     */
    static replaceViewRow(oldRowData, row, grid) {
        const key = this.getRowVueState(oldRowData, 'key');
        grid.dataMapper[key] = row;
        GridUtils.copyRowVueState(row, oldRowData);
        const index = grid.initialRowDataList.indexOf(oldRowData);
        grid.initialRowDataList.splice(index, 1, row);
        const viewRowIndex = this.getRowVueState(oldRowData, 'viewRowIndex');
        grid.getTotalData().splice(viewRowIndex, 1, row);
        // 如果数据恰好在懒加载可视范围内，需要更新视图
        const { beginIndex, pageSize } = grid.store.state.lazyLoad;
        if (grid.options.lazyLoad && viewRowIndex >= beginIndex && viewRowIndex <= beginIndex + pageSize) {
            const lazyViewIndex = viewRowIndex - beginIndex;
            grid.options.data.splice(lazyViewIndex, 1, row);
        }
    }

    /**
     * @description 移除指定的行
     * @static
     * @memberof GridUtils
     */
    static removeRow(rowData, grid) {
        const key = this.getRowVueState(rowData, 'key');
        const rowIndex = this.getRowVueState(rowData, 'rowIndex');
        const state = grid.store.state;
        const totalData = grid.getTotalData();
        const viewRowIndex = totalData.indexOf(rowData);
        grid.initialRowDataList.splice(rowIndex, 1);
        totalData.splice(viewRowIndex, 1);
        // 更新所有行索引
        grid.initialRowDataList.slice(rowIndex).forEach(newRow => {
            const oldRowIndex = this.getRowVueState(newRow, 'rowIndex');
            const oldViewRowIndex = this.getRowVueState(newRow, 'viewRowIndex');
            this.setRowVueState(newRow, 'rowIndex', oldRowIndex - 1);
            this.setRowVueState(newRow, 'viewRowIndex', oldViewRowIndex - 1);
            newRow.gridIndex -= 1;
        });
        // 删除否选行
        const index = state.checkedRowKeys.indexOf(key);
        state.checkedRowKeys.splice(index, 1);
        // 清空激活行
        if (state.activeRowDataKey && state.activeRowDataKey === key) {
            this.activeRow(grid.initialRowDataList[rowIndex], grid);
        }
        // 更新分页信息
        grid.store.commit('updateTotalRecord', totalData.length);
        // 删除索引
        delete grid.dataMapper[key];
    }

    /**
     * @description 切换行选中
     * @param {*} rowData
     * @param {*} checked
     * @param {*} grid
     * @memberof GridUtils
     */
    static toggleCheckRow(rowData, checked, grid, param = {}) {
        this.setRowVueState(rowData, 'checked', checked);
        if (grid.options.checkContinuous) {
            grid.store.commit('changeCheckedIds', rowData);
        }
        const checkOptions = { rowData, triggerEvent: param.triggerEvent };
        grid.store.commit(checked ? 'checkRow' : 'unCheckRow', checkOptions);

        // 更新分组行
        this.setParentRowGroupState(rowData, grid);
        if (param.relateActive === false) {
            return;
        }
        // 激活行
        if (grid.options.activeOnCheck && GridUtils.getRowVueState(rowData, 'active') !== true) {
            grid.store.commit('activeRow', checkOptions);
        }
    }

    /**
     * @description 更新父行分组状态
     * @static
     * @param {*} rowData
     * @param {*} grid
     * @memberof GridUtils
     */
    static setParentRowGroupState(rowData, grid) {
        const groupParentKey = this.getRowVueState(rowData, 'groupParentKey');
        if (Gikam.isNotEmpty(groupParentKey)) {
            const parentRowData = grid.dataMapper[groupParentKey];
            const childKeys = this.getRowVueState(parentRowData, 'groupChildren');
            const checkedChildren = childKeys.filter(childKey => {
                const childRowData = grid.dataMapper[childKey];
                return this.getRowVueState(childRowData, 'checked');
            });
            if (Gikam.isEmpty(checkedChildren)) {
                this.setRowVueState(parentRowData, 'checked', false);
                this.setRowVueState(parentRowData, 'halfChecked', false);
            } else if (childKeys.length === checkedChildren.length) {
                this.setRowVueState(parentRowData, 'checked', true);
                this.setRowVueState(parentRowData, 'halfChecked', false);
            } else {
                this.setRowVueState(parentRowData, 'checked', true);
                this.setRowVueState(parentRowData, 'halfChecked', true);
            }
        }
    }

    /**
     * @description 勾选行数据
     * @param {*} rowData
     * @param {*} single
     * @param {*} grid
     * @memberof GridUtils
     */
    static checkRow(rowData, grid, param) {
        if (!rowData) {
            return;
        }
        this.toggleCheckRow(rowData, true, grid, param);
    }

    /**
     * @description 取消勾选行数据
     * @param {*} rowData
     * @param {*} single
     * @param {*} grid
     * @memberof GridUtils
     */
    static unCheckRow(rowData, grid, param) {
        this.toggleCheckRow(rowData, false, grid, param);
    }

    /**
     * @description 清空过滤条件
     * @memberof Grid
     */
    static cleanRequestData(ignoreKeys = [], grid) {
        const { requestData } = grid.options;
        if (Gikam.isEmptyObject(requestData)) {
            return;
        }
        for (const key in requestData) {
            const persistFlag = ignoreKeys.some(ignoreKey => key.split('_')[0] === ignoreKey);
            if (!persistFlag) {
                delete requestData[key];
            }
        }
    }

    /**
     * @description 清空过滤条件
     * @memberof Grid
     */
    static cleanOrderData(ignoreKeys = [], grid) {
        const { order } = grid.options;
        if (Gikam.isEmptyObject(order)) {
            return;
        }
        for (const key in order) {
            if (!ignoreKeys.includes(key)) {
                delete order[key];
            }
        }
    }

    /**
     * @description 批量勾选复选框
     * @static
     * @memberof GridUtils
     */
    static ToggleCheckRowRange(beginRowIndex, endRowIndex, grid) {
        const range = beginRowIndex > endRowIndex ? [endRowIndex + 1, beginRowIndex] : [beginRowIndex, endRowIndex];
        const rowDataList = grid.initialRowDataList.slice.apply(grid.initialRowDataList, range);
        const checked = this.getRowVueState(grid.initialRowDataList[beginRowIndex], 'checked');
        rowDataList.forEach(rowData => {
            this.toggleCheckRow(rowData, checked, grid, { triggerEvent: false });
        });
    }

    /**
     * @description 通过视图行索引获取数据索引
     * @static
     * @memberof GridUtils
     */
    static getRowIndexByViewRowIndex(viewRowIndex, grid) {
        return GridUtils.getRowVueState(grid.getTotalData()[viewRowIndex], 'rowIndex');
    }

    /**
     * @description 通过数据索引获取视图索引
     * @static
     * @memberof GridUtils
     */
    static getViewIndexByRowDataIndex(rowDataIndex, grid) {
        return GridUtils.getRowVueState(grid.initialRowDataList[rowDataIndex], 'viewRowIndex');
    }

    /**
     * @description 通过id勾选节点
     * @static
     * @param {*} id
     * @param {*} grid
     * @memberof GridUtils
     */
    static toggleNodeById(id, param = {}, checked, grid) {
        const rowData = grid.dataMapper[id];
        const treeColumn = grid.options.columns.find(column => column.type === 'tree');
        const isSingleCheck = treeColumn.single || grid.options.single;
        param.isSingleCheck = isSingleCheck;
        this.toggleNodeChecked(rowData, checked, grid, param);
    }

    /**
     * @description 修改冻结列数据行高度
     * @static
     * @param {*} grid
     * @memberof GridUtils
     */
    static fillTrHeight(grid) {
        const trs = grid.model.$el.querySelector('.grid-body').querySelectorAll('tr');
        if (trs.length) {
            trs.forEach((tr, index) => {
                const trHeightDom = grid.model.$el
                    .querySelector('.grid-fixed')
                    .querySelector('.grid-body')
                    .querySelector(`tr[data-index="${index}"]`);
                trHeightDom && (trHeightDom.style.height = tr.clientHeight + 'px');
            });
        }
    }
}
