<template>
    <text-input
        ref="editor"
        :options="options"
        :propValue="propValue"
        :dataId="dataId"
        :propReadonly="readonly"
        :validateArg="validateArg"
        @change="changeHandle"
        @valueChange="changeHandle"
    ></text-input>
</template>

<script>
import textInput from '@/gikam/js/components/template/fields/textInput/textInput';
import { mapState } from 'vuex';

export default {
    props: {
        options: Object,
        propValue: [Number, String],
        validateArg: Object,
        dataId: [Number, String],
        formData: Object
    },

    components: {
        textInput
    },

    data() {
        return {
            readonly: this.options.readonly ?? false
        };
    },

    computed: {
        ...mapState(['form'])
    },

    mounted() {
        this.form.editorManager[this.options.field] = this.$refs.editor;
    },

    methods: {
        changeHandle(...args) {
            this.$emit.apply(this, ['change', ...args]);
        }
    }
};
</script>
