import Grid from './grid';
import Gikam from 'gikam';
import { GridUtils } from './grid-utils.js';

let defaultOptions = {
    // 是否显示复选框
    checkbox: true,
    // 是否单选
    single: false,
    cascadeCheck: true,
    cascadeChildrenCheck: true,
    cascadeParentCheck: true,
    // 默认全部展开
    defaultExpandAll: true,
    defaultExpandedIds: [],
    defaultSelectedIds: [],
    // 节点选中事件
    onNodeSelected: null,
    // 点击行是否触发 treeNode checkbox/radio
    checkNodeOnActive: false
};

export default class TreeGrid extends Grid {
    constructor(param) {
        const options = Object.assign(Gikam.deepExtend(defaultOptions), param);
        super(options);
    }

    initFormatterRowData(rows, cascadeLoad) {
        if (cascadeLoad === false) {
            return super.initFormatterRowData(rows);
        }
        this.initData = [];
        this.rowDataList = [];
        this.dataMapper = {};
        this.initialRowDataList = [];
        this.store.commit('setTreeCheckedRowKeys', []);
        Gikam.eachTree(rows, (rowData, parentRowData) => {
            const key = GridUtils.createRowDataKey(rowData, this);
            this.dataMapper[key] = rowData;
            this.initData.push(rowData);
            this.initialRowDataList.push(rowData);
            if (Gikam.isEmpty(rowData.parentId)) {
                rowData.parentId = '-1';
            }
            GridUtils.initRowVueState(rowData, key, this);
            GridUtils.setRowVueState(rowData, 'key', key);
            GridUtils.setRowVueState(rowData, 'rowIndex', this.rowDataList.length);
            GridUtils.setRowVueState(rowData, 'viewRowIndex', this.rowDataList.length);
            if (parentRowData) {
                const parentRowDataKey = GridUtils.getRowVueState(parentRowData, 'key');
                const parentKeys = GridUtils.getRowVueState(parentRowData, 'treeParentNodeKeys');
                GridUtils.setRowVueState(rowData, 'treeParentNodeKeys', [parentRowDataKey, ...parentKeys]);
            } else {
                GridUtils.setRowVueState(rowData, 'treeParentNodeKeys', []);
            }
            this.initTreeNodeState(rowData, parentRowData);
            this.rowDataList.push(rowData);
        });
        return this.rowDataList;
    }

    initTreeNodeState(rowData, parentRowData) {
        const { defaultExpandAll, defaultExpandedIds } = this.options;
        const parentExpand = GridUtils.getRowVueState(parentRowData, 'expand');
        const parentVisible = GridUtils.getRowVueState(parentRowData, 'visible');
        // 数据中如果有checked，则设置默认选中
        if (rowData.checked) {
            GridUtils.setRowVueState(rowData, 'treeNodeChecked', true);
            GridUtils.setRowVueState(rowData, 'treeNodeHalfChecked', false);
        }
        if (parentExpand === false || parentVisible === false) {
            GridUtils.setRowVueState(rowData, 'visible', false);
            return;
        }
        if (defaultExpandAll) {
            GridUtils.setRowVueState(rowData, 'expand', true);
            GridUtils.setRowVueState(rowData, 'visible', true);
            return;
        }
        if (!parentRowData) {
            GridUtils.setRowVueState(rowData, 'visible', true);
            return;
        }
        if (defaultExpandedIds.includes(rowData.id)) {
            GridUtils.setRowVueState(rowData, 'expand', true);
        } else {
            GridUtils.setRowVueState(rowData, 'expand', false);
        }
        // 父节点打开，子节点展示
        if (parentExpand) {
            GridUtils.setRowVueState(rowData, 'visible', true);
        } else {
            GridUtils.setRowVueState(rowData, 'visible', false);
        }
    }

    /**
     * @description 获取选中的节点,如果节点中有半选状态，则为不选中
     * @memberof TreeGrid
     * @public
     */
    getCheckedNode() {
        const rowDataList = [];
        this.rowDataList.forEach(row => {
            if (GridUtils.getTreeNodeCheckedState(row) === 'checked') {
                const rowData = Gikam.deepExtend(row);
                GridUtils.removeAllRowVueState(rowData);
                rowDataList.push(rowData);
            }
        });
        return rowDataList;
    }

    /**
     * @description 通过id展开或关闭子节点
     * @public
     * @param { String } nodeId 节点id
     * @param { boolean } expanded  子节点状态 true/false
     * @memberof TreeGrid
     */
    changeChildren(id, expand) {
        const rowData = this.dataMapper[id];
        if (expand) {
            GridUtils.expandTreeNode(rowData);
        } else {
            GridUtils.shrinkTreeNode(rowData);
        }
    }

    /**
     * @description 获取选中的节点,如果节点中有半选状态，则为选中
     * @returns
     * @memberof TreeGrid
     */
    getSelectedNode() {
        const rowDataList = [];
        this.rowDataList.forEach(row => {
            const state = GridUtils.getTreeNodeCheckedState(row);
            if (state === 'checked' || state === 'halfChecked') {
                const rowData = Gikam.deepExtend(row);
                GridUtils.removeAllRowVueState(rowData);
                rowDataList.push(rowData);
            }
        });
        return rowDataList;
    }

    /**
     * @description 通过index刷新行
     * @param {*} index
     * @returns
     * @memberof TreeGrid
     */
    refreshRowByIndex(index) {
        const def = Gikam.getDeferred();
        const oldRowData = this.initialRowDataList[index];
        const queryData = this.queryParams({ id_EQ: oldRowData.id }, 1, false);
        Gikam.post(this.options.url, queryData).done(data => {
            if (Gikam.isEmpty(data.rows)) {
                GridUtils.removeRow(oldRowData, this);
            } else {
                const node = Gikam.findTreeNode(data.rows, rowData => rowData.id === oldRowData.id);
                GridUtils.replaceViewRow(oldRowData, node, this);
            }
            def.resolve();
        });
        return def;
    }

    /**
     * @description 获取选中数据
     * @returns
     * @memberof TreeGrid
     */
    getSelections() {
        if (this.options.checkbox) {
            return this.getSelectedNode();
        } else {
            return super.getSelections();
        }
    }

    /**
     * @description 通过id展开或关闭子节点
     * @public
     * @param { boolean } expanded  子节点状态 true/false
     * @memberof TreeGrid
     */
    changeAllNodes(expand) {
        const data = this.options.data.slice();
        data.forEach(row => {
            if (expand) {
                GridUtils.expandTreeNode(row);
            } else {
                GridUtils.shrinkTreeNode(row);
            }
        });
    }

    /**
     * @description 通过id勾选节点
     * @param {*} id
     * @returns
     * @memberof TreeGrid
     */
    checkNodeById(id, param = { triggerEvent: true }) {
        if (Gikam.isEmpty(id)) {
            Gikam.error('not find id');
            return;
        }
        GridUtils.toggleNodeById(id, param, true, this);
    }

    /**
     * @description 通过id取消勾选节点
     * @param {*} id
     * @returns
     * @memberof TreeGrid
     */
    unCheckNodeById(id, param = { triggerEvent: true }) {
        if (Gikam.isEmpty(id)) {
            Gikam.error('not find id');
            return;
        }
        GridUtils.toggleNodeById(id, param, false, this);
    }

    sortData(data) {
        data.forEach(ele => {
            if (ele.children?.length) {
                this.sortDataOnLevel(ele.children);
            }
        });
        this.sortDataOnLevel(data);
    }

    sortDataOnLevel(data) {
        const orderMap = this.getOrderMap();
        const len = Object.keys(orderMap).length;
        len &&
            data.sort((a, b) => {
                let n = 0;
                for (const key in orderMap) {
                    const order = orderMap[key];
                    n++;
                    if (a[key] !== b[key] || n === len) {
                        const n = a[key] >= b[key] ? 1 : -1;
                        const o = order === 'asc' ? 1 : -1;
                        return n * o;
                    }
                }
            });
    }
}
