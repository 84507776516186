<template>
    <div class="checkbox-container" :name="field" :class="{ invisible: invisible }" @mousedown.stop @click.stop>
        <div v-if="invisible" class="readonly-text" :title="valueText" @dblclick="dblclickHandle" @click="clickHandle">
            <span>{{ valueText }}</span>
        </div>
        <div v-if="!invisible && value.length > 1 && options.showAllCheck !== false" class="item">
            <div
                class="checkbox"
                value="all"
                :class="{ checked: allChoose, readonly: readonly || setReadonlyList.length }"
                @click="checkboxClickAllHandle"
            >
                <tickImg v-if="allChoose" v-bind="{ color: '#fff', width: '14px', height: '14px' }" />
            </div>
            <span>{{ $i18n('checkbox.check.all') }}</span>
        </div>
        <template v-if="!invisible">
            <div class="item" v-for="item in checkboxList" :key="item.value">
                <div
                    class="checkbox"
                    :value="item.value"
                    :class="{ checked: item.checked == 1, readonly: readonly || setReadonlyList.includes(item.value) }"
                    @click="checkboxClickHandle(item)"
                >
                    <tickImg v-if="item.checked == 1" v-bind="{ color: '#fff', width: '14px', height: '14px' }" />
                </div>
                <span>{{ item.text }}</span>
            </div>
        </template>
        <template v-if="!invisible && otherList.length > 0">
            <div v-for="item in otherList" :key="item.text + item.type" class="item other">
                <div
                    class="checkbox"
                    :value="item.value"
                    :class="{ checked: item.checked == 1, readonly: readonly || setReadonlyList.includes(item.value) }"
                    @click="checkboxClickHandle(item)"
                >
                    <tickImg v-if="item.checked == 1" v-bind="{ color: '#fff', width: '14px', height: '14px' }" />
                </div>
                <span>{{ item.text }}</span>
                <input
                    type="text"
                    maxlength="64"
                    :readonly="readonly || setReadonlyList.includes(item.value)"
                    v-model="item.value"
                    :title="item.value"
                    @change="checkboxClickHandle(item, 'change')"
                    autocomplete="off"
                    :style="{width:item.width +'px'}"
                />
            </div>
        </template>
    </div>
</template>

<script>
import Vue from 'vue';

export default {
    props: {
        options: Object,
        propValue: String,
        propReadonly: Boolean,
        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        getCheckboxList(value, oldValue) {
            if (this.options.items) {
                this.value = this.options.items;
            } else {
                this.value = value ? JSON.parse(value) : [];
                this.oldValue = oldValue 
            }
        },

        // 触发change
        triggerChange() {
            if (this.options.items) {
                const selectVal = this.value
                    .filter(item => {
                        return item.checked;
                    })
                    .map(item => {
                        return item.value;
                    })
                    .join();
                this.$emit('change', this.field, selectVal);
            } else {
                this.$emit('change', this.field, JSON.stringify(this.value), {oldValue: this.oldValue});
            }
        },

        // 全选
        checkboxClickAllHandle() {
            if (this.readonly || this.setReadonlyList.length > 0) {
                return;
            }
            this.allChoose = !this.allChoose;
            this.value.forEach(item => {
                item.checked = this.allChoose ? 1 : 0;
            });
            this.triggerChange();
            if (this.options.onAllClick) {
                this.options.onAllClick(this.value);
            }
        },

        // 当存在items时，初始化选中赋值
        initSelectCheckbox() {
            if (this.options.items) {
                let vals = this.propValue ? this.propValue.split(',') : [];
                for (let i = 0; i < this.value.length; i++) {
                    Vue.set(this.value[i], 'checked', vals.includes(this.value[i].value) ? 1 : 0);
                }
            }
        },

        // 选中的个数
        checkedActiveNum() {
            let activeNum = this.value.filter(item => {
                return parseInt(item.checked) === 1;
            }).length;
            this.allChoose = activeNum !== 0 && this.value.length === activeNum;
        },

        // 复选框单击事件
        checkboxClickHandle(item, method) {
            if (this.readonly || this.setReadonlyList.includes(item.value)) {
                return;
            }

            if (method !== 'change') {
                Vue.set(item, 'checked', item.checked == 1 ? 0 : 1);
                this.checkedActiveNum();
                if (this.options.onClick) {
                    this.options.onClick(item);
                }
            }
            this.triggerChange();
        },

        clickHandle() {
            this.$emit('click', this);
        },

        dblclickHandle() {
            this.$emit('dblclick', this);
        },

        setReadonly(list) {
            this.setReadonlyList = list;
        }
    },

    data() {
        return {
            allChoose: false, // 全选
            field: this.options.field,
            readonly: this.options.readonly,
            value: [],
            oldValue: [],
            invisible: this.propInvisible,
            setReadonlyList: []
        };
    },

    computed: {
        valueText() {
            let text = this.value.reduce((total, item) => {
                if (parseInt(item.checked) === 1) {
                    if (item.type) {
                        total += item.value + ',';
                    } else {
                        total += item.text + ',';
                    }
                }
                return total;
            }, '');
            return text.substr(0, text.length - 1);
        },

        checkboxList() {
            return this.value.filter(item => {
                return !item.type;
            });
        },

        otherList() {
            return this.value.filter(item => {
                return item.type;
            });
        }
    },

    watch: {
        propValue(value, oldValue) {
            this.getCheckboxList(value, oldValue);
            this.initSelectCheckbox();
            this.checkedActiveNum();
        },

        propInvisible(val) {
            this.invisible = val;
        }
    },

    created() {
        this.getCheckboxList(this.propValue);
    },

    mounted() {
        this.initSelectCheckbox();
        this.checkedActiveNum();
    }
};
</script>

<style scoped>
.checkbox-container {
    width: 100%;
    min-height: 50px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    padding: 8px 8px 0 0;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.checkbox-container .item {
    margin-left: 8px;
    margin-right: 0;
    margin-bottom: 8px;
}

.checkbox-container.invisible {
    height: 24px;
    min-height: auto;
    border: none;
}

.checkbox-container.invisible .readonly-text {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    border: none;
    display: flex;
    align-items: center;
}

.checkbox-container.invisible .readonly-text > span {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.checkbox {
    width: 14px;
    height: 14px;
    border: 1px solid #d9d9d9;
    margin-right: 5px;
    border-radius: 2px;
}

.checkbox.checked {
    background-color: #007aff;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
}

.checkbox.readonly {
    background-color: #ccc;
}

.checkbox:hover {
    border: 1px solid #007aff;
    box-shadow: 0 0 3px #007aff;
    cursor: pointer;
}

.checkbox.checked:hover {
    border: none;
}

.item {
    display: flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
}

/*解决form表单下，意外影响checkbox的问题*/
div.field-container.checkbox-container .item {
    min-height: 0;
}

/* 其他的样式 */
.checkbox-container .item.other > input {
    width: 60px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    padding: 2px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.65);
}
</style>
