<template>
    <date-field 
        :options="options" 
        @change="changeHandle"
        @click="clickHandle" 
        :class="classList" 
        :propValue="propValue"        
        :propInvisible="propInvisible"/>
</template>

<script>
import Gikam from 'gikam';
let globeCurrentEditor;
document.addEventListener('mousedown', () => {
    if (!globeCurrentEditor) {
        return;
    }
    Gikam.finalDelay(
        'changeGlobeCurrentEditor',
        () => {
            if (globeCurrentEditor.propInvisible) {
                globeCurrentEditor.blur && globeCurrentEditor.blur();
                globeCurrentEditor.$nextTick(() => {
                    if (globeCurrentEditor.validateResult === false) {
                        return;
                    } else {
                        globeCurrentEditor.invisible = true;
                        globeCurrentEditor = null;
                    }
                });
            } else {
                globeCurrentEditor.blur && globeCurrentEditor.blur();
                globeCurrentEditor.invisible = true;
                globeCurrentEditor.validateResult = true;
                globeCurrentEditor = null;
            }
        },
        50
    );
});

const clearPicker = function() {
    if (Gikam.isTrue(globeCurrentEditor.propInvisible)) {
        globeCurrentEditor.dumpActiveCell && globeCurrentEditor.dumpActiveCell();
    }
};
export default {
    props: {
        options: Object,
        row: Object,
        propValue: [Number, String],
        rowIndex: Number,
        propInvisible: Boolean
    },

     inject: {
        grid: {
            default: null
        }
    },

    data() {
        return {
            rootClass: 'grid-cell-date'
        };
    },

    computed: {
        classList() {
            const classList = [this.rootClass];
            if (this.options.readonly) {
                classList.push(`${this.rootClass}-readonly`);
            }
            return classList;
        }
    },

    methods: {
        changeHandle(field, value, rowIndex, oldVal) {
            this.$emit('change', field, value, this.rowIndex, oldVal);
        },

        clickHandle(fieldEditor) {
            //当前编辑器只读
            if (fieldEditor.readonly) {
                return;
            }
            //编辑器默认呈现样式
            if (this.grid.options.editorInvisible !== true) {
                return;
            }
            if (globeCurrentEditor && globeCurrentEditor.options.type.includes('date')) {
                clearPicker();
            }
            const currentEditor = this.grid._getCurrentEditor();
            //当前编辑器正在编辑中  或者输入正确时全局mousedown事件将其变为 invisible： false
            if (currentEditor === fieldEditor && currentEditor.invisible !== true) {
                return;
            }
            //当前grid是否有记忆的表格  或者输入正确时全局mousedown事件将其变为 invisible： false
            if (currentEditor && currentEditor.invisible !== true) {
                //上一编辑器没有失焦时，使其失焦，
                currentEditor.blur && currentEditor.blur();
                currentEditor.$nextTick(() => {
                    // 失焦之后校验前一单元格
                    if (currentEditor.validateResult === false) {
                        globeCurrentEditor = currentEditor;
                    } else {
                        currentEditor.invisible = true;
                        //保存住正在编辑的单元格
                        fieldEditor.invisible = false;
                        this.grid._setCurrentEditor(fieldEditor);
                        globeCurrentEditor = fieldEditor;
                        fieldEditor.$nextTick(() => {
                            fieldEditor.focus && fieldEditor.focus();
                        });
                    }
                });
            } else {
                fieldEditor.invisible = false;
                this.grid._setCurrentEditor(fieldEditor);
                globeCurrentEditor = fieldEditor;
                fieldEditor.$nextTick(() => {
                    fieldEditor.focus && fieldEditor.focus();
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
$rootClass: 'grid-cell-date';

.#{$rootClass} {
    &-readonly {
        ::v-deep {
            > .readonly-text {
                background: transparent;
                border: none;
                padding: 0;
            }
        }
    }
}
</style>
