<template>
    <tr
        @click="clickHandle"
        :style="rowStyle"
        :class="classList"
        :data-index="rowIndex"
        @dblclick.capture="dblclickHandle"
    >
        <slot></slot>
    </tr>
</template>

<script>
import { mapState } from 'vuex';
import { GridUtils } from '../../js/grid-utils';
import Gikam from 'gikam';

export default {
    props: {
        rowData: Object,
        gridOptions: Object,
        rowIndex: Number
    },

    computed: {
        ...mapState(['grid', 'columns']),

        rowStyle() {
            const gridOptions = this.grid.options;
            const style = { ...gridOptions.rowStyleFormatter?.call(this.grid, this.rowData) };
            return style;
        },

        classList() {
            const classList = [];
            if (GridUtils.getRowVueState(this.rowData, 'active')) {
                classList.push('active');
            }
            if (GridUtils.getRowVueState(this.rowData, 'checked')) {
                classList.push('checked');
            }
            if (GridUtils.getRowVueState(this.rowData, 'visible') === false) {
                classList.push('grid-body-row-hidden');
            }
            return classList;
        }
    },

    created() {
        const key = GridUtils.getRowVueState(this.rowData, 'key');
        this.grid.$rowManager[key] = this;
    },

    methods: {
        clickHandle() {
            GridUtils.activeRow(this.rowData, this.grid);
            const { type, checkbox, single, checkNodeOnActive } = this.grid.options;
            if (type === 'treeGrid' && checkNodeOnActive && checkbox) {
                this.columns.forEach(field => {
                    if (field.type === 'tree') {
                        const isSingleCheck = field.single || single;
                        const checked = isSingleCheck
                            ? true
                            : !GridUtils.getRowVueState(this.rowData, 'treeNodeChecked');
                        GridUtils.toggleNodeChecked(this.rowData, checked, this.grid, { isSingleCheck });
                    }
                });
            }
        },

        dblclickHandle() {
            this.grid.options.onRowDblclick?.call(this.grid, Gikam.deepExtend(this.rowData));
        }
    }
};
</script>

<style lang="scss" scoped>
.grid-body-row-hidden {
    display: none;
}
</style>
