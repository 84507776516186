import Gikam from 'gikam';
import FormPanel from '@/gikam/js/components/form/vue/form.vue';
import Vue from 'vue';
import Base from '@/gikam/js/components/base';
import { I18N } from '@/gikam/i18n/I18N.js';
import { processFormFields, refreshFieldsByConfig } from './processFormFields.js';
import { getStore } from './formStore';
import { FromUtils } from './form-utils';

let defaultOptions = {
    renderTo: null,
    columns: 2,
    fields: [],
    panels: [],
    data: {
        ext$: {}
    },
    titleWidthAuto: false,
    titleAlign: void 0,
    service: void 0,
    autoSave: true,
    style: {},
    border: false,
    margin: false,
    caption: {},
    name: '',
    dbTable: null,
    // 表单布局，取值vertical | inline
    layout: 'inline',
    // 标题宽度
    titleWidth: 100,
    // 隐藏当前form
    hidden: false,
    // 隐藏字段用于编辑页面编辑时使用
    hiddenFields: [],
    // 实时保存路径
    instantSavePath: null,
    // 是否启用通过url获取数据
    enableGetDataByUrl: true
};

export default class Form extends Base {
    constructor(options) {
        super('Form');
        this.$lastChild = true;
        this.$changeFields = {};
        this.validators = {};
        this.canAutoSave = true;
        // 渲染完成的状态
        this.$renderState = null;
        this.triggerChangeFlag = true;
        // 正在编辑的字段类型
        this.editorType = false;
        // 正在编辑的字段id
        this.editorId = null;
        // 是否编辑用到的字段变量
        this.checkEditorFields = [];
        // 上次是否曾被置为只读
        // this.preReadonly = false;
        // 保存计时器，方便清除
        this.timer = null;
        // 记录校验未通过的字段
        this.errorFields = [];
        // 隐藏的字段
        this.hiddenFields = [];
        // formGroup存储器
        this.formGroupManager = {};
        // 编辑器存储器
        this.editorManager = {};
        // 保留最初的字段列
        this.initialPanels = Gikam.isEmpty(options.panels) ? [{ fields: options.fields || [] }] : options.panels;
        // 字段编辑器遮罩
        this.listeners = {
            loadSuccess: Gikam.emptyFunction,
            afterChoose: Gikam.emptyFunction,
            rendered: Gikam.emptyFunction,
            inserted: Gikam.emptyFunction,
            updated: Gikam.emptyFunction,
            beforeUpdate: (data, keys) => [data, keys],
            validating: Gikam.emptyFunction,
            titleClick: Gikam.emptyFunction
        };
        this.initialize(options, defaultOptions).init();
    }

    createModel() {
        let { options } = this;
        let mountedDom = document.createElement('dom');
        this.options.renderTo.appendChild(mountedDom);
        this.options.parent = this;
        this.cacheSelectItems(this.options.panels).done(async () => {
            this.caseSelectValue();
            this.model = new Vue({
                store: getStore(this),
                el: mountedDom,
                components: { FormPanel },
                provide: { form: this, $window: this.$window },
                data() {
                    return { options };
                },
                render() {
                    return <form-panel ref="vm" options={this.options}></form-panel>;
                }
            });
            await this.model.$nextTick();
            setTimeout(() => {
                this.trigger('rendered');
                this.refresh(null, false);
                this.notify();
                this.$renderState = 'ready';
            }, 200);
        });
    }

    getData() {
        const data = Gikam.deepExtend(this.options.data);
        const keys = Object.getOwnPropertyNames(data);
        if (keys.length === 1 && keys[0] === 'ext$') {
            return this.model.$refs.vm.getData();
        }
        return data;
    }

    getFileData(currentField) {
        let fields = Gikam.deepExtend(this.options.fields);
        if (this.options.panels.length > 0) {
            this.options.panels.forEach(item => {
                fields = fields.concat(item.fields);
            });
        }
        let fileData = {};
        let fileFields = [];
        if (currentField) {
            fileFields = [
                {
                    field: currentField
                }
            ];
        } else {
            fileFields = fields.filter(item => {
                return item.type == 'file';
            });
        }
        fileFields.forEach(item => {
            fileData[item.field] = this.getField(item.field).fileItem;
        });
        return fileData;
    }

    setData(data, saveFlag = true) {
        this.canAutoSave = false;
        this.options.data = Gikam.deepExtend(this.options.data, data, { id: this.getData().id });
        const _this = this;
        this.model.$nextTick(() => {
            _this.canAutoSave = true;
            saveFlag && this.autoSave(data);
        });
    }

    cacheSelectItems(panels) {
        let filterType = ['SelectInput', 'ComboBox', 'InsertableSelect', 'CascadeSelect'];
        let codeArray = [];
        panels.forEach(panel => {
            panel.fields.forEach(function(item) {
                if (filterType.indexOf(Gikam.toInitialUpperCase(item.type || '')) > -1 && Gikam.isEmpty(item.items)) {
                    codeArray.push(item);
                }
            });
        });
        if (Gikam.isEmpty(codeArray)) {
            return Gikam.getDeferred().resolve();
        }
        return Gikam.select.cacheItems(codeArray);
    }

    caseSelectValue() {
        const filterType = ['Select', 'SelectInput', 'ComboBox', 'InsertableSelect'];
        this.options.panels.forEach(panel => {
            panel.fields.forEach(item => {
                if (
                    filterType.indexOf(Gikam.toInitialUpperCase(item.type || '')) > -1 &&
                    Gikam.isNotEmpty(item.selectedIndex)
                ) {
                    const value =
                        item.items && item.items[item.selectedIndex] ? item.items[item.selectedIndex].value : '';
                    if (value !== '') {
                        Gikam.setFieldValue(this.options.data, item.field, value);
                    }
                }
            });
        });
    }

    async openMask() {
        await Gikam.showMask();
    }

    closeMask() {
        Gikam.cleanMask();
    }

    /**
     * @description 清除校验字段缓存
     * @memberof Form
     */
    cleanValidateCache() {
        this.errorFields = [];
    }

    refresh(param, cleanFlag) {
        const def = Gikam.getDeferred();
        this.openMask().then(() => {
            if (this.options.url) {
                cleanFlag !== false && this.cleanData();
            }
            let _this = this;
            param && this.setOptions(param);

            if (this.options.enableGetDataByUrl === false) {
                this.closeMask();
                this.cleanValidateCache();
                return def.resolve();
            }

            if (this.options.url) {
                Gikam.getJson(this.options.url)
                    .done((data, status, xhr) => {
                        this.toFieldsReadonly(['id']);
                        this.loadData(data);
                        def.resolve(data);
                        this.storeInstantSavePath(xhr);
                    })
                    .fail(function() {
                        def.reject();
                    })
                    .always(function() {
                        _this.closeMask();
                    });
            } else {
                this.closeMask();
            }
        });
        this.cleanValidateCache();
        return def;
    }

    /**
     * @description 动态刷新表单中的字段
     * @public
     * @param {Array} param 再次刷新的字段，例如表单分组参数[{title:'',fields:[{field:''}]}]，普通表单：[{field:''}]
     * @returns
     * @memberof Form
     */
    refreshFields(param) {
        const def = Gikam.getDeferred();
        if (!Array.isArray(param)) {
            return;
        }
        let panels = param[0].fields ? param : [{ fields: param }];
        this.cacheSelectItems(panels).done(() => {
            this.cleanData();
            this.options.panels = panels;
            this.validators = {};
            this.registerValidators();
            this.initFormData();
            this.caseSelectValue();
            this.model.$nextTick(() => {
                def.resolve(this);
            });
        });
        return def;
    }

    async loadData(data) {
        this.triggerChangeFlag = false;
        this.canAutoSave = false;
        this.options.data = Gikam.deepExtend(this.options.data, data);
        this.initData = Gikam.deepExtend(this.options.data);
        this.$window?.changeSaveButtonPrompt(false);
        await this.model.$nextTick();
        this.canAutoSave = true;
        this.triggerChangeFlag = true;
        this.trigger('loadSuccess', Gikam.deepExtend(this.options.data));
        this.initDataLock();
    }

    setOptions(param) {
        Gikam.extend(true, this.options, param);
    }

    getOptions() {
        return Gikam.deepExtend(this.options);
    }

    showValidateMessage(error) {
        let tpl =
            '<div class="validator-error-container">' +
            '<span class="error-title">' +
            Gikam.propI18N('GIKAM.TIP.INVALID_INPUT_FIELDS') +
            '</span>' +
            '<div class="error-zone">{errorMessage}</div>' +
            '</div>';
        let errorMessage = '';
        error.forEach(function(item) {
            if (item.title) {
                errorMessage +=
                    '<div>' +
                    '<span class="error-field-desc">' +
                    Gikam.propI18N(item.title) +
                    '</span>' +
                    Gikam.propI18N(item.message) +
                    '</div>';
            } else {
                errorMessage += '<div>' + Gikam.propI18N(item.message) + '</div>';
            }
        });

        Gikam.alert(
            Gikam.printf(tpl, {
                errorMessage: errorMessage
            }),
            200
        );
    }

    /**
     * @description 注册panels内所有的验证条件
     * @private
     * @memberof Form
     */
    registerValidators() {
        const panelsFields = this.getPanelsFields();
        if (Gikam.isEmpty(panelsFields)) {
            return;
        }
        panelsFields
            .filter(item => Gikam.isNotEmpty(item.validators))
            .forEach(item => {
                this.baseAddFieldVal(item);
            });
        return this;
    }

    validate(noModal) {
        document.body.click();
        if (this.validateExistsError(noModal) === false) {
            return false;
        }
        let _this = this;
        const error = [];
        const values = this.model.$refs.vm.getData();

        let data = Gikam.extend(this.getData(), values);
        Gikam.each(this.validators, function(field) {
            let fieldValidateResult = true;
            Gikam.each(this, function() {
                // 唯一性校验，在调用validate方法时不验证
                if (['unique', 'remote'].includes(this.type)) {
                    return true;
                }
                let r = Gikam.validator[this.type](
                    Gikam.getFieldValue(data, field),
                    this.rule,
                    field,
                    data.id,
                    Gikam.deepExtend(this, { data })
                );
                if (r.result === false) {
                    error.push({
                        field: field,
                        title: this.title,
                        message: r.message
                    });
                    fieldValidateResult = false;
                    return false;
                }
            });
            const _field = _this.getField(field);
            if (['script', 'ckeditor', 'scriptChoose'].indexOf(_field?.options.type) > -1) {
                _field.validateResult = fieldValidateResult;
                _field.buttonValidate = true;
                setTimeout(() => {
                    _field.buttonValidate = false;
                }, 220);

                if (Gikam.isFalse(fieldValidateResult)) {
                    _field.iframe && _field.iframe.changeBorderColor('#ff6e6e');
                    _field.eventType = 'blur';
                } else {
                    _field.iframe && _field.iframe.changeBorderColor('#d1d1d1');
                    _field.eventType = 'change';
                }
            } else {
                _field.validateResult = fieldValidateResult;
            }
        });

        const projectValidate = this.trigger('validating', data);

        if (
            Gikam.isPlainObject(projectValidate) &&
            Gikam.isFalse(projectValidate.result) &&
            Gikam.isNotEmpty(projectValidate.message)
        ) {
            projectValidate.title = '';
            error.push(projectValidate);
        }

        if (Gikam.isEmpty(error)) {
            return true;
        } else {
            noModal !== true && this.showValidateMessage(error);
            return false;
        }
    }

    toFieldsReadonly(array) {
        if (!Array.isArray(array)) {
            array = [array];
        }
        this.setFieldsReadonly(array, true);
    }

    toFieldsEdit(array) {
        if (!Array.isArray(array)) {
            array = [array];
        }
        this.setFieldsReadonly(array, false);
    }

    setFieldsReadonly(array, isReadonly) {
        array.forEach(item => {
            let field = this.getField(item);
            if (field) {
                field.readonly = isReadonly;
            }
        });
    }

    /**
     * @description 清除验证内容
     * @public
     * @param {string} field 需要清除验证的fields字段
     * @param {array} validators 需要清除验证内容
     * @memberof Form
     */
    removeFieldValidator(field, validators) {
        const index = this.errorFields.findIndex(item => {
            return item.field === field;
        });
        if (index > -1) {
            this.errorFields.splice(index, 1);
        }
        Gikam.each(this.validators, function(key, value) {
            if (key === field) {
                for (let i = value.length - 1; i > -1; i--) {
                    validators.indexOf(value[i].type) > -1 && value.splice(i, 1);
                }
                return false;
            }
        });

        const fields = this.getPanelsFields().filter(item => item.field === field)[0];
        if (!fields) {
            return;
        }
        for (let j = fields.validators.length - 1; j > -1; j--) {
            validators.indexOf(fields.validators[j]) > -1 && fields.validators.splice(j, 1);
        }
        return this;
    }

    /**
     * @description 增加验证内容
     * @public
     * @param {string} field 需要增加验证的fields字段
     * @param {array} validators 需要增加验证内容
     * @memberof Form
     */
    addFieldValidator(field, validators) {
        const currentFields = this.getPanelsFields().filter(item => item.field === field)[0];
        if (Gikam.isEmpty(currentFields.validators)) {
            this.model.$set(currentFields, 'validators', validators);
        } else {
            currentFields.validators = validators;
        }
        this.baseAddFieldVal(currentFields);
        return this;
    }

    /**
     * @description 获取所有panels下的fields
     * @private
     * @memberof Form
     */
    getPanelsFields() {
        return this.options.panels.reduce(function(total, item) {
            return total.concat(item.fields);
        }, []);
    }

    /**
     * @description 将fields字段内的验证内容 固定到 this.validators 对象中
     * @private
     * @param {object} fieldItem 需要增加验证的fields字段
     * @memberof Form
     */
    baseAddFieldVal(item) {
        this.validators[item.field] = [];
        item.validators.forEach(name => {
            let newValidator = {};
            if (Gikam.isPlainObject(name)) {
                Gikam.extend(newValidator, name);
            } else if (name.indexOf('strLength') === 0) {
                newValidator = {
                    type: 'strLength',
                    rule: name.replace('strLength', '')
                };
            } else if (name.indexOf('numRange') === 0) {
                newValidator = {
                    type: 'numRange',
                    rule: name.replace('numRange', '')
                };
            } else if (name.indexOf('remote') === 0) {
                newValidator = {
                    type: 'remote',
                    rule: name.replace(/remote|\[|]/g, '')
                };
            } else if (name.indexOf('unique') === 0) {
                newValidator = {
                    type: 'unique',
                    rule: name.replace(/unique/, '').replace(/\[|]/g, '')
                };
            } else {
                newValidator = {
                    type: name
                };
            }
            const validator = Gikam.extend({}, newValidator);
            validator.title = item.title;
            this.validators[item.field].push(validator);
        });
    }

    setSelectOptions(field, list) {
        this.getField(field).items = list;
    }

    getField(fieldName) {
        return this.editorManager[fieldName];
    }

    /* 
    私有方法
    自动保存时使用，用于获取字段的type和items
     */
    getFieldInfoByData(data) {
        const fieldInfo = {};
        this.options.panels.forEach(panel => {
            panel.fields.forEach(fieldOptions => {
                fieldOptions.type === 'insertableSelect' && fieldOptions.type === 'select';
                if (
                    Gikam.isNotEmpty(data[fieldOptions.field], true) &&
                    ['select', 'simpleCheckbox'].indexOf(fieldOptions.type) > -1 &&
                    !fieldOptions.remoteSearch
                ) {
                    const options = {
                        type: fieldOptions.type === 'simpleCheckbox' ? 'checkbox' : fieldOptions.type || 'text'
                    };
                    if (fieldOptions.type === 'select') {
                        const items = this.getField(fieldOptions.field).items.map(item => {
                            return { text: item.text, value: item.value };
                        });
                        options.items = items;
                    }
                    fieldInfo[fieldOptions.field] = options;
                }
            });
        });
        return fieldInfo;
    }

    autoSave(data, header) {
        if (!this.options.autoSave) {
            return;
        }
        //审计跟踪来源
        Gikam.buttonText = I18N.prop('form.save');
        const realData = this.trigger('beforeUpdate', data, Object.keys(data));
        if (realData === false) {
            return;
        }
        if (Gikam.isPlainObject(realData) && !Gikam.isEmptyObject(realData)) {
            data = realData;
        }
        if (!this.options.instantSavePath) return;
        const update = () => {
            let _this = this;
            Gikam.put(
                this.getInstantSavePath(data),
                Gikam.getJsonWrapper({ t: this.getFieldInfoByData(data) }, [this.options.service, [data]]),
                header
            ).done(() => {
                _this.trigger('updated', data);
                Gikam.buttonText = null;
            });
        };
        if (Gikam.isNotEmpty(this.getData().id)) {
            if (Gikam.isEmpty(data.id)) {
                data.id = this.getData().id;
            }
            update();
        } else {
            if (this.options.insertUrl) {
                const _this = this;
                Gikam.post(this.options.insertUrl, null, header).done(id => {
                    _this.trigger('inserted', id);
                    _this.setData({ id: id }, false);
                    data.id = id;
                    update();
                    Gikam.buttonText = null;
                });
            } else {
                Gikam.warn(`can not find insertUrl in ${this.options.id}`);
            }
        }
    }

    enableAutoSave() {
        this.canAutoSave = true;
    }

    disableAutoSave() {
        this.canAutoSave = false;
    }

    initReadonly() {
        if (this.options.readonly === true) {
            this.options.panels.forEach(panel => {
                panel.fields.forEach(item => {
                    item.readonly = true;
                });
            });
        }
    }

    showFields(fields) {
        FromUtils.toggleFields(fields, 'show', this);
    }

    hideFields(fields) {
        FromUtils.toggleFields(fields, 'hide', this);
    }

    hidePanelById(id) {
        const ids = typeof id === 'string' ? [id] : id;
        this.options.panels.forEach(fields => {
            if (ids.includes(fields.id)) {
                Vue.set(fields, 'hidden', true);
            }
        });
    }

    showPanelById(id) {
        const ids = typeof id === 'string' ? [id] : id;
        this.options.panels.forEach(fields => {
            if (ids.includes(fields.id)) {
                Vue.set(fields, 'hidden', false);
            }
        });
    }

    /**
     * @description 隐藏或显示当前form
     * @public
     * @param {Boolean} 隐藏 true, 显示 false
     * @memberof Grid
     */
    setHidden(isHidden) {
        this.options.hidden = isHidden;
    }

    cleanData() {
        const def = Gikam.getDeferred();
        this.disableAutoSave();
        this.$cleanPanelsData();
        this.options.data = { ext$: {} };
        this.model.$nextTick(() => {
            this.enableAutoSave();
            def.resolve();
        });
        return def;
    }

    $cleanPanelsData() {
        if (Gikam.isNotEmpty(this.options.panels)) {
            this.options.panels.forEach(panel => {
                panel.fields.forEach(field => {
                    Gikam.isNotEmpty(field.value) && (field.value = null);
                });
            });
        }
    }

    /**
     * @description 将字段中设置的value值添加到data中
     * @private
     * @memberof Form
     */
    initFormData() {
        if (Gikam.isNotEmpty(this.options.panels)) {
            this.options.panels.forEach(panel => {
                panel.fields.forEach(field => {
                    if (Gikam.isNotEmpty(field.value)) {
                        Gikam.setFieldValue(this.options.data, field.field, field.value);
                    }
                });
            });
        }
    }

    /**
     *
     * @description 通过查询数据，存储新增或者保存url
     * @private
     * @param {*} xhr
     * @memberof Grid
     */
    storeInstantSavePath(xhr) {
        if (this.options.instantSavePath) {
            return;
        }
        this.options.instantSavePath = Gikam.IFM_CONTEXT + xhr.getResponseHeader('GIKAM-INSTANT-SAVE-PATH');
    }

    /**
     * @description 获取实时保存路径
     * @private
     * @param {*} data
     * @returns
     * @memberof Grid
     */
    getInstantSavePath(data) {
        return Gikam.printf(this.options.instantSavePath, data);
    }

    /**
     * @description 禁止不同用户同时编辑
     * @private
     * @memberof Form
     */
    checkEditable() {
        const formId = this.options.id;
        if (!formId || !this.editorId) return;
        if (Gikam.isEmpty(this.checkEditorFields)) {
            this.options.fields.forEach(field => {
                field.field && !field.readonly && this.checkEditorFields.push(field.field);
            });
        }
        Gikam.post(
            Gikam.IFM_CONTEXT + `/secure/core/module/gikam/repositories/mark-editable/${formId}$${this.editorId}`
        ).then(res => {
            if (!res) {
                if (['sign', 'choose', 'richTextChoose', 'cron', 'image'].indexOf(this.editorType) > -1) {
                    Gikam.getLastModal() && Gikam.getLastModal().close();
                } else {
                    Gikam.simulatedEvent(document, 'mousewheel');
                    Gikam.simulatedEvent(document, 'click');
                }
                this.refresh();
                this.toFieldsReadonly(this.checkEditorFields);
                Gikam.alert(I18N.prop('form.checkEditable'));
            } else {
                clearInterval(this.timer);
                this.timer = null;
                this.toFieldsEdit(this.checkEditorFields);
            }
        });
    }

    /**
     * @description 获取焦点后循环触发checkEditable
     * @private
     * @memberof Form
     */
    repeatCheckEditor() {
        if (!this.options.id || !this.editorId) return;
        if (this.timer) {
            clearInterval(this.timer);
            this.timer = null;
        }
        this.checkEditable();
        this.timer = setInterval(() => {
            this.checkEditable();
        }, 7e3);
    }

    /**
     * @private
     * @description
     * @memberof Form
     */
    validateExistsError(noModal) {
        const errorMessages = this.errorFields.map(error => {
            return {
                title: error.title,
                message: error.message
            };
        });
        if (Gikam.isNotEmpty(errorMessages)) {
            !noModal && this.showValidateMessage(errorMessages);
            return false;
        }
        return true;
    }

    init() {
        processFormFields(this);
        this.registerValidators();
        this.initFormData();
        this.initReadonly();
        this.createModel();
    }

    uploadSingleFile(dbTable, bizId, otherParam = {}) {
        const def = Gikam.getDeferred();
        const fileData = this.getFileData(otherParam?.field);
        const files = [];
        for (let field in fileData) {
            const fileList = fileData[field];
            if (Gikam.isEmpty(fileList)) {
                continue;
            }
            const file = fileList[0];
            const formData = new FormData();
            formData.append('targetId', dbTable + '$' + bizId);
            formData.append('name', file.name);
            formData.append('file', file);
            if (otherParam) {
                otherParam.bizCategory && formData.append('bizCategory', otherParam.bizCategory);
                otherParam.scope && formData.append('scope', otherParam.scope);
                otherParam.ext && formData.append('ext', otherParam.ext);
            }
            files.push(formData);
        }
        if (Gikam.isEmpty(files)) {
            return def.resolve();
        }
        if (!dbTable || !bizId) {
            Gikam.error('can not find dbTable or bizId');
            return def.resolve();
        }
        let xhr = new XMLHttpRequest();
        xhr.open('post', Gikam.IFM_CONTEXT + '/core/module/item/files', true);
        xhr.onreadystatechange = function() {
            if (this.readyState !== 4) {
                return;
            }
            if (this.status === 200) {
                def.resolve(this.response || this.responseText);
            } else {
                def.reject();
            }
        };
        xhr.send(files[0]);
        return def;
    }

    /**
     * @description 字段值改变
     * @memberof Form
     * @private
     */
    // eslint-disable-next-line complexity
    filedChangeHandle({ field, value }) {
        // 组件初始化完成之后，触发的change事件才会提示
        if (this.$renderState !== 'ready') {
            return;
        }
        if (!this.initData) {
            this.initData = {};
        }
        let isFieldChange = Gikam.getFieldValue(this.initData, field) + '' !== value + '' ? true : false;
        if (value === '' && this.initData[field] === undefined) {
            isFieldChange = false;
        }
        if (isFieldChange === false && Gikam.isNotEmpty(this.$changeFields[field])) {
            delete this.$changeFields[field];
        } else {
            this.$changeFields[field] = value;
        }
        if (this.$changeFields['__ob__']) {
            delete this.$changeFields['__ob__'];
        }
        const changeFlag = Gikam.isEmptyObject(this.$changeFields) ? false : true;
        this.$window && this.$window.changeSaveButtonPrompt(this, changeFlag);
    }

    /**
     * @description 表单只读
     * @param {*} readonlyFlag
     * @memberof Form
     */
    setReadonly(readonlyFlag) {
        const fields = this.options.panels
            .reduce((total, panel) => {
                return [...total, ...panel.fields];
            }, [])
            .map(field => field.field);
        if (readonlyFlag) {
            this.toFieldsReadonly(fields);
        } else {
            this.toFieldsEdit(fields);
        }
    }

    /**
     * @private
     * @description 用于页面配置刷新fields
     * @memberof Form
     */
    refreshFieldsByConfig = refreshFieldsByConfig;

    /**
     * @description
     * @memberof Form
     */
    initDataLock() {
        if (!this.$window) {
            return;
        }
        if (this.$window.isCompLocked(this.options)) {
            this.model.$refs.vm.setLock();
        } else {
            this.model.$refs.vm.setLock(false);
        }
    }

    /**
     * @private
     * @description
     * @returns
     * @memberof Form
     */
    getPageEditorOptions() {
        const options = Gikam.deepExtend(this.options);
        const config = {};
        options.panels.forEach(panel => {
            panel.fields.forEach(field => {
                field.category && delete field.items;
            });
        });
        options.hiddenFields.forEach(field => {
            field.category && delete field.items;
        });
        const properties = ['columns', 'panels', 'hiddenFields', 'layout', 'dbTable', 'titleWidthAuto', 'titleWidth'];
        for (const property of properties) {
            config[property] = options[property];
        }
        return config;
    }

    setDefaultOptions(options) {
        Gikam.extend(true, defaultOptions, options.defaultOptions);
    }
}
