<template>
    <div class="alert error-alert" @keydown.enter="keydownHandle">
        <div class="alert-container">
            <div class="alert-error-icon">
                <closeImg color="#ff3b30" width="16px" height="16px" />
            </div>
            <div class="title">{{ $i18n('errorAlert.title') }}</div>
            <section v-html="options.message"></section>
            <footer>
                <div class="confirm-btn" @click="viewDetail" v-if="options.detailMessage">
                    {{ $i18n('errorAlert.detailMessage') }}
                </div>
                <div class="confirm-btn error-btn" @click="closeHandle">{{ $i18n('errorAlert.confirm') }}</div>
            </footer>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Gikam from 'gikam';
import alert from '../../alert/vue/alert';

export default {
    extends: alert,
    methods: {
        viewDetail() {
            const _this = this;
            this.errorAlert = new Vue({
                el: Gikam.createDom('div', document.body),
                data() {
                    return {
                        options: {
                            title: this.$i18n('errorAlert.viewDetail'),
                            modal: {
                                trigger: Gikam.emptyFunction
                            }
                        },
                        btnOptions: {
                            text: this.$i18n('errorAlert.copy'),
                            class: 'blue',
                            onClick: () => {
                                const copyInput = document.createElement('textarea');
                                copyInput.style.position = 'absolute';
                                copyInput.style.top = '-200px';
                                copyInput.value = _this.options.detailMessage;
                                document.body.appendChild(copyInput);
                                copyInput.select();
                                document.execCommand('copy');
                                Gikam.removeDom(copyInput);
                            }
                        },
                        downloadBtnOptions: {
                            text: this.$i18n('errorAlert.download'),
                            onClick: () => {
                                Gikam.downloadText(
                                    `${this.$i18n('errorAlert.download.fileName')}.txt`,
                                    _this.options.detailMessage
                                );
                            }
                        }
                    };
                },
                components: {
                    Modal: () => import('../../modal/vue/modal'),
                    btn: () => import('@/gikam/js/components/button/vue/button.vue')
                },

                render() {
                    return (
                        <Modal options={this.options}>
                            <div slot="modal-body" class="error-detail">
                                <div class="error-detail-content">
                                    <scroll scrollingX={false} innerPanel={true}>
                                        {_this.options.detailMessage}
                                    </scroll>
                                </div>
                                <div class="error-detail-toolbar">
                                    <btn options={this.btnOptions} style="margin-right:8px;" />
                                    <btn options={this.downloadBtnOptions} />
                                </div>
                            </div>
                        </Modal>
                    );
                },

                keydownHandle() {
                    this.$emit('click');
                }
            });
        },

        closeHandle() {
            this.$emit('click');
        }
    }
};
</script>

<style>
.error-detail {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.error-detail-content {
    flex: 1;
    font-size: 14px;
    padding: 0 16px 16px;
    color: rgba(0, 0, 0, 0.65);
    word-wrap: break-word;
    overflow: hidden;
}

.error-detail-toolbar {
    padding: 16px;
    display: flex;
    justify-content: flex-end;
}
</style>
