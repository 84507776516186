<template>
    <image-input
        ref="editor"
        :options="options"
        :propValue="propValue"
        :propReadonly="readonly"
        :validateArg="validateArg"
        :dataId="dataId"
        @editing="editingHandle"
    ></image-input>
</template>

<script>
import imageInput from '@/gikam/js/components/template/fields/image.vue';
import { mapState } from 'vuex';

export default {
    props: {
        options: Object,
        propValue: String,
        validateArg: Object,
        dataId: [Number, String]
    },

    components: {
        imageInput
    },

    data() {
        return {
            readonly: this.options.readonly ?? false
        };
    },

    computed: {
        ...mapState(['form'])
    },

    mounted() {
        this.form.editorManager[this.options.field] = this.$refs.editor;
    },

    methods: {
        editingHandle(...args) {
            this.$emit.apply(this, ['editing', ...args]);
        }
    }
};
</script>
