import Vue from 'vue';
import Gikam from 'gikam';

const observeManager = [];

export default function() {
    Vue.directive('observe-size', {
        inserted(el, binding) {
            const prop = binding.value;
            const param = {
                el,
                width: el.offsetWidth,
                height: el.offsetHeight,
                callback: Gikam.isPlainObject(prop) ? prop.handler : prop,
                scope: this
            };
            observeManager.push(param);
            if (Gikam.isPlainObject(prop) && prop.immediate) {
                prop.handler.call(this, { width: el.offsetWidth, height: el.offsetHeight });
            }
        },

        unbind(el) {
            const index = observeManager.findIndex(({ el: cacheEl }) => cacheEl === el);
            observeManager.splice(index, 1);
        }
    });

    setInterval(() => {
        for (let i = observeManager.length - 1; i > -1; i--) {
            const wrapper = observeManager[i];
            const { el, width, height, callback, scope } = wrapper;
            const { offsetHeight, offsetWidth } = el;
            const changeParam = {};
            if (width !== offsetWidth) {
                changeParam.width = offsetWidth;
            }
            if (height !== offsetHeight) {
                changeParam.height = offsetHeight;
            }
            if (!Gikam.isEmptyObject(changeParam)) {
                callback.call(scope, changeParam);
                Object.assign(wrapper, changeParam);
            }
        }
    }, 500);
}
