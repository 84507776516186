import Gikam from 'gikam';
import selectHelper from '@/gikam/js/core/selectHelper';
import { Sequence } from '@/gikam/js/core/sequence';

export default class SelectSource {
    static cache = {};

    static config = selectHelper.getConfig();

    static loadingManager = {};

    static getRemoteItems(option) {
        const cacheKey = SelectSource.getItemCacheKey(option);
        const itemConfig = SelectSource.config[option.category];
        return new Promise(resolve => {
            const loadingResolves = SelectSource.loadingManager[cacheKey];
            if (loadingResolves) {
                loadingResolves.push(resolve);
            } else {
                SelectSource.loadingManager[cacheKey] = [];
                const url = Gikam.addUrlParam(Gikam.getContextUrl(itemConfig.url), option.param);
                const tempValueMapper = {};
                Gikam.getJson(url).done(function(itemList) {
                    const items = [];
                    itemList.forEach(item => {
                        const value = Gikam.getFieldValue(item, itemConfig.valueField);
                        const text = Gikam.propI18N(Gikam.getFieldValue(item, itemConfig.textField));
                        if (!tempValueMapper[value]) {
                            items.push(Object.assign(item, { key: Sequence.nextWithTime(), value, text }));
                            tempValueMapper[value] = true;
                        }
                    });
                    if (itemConfig.cacheable === '1') {
                        SelectSource.cache[cacheKey] = items;
                    }
                    if (option.firstBlank !== false) {
                        items.unshift({
                            text: '',
                            value: ''
                        });
                    }
                    resolve(items);
                    SelectSource.loadingManager[cacheKey].forEach(resolve => resolve(items));
                    delete SelectSource.loadingManager[cacheKey];
                });
            }
        });
    }

    static getItemCacheKey(option) {
        const config = SelectSource.config[option.category];
        return Gikam.addUrlParam(config.url, option.param);
    }

    static async getItems(option) {
        if (Gikam.isNotEmpty(option.items)) {
            return option.items;
        }
        if (!option.category) {
            return [];
        }
        let resultItems = SelectSource.cache[SelectSource.getItemCacheKey(option)];
        if (resultItems) {
            return resultItems;
        }
        return await SelectSource.getRemoteItems(option);
    }
}
