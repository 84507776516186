import Vuex from 'vuex';
import Vue from 'vue';
import Gikam from '../../../core/gikam-core';
import { GridUtils } from './grid-utils';
import { PageEditAuthorityUtils } from '@/gikam/js/components/pageEdit/page-edit-authority-utils.js';

Vue.use(Vuex);

class GridStore {
    constructor(options, grid) {
        this.options = options;
        this.grid = grid;
        this.initStore();
    }

    refreshGrid(data) {
        this.grid.setOptions(data);
        Gikam.finalDelay(
            'searchGrid',
            () => {
                this.grid.refresh(data);
            },
            100
        );
    }

    pageSearch({ value, text, column, fieldName }) {
        this.grid.$setPageRequest({
            field: fieldName,
            value,
            column: column,
            selectText: text || null
        });
        Gikam.finalDelay(
            'searchGrid',
            () => {
                this.grid.pageSearchGrid();
            },
            100
        );
    }

    initStore() {
        const _this = this;
        this.options.columns = this.getColumns();
        this.store = new Vuex.Store({
            state: {
                requestData: Gikam.deepExtend(this.options.requestData),
                headRequestData: this.options.headRequestData,
                generalRequestData: [],
                fieldTypeMapper: null,
                columns: this.options.columns,
                gridId: this.options.id,
                grid: this.grid,
                $window: this.grid.$window,
                pageNum: this.options.pageNum,
                pageSize: this.options.pageSize,
                page: this.options.page,
                pageList: this.options.pageList,
                totalRecord: this.options.data?.length || 0,
                tableWidth: 0,
                headerHeight: 0,
                scrollTop: 0,
                scrollLeft: 0,
                columnsFill: this.options.columnsFill,
                defaultColumnWidth: this.options.defaultColumnWidth,
                renderTo: this.options.renderTo,
                readonly: this.options.readonly,
                contextmenu: this.options.contextmenu,
                totalMap: {},
                activeCoordinate: [0, 0],
                // 正在编辑的字段信息
                editingChildren: {},
                // TreeGrid中勾选节点容器，不包含半选状态
                checkedTreeNodes: [],
                dataLength: 0,
                requireState: false,
                // 正在请求中
                loading: false,
                // 表格需要加载的数据
                totalData: null,
                // 表格显示的数据
                viewData: null,
                // 是否服务器端查询
                serverSearch: this.options.serverSearch,
                url: this.options.url,
                // 是否有右边框，如果宽度100%的情况下是没有有边框的，否则会跟容器边框重合，造成边框重叠变粗的问题
                borderRight: false,
                borderBottom: true,
                // 导出时是否带有样式
                exportWithStyle: this.options.exportWithStyle,
                // 页面是否处于编辑状态
                pageEditing: this.grid.$window?.pageEditing,
                // 懒加载分页参数
                lazyLoad: {
                    enable: this.grid.isLazyLoad(),
                    pageSize: 1,
                    pageNumber: 1,
                    totalPage: 1,
                    totalRows: this.options.data || [],
                    beginIndex: 0,
                    rows: [],
                    loading: false
                },
                // 宽度适配列
                autoWidthColumn: null,
                commonSearch: this.options.commonSearch,
                commonSearchData: null,
                filterImmediate: this.options.filterImmediate,
                exportExcelHeader: this.options.exportExcelHeader,
                loadingMode: this.options.loadingMode,
                textLineFeed: this.options.textLineFeed,
                activeRowDataKey: null,
                checkedRowKeys: [],
                // TreeGrid勾选的节点
                treeCheckedRowKeys: [],
                showCheckedNum: this.options.showCheckedNum,
                allChecked: false,
                checkedStorage: {},
                checkedIdsStorage: [],
                storageTurn: true,
                checkContinuous: this.options.checkContinuous,
                group: this.options.group,
                // 刷新之前选中的行
                lastCheckedRowKeys: [],
                showCheckedBadgeGt: this.options.showCheckedBadgeGt,
                allCheckContinuousData: []
            },

            getters: {
                totalPage(state) {
                    return Math.ceil(state.totalRecord / state.pageSize) || 1;
                },

                defaultColumns() {
                    return _this.grid.$rawColumns;
                }
            },

            mutations: {
                changeRequireState(state, requireState) {
                    state.requireState = requireState;
                },

                refresh(_state, data) {
                    _this.refreshGrid(data);
                },

                pageSearch(_state, data) {
                    _this.pageSearch(data);
                },

                refreshColumns(state, columns) {
                    state.columns = columns;
                    _this.options.columns = columns;
                    state.chooseColumns.columnsSelectIndex = null;
                },

                refreshColumnsConfig(state, columns) {
                    // 对于已经配置的字段，需要同步列配置
                    _this.processSettingColumns(columns);
                    _this.store.commit('refreshColumns', columns);
                },

                refreshTypeMapper(state, Mapper) {
                    state.fieldTypeMapper = Mapper;
                },

                updatePageSize(state, pageSize) {
                    state.pageSize = pageSize;
                    state.pageNum = 1;
                    _this.refreshGrid();
                },

                updatePageNum(state, pageNum) {
                    state.pageNum = pageNum;
                    _this.refreshGrid();
                },

                updateTotalRecord(state, totalRecord) {
                    state.totalRecord = totalRecord;
                },

                changeScrollTop(state, scrollTop) {
                    state.scrollTop = scrollTop;
                },

                changeScrollLeft(state, left) {
                    state.scrollLeft = left;
                    const vuescroll = state.grid.model.$refs.vm.$refs.vs;
                    const { scrollLeft } = vuescroll.getPosition();
                    if (left !== scrollLeft) {
                        vuescroll.scrollTo({ x: left }, 0);
                    }
                },

                updateContextmenu(state, menu) {
                    state.contextmenu = menu;
                },

                updateDataLength(state, length) {
                    state.dataLength = length;
                },

                changeActiveCoordinate(state, coordinate) {
                    if (Array.isArray(coordinate)) {
                        state.activeCoordinate = coordinate;
                    } else {
                        state.activeCoordinate = null;
                    }
                },

                changeEditor(state, editing) {
                    if (editing && Array.isArray(editing.coordinate)) {
                        state.editingChildren = editing;
                    } else {
                        state.editingChildren = {};
                    }
                },

                changeRequestData(state, requestData) {
                    state.requestData = Gikam.deepExtend(state.requestData, requestData);
                },

                changeGeneralRequestData(state, generalRequestData) {
                    state.generalRequestData = generalRequestData;
                },

                updateHeadRequestData(state, data) {
                    const headRequestData = state.headRequestData;
                    for (let field in data) {
                        if (Gikam.isEmpty(data[field])) {
                            for (let headField in headRequestData) {
                                if (headField.split('_')[0] === field.split('_')[0]) {
                                    Vue.delete(headRequestData, headField);
                                }
                            }
                        } else {
                            Vue.set(headRequestData, field, data[field]);
                        }
                    }
                },

                addCheckedTreeNodes(state, node) {
                    state.checkedTreeNodes.push(node);
                    state.checkedTreeNodes.sort((a, b) => {
                        return a.id - b.id;
                    });
                },

                removeCheckedTreeNodes(state, node) {
                    const index = state.checkedTreeNodes.indexOf(node);
                    index > -1 && state.checkedTreeNodes.splice(index, 1);
                },

                cleanCheckedTreeNodes(state) {
                    state.checkedTreeNodes.length = 0;
                },

                changeCheckedIds(state, row) {
                    if (state.checkedStorage[row.id]) {
                        delete state.checkedStorage[row.id];
                        state.checkedIdsStorage = Object.values(state.checkedStorage).map(item => item.id);
                    } else {
                        state.checkedStorage[row.id] = row;
                        state.checkedIdsStorage.push(row.id);
                    }
                },

                mergeCheckedIds(state, data) {
                    data.forEach(row => (state.checkedStorage[row.id] = row));
                    state.checkedIdsStorage = Object.values(state.checkedStorage).map(item => item.id);
                },

                changeTurn(state, boolean) {
                    state.storageTurn = boolean;
                },

                setContinuousData(state, data) {
                    state.allCheckContinuousData = data;
                    const map = {};
                    const ids = [];
                    data.forEach(row => {
                        map[row.id] = row;
                        ids.push(row.id);
                    });
                    state.checkedStorage = map;
                    state.checkedIdsStorage = ids;
                    state.grid.options.onAllSelect?.call(state.grid, state.allCheckContinuousData);
                },

                setTotalData(state, totalData) {
                    state.totalData = totalData;
                },

                setViewData(state, viewData) {
                    state.viewData = viewData;
                },

                setBorderRight(state, border) {
                    state.borderRight = border;
                },

                setBorderBottom(state, border) {
                    state.borderBottom = border;
                },

                setHeadRequestData(state, data) {
                    state.headRequestData = data;
                },

                setPageEditing(state, editing) {
                    state.pageEditing = editing;
                },

                setData(state, data) {
                    const options = state.grid.options;
                    if (!state.lazyLoad.enable) {
                        options.data = data;
                    } else {
                        const pageSize = Math.floor(document.body.offsetHeight / 25);
                        const totalRows = data;
                        const rows = data.slice(0, pageSize * 2);
                        const totalPage = Math.ceil(data.length / pageSize);
                        Object.assign(state.lazyLoad, {
                            pageSize,
                            totalRows,
                            rows,
                            totalPage,
                            pageNumber: 1,
                            beginIndex: 0
                        });
                        options.data = rows;
                    }
                },

                setLazyPage(state, offsetPage) {
                    if (!state.lazyLoad.enable) {
                        return;
                    }
                    const { pageNumber, totalPage } = state.lazyLoad;
                    const nextPageNumber = pageNumber + offsetPage;
                    if (nextPageNumber < 1 || pageNumber > totalPage) {
                        return;
                    }
                    const options = state.grid.options;
                    state.lazyLoad.pageNumber = nextPageNumber;
                    const beginIndex = (state.lazyLoad.pageNumber - 1) * state.lazyLoad.pageSize;
                    const endIndex = beginIndex + state.lazyLoad.pageSize * 2;
                    state.lazyLoad.beginIndex = beginIndex;
                    state.lazyLoad.rows = state.lazyLoad.totalRows.slice(beginIndex, endIndex);
                    state.rows = state.lazyLoad.rows;
                    options.data = state.rows;
                },

                setTableWidth(state, width) {
                    state.tableWidth = width;
                },

                setAutoWidthColumn(state, column) {
                    state.autoWidthColumn = column;
                },

                setLazyLoading(state, loading) {
                    state.lazyLoad.loading = loading;
                },

                setCommonSearchData(state, searchData) {
                    state.commonSearchData = searchData;
                },

                setActiveRowDataKey(state, activeRowDataKey) {
                    state.activeRowDataKey = activeRowDataKey;
                },

                setCheckedRowKeys(state, keys) {
                    state.checkedRowKeys = keys;
                    if (!keys.length) {
                        state.allChecked = false;
                    }
                },

                setTreeCheckedRowKeys(state, keys) {
                    state.treeCheckedRowKeys = keys;
                },

                cleanDataBeforeRefresh(state) {
                    state.lastCheckedRowKeys = state.checkedRowKeys;
                    state.lastActiveRowDataKey = state.activeRowDataKey;
                    state.activeRowDataKey = null;
                    state.allChecked = false;
                    if (!state.checkContinuous) {
                        state.checkedRowKeys = [];
                        state.grid.groupValueMapper = {};
                    }
                },

                checkRow(state, { rowData, triggerEvent = true }) {
                    const rowDataKey = GridUtils.getRowVueState(rowData, 'key');
                    const rowDataType = GridUtils.getRowType(rowData);
                    if (state.checkedRowKeys.includes(rowDataKey)) {
                        return;
                    }
                    if (state.columns[0].radio) {
                        state.checkedRowKeys.forEach(key => {
                            const rowData = state.grid.dataMapper[key];
                            state.grid.store.commit('unCheckRow', { rowData, triggerEvent: false });
                        });
                    }
                    GridUtils.setRowVueState(rowData, 'checked', true);
                    if (rowDataType !== 'group') {
                        state.checkedRowKeys.push(rowDataKey);
                    }
                    if (triggerEvent) {
                        state.grid.trigger('select', rowData);
                    }
                    if (state.grid.getTotalData().length === state.checkedRowKeys.length) {
                        state.allChecked = true;
                    }
                },

                unCheckRow(state, { rowData, triggerEvent = true }) {
                    GridUtils.setRowVueState(rowData, 'checked', false);
                    const rowDataKey = GridUtils.getRowVueState(rowData, 'key');
                    const index = state.checkedRowKeys.indexOf(rowDataKey);
                    index > -1 && state.checkedRowKeys.splice(index, 1);
                    if (triggerEvent) {
                        state.grid.trigger('unSelect', rowData);
                    }
                    state.allChecked = false;
                },

                cleanActiveRow(state, param) {
                    if (state.activeRowDataKey) {
                        const activeRowData = state.grid.dataMapper[state.activeRowDataKey];
                        GridUtils.setRowVueState(activeRowData, 'active', false);
                        state.activeRowDataKey = null;
                        param.checked && GridUtils.unCheckRow(activeRowData, param.grid);
                    }
                },

                activeRow(state, { rowData }) {
                    if (state.grid.trigger('beforeRowActive') === false) {
                        return;
                    }
                    // 清空已存在的激活行
                    if (state.activeRowDataKey) {
                        const activeRowData = state.grid.dataMapper[state.activeRowDataKey];
                        GridUtils.setRowVueState(activeRowData, 'active', false);
                    }
                    // 更新当前激活行
                    state.activeRowDataKey = GridUtils.getRowVueState(rowData, 'key');
                    GridUtils.setRowVueState(rowData, 'active', true);
                    const rowIndex = GridUtils.getRowVueState(rowData, 'rowIndex');
                    state.grid.trigger('rowActive', rowIndex, rowData);
                },

                setAllChecked(state, checked) {
                    const { onAllSelect, onUnAllSelect, id, checkContinuous } = state.grid.options;
                    state.allChecked = checked;
                    if (checkContinuous) {
                        if (checked) {
                            Gikam.finalDelay(
                                id + 'get-all-data',
                                () => state.grid.store.dispatch('getContinuousData'),
                                41
                            );
                        } else {
                            Object.values(state.grid.dataMapper).forEach(rowData => {
                                GridUtils.setRowVueState(rowData, 'checked', false);
                            });
                            state.checkedStorage = {};
                            state.checkedIdsStorage = [];
                            state.checkedRowKeys = [];
                            onUnAllSelect?.call(state.grid, []);
                        }
                    } else {
                        const checkedRowKeys = [];
                        const totalData = state.grid.getTotalData();
                        totalData.forEach(rowData => {
                            GridUtils.setRowVueState(rowData, 'checked', checked);
                            if (GridUtils.getRowType(rowData) !== 'group') {
                                checkedRowKeys.push(GridUtils.getRowVueState(rowData, 'key'));
                            }
                        });
                        if (checked) {
                            state.checkedRowKeys = checkedRowKeys;
                            onAllSelect?.call(state.grid, totalData);
                        } else {
                            state.checkedRowKeys = [];
                            onUnAllSelect?.call(state.grid, []);
                        }
                    }
                },

                setGroupOptions(state, options) {
                    state.group = options;
                },

                updateColumnReadonly(state, options) {
                    const { fields = [], readonly } = options;
                    fields.forEach(ele => {
                        const column = state.columns.find(col => col.field === ele);
                        column.readonly = readonly;
                    });
                },

                setUrl(state, url) {
                    state.url = url;
                }
            },

            actions: {
                getContinuousData({ commit }) {
                    const params = JSON.parse(_this.grid.queryParams());
                    params.p.n = -1;
                    Gikam.showMask().then(() => {
                        Gikam.post(_this.grid.options.url, JSON.stringify(params))
                            .done(r => commit('setContinuousData', r?.rows || []))
                            .fail(() => commit('setContinuousData', []))
                            .always(Gikam.cleanMask());
                    });
                }
            },

            modules: {
                chooseColumns: {
                    namespaced: true,
                    state: {
                        columnsSelectIndex: null
                    },
                    mutations: {
                        changeColumnsSelectIndex(state, number) {
                            if (Gikam.isNumber(number)) {
                                state.columnsSelectIndex = number;
                            } else {
                                state.columnsSelectIndex = null;
                            }
                        }
                    }
                },

                columnsGroup: {
                    namespaced: true,
                    state: {
                        groupMap: {},
                        relationship: {}
                    },
                    mutations: {
                        changeGroupMap(state, groupMap) {
                            state.groupMap = groupMap;
                        },

                        changeRelationship(state, relationship) {
                            state.relationship = relationship;
                        }
                    }
                }
            }
        });
    }

    /**
     * @description 获取表格列，优先从配置文件中获取
     * @memberof Grid
     * @returns Array
     */
    getColumns() {
        const columns = Gikam.deepExtend(this.options.columns);
        const fieldMapper = {};

        this.grid.initialColumns.forEach(column => {
            if (Gikam.isNotEmpty(column.field)) {
                fieldMapper[column.field] = column;
            }
        });

        if (this.options.useStorageColumns === false) {
            return columns;
        }
        const manager = Gikam.compConfigManager;
        const gridId = this.grid.options.id;
        const preColumns = [];
        columns.forEach(column => {
            column.width = column.width || null;
            const initialMapperField = fieldMapper[column.field];
            if (this.grid.$fromPageConfig && initialMapperField) {
                Object.getOwnPropertyNames(initialMapperField).forEach(name => {
                    if (column.hasOwnProperty(name) || name === '__ob__') {
                        return;
                    }
                    column[name] = initialMapperField[name];
                });
            }
        });
        if (this.grid.$fromPageConfig) {
            PageEditAuthorityUtils.initAuthority(columns);
        }

        if (!manager.grid || !manager.grid[gridId]) {
            return columns;
        }
        let settingColumns = Gikam.deepExtend(manager.grid[gridId].columns);
        for (let i = settingColumns.length - 1; i > -1; i--) {
            if (settingColumns[i].orderNo) {
                delete settingColumns[i].orderNo;
            }
            const filterColumn = columns.filter(item => item.field && item.field === settingColumns[i].field);
            if (Gikam.isEmpty(filterColumn)) {
                //删除的列去除
                if (Gikam.pageDesignConfig?.enable !== 'true') {
                    settingColumns.splice(i, 1);
                }
            } else {
                settingColumns.splice(i, 1, Gikam.extend(filterColumn[0], settingColumns[i]));
            }
        }
        this.appendNewColumns(columns, preColumns, settingColumns);
        return this.processHiddenFields([...preColumns, ...settingColumns]);
    }

    appendNewColumns(columns, preColumns, settingColumns) {
        //新增的列追加
        columns.forEach(item => {
            if (item.checkbox || item.radio || item.index === true) {
                preColumns.push(item);
                return;
            }
            if (this.options.hiddenFields?.findIndex(hiddenField => hiddenField.field === item.field) > -1) {
                return;
            }
            if (!settingColumns.some(settingColumn => settingColumn.field === item.field)) {
                settingColumns.push(item);
            }
        });
    }

    processHiddenFields(finalColumns) {
        for (let i = finalColumns.length - 1; i > -1; i--) {
            const item = finalColumns[i];
            if (item.visible === '0' || item.visible === false) {
                this.options.hiddenFields.push(item);
            }
        }
        return finalColumns;
    }

    processSettingColumns(dynamicColumns) {
        const columns = Gikam.deepExtend(this.options.columns);
        const filedMapper = {};
        columns.forEach(column => {
            if (column.field) {
                filedMapper[column.field] = column;
            }
            if (!Gikam.isBoolean(column.index)) {
                delete column.index;
            }
        });
        dynamicColumns.forEach(column => {
            if (filedMapper[column.field]) {
                const oldColumn = filedMapper[column.field];
                if (Gikam.isEmpty(column.visible)) {
                    column.visible = oldColumn.visible;
                }
                if (Gikam.isEmpty(column.fixed)) {
                    column.fixed = oldColumn.fixed;
                }
            }
            if (!Gikam.isBoolean(column.index)) {
                delete column.index;
            }
        });
    }
}

export { GridStore };
