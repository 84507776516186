<template>
    <div class="cell" :class="{ index: indexClass }">
        <checkbox
            v-if="propsFeild.checkbox"
            :propChecked="checkAll || checkboxIsSelect"
            @change="selectCheckbox"
            :externalControl="true"
        ></checkbox>
        <radio
            v-else-if="propsFeild.radio"
            :propChecked="checkboxIsSelect"
            @change="selectRadio"
            :externalControl="true"
        ></radio>
        <div v-else-if="propsFeild.index">{{ indexText }}</div>
        <sunway-field
            v-else
            :propsFeild="propsFeild"
            :propsRow="propsRow"
            :rowIndex="rowIndex"
            :colIndex="colIndex"
            @activeRowEvent="activeRowHandler"
            ref="editorField"
        ></sunway-field>
    </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import field from './field';
import Gikam from 'gikam';
export default {
    name: 'sunway-body',
    props: ['propsFeild', 'propsRow', 'rowIndex', 'colIndex'],
    computed: {
        ...mapState(['data']),
        ...mapGetters(['activeOnCheck', 'checkOnActive']),
        ...mapState('moveCursor/', ['currentCellRow']),
        ...mapState('selectRow/', ['checkAll']),
        ...mapGetters('selectRow/', ['getSelectIds']),
        indexClass() {
            return this.propsFeild.index || this.propsFeild.radio || this.propsFeild.checkbox || false;
        },
        indexText() {
            return this.propsRow.gridIndex || this.rowIndex + 1;
        },
        checkboxIsSelect() {
            return this.getSelectIds.includes(String(this.propsRow.id)) ? true : false;
        }
    },

    methods: {
        ...mapMutations('gridBody/', ['changeActiveRowIndex']),
        ...mapMutations('selectRow/', ['changeSelectMap', 'updateSelectMap', 'changeCheckAll']),

        getCell() {
            return this.$refs.editorField ? this.$refs.editorField.getCellComp() : null;
        },

        selectCheckbox(checked) {
            this.updateSelectMap({
                id: this.propsRow.id,
                selectState: !checked
            });

            if (this.checkOnActive === true) {
                this.changeActiveRowIndex({ rowIndex: this.rowIndex, colIndex: this.colIndex, type: 'row' });
            }

            //联动表头全选
            //全选true时 取消某个元素的选中状态
            if (Gikam.isTrue(this.checkAll) && Gikam.isFalse(this.checked)) {
                return this.changeCheckAll(false);
            }
            //全选false时， 取消某个选中态 不做操作
            if (Gikam.isFalse(this.checkAll) && Gikam.isFalse(this.checked)) {
                return;
            }

            if (this.getSelectIds.length === this.data.length) {
                return this.changeCheckAll(true);
            }
        },

        activeRowHandler() {
            if (this.activeOnCheck === true) {
                this.updateSelectMap({
                    id: this.propsRow.id,
                    selectState: !this.checkboxIsSelect
                });
            }
        },

        selectRadio(checked) {
            this.changeSelectMap({
                id: this.propsRow.id,
                selectState: !checked
            });

            if (this.checkOnActive === true) {
                this.changeActiveRowIndex({ rowIndex: this.rowIndex, colIndex: this.colIndex, type: 'row' });
            }
        }
    },

    components: {
        sunwayField: field
    }
};
</script>

<style scoped>
.cell {
    min-height: 30px;
    position: relative;
}

.cell.index {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
