import Base from '@/gikam/js/components/base.js';
import Gikam from 'gikam';
import { initStore } from './lesStore';
import Vue from 'vue';
import { getTopButton } from '../js/lesConfig';
import { addContent, importEvent, printEvent, auditTrailEvent, backEvent, addCordEvent } from './windowButtonEvent';

import { getPageCss, getPageHtml, getHtmlCompType } from './utils';

import { importFormPdfCss } from './printCss';

const defaultOptions = {
    id: void 0,
    componentConfig: {
        ckeditorConvertContent: false
    },
    renderTo: void 0,
    saveTemplate: false // 是否保存页面模
};

export default class Les extends Base {
    constructor(props) {
        super();
        this.initialize(props, defaultOptions).init();
    }

    accessOutInserts() {
        this.store.commit('outComp/changeComps', this.options.appendComps);
        this.store.commit('section/changeStoreMember', {
            key: 'section',
            value: this.options.sectionButtons
        });
    }

    addIdentification() {
        this.options.interfaceType = this.options.instance ? 'instance' : 'template';
    }

    async createModel() {
        const _this = this;
        this.model = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            store: this.store,
            provide() {
                return {
                    initOptions: _this.options,
                    $getGlobalAnnexData: _this.getAnnexLists.bind(_this),
                    $getModalTemplate: _this.getTemplates.bind(_this),
                    $getTreeGrid: _this.getData.bind(_this),
                    $saveTemplate: _this.saveTemplate.bind(_this),
                    $getCreateTopName: _this.getCreateTopName.bind(_this)
                };
            },
            components: {
                les: () => import('@/gikam/js/components/huge/les/vue/les.vue')
            },
            data() {
                return { options: _this.options };
            },
            render() {
                return <les ref={'vm'} options={this.options}></les>;
            }
        });
        await this.model.$nextTick();
        this.bindInstance(this.model.$el);
    }

    createTopButton() {
        const buttonEvent = {
            backEvent: backEvent.bind(this),
            importEvent: importEvent.bind(this),
            printEvent: printEvent.bind(this),
            addCordEvent: addCordEvent.bind(this)
        };

        if (this.store.getters['tool/isTemplate']) {
            buttonEvent.addEvent = addContent.bind(this);
        }

        if (this.store.getters['tool/isInstances']) {
            buttonEvent.auditTrailEvent = auditTrailEvent.bind(this);
        }
        let topButtonArray = [];
        const fixedButton = getTopButton(buttonEvent);
        if (this.options.lesButtonGroups) {
            const lesType = this.store.state.tool.lesReadonly ? 'readonly' : this.store.state.tool.tempType;
            const outInsetButton = this.options.lesButtonGroups.filter(
                item => !item.mode || item.mode.includes(lesType)
            );
            outInsetButton.forEach(buttonMap => {
                buttonMap.onClick = buttonMap.onClick.bind(
                    this,
                    Gikam.deepExtend(this.store.state.lesInfo),
                    this.store.state.allChildrenVue
                );
            });

            topButtonArray = outInsetButton.concat(fixedButton);
        } else {
            topButtonArray = fixedButton;
        }

        if (this.options.$parent_toolbar) {
            this.options.$parent_toolbar.innerHTML = '';
        }

        const toolbar = this.$parent?.$toolbar ? this.$parent.$toolbar : this.options.$parent_toolbar;
        //创建头部模板名称
        const title = document.createElement('div');
        title.id = 'topTemplateName';

        const topTemplateName = document.getElementById('topTemplateName');
        Gikam.isNotEmpty(topTemplateName) && topTemplateName.parentElement.removeChild(topTemplateName);
        toolbar.parentElement.insertAdjacentElement('afterbegin', title);

        Gikam.create('btnToolbar', {
            renderTo: toolbar,
            items: topButtonArray
        });
        this.getCreateTopName();
    }

    //标题处创建模板名称
    getCreateTopName() {
        if (this.templateNameModel) {
            this.templateNameModel.$el.parentNode?.removeChild(this.templateNameModel.$el);
            this.templateNameModel.$destroy();
            this.templateNameModel = null;
        }
        const title = document.getElementById('topTemplateName');
        const lesInfo = this.store.state.lesInfo;
        const _this = this;
        this.templateNameModel = new Vue({
            el: Gikam.createDom('div', title),
            store: _this.store,
            components: {
                templateName: () => import('@/gikam/js/components/huge/les/vue//template/templateName.vue')
            },
            data: {
                templateName: _this.store.state.lesInfo.templateName
            },
            render() {
                return <template-name options={lesInfo}> </template-name>;
            }
        });
    }

    getCompLists() {
        return new Promise(resolve => {
            Gikam.post(Gikam.IFM_CONTEXT + '/secure/core/les/les-units/queries/choosable')
                .done(result => {
                    result.rows.forEach(item => {
                        const config = item.config
                            ? JSON.parse(item.config)
                            : { toolButtons: ['edit', 'remove', 'moveUp', 'moveDown', 'print', 'audit'] };
                        item.button = config.toolButtons;
                    });
                    this.store.commit('modal/changeTypeList', result.rows);
                })
                .fail(() => {
                    this.options.modalList?.forEach(item => {
                        const config = item.config
                            ? JSON.parse(item.config)
                            : { toolButtons: ['edit', 'remove', 'moveUp', 'moveDown', 'print', 'audit'] };
                        item.button = config.toolButtons;
                    });
                    this.options.modalList && this.store.commit('modal/changeTypeList', this.options.modalList);
                })
                .always(() => {
                    resolve();
                });
        });
    }

    getLesMessage() {
        return new Promise(resolve => {
            Gikam.getJson(
                Gikam.printf(Gikam.IFM_CONTEXT + '/secure/core/les/les-{interfaceType}s/{id}', {
                    interfaceType: this.options.interfaceType,
                    id: this.options.id
                })
            )
                .done(result => {
                    if (result.lesCategory && this.options.instance) {
                        this.store.commit('tool/changeTempType', result.lesCategory);
                    }
                    this.store.commit('changeLesInfo', result);
                })
                .fail(() => {
                    this.options.lesMessage && this.store.commit('changeLesInfo', this.options.lesMessage);
                })
                .always(() => {
                    resolve();
                });
        });
    }

    changeActiveComp(lists, id) {
        const fun = function(lists, target) {
            Gikam.each(lists, (index, comp) => {
                if (Number(comp.id) === target) {
                    comp.expend = true;
                    this.store.commit('changeActiveComp', { comp: comp, expend: true });
                    return false;
                } else if (comp.children && comp.children.length > 0) {
                    fun.call(this, comp.children, target);
                }
            });
        };
        fun.call(this, lists, id);
    }

    getData(activeId) {
        return new Promise((resolve, reject) => {
            window.workspace.window.showMask();
            const { interfaceType, id } = this.options;
            Gikam.post(
                `${Gikam.IFM_CONTEXT}/secure/core/les/les-${interfaceType}s/${id}/${interfaceType}-units/tree-grid`
            )
                .done(result => {
                    if (result.rows && result.rows.length >= 0) {
                        this.store.commit('initComp', result.rows);
                        activeId && this.changeActiveComp(result.rows, activeId);
                    }
                    resolve();
                })
                .fail(() => {
                    this.options.leftTree && this.store.commit('initComp', this.options.leftTree);
                    reject();
                })
                .always(() => {
                    window.workspace.window.closeMask();
                });
        });
    }

    getTemplates() {
        window.workspace.window.showMask();
        let def = Gikam.getDeferred();
        Gikam.post(Gikam.IFM_CONTEXT + '/secure/core/les/les-template-unit-tmpls/queries/searchable').done(result => {
            this.store.commit('modal/changeAllTemplate', result.rows);
            def.resolve();
            window.workspace.window.closeMask();
        });
        return def;
    }

    getAnnexLists() {
        const templateUrl = `/secure/core/les/les-${this.options.interfaceType}s/queries/${this.options.interfaceType}-units`;
        const f_key = this.options.instance ? 'instanceId_SEQ' : 'templateId_SEQ';
        const b_key = this.options.instance ? 'instanceId' : 'templateId';
        const unitRequestData = Gikam.getJsonWrapper(
            {
                f: {
                    [f_key]: this.options.id
                },
                n: -1
            },
            ['', [{ [b_key]: this.options.id }]]
        );
        Gikam.post(Gikam.IFM_CONTEXT + templateUrl, unitRequestData).done(data => {
            this.store.commit('setAnnexData', data.rows);
        });
    }

    async saveTemplate() {
        if (!this.options.saveTemplate) {
            return;
        }
        if (this.store.state.tool.tempType !== 'lesInstances') {
            return;
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
        const $dom = await getPageHtml(this.model.$refs.vm.$refs.body);
        const CompType = getHtmlCompType($dom, null, this.options);
        const head = await getPageCss(CompType);

        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.innerHTML = importFormPdfCss();
        head.appendChild(style);

        const promiseArr = this.options?.printCss.map(href => {
            return new Promise(resolve => {
                Gikam.getText(Gikam.IFM_CONTEXT + href).done(res => resolve(res));
            });
        });

        Promise.all(promiseArr).then(cssContents => {
            const cssContent = cssContents.reduce((total, content) => (total += content), '');
            const style = document.createElement('style');
            style.innerHTML = cssContent;
            head.appendChild(style);
            const content = `<!DOCTYPE HTML><html><head><meta charset="utf-8"/>${head.innerHTML}<style> html,body{  width: 775px; overflow: visible;} @page{ margin: ${this.options.printMargin}; size: a4;}</style></head><body>${$dom.innerHTML}</body></html>`;
            Gikam.put(
                `${Gikam.IFM_CONTEXT}/secure/core/les/les-instances/instant`,
                Gikam.getJsonWrapper({}, [
                    'coreLesInstanceServiceImpl',
                    [{ id: this.store.state.lesInfo.id, html: content }]
                ])
            );
        });
    }

    async init() {
        //初始化store
        this.store = initStore(this.options);
        //store中加入外部插入的内容
        this.accessOutInserts();
        //得到路由片段（实例和模板）
        this.addIdentification();
        //获取全局模板信息
        await this.getLesMessage();
        //获取组件列表
        await this.getCompLists();
        //获取组件定义的模板
        await this.getTemplates();
        //获取组件附件信息
        this.getAnnexLists();
        //设置页面只读
        this.store.commit('tool/changeReadonlyStates', this.options.readonly);
        // 是否显示添加按钮
        this.store.commit('tool/showAddButton', this.options.showAddButton);
        // 初始化页面
        this.createModel();
        //初始化window上的按钮
        this.createTopButton();
        //获取保存的组件
        await this.getData();
        this.saveTemplate();
    }
}
