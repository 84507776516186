<template>
    <SunwayDatePicker 
        size="small" 
        @ok="okHandle" 
        @change="changeHandle" 
        v-model="value" 
        type="RangePicker" 
        :options="{ ...options, type: 'RangePicker' }" 
        show-time />
</template>
<script>
import SunwayDatePicker from '@/sunway/date-picker/date-picker';
import Gikam from 'gikam';
import DateUtils from '@/gikam/js/utils/dateUtils';

export default {
    components: {
        SunwayDatePicker
    },

    props: {
        options: Object,
        propValue: String
    },

    data() {
        return {
            value: null
        };
    },

    watch: {
        propValue(value) {
            if (!value) {
                this.value = [null, null];
            }
        }
    },

    methods: {
        okHandle(value) {
            let fromValue = null;
            let toValue = null;
            if (Gikam.isEmpty(value)) {
                fromValue = null;
                toValue = null;
            } else {
                fromValue = DateUtils.momentToString(value[0], 'YYYY-MM-DD HH:mm:ss');
                toValue = DateUtils.momentToString(value[1], 'YYYY-MM-DD HH:mm:ss');
            }
            const changeOptions = [];
            changeOptions.push({ value: fromValue, match: 'TGOE' });
            changeOptions.push({ value: toValue, match: 'TLOE' });
            this.$emit('change', changeOptions);
        },

        changeHandle(value) {
            if (Gikam.isEmpty(value)) {
                this.okHandle();
            }
        }
    }
};
</script>
