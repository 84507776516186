<template>
    <div :class="baseClass">
        <vue-editor
            ref="editor"
            :class="{ focused: focused }"
            :editor-toolbar="customToolbar"
            :disabled="readonly"
            v-model="value"
            @focus="focusHandle"
            @blur="blurHandle"
            @paste.native="pasteHandle"
        />
        <transition name="clean-transition">
            <div v-if="showCleanIcon" :class="cleanIconClass" @click="cleanValue">
                <icon-close-round />
            </div>
        </transition>
        <transition name="slide">
            <div :class="infoIconClass" v-if="validateResult === false">
                <Tooltip :title="errorMessage" placement="bottomRight">
                    <icon-info-round />
                </Tooltip>
            </div>
        </transition>
        <div v-if="!readonly" :class="chooseIconClass" @click="iconChooseHandle">
            <chooseImg v-bind="svgIconOptions" />
        </div>
    </div>
</template>
<script>
import Gikam from 'gikam';
import BaseField from '../baseField/baseField.vue';
import Vue from 'vue';
import Vue2Editor from 'vue2-editor';
import { Tooltip } from 'ant-design-vue';

Vue.use(Vue2Editor);

const defaultOptions = {
    // 上下标箭头排列方式,vertical、horizontal
    scriptIconLayout: 'vertical',
    valueFormatter: null
};

export default {
    extends: BaseField,

    components: { Tooltip },

    props: {
        options: Object,
        propValue: String,
        rowIndex: Number
    },

    data() {
        return {
            focused: false,
            focusedValue: null,
            validateResult: true,
            readonly: this.options.readonly,
            field: this.options.field,
            value: null,
            rootClass: 'sunway-script-choose',
            customToolbar: [[{ script: 'super' }, { script: 'sub' }]],
            scriptIconLayout: this.options.scriptIconLayout ?? defaultOptions.scriptIconLayout,
            svgIconOptions: { color: '#666666', width: '14px', height: '14px' }
        };
    },

    inject: ['form', 'grid'],

    computed: {
        baseClass() {
            const classList = [this.rootClass, `${this.rootClass}-${this.scriptIconLayout}`];
            if (this.focused) {
                classList.push(`${this.rootClass}-focused`);
            }
            if (this.validateResult === false) {
                classList.push(`${this.rootClass}-validate-fail`);
            }
            if (this.readonly) {
                classList.push(`${this.rootClass}-readonly`);
            }
            return classList;
        },

        chooseIconClass() {
            const classList = [`${this.rootClass}-icon`];
            return classList;
        },

        cleanIconClass() {
            const classList = [`${this.rootClass}-clean-icon`];
            return classList;
        },

        showCleanIcon() {
            // 暂时屏蔽
            return false;
        },

        infoIconClass() {
            const classList = [`${this.rootClass}-info-icon`];
            return classList;
        },

        errorMessage() {
            return Gikam.isNotEmpty(this.error) ? this.error[0].message : null;
        },

        targetFields() {
            if (!this.options.targetFields) {
                return [];
            }
            return this.options.targetFields.reduce((result, item) => {
                let targetField = item;
                let valueField = item;
                if (Gikam.isPlainObject(item)) {
                    targetField = Object.keys(item)[0];
                    valueField = item[targetField];
                }
                result.push({ targetField, valueField });
                return result;
            }, []);
        }
    },

    watch: {
        propValue: {
            immediate: true,
            handler(value) {
                this.value = this.getVueEditorValue(value);
            }
        }
    },

    methods: {
        focusHandle() {
            if (this.readonly) {
                return;
            }
            this.focused = true;
            this.focusedValue = this.value;
            this.validateResult = true;
            const el = this.$refs.editor.$el.querySelector('.ql-editor');
            el.addEventListener('wheel', this.scroll, true);
        },

        blurHandle() {
            this.focused = false;
            if (this.value !== this.focusedValue) {
                this.validateResult = this.validate();
                if (this.validateResult === false) {
                    return;
                }
                this.$emit('change', this.options.field, this.formatterValue(this.value), this.rowIndex);
            }
            const el = this.$refs.editor.$el.querySelector('.ql-editor');
            el.removeEventListener('wheel', this.scroll, true);
        },

        formatterValue(value) {
            const valueFormatter = this.options.valueFormatter || defaultOptions.valueFormatter;
            if (valueFormatter) {
                return valueFormatter(value, this.options);
            }
            return value;
        },

        cleanValue() {
            this.value = '';
            this.saveValue(this.value);
            this.setRelateFieldsValue([{}]);
        },

        setRelateFieldsValue(rows) {
            if (Gikam.isEmpty(this.targetFields)) {
                return;
            }
            const data = Gikam.isArray(rows) ? rows[0] : rows;
            const valueObject = this.targetFields.reduce((mapper, item) => {
                mapper[item.targetField] = data[item.valueField];
                return mapper;
            }, {});
            Gikam.isNotEmpty(this.rowIndex) && (valueObject.index = this.rowIndex);
            (this.form || this.grid).setData(valueObject);
        },

        iconChooseHandle() {
            if (this.options.onChoose) {
                this.options.onChoose.apply(this.form || this.grid);
                return;
            }
            let category = this.options.category;
            if (this.options.getCategory) {
                category = this.options.getCategory();
            }
            let config = Gikam.choose.getConfig(category);
            if (!config) {
                return;
            }
            let url = Gikam.IFM_CONTEXT + config.url;
            let customData = null;
            if (this.options.onBeforeChoose) {
                customData = this.options.onBeforeChoose.apply(this.form || this.grid, [this.rowIndex]);
            }
            if (customData === false) {
                return;
            }
            const requestData = Gikam.deepExtend(customData);
            const _this = this;
            this.options.single && (requestData.single = 1);
            Gikam.create('modal', {
                title: config.title,
                url: url + Gikam.param(requestData),
                width: config.width,
                height: config.height,
                onAfterClose: function(rows) {
                    if (_this.setValueAfterChoose && Gikam.isNotEmpty(rows)) {
                        _this.value = Gikam.getFieldValue(rows[0], _this.field);
                        _this.saveValue(_this.value);
                    }
                    _this.setRelateFieldsValue(rows);
                    _this.$emit('afterChoose', rows, _this.rowIndex);
                }
            });
        },

        pasteHandle(event) {
            const selection = window.getSelection();
            if (!selection.rangeCount) return false;
            const clipboard = event.clipboardData || window.clipboardData;
            let paste = (event.clipboardData || window.clipboardData).getData('text');
            if (/<script.*?>(.*?)<\/script>/.test(paste)) {
                return;
            }
            let html = clipboard.getData('text/html');
            if (html && /<(sub|sup).*?>(.*?)<\/(sub|sup)>/.test(html)) {
                let match = html.match(/<!--StartFragment-->(.*?)<!--EndFragment-->/);
                if (match) {
                    const text = html.match(/<!--StartFragment-->(.*?)<!--EndFragment-->/)[1];
                    if (!/&lt;(sub|sup).*?&gt;/.test(text)) {
                        paste = text;
                    }
                }
            }
            selection.deleteFromDocument();
            const dom = selection.getRangeAt(0).createContextualFragment(paste);
            selection.getRangeAt(0).insertNode(dom);
            event.preventDefault();
            event.stopPropagation();
        },

        override(props) {
            Gikam.extend(defaultOptions, props);
        },

        // 如果值没有以<p>开头，在值外层包裹<p>,因为VueEditor的值都是以<p>包裹
        getVueEditorValue(value) {
            if (!value) {
                return '';
            }
            return value.startsWith('<p>') ? value : `<p>${value}</p>`;
        },

        scroll(e) {
            if (!this.focused) {
                return;
            }
            e.preventDefault();
            const el = this.$refs.editor.$el.querySelector('.ql-editor');
            const { scrollHeight, offsetHeight, scrollTop } = el;
            const wheel = e.wheelDelta || -e.detail;
            const delta = Math.max(-1, Math.min(1, wheel));

            if (delta > 0) {
                if (scrollTop - 17 > 0) {
                    el.scrollTop -= 17;
                } else {
                    el.scrollTop = 0;
                }
            } else {
                if (offsetHeight + scrollTop + 17 <= scrollHeight) {
                    el.scrollTop += 17;
                } else {
                    el.scrollTop = scrollHeight - offsetHeight;
                }
            }
        }
    }
};
</script>

<style lang="scss">
$rootClass: sunway-script-choose;

.#{$rootClass} {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    min-height: 30px;
    transition: all 0.2s;
    display: flex;
    overflow: hidden;

    &-readonly {
        background-color: #f4f4f4;
    }

    &-icon {
        width: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        background-color: #fff;
        z-index: 1;
    }

    &-clean-icon {
        &.clean-transition-enter-active {
            animation: slideInRight 0.5s;
        }

        &.clean-transition-leave-active {
            animation: slideOutRight 0.5s;
        }
    }

    &-info-icon {
        color: #ff3b30;
        font-size: 20px;
        display: flex;
        align-items: center;
        width: 25px;
        justify-content: flex-start;

        &.slide-enter-active {
            animation: slideInRight 0.5s;
        }

        &.slide-leave-active {
            animation: slideOutRight 0.5s;
        }
    }

    &-focused {
        border-color: #007aff;

        > .quillWrapper {
            > .ql-toolbar {
                border-right: 1px solid #d9d9d9 !important;
            }
        }
    }

    > .quillWrapper {
        display: flex;
        align-items: stretch;
        flex: 1;

        > .ql-container {
            flex: 1;
            border: none;
            height: unset;
            width: 0;

            > .ql-editor {
                min-height: unset;
                padding: 8px 8px 0;
                font-size: 12px;
            }
        }
    }

    &-vertical {
        > .quillWrapper {
            > .ql-toolbar {
                border: none;
                padding: 0 !important;
                width: 0;
                transition: width 0.3s;
                overflow: hidden;
                min-width: 0;

                > .ql-formats {
                    margin: 0 !important;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    position: relative;

                    > button {
                        padding: 0;
                        align-content: stretch;
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        > svg {
                            width: 14px;
                            height: 14px;
                        }

                        &:first-child {
                            border-bottom: 1px solid #d9d9d9;
                        }
                    }
                }
            }
        }

        &.#{$rootClass}-focused {
            > .quillWrapper {
                > .ql-toolbar {
                    width: 30px !important;
                }
            }
        }
    }

    &-horizontal {
        > .quillWrapper {
            > .ql-toolbar {
                border: none;
                padding: 0 !important;
                width: 0;
                transition: width 0.3s;
                overflow: hidden;

                > .ql-formats {
                    margin: 0 !important;
                    height: 100%;
                    display: flex;
                    margin: 0;
                    align-items: center;

                    > button {
                        padding: 0;
                        align-content: stretch;
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        > svg {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }

        &.#{$rootClass}-focused {
            > .quillWrapper {
                > .ql-toolbar {
                    width: 50px !important;
                }
            }
        }
    }

    &-validate-fail {
        border-color: rgb(255, 59, 48);
    }
}
</style>
