function* sequence() {
    let value = 0;
    while (true) {
        yield value++;
    }
}

const s = sequence();

/**
 * @export
 * @class Sequence
 * @description 获取序列类
 */
export class Sequence {
    constructor() {
        this.currentValue = 0;
    }

    getNextValue() {
        return (this.currentValue += 1);
    }

    static nextWithTime() {
        return s.next().value + Date.now();
    }
}
