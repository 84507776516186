<template>
    <div
        class="form"
        :id="options.id"
        :class="formClass"
        :style="options.style"
        v-show="visible"
        @mousedown="formMousedownHandle"
        @dragstart.stop="() => false"
    >
        <div
            class="panel"
            v-for="(panel, index) in options.panels"
            :key="index"
            :id="panel.id"
            :class="{ 'no-title': !panel.title }"
            v-show="panel.hidden !== true"
        >
            <header v-if="panel.title">{{ propI18N(panel.title) }}</header>
            <sunway-caption v-if="options.caption.text" :options="options.caption"></sunway-caption>
            <sunway-caption v-if="panel.caption && panel.caption.text" :options="panel.caption"></sunway-caption>
            <draggable
                v-model="panel.fields"
                :class="{ floatMode: options.mode === 'surround' || panel.mode === 'surround' }"
                tag="section"
                group="formFieldGroup"
                :move="moveHandle"
                :disabled="!pageEditing"
            >
                <span :is="pageEditing ? 'transition-group' : 'span'" type="transition" name="flip-list">
                    <template v-for="field in panel.fields">
                        <form-field-group
                            :key="field.key || field.field"
                            :ref="`group_${field.field}`"
                            :field="field"
                            :columns="options.columns"
                            :data="options.data"
                            :fields="panel.fields"
                            :formOptions="options"
                        ></form-field-group>
                    </template>
                </span>
            </draggable>
        </div>
        <data-lock-mask v-if="locked" :options="options" :win="$window" />
        <form-page-edit-wrapper
            v-if="showPageEditWrapper"
            :showPageEditWrapper.sync="showPageEditWrapper"
            :options="options"
        ></form-page-edit-wrapper>
    </div>
</template>

<script>
import Gikam from 'gikam';
import draggable from 'vuedraggable';
import FormFieldGroup from './formFieldGroup';
import { mapState } from 'vuex';
import formPageEditWrapper from './formPageEditWrapper';

export default {
    props: {
        options: Object
    },

    components: {
        dataLockMask: () => import('@/gikam/js/components/dataLockMask/vue/dataLockMask.vue'),
        draggable,
        FormFieldGroup,
        formPageEditWrapper
    },

    inject: ['form', '$window'],

    data() {
        return {
            rows: [],
            hiddenGrid: this.options.hidden,
            tipPanelTimer: null,
            cleanTtipPanelTimer: null,
            tipPanel: null,
            locked: false,
            showPageEditWrapper: false
        };
    },

    computed: {
        ...mapState(['pageEditing']),

        formClass() {
            const { titleWidthAuto, border, margin } = this.options;

            return [
                {
                    'title-width-auto': titleWidthAuto,
                    border,
                    margin
                },
                this.options.layout
            ];
        },

        visible() {
            return !this.options.hidden;
        }
    },

    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.getHiddenGrid();
            });
        });
    },

    methods: {
        getHiddenGrid() {
            if (!Gikam.compConfigManager?.form) {
                return;
            }
            const visible = Gikam.compConfigManager?.form[this.options.id]?.visible;
            Gikam.isNotEmpty(visible) && (this.hiddenGrid = !visible);
        },

        changeHiddenGridState(arg) {
            this.hiddenGrid = arg;
        },

        propI18N(text) {
            return Gikam.propI18N(text);
        },

        getData() {
            this.$children.forEach(panel => {
                panel.$children &&
                    panel.$children.forEach(formItem => {
                        if (formItem.$children?.length) {
                            const item = formItem.$children[0];
                            if (item.$children?.length) {
                                const editor = item.$children[0];
                                if (editor.$children?.length) {
                                    const fieldEditor = editor.$children[0];
                                    const { field, value, options } = fieldEditor;
                                    if (field && Gikam.isNotEmpty(value)) {
                                        let newValue = value;
                                        if (options?.type === 'checkbox') {
                                            newValue = JSON.stringify(value);
                                        } else if (
                                            ['script', 'scriptChoose'].includes(options?.type) &&
                                            fieldEditor.formatterValue
                                        ) {
                                            newValue = fieldEditor.formatterValue(value);
                                        }
                                        Gikam.setFieldValue(this.options.data, field, newValue);
                                    }
                                }
                            }
                        }
                    });
            });
            return Gikam.deepExtend(this.options.data);
        },

        formatTitleTip(item) {
            const title = item.titleTip ? item.titleTip : item.title;
            return this.propI18N(title);
        },

        hoverTip(e, field) {
            this.tipPanelTimer = setTimeout(() => {
                this.showTipPanels(e, field);
            }, 500);
        },

        showTipPanels(event, { tip }) {
            const hoverPanel = () => {
                this.cleanTtipPanelTimer && clearTimeout(this.cleanTtipPanelTimer);
            };
            this.tipPanel = new Gikam.Vue({
                el: Gikam.createDom('div', document.body),
                components: {
                    tipPanel: () => import('../components/tipPanel.vue')
                },
                methods: {
                    close() {
                        this.$refs.vm.close();
                    },
                    hoverPanel: hoverPanel
                },
                render() {
                    return (
                        <tip-panel
                            ref="vm"
                            targetDom={event.target}
                            content={tip}
                            onleavePanel={this.close}
                            onhoverPanel={this.hoverPanel}
                        ></tip-panel>
                    );
                }
            });
        },

        leaveTip() {
            this.tipPanelTimer && clearTimeout(this.tipPanelTimer);
            if (this.tipPanel) {
                this.cleanTtipPanelTimer = setTimeout(() => {
                    this.tipPanel.close();
                    this.tipPanel = null;
                }, 500);
            }
        },

        setLock(flag = true) {
            this.locked = flag;
        },

        moveHandle() {
            return this.pageEditing;
        },

        formMousedownHandle() {
            if (this.pageEditing) {
                this.showPageEditWrapper = true;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.form {
    background-color: white;
    font-size: 12px;
    padding-top: 10px;
    position: relative;

    &.hidden {
        display: none;
    }

    &.margin {
        margin: 8px;
    }

    &.vertical {
        padding: 0;

        > .panel {
            padding: 8px;
        }
    }

    &.border {
        border: 1px solid #eee;

        > .panel {
            border-left: none;
            border-right: none;

            &:last-child {
                border-bottom: none;
            }
        }
    }

    > .panel {
        border: 1px solid #eee;
        padding-top: 20px;
        position: relative;
        padding-right: 10px;
        margin-bottom: 10px;

        &.no-title {
            border: none;
            padding-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        > .caption-container {
            padding-left: 16px;
            padding-bottom: 8px;
        }

        > header {
            display: inline-block;
            position: absolute;
            left: 16px;
            top: -10px;
            padding: 0 8px;
            background-color: #fff;
            font-size: 12px;
            color: black;
            font-weight: bold;
        }

        > section {
            > span {
                display: flex;
                flex-wrap: wrap;

                ::v-deep {
                    > li {
                        width: 350px;
                        height: 40px;
                        background-color: #ffffff;
                        line-height: 30px;
                        list-style: none;
                        font-size: 12px;
                        color: rgba(0, 0, 0, 0.65);
                        position: relative;
                        margin-left: 50px;
                        margin-bottom: 16px;
                        border: 1px solid #007aff;
                        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
                        padding: 3px 8px;
                        display: flex;
                        align-items: center;

                        > span {
                            padding-right: 8px;
                        }

                        &::after {
                            content: '';
                            display: block;
                            border: 1px solid #d9d9d9;
                            flex: 1;
                            height: 100%;
                            border-radius: 4px;
                        }
                    }
                }

                &.floatMode {
                    zoom: 1;
                    display: block;

                    > .form-group {
                        float: left;
                    }
                }

                &::after {
                    content: '';
                    height: 0;
                    line-height: 0;
                    display: block;
                    visibility: hidden;
                    clear: both;
                }
            }
        }
    }
}
</style>
