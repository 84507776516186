<template>
    <div component="confirm" :class="['confirm-dialog', options.rootClass]">
        <transition @enter="enterHandle" @before-leave="beforeLeaveHandle" @after-leave="afterLeaveHandle">
            <div v-if="visible" class="confirm-container" :style="{ height: options.height + 'px' }" ref="container">
                <template v-if="message">
                    <div class="header">
                        <span class="confirm-header-title">{{ title }}</span>
                        <div class="close-btn" @click="closeClick"></div>
                    </div>
                    <div v-if="subTitle" class="sub-title">{{ subTitle }}</div>
                    <section>
                        <scroll>
                            <div class="message-body" v-html="getXssMessage(message)"></div>
                        </scroll>
                    </section>
                </template>
                <section v-else>
                    <scroll>
                        <div class="confirm-body">
                            <i class="confirm-body-icon"><IconHelp></IconHelp></i>
                            <span class="confirm-body-title">{{ title }}</span>
                            <div class="confirm-body-content" v-html="getXssMessage(subTitle)"></div>
                        </div>
                    </scroll>
                </section>
                <div class="footer">
                    <button class="yes button" @click="yesClick()" :class="yesClass">
                        {{ options.yesText || $i18n('confirm.yes') }}
                    </button>
                    <button class="no button" @click="noClick()" :class="noClass">
                        {{ options.noText || $i18n('confirm.no') }}
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Gikam from 'gikam';
import IconHelp from '@/gikam/js/components/icon/vue/IconHelp';
import { MouseUtils } from '@/gikam/js/utils/mouseUtils';

export default {
    props: {
        options: Object
    },

    components: {
        IconHelp
    },

    created() {
        Gikam.extend(this, this.options);
    },

    data() {
        return {
            title: void 0,
            subTitle: void 0,
            message: void 0,
            activeButton: 'yes',
            visible: false
        };
    },

    computed: {
        yesClass() {
            const clazz = { active: this.activeButton === 'yes' };
            return clazz;
        },

        noClass() {
            const clazz = { active: this.activeButton === 'no' };
            return clazz;
        }
    },

    methods: {
        noClick() {
            this.visible = false;
        },

        yesClick() {
            Gikam.buttonText = '确定';
            Gikam.removeDom(this.$el);
            document.removeEventListener('keydown', this.keydownHandle);
            this.options.onYesClick();
            setTimeout(() => {
                Gikam.buttonText = null;
            }, 300);
        },

        closeClick() {
            Gikam.removeDom(this.$el);
            document.removeEventListener('keydown', this.keydownHandle);
        },

        keydownHandle(e) {
            const keyCode = e.keyCode;
            if (keyCode === 37) {
                this.activeButton = 'yes';
            } else if (keyCode === 39) {
                this.activeButton = 'no';
            } else if (keyCode === 13) {
                this.activeButton === 'yes' ? this.yesClick() : this.noClick();
            } else if (keyCode === 9) {
                this.activeButton = this.activeButton === 'no' ? 'yes' : 'no';
            }
        },

        enterHandle() {
            const container = this.$refs.container;
            const mousePosition = MouseUtils.getMousePosition();
            if (mousePosition) {
                const offsetX = mousePosition.x - container.offsetLeft;
                const offsetY = mousePosition.y - container.offsetTop;
                container.style.transformOrigin = `${offsetX}px ${offsetY}px`;
            }
            container.classList.add('zoom-in');
        },

        afterEnterHandle() {
            this.$refs.container.classList.remove('zoom-in');
        },

        beforeLeaveHandle() {
            this.$refs.container.classList.add('zoom-out');
        },

        afterLeaveHandle() {
            Gikam.removeDom(this.$el);
            document.removeEventListener('keydown', this.keydownHandle);
            this.options.onNoClick();
        },

        getXssMessage(text) {
            return this.$xss(text);
        }
    },

    mounted() {
        document.addEventListener('keydown', this.keydownHandle);
        this.visible = true;
    }
};
</script>

<style lang="scss">
@import '@/gikam/css/gikam-animation.scss';

.confirm-dialog {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Microsoft YaHei', serif;
    z-index: 10;

    > .confirm-container {
        width: 408px;
        height: 204px;
        background-color: #fff;
        border-radius: 4px;
        position: relative;
        padding: 0 24px 44px 24px;
        padding-bottom: 44px;
        display: flex;
        flex-direction: column;
        box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);

        &.zoom-in {
            animation: zoomIn 0.3s;
        }

        &.zoom-out {
            animation: zoomOut 0.3s;
        }

        > .header {
            height: 48px;
            display: flex;
            align-items: center;
            padding: 0;
            justify-content: space-between;

            > .confirm-header-title {
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
                font-weight: bold;
            }

            > .close-btn {
                width: 15px;
                height: 15px;
                background: url(../../../img/box-close.png) center no-repeat;
                cursor: pointer;
            }
        }

        > section {
            font-size: 12px;
            padding: 0;
            color: rgba(0, 0, 0, 0.65);
            flex: 1;
            position: relative;
            height: 0;
            min-height: 0;

            .message-body {
                padding: 5px 24px 10px;
            }
        }

        > .footer {
            font-size: 14px;
            color: #666;
            height: 28px;
            align-items: flex-start;
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: 16px;
            right: 24px;

            > button {
                border-radius: 4px;
                width: 64px;
                height: 28px;
                margin-left: 20px;
                border: 1px solid #d9d9d9;
                background-color: #fff;
                outline: none;
                font-size: 12px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;

                &.active {
                    box-shadow: 0px 2px 10px 0px rgb(0, 122, 255, 0.5);
                    border: 1px solid #007aff;
                }
            }

            > button.yes {
                background-color: #007aff;
                color: #fff;
                text-align: center;
            }

            > button.no {
                background-color: #fff;
                color: #666;
                text-align: center;
            }
        }

        > .sub-title {
            height: 20px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            margin-bottom: 5px;
        }
    }
}

.confirm-body {
    padding-top: 30px;

    > .confirm-body-icon {
        color: #faad14;
        font-size: 20px;
        vertical-align: middle;
    }

    > .confirm-body-title {
        opacity: 0.85;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0;
        margin-left: 8px;
    }

    > .confirm-body-content {
        opacity: 0.65;
        font-size: 12px;
        color: #000000;
        letter-spacing: 0;
        margin-left: 30px;
        line-height: 18px;
    }
}
</style>
