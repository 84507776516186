import Gikam from 'gikam';

export default class CheckboxGroupHelper {
    static cache = {};

    static config = {};

    static getRemoteItems(option) {
        const cacheKey = CheckboxGroupHelper.getItemCacheKey(option);
        const itemConfig = CheckboxGroupHelper.config[option.category];
        return new Promise(resolve => {
            if (itemConfig.loading) {
                !itemConfig.resolves && (itemConfig.resolves = []);
                itemConfig.resolves.push(resolve);
            } else {
                itemConfig.loading = true;
                const url = Gikam.addUrlParam(Gikam.getContextUrl(itemConfig.url), option.param);
                Gikam.getJson(url).done(function(itemList) {
                    const items = itemList.map(item => {
                        Object.assign(item, {
                            value: Gikam.getFieldValue(item, itemConfig.valueField),
                            text: Gikam.getFieldValue(item, itemConfig.textField)
                        });
                        return item;
                    });
                    const groupItems = CheckboxGroupHelper.formatterGroupItems(itemConfig, items);
                    const resultItems = {
                        items,
                        groupItems,
                        otherInput: itemConfig.displayOther === '1',
                        isGroup: itemConfig.groupField != null
                    };
                    if (itemConfig.cacheable === '1') {
                        CheckboxGroupHelper.cache[cacheKey] = resultItems;
                    }
                    resolve(resultItems);
                    itemConfig.resolves && itemConfig.resolves.forEach(resolve => resolve(resultItems));
                    itemConfig.resolves = [];
                    itemConfig.loading = false;
                });
            }
        });
    }

    static getItemCacheKey(option) {
        const config = CheckboxGroupHelper.config[option.category];
        return Gikam.addUrlParam(config.url, option.param);
    }

    static async getItems(option) {
        if (!option.category) {
            return [];
        }
        let resultItems = CheckboxGroupHelper.cache[CheckboxGroupHelper.getItemCacheKey(option)];
        if (resultItems) {
            return resultItems;
        }
        return await CheckboxGroupHelper.getRemoteItems(option);
    }

    static addConfigParam(list) {
        list.forEach(item => {
            CheckboxGroupHelper.config[item.category] = item;
        });
    }

    static formatterGroupItems(itemConfig, items) {
        const groupMap = new Map();
        if (!itemConfig.groupField) {
            groupMap.set(null, items);
        } else {
            items.forEach(item => {
                const groupTitle = item[itemConfig.groupField];
                if (!groupMap.get(groupTitle)) {
                    groupMap.set(groupTitle, []);
                }
                const group = groupMap.get(groupTitle);
                group.push(item);
            });
        }
        return groupMap;
    }
}
