<template>
    <div class="header-container">
        <table
            :style="{ width: width }"
            :class="{ 'filter-open': filterOpen && filter, 'no-user-select': columnResizing }"
        >
            <thead>
                <tr :style="{ height }">
                    <template v-for="item in propColumns">
                        <th v-if="getFieldVisible(item)" :key="getHeaderKey(item)" :style="getCellWidth(item)">
                            <div v-if="propEditorInvisible && item.editor" class="editor-flag"></div>
                            <template v-if="item.checkbox || item.radio">
                                <badge
                                    class="corner-marker"
                                    v-if="selectNumVisible"
                                    :options="{ count: selectNum }"
                                ></badge>
                                <div v-if="item.checkbox" class="all-checkbox-container">
                                    <checkbox
                                        @change="checkHandle"
                                        :propChecked="allCheckedOptions.propChecked"
                                        :propHalfChecked="allCheckedOptions.propHalfChecked"
                                    ></checkbox>
                                </div>
                                <div class="filter" v-if="filter" v-show="filterOpen">
                                    <div class="container">
                                        <div class="icon close" @click="closeFilter"></div>
                                        <div class="icon reset" @click="resetFilter"></div>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="item.index === true">
                                <div class="index-header-cell">序号</div>
                            </template>
                            <template v-else>
                                <div
                                    :class="{ 'is-sort': item.sort !== false }"
                                    :style="{ textAlign: item.titleAlign }"
                                    v-html="(propI18N(item.title) + '').replace(/<\/?p>/g, '')"
                                    @click="sort(item)"
                                ></div>
                                <div class="filter" v-show="filter && filterOpen">
                                    <div v-if="item.filter === false"></div>
                                    <editor v-else :options="item" :filterOriginalData="filterOriginalData"></editor>
                                </div>
                                <div
                                    class="exchange-wrapper"
                                    v-if="showSortArrow && orderField === item.field && orderType"
                                >
                                    <div class="exchange-up" :class="{ active: orderType === 'asc' }"></div>
                                    <div class="exchange-down" :class="{ active: orderType === 'dsc' }"></div>
                                </div>
                            </template>
                        </th>
                    </template>
                </tr>
            </thead>
        </table>
    </div>
</template>

<script>
import Gikam from '../../../core/gikam-core';
import Vue from 'vue';
import filterDateField from './header/editor/filterDate.vue';
import { mapState, mapMutations } from 'vuex';
import badge from '@/gikam/js/components/badge/badge.vue';

Vue.component('filterDateField', filterDateField);

const getEditorType = (options, fieldType) => {
    let type = options.type || 'text';
    if (type === 'link' || type === 'choose' || type === 'tree') {
        type = 'text';
    } else if (type === 'simpleCheckbox') {
        type = 'select';
        options.items = [
            { text: '', value: '' },
            { text: '是', value: '1' },
            { text: '否', value: '0' }
        ];
    }

    if (fieldType === 'dateTime') {
        type = 'filterDateTime';
    } else if (fieldType === 'date') {
        type = 'filterDate';
    } else if (fieldType === 'number') {
        type = 'number';
    }

    if (options.type === 'select') {
        type = 'select';
    }
    return type;
};

const getEnterType = ({ type = 'text' }) => {
    let _type = type;
    if (type === 'dateTime') {
        _type = 'filterDateTime';
    } else if (type === 'date') {
        _type = 'filterDate';
    }
    return _type;
};

const getFieldMatch = (ctx, options) => {
    const fieldTypeMapper = ctx.parent.$store.state.fieldTypeMapper;
    const type = fieldTypeMapper ? fieldTypeMapper[options.field.toLowerCase().replace('ext$.', '')] : 'string';
    let matchType = 'SC';
    if (options.match) {
        matchType = options.match;
    } else {
        if (options.type === 'select' && options.multiple === true) {
            matchType = 'OR';
        } else if (options.type === 'simpleCheckbox') {
            matchType = 'IN';
        } else if (options.type === 'select') {
            matchType = 'EQ';
        } else {
            matchType = {
                string: 'CISC',
                number: 'NEQ',
                data: 'DEQ',
                dateTime: 'TEQ'
            }[type];
        }
    }

    if (!options.field.includes('_') || (options.field.includes('$') && !options.field.includes('ext$.'))) {
        return options.field + '_' + matchType;
    } else {
        return options.field;
    }
};

// 通过表头快捷数据刷新表格
function refreshByHeaderData(context, fieldOptions, value) {
    const store = context.parent.$store;
    let fieldMatch = getFieldMatch(context, fieldOptions);
    if (fieldOptions.type === 'simpleCheckbox' && value === '0') {
        // fieldMatch = fieldMatch.replace('EQ', "OR");
        const requestData = { [fieldMatch]: '0,' };
        store.commit('updateHeadRequestData', requestData);
    } else {
        store.commit('updateHeadRequestData', { [fieldMatch]: value });
    }
    store.commit('refresh');
}

// 判断是否服务器端查询
function isFieldSeverSearch(context, fieldOptions) {
    return !Gikam.isFalse(context.injections.grid.options.serverSearch) && !Gikam.isFalse(fieldOptions.serverSearch);
}

const editor = {
    functional: true,
    inject: ['grid'],
    // eslint-disable-next-line complexity
    render(h, context) {
        const options = Gikam.deepExtend(context.props.options);
        options.readonly = false;
        delete options.validators;
        if (options.type === 'select') {
            options.search = true;
            options.placeholder = options.placeholder || ' ';
        }
        options.type === 'richText' && delete options.type;
        options.type === 'textarea' && delete options.type;
        options._isHeader = true;

        const fieldTypeMapper = context.parent.$store.state.fieldTypeMapper;
        let fieldType = null;
        if (options.field && fieldTypeMapper) {
            const simpleFieldName = context.props.options.field.toLowerCase().replace('ext$.', '');
            fieldType = fieldTypeMapper[simpleFieldName];
        }
        options.field && options.fieldType && (fieldType = options.fieldType);
        const type = options.filter?.type ? getEnterType(options.filter) : getEditorType(options, fieldType);

        let editor = type + 'Field';
        const readonly = options.filter === false ? true : false;
        return (
            <editor
                ref={options.field}
                options={options}
                propReadonly={readonly}
                propSetValueAfterChoose={true}
                propValue={options.field ? context.parent.requestFieldData[options.field.replace('ext$.', '')] : null}
                onChange={(...args) => {
                    context.props.filterOriginalData[options.field] = args[1];
                    if (options.filter === false) {
                        return;
                    }
                    context.parent.$store.commit('updatePageNum', 1);
                    if (isFieldSeverSearch(context, options)) {
                        refreshByHeaderData(context, options, args[1]);
                    } else {
                        const store = context.parent.$store;
                        const _options = Gikam.deepExtend(options, { type: editor });
                        store.commit('pageSearch', { args, column: _options });
                    }
                }}
                onBlur={(...args) => {
                    if (isFieldSeverSearch(context, options) && !options.url) {
                        refreshByHeaderData(context, options, args[1]);
                    }
                }}
            ></editor>
        );
    }
};

export default {
    props: {
        propColumns: Array,
        propWidth: Number,
        propFilter: Boolean,
        propFilterOpen: Boolean,
        propColumnResize: Boolean,
        propEditorInvisible: Boolean,

        propTotalChecked: {
            isShow: Boolean,
            num: Number
        },
        propShowCheckedNum: Boolean,
        propFixedColumn: {
            type: Boolean,
            default: false
        },
        showSortArrow: Boolean,
        propOrderField: String,
        propOrderType: String
    },

    components: { editor, badge },

    data() {
        return {
            filterOriginalData: {}, // 快捷查询的原始条件
            initX: null,
            resizeCell: null,
            columnResizing: false,
            headerWidth: this.propWidth,
            orderField: this.propOrderField,
            orderType: this.propOrderType
        };
    },

    computed: {
        ...mapState([
            'totalRecord',
            'defaultColumnWidth',
            'checkedRowKeys',
            'grid',
            'showCheckedNum',
            'showCheckedBadgeGt',
            'propCheckContinuous',
            'allChecked',
            'totalRecord',
            'lazyLoad'
        ]),

        showSelectNum() {
            return this.propShowCheckedNum && this.selectNum > 1;
        },

        selectNumVisible() {
            return this.showCheckedNum && this.checkedRowKeys.length > this.showCheckedBadgeGt;
        },

        selectNum() {
            if (this.lazyLoad.enable) {
                return this.allChecked ? this.lazyLoad.totalRows.length : this.checkedRowKeys.length;
            } else {
                return this.propCheckContinuous && this.allChecked ? this.totalRecord : this.checkedRowKeys.length;
            }
        },

        columns() {
            return this.propColumns;
        },

        width() {
            return this.headerWidth + 'px';
        },

        height() {
            return this.$store.state.headerHeight - 1 + 'px';
        },

        filter() {
            return this.propFilter;
        },

        filterOpen() {
            return this.propFilterOpen;
        },

        requestData() {
            return this.$store.state.requestData;
        },

        //将条件转换为仅有field的映射
        requestFieldData() {
            const data = {};
            for (let field in this.requestData) {
                data[field.split('_')[0]] = this.requestData[field];
            }
            return data;
        },

        allCheckedOptions() {
            if (Gikam.isEmpty(this.checkedRowKeys)) {
                return {
                    propChecked: false,
                    propHalfChecked: false
                };
            }
            if (this.checkedRowKeys.length === this.grid.initialRowDataList.length) {
                return {
                    propChecked: true,
                    propHalfChecked: false
                };
            }
            return {
                propChecked: true,
                propHalfChecked: true
            };
        }
    },

    methods: {
        ...mapMutations(['setAllChecked']),

        propI18N(text) {
            return Gikam.propI18N(text);
        },

        checkHandle(checked) {
            this.$emit('allCheck', checked);
            this.setAllChecked(checked);
        },

        closeFilter() {
            this.$emit('filterClose');
        },

        resetFilter() {
            this.$emit('allCheck', false);
            for (let name in this.$refs) {
                this.$refs[name].value = '';
            }
        },

        saveColumns() {
            const url = Gikam.IFM_CONTEXT + '/core/module/sys/page-grid-field-configs';
            const { gridId, $window } = this.$store.state;
            const columnsConfig = this.columns
                .filter(item => !item.checkbox && !item.index)
                .map(item => {
                    return {
                        field: item.field,
                        width: item.width,
                        visible: item.visible == true ? '1' : '0',
                        fixed: item.visible == true ? '1' : '0'
                    };
                });
            return Gikam.postText(
                url,
                Gikam.getJsonWrapper(
                    {
                        gridId,
                        pageId: $window.$pageId
                    },
                    ['', columnsConfig]
                )
            );
        },

        // 排序
        sort(item) {
            if (item.sort === false) {
                return;
            }
            if (this.orderField === item.field) {
                if (!this.orderType) {
                    this.orderType = 'asc';
                } else {
                    this.orderType = { asc: 'desc', desc: void 0 }[this.orderType];
                }
            } else {
                this.orderField = item.field;
                this.orderType = 'asc';
            }
            this.$emit('sortListGrid', this.orderField, this.orderType);
        },

        getHeaderKey(options) {
            if (options.field) {
                return options.field;
            }
            if (options.index) {
                return 'index';
            }
            if (options.checkbox) {
                return 'checkbox';
            }
            if (options.radio) {
                return 'radio';
            }
        },

        getFieldVisible(options) {
            if (options.visible === undefined || options.visible === '1') {
                return true;
            }
            return false;
        },

        getCellWidth(item) {
            return `width:${parseFloat(item.width || this.defaultColumnWidth)}px`;
        }
    },

    watch: {
        propOrderField(val) {
            this.orderField = val;
        },

        propOrderType(val) {
            this.orderType = val;
        }
    }
};
</script>

<style scoped>
.header-container {
    margin-top: 5px;
    border: 1px solid #eee;
    border-bottom: none;
    box-shadow: 0 2px 3.6px 0.4px rgba(0, 0, 0, 0.06);
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    position: relative;
    table-layout: fixed;
}

table > thead > tr > th {
    position: relative;
    height: 35px;
    padding: 0 8px;
    font-weight: normal;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-left: 1px solid #eee;
}

table > thead > tr > th > div {
    overflow: hidden;
}

table.filter-open > thead > tr > th {
    height: 66px;
    vertical-align: top;
    padding-top: 9px;
}

table > thead > tr > th:first-child {
    border-left: none;
}

table > thead > tr > th > .all-checkbox-container,
table > thead > tr > th > .index-header-cell {
    display: flex;
    justify-content: center;
}

table > thead > tr > th > .corner-marker {
    position: absolute;
    top: 0;
    right: 0;
}

table > thead > tr > th > .corner-marker ::v-deep > .ant-badge-count {
    min-width: 16px;
    height: 16px;
    padding: 0 4px;
    color: #fff;
    line-height: 16px;
}

table > thead {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    border-bottom: 1px solid #eee;
}

table > thead > tr > th .is-sort {
    padding-right: 8px;
    text-overflow: ellipsis;
    cursor: pointer;
    line-height: initial;
}

table > thead > tr > th > .sort {
    position: absolute;
    top: 3px;
    right: 0;
    width: 19px;
    height: 29px;
    padding-right: 8px;
    padding-left: 3px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.no-user-select {
    user-select: none;
}

.filter {
    padding: 9px 0 4px 0;
}

.filter > * {
    height: 24px;
}

.filter > .container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter > .container > .icon {
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.filter > .container > .icon.close {
    background: url(../img/close.svg) no-repeat center;
    margin-right: 8px;
}

.filter > .container > .icon.reset {
    background: url(../img/reset-blue.svg) no-repeat center;
}

.editor-flag {
    position: absolute;
    top: 0;
    border-style: solid;
    left: 0;
    border-width: 4px;
    border-color: #007aff transparent transparent #007aff;
}

.exchange-wrapper {
    position: absolute;
    right: 8px;
    top: 50%;
    margin-top: -7px;
    width: 10px;
    height: 14px;
}

.exchange-wrapper > div {
    width: 0;
    height: 0;
    cursor: pointer;
}

.exchange-wrapper > .exchange-up {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 5px solid #d9d9d9;
    margin-bottom: 2px;
}

.exchange-wrapper > .exchange-up.active {
    border-bottom-color: #007aff;
}

.exchange-wrapper > .exchange-down {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid #d9d9d9;
}

.exchange-wrapper > .exchange-down.active {
    border-top-color: #007aff;
}
</style>
