import Gikam from 'gikam';
import jQuery from 'jquery';
export function getTextOnValue(totalList, dataArr) {
    let index = 0;
    let item = totalList;
    const text = [];

    while (index < dataArr.length && item) {
        const value = dataArr[index]?.trim();
        index += 1;
        const currentRow = item.filter(item => item.value === value)[0];
        if (currentRow) {
            text.push(currentRow.text || '');
            item = currentRow.children || [];
        } else {
            break;
        }
    }
    return text;
}

export function getLevelChildren(totalList, dataArr, init) {
    let maxLen = dataArr.length;
    if (init) {
        maxLen = dataArr.length - 1;
        if (dataArr.length === 0) {
            return totalList;
        }
    }

    let index = 0;
    let item = totalList;

    while (index < maxLen && item) {
        const value = dataArr[index]?.trim();
        index += 1;
        const currentRow = item.filter(item => item.value === value)[0];
        if (currentRow) {
            item = currentRow.children || [];
        } else {
            break;
        }
    }

    return item;
}

export function getItemsByUrl(url, parentId) {
    if (Gikam.isEmpty(url)) {
        return [];
    }
    let def = jQuery.Deferred();
    url = Gikam.printf(url, {
        parentId: parentId
    });
    Gikam.post(url).done(rows => {
        if (rows) {
            rows.forEach(row => {
                row.value = row.id;
            });
            def.resolve(rows);
        } else {
            def.resolve([]);
        }
    });
    return def;
}
