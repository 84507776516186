<template>
    <div
        v-if="readonly || invisible"
        class="year-input readonly"
        :class="{ invisible: invisible }"
        @mousedown.stop
        @click="clickHandle"
        @select="focus"
    >
        <div class="readonly-text">{{ value }}</div>
        <div class="validate-error-icon" v-if="validateResult === false" :class="{ input: !readonly }" @click.stop>
            <info-image @mouseenter.stop="errorIconMouseenter" @mouseleave.stop="errorIconMouseleave"></info-image>
        </div>
    </div>
    <div
        v-else
        class="year-input"
        :class="{ 'validate-error': !validateResult }"
        @mousedown.stop
        @click="clickHandle"
        @select="focus"
    >
        <input :style="style" :name="options.field" v-model="value" :readonly="inputEditable" ref="input" />
        <div class="year-input-icon" @click.stop="focus"></div>
        <div class="validate-error-icon" v-if="validateResult === false" :class="{ input: !readonly }" @click.stop>
            <info-image @mouseenter.stop="errorIconMouseenter" @mouseleave.stop="errorIconMouseleave"></info-image>
        </div>
    </div>
</template>
<script>
import GikamCore from 'gikam';
import Vue from 'vue';
import yearPicker from './yearPicker.vue';
import infoImage from '../../img/info.vue';
import BaseField from '../../baseField/baseField.vue'; //校验相关的逻辑

const Gikam = GikamCore.getTopGikam();

const defaultOptions = {
    style: {
        backgroundColor: '#fff'
    }
};

export default {
    name: 'yearField',

    extends: BaseField,

    components: { infoImage },
    props: {
        options: Object,
        propValue: [String, Number],
        rowIndex: Number,
        cellIndex: Number,
        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            style: this.options.style || defaultOptions.style,
            yearPicker: void 0,
            validateResult: true,
            readonly: this.options.readonly ?? false,
            field: this.options.field,
            value: this.options.value,
            year: void 0,
            nowYear: void 0,
            isChooseYear: void 0,
            invisible: this.propInvisible
        };
    },

    computed: {
        inputEditable() {
            return this.options.editable === true ? false : 'readonly';
        }
    },

    watch: {
        value(val, oldVal) {
            if (this.form || this.grid) {
                this.validateResult = this.validate();
            } else {
                this.validateResult = true;
            }
            if (Gikam.isTrue(this.validateResult)) {
                this.$emit('change', this.options.field, val, this.rowIndex, oldVal);
            }
        },

        propValue(val) {
            this.value = val;
        }
    },

    methods: {
        cleanYearPicker() {
            Gikam.yearPicker.destroy();
        },

        override(props) {
            Gikam.extend(defaultOptions, props);
        },

        popYearPicker() {
            if (this.yearPicker) {
                return;
            }

            let _this = this;
            if (Gikam.yearPicker) {
                this.cleanYearPicker();
            }

            if (!Gikam.isTrue(this.propInvisible)) {
                Gikam.simulatedEvent(document, 'mousedown');
            }

            if (this.readonly) {
                return;
            }

            const date = new Date();
            this.year = this.value ? Gikam.DateUtils.toDate(this.value + '-01-01') : date;
            this.isChooseYear = this.value ? Gikam.DateUtils.toDate(this.value + '-01-01') : 'null';
            this.nowYear = date.getFullYear();

            if (this.options.onBeforeSelect) {
                this.options.onBeforeSelect.apply(this.form || this.grid);
            }
            Gikam.yearPicker = new Vue({
                el: Gikam.createDom('div', window.top.document.body),
                components: { yearPicker },
                provide: {
                    yearInput: this
                },
                methods: {
                    destroy() {
                        Gikam.removeDom(this.$el);
                        this.$destroy();
                        Gikam.yearPicker = null;
                    }
                },
                render() {
                    return (
                        <year-picker
                            years={_this.year}
                            isChooseYears={_this.isChooseYear}
                            nowYear={_this.nowYear}
                            yearRange={_this.options.yearRange}
                        />
                    );
                }
            });
        },

        setYearRange(yearRange) {
            this.options.yearRange = yearRange;
        },

        dumpActiveCell() {
            Gikam.yearPicker && this.cleanYearPicker();
            this.$refs.input && this.$refs.input.blur();
        },

        activeCell() {
            if (this.invisible) {
                this.$emit('click', this);
            } else {
                this.focus();
            }
        },

        focus() {
            this.$refs.input && this.$refs.input.focus();
            this.popYearPicker();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        }
    }
};
</script>

<style scoped>
.year-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    color: #666;
    font-size: 12px;
}

.filter .year-input {
    height: 24px;
}

.year-input.readonly {
    display: flex;
    align-items: center;
}

.year-input input,
.year-input .readonly-text {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-right: 30px;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}

.year-input .readonly-text {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
}

.year-input.validate-error input {
    border-color: #ff6e6e;
}

.year-input input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.year-input .year-input-icon {
    width: 30px;
    background: url(../../../../../img/date/date.svg) no-repeat center;
    cursor: pointer;
    margin-left: -30px;
    background-size: 18px;
}

.invisible .readonly-text {
    background-color: transparent;
    border: none;
}

.year-input .validate-error-icon {
    margin-left: -25px;
    display: flex;
    align-items: center;
}

.year-input.validate-error input {
    padding-right: 53px;
}

.year-input .validate-error-icon.input {
    margin-left: -48px;
}

.year-input .validate-error-icon svg {
    height: 20px;
    cursor: pointer;
}
</style>
