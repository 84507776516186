<template>
    <div class="toolbar">
        <slot></slot>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.toolbar {
    position: absolute;
    top: 0;
    left: 0;
    background: #337bf1;
    border-radius: 0 0 4px 0;
    pointer-events: all;

    ::v-deep {
        > button {
            width: 20px;
            height: 20px;
            border: none;
            background-color: transparent;
            color: #fff;
            line-height: 20px;
            text-align: center;
            cursor: pointer;
            outline: none;
            font-size: 12px;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}
</style>
