<template>
    <cascade-select
        ref="editor"
        :options="options"
        :propValue="propValue"
        :propReadonly="readonly"
        :propInvisible="propInvisible"
        :validateArg="validateArg"
        :inputStyle="inputStyle"
        :dataId="dataId"
        @change="changeHandle"
        @click="clickHandle"
    ></cascade-select>
</template>

<script>
import cascadeSelect from '@/gikam/js/components/template/fields/cascadeSelect/cascadeSelect.vue';
import { mapState } from 'vuex';

export default {
    props: {
        options: Object,
        propValue: [String, Number],
        propInvisible: Boolean,
        validateArg: Object,
        inputStyle: {
            type: Object,
            default: void 0
        },
        dataId: [Number, String]
    },

    components: {
        cascadeSelect
    },

    data() {
        return {
            readonly: this.options.readonly ?? false
        };
    },

    computed: {
        ...mapState(['form'])
    },

    mounted() {
        this.form.editorManager[this.options.field] = this.$refs.editor;
    },

    methods: {
        changeHandle(...args) {
            this.$emit.apply(this, ['change', ...args]);
        },

        clickHandle(...args) {
            this.$emit.apply(this, ['click', ...args]);
        }
    }
};
</script>
