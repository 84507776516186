import _objectSpread from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.splice.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import IconSetting from '@/gikam/js/components/icon/vue/IconSetting';
import IconHide from '@/gikam/js/components/icon/vue/IconHide';
import IconRemove from '@/gikam/js/components/icon/vue/IconRemove';
import settingToolbar from '@/gikam/js/components/pageEdit/vue/settingToolbar';
export default {
  props: {
    options: Object,
    columns: Array
  },
  components: {
    IconSetting: IconSetting,
    IconHide: IconHide,
    IconRemove: IconRemove,
    settingToolbar: settingToolbar
  },
  created: function created() {
    this.grid.columnWrapperManager[this.options.key || this.options.field] = this;
  },
  data: function data() {
    return {
      active: false
    };
  },
  computed: _objectSpread(_objectSpread({}, mapState(['pageEditing', '$window', 'grid'])), {}, {
    classList: function classList() {
      return ['grid-header-page-edit-wrapper', {
        active: this.active
      }];
    }
  }),
  methods: {
    mousedownHandle: function mousedownHandle() {
      this.$window.activePageEditSettingItem(this);
    },
    settingClickHandle: function settingClickHandle() {
      this.$window.openSettingPanel({
        title: this.$i18n('pageEdit.grid.column.setting'),
        type: 'gridFieldSetting',
        options: this.options,
        param: this.grid.options
      });
    },
    hideHandle: function hideHandle() {
      var index = this.columns.indexOf(this.options);
      this.columns.splice(index, 1);
      this.grid.options.hiddenFields.push(this.options);
    },
    removeHandle: function removeHandle() {
      var index = this.columns.indexOf(this.options);
      this.columns.splice(index, 1);
      this.$window.openSettingPanel({});
    }
  }
};