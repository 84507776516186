import _objectSpread from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Gikam from '@/gikam/js/core/gikam-core';
import picker from './echartsDropdownPicker';
import Vue from 'vue';
export default {
  props: {
    echartsOptions: Object,
    options: Object,
    id: [String, Number]
  },
  created: function created() {
    this.initTitle();
    this.initDropDown();
  },
  mounted: function mounted() {
    var _this2 = this;

    import('echarts').then(function (echarts) {
      _this2.echarts = echarts.init(_this2.$refs.main);

      _this2.echarts.setOption(_this2.echartsOptions);

      _this2.$emit('ready', _this2.echarts, echarts);
    });
    this.$nextTick(function () {
      _this2.searchShow && _this2.initForm();
    });
  },
  computed: {
    formatterTitleStyle: function formatterTitleStyle() {
      var style = this.titleStyle;
      style && style.fontSize && (style.fontSize = style.fontSize + 'px');
      return style;
    },
    dropDownShow: function dropDownShow() {
      return Gikam.isNotEmpty(this.flatDropDownMenu) ? true : false;
    },
    searchShow: function searchShow() {
      return this.options.search.type === 'form' && Gikam.isNotEmpty(this.options.search.fields);
    },
    titleShow: function titleShow() {
      return Gikam.isEmpty(this.title) ? false : true;
    },
    searchTop: function searchTop() {
      return this.titleShow ? '30px' : 0;
    },
    echartsStyle: function echartsStyle() {
      return {
        height: this.titleShow ? 'calc(100% - 30px)' : '100%',
        'padding-top': this.searchShow ? '30px' : '0'
      };
    },
    searchHeight: function searchHeight() {
      return this.trunClose ? '30px' : this.verticalMaxHeight + 'px';
    },
    verticalMaxHeight: function verticalMaxHeight() {
      return this.options.search.fields.length * 30;
    }
  },
  data: function data() {
    return {
      title: '',
      titleStyle: {},
      flatDropDownMenu: [],
      inputDirection: 'vertical',
      //竖向vertical  横向transverse
      trunClose: true
    };
  },
  methods: {
    initTitle: function initTitle() {
      if (Gikam.isNotEmpty(this.options.search.fields) && Gikam.isNotEmpty(this.echartsOptions.title)) {
        if (Gikam.isNotEmpty(this.echartsOptions.title.text)) {
          this.title = this.echartsOptions.title.text;
          delete this.echartsOptions.title.text;
        }

        if (Gikam.isNotEmpty(this.echartsOptions.title.textStyle)) {
          this.titleStyle = this.echartsOptions.title.textStyle;
          delete this.echartsOptions.title.textStyle;
        }
      }
    },
    initDropDown: function initDropDown() {
      var optionsMenu = Gikam.isNotEmpty(this.options.dropDownMenu) ? this.options.dropDownMenu : this.echartsOptions.dropDownMenu;

      if (Gikam.isEmpty(optionsMenu)) {
        this.flatDropDownMenu = [];
        return;
      }

      var dropDownMenu = [];
      optionsMenu.forEach(function (item) {
        if (item.type === 'btnGroup') {
          item.items.forEach(function (list) {
            list.type === 'button' && dropDownMenu.push(list);
          });
        } else if (item.type === 'button') {
          dropDownMenu.push(item);
        }
      });
      this.flatDropDownMenu = dropDownMenu;
    },
    initForm: function initForm() {
      var form = this.options.search;
      form.titleWidthAuto && delete form.titleWidthAuto;
      Gikam.create(form.type, _objectSpread({
        renderTo: this.$refs.elForm
      }, form));
      this.initFormClass();
    },
    initFormClass: function initFormClass() {
      var formElWidth = this.$refs.search.clientWidth - 32;
      var fieldLength = this.options.search.fields.length;

      if (formElWidth / fieldLength < 260) {
        this.inputDirection = 'vertical';
      } else {
        this.inputDirection = 'transverse';
      }
    },
    changeTrunState: function changeTrunState() {
      this.trunClose = !this.trunClose;
    },
    showPicker: function showPicker(event) {
      var h = this.$createElement;

      var _this = this;

      var maxHeight = this.$el.getBoundingClientRect().height - 40;
      var calcHeight = this.flatDropDownMenu.length * 30;
      new Vue({
        el: Gikam.createDom('div', document.body),
        render: function render() {
          var h = arguments[0];
          return h(picker, {
            "attrs": {
              "target": event.target,
              "propDropDownMenu": _this.flatDropDownMenu,
              "propHeight": calcHeight < maxHeight ? calcHeight : maxHeight
            }
          });
        }
      });
    },
    setOption: function setOption() {
      var _this$echarts;

      this.initTitle();

      (_this$echarts = this.echarts).setOption.apply(_this$echarts, arguments);
    },
    sizeChangeHandle: function sizeChangeHandle() {
      this.echarts && this.echarts.resize();
      this.searchShow && this.initFormClass();
    }
  }
};