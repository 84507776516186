<template>
    <editor
        :is="editorType"
        ref="editor"
        :options="options"
        :propValue="propValue"
        :dataId="dataId"
        :propReadonly="readonly"
        :validateArg="validateArg"
        :class="[options.category, options.param]"
        :scope="form"
        @change="changeHandle"
    ></editor>
</template>

<script>
import selectEditor from '@/gikam/js/components/template/fields/select/select';
import SelectSource from '@/gikam/js/core/selectSource';
import { mapState } from 'vuex';
import RemoteSearchSelect from '@/gikam/js/components/template/fields/select/remote-search-select.vue';

export default {
    props: {
        options: Object,
        propValue: [Number, String],
        dataId: [Number, String],
        validateArg: Object,
        formData: Object
    },

    components: {
        selectEditor,
        RemoteSearchSelect
    },

    data() {
        return {
            readonly: this.options.readonly ?? false,
            items: []
        };
    },

    computed: {
        ...mapState(['form']),

        editorType() {
            return this.options.remoteSearch ? 'RemoteSearchSelect' : 'selectEditor';
        }
    },

    async created() {
        await this.initItems();
    },

    watch: {
        items(value) {
            this.$set(this.options, 'items', value);
        }
    },

    mounted() {
        this.form.editorManager[this.options.field] = this.$refs.editor;
    },

    methods: {
        async initItems() {
            this.items = await SelectSource.getItems(this.options);
        },

        changeHandle(...args) {
            this.$emit.apply(this, ['change', ...args]);
        }
    }
};
</script>
