import _objectSpread from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
import InputGroup from '@/gikam/js/components/input-group/input-group';
import { mapState } from 'vuex';
import Gikam from 'gikam';
export default {
  components: {
    InputGroup: InputGroup
  },
  props: {
    options: Object,
    propValue: String,
    validateArg: Object,
    dataId: [Number, String],
    formData: Object
  },
  data: function data() {
    return {
      value: {}
    };
  },
  computed: _objectSpread({}, mapState(['form'])),
  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler: function handler(formData) {
        this.value = this.options.items.reduce(function (total, item) {
          var _item$value;

          total[item.field] = (_item$value = item.value) !== null && _item$value !== void 0 ? _item$value : Gikam.getFieldValue(formData, item.field);
          return total;
        }, {});
      }
    }
  },
  created: function created() {
    var _this = this;

    this.options.items.forEach(function (item) {
      if (Gikam.isNotEmpty(item.value)) {
        Gikam.setFieldValue(_this.formData, item.field, item.value);
      }
    });
  },
  mounted: function mounted() {
    this.form.editorManager[this.options.field] = this.$refs.editor;
  },
  methods: {
    changeHandle: function changeHandle(_ref) {
      var name = _ref.name,
          value = _ref.value,
          onChange = _ref.onChange;
      this.$emit('change', name, value);
      onChange === null || onChange === void 0 ? void 0 : onChange.call(this.form, name, value);
    }
  }
};