<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 12 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
        <title>标记为完成</title>
        <desc>Created with Sketch.</desc>
        <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="画板" transform="translate(-109.000000, -146.000000)">
                <g id="标记为完成" transform="translate(106.996739, 146.000000)">
                    <rect id="矩形备份-4" opacity="0.459750221" x="0" y="0" :width="width" :height="height"></rect>
                    <circle
                        id="椭圆形备份-4"
                        stroke="#027AFF"
                        fill-rule="nonzero"
                        cx="8.04147382"
                        cy="5.84048366"
                        r="2.87522052"
                    ></circle>
                    <polyline
                        id="路径-3备份-5"
                        stroke="#027AFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        points="6.93152826 5.84048366 7.76573378 6.67468918 9.15141938 5.28900358"
                    ></polyline>
                    <path
                        d="M8,0.305975752 C11.160196,0.305975752 13.7220411,2.8678208 13.7220411,6.02801681 C13.7220411,7.98407216 12.077872,11.0772933 8.78953389,15.3076801 C8.73846923,15.3733738 8.67940535,15.4324377 8.61371159,15.4835023 C8.21119745,15.7963819 7.64490447,15.7533759 7.29356856,15.4019376 L7.21043204,15.3076799 L6.9160435,14.9262163 L6.37088756,14.2042982 C3.64226848,10.5352661 2.27795894,7.809839 2.27795894,6.02801681 C2.27795894,2.8678208 4.83980398,0.305975752 8,0.305975752 Z M8,1.30597575 C5.39208873,1.30597575 3.27795894,3.42010555 3.27795894,6.02801681 C3.27795894,7.5362776 4.57341547,10.1130303 7.18566375,13.6240738 L7.70770869,14.3152611 L7.99996192,14.6939677 C11.156706,10.6329286 12.7220411,7.68802012 12.7220411,6.02801681 C12.7220411,3.42010555 10.6079113,1.30597575 8,1.30597575 Z"
                        id="椭圆形"
                        :fill="color"
                        fill-rule="nonzero"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#027AFF'
        }
    }
};
</script>
