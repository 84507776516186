<template>
    <div v-if="isNotScroll" class="noScroll">
        <slot></slot>
    </div>
    <div
        v-else
        class="scroll-wrapper"
        :class="{ inner: showScrollBar, 'user-selected': resizing, adaptive, transition }"
        ref="wrapper"
        @wheel="scrollWheel"
        @touchstart="touchStart"
        @touchmove="touchMove"
        @touchend="touchEnd"
        @mouseover="showScrollBar = true"
        @mouseleave="showScrollBar = false"
    >
        <div class="scroll-container" ref="container" @scroll="preventScroll">
            <div
                class="scroll-content"
                ref="content"
                :style="{
                    transform: `translate3D(-${scrollLeft}px, -${scrollTop}px, 0)`,
                    width: maxWidth,
                    height: maxHeight
                }"
            >
                <slot></slot>
            </div>
        </div>
        <div class="horizontal-scrollbar" v-if="rateX < 1 && scrollingX" :style="{ height: barSize }">
            <div
                class="scrollbar"
                @mousedown="horizontalMouseDown"
                :style="{ width: horizontalWidth + 'px', left: left + 'px', background: barBackground }"
            ></div>
        </div>
        <div class="vertical-scrollbar" v-if="rateY < 1 && scrollingY" :style="{ width: barSize }">
            <div
                class="scrollbar"
                @mousedown="verticalMouseDown"
                :style="{ height: verticalHeight + 'px', top: top + 'px', background: barBackground }"
            ></div>
        </div>
    </div>
</template>

<script>
import Gikam from 'gikam';
const wheel = document.onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';

export default {
    props: {
        //是否允许纵向滚动
        scrollingY: {
            type: Boolean,
            default: true
        },
        //是否允许横向滚动
        scrollingX: {
            type: Boolean,
            default: true
        },
        //滚动条宽度
        barSize: {
            type: String,
            default: '8px'
        },
        //滚动条背景色
        barBackground: {
            type: String,
            default: 'rgba(187, 187, 187, 0.8)'
        },
        //适配移动端
        adaptive: {
            type: Boolean,
            default: false
        },
        // 鼠标滚轮滚动一下的距离
        distance: {
            type: Number,
            default: 60
        },
        // 是否是在弹窗内使用
        innerPanel: {
            type: Boolean,
            default: false
        },
        // 设置内容最大宽度
        maxWidth: {
            type: String,
            default: ''
        },
        // 设置内容最大高度
        maxHeight: {
            type: String,
            default: ''
        },
        scrollTransition: {
            type: Boolean,
            default: true
        },
        isNotScroll: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            //scroll容器宽度
            wrapperWidth: 0,
            //scroll容器高度
            wrapperHeight: 0,
            //内容宽度
            contentWidth: 0,
            //内容高度
            contentHeight: 0,
            //记录滚动状态
            resizing: false,
            //记录滚动条状态
            showScrollBar: false,
            //容器宽度比(容器宽度:内容宽度)
            rateX: 1,
            //内容高度比(容器高度:内容高度)
            rateY: 1,
            //记录横向滚动条初始位置
            initX: 0,
            //记录纵向滚动条初始位置
            initY: 0,
            //滚动条纵向位置
            top: 0,
            //滚动条横向位置
            left: 0,
            //是否添加滚动动画
            transition: this.scrollTransition,
            //横向滚动
            horizontalRolling: false,
            //纵向滚动
            verticalRolling: false
        };
    },

    mounted() {
        if (this.isNotScroll) {
            return;
        }
        const _this = this;
        if (window.MutationObserver) {
            this.observer = new window.MutationObserver(() => {
                _this.$nextTick(() => {
                    setTimeout(() => {
                        _this.initScroll();
                    }, 600);
                });
            });
            this.observer.observe(_this.$refs.wrapper, {
                subtree: true,
                attributes: true,
                childList: true
            });
        }
        _this.$nextTick(() => {
            _this.initScroll();
        });
        setTimeout(() => {
            _this.bindIframeScroll();
        }, 0);
    },

    methods: {
        bindIframeScroll() {
            const _this = this;
            const content = this.$refs.content;
            if (!content) return;
            const iframes = content.querySelectorAll('iframe');
            if (!iframes.length) return;
            iframes.forEach(function(iframe) {
                const _onload = iframe.onload;
                iframe.onload = function() {
                    _onload && _onload();
                    iframe.contentDocument.body.addEventListener(
                        wheel,
                        function(event) {
                            _this.scrollWheel(event, true);
                        },
                        { passive: false }
                    );
                    if (Gikam.isMobile()) {
                        iframe.contentDocument.body.ontouchstart = function(event) {
                            Gikam.finalDelay('mobileTouch', _this.touchStart(event), 200);
                        };
                        iframe.contentDocument.body.ontouchmove = function(event) {
                            _this.touchMove(event, true);
                        };
                        iframe.contentDocument.body.ontouchend = function(event) {
                            _this.touchEnd(event);
                        };
                    }
                };
            });
        },

        //处理浏览器宽度变化造成的页面显示不全
        onResize() {
            this.top = 0;
            this.left = 0;
            this.initScroll();
        },

        //由于tab等键造成的移动的处理
        preventScroll() {
            const { scrollLeft, scrollTop } = this.$refs.container;
            if (scrollLeft === 0 && scrollTop === 0) return;
            this.$refs.container.scrollLeft = 0;
            this.$refs.container.scrollTop = 0;
            this.scrollBy({
                dx: scrollLeft,
                dy: scrollTop
            });
        },

        initScroll() {
            if (!this.$refs.wrapper || !this.$refs.content) return;
            this.wrapperWidth = this.$refs.wrapper.offsetWidth;
            this.wrapperHeight = this.$refs.wrapper.offsetHeight;
            this.contentWidth = this.$refs.content.offsetWidth;
            this.contentHeight = this.$refs.content.offsetHeight;
            this.rateX = this.wrapperWidth / this.contentWidth;
            this.rateY = this.wrapperHeight / this.contentHeight;
            if (this.top === 0) {
                return;
            }
            this.top = this.getTop(this.top);
            this.left = this.getLeft(this.left);
            this.changeClarity();
        },

        horizontalMouseDown(e) {
            this.transition = false;
            this.$emit('transitionChange', false);
            //记录初始位置
            this.initX = e.pageX;
            !this.initY && (this.initY = e.pageY);
            //标记滚动状态
            this.resizing = true;
            //为全局绑定鼠标移动即按键抬起事件
            document.addEventListener('mousemove', this.horizontalMouseMove, false);
            document.addEventListener('mouseup', this.horizontalMouseUp, false);
        },

        horizontalMouseMove(e) {
            if (!this.resizing) return;
            if (Math.abs(e.pageY - this.initY) > 50) {
                this.horizontalMouseUp();
                return;
            }
            //计算移动距离
            const distanceX = e.pageX - this.initX;
            //鼠标移动节流
            if (Math.abs(distanceX) < 3) return;
            //移动滚动条
            this.left = this.getLeft(this.left + distanceX);
            this.initX = e.pageX;
        },

        horizontalMouseUp() {
            this.initX = null;
            this.initY = null;
            this.resizing = false;
            this.transition = true;
            this.$emit('transitionChange', true);
            document.removeEventListener('mousemove', this.horizontalMouseMove, false);
            document.removeEventListener('mouseup', this.horizontalMouseUp, false);
            this.changeClarity();
        },

        //纵向鼠标按下事件
        verticalMouseDown(e) {
            this.transition = false;
            this.$emit('transitionChange', false);
            this.initY = e.pageY;
            !this.initX && (this.initX = e.pageX);
            this.resizing = true;
            document.addEventListener('mousemove', this.verticalMouseMove, false);
            document.addEventListener('mouseup', this.verticalMouseUp, false);
        },

        //纵向鼠标移动事件
        verticalMouseMove(e) {
            if (!this.resizing) {
                return;
            }
            if (Math.abs(e.pageX - this.initX) > 50) {
                this.verticalMouseUp();
                return;
            }
            const distanceY = e.pageY - this.initY;
            if (Math.abs(distanceY) < 3) {
                return;
            }
            this.top = this.getTop(this.top + distanceY);
            this.initY = e.pageY;
        },

        //纵向鼠标按键抬起事件
        verticalMouseUp() {
            this.initY = null;
            this.initX = null;
            this.resizing = false;
            this.transition = true;
            this.$emit('transitionChange', true);
            document.removeEventListener('mousemove', this.verticalMouseMove, false);
            document.removeEventListener('mouseup', this.verticalMouseUp, false);
            this.changeClarity();
        },

        //鼠标滚轮滚动事件
        scrollWheel(e, iframe) {
            this.transition = true;
            this.verticalRolling = true;
            //判断纵向滚动条是否存在及是否可用
            let deltaY = 0;
            if (e.type === 'DOMMouseScroll') {
                deltaY = e.detail;
            } else {
                deltaY = e.deltaY || (iframe ? -e.wheelDelta : e.wheelDelta);
            }
            if (this.rateY < 1 && this.scrollingY) {
                //移动滚动条
                this.top += (this.getStep(deltaY) / this.contentHeight) * this.wrapperHeight;
                //top极限判断
                if (this.top > this.limitsY) {
                    this.top = this.limitsY;
                    this.$emit('scrollBottom');
                } else if (this.top < 0) {
                    this.top = 0;
                    this.$emit('scrollTop');
                } else {
                    if (!iframe) {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                    !this.innerPanel && Gikam.simulatedEvent(document, wheel);
                }
            }
            this.changeClarity();
            this.verticalRolling = false;
        },

        //计算滚轮单步移动距离
        getStep(distance) {
            return distance > 0 ? this.distance : -this.distance;
        },

        //滚动条滚动钩子函数
        handleScroll() {
            this.$emit('handle-scroll', this.scrollTop, this.scrollLeft);
        },

        //修改滚动时是否有动画
        changeTransition(transition) {
            this.transition = transition;
        },

        //依据传入参数，将内容滚动至指定位置
        scrollTo(pos) {
            this.initScroll();
            if (this.wrapperHeight - this.contentHeight < 0 && Gikam.isNumber(pos.y)) {
                this.top = this.getTop(
                    (pos.y / (this.contentHeight - this.wrapperHeight)) * (this.wrapperHeight - this.verticalHeight)
                );
            }
            if (this.wrapperWidth - this.contentWidth < 0 && Gikam.isNumber(pos.x)) {
                this.changeTransition(false);
                this.left = this.getLeft(
                    (pos.x / (this.contentWidth - this.wrapperWidth)) * (this.wrapperWidth - this.horizontalWidth)
                );
            }
            this.changeClarity();
        },

        //依据传入参数，将内容滚动特定距离
        scrollBy(pos) {
            this.initScroll();
            if (pos.dx && this.rateX < 1) {
                let x = pos.dx;
                let flag = false;
                this.left += this.getLeft(
                    (x / (this.contentWidth - this.wrapperWidth)) * (this.wrapperWidth - this.horizontalWidth),
                    flag
                );
            }
            if (pos.dy && this.rateY < 1) {
                let y = pos.dy;
                let flag = false;
                if (y < 0) {
                    y = Math.abs(y);
                    flag = true;
                }
                this.top += this.getTop(
                    (y / (this.contentHeight - this.wrapperHeight)) * (this.wrapperHeight - this.verticalHeight),
                    flag
                );
            }
            this.changeClarity();
        },

        //获取当前滚动条位置
        getPosition() {
            return {
                scrollTop: this.scrollTop,
                scrollLeft: this.scrollLeft
            };
        },

        //top极限判断
        getTop(top, flag) {
            let _top = top;
            _top = _top > 0 ? top : 0;
            _top = _top > this.limitsY ? this.limitsY : _top;
            if (this.wrapperHeight >= this.contentHeight) {
                _top = 0;
            }
            return flag ? -_top : _top;
        },

        //left极限判断
        getLeft(left, flag) {
            let _left = left;
            _left = _left > 0 ? _left : 0;
            _left = _left > this.limitsX ? this.limitsX : _left;
            return flag ? -_left : _left;
        },

        //移动端触摸事件
        touchStart(e) {
            this.initX = e.changedTouches[0].pageX;
            this.initY = e.changedTouches[0].pageY;
            this.resizing = true;
            this.transition = true;
        },

        // eslint-disable-next-line complexity
        touchMove(e, iframe) {
            const { pageX, pageY } = e.changedTouches[0];
            const distanceX = pageX - this.initX;
            const distanceY = pageY - this.initY;
            if (Math.abs(distanceY) > 5 && this.rateY < 1 && !this.horizontalRolling) {
                this.touchDelay('verticalRolling');
                this.transition = true;
                const dy = this.getTop(
                    (Math.abs(distanceY) / this.contentHeight) * this.wrapperHeight * 2,
                    distanceY < 0
                );
                if (this.top - dy < 0) {
                    this.top = 0;
                } else if (this.top - dy > this.limitsY) {
                    this.top = this.limitsY;
                } else {
                    this.top -= dy;
                }

                this.initY = pageY;
                !iframe && e.preventDefault();
                e.stopPropagation();
            } else if (Math.abs(distanceX) > 5 && this.rateX < 1 && !this.verticalRolling) {
                this.touchDelay('horizontalRolling');
                this.transition = false;
                const dx = this.getLeft((Math.abs(distanceX) / this.contentWidth) * this.wrapperWidth, distanceX < 0);
                if (this.left - dx < 0) {
                    this.left = 0;
                } else if (this.left - dx > this.limitsX) {
                    this.left = this.limitsX;
                } else {
                    this.left -= dx;
                }
                this.initX = pageX;
                !iframe && e.preventDefault();
                e.stopPropagation();
            }
        },

        touchEnd() {
            this.initX = null;
            this.initY = null;
            this.resizing = false;
            this.transition = true;
        },

        //移动后改为translate2D
        changeClarity() {
            setTimeout(() => {
                this.$refs.content &&
                    (this.$refs.content.style.transform = `translate(-${this.scrollLeft}px, -${this.scrollTop}px)`);
            }, 600);
        },

        //触发横向/纵向滚动
        touchDelay(dir) {
            this[dir] = true;
            setTimeout(() => {
                this[dir] = false;
            }, 500);
        },

        // 改变内容宽高后滚动到相对位置
        resizeScroll() {
            const topRate = this.top / (this.wrapperHeight - this.verticalHeight);
            const leftRate = this.left / (this.wrapperWidth - this.horizontalWidth);
            this.initScroll();
            this.top = (this.wrapperHeight - this.verticalHeight) * topRate;
            this.left = (this.wrapperWidth - this.horizontalWidth) * leftRate;
        }
    },

    computed: {
        //计算横向滚动条宽度
        horizontalWidth() {
            const width = this.rateX < 1 ? this.rateX * this.wrapperWidth : 0;
            return width < 10 ? 10 : width;
        },

        //计算纵向滚动条高度
        verticalHeight() {
            const height = this.rateY < 1 ? this.rateY * this.wrapperHeight : 0;
            return height < 10 ? 10 : height;
        },

        //计算纵向滚动内容位置
        scrollTop() {
            return (
                Math.round(
                    (this.top / (this.wrapperHeight - this.verticalHeight)) * (this.contentHeight - this.wrapperHeight)
                ) || 0
            );
        },

        //计算横向滚动内容位置
        scrollLeft() {
            return (
                Math.round(
                    (this.left / (this.wrapperWidth - this.horizontalWidth)) * (this.contentWidth - this.wrapperWidth)
                ) || 0
            );
        },

        limitsX() {
            return this.wrapperWidth - this.horizontalWidth;
        },

        limitsY() {
            return this.wrapperHeight - this.verticalHeight;
        }
    },

    watch: {
        scrollTop() {
            this.handleScroll();
        },

        scrollLeft() {
            this.handleScroll();
        },

        scrollTransition(val) {
            this.transition = val;
        },

        rateY(val) {
            this.$emit('hasVerticalScroll', val < 1);
        }
    }
};
</script>
<style scoped>
.scroll-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.scroll-wrapper > .scroll-container {
    position: relative;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    height: fit-content;
    height: 100%;
    scroll-behavior: smooth;
    transform-style: preserve-3d;
    -webkit-backface-visibility: hidden;
}

.scroll-wrapper > .scroll-container > .scroll-content {
    position: relative;
    display: inline-block;
    min-width: 100%;
    min-height: 100%;
    width: fit-content;
    -webkit-overflow-scrolling: touch;
    -webkit-overflow: auto;
}

.scroll-wrapper > div > .scrollbar {
    position: absolute;
    cursor: pointer;
    border-radius: 10px;
    opacity: 0;
    transition: opacity 0.5s;
}

.scroll-wrapper.transition > div > .scrollbar {
    transition: top 0.5s cubic-bezier(0, 0, 0.2, 1), opacity 0.5s;
}

.scroll-wrapper > .horizontal-scrollbar {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
}

.scroll-wrapper > .horizontal-scrollbar .scrollbar {
    width: 20px;
    height: 100%;
    top: 0;
}

.scroll-wrapper > .vertical-scrollbar {
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.scroll-wrapper.user-selected {
    user-select: none;
}

.scroll-wrapper.user-selected > .vertical-scrollbar,
.scroll-wrapper.user-selected > .horizontal-scrollbar {
    user-select: none;
}

.scroll-wrapper.inner > div > .scrollbar {
    opacity: 0.8;
}

.scroll-wrapper > .vertical-scrollbar .scrollbar {
    width: 100%;
    left: 0;
}

@media screen and (max-width: 768px) {
    .scroll-wrapper.adaptive {
        max-height: 200px;
    }

    .scroll-wrapper > div > .scrollbar {
        opacity: 0.8;
    }

    .scroll-wrapper > .horizontal-scrollbar {
        height: 6px !important;
    }
    .scroll-wrapper > .vertical-scrollbar {
        width: 6px !important;
    }
}
</style>
