import Gikam from '../core/gikam-core.js';
import window from './template/window';
import Base from './base.js';
import Vue from 'vue';
import jQuery from 'jquery';
import { I18N } from '@/gikam/i18n/I18N';

let defaultOptions = {
    renderTo: void 0,
    //页面大标题
    title: void 0,
    //定义子标题
    subTitle: null,
    //纪录缓存的window标识
    objectId: void 0,
    //是否显示标题，默认显示
    showTitle: true,
    //标题后的按钮位置，可选配置为'left','center','right'(默认)
    toolbarAlign: 'right',
    //标题及按钮工具栏显示在顶部
    toolbarTop: true,
    //是否进入全屏状态
    isEnterFullScreen: false,
    // 是否开启数据锁功能
    dataLock: false,
    // 宽度
    width: void 0,
    // 页面加载中模式,normal|retro|progress
    loadingMode: 'progress'
};

export default class Window extends Base {
    static windowManager = {};

    static htmlParseDom = null;

    static rememberWindow(window) {
        if (!window.options.objectId) {
            return;
        }
        this.listeners = {
            ready: Gikam.emptyFunction
        };
        if (window.hasIframe()) {
            window.hide();
            Window.windowManager[window.options.objectId] = window;
        } else {
            let fragment = document.createDocumentFragment();
            fragment.appendChild(window.model.$el);
            Window.windowManager[window.options.objectId] = fragment;
        }
    }

    constructor(param) {
        super();
        this.history = [];
        this.historyViewModel = [];
        this.historyCompStore = [];
        this.fragment = document.createDocumentFragment();
        this.activeUrl = void 0;
        this.listeners = { beforeClose: Gikam.emptyFunction };
        this.viewModelData = this.getViewModelData();
        // 窗口是否只读
        this.readonly = false;
        // 获取菜单url参数
        this.$param = {};
        // 子组件数量
        this.$compStoreNum = {};
        // 所有组件
        this.$compStore = {};
        // 根组件
        this.$window = this;
        // 页面id
        this.$pageId = null;
        // 存储页面的url
        this.pageUrl = '';
        // js容器
        this.jsFileManager = new Map();
        // 是否js编辑模式
        this.isEdit = false;
        // html内容
        this.htmlText = '';
        // 保存按钮
        // this.$saveButton = null;
        // 记录发生改变的组件
        this.$changeCompContainer = [];
        // 当前是否是点击的保存按钮
        this.$isSaveButtonClick = false;
        // 在只读页面需要显示的按钮
        this.$alwaysButton = [];
        // 数据锁标识
        this.$dataLockId = null;
        // 数据锁线程
        this.$lockWorker = null;
        // 整个窗口数据是否处于锁定状态
        this.$dataLocked = false;
        // 锁定用户
        this.$dataLockUser = null;
        // 页面中单个组件锁定,{compId:111,worker:xxx,dataLockId:222,dataLocked:false,dataLockUser:'admin'}
        this.$dataLockedComp = [];
        // 页面编辑中
        this.pageEditing = false;
        // 组件容器
        this.compInstanceContainer = {};
        // 初始化
        this.initialize(param, defaultOptions).init();
    }

    changeSubTitle(text) {
        text && (this.options.subTitle = text);
    }

    createModel() {
        const { options, viewModelData } = this;
        return new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            components: { window },
            provide: {
                window: this
            },
            data() {
                return { options, viewModelData };
            },
            render() {
                return <window ref="vm" options={this.options} viewModelData={this.viewModelData}></window>;
            }
        });
    }

    async showMask() {
        await Gikam.showMask(I18N.prop('window.dataLoading'), undefined, { mode: this.options.loadingMode });
    }

    closeMask() {
        Gikam.cleanMask();
    }

    async showPageMask() {
        await this.showMask();
    }

    closePageMask() {
        Gikam.cleanMask();
    }

    clean() {
        let toolbar = this.model.$el.querySelector('.toolbar');
        let body = this.model.$el.querySelector('.body');
        if (toolbar) {
            toolbar.innerHTML = '';
        }
        if (body) {
            body.innerHTML = '';
        }
    }

    rememberWindow() {
        this.model.$compChildren = this.$children;
        this.model.$alwaysButton = this.$alwaysButton;
        this.model.$el.$pageId = this.$pageId;
        this.$children = [];
        this.fragment.appendChild(this.model.$el);
        this.historyViewModel.push(this.model);
        this.historyCompStore.push(this.$compStore);
        this.init();
    }

    async load(url, remember = true, param = {}) {
        if (this.options.loadingMode === 'progress') {
            this.viewModelData.pageRequesting = true;
        } else {
            this.showPageMask();
        }
        const header = {};
        this.pageUrl = url;
        Gikam.extend(this.$param, Gikam.getRequestParam(url));
        await this.initDataLock();
        this.readonly = Gikam.isTrue(this.$param.readonly);
        if (this.options.objectId) {
            header['sunway-menu'] = this.options.objectId;
        }
        if (this.isEdit) {
            header['sunway-page-config'] = '1';
        }
        if (!Gikam.isEmptyObject(Gikam.loginUser)) {
            header['sunway-remember-me'] = Gikam.loginUser.ext$['loginToken'.toLocaleLowerCase()];
        }
        Gikam.getText(url, header)
            .done(r => {
                if (typeof r === 'string' && r.indexOf('<!DOCTYPE') === 0) {
                    return;
                }
                if (remember) {
                    if (Gikam.isNotEmpty(this.history)) {
                        this.rememberWindow();
                        this.$cleanStoreComp();
                        // 路由替换
                        param.routerReplace && this.history.pop();
                        this.$pageId = this.getPageId(url);
                    }
                    this.history.push(url);
                }
                this.htmlText = r;
                try {
                    this.loadHtmlScript(r);
                } catch (e) {
                    Gikam.error(e);
                }
                setTimeout(() => this.getJsFileText(r));
            })
            .fail(result => {
                if (result.status === 404 || result.status === 500) {
                    let fragment = document.createDocumentFragment();
                    fragment.appendChild(this.$window.model.$el);
                    top.workspace.viewModel.$refs.vm.$refs.businessArea.appendChild(fragment);
                    this.loadHtmlScript(result.responseText);
                }
            })
            .always(() => {
                if (this.options.loadingMode === 'progress') {
                    this.viewModelData.pageRequesting = false;
                } else {
                    this.closePageMask();
                }
            });
    }

    /**
     * @description 将url作为页面id
     * @private
     * @param {String} url请求地址
     * @memberof Window
     */
    getPageId(url) {
        return url ? url.replace(/\?[\s\S]+/g, '') : null;
    }

    enterFullScreen() {
        let el = this.model.$refs.vm.$el;
        if (el) {
            this.options.isEnterFullScreen = true;
        }
        return;
    }

    goBack(options = {}) {
        const def = jQuery.Deferred();
        this.validateWindowComp(I18N.prop('window.save.before.back')).then(boolean => {
            if (boolean) {
                def.resolve();
                return;
            }
            this.showPageMask().then(async () => {
                this.pageUrl = this.history.pop();
                await this.$destroyCurrentModel();
                const lastViewModel = this.historyViewModel.pop();
                this.$compStore = this.historyCompStore.pop();
                this.$param.readonly = false;
                this.options.renderTo.appendChild(lastViewModel.$el);
                this.model = lastViewModel;
                this.$dom = this.model.$refs.vm.$refs.body;
                this.$dataLocked = false;
                this.$children = this.model.$compChildren;
                const storage = this.model.storage;
                if (storage?.grid) {
                    const grid = storage.grid;
                    const { scrollTop: y, scrollLeft: x } = storage.grid.store.state;
                    grid.refreshRowByIndex(storage.index).done(() => {
                        this.model.storage = null;
                        grid.scrollTo({ x, y });
                        def.resolve();
                    });
                } else {
                    if (options.refresh !== false) {
                        this.refreshData();
                    }
                    def.resolve();
                }
                this.closePageMask();
                this.$pageId = this.model.$el.$pageId;
                this.destroyLockData();
            });
        });
        return def;
    }

    removeTitle() {
        this.options.showTitle = false;
        return this;
    }

    setToolbarAlign(align) {
        this.model.$refs.vm.toolbarAlign = align;
        return this;
    }

    save(param) {
        let dataList = [null];
        let _this = this;
        let def = jQuery.Deferred();
        let mainForm = Gikam.getInstance(jQuery(_this.$dom).find('.form:first'));
        if (!param.url) {
            Gikam.error('url not exists');
        }
        if (!param.page.setCompUrl) {
            Gikam.error('do not find updateCompUrl method');
        }
        let $comps = jQuery(this.$dom).find('.form,.grid:not(.grid-fixed),.simple-grid');
        let validateResult = true;
        $comps.each(function() {
            let comp = Gikam.getInstance(jQuery(this));
            let compData = [];
            let d = comp.getData();
            if (comp.validate() === false) {
                validateResult = false;
                return false;
            }
            if (Gikam.isEmpty(comp.options.service)) {
                return true;
            }
            compData.push(comp.options.service);
            compData.push(Array.isArray(d) ? d : [d]);
            if (typeof param.format === 'function') {
                compData = param.format(comp.options.id, compData);
            }
            dataList.push(compData);
        });
        if (validateResult === false) {
            this.closeMask();
            return def;
        }
        this.showMask();
        Gikam[Gikam.isEmpty(param.page.param.id) ? 'postText' : 'put'](
            param.url,
            Gikam.getJsonWrapper.apply(Gikam, dataList),
            param.header
        )
            .done(function(id) {
                _this.saveSuccess();
                if (Gikam.isNotEmpty(param.page.param.id)) {
                    def.resolve();
                    return;
                }
                mainForm.toFieldsReadonly(['id']);
                param.page.param.id = arguments[0];
                param.page.setCompUrl.call(param.page);
                let async = [];
                $comps.each(function() {
                    let comp = Gikam.getInstance(this);
                    comp.getOptions().service && async.push(comp.refresh());
                });
                jQuery.when.apply(jQuery, async).done(function() {
                    def.resolve(id);
                });
            })
            .always(function() {
                _this.$isSaveButtonClick = false;
                _this.closeMask();
            });
        return def;
    }

    setDefaultOptions(options) {
        Gikam.extend(true, defaultOptions, options.defaultOptions);
    }

    showHeader() {
        this.model.$refs.vm.headerShow = true;
    }

    /**
     * @description 注册window下所有组件
     * @private
     * @param {String} type 组件类型
     * @param {*} comp 组件对象
     * @memberof Window
     */
    $addStoreComp(type, comp) {
        if (!this.$compStore[type]) {
            this.$compStore[type] = [];
        }
        this.$compStore[type].push(comp);
    }

    $removeStoreComp(type, comp) {
        const index = this.$compStore[type]?.indexOf(comp);
        if (index > -1) {
            this.$compStore[type].splice(index, 1);
        }
    }

    /**
     * @description 清空window下所有组件
     * @private
     * @memberof Window
     */
    $cleanStoreComp() {
        this.$compStore = {};
    }

    /**
     * @description 刷新window下所有组件数据
     * @public
     * @memberof Window
     */
    refreshData() {
        if (Gikam.isFalse(this.$param.refresh)) {
            return;
        }
        for (let compType in this.$compStore) {
            this.$compStore[compType].forEach(comp => {
                comp.refresh && comp.$canRefreshPageSwitch && comp.refresh();
            });
        }
    }

    init() {
        this.model = this.createModel();
        const { body, toolbar } = this.model.$refs.vm.$refs;
        this.$dom = body;
        this.$toolbar = toolbar;
        this.initItems();
    }

    /**
     * @description 创建子组件方法
     * @param {Object} options {title:'',subTitle:'',header:[],footer:[],body:[],items:[]}
     * @memberof Window
     */
    createChildren(options) {
        const { title, subTitle, body, items } = options;
        if (title) {
            this.options.title = title;
        }
        if (subTitle) {
            this.options.subTitle = subTitle;
        }
        const children = body || items;
        const childrenFill = children.length === 1;
        children.forEach(item => {
            item.renderTo = this.$dom;
            item.$window = this;
            item.fill = childrenFill;
            Gikam.create(item.type, item);
        });
    }

    /**
     * @description 是否有iframe
     * @private
     * @memberof Window
     */
    hasIframe() {
        return this.model.$el.querySelector('iframe');
    }

    /**
     * @description 隐藏
     * @public
     * @memberof Window
     */
    hide() {
        this.model.$refs.vm.hide();
    }

    /**
     * @description 显示
     * @public
     * @memberof Window
     */
    show() {
        this.model.$refs.vm.show();
    }

    /**
     * @description 父组件尺寸变更时触发
     * @private
     * @param
     * @memberof Tab
     */
    onResize() {
        this.options.width = null;
        this.$width = this.$dom.clientWidth;
        this.$height = this.$dom.clientHeight;
        this.$children.forEach(comp => {
            comp.onResize && comp.onResize();
        });
    }

    loadHtmlScript(text) {
        if (!Window.htmlParseDom) {
            Window.htmlParseDom = Gikam.createDom('div', document.body);
        }
        jQuery(Window.htmlParseDom).html(text);
    }

    /**
     * @description
     * @private
     * @memberof Window
     */
    getJsFileText(htmlText) {
        const lines = htmlText.split(/\n/);
        const temp = { path: null, content: [] };
        let beginFlag = false;
        lines.forEach(line => {
            if (line.indexOf('/*start/') === 0) {
                temp.path = line.replace(/(^\/\*start)|(\*\/)/g, '');
                beginFlag = true;
                return;
            }
            if (line.indexOf('/*end/') === 0) {
                beginFlag = false;
                this.jsFileManager.set(temp.path, temp.content.join('\n'));
                return;
            }
            if (beginFlag) {
                temp.content.push(line);
            }
        });
    }

    onBeforeClose(callback) {
        if (Gikam.isFunction(callback)) {
            this.listeners.beforeClose = callback;
        }
    }

    /**
     * @description 表格或表单状态改变之后对状态进行记录
     * @param {*} flag
     * @memberof Window
     * @private
     */
    changeSaveButtonPrompt(comp, flag) {
        if (!this.model.$saveButton) {
            return;
        }
        if (flag === true) {
            if (!this.$changeCompContainer.some(item => item === comp)) {
                this.$changeCompContainer.push(comp);
            }
        } else {
            const index = this.$changeCompContainer.indexOf(comp);
            this.$changeCompContainer.splice(index, 1);
        }
        this.model.$saveButton.prompt = Gikam.isEmpty(this.$changeCompContainer) ? false : true;
    }

    /**
     * @description 还原到没有任何更改的状态
     * @memberof Window
     * @private
     */
    cleanSavePromptState() {
        if (!this.model.$saveButton) {
            return;
        }
        this.$changeCompContainer = [];
        this.model.$saveButton.prompt = false;
        const compType = ['Form', 'Grid'];
        compType.forEach(compType => {
            if (this.$compStore[compType]) {
                this.$compStore[compType].forEach(comp => {
                    comp.$changeFields = {};
                    comp.initData = Gikam.deepExtend(comp.options.data);
                });
            }
        });
    }

    /**
     * @description 调用save方法保存成功之后
     * @memberof Window
     * @private
     */
    saveSuccess() {
        this.cleanSavePromptState();
    }

    /**
     * @description 校验是否有发生改变的组件
     * @memberof Window
     * @private
     */
    validateWindowComp(message, clean) {
        return new Promise(resolve => {
            if (Gikam.isNotEmpty(this.$changeCompContainer)) {
                Gikam.confirm(
                    '提示',
                    message,
                    () => {
                        if (clean) {
                            this.$changeCompContainer = [];
                        }
                        resolve(false);
                    },
                    () => resolve(true)
                );
            } else {
                resolve(false);
            }
        });
    }

    /**
     * @description 业务页面初始化加载的时候使用，页面上按钮总是显示
     * @param Array [buttonId]
     * @memberof Window
     * @public
     */
    setAlwaysButton(buttons) {
        this.$alwaysButton = buttons;
    }

    /**
     * @private
     * @description
     * @returns
     * @memberof Window
     */
    getDataLockState(dataLockId) {
        return new Promise(resolve => {
            const url = `${Gikam.IFM_CONTEXT}/secure/core/module/gikam/repositories/mark-editable/${dataLockId}`;
            Gikam.post(url).done(data => resolve(data));
        });
    }

    /**
     * @description
     * @memberof Window
     */
    async initDataLock() {
        if (this.options.dataLock && this.$param.id) {
            this.$dataLockId = (
                this.pageUrl.split('?')[0].replace(Gikam.IFM_CONTEXT, '') + `/${this.$param.id}`
            ).replace(/\//g, '-');
            await this.lockData();
        }
    }

    /**
     * @private
     * @description
     * @memberof Window
     */
    async lockData() {
        const result = await this.getDataLockState(this.$dataLockId);
        this.$dataLocked = !result.editable;
        this.$dataLockUser = result.lockUserName;
        if (this.$dataLocked === false) {
            this.$lockWorker = new Worker(process.env.BASE_URL + 'worker/datalock.worker.js');
            this.$lockWorker.postMessage({ IFM_CONTEXT: Gikam.IFM_CONTEXT, markFlag: this.$dataLockId });
        }
    }

    /**
     * @private
     * @description
     * @memberof Window
     */
    destroyLockData() {
        if (this.options.dataLock && this.$lockWorker) {
            this.$lockWorker.postMessage('end');
            this.$lockWorker.terminate();
            this.$lockWorker = null;
        }
        this.$dataLockedComp
            .filter(item => item.lockWorker)
            .forEach(item => {
                item.lockWorker.postMessage('end');
                item.lockWorker.terminate();
                item.lockWorker = null;
            });
        this.$dataLockedComp.length = 0;
    }

    /**
     * @private
     * @description
     * @memberof Window
     */
    async lockDataByComp(compOptions, dataId) {
        if (this.options.dataLock && compOptions.dataLockWatch) {
            const url = this.pageUrl.split('?')[0].replace(Gikam.IFM_CONTEXT, '');
            let dataLockedComp = this.$dataLockedComp.filter(item => item.compId === compOptions.id)[0];
            let dataLockId = `${url}/${compOptions.id}/${dataId}`.replace(/\//g, '-');
            if (dataLockedComp) {
                if (dataLockedComp.lockWorker) {
                    dataLockedComp.lockWorker.postMessage('end');
                    dataLockedComp.lockWorker.terminate();
                    dataLockedComp.lockWorker = null;
                }
                dataLockedComp.dataLockId = dataLockId;
            } else {
                dataLockedComp = {
                    compId: compOptions.id,
                    lockWorker: null,
                    dataLockId: dataLockId,
                    dataLocked: false
                };
                this.$dataLockedComp.push(dataLockedComp);
            }
            const result = await this.getDataLockState(dataLockId);
            dataLockedComp.dataLocked = !result.editable;
            dataLockedComp.dataLockUser = result.lockUserName;
            if (dataLockedComp.dataLocked === false) {
                dataLockedComp.lockWorker = new Worker(process.env.BASE_URL + 'worker/datalock.worker.js');
                dataLockedComp.lockWorker.postMessage({
                    IFM_CONTEXT: Gikam.IFM_CONTEXT,
                    markFlag: dataLockedComp.dataLockId
                });
            }
        }
    }

    /**
     * @description 子组件是否锁定
     * @param {*} compOptions
     * @returns
     * @memberof Window
     */
    isCompLocked(compOptions) {
        if (compOptions.dataLockWatch) {
            return false;
        }
        if (this.$dataLocked === true) {
            return true;
        }
        if (compOptions.watchCompId) {
            let dataLockedComp = this.$dataLockedComp.filter(item => item.compId === compOptions.watchCompId)[0];
            return dataLockedComp.dataLocked;
        } else {
            return this.$dataLockedComp.length === 0 ? false : this.$dataLockedComp[0].dataLocked;
        }
    }

    /**
     * @private
     * @description 获取数据锁用户
     * @memberof Window
     */
    getDataLockUser(compOptions) {
        if (this.$dataLocked === true) {
            return this.$dataLockUser;
        }
        if (compOptions.watchCompId) {
            let dataLockedComp = this.$dataLockedComp.filter(item => item.compId === compOptions.watchCompId)[0];
            return dataLockedComp.dataLockUser;
        } else {
            return this.$dataLockedComp.length === 0 ? false : this.$dataLockedComp[0].dataLockUser;
        }
    }

    // 打开页面编辑
    openEditPage() {
        this.model.$refs.vm.pageEdit = true;
    }

    refresh() {
        this.bindInstance(this.model.$el);
        this.$compStore = {};
        this.load(Gikam.getContextUrl(this.pageUrl), false);
    }

    openSettingPanel(param) {
        Vue.set(this.options, 'settingPanelOptions', param);
    }

    /**
     * @private
     * @description
     * @memberof Window
     */
    async activePageEditSettingItem(item) {
        if (this.$pageEditActiveItem === item) {
            return;
        }
        if ((await this.model.$activeSettingPanel?.onBeforeClose()) === false) {
            return false;
        }
        if (this.$pageEditActiveItem) {
            this.openSettingPanel();
            this.$pageEditActiveItem.active = false;
        }
        item.active = true;
        this.$pageEditActiveItem = item;
    }

    /**
     * @description 获取页面url地址
     * @memberof Window
     */
    getMainPageUrl() {
        return this.pageUrl.split('?')[0].replace(Gikam.IFM_CONTEXT, '');
    }

    async $destroyCurrentModel() {
        for (const childComp of this.$children) {
            await childComp.$destroy();
        }
        this.$compStore = null;
        this.model.$destroy();
        Gikam.removeDom(this.model.$el);
        this._model = null;
    }

    destroy() {
        delete Gikam.compInstanceContainer[this.options.id];
        delete this.compInstanceContainer[this.options.id];
        if (Window.windowManager[this.options.objectId]) {
            delete Window.windowManager[this.options.objectId];
        }
        for (const viewModel of this.historyViewModel) {
            for (const childComp of viewModel.$children) {
                childComp.$destroy();
            }
            viewModel.$destroy();
            viewModel.$alwaysButton = null;
        }
        this.$compStore = {};
        for (const childComp of this.$children) {
            childComp.$destroy();
        }
        this.destroyLockData();
        this.model.$destroy();
        Gikam.removeDom(this.model.$el);
        if (Gikam.window === this) {
            Gikam.window = null;
        }
        Object.keys(this).forEach(key => (this[key] = null));
    }

    initItems() {
        if (Gikam.isEmpty(this.options.items)) {
            return;
        }
        this.createChildren({ items: this.options.items });
    }

    /**
     * @private
     * @description
     * @memberof Window
     */
    getViewModelData() {
        return {
            pageRequesting: false
        };
    }
}
