//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    color: {
      type: String,
      default: '#7F7F7F'
    }
  }
};