import _toConsumableArray from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Gikam from 'gikam';
export default {
  name: 'shuttle-frame',
  props: {
    options: Object
  },
  data: function data() {
    return {
      isLock: false
    };
  },
  inject: {
    shuttleFrame: {
      default: null
    }
  },
  methods: {
    add: function add() {
      this.options.addList.length > 0 && !this.options.readonly && this.changeData('add');
    },
    del: function del() {
      this.options.delList.length > 0 && !this.options.readonly && this.changeData('del');
    },
    changeData: function changeData(type) {
      var _this = this;

      if (this.isLock) {
        return;
      }

      this.isLock = true;
      this.shuttleFrame.initData(true);
      this.options.chooseGrid = true;

      if (type === 'add') {
        this.addList();
      } else {
        if (!this.options.onBeforeDel(this.options.delList)) {
          this.options.delList = [];
          this.isLock = false;
          return;
        }

        this.shuttleFrame.leftGirdTotal += this.options.delList.length;
        this.options.data = [].concat(_toConsumableArray(this.options.data), _toConsumableArray(this.options.delList));
        var func = this.shuttleFrame.$getFieldValue;
        var key = this.options.delRepeatField;
        this.options.rightData = this.options.rightData.filter(function (item) {
          return !_this.options.data.some(function (obj) {
            return func(obj, key) === func(item, key);
          });
        });

        if (this.options.onDelClick) {
          this.options.onDelClick(this.options.delList, this.options.data, this.options.rightData);
        }
      }

      this.refreshData();
      setTimeout(function () {
        _this.shuttleFrame.leftGrid.getData().forEach(function (item, index) {
          _this.shuttleFrame.leftGrid.model.$refs.vm.$refs['checkbox_' + index] && (_this.shuttleFrame.leftGrid.model.$refs.vm.$refs['checkbox_' + index][0].checked = false);
        });

        _this.shuttleFrame.rightGrid.getData().forEach(function (item, index) {
          _this.shuttleFrame.rightGrid.model.$refs.vm.$refs['checkbox_' + index] && (_this.shuttleFrame.rightGrid.model.$refs.vm.$refs['checkbox_' + index][0].checked = false);
        });

        _this.options.addList = [];
        _this.options.delList = [];

        _this.shuttleFrame.leftOriginalDataHandle();

        _this.shuttleFrame.rightOriginalDataHandle();

        _this.isLock = false;
      });
    },
    addList: function addList() {
      var _this2 = this;

      if (!this.options.onBeforeAdd(this.options.addList)) {
        this.options.addList = [];
        return;
      } else if (this.options.onBeforeAdd(this.options.addList) instanceof Array) {
        this.options.addList = this.options.onBeforeAdd(this.options.addList);
      } //清除右侧查询条件 为了不影响下面逻辑 先手动修改数据 再修改筛选的值


      this.shuttleFrame.rightGrid.model.$refs.vm.$refs.header.filterOriginalData = {};
      var data = Gikam.deepExtend(this.shuttleFrame.rightGrid.data);
      this.shuttleFrame.options.rightData = data;
      this.shuttleFrame.rightGrid.options.data = data;
      this.shuttleFrame.rightGrid.trigger('loadSuccess');
      !this.shuttleFrame.rightGrid.options.serverSearch && this.shuttleFrame.rightGrid.cleanHeadRequestData();
      this.shuttleFrame.leftGirdTotal = this.shuttleFrame.leftGirdTotal > this.options.addList.length ? this.shuttleFrame.leftGirdTotal - this.options.addList.length : 0;
      this.options.rightData = [].concat(_toConsumableArray(this.options.rightData), _toConsumableArray(this.options.addList));
      var func = this.shuttleFrame.$getFieldValue;
      var key = this.options.delRepeatField;
      this.options.data = this.options.data.filter(function (item) {
        return !_this2.options.rightData.some(function (obj) {
          return func(obj, key) === func(item, key);
        });
      });

      if (this.options.onAddClick) {
        this.options.onAddClick(this.options.addList, this.options.data, this.options.rightData);
      }
    },
    refreshData: function refreshData() {
      this.shuttleFrame.updateLeftGridByData(this.options.data);
      this.shuttleFrame.updateRightGridByData(this.options.rightData);
    }
  }
};