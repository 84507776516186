<template>
    <div
        class="field-container"
        :is="editorType"
        :options="options"
        :validateArg="formData"
        :dataId="formData.id"
        :propValue="propValue"
        :propReadonly="readonly"
        :formData="formData"
        :style="styleObject"
        @change="changeHandle"
        @valueChange="changeHandle"
        @enterKeyDown="enterKeyDownHandle"
        @afterChoose="afterChooseHandle"
        @focus="focusHandle"
        @click="clickHandle"
        @editing="editingHandle"
        @rendered="renderedHandle"
        @dataChange="dataChangeHandle"
        @dblclick="dblclickHandle"
    ></div>
</template>

<script>
import Gikam from 'gikam';
import { mapState } from 'vuex';
import Vue from 'vue';

const files = require.context('@/gikam/js/components/form/vue/editor', true, /\.vue$/, 'sync');
files.keys().map(key => {
    const name = key.slice(key.lastIndexOf('/') + 1).replace('.vue', '');
    Vue.component(name, files(key).default);
});

export default {
    props: {
        options: Object,
        formData: Object
    },

    data() {
        return {
            readonly: this.options.readonly ?? false
        };
    },

    computed: {
        ...mapState(['form']),

        editorType() {
            let type = this.options.type || 'text';
            const lowerCase = this.toFirstLowerCase(type);

            if (['float', 'integer', 'number'].includes(type)) {
                return 'formNumber';
            }
            if (type === 'checkbox' && Gikam.isNotEmpty(this.options.items)) {
                return 'formCheckboxItems';
            }
            return `form${lowerCase}`;
        },

        propValue() {
            const value = Gikam.getFieldValue(this.formData, this.options.field);
            return value ?? this.options.value;
        },

        styleObject() {
            const style = {};
            const { inputWidth } = this.options;
            if (inputWidth) {
                style.width = Gikam.isString(inputWidth) ? inputWidth : `${inputWidth}px`;
            }
            return style;
        },

        value() {
            return this.propValue;
        }
    },

    methods: {
        changeHandle(...args) {
            const [field, value] = args;
            Gikam.setFieldValue(this.formData, field, value);
            const { onChange, autoSave } = this.options;
            if (onChange && this.form.triggerChangeFlag === true) {
                onChange.apply(this.form, args);
            }
            if (this.form.canAutoSave) {
                const data = { id: this.formData.id, ext$: {} };
                Gikam.setFieldValue(data, field, value);
                if (Gikam.isFalse(autoSave)) {
                    return;
                }
                this.form.autoSave(data);
            }
            if (this.form.triggerChangeFlag === true) {
                this.form.filedChangeHandle({ field, value });
            }
        },

        enterKeyDownHandle(...args) {
            Gikam.setFieldValue(this.formData, args[1], args[0]);
        },

        afterChooseHandle(...args) {
            this.options.onAfterChoose?.apply(this.form, args);
        },

        focusHandle(...args) {
            this.options.onFocus?.apply(this.form, args);
        },

        clickHandle(...args) {
            this.options.onClick?.apply(this.form, args);
        },

        dblclickHandle(...args) {
            this.options.onDblclick?.apply(this.form, args);
        },

        dataChangeHandle(...args) {
            this.options.onDataChange?.apply(this.form, args);
        },

        editingHandle(state) {
            this.form.editorType = state ? this.options.type || 'text' : false;
            this.form.editorId = this.formData.id;
            if (state) {
                const wheel = document.onmousewheel ? 'mousewheel' : 'DOMMouseScroll';
                Gikam.simulatedEvent(document, wheel);
                Gikam.simulatedEvent(document, 'click');
            }
        },

        renderedHandle(...args) {
            const { onRendered, field } = this.options;
            Gikam.finalDelay(
                'formSelectRendered' + field,
                () => {
                    onRendered?.apply(this.form, args);
                },
                41
            );
        },

        toFirstLowerCase(type) {
            return type.replace(/( |^)[a-z]/g, L => L.toUpperCase());
        }
    }
};
</script>
