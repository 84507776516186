<template>
    <div
        class="formula"
        :style="styleObject"
        @click="clickHandle"
        v-html="value"
        :class="{ 'validate-error': !validateResult, readonly, invisible }"
        @dblclick="dblclickHandle"
        @mousedown.stop
    ></div>
</template>

<script>
import Gikam from '../../../../core/gikam-core';

export default {
    props: {
        height: {
            style: Number,
            default: 100
        },
        options: Object,
        propValue: [String, Number],
        rowIndex: Number,
        cellIndex: Number,
        propReadonly: {
            type: Boolean,
            default: void 0
        },
        //编辑器中的边框等样式是否显示出来
        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            validateResult: true,
            readonly: Gikam.isNotEmpty(this.propReadonly) ? this.propReadonly : this.options.readonly,
            field: this.options.field,
            value: this.propValue,
            invisible: this.propInvisible
        };
    },

    computed: {
        styleObject() {
            return {
                height: this.height + 'px'
            };
        }
    },

    watch: {
        value(val, oldVal) {
            this.$emit('change', this.options.field, val, this.rowIndex, {oldValue: oldVal});
        },

        propValue(val) {
            this.value = val;
        }
    },

    methods: {
        dblclickHandle() {
            this.$emit('dblclick', this);
        },

        clickHandle() {
            if (this.readonly) {
                return;
            }
            this.$emit('editing', false);
            const _this = this;
            const modal = Gikam.create('modal', {
                title: '公式编辑器',
                width: 820,
                height: 625,
                src: Gikam.IFM_CONTEXT + '/static/plugin/ueeditor/index.html',
                onBeforeClose() {
                    const innerWindow = modal.model.$refs.vm.$refs.iframe.contentWindow;
                    _this.value = innerWindow.getData();
                    _this.$emit('editing', true);
                }
            });
            const iframe = modal.model.$refs.vm.$refs.iframe;
            iframe.onload = () => {
                iframe.contentWindow.setData(this.value);
            };
        }
    }
};
</script>

<style scoped>
.formula {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.formula >>> p {
    margin: 0;
}

.formula.readonly {
    background-color: #f4f4f4;
}
</style>
