<template>
    <formula
        ref="editor"
        :options="options"
        :propValue="propValue"
        :dataId="dataId"
        :propReadonly="readonly"
        :validateArg="validateArg"
        @change="changeHandle"
        @editing="editingHandle"
        @dblclick="dblclickHandle"
    ></formula>
</template>

<script>
import formula from '@/gikam/js/components/template/fields/formula/formula';
import { mapState } from 'vuex';

export default {
    props: {
        options: Object,
        propValue: [Number, String],
        dataId: [Number, String],
        validateArg: Object,
        formData: Object
    },

    components: {
        formula
    },

    data() {
        return {
            readonly: this.options.readonly ?? false
        };
    },

    computed: {
        ...mapState(['form'])
    },

    mounted() {
        this.form.editorManager[this.options.field] = this.$refs.editor;
    },

    methods: {
        changeHandle(...args) {
            this.$emit.apply(this, ['change', ...args]);
        },

        editingHandle(...args) {
            this.$emit.apply(this, ['editing', ...args]);
        },
        
        dblclickHandle(...args) {
            this.$emit.apply(this, ['dblclick', ...args]);
        }
    }
};
</script>
