import Gikam from '../../../core/gikam-core.js';
import Base from '../../base.js';
import Vue from 'vue';
import imgGrid from '../vue/imgGrid.vue';
import { I18N } from '@/gikam/i18n/I18N.js';

// 菜单图标配置组件
let defaultOptions = {
    url: void 0,
    data: [],
    // 隐藏头部按钮
    hiddenBtn: [],
    width: void 0,
    height: void 0,
    onClick: void 0,
    onDblclick: void 0,
    // 组件渲染完成
    onRendered: void 0,
    // 点击删除按钮
    onDelete: void 0,
    page: null,
    // 自定义按钮
    customBottons: []
};

export default class ImgGrid extends Base {
    constructor(options) {
        super(options);
        this.initialize(options, defaultOptions).init();
        this.listeners = {
            loadSuccess: Gikam.emptyFunction
        };
    }

    initPanel() {
        const _this = this;
        this.imgsPanel = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            provide: {
                imgGrid: _this
            },
            components: { imgGrid },
            data() {
                return {
                    options: _this.options
                };
            },
            render() {
                return <img-grid ref="imgGrid" options={this.options}></img-grid>;
            }
        });
        this.insertBtnGroup();
        this.bindInstance(this.imgsPanel.$el);
    }

    // 头部按钮-删除，刷新
    insertBtnGroup() {
        let _this = this;
        let items = [
            {
                type: 'button',
                text: I18N.prop('imgGrid.remove'),
                icon: 'remove',
                onClick: function() {
                    let activeNode = _this.imgsPanel.$refs.imgGrid.activeNode;
                    _this.options.onDelete && _this.options.onDelete(_this.getSelections(), activeNode);
                }
            },
            {
                type: 'button',
                text: I18N.prop('imgGrid.refresh'),
                icon: 'refresh',
                color: 'primary',
                onClick: function() {
                    _this.refresh();
                }
            }
        ];

        items = items.filter((item, index) => {
            return !this.options.hiddenBtn[index];
        });
        // 自定义按钮
        items = items.concat(this.options.customBottons);

        (!this.options.hiddenBtn[0] || !this.options.hiddenBtn[1] || this.options.customBottons.length > 0) &&
            Gikam.create('btnGroup', {
                renderTo: this.imgsPanel.$refs.imgGrid.$refs['btnGroup'],
                id: 'left-grid',
                padding: '5px 0 0 0',
                items: items
            });
    }

    /**
     * 动态填充数据
     * @public
     * @param { Array } list -图标数组
     * @memberof imgGrid
     */
    setData(data) {
        this.options.data = data;
    }

    /**
     * 返回选中图片
     * @public
     * @memberof imgGrid
     */
    getSelections() {
        return this.imgsPanel.$refs.imgGrid.getSelections();
    }

    /**
     * 所有图片
     * @public
     * @memberof imgGrid
     */
    getData() {
        return this.imgsPanel.$refs.imgGrid.getData();
    }

    // url参数
    queryParams() {
        let _this = this;
        this.options.requestData &&
            Gikam.each(this.options.requestData, function(k, v) {
                if (Gikam.isEmpty(v)) delete _this.options.requestData[k];
            });
        const requestData = {
            f: Gikam.extend(true, {}, this.options.requestData),
            n: Gikam.isNotEmpty(this.options.pageNum) ? this.options.pageNum : -1,
            s: this.options.pageSize ? this.options.pageSize : 50
        };
        return Gikam.getJsonWrapper(requestData);
    }

    setOptions(option) {
        Gikam.extend(true, this.options, option);
    }

    refresh(param) {
        param && this.setOptions(param);
        this.options.url && this.getUrlData();
    }

    // 从url获取数据
    getUrlData() {
        Gikam.post(this.options.url, this.queryParams()).done(data => {
            this.setData(data.rows);
            this.options.page = {
                pageNumber: data.data,
                total: data.total
            };
            this.options.onLoadSuccess && this.options.onLoadSuccess(this.options.data);
        });
    }

    init() {
        this.initPanel();
        this.options.url && this.getUrlData();
    }
}
