import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Gikam from 'gikam';
import ButtonPageEditWrapper from './button-page-edit-wrapper.vue';
export default {
  name: 'Button',
  components: {
    ButtonPageEditWrapper: ButtonPageEditWrapper
  },
  props: {
    options: Object,
    scope: Object,
    index: Number,
    memory: Boolean,
    window: Object
  },
  data: function data() {
    var _Gikam$window;

    return {
      hidden: Gikam.isEmpty(this.options.hidden) ? false : this.options.hidden,
      text: Gikam.propI18N(this.options.text),
      markerNum: this.options.markerNum || 0,
      prompt: false,
      win: this.window,
      pageEditing: (_Gikam$window = Gikam.window) === null || _Gikam$window === void 0 ? void 0 : _Gikam$window.pageEditing,
      canPageEdit: true,
      ignoreIcon: ['pass', 'save', 'remove', 'submit', 'add', 'remove', 'export', 'remove-row', 'edit', 'default', 'add-row', 'move-up', 'move-down', 'view-audit', 'back', 'browse', 'reject', 'review', 'copy', 'enable', 'print', 'upload', 'remove', 'file-remove', 'select', 'activate', 'search']
    };
  },
  watch: {
    hidden: function hidden(val) {
      var _this = this;

      this.$nextTick(function () {
        _this.$emit('hiddenChangeEvent', val, _this);
      });
    },
    'options.text': function optionsText(text) {
      this.text = Gikam.propI18N(text);
    }
  },
  created: function created() {
    this.options.id = this.getCompId();
    this.win = this.win || Gikam.getCurrentWindow();

    if (this.win) {
      this.win.$addStoreComp('Button', this);

      if (this.options.icon === 'save' || this.options.isSaveButton) {
        this.win.model.$saveButton = this;
      }
    }

    this.mergePageEditOptions();
  },
  mounted: function mounted() {
    var _this$options$onRende;

    (_this$options$onRende = this.options.onRendered) === null || _this$options$onRende === void 0 ? void 0 : _this$options$onRende.call(this);

    if (this.options.id) {
      Gikam.compInstanceContainer[this.options.id] = this; // 有些地方使用的是异步获取button

      this.notify();
    }

    if (this.memory) {
      this.$emit('memoryWidth', this.index, this.$el.offsetWidth);
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.options.id) {
      delete Gikam.compInstanceContainer[this.options.id];
    }

    this.win.$removeStoreComp('Button', this);
    this.win = null;
  },
  methods: {
    show: function show() {
      this.hidden = false;
    },
    hide: function hide() {
      this.hidden = true;
    },
    setMarkerNum: function setMarkerNum(number) {
      if (Gikam.isNumber(number)) {
        this.markerNum = number;
        return true;
      } else {
        return false;
      }
    },
    clickHandle: function clickHandle(event) {
      Gikam.buttonText = this.text;

      if (this.options.onClick) {
        //如果是保存按钮，点击的时候记录，防止不点击保存按钮，只调用保存方法而出现提示的问题
        if (this.win && this.options.icon === 'save') {
          this.win.$isSaveButtonClick = true;
        }

        this.options.onClick(event);
      }

      this.$emit('click', event);
      setTimeout(function () {
        Gikam.buttonText = null;
      }, 300);
    },
    getCompId: function getCompId() {
      var _this$$parent, _this$$parent$options;

      if (this.options.id) {
        return this.options.id;
      }

      if ((_this$$parent = this.$parent) !== null && _this$$parent !== void 0 && (_this$$parent$options = _this$$parent.options) !== null && _this$$parent$options !== void 0 && _this$$parent$options.id) {
        var _this$$parent2, _this$$parent2$option;

        return ((_this$$parent2 = this.$parent) === null || _this$$parent2 === void 0 ? void 0 : (_this$$parent2$option = _this$$parent2.options) === null || _this$$parent2$option === void 0 ? void 0 : _this$$parent2$option.id) + '-' + this.options.text;
      }

      if (Gikam.window) {
        return Gikam.window.getMainPageUrl().replace('/', '-') + '-' + this.options.text;
      }
    },
    getPageEditorOptions: function getPageEditorOptions() {
      var options = Gikam.deepExtend(this.options);
      var config = {};
      var properties = ['text', 'visibleRoles', 'editRoles', 'icon'];
      properties.forEach(function (property) {
        return config[property] = options[property];
      });
      return config;
    },
    notify: function notify() {
      var pro = Gikam.createdComponent[this.options.id];

      if (pro) {
        pro.resolve(this);
        delete Gikam.createdComponent[this.options.id];
      }
    }
  }
};