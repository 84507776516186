import Base from './base.js';
import Gikam from '../core/gikam-core';
import Vue from 'vue';
import shuttleFrame from './template/shuttleFrame/shuttleFrame.vue';

// 穿梭框
let defaultOptions = {
    // 左右表头-当rightColumns不存在时
    columns: void 0,
    // 右表头
    rightColumns: void 0,
    // 左侧grid的列表数据
    data: [],
    // 右侧grid的列表数据
    rightData: [],
    // 向右添加的数据
    addList: [],
    // 向左删除的数据
    delList: [],
    // 默认打开快捷查询
    filterOpen: true,
    // 左grid url
    leftGridUrl: void 0,
    // 右grid url
    rightGridUrl: void 0,
    leftRequestData: void 0,
    rightRequestData: void 0,
    // 添加函数 =>
    onAddClick: void 0,
    // 删除函数 <=
    onDelClick: void 0,
    // 添加时的校验函数
    onBeforeAdd: () => true,
    // 删除时的校验函数
    onBeforeDel: () => true,
    // 加载数据完成
    onLeftLoadSuccess: void 0,
    onRightLoadSuccess: void 0,
    // 左侧grid去重字段，默认id
    delRepeatField: 'id',
    // 是否分页
    page: false,
    leftPage: true,
    rightPage: true,
    leftTitle: '',
    rightTitle: '',
    leftToolbar: [],
    rightToolbar: [],
    // 取消左侧总条数变化，防止分页后数据丢失
    cancelLeftTotal: false,
    rightServerSearch: true,
    leftServerSearch: true,
    // 双击移动数据
    dblclickMove: true
};

export default class ShuttleFrame extends Base {
    constructor(options) {
        super(options);
        this.initTurn = false;
        this.getListeners();
        this.initialize(options, defaultOptions).init();
    }

    getListeners() {
        this.listeners = {
            rendered: Gikam.emptyFunction
        };
    }

    createViewModel() {
        const _this = this;

        let shuttleModel = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            provide: {
                shuttleFrame: this
            },
            render() {
                return <shuttle-frame ref="shuttleFrame" options={this.options}></shuttle-frame>;
            },
            components: {
                shuttleFrame
            },
            data() {
                return {
                    options: _this.options
                };
            }
        });
        this.createLeftGrid(shuttleModel);
        this.createRightGrid(shuttleModel);

        this.setOriginData();
        this.bindInstance(shuttleModel.$el);
        this.trigger('rendered');
        this.options.rightOriginalData = Gikam.deepExtend(this.options.rightData);

        shuttleModel.$nextTick(() => this.notify());
    }

    setOriginData() {
        const _this = this;
        if (this.leftGrid && this.rightGrid && this.leftGrid.store && this.rightGrid.store) {
            this.updateLeftGridByData(this.options.data);
        } else {
            setTimeout(() => {
                _this.setOriginData();
            }, 200);
        }
    }

    // 创建左侧grid
    createLeftGrid(shuttleModel) {
        let _this = this;
        this.leftGrid = Gikam.create('grid', {
            renderTo: shuttleModel.$refs.shuttleFrame.$refs['left-grid'],
            id: 'left-grid',
            generalButtonGroup: ['refresh', 'export', 'generalQuery'],
            fill: true,
            url: _this.options.leftGridUrl,
            requestData: _this.options.leftRequestData,
            page: _this.options.leftPage ?? _this.options.page,
            filterOpen: _this.options.filterOpen,
            data: _this.options.data,
            columns: _this.options.columns,
            columnsFill: true,
            activeOnCheck: false,
            toolbar: _this.options.leftToolbar,
            serverSearch: this.options.leftServerSearch,
            onAllSelect() {
                _this.options.addList = _this.leftGrid.model.$children[0].getSelections();
            },
            onUnAllSelect() {
                _this.options.addList = _this.leftGrid.model.$children[0].getSelections();
            },
            onSelect() {
                _this.options.addList = _this.leftGrid.model.$children[0].getSelections();
            },
            onUnSelect() {
                _this.options.addList = _this.leftGrid.model.$children[0].getSelections();
            },
            onRowDblclick(data) {
                // 判断单双击，全选双击不处罚add or del事件
                if (typeof data === 'boolean' || !_this.options.dblclickMove) {
                    return;
                }
                _this.options.addList = [data];
                shuttleModel.$refs.shuttleFrame.add();
            },
            onBeforeLoad(data) {
                if (_this.leftTotalRecord) {
                    this.backData.total = _this.leftTotalRecord;
                }
                if (_this.leftTotalPage) {
                    this.options.totalPage = _this.leftTotalPage;
                }
                const key = _this.options.delRepeatField;
                if (_this.options.delList.length > 0) {
                    return data.filter(
                        item =>
                            !_this.options.rightData.some(
                                list => this.$getFieldValue(list, key) === this.$getFieldValue(item, key)
                            )
                    );
                } else if (Gikam.isNotEmpty(_this.options.rightOriginalData)) {
                    return data.filter(
                        item =>
                            !_this.options.rightOriginalData.some(
                                list => this.$getFieldValue(list, key) === this.$getFieldValue(item, key)
                            )
                    );
                }
            },
            onLoadSuccess(data, dataItem) {
                Gikam.finalDelay(
                    'leftLoadSuccess',
                    () => {
                        let allData = _this.leftGrid.getData();
                        _this.options.onLeftLoadSuccess && _this.options.onLeftLoadSuccess(allData);
                    },
                    100
                );
                _this.options.leftServerSearch = dataItem && Gikam.isFalse(dataItem.initPageData) ? false : true;
                if (!data) {
                    let gridFilter = _this.leftGrid.model.$refs.vm.$refs.header;
                    _this.leftOriginalDataHandle();
                    let allData = _this.options.leftOriginalData;
                    let filters = gridFilter.filterOriginalData;
                    let keys = Object.keys(filters);
                    if (keys.length > 0) {
                        let filtersData = allData.filter(item => {
                            return keys.every(key => {
                                if (key.indexOf('ext$') !== -1) {
                                    let type = key.split('.');
                                    item.ext$[type[1]] = item.ext$[type[1]] ? item.ext$[type[1]] : '';
                                    return item.ext$[type[1]].indexOf(filters[key]) !== -1;
                                } else {
                                    item[key] = item[key] ? item[key] + '' : '';
                                    return item[key].indexOf(filters[key]) !== -1;
                                }
                            });
                        });
                        _this.updateLeftGridByData(filtersData, true);
                    }
                    return;
                }

                if (!_this.options.chooseGrid) {
                    const key = _this.options.delRepeatField;
                    let n = _this.options.data.filter(
                        item => !data.some(obj => this.$getFieldValue(obj, key) === this.$getFieldValue(item, key))
                    );
                    if (_this.options.data.length !== data.length || n.length > 0) {
                        _this.checkLeftTotal(this.backData.total);
                        _this.updateLeftGridByData(data, true);
                    }
                }
                _this.options.chooseGrid = false;
            }
        });
    }

    $getFieldValue(row, key) {
        if (key.indexOf('ext$.') === 0) {
            return row.ext$[key.replace('ext$.', '')];
        } else {
            return row[key];
        }
    }

    checkLeftTotal(total) {
        this.leftGridTotal =
            total &&
            total > this.options.rightData.length &&
            Gikam.isEmpty(this.options.addList) &&
            Gikam.isEmpty(this.options.delList)
                ? total - this.options.rightData.length
                : this.leftGridTotal;
    }

    // 创建右侧grid
    createRightGrid(shuttleModel) {
        let _this = this;
        this.rightGrid = Gikam.create('grid', {
            renderTo: shuttleModel.$refs.shuttleFrame.$refs['right-grid'],
            id: 'right-grid',
            generalButtonGroup: ['refresh', 'export', 'generalQuery'],
            fill: true,
            url: _this.options.rightGridUrl,
            requestData: _this.options.rightRequestData,
            page: _this.options.rightPage ?? _this.options.page,
            filterOpen: _this.options.filterOpen,
            columns: _this.options.rightColumns ? _this.options.rightColumns : _this.options.columns,
            data: _this.options.rightData,
            columnsFill: true,
            activeOnCheck: false,
            toolbar: _this.options.rightToolbar,
            serverSearch: this.options.rightServerSearch,
            onAllSelect() {
                _this.options.delList = _this.rightGrid.model.$children[0].getSelections();
            },
            onUnAllSelect() {
                _this.options.delList = _this.rightGrid.model.$children[0].getSelections();
            },
            onSelect() {
                _this.options.delList = _this.rightGrid.model.$children[0].getSelections();
            },
            onUnSelect() {
                _this.options.delList = _this.rightGrid.model.$children[0].getSelections();
            },
            onRowDblclick(data) {
                if (typeof data === 'boolean' || !_this.options.dblclickMove) {
                    return;
                }
                _this.options.delList = [data];
                shuttleModel.$refs.shuttleFrame.del();
            },
            onBeforeLoad() {
                if (_this.rightTotalRecord) {
                    this.backData.total = _this.rightTotalRecord;
                }
                if (_this.rightTotalPage) {
                    this.options.totalPage = _this.rightTotalPage;
                }
            },
            onLoadSuccess(data, dataItem) {
                Gikam.finalDelay(
                    'rightLoadSuccess',
                    () => {
                        let allData = _this.rightGrid.getData();
                        _this.options.onRightLoadSuccess && _this.options.onRightLoadSuccess(allData);
                    },
                    100
                );
                _this.options.rightServerSearch = dataItem && Gikam.isFalse(dataItem.initPageData) ? false : true;
                if (!data) {
                    let gridFilter = _this.rightGrid.model.$refs.vm.$refs.header;
                    _this.rightOriginalDataHandle();
                    let allData = _this.options.rightOriginalData;
                    let filters = gridFilter.filterOriginalData;
                    let keys = Object.keys(filters);
                    if (keys.length > 0) {
                        let filtersData = allData.filter(item => {
                            return keys.every(key => {
                                if (key.indexOf('ext$') !== -1) {
                                    let type = key.split('.');
                                    item.ext$[type[1]] = item.ext$[type[1]] ? item.ext$[type[1]] : '';
                                    return item.ext$[type[1]].indexOf(filters[key]) !== -1;
                                } else {
                                    item[key] = item[key] ? item[key] : '';
                                    return item[key].indexOf(filters[key]) !== -1;
                                }
                            });
                        });
                        _this.updateRightGridByData(filtersData, true);
                    }
                    return;
                } else {
                    if (!_this.initTurn && _this.options.rightGridUrl && data.length) {
                        _this.initTurn = true;
                        _this.options.rightOriginalData = Gikam.deepExtend(data);
                    }
                }
            }
        });
    }

    // 左侧的快捷查询跟数据
    leftOriginalDataHandle() {
        const key = this.options.delRepeatField;
        this.options.leftOriginalData = this.options.leftOriginalData.filter(
            item =>
                !this.options.rightOriginalData.some(
                    obj => this.$getFieldValue(obj, key) === this.$getFieldValue(item, key)
                )
        );
        this.options.leftOriginalData = this.options.leftOriginalData.filter(
            item => !this.options.data.some(obj => this.$getFieldValue(obj, key) === this.$getFieldValue(item, key))
        );
        this.options.leftOriginalData = [...this.options.leftOriginalData, ...this.options.data];
    }

    // 右侧的快捷查询跟数据
    rightOriginalDataHandle() {
        const key = this.options.delRepeatField;
        this.options.rightOriginalData = this.options.rightOriginalData.filter(
            item => !this.options.data.some(obj => this.$getFieldValue(obj, key) === this.$getFieldValue(item, key))
        );
        this.options.rightOriginalData = this.options.rightOriginalData.filter(
            item =>
                !this.options.rightData.some(obj => this.$getFieldValue(obj, key) === this.$getFieldValue(item, key))
        );
        this.options.rightOriginalData = [...this.options.rightOriginalData, ...this.options.rightData];
    }

    // 设置左初始选项
    updateLeftGridByData(data, type) {
        if (!type) {
            this.options.leftOriginalData = data;
        }
        this.options.data = data;
        if (this.leftGrid && type) {
            this.initData();
        } else {
            this.refreshData();
        }
    }

    // 设置右初始选项
    updateRightGridByData(rightData, type) {
        if (!type) {
            this.options.rightOriginalData = Gikam.deepExtend(rightData);
        }
        this.options.rightData = rightData;
        this.rightGrid.loadData(this.options.rightData);
        if (this.rightGrid && type) {
            this.initData();
        } else {
            this.refreshData();
        }
    }

    // 初始化grid
    initData(type) {
        const key = this.options.delRepeatField;
        this.options.leftGridUrl && (this.options.data = this.leftGrid.options.data);
        if (this.options.rightGridUrl) {
            this.options.rightData = type ? this.rightGrid.data : this.rightGrid.options.data;
        }
        this.options.data = this.options.data.filter(
            item =>
                !this.options.rightData.some(obj => this.$getFieldValue(obj, key) === this.$getFieldValue(item, key))
        );
        this.refreshData();
    }

    refreshData() {
        this.refreshLeftData();

        if (Gikam.isFalse(this.options.rightPage) ? false : this.options.page) {
            this.rightTotalPage =
                Math.ceil(this.rightGrid.store.state.totalRecord / this.rightGrid.store.state.pageSize) || 1;
            if (this.options.rightServerSearch) {
                this.rightGrid.refreshByData({
                    rows: this.options.rightData
                });
            } else {
                this.rightGrid.refreshByData({
                    rows: this.options.rightData,
                    initPageData: false
                });
            }
        } else {
            if (this.options.rightServerSearch) {
                this.rightGrid.refreshByData({
                    rows: this.options.rightData
                });
            } else {
                this.rightGrid.refreshByData({
                    rows: this.options.rightData,
                    initPageData: false
                });
            }
        }
    }

    refreshLeftData() {
        if (Gikam.isFalse(this.options.leftPage) ? false : this.options.page) {
            this.leftTotalPage =
                Math.ceil(this.leftGrid.store.state.totalRecord / this.leftGrid.store.state.pageSize) || 1;
            this.leftGridTotal = this.leftGridTotal || this.options.data.length;
            if (this.options.leftServerSearch) {
                this.leftGrid.refreshByData({
                    total: this.leftGridTotal,
                    rows: this.options.data
                });
            } else {
                this.leftGrid.refreshByData({
                    total: this.leftGridTotal,
                    rows: this.options.data,
                    initPageData: false
                });
            }
        } else {
            if (this.options.leftServerSearch) {
                this.leftGrid.refreshByData({
                    rows: this.options.data
                });
            } else {
                this.leftGrid.refreshByData({
                    rows: this.options.data,
                    initPageData: false
                });
            }
        }
    }

    setLeftData(data, flag = true) {
        this.updateLeftGridByData(data);
        this.leftGrid.data = [...data];
        if (flag) {
            this.resetFilter();
        } else {
            setTimeout(() => {
                if (this.options.leftServerSearch) {
                    this.leftGrid.store.commit('refresh');
                } else {
                    this.leftGrid.pageSearchGrid();
                }
            });
        }
    }

    setRightData(data, flag = true) {
        this.updateRightGridByData(data);
        this.rightGrid.data = [...data];
        if (flag) {
            this.resetFilter();
        } else {
            setTimeout(() => {
                if (this.options.rightServerSearch) {
                    this.rightGrid.store.commit('refresh');
                } else {
                    this.rightGrid.pageSearchGrid();
                }
            });
        }
    }

    // 最终返回结果-右grid
    getSelectList() {
        return Gikam.deepExtend(this.options.rightOriginalData);
    }

    /**
     * 清空穿梭框的搜索
     * @public
     * @param { String } type: left/right
     * @memberof shuttleFrame
     */
    resetFilter(type) {
        if (type !== 'right') {
            this.leftGrid.model.$refs.vm.$refs.header.resetFilter();
        }
        if (type !== 'left') {
            setTimeout(() => {
                // 两个清空要等一个执行完毕，在进行下一个
                this.rightGrid.model.$refs.vm.$refs.header.resetFilter();
            }, 200);
        }
    }

    onResize() {
        this.leftGrid.onResize();
        this.rightGrid.onResize();
    }

    init() {
        this.createViewModel();
    }
}
