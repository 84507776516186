<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 15 15"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>单选</title>
        <g id="文本框标记单选/多选方案" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
                id="文本框标记单选多选方案"
                transform="translate(-451.000000, -196.000000)"
                :fill="color"
                fill-rule="nonzero"
            >
                <g id="单选" transform="translate(451.000000, 196.000000)">
                    <path
                        d="M6.407296,4.936536 L13.05696,8.439431 C13.749752,8.764059 13.674308,9.762945 12.943262,9.984621 L11.650676,10.375229 L13.211118,12.320181 C13.480179,12.690574 13.397032,13.205651 13.027554,13.475016 L11.422942,14.64439 C11.037178,14.926238 10.490945,14.829749 10.237287,14.446273 L8.845218,12.477307 L8.243982,13.321064 C7.912472,13.957747 6.893257,13.757062 6.727107,13.121996 L5.195927,5.771486 C5.119516,5.119109 5.796162,4.648324 6.407296,4.936536 Z M7.246867,0 C10.79632,0 13.807022,2.56875 14.392141,6.031298 C14.443294,6.334003 14.23937,6.620862 13.936664,6.672015 C13.633959,6.723168 13.3471,6.519244 13.295947,6.216538 C12.800861,3.286774 10.251607,1.111735 7.246867,1.111735 C3.858527,1.111735 1.111735,3.858527 1.111735,7.246867 C1.111735,9.722854 2.591866,11.926543 4.828093,12.886939 C5.110176,13.008086 5.240641,13.334968 5.119494,13.617051 C4.998348,13.899134 4.671466,14.029599 4.389383,13.908452 C1.748321,12.774191 0,10.171208 0,7.246867 C0,3.244533 3.244533,0 7.246867,0 Z M6.369267,6.133027 L7.655231,12.297573 L8.12933,11.641225 C8.321214,11.454512 8.566611,11.334644 8.8598,11.334644 C9.129491,11.334644 9.36458,11.460034 9.613854,11.714748 L10.980673,13.634201 L12.182781,12.758156 L10.646844,10.842301 C10.471962,10.585826 10.447354,10.297572 10.542765,10.01772 C10.624021,9.780144 10.773489,9.615719 10.994061,9.471491 L11.132987,9.406718 L12.054985,9.128098 L6.369267,6.133027 Z"
                        id="形状"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>

<style scoped>
svg {
    display: block;
}
</style>
