import _objectSpread from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
//
//
//
//
//
//
//
//
import dateEditor from '@/gikam/js/components/template/fields/date/dateInput';
import { mapState } from 'vuex';
export default {
  components: {
    dateEditor: dateEditor
  },
  props: {
    options: Object,
    propValue: [Number, String],
    dataId: [Number, String],
    validateArg: Object,
    formData: Object
  },
  data: function data() {
    var _this$options$readonl;

    return {
      readonly: (_this$options$readonl = this.options.readonly) !== null && _this$options$readonl !== void 0 ? _this$options$readonl : false
    };
  },
  computed: _objectSpread({}, mapState(['form'])),
  mounted: function mounted() {
    this.form.editorManager[this.options.field] = this.$refs.editor;
  },
  methods: {
    changeHandle: function changeHandle() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this.$emit.apply(this, ['change'].concat(args));
    }
  }
};