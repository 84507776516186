import Gikam from '../../../core/gikam-core.js';
import modal from '@/gikam/js/components/modal/vue/modal.vue';
import Vue from 'vue';
import Base from '../../base';
import { ModalUtils } from './modal-utils.js';

let defaultOptions = {
    id: void 0,
    title: void 0,
    // 设置modal-title样式
    titleStyle: {},
    width: '80%',
    height: '80%',
    src: void 0,
    showCloseBtn: true,
    showMaxBtn: true,
    showMinBtn: true,
    toolbarTop: null,
    animation: true,
    // 设置modal-body样式
    bodyStyle: {},
    // 用于自定义数据
    data: void 0,
    // 非模块对话框
    isModal: true,
    // 子元素
    items: [],
    // 尺寸变化事件
    onResize: null
};

const baseBindButtonEnter = function(buttonId, e) {
    if (e.keyCode === 13) {
        e.stopPropagation();
        e.preventDefault();
        document.getElementById(buttonId)?.click();
    }
};

export default class Modal extends Base {
    constructor(props) {
        super(props);
        this.activeGrid = null;
        this.ready = false;
        this.readyCallbackManager = [];
        this.listeners = {
            afterClose: Gikam.emptyFunction,
            beforeClose: Gikam.emptyFunction,
            resize: Gikam.emptyFunction,
            minBtnClick: Gikam.emptyFunction
        };
        this.readyDeferred = Gikam.getDeferred();
        this.initialize(props, defaultOptions).init(props);
    }

    createModel() {
        if (Gikam.activeGrid) {
            this.activeGrid = Gikam.activeGrid;
            Gikam.activeGrid = null;
        }
        const _this = this;
        this.options.modal = this;
        return new Vue({
            el: Gikam.createDom('div', document.body),
            components: { modal },
            data() {
                return { options: _this.options };
            },
            methods: {
                modalResizeHandle() {
                    _this.options.onResize?.call(_this);
                },
                destroyLockData() {
                    if (_this.window) {
                        _this.window.destroy();
                    }
                    _this.$destroy.bind(_this);
                }
            },
            render() {
                return (
                    <modal
                        options={this.options}
                        ref="vm"
                        onResize={_this.onResize.bind(_this)}
                        onReady={_this.readyHandle.bind(_this)}
                        onPageEditClick={_this.pageEditClickHandle.bind(_this)}
                        onClose={this.destroyLockData}
                        onModalResize={this.modalResizeHandle}
                    ></modal>
                );
            }
        });
    }

    createWindow() {
        if (this.options.src) {
            return;
        }
        let toolbarTop = false;
        if (this.options.toolbarTop) {
            toolbarTop = true;
        } else if (Gikam.isFalse(this.options.toolbarTop)) {
            toolbarTop = false;
        } else {
            toolbarTop = this.options.url ? true : false;
        }
        const { width } = this.model.$refs.vm.dragOptions;
        return Gikam.create('window', {
            $parent: this,
            renderTo: this.model.$el.querySelector('.modal-body'),
            toolbarTop: toolbarTop,
            headerShow: false,
            width: `${width}px`
        });
    }

    //开启遮罩
    async openMask() {
        await this.window.showMask();
    }

    //关闭遮罩
    closeMask() {
        this.window.closeMask();
    }

    //获取子组件Iframe元素对象
    getIframeDom() {
        return this.model.$children[0].getIframeDom();
    }

    load() {
        this.options.url && this.window.load(this.options.url);
    }

    close(data) {
        this.model.$children[0].close(data);
        Gikam.activeGrid = this.activeGrid;
    }

    /**
     * @description 初始化移动弹框尺寸,如果是手机端，宽度强制显示100%
     * @private
     * @memberof Modal
     */
    initMobileSize() {
        if (Gikam.isMobilePhone()) {
            this.options.width = '100%';
            this.options.height = '100%';
        }
    }

    /**
     * @description 给modal的header中间添加html
     * @Public
     * @memberof Modal
     */
    setHeaderCenter(htmlString) {
        this.model.$refs.vm.setHeaderCenterDom(htmlString);
    }

    /**
     * @description modal窗口大小改变
     * @private
     * @param
     * @memberof Tab
     */
    onResize() {
        this.window && this.window.onResize();
    }

    /**
     * @description 获取modal的自定义数据
     * @Public
     * @memberof Modal
     */
    getData() {
        return this.options.data;
    }

    /**
     * @description 设置modal的自定义数据
     * @Public
     * @memberof Modal
     */
    setData(data) {
        this.options.data = data;
    }

    /**
     * @description 设置modal的自定义数据
     * @public
     * @memberof Modal
     */
    onBeforeClose(callback) {
        const beforeClose = this.listeners.beforeClose;
        this.listeners.beforeClose = data => {
            let result = true;
            if (beforeClose && beforeClose.call(this, data) === false) {
                result = false;
            }
            if (Gikam.isFunction(callback) && callback.call(this, data) === false) {
                result = false;
            }
            return result;
        };
    }

    /**
     * @description 设置model内按钮的点击事件
     * @public
     * @memberof Modal
     */
    bindButtonEnter(buttonId) {
        if (this.addButtonEnterHandler) {
            return;
        }
        this.addButtonEnterHandler = baseBindButtonEnter.bind(this, buttonId);
        this.onBeforeClose(this.unBindButtonEnter.bind(this));
        document.addEventListener('keydown', this.addButtonEnterHandler);
        return this;
    }

    /**
     * @description 取消已经绑定的事件
     * @public
     * @memberof Modal
     */
    unBindButtonEnter() {
        if (this.addButtonEnterHandler) {
            document.removeEventListener('keydown', this.addButtonEnterHandler);
            this.addButtonEnterHandler = null;
        }
    }

    init() {
        this.initMobileSize();
        this.model = this.createModel();
        this.window = this.createWindow();
        this.window && (this.window.$parent = this);
        this.bindInstance(this.model.$el);
        this.load();
        ModalUtils.initItems(this);
    }

    /**
     * @description 动态设置弹框位置及大小
     * @param {*} width
     * @param {*} height
     * @param {*} left
     * @param {*} top
     * @memberof Modal
     */
    setSize(width, height, left, top) {
        this.model.$children[0].setSize(width, height, left, top);
    }

    /**
     * @public
     * @description
     * @memberof Modal
     */
    onReady() {
        return this.readyDeferred;
    }

    /**
     * @private
     * @description
     * @memberof Modal
     */
    readyHandle() {
        this.readyDeferred.resolve();
    }

    /**
     * @description 动态设置弹框标题
     * @param {*} title
     * @memberof Modal
     */
    setTitle(title) {
        this.model.$children[0].setTitle(title);
    }

    /**
     * @private
     * @description
     * @memberof Modal
     */
    pageEditClickHandle() {
        this.window.openEditPage();
    }
}
