<template>
    <ConfigProvider>
        <div @click="clickHandle">
           <div v-if="isReadonly" class="readonly-text" :style="textStyleFormatter" >{{ readonlyValue }}</div>
           <template v-else>
              <div
              :is="'A' +  dateType"
              :size="size"
              v-model="value"
              @change="changeHandle"
              @ok="okHandle"
              :disabled="disabled"
              :show-time="showTime"
              />
          </template>
          </div>      
    </ConfigProvider>
</template>

<script>
import { DatePicker } from 'ant-design-vue';
import Vue from 'vue';
import ConfigProvider from '../config-provider/config-provider';
import Gikam from 'gikam';

Vue.use(DatePicker);

export default {
    components: {
        ConfigProvider
    },
    props: {
        propValue: Array,
        size: {
            default: 'small',
            type: String
        },
        disabled: {
            default: false,
            type: Boolean
        },
        showTime: Boolean,
        options:Object,
        propInvisible: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            value: null,
            oldValue: null,
            textStyleFormatter: this.options.textStyleFormatter ? this.options.textStyleFormatter() : '',
            invisible: this.propInvisible,
            readonly: this.options.readonly
        };
    },

    computed:{
        dateType() {
           return this.options.type === "dateRange" ? "RangePicker" : this.options.type         
        },

        readonlyValue(){
           return this.options.value?.split(',').join('~') 
        },

        isReadonly(){
          return this.options.type === "dateRange" && this.readonly 
        }
    },

    watch: {
        propValue:{
            handler(value){               
               this.value = Gikam.isNotEmpty(value) ? value : null;
            },
            immediate:true
        }
    },

    model: {
        event: 'change',
        prop: 'propValue'
    },

    methods: {
        changeHandle() {
            this.$emit('change', this.value, this.oldValue);
        },

        okHandle(dates) {
            this.oldValue = dates
            this.$emit('ok', this.value);
        },

        clickHandle() {
            this.$emit('click', this);
        },

    }
};
</script>

<style lang="scss" scoped>
.ant-calendar-picker{
    width: 100% !important;
}
.ant-calendar-picker-input {
    padding: 0;
    input {
        font-size: 12px;
    }
}
</style>
