<template>
    <div class="tree-grid-node">
        <div class="tree-grid-node-blank" :style="nodeBlankStyle"></div>

        <span v-if="hasChildren" class="switch" @click.stop="toggleClickHandle">
            <i :is="switchIcon" />
        </span>
        <span v-else class="noswitch"></span>

        <div
            v-if="showCheckbox"
            :is="checkboxType"
            @change="checkedChangeHandle"
            :propChecked="checked"
            :propHalfChecked="halfChecked"
        ></div>

        <span class="node-text" v-if="options.htmlRender" v-html="text"> </span>
        <span class="node-text" v-else>{{ text }} </span>
    </div>
</template>

<script>
import Gikam from 'gikam';
import { GridUtils } from '../../../js/grid-utils';
import { mapState } from 'vuex';

export default {
    props: {
        options: Object,
        row: Object,
        rowIndex: Number
    },

    computed: {
        ...mapState(['grid']),

        nodeBlankStyle() {
            const level = this.row.lvl ?? 1;

            return {
                width: `${10 * (level - 1)}px`
            };
        },

        hasChildren() {
            return Gikam.isNotEmpty(this.row.children);
        },

        switchIcon() {
            return GridUtils.getRowVueState(this.row, 'expand') ? 'icon-minus-square-o' : 'icon-plus-square-o';
        },

        text() {
            const { formatter, field } = this.options;
            if (formatter) {
                return formatter.call(this.grid, this.row);
            } else {
                return Gikam.getFieldValue(this.row, field);
            }
        },

        checked() {
            return GridUtils.getRowVueState(this.row, 'treeNodeChecked');
        },

        halfChecked() {
            return GridUtils.getRowVueState(this.row, 'treeNodeHalfChecked');
        },

        isSingleCheck() {
            return this.options.single || this.grid.options.single;
        },

        checkboxType() {
            return this.isSingleCheck ? 'radio' : 'checkbox';
        },

        showCheckbox() {
            return this.grid.options.checkbox;
        }
    },

    methods: {
        toggleClickHandle() {
            GridUtils.toggleExpandTreeNode(this.row);
        },

        checkedChangeHandle(checked) {
            const param = {
                isSingleCheck: this.isSingleCheck
            };
            GridUtils.toggleNodeChecked(this.row, checked, this.grid, param);
        }
    }
};
</script>

<style scoped lang="scss">
.tree-grid-node {
    display: flex;
    align-items: center;
    width: 100%;

    > .node-text {
        padding-left: 5px;
        display: inline;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 1;
    }

    > .noswitch {
        display: inline-block;
        width: 12px;
    }

    > .switch {
        cursor: pointer;
        color: #000;
        font-size: 12px;
        padding-top: 2px;
    }

    > .noswitch,
    > .switch {
        margin-right: 6px;
    }
}
</style>
