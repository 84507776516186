<template>
    <a-dropdown v-bind="options" :visible="visible">
        <slot></slot>
        <slot name="overlay" slot="overlay"></slot>
    </a-dropdown>
</template>

<script>
import Vue from 'vue';
import { Dropdown } from 'ant-design-vue';

Vue.use(Dropdown);

export default {
    props: {
        options: Object
    },

    data() {
        return {
            target: null,
            visible: false,
            innerTriggerClose: false
        };
    },

    mounted() {
        this.target = this.$slots.default[0].context.$el;
        this.initEvent();
    },

    methods: {
        close() {
            this.$emit('beforeClose');
            this.visible = false;
            document.removeEventListener('mousedown', this.mousedownHandle);
            this.innerTriggerClose = false;
            this.$emit('visibleChange', false);
        },

        initEvent() {
            this.target.addEventListener('click', () => {
                if (this.visible === true) {
                    this.close();
                } else {
                    this.visible = true;
                    document.addEventListener('mousedown', this.mousedownHandle);
                    document.addEventListener('dropdownDestroy', () => {
                        this.close();
                    });
                    this.$emit('visibleChange', true);
                }
            });
            this.target.addEventListener('mousedown', () => {
                if (this.visible === true) {
                    this.innerTriggerClose = true;
                }
            });
        },

        mousedownHandle() {
            if (this.innerTriggerClose === true) {
                return;
            }
            this.close();
        }
    }
};
</script>
