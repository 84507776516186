<template>
    <div class="blow-up-img">
        <div class="modify-btn-container" v-if="showModify">
            <div :title="$i18n('imgGrid.change')" class="upload-change-img" @click="modifyHandle">
                <img src="../img/modify.svg" width="16px" height="16px" />
                <span>{{ $i18n('imgGrid.change') }}</span>
            </div>
        </div>
        <div class="img-container">
            <scroll>
                <img
                    v-show="showImgId !== imgNode.id"
                    class="default-img"
                    src="../img/defaultImg.svg"
                    :key="imgNode.id + 'default'"
                />
                <img
                    v-show="showImgId === imgNode.id"
                    class="img"
                    :src="getDownloadUrl(imgNode.downloadUrl)"
                    :key="imgNode.id"
                    @load="imgLoad"
                    :style="{ transform: 'rotate(' + rotate + 'deg)', zoom: zoom / 100 }"
                />
            </scroll>
        </div>
        <div class="rotate-img">
            <shrink-img
                class="btn switch-left"
                v-bind="{ color: '#333', width: '16px', height: '16px' }"
                @click.native="switchImg(-1)"
            />
            <shrink-img
                class="btn switch-right"
                v-bind="{ color: '#333', width: '16px', height: '16px' }"
                @click.native="switchImg(1)"
            />
            <div class="blowUp" @click="sizeImg(1)"></div>
            <span class="ratio">{{ zoom }}%</span>
            <div class="btn narrow" @click="sizeImg(-1)"></div>
            <clearImg
                class="btn rotate-left"
                v-bind="{ color: '#333', width: '14px', height: '14px' }"
                @click.native="rotateImg(-1)"
            />
            <clearImg
                class="rotate-right"
                v-bind="{ color: '#333', width: '14px', height: '14px' }"
                @click.native="rotateImg(1)"
            />
        </div>
    </div>
</template>
<script>
import Gikam from '../../../core/gikam-core';

export default {
    props: {
        propImgNode: Object,
        propImgIndex: Number,
        showModify: Boolean
    },

    data() {
        return {
            showImgId: null,
            imgNode: this.propImgNode,
            imgIndex: this.propImgIndex,
            rotate: 0,
            zoom: 100
        };
    },

    watch: {
        propImgIndex() {
            this.imgIndex = this.propImgIndex;
            this.imgNode = this.propImgNode;
            this.rotate = 0;
            this.zoom = 100;
        },

        propImgNode: {
            handler(node) {
                this.imgNode = node;
            },
            deep: true
        }
    },

    methods: {
        // 图片路径
        getDownloadUrl(downloadUrl) {
            return Gikam.IFM_CONTEXT + downloadUrl;
        },

        imgLoad() {
            this.showImgId = this.imgNode.id;
        },

        // 切换
        switchImg(type) {
            this.$emit('switchImg', this.imgIndex + type);
        },

        // 缩放
        sizeImg(size) {
            this.zoom = this.zoom + size * 10;
            if (this.zoom > 150) {
                this.zoom = 150;
            }
            if (this.zoom < 50) {
                this.zoom = 50;
            }
        },

        // 旋转
        rotateImg(rotate) {
            this.rotate += rotate * 90;
        },

        // 替换按钮
        modifyHandle() {
            this.$emit('modifyClick', this.imgNode);
        }
    }
};
</script>
<style scoped>
.blow-up-img {
    width: 100%;
    height: 100%;
    padding: 32px 8px;
    background-color: #fafafa;
    position: relative;
}

.blow-up-img .modify-btn-container {
    width: 100%;
    height: 32px;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
}

.blow-up-img .modify-btn-container .modify-btn {
    width: 60px;
    height: 24px;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    padding: 5px 6px;
    position: absolute;
    right: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.blow-up-img .img-container {
    width: 100%;
    height: 100%;
    border: 1px solid #eee;
    text-align: center;
    background-color: #fff;
}

.blow-up-img .default-img {
    width: 200px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: 16px;
}

.blow-up-img .rotate-img {
    width: 100%;
    height: 32px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blow-up-img .rotate-img >>> svg {
    cursor: pointer;
}

.blow-up-img .rotate-img .btn {
    margin-right: 24px;
}

.blow-up-img .rotate-img .blowUp,
.blow-up-img .rotate-img .narrow {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.blow-up-img .rotate-img .blowUp {
    background: url(../img/blowUp.svg) no-repeat center;
    background-size: 100%;
}

.blow-up-img .rotate-img .narrow {
    background: url(../img/narrow.svg) no-repeat center;
    background-size: 100%;
}

.blow-up-img .rotate-img .ratio {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    margin: 0 6px;
    user-select: none;
}

.blow-up-img .rotate-img .switch-left {
    transform: rotate(90deg);
}

.blow-up-img .rotate-img .switch-right {
    transform: rotate(-90deg);
}

.blow-up-img .rotate-img .rotate-right {
    transform: rotateY(180deg);
}
</style>
