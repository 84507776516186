<template>
    <SunwayRangePicker
        @change="changeHandle"
        v-model="value"
        :disabled="disabled"
        :dataId="dataId"
        :validateArg="validateArg"
        size="default"
        :options="options"
        :showTime="options.showTime"
    />
</template>
<script>
import SunwayRangePicker from '@/sunway/date-picker/date-picker';
import DateUtils from '@/gikam/js/utils/dateUtils';
import Gikam from 'gikam';

export default {
    components: {
        SunwayRangePicker
    },

    props: {
        options: Object,
        propValue: String,
        propReadonly: Boolean,
        validateArg: Object,
        dataId: [Number, String]
    },

    data() {
        return {
            value: null
        };
    },

    computed: {
        disabled() {
            return this.propReadonly ?? this.options.readonly ?? false;
        }
    },

    watch: {
        propValue: {
            immediate: true,
            handler(value) {
                if (!value) {
                    return;
                }
                const range = value.split(',');
                if (this.value) {
                    const from = this.options.showTime ? DateUtils.momentToStringTime(this.value[0]) : DateUtils.momentToString(this.value[0]);
                    const to = this.options.showTime ? DateUtils.momentToStringTime(this.value[1]) : DateUtils.momentToString(this.value[1]);
                    // 赋值与当前值相同，则不进行赋值
                    if (from === range[0] && to === range[1]) {
                        return;
                    }
                }
                 this.value =this.options.showTime ? 
                    [DateUtils.stringTimeToMoment(range[0]), DateUtils.stringTimeToMoment(range[1])] 
                    :[DateUtils.stringToMoment(range[0]), DateUtils.stringToMoment(range[1])];
            }
        }
    },

    methods: {
          changeHandle(value, oldValue) {  
            const fromValue = Gikam.isEmpty(value) ? '': this.options.showTime ? DateUtils.momentToStringTime(value[0]) : DateUtils.momentToString(value[0]);
            const toValue = Gikam.isEmpty(value) ? '': this.options.showTime ? DateUtils.momentToStringTime(value[1]) : DateUtils.momentToString(value[1]);
            const oldFromValue = Gikam.isEmpty(oldValue) ? '': this.options.showTime ? DateUtils.momentToStringTime(oldValue[0]) : DateUtils.momentToString(oldValue[0]);
            const oldToValue = Gikam.isEmpty(oldValue) ? '': this.options.showTime ? DateUtils.momentToStringTime(oldValue[1]) : DateUtils.momentToString(oldValue[1]);
            this.$emit('change', this.options.field, `${fromValue},${toValue}`, {oldValue: `${oldFromValue},${oldToValue}`});            
        }    
    }
};
</script>

<style lang="scss" scoped>
::v-deep {
    .ant-calendar-picker-input.ant-input {
        height: 30px;
    }
}
</style>
