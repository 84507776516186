import "ant-design-vue/lib/config-provider/style/css";
import _ConfigProvider from "ant-design-vue/lib/config-provider";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { I18N } from '@/gikam/i18n/I18N.js';
import Vue from 'vue';
Vue.use(_ConfigProvider);
export default {
  data: function data() {
    return {
      locale: {}
    };
  },
  created: function created() {
    var _this = this;

    var language = I18N.getLocale() || 'zh_CN';

    if (language.includes('-') || language.includes('_')) {
      var split = language.replace('-', '_').split('_');
      language = split[0] + '_' + split[1].toUpperCase();
    }

    import("ant-design-vue/es/locale/".concat(language)).then(function (module) {
      _this.locale = module.default;
    });
  }
};