import _objectSpread from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import _Ckeditor from "sunway-ui-vue/src/ckeditor";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import Ckeditor from '@/gikam/js/components/ckeditor/ckeditor'; // todo 左侧列表，右侧表单，偶尔会有表单中富文本不自动保存的问题，后期再进行替换，先用formCkeditor

export default {
  components: {
    SunwayCkeditor: _Ckeditor
  },
  props: {
    options: Object,
    propValue: String,
    validateArg: Object,
    dataId: [Number, String],
    propReadonly: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return _objectSpread(_objectSpread({}, Ckeditor.getOptions(this.options)), {}, {
      readonly: this.propReadonly
    });
  },
  computed: _objectSpread({}, mapState(['form'])),
  watch: {
    propReadonly: {
      immediate: true,
      handler: function handler(value) {
        this.readonly = value;
      }
    }
  },
  mounted: function mounted() {
    this.form.editorManager[this.options.field] = this;
  },
  methods: {
    changeHandle: function changeHandle(value) {
      this.$emit('change', this.options.field, value);
    }
  }
};