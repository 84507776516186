<template>
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 10 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <!-- Generator: Sketch 64 (93537) - https://sketch.com -->
        <desc>Created with Sketch.</desc>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-1219.000000, -3464.000000)" fill="currentColor" fill-rule="nonzero">
                <g transform="translate(1219.000000, 3464.000000)">
                    <path
                        d="M4.9875,0 L0.009722225,6.31666666 L2.14305556,6.31666666 L2.14305556,9.61072449 L7.83194445,9.61072449 L7.83194445,6.31666666 L9.96527779,6.31666666 L4.9875,0 Z M2.14305555,11.2944445 L7.83194444,11.2944445 L7.83194444,10.3980613 L2.14305555,10.3980613 L2.14305555,11.2944445 Z"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
