<template>
    <tooltip :options="tooltipProps" @beforeDisplay="beforeDisplay">
        <div
            :is="editorType"
            :options="deepOptions"
            :rowIndex="rowDataIndex"
            :cellIndex="cellIndex"
            :propValue="value"
            :row="row"
            :propInvisible="propInvisible"
            :tooltipOptions="tooltipOptions"
            @change="changeHandle"
            @cellClick="cellClickHandle"
        ></div>
    </tooltip>
</template>

<script>
import Gikam from 'gikam';
import { mapState } from 'vuex';
import Vue from 'vue';
import Tooltip from '@/gikam/js/components/tooltip/Tooltip.vue';
import { GridUtils } from '../../js/grid-utils';

const files = require.context('./editor', true, /\.vue$/, 'sync');
files.keys().forEach(key => {
    const name = key.slice(key.lastIndexOf('/') + 1).replace('.vue', '');
    Vue.component(`${name}`, files(key).default);
});

export default {
    components: {
        Tooltip
    },

    props: {
        options: Object,
        row: Object,
        rowIndex: Number,
        cellIndex: Number,
        tooltipOptions: Object,
        propInvisible: Boolean
    },

    data() {
        return {
            readonly: false
        };
    },

    computed: {
        ...mapState(['grid', '$window']),

        value() {
            return Gikam.getFieldValue(this.row, this.options.field);
        },

        deepOptions() {
            const deepOptions = Gikam.deepExtend(this.options, {
                value: this.value,
                readonly: Gikam.isTrue(this.options.editor)
                    ? this.readonly || this.options.readonly
                    : !this.options.editor
            });
            const beforeEditorRenderOptions = this.options.onBeforeEditorRender?.call(this.grid, this.row);
            if (beforeEditorRenderOptions === false) {
                deepOptions.readonly = true;
            } else {
                Gikam.extend(deepOptions, beforeEditorRenderOptions);
            }
            return deepOptions;
        },

        editorType() {
            const editor = Vue.component(`grid${Gikam.toInitialUpperCase(this.deepOptions.type || 'text')}`);
            if (editor) {
                return `grid-${this.deepOptions.type || 'text'}`;
            }
            // 如果编辑器没有找到，使用全局字段编辑器
            return `${this.deepOptions.type || 'text'}Field`;
        },

        tooltipProps() {
            const options = Gikam.deepExtend(this.tooltipOptions);
            options.title = this.value;
            return options;
        },

        rowDataIndex() {
            return GridUtils.getRowVueState(this.row, 'rowIndex');
        }
    },

    mounted() {
        // this.registerCellEditor();
    },

    methods: {
        changeHandle(...args) {
            const [field, value] = args;
            Gikam.setFieldValue(this.row, field, value);
            this.options.onChange?.apply(this.grid, [...args]);
            if (this.grid.canAutoSave) {
                const data = { id: this.row.id, row: this.row, ext$: {} };
                Gikam.setFieldValue(data, field, value);
                this.grid.autoSave(data);
            }
        },

        beforeDisplay() {
            this.tooltipProps.visible = this.$el.scrollWidth > this.$el.offsetWidth;
        },

        cellClickHandle() {
            const rowIndex = GridUtils.getRowVueState(this.row, 'rowIndex');
            // 临时，后期把所有编辑器提取之后，去掉
            if (this.$window) {
                this.$window.model.storage = {
                    grid: this.grid,
                    index: rowIndex
                };
            }
            setTimeout(() => this.grid.trigger('cellClick', this.options.field, Gikam.deepExtend(this.row), rowIndex));
        }

        // registerCellEditor() {
        //     const rowIndex = GridUtils.getRowVueState(this.row, 'rowIndex');
        //     const manager = this.grid.editorManager;
        //     if (!manager[rowIndex]) {
        //         manager[rowIndex] = {};
        //     }
        //     manager[rowIndex][this.options.field] = this.$children.$children;
        // }
    }
};
</script>

<style></style>
