import Gikam from '@/gikam/js/core/gikam-core';
import { I18N } from '@/gikam/i18n/I18N.js';
import progress from '../vue/progress/progress';

/** options参数
 *  arg 依赖的外部参数，直接调用可以传{}(导出文件名，导出的url, 是否弹窗导出)
 *  url: 导出的url ,
 *  grigFileName: 导出文件名，
 *  dataIds:选中数据id
 *  params: 参数,
 *  store: this.store
 */

export default class GridExport {
    constructor(options) {
        this.def = Gikam.jQuery.Deferred();
        Object.assign(this, options);
        this.initParams();
        this.initHeader();
        this.export();
        this.exportAllNumber = '1';
    }

    initParams() {
        delete this.params.s;
        this.params.dt = Gikam.propI18N(this.grigFileName || this.arg[0]) || I18N.prop('grid.exportResults');
    }

    initHeader() {
        this.header = {
            [this.store.state.exportExcelHeader]: '1',
            'GIKAM-REQUEST-MODE': 'sync'
        };
    }

    export() {
        if (this.arg[2]) {
            //弹窗导出
            this.initChooseModel().done((filterColumns, state) => {
                this.exportMode = state;
                this.header['GIKAM-REQUEST-MODE'] = state;
                this.exportAllNumber !== '0' && (this.params.n = -1);
                this.exportAllNumber === '2' && (this.params.f.id_in = this.dataIds);
                this.columns = this.getMapColumns(filterColumns);
                this.download();
            });
        } else {
            //直接导出
            this.exportMode = 'sync';
            const _columns = this.asyncGetColumns(this.store.state.columns);
            this.columns = this.getMapColumns(_columns);
            this.download();
        }
    }

    //下载
    download() {
        if (this.exportMode === 'sync') {
            this.progress = new progress({
                id: 'excel-progress',
                download: this.syncDownload.bind(this)
            });
            this.syncDownload();
        } else if (this.exportMode === 'async') {
            this.asyncDownload();
        }
    }

    syncDownload() {
        this.getUrl()
            .done((downloadUrl, status, xhr) => {
                this.progress.stopProgress();
                this.progress.changePercentageNum(100);
                setTimeout(() => {
                    this.progress.closeProgress();
                    const url = xhr.getResponseHeader('GIKAM-EXCEL-URL') || downloadUrl;
                    url && Gikam.download(Gikam.getContextUrl(decodeURIComponent(url)));
                }, 300);
            })
            .fail(() => {
                this.progress.stopProgress();
                this.progress.changeProgress('wrong');
            });
    }

    asyncDownload() {
        this.getUrl();
    }

    getUrl() {
        return Gikam.postText(this.url, Gikam.getJsonWrapper(this.params, ['', this.columns]), this.header);
    }

    //初始化页面
    getToolbar() {
        return [
            {
                type: 'button',
                text: I18N.prop('grid.cancel'),
                onClick: () => {
                    this.modal.close();
                    this.def.reject();
                }
            },
            {
                type: 'button',
                text: '异步导出',
                onClick: () => {
                    const filterColumns = this.grid.getSelections();
                    if (filterColumns.length <= 0) {
                        return;
                    }
                    this.modal.close();
                    this.def.resolve(filterColumns, 'async');
                }
            },
            {
                type: 'button',
                text: I18N.prop('grid.synchronousExport'),
                class: 'blue',
                onClick: () => {
                    const filterColumns = this.grid.getSelections();
                    if (filterColumns.length <= 0) {
                        return;
                    }
                    this.modal.close();
                    this.def.resolve(filterColumns, 'sync');
                }
            }
        ];
    }

    initChooseModel() {
        const { modal, layout } = Gikam.createSimpleModal({
            title: I18N.prop('grid.selectExportColumn'),
            width: 360,
            height: 500,
            toolbarFormatter: () => {
                return this.getToolbar();
            }
        });
        this.modal = modal;
        this.layout = layout;
        layout.model.$nextTick(() => {
            this.initGrid();
        });
        return this.def;
    }

    async initGrid() {
        const { grid, columns } = this.store.state;
        const visibleColumns = columns.filter(item => Gikam.isNotEmpty(item.title));
        const data = [...visibleColumns, ...grid.options.hiddenFields, ...(await this.getFormColumns())];
        const _this = this;
        this.grid = Gikam.create('grid', {
            type: 'grid',
            renderTo: this.layout.options.center.$dom,
            page: false,
            genericQuery: false,
            filter: false,
            toolbarAlign: 'left',
            generalButtonGroup: false,
            checkOneOnActive: false,
            toolbar: [{ type: 'form', titleWidthAuto: true, fields: this.getToolbarFormFields() }],
            data,
            columns: this.getModelGridColumns(),
            onLoadSuccess() {
                visibleColumns.forEach((item, index) => _this.grid.selectRowByIndex(index));
            }
        });
    }

    getToolbarFormFields() {
        return [
            {
                title: I18N.prop('grid.exportContent'),
                type: 'select',
                field: 'exportAll',
                value: '1',
                refreshGrid: false,
                // readonly: this.params.n !== -1 ? false : true,
                items: [
                    { text: I18N.prop('grid.exportThisPage'), value: '0' },
                    { text: I18N.prop('grid.exportAll'), value: '1' },
                    { text: I18N.prop('grid.export.select'), value: '2' }
                ],
                onChange: (field, value) => {
                    this.exportAllNumber = value;
                }
            }
        ];
    }

    getModelGridColumns() {
        return [
            { checkbox: true },
            { index: true },
            {
                title: '列名称',
                field: 'title',
                width: 240,
                formatter(rowIndex, cellValue) {
                    return Gikam.propI18N(cellValue);
                }
            }
        ];
    }

    //处理列时的工具
    asyncGetColumns(filterColumns) {
        return this.store.state.columns.filter(function(item) {
            return Gikam.isNotEmpty(item.field) && filterColumns.some(filterItem => item.field === filterItem.field);
        });
    }

    getMapColumns(columns) {
        return columns.map(item => {
            const filedDefine = {
                id: item.field,
                name: Gikam.propI18N(item.title),
                alignment: this.gridOptions.contentAlign,
                type: item.fieldType ? item.fieldType : this.getFieldType(item.field)
            };

            if (item.type === 'select') {
                let optionsMapper = {};
                item.items.forEach(item => {
                    optionsMapper[item.value] = item.text;
                });
                filedDefine.options = optionsMapper;
            }

            if (item.styleFormatter && this.store.state.exportWithStyle) {
                filedDefine.styleFormatter = item.styleFormatter + '';
            }

            return filedDefine;
        });
    }

    getFieldType(field) {
        const mapper = this.store.state.fieldTypeMapper;
        const type = mapper ? mapper[field.toLowerCase().replace('ext$.', '')] : 'string';
        return type;
    }

    getFormColumns() {
        return new Promise(resolve => {
            const { grid, columns } = this.store.state;
            const dbTable = grid.options.dbTable;
            if (!dbTable) {
                resolve([]);
                return;
            }
            const url = `${Gikam.IFM_CONTEXT}/secure/core/module/design/studio-table-fields?tableName=${dbTable}`;
            Gikam.getJson(url).done(result => {
                const items = result
                    .filter(item => {
                        return !columns.some(column => column.field === item.field);
                    })
                    .map(item => {
                        const { title, field } = JSON.parse(item.config);
                        return { title, field };
                    });
                resolve(items);
            });
        });
    }
}
