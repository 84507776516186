<template>
    <div class="mask">
        <img v-if="mode === 'normal'" src="./img/page-loading.png" />
        <a-spin v-else-if="mode === 'retro'" :tip="$i18n('mask.dataLoading')" size="large"> </a-spin>
        <img v-else-if="mode === 'progress'" src="./img/page-loading.png" />
    </div>
</template>

<script>
import { Spin } from 'ant-design-vue';
import Vue from 'vue';

Vue.use(Spin);

export default {
    props: {
        mode: {
            type: String,
            default: 'normal' // normal | 'retro'
        }
    },

    mounted() {
        this.$nextTick(() => this.$emit('ready'));
    }
};
</script>

<style lang="scss" scoped>
.mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    z-index: 30;

    > .message {
        padding-left: 10px;
    }

    ::v-deep {
        .ant-spin-dot-spin {
            animation-duration: 2.4s;
        }
    }
}
</style>
