<template>
    <modal :options="options.modalOption" ref="modal">
        <div slot="modal-body" class="progress-bar">
            <div class="loading" v-if="page === 'loading'">
                <div class="title">{{ progressText }}</div>
                <div class="progress">
                    <div class="bar" :style="{ width: percentage }"></div>
                </div>
                <div class="percentage">{{ percentage }}</div>
            </div>
            <div class="result-right result" v-else-if="page === 'right'">
                <div class="title">
                    <div class="confirm">
                        <div class="svg">
                            <tick color="#ffffff" />
                        </div>
                    </div>
                    <div class="text">{{ rightText }}</div>
                </div>
                <div class="bottom-button">
                    <gbutton :options="{ text: '确定', padding: '0 16px', class: 'blue' }" @click="confirm"></gbutton>
                </div>
            </div>
            <div class="result-wrong result" v-else-if="page === 'wrong'">
                <div class="title">
                    <div class="confirm">
                        <div class="svg">
                            <fork color="#ffffff" />
                        </div>
                    </div>
                    <div class="text">{{ wrongText }}</div>
                </div>
                <div class="bottom-button">
                    <gbutton
                        :options="{ text: '取消', padding: '0 16px', margin: '0 10px 0 0' }"
                        @click="cancel"
                    ></gbutton>
                    <gbutton :options="{ text: '重试', padding: '0 16px', class: 'blue' }" @click="tryAgain"></gbutton>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import modal from '@/gikam/js/components/modal/vue/modal.vue';

export default {
    name: 'progressBar',

    props: {
        options: Object
    },

    inject: ['progress', 'oldGrid'],

    computed: {
        progressText: function() {
            return this.options.title || '导出中,请稍后...';
        },
        rightText: function() {
            return this.options.rightText || '导出完成';
        },
        wrongText: function() {
            return this.options.worngText || '导出失败';
        },
        percentage: function() {
            return this.percentageNum + '%';
        }
    },

    data() {
        return {
            body: null,
            modal: null,
            percentageNum: '60',
            page: 'wrong',
            stop: false
        };
    },

    mounted() {
        this.installProgress();
    },

    methods: {
        changePage(val) {
            this.page = val === 'right' ? 'right' : val === 'wrong' ? 'wrong' : 'loading';
        },

        changePercentage(p) {
            const _p = p + '';
            this.percentageNum = ~_p.indexOf('%') ? _p.slice(0, _p.length - 1) : _p;
        },

        confirm() {
            this.$refs.modal.close();
        },

        cancel() {
            this.$refs.modal.close();
        },

        tryAgain() {
            this.installProgress();
            this.options.download();
        },

        changeStop() {
            this.stop = false;
        },

        installProgress() {
            this.page = 'loading';
            this.percentageNum = '0';
            this.stop = false;
            this.animation();
        },

        animation() {
            setTimeout(() => {
                const oldPercentage = new Number(this.percentageNum);
                if (this.stop) {
                    return;
                }
                let _speed = 0;
                if (oldPercentage < 59) {
                    _speed = 20;
                } else if (oldPercentage < 79) {
                    _speed = 10;
                } else if (oldPercentage < 89) {
                    _speed = 5;
                } else if (oldPercentage < 98) {
                    _speed = 1;
                } else {
                    this.stop = true;
                }
                this.percentageNum = oldPercentage + _speed;
                this.animation();
            }, 800);
        }
    },

    components: { modal }
};
</script>

<style scoped>
.progress-bar {
    height: 100%;
    width: 100%;
    padding: 16px;
}

.progress-bar .loading {
    position: relative;
    width: 100%;
    height: 100%;
    font-family: 'MicrosoftYaHei';
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
}

.progress-bar .loading .title {
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    text-align: center;
}

.progress-bar .loading .progress {
    position: absolute;
    top: 78px;
    left: 62px;
    bottom: 78px;
    right: 62px;
    background-color: #d9d9d9;
    border-radius: 7px;
}

.progress-bar .loading .progress .bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #52c41a;
    transition: width 0.5s linear -0.2s;
    border-radius: 7px;
}

.progress-bar .loading .percentage {
    position: absolute;
    top: 78px;
    left: 330px;
}

.progress-bar .result {
    width: 100%;
    height: 100%;
    position: relative;
}

.progress-bar .result .title {
    display: flex;
    padding: 8px 0;
}

.progress-bar .result .title .confirm {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
}

.progress-bar .result .title .text {
    flex: 1;
    font-size: 14px;
    padding-left: 15px;
    position: relative;
}

.progress-bar .result .bottom-button {
    text-align: right;
    position: absolute;
    right: 0;
    bottom: 0;
}

.progress-bar .result.result-wrong .title .confirm {
    background: red;
}

.progress-bar .result.result-wrong .title .confirm .svg {
    width: 10px;
    margin-left: 4px;
    line-height: 16px;
}

.progress-bar .result.result-right .title .confirm {
    background: #52c41a;
}

.progress-bar .result .title.result-right .confirm .svg {
    width: 14px;
    margin-left: 2px;
    height: 18px;
    line-height: 18px;
}
</style>
