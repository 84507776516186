//
//
//
//
//
//
//
//
export default {
  props: {
    items: {
      default: function _default() {
        return [];
      },
      type: Array
    }
  },
  data: function data() {
    return {
      rootClass: 'sw-link-group'
    };
  },
  computed: {
    itemClass: function itemClass() {
      return "".concat(this.rootClass, "-item");
    }
  },
  methods: {
    clickHandle: function clickHandle(item) {
      this.$emit('itemClick', item);
    }
  }
};