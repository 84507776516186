<template>
    <div class="default-scroll" ref="default-scroll">
        <slot />
    </div>
</template>
<script>
export default {
    data() {
        return {
            scrollTop: 0,
            scrollLeft: 0,
            scrollDom: null
        };
    },

    mounted() {
        this.scrollDom = this.$refs['default-scroll'];
        this.scrollDom.addEventListener(
            'scroll',
            () => {
                this.scrollHandle();
            },
            true
        );
    },

    methods: {
        scrollHandle() {
            this.scrollTop = this.scrollDom.scrollTop;
            this.scrollLeft = this.scrollDom.scrollLeft;
            this.$emit('handle-scroll', this.scrollTop, this.scrollLeft);
        },

        scrollTo(pos) {
            this.scrollDom.scrollTo(pos.x, pos.y);
        },

        scrollBy(pos) {
            this.scrollTop += pos.dy;
            this.scrollLeft += pos.dx;
            this.scrollDom.scrollTo(this.scrollTop, this.scrollLeft);
        },

        //获取当前滚动条位置
        getPosition() {
            return {
                scrollTop: this.scrollTop,
                scrollLeft: this.scrollLeft
            };
        },

        resizeScroll() {}
    }
};
</script>
<style scoped>
.default-scroll {
    overflow: auto;
    width: 100%;
    height: 100%;
}
</style>
