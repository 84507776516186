<template>
    <div class="body-cell select-row-checkbox-cell" @click.stop="clickHandle" @mousedown="mousedownHandle">
        <editor ref="checkbox" :is="checkboxType" :propChecked="propChecked" @change="changeHandle" />
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { GridUtils } from '../../js/grid-utils';
import { EventUtils } from '@/gikam/js/utils/event-utils';

export default {
    props: {
        rowData: Object,
        rowIndex: Number,
        fieldOptions: Object
    },

    computed: {
        ...mapState(['checkContinuous', 'checkedIdsStorage', 'lazyLoad', 'grid', 'checkedRowKeys']),

        propChecked() {
            if (this.allChecked) {
                return true;
            }
            if (GridUtils.getRowVueState(this.rowData, 'checked')) {
                return true;
            }
            if (this.checkContinuous) {
                return this.checkedIdsStorage.includes(this.rowData.id);
            }
            return false;
        },

        checkboxType() {
            return this.fieldOptions.checkbox ? 'checkbox' : 'radio';
        }
    },

    methods: {
        ...mapMutations(['activeRow', 'checkRow', 'unCheckRow']),

        changeHandle(checked, value, event) {
            event.stopPropagation();
            const rowIndex = GridUtils.getRowVueState(this.rowData, 'rowIndex');
            GridUtils.toggleCheckRow(this.rowData, checked, this.grid);
            this.grid.trigger('checkboxClick', this.rowData, rowIndex);
        },

        clickHandle() {
            this.$refs.checkbox.$el.click();
            // 如果点击的是单元格需要触发激活行,复选框点击阻止冒泡
            this.activeRow({ rowData: this.rowData });
        },

        mousedownHandle() {
            if (!EventUtils.isShiftKeyDown) {
                this.grid.lastCheckboxRowData = this.rowData;
                return;
            }
            document.body.style.userSelect = 'none';
            const beginIndex = GridUtils.getRowVueState(this.grid.lastCheckboxRowData, 'rowIndex');
            const endIndex = GridUtils.getRowVueState(this.rowData, 'rowIndex');
            GridUtils.ToggleCheckRowRange(beginIndex, endIndex, this.grid);
            this.grid.lastCheckboxRowData = this.rowData;
        }
    }
};
</script>
