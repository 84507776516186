<template>
    <div
        :is="fieldEditorType"
        :options="fieldOptions"
        :propReadonly="readonly"
        :propSetValueAfterChoose="true"
        :propValue="value"
        @change="changeHandle"
        @input="inputHandle"
    ></div>
</template>

<script>
import Gikam from 'gikam';
import { mapState, mapMutations } from 'vuex';
import filterCheckboxGroup from './editor/filterCheckboxGroup';
import filterSelect from './editor/filterSelect';
import filterText from './editor/filterText.vue';
import filterSimpleCheckbox from './editor/filterSimpleCheckbox';
import filterDate from './editor/filterDate';
import filterDateTime from './editor/filterDateTime';
import filterNumber from './editor/filterNumber';

export default {
    props: {
        options: Object,
        filterOriginalData: Object,
        filter: [Object, Boolean],
        requestFieldData: Object
    },

    components: {
        filterCheckboxGroup,
        filterDate,
        filterDateTime,
        filterSelect,
        filterText,
        filterSimpleCheckbox,
        filterNumber
    },

    data() {
        const fieldOptions = Gikam.deepExtend(this.options);
        delete fieldOptions.validators;
        return { fieldOptions };
    },

    created() {
        this.fieldOptions.readonly = false;
        this.fieldOptions._isHeader = true;
    },

    computed: {
        ...mapState(['fieldTypeMapper', 'serverSearch', 'url', 'filterImmediate']),

        fieldEditorType() {
            let type = this.fieldOptions.type || 'text';
            let fieldName = this.fieldOptions.field;
            let filterOptions = this.fieldOptions.filter;
            // 如果为text类型，取值根据后台返回的columnTypeMap中字段类型
            if (type === 'text' && this.fieldTypeMapper && fieldName) {
                const simpleFieldName = fieldName.toLowerCase().replace('ext$', '');
                type = this.fieldTypeMapper[simpleFieldName];
            }
            // 字段中配置快捷查询参数
            if (fieldName && filterOptions) {
                type = filterOptions.type || type;
            }
            return this.getEditorType(type);
        },

        readonly() {
            return this.fieldOptions.filter === false ? true : false;
        },

        value() {
            return Gikam.getFieldValue(this.requestFieldData, this.fieldOptions.field) ?? '';
        },

        isSeverSearch() {
            return this.serverSearch !== false && this.fieldOptions.serverSearch !== false;
        }
    },

    methods: {
        ...mapMutations(['updatePageNum', 'updateHeadRequestData', 'refresh', 'pageSearch']),

        getEditorType(type) {
            const compTypeMapper = {
                checkboxGroup: 'filterCheckboxGroup',
                select: 'filterSelect',
                dateTime: 'filterDateTime',
                date: 'filterDate',
                number: 'filterNumber',
                simpleCheckbox: 'filterSimpleCheckbox'
            };
            return compTypeMapper[type] || 'filterText';
        },

        setHeadRequestData({ value, match }) {
            const fieldName = this.fieldOptions.field;
            this.filterOriginalData[fieldName] = value;
            if (this.fieldOptions.filter === false || this.filter === false) {
                return;
            }
            this.updatePageNum(1);
            const filedName = this.fieldOptions.field;
            let matchType = this.fieldOptions.match || match;
            let requestMatchType = filedName;
            if (!filedName.includes('_') || (filedName.includes('$') && !filedName.includes('ext$.'))) {
                requestMatchType = `${filedName}_${matchType}`;
            }
            this.updateHeadRequestData({ [requestMatchType]: value });
        },

        changeHandle(changeOptions) {
            const fieldName = this.fieldOptions.field;
            if (Array.isArray(changeOptions)) {
                changeOptions.forEach(item => this.setHeadRequestData(item));
            } else {
                this.setHeadRequestData(changeOptions);
            }
            if (this.isSeverSearch) {
                this.refresh();
            } else {
                const column = Gikam.deepExtend(this.fieldOptions, { type: this.fieldEditorType });
                this.pageSearch({ fieldName, value: changeOptions.value, text: changeOptions.text, column });
            }
        },

        inputHandle(inputOptions) {
            Gikam.finalDelay(
                'GridSearch',
                () => {
                    if (this.filterImmediate) {
                        this.changeHandle(inputOptions);
                    }
                },
                400
            );
        }
    }
};
</script>
