<template>
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 13 15"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-325.000000, -1212.000000)">
                <g transform="translate(311.000000, 848.000000)">
                    <g transform="translate(1.000000, 32.000000)">
                        <g transform="translate(12.000000, 332.000000)">
                            <rect fill-opacity="0" fill="#FFFFFF" x="0" y="0" width="16" height="16"></rect>
                            <path
                                d="M11.0382372,4.55123547 L6.46077245,4.55123547 C6.15560812,4.55123547 5.88858935,4.29687501 5.88858935,4.00617733 C5.88858935,3.71547965 6.15560812,3.46111919 6.46077245,3.46111919 L11.0382372,3.46111919 C11.3434016,3.46111919 11.6104203,3.71547965 11.6104203,4.00617733 C11.6104203,4.29687501 11.3434016,4.55123547 11.0382372,4.55123547 Z"
                                fill="currentColor"
                                fill-rule="nonzero"
                            ></path>
                            <path
                                d="M12.6403499,14.9800145 L2.34105414,14.9800145 C1.61628889,14.9800145 1.00596023,14.3986192 1.00596023,13.7082122 L1.00596023,1.28088663 C1.00596023,0.590479657 1.61628887,0.00908429665 2.34105414,0.00908429665 L12.6403499,0.00908429665 C13.3651152,0.00908429665 13.9754438,0.590479657 13.9754438,1.28088663 L13.9754438,13.7445494 C13.9754438,14.3986192 13.3651152,14.9800145 12.6403499,14.9800145 Z M2.34105414,1.09920058 C2.22661752,1.09920058 2.15032643,1.171875 2.15032643,1.28088663 L2.15032643,13.7082122 C2.15032643,13.8172238 2.22661752,13.8898983 2.34105414,13.8898983 L12.6403499,13.8898983 C12.7547865,13.8898983 12.8310776,13.8172238 12.8310776,13.7445494 L12.8310776,1.28088663 C12.8310776,1.171875 12.7547865,1.09920058 12.6403499,1.09920058 L2.34105414,1.09920058 Z"
                                fill="currentColor"
                                fill-rule="nonzero"
                            ></path>
                            <path
                                d="M3.94316681,4.00617733 C3.94316681,4.30720462 4.19934191,4.55123547 4.51534991,4.55123547 C4.83135791,4.55123547 5.08753301,4.30720462 5.08753301,4.00617733 C5.08753301,3.70515003 4.83135791,3.46111919 4.51534991,3.46111919 C4.19934191,3.46111919 3.94316681,3.70515003 3.94316681,4.00617733 Z"
                                fill="currentColor"
                                fill-rule="nonzero"
                            ></path>
                            <path
                                d="M11.0382372,8.18495639 L6.46077245,8.18495639 C6.15560812,8.18495639 5.88858935,7.93059593 5.88858935,7.63989825 C5.88858935,7.34920057 6.15560812,7.09484011 6.46077245,7.09484011 L11.0382372,7.09484011 C11.3434016,7.09484011 11.6104203,7.34920057 11.6104203,7.63989825 C11.6104203,7.93059593 11.3434016,8.18495639 11.0382372,8.18495639 Z"
                                fill="currentColor"
                                fill-rule="nonzero"
                            ></path>
                            <path
                                d="M3.94316681,7.63989825 C3.94316681,7.94092555 4.19934191,8.18495639 4.51534991,8.18495639 C4.83135791,8.18495639 5.08753301,7.94092555 5.08753301,7.63989825 C5.08753301,7.33887095 4.83135791,7.09484011 4.51534991,7.09484011 C4.19934191,7.09484011 3.94316681,7.33887095 3.94316681,7.63989825 Z"
                                fill="currentColor"
                                fill-rule="nonzero"
                            ></path>
                            <path
                                d="M11.0382372,11.4553052 L6.46077245,11.4553052 C6.15560812,11.4553052 5.88858935,11.2009448 5.88858935,10.9102471 C5.88858935,10.6195494 6.15560812,10.3651889 6.46077245,10.3651889 L11.0382372,10.3651889 C11.3434016,10.3651889 11.6104203,10.6195494 11.6104203,10.9102471 C11.6104203,11.2009448 11.3434016,11.4553052 11.0382372,11.4553052 Z"
                                fill="currentColor"
                                fill-rule="nonzero"
                            ></path>
                            <path
                                d="M3.94316681,10.9102471 C3.94316681,11.2112744 4.19934191,11.4553052 4.51534991,11.4553052 C4.83135791,11.4553052 5.08753301,11.2112744 5.08753301,10.9102471 C5.08753301,10.6092198 4.83135791,10.3651889 4.51534991,10.3651889 C4.19934191,10.3651889 3.94316681,10.6092198 3.94316681,10.9102471 Z"
                                fill="currentColor"
                                fill-rule="nonzero"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
