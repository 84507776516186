import moment from 'moment';

export default {
    formatter: function(date, fmt) {
        if (!date) {
            return;
        }
        let formatterStr = fmt,
            o = {
                'M+': date.getMonth() + 1,
                'd+': date.getDate(),
                'h+': date.getHours(),
                'm+': date.getMinutes(),
                's+': date.getSeconds(),
                'q+': Math.floor((date.getMonth() + 3) / 3),
                S: date.getMilliseconds()
            };
        if (/(y+)/.test(fmt)) {
            formatterStr = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (let k in o) {
            if (new RegExp('(' + k + ')').test(fmt)) {
                formatterStr = formatterStr.replace(
                    RegExp.$1,
                    RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length)
                );
            }
        }
        return formatterStr;
    },

    toDate: function(str) {
        if (!str) {
            return;
        }
        let array = str.substr(0, 10).split('-');
        const time = str.substr(11).split(':');
        const hour = parseInt(time[0]);
        const minute = parseInt(time[1]);
        const second = parseInt(time[2] || 0);
        if (isNaN(hour)) {
            return new Date(array[0], parseInt(array[1]) - 1, array[2]);
        } else {
            return new Date(array[0], parseInt(array[1]) - 1, array[2], hour, minute, second);
        }
    },

    //将指定格式字符串解析为Date()对象(保留时与分)
    //格式：yyyy-MM-dd hh:mm
    //注：如未传入时、分，则默认为0
    toDateTime: function(str) {
        if (!str) {
            return;
        }
        let array = str.substr(0, 10).split('-');
        let time = str.substr(11).split(':');
        let hour = 0;
        let minute = 0;
        if (time.length > 1) {
            hour = !isNaN(time[0]) ? Math.floor(time[0]) : 0;
            minute = !isNaN(time[1]) ? Math.floor(time[1]) : 0;
        }
        return new Date(array[0], Math.floor(array[1]) - 1, array[2], hour, minute);
    },

    momentToString(moment, formatter = 'YYYY-MM-DD') {
        if (!moment) {
            return null;
        }
        return moment.format(formatter);
    },

    stringToMoment(value, formatter = 'YYYY-MM-DD') {
        if (!value) {
            return null;
        }
        return moment(value, formatter);
    },

    stringTimeToMoment(value, formatter = 'YYYY-MM-DD HH:mm:ss'){
        if (!value) {
            return null;
        }
        return moment(value, formatter);
    },

    momentToStringTime(moment, formatter = 'YYYY-MM-DD HH:mm:ss') {
        if (!moment) {
            return null;
        }
        return moment.format(formatter);
    },
};
