import "core-js/modules/web.dom-collections.for-each.js";
import Gikam from './gikam-core';
var config = {};
export default {
  addConfigParam: function addConfigParam(param) {
    param.forEach(function (item) {
      config[item.category] = item;
    });
  },
  getConfig: function getConfig(category) {
    if (!category) {
      return config;
    }

    if (!config[category]) {
      Gikam.error('do not find choose config by [' + category + ']');
    }

    return config[category];
  }
};