import _classCallCheck from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/classCallCheck";
import _defineProperty from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/defineProperty";
export var EventUtils = function EventUtils() {
  _classCallCheck(this, EventUtils);
};

_defineProperty(EventUtils, "isShiftKeyDown", false);

document.documentElement.addEventListener('keydown', function (e) {
  EventUtils.isShiftKeyDown = e.shiftKey;
});
document.documentElement.addEventListener('keyup', function (e) {
  EventUtils.isShiftKeyDown = e.shiftKey;
});
document.documentElement.addEventListener('mouseup', function () {
  var style = document.documentElement.style;

  if (style.userSelect === 'none') {
    style.userSelect = '';
  }
});