import Gikam from 'gikam';
import { Sequence } from '@/gikam/js/core/sequence';
import { PageEditAuthorityUtils } from '@/gikam/js/components/pageEdit/page-edit-authority-utils.js';

let form;

const defaultFieldType = {
    string: 'text',
    double: 'number',
    long: 'number',
    localdate: 'date',
    localdatetime: 'dateTime'
};

function getFormConfig() {
    return (Gikam.compConfigManager?.form ?? {})[form.options.id];
}

function parseFieldsByConfig() {
    const fields = getFormConfig()?.columns;
    if (!fields) {
        return;
    }
    const panels = [];
    let currentPanel = null;
    fields.forEach((item, index) => {
        const mapperField = form.fieldMapper[item.field];
        if (mapperField) {
            Object.getOwnPropertyNames(mapperField).forEach(name => {
                if (!item.hasOwnProperty(name) && name !== '__ob__') {
                    item[name] = mapperField[name];
                }
            });
        }
        item.visible = item.visible == 1 ? true : false;
        if (currentPanel && item.fieldSetTitle !== currentPanel.title) {
            panels.push(currentPanel);
            currentPanel = null;
        }
        if (currentPanel === null) {
            currentPanel = { title: item.fieldSetTitle, fields: [] };
        }
        currentPanel.fields.push(item);
        if (index === fields.length - 1) {
            panels.push(currentPanel);
        }
    });
    form.options.panels = panels;
}

/**
 * @description 将字段转换为映射
 */
function convertFieldsToMapper() {
    const mapper = {};
    const initialMapper = {};
    if (form.$fromPageConfig) {
        form.initialPanels.forEach(panel => {
            panel.fields.forEach(item => {
                if (item.field) {
                    initialMapper[item.field] = item;
                }
            });
        });
    }
    form.options.panels.forEach(panel => {
        panel.fields.forEach(item => {
            if (item.field) {
                mapper[item.field] = item;
                const initialMapperField = initialMapper[item.field];
                if (form.$fromPageConfig && initialMapperField) {
                    Object.getOwnPropertyNames(initialMapperField).forEach(name => {
                        if (item.hasOwnProperty(name) || name === '__ob__') {
                            return;
                        }
                        item[name] = initialMapperField[name];
                    });
                }
            } else {
                item.key = Sequence.nextWithTime();
            }
        });
        if (form.$fromPageConfig) {
            PageEditAuthorityUtils.initAuthority(panel.fields);
        }
    });
    form.fieldMapper = mapper;
}

export function processFormFields(formInstance) {
    form = formInstance;
    if (Gikam.isEmpty(form.options.panels)) {
        form.options.panels.push({ fields: form.options.fields });
    }
    form.defaultPanels = form.options.panels;
    convertFieldsToMapper();
    parseFieldsByConfig();
    form = null;
}

function getFieldType(field, type) {
    field.type = defaultFieldType[type] || type;
    if (type === 'double') {
        field.isInteger = false;
    } else if (type === 'long') {
        field.isInteger = true;
    }
}

export function refreshFieldsByConfig(fields, hidden, columns) {
    if (!Array.isArray(fields)) {
        return;
    }

    form.model.$children[0].changeHiddenGridState(!hidden);
    if (!hidden) {
        return;
    }

    const panels = [];
    let currentPanel = null;
    const formInstance = Gikam.getComp(fields[0].blockId);
    let originFields = (Gikam.compConfigManager?.form ?? {})[fields[0].blockId]?.columns;
    if (Gikam.isEmpty(originFields)) {
        originFields = [];
        formInstance.options.panels.forEach(item => (originFields = originFields.concat(item.fields)));
    }
    // eslint-disable-next-line complexity
    fields.forEach((field, index) => {
        const item = field.json || {};
        let _item = originFields.find(list => list.field === item.field);
        if (_item) {
            _item.visible = item.visible == 1 ? true : false;
            _item.title = item.title;
            _item.colspan && (_item.colspan = item.colspan);
            if (item.defaultField === '0') {
                getFieldType(_item, item.fieldType);
            }
        } else {
            _item = item;
            _item.visible = item.visible == 1 ? true : false;
            getFieldType(_item, item.fieldType);
        }
        _item.editor = item.visible === '1' ? true : false;

        if (currentPanel && item.fieldSetTitle !== currentPanel.title) {
            panels.push(currentPanel);
            currentPanel = null;
        }
        if (currentPanel === null) {
            currentPanel = { title: item.fieldSetTitle, fields: [] };
        }
        currentPanel.fields.push(_item);
        if (index === fields.length - 1) {
            panels.push(currentPanel);
        }
    });
    formInstance.options.panels = Gikam.deepExtend(panels);
    formInstance.options.columns = parseInt(columns);
}
