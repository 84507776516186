<template>
    <page-edit
        v-if="pageEdit"
        :title="title"
        :settingPanelOptions="options.settingPanelOptions"
        @exit="pageEditExitHandle"
    ></page-edit>
    <div
        class="window"
        :id="options.id"
        @mousemove="mousemove"
        v-show="!hidden"
        :class="options.isEnterFullScreen ? 'full-screen-window' : ''"
        v-else
    >
        <header v-if="headerVisible && toolbarTop" v-show="headerShow">
            <div class="widow-title" v-if="options.showTitle && options.title">
                <div class="title">{{ title }}</div>
                <div class="sub-title" v-if="subTitle" :title="subTitle">/{{ subTitle }}</div>
            </div>
            <div class="toolbar" ref="toolbar" :class="toolbarAlign"></div>
        </header>
        <section>
            <div class="body" ref="body" :comp-id="options.id"></div>
        </section>
        <footer v-if="!toolbarTop">
            <div class="toolbar right" ref="toolbar"></div>
        </footer>
        <transition name="fade">
            <div
                class="exit-full-screen"
                v-show="showExitBtn"
                @mousemove.stop
                @mouseenter="clearTimer"
                @mouseleave="hiddenExitBtn"
                @dblclick="exitFullScreen()"
                :title="$i18n('window.exitFullScreen')"
            >
                <exitFullScreenImg :width="'18px'" :height="'18px'"></exitFullScreenImg>
            </div>
        </transition>
        <WindowPageProgress v-if="progressVisible" :class="pageRequestingClass" :pageRequesting="pageRequesting" />
    </div>
</template>

<script>
import WindowPageProgress from './window-page-progress.vue';

export default {
    components: {
        PageEdit: () => import('@/gikam/js/components/pageEdit/vue/pageEdit'),
        WindowPageProgress
    },

    inject: ['window'],

    props: {
        options: Object,
        viewModelData: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        const data = {
            title: void 0,
            subTitle: void 0,
            pageLoading: false,
            dataLoading: false,
            toolbarTop: true,
            headerShow: true,
            toolbarAlign: 'right',
            hidden: false,
            pageEdit: false,
            // 页面编辑时当前激活的属性面板
            $activeSettingPanel: null,
            headerVisible: true,
            showExitBtn: false,
            timer: null
        };
        return { ...data, ...this.options };
    },

    computed: {
        pageRequesting() {
            return this.viewModelData.pageRequesting;
        },

        pageRequestingClass() {
            return 'window-page-requesting-progress';
        },

        progressVisible() {
            return this.options.loadingMode === 'progress';
        }
    },

    watch: {
        'options.subTitle'(val) {
            val && (this.subTitle = val);
        }
    },

    methods: {
        hide() {
            this.hidden = true;
        },

        show() {
            this.hidden = false;
        },

        initMove() {
            const box = document.getElementsByClassName('exit-full-screen')[0];
            let disX;
            let disY;
            box.onmousedown = function(ev) {
                const oEvent = ev || event;
                disX = oEvent.clientX - box.offsetLeft;
                disY = oEvent.clientY - box.offsetTop;
                document.onmousemove = function(ev) {
                    const oEvent = ev || event;
                    let l = oEvent.clientX - disX;
                    let t = oEvent.clientY - disY;
                    if (l < 0) {
                        l = 0;
                    } else if (l > document.documentElement.clientWidth - box.offsetWidth) {
                        l = document.documentElement.clientWidth - box.offsetWidth;
                    }
                    if (t < 0) {
                        t = 0;
                    } else if (t > document.documentElement.clientHeight - box.offsetHeight) {
                        t = document.documentElement.clientHeight - box.offsetHeight;
                    }
                    box.style.left = l + 'px';
                    box.style.top = t + 'px';
                };
                document.onmouseup = function() {
                    document.onmousemove = null;
                    document.onmouseup = null;
                };
                return false;
            };
        },

        exitFullScreen() {
            this.showExitBtn = false;
            this.options.isEnterFullScreen = false;
        },

        async pageEditExitHandle() {
            this.pageEdit = false;
            await this.$nextTick();
            this.window.$dom = this.$refs.body;
            this.window.$toolbar = this.$refs.toolbar;
            this.window.bindInstance(this.$el);
            this.window.bindInstance(this.window.$dom);
            this.window.refresh();
            this.window.openSettingPanel();
        },

        mousemove(ev) {
            if (!this.options.isEnterFullScreen) {
                return;
            }
            const { pageX, pageY } = ev;
            const { clientWidth } = document.documentElement;

            if (clientWidth - pageX <= 16 && pageY <= 16) {
                this.showExitBtn = true;
                this.clearTimer();
            } else if (this.showExitBtn && !this.timer) {
                this.hiddenExitBtn();
            }
        },

        clearTimer() {
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = null;
            }
        },

        hiddenExitBtn() {
            this.timer = setTimeout(() => {
                this.showExitBtn = false;
                this.clearTimer();
            }, 1000);
        }
    }
};
</script>

<style scoped>
.window {
    height: 100%;
    width: 100%;
    flex-direction: column;
    display: flex;
    position: relative;
}

.window.full-screen-window {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #ffffff;
    z-index: 9;
}

.window .exit-full-screen {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 4px;
    right: 4px;
    background: #27986d;
    border-radius: 50%;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

header {
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    background-color: #ffffff;
}

header > .widow-title {
    padding: 0 15px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 4px 0;
}

header > .widow-title > .title {
    font-size: 16px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    display: inline;
}

header > .widow-title > .sub-title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
    display: inline;
    margin-left: 8px;
}

/* header - toolbar */
header > .toolbar,
footer > .toolbar {
    width: 0;
    flex: 1;
    display: flex;
    padding-right: 8px;
    overflow: hidden;
}

header > .toolbar >>> .btn-toolbar {
    margin: 2px 0 3px;
}

header > .toolbar.toolbar-left > .btn-toolbar > .items:first-child {
    margin-left: 0;
}

.toolbar.left {
    justify-content: flex-start;
}

.toolbar.center {
    justify-content: center;
}

.toolbar.right >>> .btn-toolbar {
    justify-content: flex-end;
}

/* 控制type:button的margin ；type:btnGroup 内的button不控制 */
header > .toolbar >>> .items > .btn-container {
    margin: 0 !important;
}

/* 控制type:button的宽度；由子节点撑开 */
header > .toolbar >>> .items > .btn-group {
    display: flex;
    align-content: center;
}

header > .toolbar >>> .button {
    height: 24px !important;
}

.window > section {
    flex: 1;
    overflow: hidden;
}

.window > section > .body {
    height: 100%;
    width: 100%;
}

footer {
    height: 60px;
    padding: 0 16px;
    display: flex;
    align-items: center;
}

.window .fade-enter-active {
    animation: in 0.5s ease;
}

.window .fade-leave-active {
    animation: out 0.5s ease;
}

.window .fade-enter,
.window .fade-leave-to {
    opacity: 0;
    transform: translate(30px, -30px);
}

@keyframes in {
    0% {
        opacity: 0;
        transform: translate(30px, -30px);
    }
    100% {
        opacity: 1;
        transform: translate(0px, 0px);
    }
}

@keyframes out {
    0% {
        opacity: 1;
        transform: translate(0px, 0px);
    }
    100% {
        opacity: 0;
        transform: translate(30px, -30px);
    }
}
</style>
