import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
import Gikam from 'gikam';
export default {
  props: {
    options: {
      default: function _default() {
        return {};
      },
      type: Object
    },
    propId: [String, Number],
    propChecked: {
      type: Boolean,
      default: false
    },
    propHalfChecked: {
      type: Boolean,
      default: false
    },
    propValue: [String, Number],
    externalControl: {
      type: Boolean,
      default: false
    },
    propReadonly: Boolean,
    width: [String, Number],
    height: [String, Number]
  },
  data: function data() {
    return {
      checked: false,
      value: this.propValue,
      halfChecked: false,
      name: this.options.name
    };
  },
  computed: {
    styleObject: function styleObject() {
      return {
        width: "".concat(this.width, "px"),
        height: "".concat(this.height, "px")
      };
    },
    classList: function classList() {
      var classList = [{
        checked: this.checked && !this.halfChecked
      }, {
        disabled: this.propReadonly
      }, {
        'half-checked': this.checked && this.halfChecked
      }];
      return classList;
    }
  },
  watch: {
    propChecked: {
      immediate: true,
      handler: function handler(checked) {
        this.checked = checked;
      }
    },
    propHalfChecked: {
      immediate: true,
      handler: function handler(halfChecked) {
        this.halfChecked = halfChecked;
      }
    }
  },
  methods: {
    clickHandle: function clickHandle(e) {
      if (this.propReadonly) {
        return;
      }

      if (Gikam.isFalse(this.externalControl)) {
        // 半选状态
        if (this.halfChecked && this.checked) {
          this.halfChecked = false;
        } else {
          this.checked = !this.checked;
        }
      }

      this.$emit('change', this.checked, this.value, e, this.propId);
    }
  }
};