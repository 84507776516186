import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.string.iterator.js";
import Gikam from 'gikam';
export var mixin = {
  methods: {
    pasteWithStyle: function pasteWithStyle(event) {
      // 阻止默认行为
      event.preventDefault(); // 获取剪贴板的文本

      var htmlText = event.clipboardData.getData('text/html');

      if (Gikam.isEmpty(htmlText)) {
        htmlText = event.clipboardData.getData('text/text');
      }

      if (window.getSelection && Gikam.isNotEmpty(htmlText)) {
        // 创建节点
        var spanDom = document.createElement('span');
        spanDom.innerHTML = htmlText;
        Array.from(spanDom.querySelectorAll('div,span,sup,sub')).forEach(function (node) {
          node.removeAttribute('style');
        }); // 在当前的光标处插入文本节点

        var range = window.getSelection().getRangeAt(0); // 删除选中文本

        range.deleteContents(); // 插入文本

        range.insertNode(spanDom);
      }
    },
    pasteWithoutStyle: function pasteWithoutStyle(event) {
      if (event.clipboardData) {
        // 阻止默认行为
        event.preventDefault(); // 获取剪贴板的文本

        var text = event.clipboardData.getData('text');

        if (window.getSelection && Gikam.isNotEmpty(text)) {
          // 创建文本节点
          var textNode = document.createTextNode(text); // 在当前的光标处插入文本节点

          var range = window.getSelection().getRangeAt(0); // 删除选中文本

          range.deleteContents(); // 插入文本

          range.insertNode(textNode);
        }
      }
    },
    pasteHandle: function pasteHandle(event) {
      if (!event.clipboardData) {
        return;
      }

      this.options.pasteWithStyle ? this.pasteWithStyle(event) : this.pasteWithoutStyle(event);
    },
    beforePasteHandle: function beforePasteHandle() {
      if (this.options.pasteWithStyle) {
        return;
      }

      if (window.clipboardData) {
        var text = window.clipboardData.getData('text');

        if (Gikam.isNotEmpty(text)) {
          window.clipboardData.setData('text', text);
        }
      }
    }
  }
};