import Gikam from 'gikam';
import { GridUtils } from '../js/grid-utils';

let globeCurrentEditor;
document.addEventListener('mousedown', () => {
    if (!globeCurrentEditor) {
        return;
    }
    Gikam.finalDelay(
        'changeGlobeCurrentEditor',
        () => {
            if (globeCurrentEditor.propInvisible) {
                globeCurrentEditor.blur && globeCurrentEditor.blur();
                globeCurrentEditor.$nextTick(() => {
                    if (globeCurrentEditor.validateResult === false) {
                        return;
                    } else {
                        globeCurrentEditor.invisible = true;
                        globeCurrentEditor = null;
                    }
                });
            } else {
                globeCurrentEditor.blur && globeCurrentEditor.blur();
                globeCurrentEditor.invisible = true;
                globeCurrentEditor.validateResult = true;
                globeCurrentEditor = null;
            }
        },
        50
    );
});

const clearSelectPicker = function() {
    if (Gikam.isTrue(globeCurrentEditor.propInvisible)) {
        globeCurrentEditor.dumpActiveCell && globeCurrentEditor.dumpActiveCell();
    }
};

export default {
    functional: true,
    props: {
        options: Object,
        row: Object,
        rowIndex: Number,
        cellIndex: Number
    },
    inject: {
        grid: {
            default: null
        }
    },
    render(h, ctx) {
        const options = ctx.props.options;
        let editor = (options.type || 'text') + 'Field';
        if (options.type === 'checkbox' && Gikam.isNotEmpty(options.items)) {
            editor = 'frontConfigurationCheckboxField';
        }
        return (
            <editor
                options={ctx.props.options}
                validateArg={ctx.props.row}
                rowIndex={ctx.props.rowIndex}
                cellIndex={ctx.props.cellIndex}
                ref={ctx.props.rowIndex + '_' + ctx.props.cellIndex}
                propValue={Gikam.getFieldValue(ctx.props.row, ctx.props.options.field)}
                propReadonly={
                    options.onEditorInitReadonly ? options.onEditorInitReadonly(ctx.props.row) : options.readonly
                }
                beforeChange={() => {
                    const deepRowData = Gikam.deepExtend(ctx.props.row);
                    return ctx.props.options.onBeforeChange?.call(ctx.injections.grid, deepRowData);
                }}
                propInvisible={ctx.parent.editorInvisible}
                onChange={(...args) => {
                    Gikam.setFieldValue(ctx.props.row, args[0], args[1]);
                    if (options.onChange && ctx.injections.grid.options.fieldChangeFlag) {
                        options.onChange.apply(ctx.injections.grid, args);
                    }
                    if (ctx.injections.grid.canAutoSave) {
                        const data = { id: ctx.props.row.id, row: ctx.props.row, ext$: {} };
                        Gikam.setFieldValue(data, args[0], args[1]);
                        ctx.injections.grid.autoSave(data);
                    }
                    if (editor === 'simpleCheckboxField') {
                        const rowIndex = GridUtils.getRowVueState(ctx.props.row, 'rowIndex');
                        ctx.injections.grid.activeRowByIndex(rowIndex);
                    }
                    ctx.injections.grid.filedChangeHandle({
                        field: args[0],
                        value: args[1],
                        index: args[2]
                    });
                }}
                onDataChange={(...args) => {
                    options.onDataChange?.apply(ctx.injections.grid, args);
                }}
                onFocus={(...args) => {
                    if (!options.onFocus) {
                        return;
                    }
                    options.onFocus.apply(ctx.injections.grid, args);
                }}
                onAfterChoose={(rows, rowIndex, targetFields) => {
                    options.onAfterChoose?.call(ctx.injections.grid, rows, rowIndex, targetFields);
                }}
                onRendered={(...args) => {
                    if (!options.onRendered) {
                        return;
                    }
                    Gikam.finalDelay(
                        'gridSelectRendered' + ctx.props.rowIndex + ctx.props.cellIndex,
                        () => {
                            options.onRendered.apply(ctx.injections.grid, args);
                        },
                        41
                    );
                }}
                onKeydown={e => {
                    switch (e.keyCode) {
                        case 9:
                            e.preventDefault();
                            if (e.shiftKey) {
                                ctx.parent.bodyKeyDown(e, 'left');
                            } else {
                                ctx.parent.bodyKeyDown(e, 'right');
                            }
                            break;
                        case 13:
                            ctx.parent.bodyKeyDown(e, 'down');
                            break;
                        case 38:
                            e.preventDefault();
                            ctx.parent.bodyKeyDown(e, 'up');
                            break;
                        case 40:
                            e.preventDefault();
                            ctx.parent.bodyKeyDown(e, 'down');
                            break;
                        default:
                            return;
                    }
                }}
                onSaveCoordinate={() => {
                    ctx.injections.grid.store.commit('changeActiveCoordinate', [
                        ctx.props.rowIndex,
                        ctx.props.cellIndex
                    ]);
                }}
                onClick={fieldEditor => {
                    //当前编辑器只读
                    if (fieldEditor.readonly) {
                        return;
                    }
                    //编辑器默认呈现样式
                    if (ctx.parent.editorInvisible !== true) {
                        return;
                    }
                    if (
                        globeCurrentEditor &&
                        ['select', 'insertableSelect', 'comboBox'].indexOf(globeCurrentEditor.options.type) > -1
                    ) {
                        clearSelectPicker();
                    }
                    const currentEditor = ctx.injections.grid._getCurrentEditor();
                    //当前编辑器正在编辑中  或者输入正确时全局mousedown事件将其变为 invisible： false
                    if (currentEditor === fieldEditor && currentEditor.invisible !== true) {
                        return;
                    }
                    //当前grid是否有记忆的表格  或者输入正确时全局mousedown事件将其变为 invisible： false
                    if (currentEditor && currentEditor.invisible !== true) {
                        //上一编辑器没有失焦时，使其失焦，
                        currentEditor.blur && currentEditor.blur();
                        currentEditor.$nextTick(() => {
                            // 失焦之后校验前一单元格
                            if (currentEditor.validateResult === false) {
                                globeCurrentEditor = currentEditor;
                            } else {
                                currentEditor.invisible = true;
                                //保存住正在编辑的单元格
                                fieldEditor.invisible = false;
                                ctx.injections.grid._setCurrentEditor(fieldEditor);
                                globeCurrentEditor = fieldEditor;
                                fieldEditor.$nextTick(() => {
                                    fieldEditor.focus && fieldEditor.focus();
                                });
                            }
                        });
                    } else {
                        fieldEditor.invisible = false;
                        ctx.injections.grid._setCurrentEditor(fieldEditor);
                        globeCurrentEditor = fieldEditor;
                        fieldEditor.$nextTick(() => {
                            fieldEditor.focus && fieldEditor.focus();
                        });
                    }
                }}
                onSelfClean={() => {
                    Gikam.setFieldValue(ctx.props.row, ctx.props.options.field, null);
                }}
            ></editor>
        );
    }
};
