import Gikam from '../../core/gikam-core.js';
import Base from '../base.js';
import { I18N } from '@/gikam/i18n/I18N.js';

window.addEventListener('message', function(e) {
    if (e.data && (e.data.type === 'PRINT' || e.data.type === 'DOWNLOAD')) {
        Gikam.post(e.data.url);
    }
});

let defaultOptions = {
    iframe: null,
    version: null,
    fileType: null,
    fileId: null,
    fileName: null,
    fileUrl: null,
    mode: 'view',
    viewType: 'modal',
    callbackUrl: null,
    popupIsModal: true,
    otherParam: {
        download: true,
        print: true
    },
    saved: true,
    onAfterClose: Gikam.emptyFunction
};

export default class EditorOffice extends Base {
    constructor(options) {
        super();
        this.getListeners();
        options.viewType =
            options.otherParam && options.otherParam.viewType ? options.otherParam.viewType : options.viewType;
        this.initialize(options, defaultOptions).init();
    }

    /**
     * @description 可配置钩子函数
     * @private
     * @memberof EditorOffice
     */
    getListeners() {
        this.listeners = {
            warn: Gikam.emptyFunction,
            error: Gikam.emptyFunction
        };
    }

    /**
     * @description 加载sunwayoffice组件
     * @private
     * @memberof sunwayoffice
     */
    loadDocEditor() {
        //监听加载状态
        this.iframe.onload = function() {
            //加载完毕初始化编辑页面
            this.iframe.contentWindow.initDocEditorPage(this.serverUrl, this.editorOfficeObj, this.officeHook);
        }.bind(this);

        //监听编辑页面无法加载时的情况
        this.iframe.onerror = function() {
            Gikam.alert(I18N.prop(`office.file.editor.not.find.tip`));
            this.trigger('error');
            this.destroy();
        }.bind(this);
    }

    getFileType() {
        return Gikam.isNotEmpty(this.options.fileUrl)
            ? this.options.fileUrl.substring(this.options.fileUrl.lastIndexOf('.') + 1)
            : '';
    }

    getFileName() {
        return Gikam.isNotEmpty(this.options.fileUrl)
            ? this.options.fileUrl.substring(this.options.fileUrl.lastIndexOf('/') + 1)
            : '';
    }

    /**
     * @description 初始化组件参数
     * @private
     * @memberof sunwayoffice
     */
    // eslint-disable-next-line complexity
    async initParams() {
        //判断文件数据是否完整
        let fileDetailCompleted = true;
        //判断时候需要定制化logo
        let needCustomer = true;
        //判断使用桌面模式还是移动模式
        let type = 'desktop';
        let key = new Date().getTime().toString();
        this.options.fileType = this.getFileType().toLowerCase();
        this.options.fileName = this.getFileName();

        if (navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i)) type = 'mobile';

        //获取sunwayoffice服务地址
        this.serverUrl = Gikam.sunwayoffice.serverUrl || Gikam.IFM_CONTEXT;
        if (Gikam.isNotEmpty(this.options.fileId)) {
            await this.getFileUrl()
                .done(file => {
                    this.options.fileId = file.id;
                    if (Gikam.isNotEmpty(this.options.fileUrl)) {
                        this.options.fileUrl = Gikam.isTrue(Gikam.sunwayoffice.watermarkEnable)
                            ? this.options.fileUrl.replace(
                                  this.options.fileName,
                                  encodeURIComponent(this.options.fileName + '_REVERT_WATERMARK_BAK')
                              )
                            : this.options.fileUrl;
                    } else {
                        this.options.fileUrl = Gikam.isTrue(Gikam.sunwayoffice.watermarkEnable)
                            ? file.downloadUrl.replace(
                                  encodeURIComponent(file.name),
                                  encodeURIComponent(
                                      file.name.replace('.' + (file.fileExt || ''), '') +
                                          '_REVERT_WATERMARK_BAK' +
                                          '.' +
                                          (file.fileExt || '')
                                  )
                              )
                            : file.downloadUrl.replace(file.name, encodeURIComponent(file.name));
                    }
                    this.options.fileName = file.name || '';
                    this.options.fileType = (file.fileExt || '').toLocaleLowerCase();
                    this.options.version = file.version || '';
                    this.iframe.src = `${Gikam.IFM_CONTEXT}/static/plugin/sunwayoffice/office.html`;
                })
                .fail(() => {
                    this.destroy();
                    fileDetailCompleted = false;
                    Gikam.alert(I18N.prop('office.file.info.not.find.tip'));
                });
            key = this.options.fileId.toString();
            if (this.options.version) {
                key = `${key.substring(0, 16)}-V${this.options.version}`;
            } else {
                key = key.substring(0, 14);
            }

            await this.getInfo()
                .done(result => {
                    this.options.customer = {
                        address: result.address,
                        info: result.info,
                        logo: `${Gikam.IFM_CONTEXT}${result.aboutLogo}`, // 关于中的公司logo地址， png格式的推荐大小为432*70
                        mail: result.mail,
                        name: result.name,
                        www: result.www
                    };
                    this.options.logo = {
                        image: `${Gikam.IFM_CONTEXT}${result.logo}` // logo地址， 推荐大小172*40
                        // url: result.logoUrl // 点击logo跳转地址
                    };

                    this.options.formats = this.getFormats(result.downloadAs, this.options.fileType);
                })
                .catch(() => {
                    // 暂时不需要处理, 没有就显示sunwayoffice的默认值
                    needCustomer = false;
                });

            await this.getToolbarMenu(this.options.fileType)
                .done(result => {
                    this.options.menu = this.formatData(result.rows);
                })
                .fail(() => {});

            if (this.options.otherParam && this.options.otherParam.officeTmplId && !this.options.otherParam.eln) {
                await this.getDataSource()
                    .done(result => {
                        this.options.dataSourceData = result;
                        this.options.templId = this.options.otherParam.officeTmplId;
                    })
                    .fail(() => {});
            }
        }
        this.iframe.src = `${Gikam.IFM_CONTEXT}/static/plugin/sunwayoffice/office.html`;

        if (!fileDetailCompleted) return;

        let callbackUrl =
            this.options.callbackUrl ||
            `${Gikam.sunwayoffice.downloadUrl}/core/module/item/files/${this.options.fileId}/action/sunwayoffice-invoke`;

        // Word/Excel/ELN编辑时回调地址， 其他情况为上述默认地址
        if (this.options.otherParam && this.options.otherParam.officeTmplId) {
            if (this.options.otherParam && this.options.otherParam.eln) {
                // ELN模板生成的报告编辑
                callbackUrl = `${callbackUrl}?service=coreOfficeELNInvokeConsumer&tmplId=${this.options.otherParam.officeTmplId}`;
            } else {
                // Word/Excel/ELN报告模板编辑
                callbackUrl = `${callbackUrl}?service=coreOfficeTmplInvokeConsumer&tmplId=${this.options.otherParam.officeTmplId}`;
            }
        }

        //配置office编辑器所需参数
        this.editorOfficeObj = {
            //文档参数
            document: {
                //文件类型
                fileType: this.options.fileType,
                //文件ID
                key: key,
                //文件名称
                title: this.options.fileName,
                //文件下载地址
                url: Gikam.getFileContextUrl(this.options.fileUrl, 'sunwayoffice'),
                permissions: {
                    download: this.options.otherParam.download, // 下载按钮，默认true，传入false会隐藏
                    print: this.options.otherParam.print, //打印按钮，默认true，传入false会隐藏
                    comment: this.options.otherParam.comment === false ? false : true, // 传入false禁止批注功能
                    printLimitNum: this.options.otherParam.printLimitNum, //打印次数限制
                    printCount: this.options.otherParam.printCount //已经打印的次数
                }
            },
            //编辑器参数
            editorConfig: {
                lang: Gikam.locale === 'en-US' ? 'en' : 'zh-CN',
                // 传了回调的保存接口后，直接调用，不传就用核心的
                callbackUrl: callbackUrl,
                ///编辑模式（view : 查看 | edit : 编辑）
                mode: this.options.mode,
                // 工作流程对应的文本控制标签, 值是数组, 如果不传值, 默认都能修改, 都不能修改则对应的是mode = 'view'预览模式
                workflow: this.options.otherParam.workflow || [],
                dataSourceData: this.options.dataSourceData || {}, // 数据源数据
                customization: {
                    spellcheck: false, // 不开启拼写检查
                    forcesave: true, // 默认配置, 修改慎重修改, 此处是把office.html文件中的默认值给移到此处
                    help: false, // 默认配置, 修改慎重修改, 此处是把office.html文件中的默认值给移到此处
                    customer: this.options.customer || {},
                    logo: this.options.logo || {},
                    trackChanges: Gikam.isTrue(this.options.otherParam.trackChanges) ? true : false,
                    trackChangesOperation: Gikam.isFalse(this.options.otherParam.trackChangesOperation) ? false : true
                },
                menu: this.options.menu || [],
                waterMark: Gikam.isTrue(Gikam.sunwayoffice.watermarkEnable) ? true : false,
                waterMarkConfig: this.options.otherParam.waterMarkConfig,
                downloadCountUrl:
                    `${Gikam.IFM_CONTEXT}/core/module/item/files/${this.options.fileId}/logs/action/DOWNLOAD` || '', // 记录下载次数
                printCountUrl:
                    `${Gikam.IFM_CONTEXT}/core/module/item/files/${this.options.fileId}/logs/action/PRINT` || '', // 记录打印次数
                formats: this.options.formats, // 自定义文件-下载为中的文件下载格式
                templId: this.options.templId || ''
            },
            type: type
        };

        // 不需要定制化信息时, 删除定制化信息配置
        if (!needCustomer) {
            delete this.editorOfficeObj.editorConfig.customization.customer;
            delete this.editorOfficeObj.editorConfig.customization.logo;
        }

        this.editorOfficeObj.editorConfig.user = {};
        if (window.workspace.user.userName) {
            this.editorOfficeObj.editorConfig.user.name = window.workspace.user.userName;
        }
        if (window.workspace.user.id) {
            this.editorOfficeObj.editorConfig.user.id = window.workspace.user.id;
        }
        // 预览模式下且能添加评论配置
        if (this.options.mode === 'view' && this.options.otherParam.comment !== false) {
            this.editorOfficeObj.document.permissions.edit = false;
            this.editorOfficeObj.editorConfig.mode = 'edit';
        }
        // 预览模式下，不能添加评论配置
        if (this.options.mode === 'view' && this.options.otherParam.comment === false) {
            this.editorOfficeObj.editorConfig.callbackUrl = '';
        }
        // 只能修改文本控制区域的内容， 且不可删除文本控制区域
        if (this.options.otherParam.fillForms === true) {
            this.editorOfficeObj.document.permissions.edit = false;
            this.editorOfficeObj.document.permissions.comment = false;
            this.editorOfficeObj.editorConfig.mode = 'edit';
            this.editorOfficeObj.document.permissions.fillForms = true;
        }
        this.options.viewType !== 'blank' && this.loadDocEditor();
    }

    /**
     * @description 获取文件信息
     * @private
     * @memberof EditorOffice
     */
    getFileUrl = function() {
        return Gikam.getJson(`${Gikam.IFM_CONTEXT}/core/module/item/files/${this.options.fileId}`);
    };

    /**
     * @description 获取定制化的logo信息及关于里面的公司信息
     * @private
     * @memberof EditorOffice
     */
    getInfo = function() {
        return Gikam.getJson(`${Gikam.IFM_CONTEXT}/secure/core/module/office/customizations/configs`);
    };

    /**
     * @description 获取toolbar数据
     * @private
     * @memberof EditorOffice
     */
    getToolbarMenu = function(ext) {
        const officeCategory = this.convertExt2Type(ext);
        if (officeCategory === false) {
            return;
        }
        const url = Gikam.printf(
            `${Gikam.IFM_CONTEXT}/secure/core/module/office/toolbars/queries/category/{officeCategory}`,
            {
                officeCategory: officeCategory.toString().toLowerCase()
            }
        );
        return Gikam.post(url);
    };

    /**
     * 获取数据源数据
     * @param {*} officeTmplId
     * @private
     * @memberof EditorOffice
     */
    getDataSource = function() {
        const url = `${Gikam.IFM_CONTEXT}/secure/core/module/office/dses/tree/queries`;
        return Gikam.getJson(url);
    };

    /**
     * @description 根据文件类型,判断应该获取WORD还是EXCEL的数据
     * @param {*} ext
     * @private
     * @memberof EditorOffice
     */
    convertExt2Type = function(ext) {
        const supportType = [
            {
                code: 'WORD',
                typeArray: [
                    'doc',
                    'docm',
                    'docx',
                    'dot',
                    'dotm',
                    'dotx',
                    'epub',
                    'fodt',
                    'htm',
                    'html',
                    'mht',
                    'odt',
                    'ott',
                    'pdf',
                    'rtf',
                    'txt',
                    'djvu',
                    'xps'
                ]
            },
            {
                code: 'EXCEL',
                typeArray: ['csv', 'fods', 'ods', 'ots', 'xls', 'xlsm', 'xlsx', 'xlt', 'xltm', 'xltx']
            },
            {
                code: 'PRESENTATION',
                typeArray: ['fodp', 'odp', 'otp', 'pot', 'potm', 'potx', 'pps', 'ppsm', 'ppsx', 'ppt', 'pptm', 'pptx']
            }
        ];
        for (let i = 0; i < supportType.length; i++) {
            const typeArray = supportType[i].typeArray;
            if (typeArray.includes(ext)) {
                return supportType[i].code;
            }
        }
        // 不做处理
        return false;
    };

    /**
     * @description 根据文件类型, 获取拿到数据库中配置的“下载为”的文件格式
     * @param {*} formats
     * @param {*} fileType
     * @memberof EditorOffice
     */
    getFormats = function(formats, fileType) {
        const data = JSON.parse(formats);
        const type = this.convertExt2Type(fileType);
        if (type === 'WORD') {
            return (data.word && data.word.split(',')) || [];
        }

        if (type === 'EXCEL') {
            return (data.excel && data.excel.split(',')) || [];
        }

        return [];
    };

    /**
     * @description 格式化返回的toolbar数据
     * @param {*} data
     * @private
     * @memberof EditorOffice
     */
    formatData = function(data) {
        let result = [];
        for (let i = 0; i < data.length; i++) {
            let item = {};
            let subMenu = {};
            let parent = data[i];
            let children = parent.toolbarLineList;
            for (let j = 0; j < children.length; j++) {
                subMenu[children[j].menuCode] = !Number(children[j].lastSuspendedFlag);
            }
            item[parent.menuCategoryCode] = !Number(parent.lastSuspendedFlag);
            item.subMenu = subMenu;
            result.push(item);
        }
        return result;
    };

    /**
     * @description 初始化iframe容器
     * @private
     * @memberof sunwayoffice
     */
    initEditorContainer() {
        const excludeUrlArray = [
            'https://localhost',
            'http://localhost',
            'localhost',
            'https://127.0.0.1',
            'http://127.0.0.1',
            '127.0.0.1'
        ];
        const valid = excludeUrlArray.some(item => Gikam.sunwayoffice.downloadUrl.startsWith(item));
        if (valid) {
            Gikam.alert('GIKAM.OFFICE.TIP.DO_NOT_USE_LOCALHOST');
            return;
        }
        if (this.options.viewType === 'modal') {
            let tempMeta = document.getElementsByTagName('meta')[1];
            tempMeta.setAttribute(
                'content',
                'width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, minimal-ui'
            );
            let _this = this;
            this.modal = Gikam.create('modal', {
                title: I18N.prop('office.file.edit.modal.title'),
                src: 'about:blank',
                isModal: Gikam.isFalse(_this.options.popupIsModal) ? false : true,
                onAfterClose: function() {
                    tempMeta.setAttribute(
                        'content',
                        'width=device-width, initial-scale=0.6, maximum-scale=0.6, minimum-scale=0.6, user-scalable=no'
                    );
                    if (typeof _this.options.onAfterClose === 'function') {
                        _this.options.onAfterClose();
                    }
                }
            });

            this.iframe = this.modal.getIframeDom();
        } else if (this.options.viewType === 'iframe') {
            this.iframe = this.options.iframe;
        } else if (this.options.viewType === 'blank') {
            this.iframe = window.open(`${location.href}static/plugin/sunwayoffice/office.html`);
            this.iframe.onload = async () => {
                this.iframe.document.title = this.options.fileName;
                await this.initParams();
                this.iframe.initDocEditorPage(this.serverUrl, this.editorOfficeObj, this.officeHook);
            };
        }

        if (!this.iframe) {
            this.destroy();
            Gikam.alert(I18N.prop('office.load.iframe.error.tip'));
        }
    }

    /**
     * @description 初始化sunwayoffice自用钩子函数
     * @private
     * @memberof sunwayoffice
     */
    initOfficeHookFunction() {
        let _this = this;
        //组件错误时调用
        let errorFunc = function(event) {
            if (event.data.errorCode == -4) {
                Gikam.alert(I18N.prop(`office.file.download.error.tip`));
            }
            Gikam.error(
                `${I18N.prop('office.error.code')}: ${event.data.errorCode} -------- ${I18N.prop(
                    'office.error.message'
                )}: ${event.data.errorDescription}`
            );
            _this.trigger('error', event);
            _this.destroy();
        };

        //组件警告时调用
        let warningFunc = function(event) {
            if (!event.data.warningCode) return;
            Gikam.warn(
                `${I18N.prop('office.warning.code')}: ${event.data.warningCode} -------- ${I18N.prop(
                    'office.waring.message'
                )}: ${event.data.warningDescription}`
            );
            _this.trigger('warn', event);
        };

        //无法获取sunwayoffice服务器api.js时调用
        let onScriptError = function() {
            Gikam.alert(I18N.prop(`office.load.iframe.error.tip`));
            _this.trigger('error');
            _this.destroy();
        };

        //将应用程序加载到浏览器时调用的函数
        let onAppReady = Gikam.emptyFunction;
        //将文档加载到文档编辑器中时调用的函数
        let onDocumentReady = Gikam.emptyFunction;
        //修改文档时调用的函数, event.data为true时, 表示正在保存, 为false时, 表示保存完毕
        // 一次修改会触发两次此方法
        let onDocumentStateChange = function(event) {
            _this.saved = !event.data;
        };

        this.officeHook = {
            onError: errorFunc,
            onWarning: warningFunc,
            onScriptError: onScriptError,
            onAppReady: onAppReady,
            onDocumentReady: onDocumentReady,
            onDocumentStateChange: onDocumentStateChange
        };
    }

    init() {
        this.initOfficeHookFunction();
        this.initEditorContainer();
        this.initParams();
    }

    /**
     * @description 关闭编辑页面
     * @private
     * @memberof sunwayoffice
     */
    destroy() {
        if (this.modal) this.modal.close();
        if (this.iframe) this.iframe.src = '';
    }
}
