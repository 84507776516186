import Gikam from '../../core/gikam-core.js';
import SunwayConfirm from './confirm.vue';
import Base from '../base.js';
import Vue from 'vue';

let defaultOptions = {
    message: void 0,
    title: '请确认',
    subTitle: void 0,
    onYesClick: Gikam.emptyFunction,
    onNoClick: Gikam.emptyFunction,
    yesText: void 0,
    noText: void 0,
    rootClass: null
};

export default class Confirm extends Base {
    constructor(props) {
        super(props);
        this.initialize(props, defaultOptions).init();
    }

    createModel() {
        const options = this.options;
        return new Vue({
            el: Gikam.createDom('div', document.body),
            render() {
                return <SunwayConfirm options={options}></SunwayConfirm>;
            }
        });
    }

    init() {
        this.createModel();
    }
}
