//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Dropdown from '@/gikam/js/components/template/basePopPanel/basePopPanel';
import SunwayWindow from '@/sunway/window/window';
export default {
  components: {
    Dropdown: Dropdown,
    SunwayWindow: SunwayWindow
  },
  props: {
    target: HTMLElement,
    overlay: Object
  }
};