<template>
    <ScriptInputInline
        v-if="mode === 'inline'"
        :options="options"
        :propValue="propValue"
        :propReadonly="readonly"
        :validateArg="validateArg"
        :scriptIconLayout="scriptIconLayout"
        :toolbarLayout="toolbarLayout"
        @change="editorChangeHandle"
    />
    <dropdown
        v-else-if="mode === 'dropdown'"
        :options="dropdownOptions"
        @visibleChange="visibleChangeHandle"
        @beforeClose="beforeCloseHandle"
    >
        <div ref="inputWrapper" :class="inputClass" @click="clickHandle" tabindex="0" @keyup.tab="keyupTabHandle">
            <span v-html="formatterValue(value)" class="sw-script-input-content" ref="input"></span>
            <tooltip v-if="!validateResult" :options="tooltipOptions">
                <span class="validate-error-icon">
                    <icon-info-round />
                </span>
            </tooltip>
        </div>

        <template slot="overlay">
            <div
                class="sw-script-input-overlay"
                :class="{ readonly }"
                @mousewheel.stop
                ref="overlay"
                @mousedown.stop
                @keydown.tab.capture.stop="editorTabKeydownHandle"
            >
                <div class="sw-script-input-overlay-close" @click="closeClickHandle">
                    <icon-close-round />
                </div>
                <vue-editor
                    ref="editor"
                    :editor-toolbar="customToolbar"
                    :disabled="readonly"
                    v-model="value"
                    @click.native.stop
                    @focus="focusHandle"
                    @paste.native="pasteHandle"
                />
            </div>
        </template>
    </dropdown>
    <ScriptInputEditor
        v-else
        :options="options"
        :propValue="propValue"
        :propReadonly="readonly"
        :validateArg="validateArg"
        @change="editorChangeHandle"
    />
</template>

<script>
import Vue from 'vue';
import Dropdown from '@/gikam/js/components/dropdown/Dropdown.vue';
import Tooltip from '@/gikam/js/components/tooltip/Tooltip.vue';
import BaseField from '@/gikam/js/components/template/baseField/baseField.vue';
import Gikam from 'gikam';
import ScriptInputEditor from './script-input-editor.vue';
import { mixin } from './js/script-input-mixin';
import Vue2Editor from 'vue2-editor';
import ScriptInputInline from './script-input-inline.vue';

Vue.use(Vue2Editor);

// 全局参数
const defaultOptions = {
    // 取值dropdown|inline|relax
    mode: 'dropdown',
    valueFormatter: null,
    pasteWithStyle: false,
    // 上下标箭头排列方式,vertical、horizontal
    scriptIconLayout: 'vertical',
    // 工具栏位置
    toolbarLayout: 'left'
};

export default {
    extends: BaseField,

    mixins: [mixin],

    components: { Dropdown, Tooltip, ScriptInputEditor, ScriptInputInline },

    props: {
        options: Object,
        rowIndex: Number,
        propValue: String,
        propInvisible: Boolean,
        validateArg: Object
    },

    data() {
        return {
            customToolbar: [[{ script: 'super' }, { script: 'sub' }]],
            dropdownOptions: { trigger: ['click'] },
            value: this.getVueEditorValue(this.propValue),
            isTextChange: false,
            active: false,
            readonly: this.propReadonly ?? this.options.readonly,
            invisible: this.propInvisible,
            validateResult: true,
            field: this.options.field,
            focusValue: null,
            scriptIconLayout: this.options.scriptIconLayout ?? defaultOptions.scriptIconLayout,
            toolbarLayout: this.options.toolbarLayout ?? defaultOptions.toolbarLayout
        };
    },

    computed: {
        inputClass() {
            const classList = ['sw-script-input'];
            this.active && classList.push('active');
            this.readonly && classList.push('readonly');
            this.invisible && classList.push('invisible');
            !this.validateResult && classList.push('validate-error');
            return classList;
        },

        tooltipOptions() {
            return {
                placement: 'bottom',
                title: this.error.length === 0 ? null : Gikam.propI18N(this.error[0].message)
            };
        },

        dropdown() {
            return this.options.dropdown ?? true;
        },

        mode() {
            const mode = this.options.mode ?? defaultOptions.mode;
            if (!['inline', 'dropdown', 'relax'].includes(mode)) {
                return 'dropdown';
            }
            return mode;
        }
    },

    watch: {
        propValue(value) {
            this.value = this.getVueEditorValue(value);
        }
    },

    methods: {
        async visibleChangeHandle(visible) {
            this.active = visible;
            if (visible == false) {
                if (this.isTextChange) {
                    this.validateResult = this.validate();
                    this.isTextChange = false;
                    if (this.validateResult) {
                        this.$emit('change', this.options.field, this.formatterValue(this.value), this.rowIndex);
                    }
                }
                document.removeEventListener('mousewheel', this.wheelHandle);
            } else {
                document.addEventListener('mousewheel', this.wheelHandle);
                this.resizeDropdownWidth();
                await this.$nextTick();
                this.$refs.editor.quill.focus();
            }
        },

        async resizeDropdownWidth() {
            await this.$nextTick();
            setTimeout(() => {
                const dropdown = this.$refs.overlay.parentNode;
                const dopdownMinWidth = parseFloat(dropdown.style.minWidth);
                dopdownMinWidth > 350 && (dropdown.style.minWidth = '350px');
            });
        },

        changeHandle() {
            this.isTextChange = true;
        },

        triggerDocumentClick() {
            document.dispatchEvent(new Event('mousedown'));
        },

        wheelHandle() {
            this.triggerDocumentClick();
        },

        clickHandle() {
            this.invisible ? this.$emit('click', this) : this.$emit('saveCoordinate');
        },

        closeClickHandle() {
            this.triggerDocumentClick();
        },

        focusHandle() {
            this.focusValue = this.value;
        },

        beforeCloseHandle() {
            if (this.focusValue === null) {
                return;
            }
            if (this.value !== this.focusValue) {
                this.changeHandle();
            }
            this.focusValue = null;
        },

        editorChangeHandle(value) {
            this.$emit('change', this.options.field, this.formatterValue(value), this.rowIndex);
        },

        formatterValue(value) {
            let result = value;
            if (Gikam.isNotEmpty(value) && value.match(/<p(?:(?!<\/p>).|\n)*?>/gm).length === 1) {
                result = value.replace(/<p(?:(?!<\/p>).|\n)*?>|<\/p>/g, '');
            }
            // 自定制组件的值
            const valueFormatter = this.options.valueFormatter || defaultOptions.valueFormatter;
            if (valueFormatter) {
                result = valueFormatter(result, this.options);
            }
            return result;
        },

        // 如果值没有以<p>开头，在值外层包裹<p>,因为VueEditor的值都是以<p>包裹
        getVueEditorValue(value) {
            if (!value) {
                return '';
            }
            const val = value.startsWith('<p>')
                ? value
                : `<p>${value.split(new RegExp('\\<br\\s*\\/?\\>')).join('</p><p>')}</p>`;
            return val;
        },

        override(options) {
            Gikam.extend(defaultOptions, options);
        },

        keyupTabHandle(event) {
            event.target.click();
        },

        editorTabKeydownHandle() {
            this.closeClickHandle();
            this.$refs.inputWrapper.focus();
        },

        pasteHandle(event) {
            const selection = window.getSelection();
            if (!selection.rangeCount) return false;
            const clipboard = event.clipboardData || window.clipboardData;
            let paste = (event.clipboardData || window.clipboardData).getData('text');
            if (/<script.*?>(.*?)<\/script>/.test(paste)) {
                return;
            }
            let html = clipboard.getData('text/html');
            if (html && /<(sub|sup).*?>(.*?)<\/(sub|sup)>/.test(html)) {
                let match = html.match(/<!--StartFragment-->(.*?)<!--EndFragment-->/);
                if (match) {
                    const text = html.match(/<!--StartFragment-->(.*?)<!--EndFragment-->/)[1];
                    if (!/&lt;(sub|sup).*?&gt;/.test(text)) {
                        paste = text;
                    }
                }
            }
            selection.deleteFromDocument();
            const dom = selection.getRangeAt(0).createContextualFragment(paste);
            selection.getRangeAt(0).insertNode(dom);
            event.preventDefault();
            event.stopPropagation();
        }
    }
};
</script>

<style lang="scss" scoped>
@import './css/scriptInput.scss';
</style>
