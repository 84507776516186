<template>
    <dropdown-menu :options="{ trigger: ['hover', 'click'], getPopupContainer: getPopupContainer }">
        <div class="general-group-container">
            <general-group color="#1597a5" />
        </div>
        <general-button-panel
            slot="overlay"
            :generalButtonGroup="generalButtons"
            :propFilter="filter"
            @generalButtonClick="generalClickHandle"
        ></general-button-panel>
    </dropdown-menu>
</template>

<script>
import Gikam from 'gikam';
import GridConfig from '../gridConfig.js';
import DropdownMenu from '@/gikam/js/components/dropdown/DropdownMenu.vue';
import GeneralButtonPanel from './generalButtonPanel';
import { mapState } from 'vuex';

export default {
    components: {
        DropdownMenu,
        GeneralButtonPanel
    },

    data() {
        return {
            timer: null,
            enterButton: false
        };
    },

    computed: {
        ...mapState(['grid']),

        generalButtons() {
            let { generalButtonGroup } = this.grid.options;
            if (generalButtonGroup === false) {
                return [];
            }
            const buttons = Gikam.isEmpty(generalButtonGroup)
                ? ['refresh', 'export', 'generalQuery', 'config']
                : generalButtonGroup;
            return buttons;
        },

        filter() {
            return this.grid.options.filter;
        }
    },

    methods: {
        generalClickHandle(name) {
            if (name === 'search') {
                this.grid.options.filterOpen = !this.grid.options.filterOpen;
            } else if (name === 'clear') {
                this.grid.cleanHeadRequestData();
                this.grid.refresh();
            } else if (name === 'refresh') {
                this.grid.refresh();
            } else if (name === 'export') {
                this.grid.exportExcel(null, null, true);
            } else if (name === 'config') {
                new GridConfig(this.$store);
            } else if (name === 'columnResize') {
                this.grid._addMask();
            } else if (name === 'generalQuery') {
                if (this.grid.options.advancedSearch.mode === 'top') {
                    this.grid.model.$children[0].canShowGeneralQuery = !this.grid.model.$children[0]
                        .canShowGeneralQuery;
                } else {
                    this.grid.options.genericQuery = !this.grid.options.genericQuery;
                }
            }
        },

        getPopupContainer(target) {
            return target.parentNode;
        }
    }
};
</script>

<style scoped lang="scss">
.general-group-container {
    width: 24px;
    height: 24px;
    padding: 2px 4px 4px 4px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
}
</style>
