import Gikam from 'gikam';
import Vue from 'vue';
import Base from '@/gikam/js/components/base';

/** 
 * items取值:{
     text: '节点一';
     value:'节点值';
     status : "finish" | "process" |"next" | "wait" |
    }
* */
let defaultOptions = {
    id: null,
    items: [],
    onItemClick: null,
    url: null,
    requestData: null
};

export default class Steps extends Base {
    constructor(props) {
        super('Steps');
        this.initialize(props, defaultOptions).init(props);
    }

    createModel() {
        const options = this.options;
        this.model = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            provide: { steps: this },
            components: { steps: () => import('../vue/steps.vue') },
            data() {
                return { options };
            },
            render() {
                return <steps ref="vm" options={this.options}></steps>;
            }
        });
    }

    init() {
        this.createModel();
        this.initData();
    }

    /**
     * @public
     * @description 刷新列表
     * @param {*} [items=[]]
     * @memberof Steps
     */
    refreshItems(items = []) {
        this.options.items = items;
    }

    /**
     * @private
     * @description 初始化数据
     * @memberof Steps
     */
    initData() {
        if (!this.options.url) {
            return;
        }
        Gikam.post(this.options.url, Gikam.getJsonWrapper({ f: this.options.requestData })).done(data =>
            this.refreshItems(data.rows)
        );
    }
}
