<template>
    <div class="year-picker" @click.stop @mousedown.stop>
        <div class="ym-select">
            <div class="year">
                <button :title="$i18n('year.lastYear')" v-on:click="setLastYear()" class="last-year-btn"></button>
                <div class="ym-container">
                    <input class="year-input-first" v-model="firstYear" autocomplete="off" />{{ $i18n('year.year') }} -
                    <input class="year-input-last" v-model="lastYear" autocomplete="off" />{{ $i18n('year.year') }}
                </div>
                <button :title="$i18n('year.nextYear')" v-on:click="setNextYear()" class="next-year-btn"></button>
            </div>
        </div>

        <div class="year-select-container">
            <table>
                <tbody>
                    <tr v-for="(yearGroup, index) in allYears" :key="index">
                        <td
                            v-for="year in yearGroup"
                            :key="year"
                            :class="[
                                { 'now-year': nowYear == year },
                                { lastSelectedYear: chooseYears == year },
                                { otherRangeYears: otherRangeYears(year) }
                            ]"
                            :value="year"
                            @click="chooseYear"
                            @dblclick="bindYearDblclickEvent"
                        >
                            <div class="text">
                                {{ year }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="select-now">
            <div class="btn-container">
                <div class="clean-btn" @click="clean">{{ $i18n('year.clean') }}</div>
                <div class="select-now-btn" v-on:click="selectNow()">{{ $i18n('year.now') }}</div>
                <div class="confirm-btn" @click="confirm">{{ $i18n('year.confirm') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import Gikam from '../../../../core/gikam-core';
import jQuery from 'jquery';

export default {
    name: 'yearPicker',
    props: {
        years: void 0,
        nowYear: void 0,
        isChooseYears: void 0,
        yearRange: []
    },

    inject: {
        yearInput: {
            default: null
        }
    },

    data() {
        return {
            Gikam: Gikam,
            year: void 0,
            chooseYears: void 0,
            firstYear: void 0,
            lastYear: void 0,
            allYears: [],
            cellYears: [],
            initYear: Gikam.DateUtils.formatter(this.years, 'yyyy-MM-dd')
        };
    },

    mounted() {
        this.refreshAll();
    },

    computed: {},

    methods: {
        // 时间双击
        bindYearDblclickEvent(e) {
            let activeDom = e.target.tagName === 'TD' ? e.target : e.target.parentNode;
            if (this.otherRangeYears(activeDom.getAttribute('value'))) {
                return;
            }
            this.chooseYear(e);
            this.confirm();
        },

        // 创建年选择组件时，对年选择组件进行定位
        pickerPosition() {
            let $yearInput = jQuery(this.yearInput.$refs['input']);
            let position = {
                position: 'absolute',
                top: $yearInput.offset().top + $yearInput.outerHeight() + 6
            };

            if ($yearInput.offset().left + jQuery(this.$el).width() < window.top.document.body.clientWidth) {
                position.left = $yearInput.offset().left;
            } else {
                position.right = 8;
            }
            if (window !== window.top) {
                const iframeRect = window.top.document.body
                    .querySelector(`[name='${window.name}']`)
                    .getBoundingClientRect();
                position.right && (position.right += iframeRect.right);
                position.left && (position.left += iframeRect.left);
                position.top += iframeRect.top;
            }
            let $dom = jQuery(this.$el).css(position);

            if ($dom.outerHeight() + $dom.offset().top > jQuery(window.top).height()) {
                $dom.addClass('top').css({
                    top: $yearInput.offset().top - $dom.outerHeight() - 8
                });
            }
        },

        // 根据年的区间，创建年份表
        creatYears() {
            let years = parseInt(this.year) - 10;
            let cellYearsArray = [];
            for (let i = 0; i < 4; i++) {
                for (let j = 1; j <= 4; j++) {
                    cellYearsArray.push(years);
                    years++;
                }
                this.$set(this.allYears, i, cellYearsArray);
                cellYearsArray = [];
            }
            this.cellYears = [];
        },

        //判断是否在年份区间之内
        otherRangeYears(year) {
            if (!this.yearRange) {
                return false;
            }
            if (this.yearRange[0] && this.yearRange[1]) {
                if (year >= this.yearRange[0] && year <= this.yearRange[1]) {
                    return false;
                }
                return true;
            } else if (!this.yearRange[0] && this.yearRange[1]) {
                if (year <= this.yearRange[1]) {
                    return false;
                }
                return true;
            } else if (this.yearRange[0] && !this.yearRange[1]) {
                if (year >= this.yearRange[0]) {
                    return false;
                }
                return true;
            }
        },

        // 判断是否已选择年份
        isChoosed() {
            let isChoosed = false;
            isChoosed = !isNaN(this.isChooseYears);
            return isChoosed;
        },

        // 刷新年份表
        refresh() {
            this.year = Gikam.DateUtils.formatter(this.years, 'yyyy');
            this.firstYear = parseInt(this.year) - 10;
            this.lastYear = parseInt(this.year) + 5;
            this.creatYears();
        },

        // 渲染时创建年份区间
        refreshAll() {
            if (this.isChoosed()) {
                this.year = Gikam.DateUtils.formatter(this.isChooseYears, 'yyyy');
                this.chooseYears = Gikam.DateUtils.formatter(this.isChooseYears, 'yyyy');
            } else {
                this.year = Gikam.DateUtils.formatter(this.years, 'yyyy');
            }
            this.firstYear = parseInt(this.year) - 10;
            this.lastYear = parseInt(this.year) + 5;
            this.creatYears();
            this.$nextTick(() => {
                this.pickerPosition();
            });
        },

        // 上一个年份区间
        setLastYear() {
            this.years.setFullYear(parseInt(this.year) - 16);
            this.refresh();
        },

        // 下一个年份区间
        setNextYear() {
            this.years.setFullYear(parseInt(this.year) + 16);
            this.refresh();
        },

        // 选择今年
        selectNow() {
            const date = new Date();
            if (this.otherRangeYears(Gikam.DateUtils.formatter(date, 'yyyy'))) {
                return;
            }
            this.activeYear = Gikam.DateUtils.formatter(date, 'yyyy');
            this.confirm();
        },

        // 选择年份
        chooseYear(e) {
            let activeDom = e.target.tagName === 'TD' ? e.target : e.target.parentNode;
            if (this.otherRangeYears(activeDom.getAttribute('value'))) {
                return;
            }
            let activeCell = this.$el.querySelector('td.active');
            if (activeCell) {
                activeCell.classList.remove('active');
            }
            activeCell = activeDom;
            activeCell.classList.add('active');
            this.activeYear = activeCell.getAttribute('value');
        },

        // 确认
        confirm() {
            let value = '';
            if (!this.activeYear) {
                if (this.isChoosed()) {
                    value = Gikam.DateUtils.formatter(this.isChooseYears, 'yyyy');
                }
            } else {
                value = this.activeYear;
            }
            this.yearInput.value = value;
            this.yearInput.focus(true);
            this.destroy();
        },

        // 清空
        clean() {
            this.yearInput.value = '';
            this.yearInput.focus(true);
            this.destroy();
        },

        // 销毁年选择组件
        destroy() {
            this.yearInput.cleanYearPicker();
        }
    }
};
</script>

<style scoped>
@keyframes year-slide-bottom {
    from {
        transform: translate3d(0, 20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@keyframes year-slide-top {
    from {
        transform: translate3d(0, -20px, 0);
        opacity: 0.3;
    }
    to {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

button::-moz-focus-inner {
    border: none;
}

.year-picker {
    background-color: #fff;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    font-family: 'Microsoft YaHei', serif;
    width: 248px;
    position: absolute;
    z-index: 10;
    animation: year-slide-bottom 0.3s;
    box-shadow: 0 0 2px 2px #eeeeee;
    color: #000;
}

.year-picker.top {
    animation: year-slide-top 0.3s;
}

.year-picker:before {
    content: '';
    position: absolute;
    top: -7px;
    left: 30px;
    width: 10px;
    height: 10px;
    background-color: #fff;
    transform: rotate(45deg);
    border: 1px solid #e5e5e5;
    border-bottom: none;
    border-right: none;
}

.year-picker.top:before {
    bottom: -7px;
    top: auto;
    transform: rotate(-135deg);
}

.year-picker > .ym-select {
    height: 36px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    justify-content: space-between;
    padding: 0 16px;
}

.year-picker > .ym-select > .year {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.year-picker > .ym-select button {
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    background-repeat: no-repeat;
    height: 98%;
    padding: 0;
    margin-top: 1px;
}

.last-year-btn {
    background-image: url('../../../../../img/date/left.png');
    width: 24px;
    background-position: 0 center;
}

.next-year-btn {
    background-image: url('../../../../../img/date/right.png');
    width: 24px;
    background-position: 20px center;
}

.ym-container {
    display: flex;
    align-items: center;
    font-size: 14px;
}

.year-picker > .ym-select .ym-container > input {
    height: 30px;
    border: none;
    font-size: 14px;
    color: #000;
    text-align: center;
    font-family: 'Microsoft YaHei', serif;
}

.year-input-first {
    width: 38px;
}

.year-input-last {
    width: 38px;
}

.year-picker > .ym-select > .ym-container > input:focus {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}

.year-picker > .year-select-container {
    font-size: 12px;
    padding: 0;
}

.year-picker > .year-select-container > table {
    width: 100%;
}

.year-picker > .year-select-container > table thead td {
    text-align: center;
    color: #000;
    padding: 10px 0 16px 0;
}

.year-picker > .year-select-container > table > tbody > tr > td {
    width: 25%;
    cursor: pointer;
    font-size: 12px;
    vertical-align: middle;
    text-align: center;
    padding: 10px 0px;
}

.year-picker > .year-select-container > table > tbody > tr > td .text {
    width: 48px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    transition: all 0.3s;
    margin: 0 auto;
}

.year-picker > .year-select-container > table > tbody > tr > td.otherRangeYears .text {
    background-color: #ffffff !important;
    color: rgba(0, 0, 0, 0.5) !important;
}

.year-picker > .year-select-container > table > tbody > tr > td.now-year .text {
    background-color: #d6d6d6 !important;
    color: #000000 !important;
}

.year-picker > .year-select-container > table > tbody > tr > td.lastSelectedYear .text {
    border: 2px solid #007aff;
    line-height: 20px;
}

.year-picker > .year-select-container > table > tbody > tr > td.active .text {
    background-color: #007aff !important;
    color: #fff !important;
}

.year-picker > .year-select-container > table > tbody > tr > td:hover .text {
    background-color: #007aff;
    color: #fff;
}

.year-picker > .select-now {
    border-top: 1px solid rgba(217, 217, 217, 0.5);
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: flex-end;
}

.year-picker > .select-now > a {
    color: #007aff;
    font-size: 12px;
    text-decoration: none;
}

.confirm-btn,
.clean-btn,
.select-now-btn {
    height: 24px;
    width: 52px;
    background-color: #007aff;
    color: #fff;
    border: 1px solid #d9d9d9;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
}

.clean-btn,
.select-now-btn {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    margin-right: 10px;
    color: rgba(0, 0, 0, 0.65);
}

.year-picker .btn-container {
    display: flex;
    justify-content: flex-end;
    padding: 6px 0;
}
</style>
