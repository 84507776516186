<template>
    <tr class="total-row" v-if="sumRowShow">
        <td :colspan="options.sum.endIndex + 1">
            <span class="total-row-cell-sum">{{ $i18n('grid.totalRow') }}</span>
        </td>
        <td v-for="field in effectiveColumns" :key="field.field">
            <span class="total-row-cell">
                {{ getTotalText(field) }}
            </span>
        </td>
    </tr>
</template>

<script>
import { mapState } from 'vuex';
import Gikam from 'gikam';

export default {
    props: {
        options: Object,
        loading: Boolean
    },

    computed: {
        ...mapState(['columns', 'totalMap']),

        effectiveColumns() {
            return this.columns
                .filter(({ visible }) => visible === undefined || visible === '1' || visible === true)
                .slice(this.options.sum.endIndex + 1);
        },

        sumRowShow() {
            if (Gikam.isEmpty(this.options.data) || this.loading) {
                return false;
            }
            const { fields, nonNumberFields } = this.options.sum;
            return Gikam.isNotEmpty(fields) || Gikam.isNotEmpty(nonNumberFields);
        }
    },

    methods: {
        getTotalText(fieldOptions) {
            const fieldName = fieldOptions.field;
            return this.totalMap[fieldName] ?? this.totalMap[fieldName.replace('ext$.', '').toUpperCase()] ?? '';
        }
    }
};
</script>

<style lang="scss" scoped>
tr {
    height: 48px;
    > td {
        padding-bottom: 16px !important;
        height: 48px;
        > .total-row-cell-sum {
            padding-right: 8px;
            display: block;
            text-align: right;
        }

        > .total-row-cell {
            padding-left: 8px;
        }
    }
}
</style>
