<template>
    <div
        :id="options.id"
        class="icons-panel-cont"
        :style="{ width: options.width + 'px', height: options.height + 'px' }"
    >
        <scroll>
            <div class="icons-panel">
                <div
                    class="item"
                    :class="{ active: activeNode && activeNode.id === node.id }"
                    v-for="node in iconsList"
                    :key="node.id"
                    @click="activeIcon(node)"
                >
                    <div class="checkbox"><div class="round"></div></div>
                    <div class="icons-container">
                        <div
                            v-if="!options.noRequest"
                            class="icons"
                            :style="{ backgroundImage: node.src ? 'url(' + node.src + ')' : undefined }"
                        ></div>
                        <div v-else-if="options.noRequest" class="icons">
                            <i :is="node.src" class="no-request-svg"></i>
                        </div>
                    </div>
                </div>
            </div>
        </scroll>
    </div>
</template>
<script>
import Gikam from '../../../core/gikam-core';
export default {
    props: {
        options: Object
    },

    data() {
        return {
            iconsList: this.options.data, // 图标数据
            activeNode: null // 选中的图标
        };
    },

    watch: {
        'options.data'(list) {
            this.iconsList = list;
        }
    },

    methods: {
        // 背景图
        showBgIcon(node) {
            let bg = void 0;
            node.src && (bg = `url(${node.src})`);
            return bg;
        },

        // 点击选中激活图标
        activeIcon(icon) {
            this.activeNode = icon;
            this.options.onClick && this.options.onClick(icon);
        },

        // 事件选中图标
        activeIconHandle(id) {
            this.activeNode = this.iconsList.filter(item => item.id === id)[0];
            if (this.activeNode) {
                this.activeIcon(this.activeNode);
                return;
            }
            this.cancel();
            this.options.onClick && this.options.onClick(undefined);
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.options.onRendered && this.options.onRendered(this);
        });
    },
    created() {
        if (this.options.noRequest) {
            this.iconsList = Gikam.menuUrlList;
        }
    }
};
</script>
<style scope>
.icons-panel-cont {
    width: 898px;
    height: 100%;
    padding: 0 8px;
    margin: 0 auto;
}

.icons-panel-cont .icons-panel {
    display: inline-block;
    border-top: 1px solid #d9d9d9;
    border-left: 1px solid #d9d9d9;
}

.icons-panel-cont .icons-panel > .item {
    display: inline-block;
    width: 88px;
    height: 88px;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    cursor: pointer;
    position: relative;
}

.icons-panel-cont .icons-panel > .item .checkbox {
    width: 12px;
    height: 12px;
    padding: 2px;
    border: 1px solid #333;
    border-radius: 50%;
    position: absolute;
    top: 8px;
    left: 8px;
}

.icons-panel-cont .icons-panel > .item .checkbox > .round {
    width: 6px;
    height: 6px;
    border-radius: 50%;
}

.icons-panel-cont .icons-panel > .item .icons-container {
    width: 48px;
    height: 48px;
    padding: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -24px;
    margin-left: -24px;
    background-color: #d9d9d9;
    border-radius: 8px;
}

.icons-panel-cont .icons-panel > .item .icons {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

/* 选中样式 */
.icons-panel-cont .icons-panel > .item.active {
    border: 2px solid #007aff;
    position: relative;
    top: -1px;
    left: -1px;
    z-index: 2;
}

.icons-panel-cont .icons-panel > .item.active .checkbox {
    border: 1px solid #007aff;
}

.icons-panel-cont .icons-panel > .item.active .checkbox > .round {
    background-color: #007aff;
}

.no-request-svg {
    color: #007aff;
    font-size: 32px;
}
</style>
