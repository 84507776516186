import NP from 'number-precision';

/**
 * @description 精度数字处理
 * @export
 * @class NumberUtils
 */
export default class NumberUtils {
    /**
     * @description 加
     * @static
     * @param {numType} num1
     * @param {numType} num2
     * @param {...numType[]} others
     * @memberof NumberUtils
     */
    static plus(num1, num2, ...others) {
        return NP.plus(num1, num2, ...others);
    }

    /**
     * @description 减
     * @static
     * @param {numType} num1
     * @param {numType} num2
     * @param {...numType[]} others
     * @returns
     * @memberof NumberUtils
     */
    static minus(num1, num2, ...others) {
        return NP.minus(num1, num2, ...others);
    }

    /**
     * @description 乘
     * @static
     * @param {numType} num1
     * @param {numType} num2
     * @param {...numType[]} others
     * @returns
     * @memberof NumberUtils
     */
    static times(num1, num2, ...others) {
        return NP.times(num1, num2, ...others);
    }

    /**
     * @description 除
     * @static
     * @param {numType} num1
     * @param {numType} num2
     * @param {...numType[]} others
     * @returns
     * @memberof NumberUtils
     */
    static divide(num1, num2, ...others) {
        return NP.divide(num1, num2, ...others);
    }
}
