<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 30 30"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <!-- Generator: Sketch 51.2 (57519) - http://www.bohemiancoding.com/sketch -->
        <title>新增任务</title>
        <desc>Created with Sketch.</desc>
        <defs></defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="#1c838a" fill-rule="evenodd">
            <g id="新增任务-功能展开" transform="translate(-92.000000, -428.000000)" :fill="color" fill-rule="nonzero">
                <g id="新增任务" transform="translate(92.000000, 428.000000)">
                    <path
                        d="M15,0.00650759219 C6.7202603,0.00650759219 0.00650759219,6.7202603 0.00650759219,15 C0.00650759219,23.2797397 6.7202603,29.9934924 15,29.9934924 C23.2797397,29.9934924 29.9934924,23.2797397 29.9934924,15 C29.9934924,6.7202603 23.2797397,0.00650759219 15,0.00650759219 Z M15.0065076,28.0065076 C7.8176489,28.0065076 2.00650759,22.1777032 2.00650759,15.0065076 C2.00650759,7.83531194 7.83531194,2.00650759 15.0065076,2.00650759 C22.1953663,2.00650759 28.0065076,7.83531194 28.0065076,15.0065076 C28.0065076,22.1777032 22.1953663,28.0065076 15.0065076,28.0065076 Z"
                        id="Shape"
                    ></path>
                    <polygon id="Shape" points="8 14 21.9939262 14 21.9939262 15.998698 8 15.998698"></polygon>
                    <polygon id="Shape" points="14 8 15.998698 8 15.998698 21.9939262 14 21.9939262"></polygon>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '15px'
        },
        height: {
            type: String,
            default: '15px'
        },
        color: {
            type: String,
            default: '#ffffff'
        }
    }
};
</script>

<style></style>
