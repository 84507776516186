<template>
    <div
        class="number-input"
        @click="clickHandle"
        @select="focus"
        :class="{
            readonly: readonly,
            'validate-error': !validateResult,
            'has-arrow': hasArrow,
            invisible: invisible,
            'active-number': isFocus
        }"
        @mousedown.stop
        data-user="memoryGlobalField"
        :style="inputStyle"
    >
        <div v-if="readonlyInvisible" class="readonly-text" data-user="memoryGlobalField">
            {{ value }}
        </div>
        <input
            v-if="!readonlyInvisible"
            :name="field"
            v-model.lazy="value"
            @focus="focusHandle"
            @blur="blurHandle($event)"
            @keydown="keydownHandle"
            @keyup="keyupHandle"
            @input="inputHandle"
            ref="input"
            autocomplete="off"
            :style="inputStyle || options.inputStyle"
        />
        <div class="validate-error-icon" v-if="validateResult === false" @click.stop>
            <info-image @mouseenter.stop="errorIconMouseenter" @mouseleave.stop="errorIconMouseleave"></info-image>
        </div>
        <div class="addon" v-if="addon" v-html="$xss(addon)"></div>
        <div class="switch" @mousedown.stop v-if="hasArrow && !readonlyInvisible">
            <div class="up" @click.stop="changeValue(value, 1)"></div>
            <div class="down" @click.stop="changeValue(value, -1)"></div>
        </div>
    </div>
</template>
<script>
import Gikam from '../../../core/gikam-core';
import infoImage from '../img/info.vue';
import BaseField from '../baseField/baseField.vue'; //校验相关的逻辑

export default {
    name: 'textInput',
    props: ['options', 'propValue', 'rowIndex', 'cellIndex', 'propReadonly', 'propInvisible', 'inputStyle'],

    data() {
        return {
            field: this.options.field,
            value: Gikam.isNotEmpty(this.propValue) ? this.propValue : this.options.value ? this.options.value : '',
            hasArrow: this.options.hasArrow,
            readonly: Gikam.isNotEmpty(this.propReadonly) ? this.propReadonly : this.options.readonly,
            invisible: this.propInvisible,

            validateResult: true,
            //保存前一次校验状态，用于当输入框没输入值时，blur时，还原校验状态
            oldValidateResult: null,
            //用于判断用户输没输过值
            isInput: false,
            //保存前一次输入框的值，用于判断用户输入之后的值是否与之前的值一致
            oldValue: this.propValue,
            isFocus: false,
            ignoreKey: {
                8: 'backspace',
                9: 'tab',
                46: 'delete',
                37: 'leftArrow',
                39: 'rightArrow',
                13: 'enter',
                189: 'minus',
                187: 'plus',
                109: 'numberMinus',
                107: 'numberPlus',
                173: 'firefoxMinus',
                //复合键
                86: true, //V
                67: true, //V
                88: true //V
            },
            lastBlurValue: ''
        };
    },

    computed: {
        isInteger() {
            return Gikam.isEmpty(this.options.isInteger) ? false : this.options.isInteger;
        },

        hasRange() {
            return Gikam.isNotEmpty(this.options.range);
        },

        readonlyInvisible() {
            return this.readonly || this.invisible;
        },

        addon() {
            return this.options.addon;
        },

        returnRangeArray() {
            return this.options.range.slice(1, this.options.range.length - 1).split(',');
        },

        returnRangeDelimit() {
            return [
                this.options.range.slice(0, 1),
                this.options.range.slice(this.options.range.length - 1, this.options.range.length)
            ];
        }
    },
    extends: BaseField,
    components: { infoImage },

    watch: {
        value(val) {
            if (val === '') {
                return this.validates(val);
            }
            if (isNaN(this.convertValue(val))) {
                return (this.value = '');
            }
            const vals = String(val).split('.');
            const val_int =
                vals[0].indexOf('-') === 0 && new String(parseInt(vals[0])).indexOf('-') !== 0
                    ? '-' + parseInt(vals[0])
                    : parseInt(vals[0]);
            const val_float = !this.isInteger && vals[1] ? '.' + vals[1] : '';

            const text = val_int + val_float;
            if (val !== text) {
                return (this.value = text);
            }
            if (this.hasRange) {
                this.checkValueFromRange(val);
            } else {
                this.validates(val);
            }
        },

        propValue(val) {
            this.validateResult = true;
            this.oldValue = val;
            this.value = val;
        },

        propInvisible(val) {
            this.invisible = val;
        }
    },

    methods: {
        checkValueFromRange(value) {
            const _v = this.convertValue(value);
            const leftNum = this.convertValue(this.returnRangeArray[0]);
            const rightNum = this.convertValue(this.returnRangeArray[1]);
            const leftDelimit = this.returnRangeDelimit[0];
            const rightDelimit = this.returnRangeDelimit[1];

            if (leftDelimit === '(' && _v <= leftNum) {
                this.value = leftNum + 1;
            } else if (leftDelimit === '[' && _v < leftNum) {
                this.value = leftNum;
            } else if (rightDelimit === ')' && _v >= rightNum) {
                this.value = rightNum - 1;
            } else if (rightDelimit === ']' && _v > rightNum) {
                this.value = rightNum;
            } else {
                this.validates(value);
            }
        },

        validates(val) {
            this.validateResult = this.validate();
            this.isValidate = true;
            const oldValue = this.oldValue;
            this.oldValue = val;
            if (Gikam.isTrue(this.validateResult)) {
                this.$emit('change', this.options.field, val, this.rowIndex, oldValue);
            }
        },

        focusHandle() {
            this.isFocus = true;
            //存储当前激活的单元格
            if (this.grid) {
                Gikam.globalActiveField = { [this.grid.options.id]: this };
            }
            this.oldValidateResult = this.validateResult;
            this.isInput = false;
            this.validateResult = true;
            this.$emit('focus', this.options.field, this.value, this.rowIndex);

            if (!Gikam.isTrue(this.propInvisible)) {
                Gikam.simulatedEvent(document, 'mousedown');
            }
        },

        blurHandle(event) {
            //防止第一次输入相同值而触发保存
            event.target.value != this.oldValue && (this.value = event.target.value);
            this.isFocus = false;
            if (Gikam.isFalse(this.isInput)) {
                this.validateResult = this.oldValidateResult;
            } else if (
                this.oldValue === this.value &&
                this.isInput &&
                !this.isValidate &&
                Gikam.isNotEmpty(this.isValidate)
            ) {
                this.validateResult = this.validate();
            }
            this.isValidate = false;
            if (this.value === this.lastBlurValue) {
                this.$emit('blur', this.options.field, this.value, this.rowIndex);
            }
            this.lastBlurValue = this.value;
        },

        inputHandle(e) {
            this.isInput = true;
            this.$emit('input', e);
        },

        validKeyDown(keyCode, shiftKey, ctrlKey) {
            if (this.ignoreKey[keyCode] === ctrlKey) {
                return true;
            }

            if (typeof this.ignoreKey[keyCode] === 'string') {
                return true;
            }
            return false;
        },

        keydownHandle(e) {
            if (!Gikam.isNumber(e.target.value + e.key) && !this.validKeyDown(e.keyCode, e.shiftKey, e.ctrlKey)) {
                e.preventDefault();
                e.stopPropagation();
            }
        },

        keyupHandle(e) {
            const value = e.target.value;
            let numberValue = '';
            for (var i = 0; i < value.length; i++) {
                const charCode = value.charCodeAt(i);
                if (charCode > 45 && charCode < 58 && charCode !== 47) {
                    numberValue += value.charAt(i);
                }
            }
            if (value[0] === '-') {
                numberValue = '-' + numberValue;
            }
            e.target.value = numberValue;
            this.$emit('keyup', e);
        },

        errorIconMouseenter(event) {
            this.showErrorPanel(event);
        },

        errorIconMouseleave(event) {
            this.removeErrorPanel(event);
        },

        changeValue(value, number) {
            const val = String(value).split('.');
            if (this.isInteger || Gikam.isEmpty(val[1])) {
                this.value = isNaN(this.convertValue(val[0])) ? 0 : this.convertValue(val[0]) + number;
                return;
            }
            const decimal_length = val[1].length;
            if (val[0] === '0' && number === -1) {
                this.value = (Number('0.' + val[1]) - 1).toFixed(decimal_length);
                return;
            }
            if (val[0] === '-0' && number === 1) {
                this.value = (1 - Number('0.' + val[1])).toFixed(decimal_length);
                return;
            }
            this.value = (Number(value) + number).toFixed(decimal_length);
        },

        convertValue(value) {
            return this.isInteger ? parseInt(value) : parseFloat(value);
        },

        //键盘控制光标移动前 单元格做的事情
        dumpActiveCell() {
            if (!this.invisible) {
                this.$refs.input && this.$refs.input.blur();
            }
        },

        //键盘控制光标移动后 单元格做的事情
        activeCell() {
            if (!this.invisible) {
                this.focus();
            } else {
                this.$emit('click', this);
            }
        },

        //用于 cellEditor 的click事件处理当前单元格
        focus() {
            this.$refs.input.focus();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.filter > .number-input {
    height: 24px;
}

.number-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    background: #fff;
    border-radius: 4px;
    padding: 0 0 0 4px;
    overflow: hidden;
    color: #666;
    .readonly-text,
    input {
        flex: 1;
        padding-right: 4px;
    }

    & > input {
        border: none;
        color: rgba(0, 0, 0, 0.65);
        font-family: 'Microsoft YaHei', serif;
        font-size: 12px;
        height: 100%;
        min-width: 0;
    }
    .addon {
        font-family: 'Microsoft YaHei', serif;
        font-size: 12px;
        padding-right: 4px;
        color: inherit;
        word-break: normal;
    }

    .switch {
        width: 25px;
        height: 100%;
        border-radius: 0 4px 4px 0 / 0 4px 4px 0;
        display: block;
        overflow: hidden;
        position: relative;
        .up {
            width: 100%;
            background: url(../../../../img/date/up.png) no-repeat center;
            background-color: #d6d6d6;
            cursor: pointer;
            min-height: 50%;
            position: absolute;
            top: 0;
            right: 0;
        }

        .down {
            width: 100%;
            background: url(../../../../img/date/down.png) no-repeat center;
            background-color: #d6d6d6;
            border-top: 1px solid #fff;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 0;
            min-height: 50%;
        }
    }

    .validate-error-icon {
        width: 20px;
        padding-top: 3px;
        padding-right: 4px;
    }
}

.number-input.readonly {
    color: #666;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    align-items: center;
    background: #f4f4f4;
}

.number-input.invisible {
    background-color: transparent;
    border: none;
}
</style>
