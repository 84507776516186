export function initCss(width) {
    const idealWidth = width > 1440 ? 978 : 649;
    let landscape;

    if (width > 1440) {
        landscape = 'landscape';
    } else {
        landscape = 'portrait';
    }

    return `
    @page{ size: ${landscape}}
    body{ 
        width: ${idealWidth + 20}px;
    }
    .les-comp{
        overflow:hidden;
        padding: 8px !important;
        border-bottom: none !important;
    }
    .les-comp.les-diagramDraw{ 
        border-bottom: 1px solid #eee !important;
    }
    
     iframe {
        border: none !important;
    }

    img{
        maxWidth: 100%;
        height: auto !important;
        border:'1px solid #999 !important;
        page-break-inside:avoid;
    }

    .noEditing{ 
        overflow: hidden
    }
    `;
}

export function formCss() {
    return `
    .form{
        width: 100%;
        page-break-inside: avoid;
    }
    .form, .form .panel{
        padding: 0 !important;
    }
    .form .form-group .title {
        color: #000;
        font-weight: bold;
        justify-content: flex-start;
        padding: 0 10px;
    }
    .form .form-group .field .readonly-text {
        background-color: #fff;
        border: none;
        border-radius: 0;
    }

    .form .panel {
        position: relative;
    }
    .form .panel:before {
        position: absolute;
        content:'';
        top: 0;
        bottom: 0;
        left:0;
        right: 0;
        z-index:10;
        border-top: 1px solid #999999  !important;
        border-left: 1px solid #999999  !important;
    }

    .form .form-group {  
        margin-bottom: 0 !important;
        position: relative;
        height: fit-content;
    }

    .form .form-group:before {  
        position: absolute;
        content:'';
        top:-2px;
        bottom:2px;
        left:0;
        right: 0;
        z-index:8;
        border-bottom: 1px solid #999999;
        border-right: 1px solid #999999;
    }

    .form .form-group .field {
        border-left: 1px solid #999999;
        height : 30px !important;
    }

    .form .form-group .colon {
        display: none;
    }
    `;
}

export function gridCss() {
    return `
    .grid { 
        padding: 0 !important;
        height: fit-content !important;
        border-right: 1px solid #999999 !important;
        page-break-inside:avoid;
    }
    
    .grid .grid-header  {
        background: #fff !important;
    }

    .grid .grid-header .header-container {
        margin-top: 0 !important;
    }
    
     .grid .grid-header thead,
     .grid .grid-header th,
     .grid .grid-header tbody,
     .grid .grid-header tr,
     .grid .grid-header td{
        border-color: #999999 !important;
    }

    .grid .grid-header th{
        border-left: 1px solid #999999 !important;
        border-top: 1px solid #999999 !important;
        border-right: 1px solid #999999 !important;
    }
    
     .grid .grid-header th span {
        color: #000 !important;
        font-weight: bold !important;
    }

    
     .grid .grid-body ,.grid .grid-header .header-container  {
        overflow: visible;   
        background-color: #fff !important;
        border-bottom:1px solid #eee;
    }
    
     .grid .grid-body table,
     .grid .grid-body th,
     .grid .grid-body tbody,
     .grid .grid-body tr,
     .grid .grid-body thead,
     .grid .grid-body td{
        border: none !important;
        background: none !important;
    }

    .grid .grid-body .no-record-tr{
        border:none;
        position: relative;
    }

    .grid .grid-body .grid-no-record {
        display: contents;
    }

    .grid .grid-body .grid-no-record>.no-record-wrapper {
        border-left: 1px solid #999999 !important;
        border-bottom: 1px solid #999999 !important;
    }

    .grid .grid-body .no-record-tr .no-record-cell .no-record {
        margin-bottom: 2px
    }

    .grid .grid-body .no-record-tr:after {  
        position: absolute;
        content:'';
        top:0;
        bottom:0;
        left:0;
        right: 0;
        z-index:8;
        border-left:  1px solid #999999;
        border-right: 1px solid #999999;
    }

     .grid .grid-body tbody tr {
         background-color: #fff !important;
         border-bottom: 1px solid #999999 !important;
    }
    
     .grid .grid-body tr:not(.no-record-tr) td:first-child {
        border-left: 1px solid #999999 !important;
    }
    
     .grid .grid-body tr:not(.no-record-tr) td {
        border-right: 1px solid #999999 !important;
    }

    `;
}

export function resultInputCss() {
    return ` 
    .table {
        page-break-inside: avoid;
    }

    .table .body-row,
    .table .body-row input,
    .table .body-row .number-input,
    .table .head-cell,
    .table .title{
        border:none !important
    } 
    
    .table .body-col::after,
    .table .title::after,
    .table .head-cell::after,
    .table .header-level-one.root::after {
        border-color:  #999999 !important;
        z-index:10;
    }
    
    .table .notData{
        border-top: none;
        border-color:  #999999 !important;
    }
    
    .table .head-cell::after{
        left:0;
        border-top: 1px solid #999999 ;
        border-bottom: 1px solid #999999 ;
        border-color:  #999999 !important;
    }
    
    .table .title::after{
        left:0;
        border-top: 1px solid #999999 ;
        border-color:  #999999 !important;
    }

    .table .body-col:first-child::after{
        left:0;
        border-left: 1px solid #999999 ;
    }
   
    .table .body-row{  
        margin-bottom: 0 !important;
        position: relative;
    }

    .table .body-row:not(.isEmpty){
        padding-top: 1px;
    }

    .table .body-row:not(.isEmpty):after {  
        position: absolute;
        content:'';
        top:0;
        bottom:0;
        left:0;
        right: 0;
        z-index:8;
        border-bottom: 1px solid #999999;
    }

    .table{
        margin: 0 !important;
    }
     
    .table .toolBar{
        display:none;
    }

    .table .head-body{
        height:fit-content !important;
    }

    .table .head-body > .header .cell-title > span,
    .table .head-body > .header .head-group .title > span {
        color: #000 !important;
        font-weight: bold !important;
   }

    `;
}

export function importFormPdfCss() {
    return `
    .panel section {
        display: block !important;
        width: 100%;
    }

    .panel section:after {
        content: '';
        visibility: hidden;
        clear: both;
        height: 0;
        display: block;
    }

    .panel section .form-group {
        display: block !important;
        float: left;
    }

    .panel section .form-group div {
        display: block !important;
        float: left;
        flex: none !important;
    }

    .les-template .static {
        padding-left: 0 !important;
        margin-right: 0 !important;
    }

    .number-input.readonly {
        background: #fff !important;
    }

    .form .form-group .title {
        padding: 5px 10px !important;
    }

    .form .form-group .field .number-input {
        border:none !important;
    }
`;
}
