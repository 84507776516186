import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.concat.js";
//
//
//
//
import checkboxGroup from '@/gikam/js/components/template/checkbox/checkboxGroup.vue';
export default {
  props: {
    options: Object,
    propValue: [Number, String],
    rowIndex: Number
  },
  components: {
    checkboxGroup: checkboxGroup
  },
  watch: {
    propValue: {
      immediate: true,
      handler: function handler(value) {
        this.$set(this.options, 'value', value);
      }
    }
  },
  methods: {
    changeHandle: function changeHandle() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this.$emit.apply(this, ['change'].concat(args));
    }
  }
};