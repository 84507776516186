import Vue from 'vue';
import Gikam from 'gikam';
import { PageEditAuthorityUtils } from './page-edit-authority-utils';
Vue.mixin({
  methods: {
    getAuthorityVisible: function getAuthorityVisible() {
      return PageEditAuthorityUtils.getAuthorityVisible(this.options);
    },
    mergePageEditOptions: function mergePageEditOptions() {
      var currentWindow = Gikam.getCurrentWindow();

      if (!currentWindow) {
        return null;
      }

      var pageEditConfig = Gikam.compConfigManager[currentWindow.getMainPageUrl()];

      if (pageEditConfig && pageEditConfig[this.options.id]) {
        Object.assign(this.options, pageEditConfig[this.options.id]);
      }
    }
  }
});