import Vue from 'vue';
import Gikam from 'gikam';
import CustomOverlay from './custom-overlay.vue';
export default {
  methods: {
    createOverlay: function createOverlay() {
      var h = this.$createElement;

      var _Gikam$deepExtend = Gikam.deepExtend(this.options),
          overlay = _Gikam$deepExtend.overlay;

      var target = this.$refs.select;
      var overlayId = overlay.id;
      this.isActive = true;

      var _this = this;

      this.overlayPicker = new Vue({
        el: Gikam.createDom('div', window.top.document.body),
        components: {
          CustomOverlay: CustomOverlay
        },
        render: function render() {
          var h = arguments[0];
          return h(CustomOverlay, {
            "attrs": {
              "target": target,
              "overlay": overlay
            },
            "on": {
              "destroyed": this.destroyedHandle
            }
          });
        },
        created: function created() {
          if (overlayId) {
            Gikam.compInstanceContainer[overlayId] = this;
          }

          delete overlay.id;
          overlay.headerVisible = false;
        },
        beforeDestroy: function beforeDestroy() {
          if (overlayId) {
            delete Gikam.compInstanceContainer[overlayId];
          }

          Gikam.removeDom(this.$el);
          _this.overlayPicker = null;
          _this.isActive = false;
        },
        methods: {
          close: function close(data) {
            _this.setValue(data);

            this.$destroy();
          },
          destroyedHandle: function destroyedHandle() {
            this.$destroy();
          }
        }
      });
    }
  }
};