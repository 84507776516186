<template>
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 13 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-1091.000000, -123.000000)">
                <g transform="translate(1090.000000, 122.000000)">
                    <g transform="translate(1.000000, 1.000000)" fill="currentColor" fill-rule="nonzero">
                        <g>
                            <path
                                d="M9.37708333,2.54583333 C9.11041666,2.54583333 8.87708333,2.3125 8.87708333,2.04583333 L8.87708333,1.17916667 C8.87708333,1.07916667 8.81041666,1.0125 8.71041667,1.0125 L4.04375,1.0125 C3.94375,1.0125 3.87708333,1.07916667 3.87708333,1.17916667 L3.87708333,2.0125 C3.87708333,2.27916667 3.64375,2.5125 3.37708333,2.5125 C3.11041666,2.5125 2.87708333,2.27916667 2.87708333,2.0125 L2.87708333,1.17916667 C2.87708333,0.545833344 3.41041666,0.0125 4.04375,0.0125 L8.71041667,0.0125 C9.34375,0.0125 9.87708333,0.545833328 9.87708333,1.17916667 L9.87708333,2.04583333 C9.87708333,2.3125 9.64375,2.54583333 9.37708333,2.54583333 Z"
                            ></path>
                            <path
                                d="M12.1770833,3.0125 L0.510416672,3.0125 C0.24375,3.0125 0.0104166719,2.77916667 0.0104166719,2.5125 C0.0104166719,2.24583333 0.24375,2.0125 0.510416672,2.0125 L12.1770833,2.0125 C12.44375,2.0125 12.6770833,2.24583333 12.6770833,2.5125 C12.6770833,2.77916667 12.44375,3.0125 12.1770833,3.0125 Z"
                            ></path>
                            <path
                                d="M10.24375,12.6791667 L2.44375,12.6791667 C1.81041667,12.6791667 1.27708333,12.1458333 1.27708333,11.5125 L1.27708333,2.84583333 C1.27708333,2.57916666 1.51041666,2.34583333 1.77708333,2.34583333 C2.04375,2.34583333 2.27708333,2.57916666 2.27708333,2.84583333 L2.27708333,11.5125 C2.27708333,11.6125 2.34375,11.6791667 2.44375,11.6791667 L10.24375,11.6791667 C10.34375,11.6791667 10.4104167,11.6125 10.4104167,11.5125 L10.4104167,2.87916667 C10.4104167,2.6125 10.64375,2.37916667 10.9104167,2.37916667 C11.1770833,2.37916667 11.4104167,2.6125 11.4104167,2.87916667 L11.4104167,11.5125 C11.4104167,12.1458333 10.8770833,12.6791667 10.24375,12.6791667 L10.24375,12.6791667 Z"
                            ></path>
                            <path
                                d="M4.14375,10.1791667 C3.87708333,10.1791667 3.64375,9.94583334 3.64375,9.67916667 L3.64375,4.5125 C3.64375,4.24583333 3.87708333,4.0125 4.14375,4.0125 C4.41041667,4.0125 4.64375,4.24583333 4.64375,4.5125 L4.64375,9.67916667 C4.64375,9.94583334 4.41041667,10.1791667 4.14375,10.1791667 Z M6.37708333,10.1791667 C6.11041666,10.1791667 5.87708333,9.94583334 5.87708333,9.67916667 L5.87708333,4.5125 C5.87708333,4.24583333 6.11041666,4.0125 6.37708333,4.0125 C6.64375,4.0125 6.87708333,4.24583333 6.87708333,4.5125 L6.87708333,9.67916667 C6.87708333,9.94583334 6.64375,10.1791667 6.37708333,10.1791667 Z M8.57708333,10.1791667 C8.31041666,10.1791667 8.07708333,9.94583334 8.07708333,9.67916667 L8.07708333,4.5125 C8.07708333,4.24583333 8.31041666,4.0125 8.57708333,4.0125 C8.84375,4.0125 9.07708333,4.24583333 9.07708333,4.5125 L9.07708333,9.67916667 C9.07708333,9.94583334 8.84375,10.1791667 8.57708333,10.1791667 Z"
                            ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
