<template>
    <a-dropdown v-bind="options" @visibleChange="visibleChangeHandle">
        <slot></slot>
        <slot name="overlay" slot="overlay"></slot>
    </a-dropdown>
</template>

<script>
import Vue from 'vue';
import { Dropdown } from 'ant-design-vue';
import Gikam from 'gikam';

Vue.use(Dropdown);

export default {
    props: {
        options: Object
    },

    methods: {
        visibleChangeHandle(visible) {
            if (visible) {
                Gikam.simulatedEvent(document, 'dropdownDestroy');
            }
        }
    }
};
</script>
