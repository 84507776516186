<template>
    <div
        v-if="readonly || invisible"
        class="textarea readonly"
        @click="clickHandle"
        @select="focus"
        :class="{ invisible: invisible }"
        @mousedown.stop
    >
        <div v-if="options.height === 'auto'" class="readonly-text auto">{{ value }}</div>
        <textarea
            v-else
            class="readonly-text"
            :style="{ height: options.height + 'px' }"
            v-model="value"
            :placeholder="placeholder"
            disabled="disabled"
        ></textarea>
    </div>
    <div
        v-else
        class="textarea"
        :class="{ 'validate-error': !validateResult }"
        @click="clickHandle"
        @select="focus"
        @mousedown.stop
    >
        <textarea
            ref="input"
            :name="options.field"
            v-model.lazy="value"
            :placeholder="placeholder"
            @focus="focusHandle($event)"
            @blur="blurHandle"
            @input="inputHandle"
            :style="{ height: options.height + 'px' }"
        ></textarea>
        <div
            class="validate-error-icon"
            :style="{ marginLeft: getErrorLeft() }"
            v-if="validateResult === false"
            @click.stop
        >
            <info-image @mouseenter.stop="errorIconMouseenter" @mouseleave.stop="errorIconMouseleave"></info-image>
        </div>
    </div>
</template>
<script>
import infoImage from '../img/info.vue';
import BaseField from '../baseField/baseField.vue';
import Gikam from '../../../core/gikam-core';

export default {
    name: 'textareaField',
    extends: BaseField,
    components: { infoImage },

    props: {
        options: Object,
        propValue: [String, Number],
        rowIndex: Number,
        cellIndex: Number,
        propReadonly: {
            type: Boolean,
            default: void 0
        },
        //编辑器中的边框等样式是否显示出来
        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            readonly: Gikam.isNotEmpty(this.propReadonly) ? this.propReadonly : this.options.readonly,
            field: this.options.field,
            value: this.propValue,
            placeholder: Gikam.propI18N(this.options.placeholder),
            invisible: this.propInvisible,
            isScorll: false,

            validateResult: true,
            //保存前一次校验状态，用于当输入框没输入值时，blur时，还原校验状态
            oldValidateResult: null,
            //用于判断用户输没输过值
            isInput: false,
            //保存前一次输入框的值，用于判断用户输入之后的值是否与之前的值一致
            oldValue: this.propValue,
            //判断是否为初始化之后的赋值
            setDataState: false,
            isValidate: false
        };
    },

    watch: {
        value(val, oldVal) {
            if (this.setDataState) {
                this.setDataState = false;
                return;
            }
            this.validateResult = this.validate();
            this.isValidate = true;
            this.oldValue = val;
            if (Gikam.isTrue(this.validateResult)) {
                this.$emit('change', this.options.field, val, this.rowIndex, oldVal);
            }
        },

        propValue(val) {
            if (this.value !== val) {
                this.setDataState = true;
            }
            this.validateResult = true;
            this.oldValue = val;
            this.value = val;
        },

        propInvisible(val) {
            this.invisible = val;
        }
    },

    //带有out后缀的方法为继承组件内部调用的方法
    methods: {
        focusHandle() {
            this.oldValidateResult = this.validateResult;
            this.isInput = false;
            this.validateResult = true;
            this.$emit('focus', this.options.field, this.value, this.rowIndex);

            if (!Gikam.isTrue(this.propInvisible)) {
                Gikam.simulatedEvent(document, 'mousedown');
            }
        },

        blurHandle() {
            if (Gikam.isFalse(this.isInput)) {
                this.validateResult = this.oldValidateResult;
            } else if (this.oldValue === this.value && this.isInput && !this.isValidate) {
                this.validateResult = this.validate();
            }
            this.isValidate = false;

            let textarea = this.$refs.input;
            this.isScorll = textarea.scrollHeight > textarea.clientHeight ? true : false;
            this.$emit('editing', false);
        },

        inputHandle() {
            this.isInput = true;
        },

        getErrorLeft() {
            if (this.isScorll) {
                return '-37px';
            } else {
                return '-25px';
            }
        },

        //键盘控制光标移动前 单元格做的事情
        dumpActiveCell() {
            if (!this.invisible) {
                this.$refs.input && this.$refs.input.blur();
            }
        },

        //键盘控制光标移动后 单元格做的事情
        activeCell() {
            if (!this.invisible) {
                this.focus();
            } else {
                this.$emit('click', this);
            }
        },

        //用于 cellEditor 的click事件处理当前单元格
        focus() {
            this.$refs.input && this.$refs.input.focus();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            this.options.onContentClick?.call(null, this.value);
            if (this.readonly && Gikam.isNotEmpty(this.options.onClick)) {
                this.options.onClick.call(this, this.options);
                return;
            }
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        }
    }
};
</script>

<style>
.textarea {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    min-height: 60px;
}

.textarea.readonly {
    color: #666;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    align-items: center;
}

.textarea textarea,
.textarea .readonly-text {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    resize: none;
    padding-left: 8px;
    padding-right: 14px;
}

.textarea .readonly-text {
    height: 100%;
    background-color: #f4f4f4;
    display: flex;
    word-break: break-all;
}

.textarea .readonly-text.auto {
    padding-top: 4px;
    padding-bottom: 4px;
    line-height: 20px;
}

.textarea.invisible .readonly-text {
    background-color: transparent;
    border: none;
}

.textarea textarea:focus {
    outline: none;
}

.textarea.validate-error textarea {
    border-color: #ff6e6e;
}

.textarea textarea:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.textarea .validate-error-icon {
    z-index: 100;
    margin-left: -25px;
    display: flex;
    align-items: center;
}

.textarea .validate-error-icon svg {
    height: 20px;
    cursor: pointer;
}
</style>
