<template>
    <div
        class="choose-input"
        :class="{ 'validate-error': !validateResult, readonly: readonly, invisible: invisible }"
        @mousedown.stop
        @dblclick="dblclickHandle"
        @click="clickHandle"
        @select="focus"
    >
        <div v-if="readonly || invisible" class="readonly-text">{{ value }}</div>
        <template v-else>
            <input :name="options.field" :value="text" readonly ref="input" autocomplete="off" v-if="mode === 'text'" />
            <input :name="options.field" :value="value" :readonly="editable" ref="input" autocomplete="off" v-else />
            <div class="choose-input-icon" @click="choose">
                <editImg v-bind="{ color: '#666666', width: '16px', height: '16px' }" />
            </div>
        </template>
    </div>
</template>
<script>
import Gikam from '../../../../core/gikam-core';
import Vue from 'vue';
import Cron from './cronPicker.vue';

const defaultOptions = {
    // 是否显示分、秒通配符
    minuteWildcard: true,
    secondWildcard: true
};

export default {
    name: 'cronField',
    props: {
        options: Object,
        propValue: String,
        rowIndex: Number,
        //编辑器中的边框等样式是否显示出来
        propInvisible: {
            type: Boolean,
            default: false
        },
        validateArg: Object
    },

    data() {
        return {
            validateResult: true,
            readonly: this.options.readonly,
            field: this.options.field,
            value: this.propValue,
            invisible: this.propInvisible,
            text: this.options.formatter ? this.options.formatter(this.rowIndex, this.propValue, this.validateArg) : '',
            minuteWildcard: this.options.minuteWildcard ?? defaultOptions.minuteWildcard,
            secondWildcard: this.options.secondWildcard ?? defaultOptions.secondWildcard
        };
    },

    computed: {
        editable() {
            return this.options.editable === true ? '' : 'readonly';
        },
        mode() {
            return this.options.formatter ? 'text' : 'value';
        }
    },

    watch: {
        value(val, oldVal) {
            this.$emit('change', this.options.field, this.value, this.rowIndex, this.text, { oldValue: oldVal });
        },
        propValue(val) {
            this.value = val;
        }
    },

    methods: {
        override(props) {
            Gikam.extend(defaultOptions, props);
        },

        getCronDesc(value) {
            return Gikam.postText(Gikam.IFM_CONTEXT + '/core/module/sys/crons', Gikam.getJsonWrapper({ cron: value }));
        },

        trigger(event, data) {
            let listener = this.options['on' + Gikam.toInitialUpperCase(event)];
            if (listener) {
                return listener.call(this.parent, data, this.rowIndex);
            }
        },

        choose() {
            let modal = Gikam.create('modal', {
                title: 'Cron表达式',
                width: 900
            });

            let _this = this;

            let cronPicker = new Vue({
                el: modal.window.$dom,
                components: {
                    Cron
                },
                data() {
                    return {
                        value: _this.value,
                        mode: _this.options.mode,
                        minuteWildcard: _this.minuteWildcard,
                        secondWildcard: _this.secondWildcard
                    };
                },
                methods: {
                    modalClose() {
                        modal.close();
                    },

                    change(value) {
                        _this.value = value;
                        this.createCronDesc(value).done(desc => {
                            if (_this.options.onAfterChoose) {
                                _this.options.onAfterChoose.call(_this, desc);
                            }
                        });
                    },

                    getCronDesc(value) {
                        return Gikam.postText(
                            Gikam.IFM_CONTEXT + '/core/module/sys/crons/descs',
                            Gikam.getJsonWrapper({ cron: value })
                        );
                    }
                },
                template:
                    "<Cron ref='cronView' :value='value' :mode='mode' :secondWildcard='secondWildcard' :minuteWildcard='minuteWildcard' @change='change' @modalclose='modalClose'></Cron>"
            });

            Gikam.create('layout', {
                renderTo: modal.window.$dom,
                center: {
                    items: [
                        {
                            type: 'btnToolbar',
                            items: [
                                {
                                    type: 'button',
                                    text: '取消',
                                    onClick() {
                                        modal.close();
                                    }
                                },
                                {
                                    type: 'button',
                                    text: '确定',
                                    class: 'blue',
                                    onClick: () => {
                                        let cronView = cronPicker.$refs.cronView;
                                        let type = cronView.appointDataType();
                                        if (type) {
                                            const value = cronView.cronExpression;
                                            if (this.mode === 'text') {
                                                this.getCronDesc(value).done(res => {
                                                    this.text = JSON.parse(res).description;
                                                    this.value = value;
                                                    modal.close();
                                                });
                                            } else {
                                                this.value = value;
                                                modal.close();
                                            }
                                        }
                                    }
                                }
                            ]
                        }
                    ]
                }
            });
        },

        dblclickHandle() {
            this.$emit('dblclick', this);
        },

        //键盘控制光标移动前 单元格做的事情
        dumpActiveCell() {
            if (!this.invisible) {
                this.$refs.input && this.$refs.input.blur();
            }
        },

        //键盘控制光标移动后 单元格做的事情
        activeCell() {
            if (!this.invisible) {
                this.focus();
            } else {
                this.$emit('click', this);
            }
        },

        //用于 cellEditor 的click事件处理当前单元格
        focus() {
            this.$refs.input && this.$refs.input.focus();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        }
    }
};
</script>

<style scoped>
.choose-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
}

.filter .choose-input {
    height: 24px;
}

.choose-input input,
.choose-input .readonly-text {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-right: 40px;
    padding-left: 10px;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
}

.choose-input .readonly-text {
    background-color: #f4f4f4;
    color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
}

.choose-input input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.choose-input.validate-error input {
    border-color: #ff6e6e;
}

.choose-input .choose-input-icon {
    width: 30px;
    cursor: pointer;
    margin-left: -30px;
    background-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.invisible .readonly-text {
    background-color: transparent;
    border: none;
}
</style>
