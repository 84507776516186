import Gikam from 'gikam';
import { I18N } from '@/gikam/i18n/I18N';
import { getPageCss, printPage, getPageHtml, getHtmlCompType } from './utils';
import { importFormPdfCss } from './printCss';

/**
 * 顶部添加内容按钮
 * @memberof Les
 */
export function addContent() {
    if (this.store.state.bodyIsEdit) {
        Gikam.alert(I18N.prop('les.comment.alert.save.before.add.component'));
        return;
    }
    const _getTemplates = this.getTemplates.bind(this);
    this.addContentModal = new Gikam.Vue({
        el: Gikam.createDom('div', document.body),
        store: this.store,
        provide() {
            return {
                changeTemplateData: _getTemplates
            };
        },
        components: {
            lesModel: () => import('../vue/lesModel.vue')
        },
        render() {
            return <lesModel />;
        }
    });
}

/**
 * 导出pdf按钮
 * @memberof Les
 */

export async function importEvent() {
    if (this.store.state.bodyIsEdit) {
        Gikam.alert(I18N.prop('les.comment.alert.save.before.export.pdf'));
        return;
    }
    window.workspace.window.showMask();
    const center = this.model.$refs.vm.$refs.body;
    const $dom = await getPageHtml(center);
    const CompType = getHtmlCompType($dom, 'importPdf', this.options);
    const head = await getPageCss(CompType);

    const style = document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.innerHTML = importFormPdfCss();

    head.appendChild(style);

    const promiseArr = this.options?.printCss.map(href => {
        return new Promise(resolve => {
            Gikam.getText(Gikam.IFM_CONTEXT + href).done(res => resolve(res));
        });
    });

    Promise.all(promiseArr).then(cssContents => {
        const cssContent = cssContents.reduce((total, content) => (total += content), '');
        const style = document.createElement('style');
        style.innerHTML = cssContent;
        head.appendChild(style);
        const content = `<!DOCTYPE HTML><html><meta http-equiv="Content-Type" content="text/html;charset=utf-8" />${head.innerHTML}<style> html{overflow: auto;} body{  width: 775px; height: auto;} @page{ margin: ${this.options.printMargin}; size: a4;}</style></head><body>${$dom.innerHTML}</body></html>`;
        Gikam.postText(
            Gikam.IFM_CONTEXT + '/core/module/item/files/action/convert-html-to-pdf',
            Gikam.getJsonWrapper({
                content,
                fileName: this.store.state.lesInfo.templateName || this.store.state.lesInfo.instanceName
            })
        )
            .done(function(url) {
                Gikam.download(Gikam.IFM_CONTEXT + url);
            })
            .always(() => {
                window.workspace.window.closeMask();
            });
    });
}

/**
 * 返回内容按钮
 * @memberof Les
 */
export function backEvent() {
    // 返回页面的时候清空全局中的les组件，方便下次进来再次添加
    Gikam.lesComponents = {};
    if (this.store.state.activeComp.isNew === true) {
        this.store.commit('deleteComp', this.store.state.activeComp);
    }
    this.store.commit('changeEditState', false);
    this.store.commit('changeActiveComp', { comp: {} });
    this.options.back();
}

/**
 * 打印按钮
 * @memberof Les
 */
export async function printEvent() {
    if (this.store.state.bodyIsEdit) {
        Gikam.alert(I18N.prop('les.comment.alert.save.before.print'));
        return;
    }
    window.workspace.window.showMask();
    const center = this.model.$refs.vm.$refs.body;
    const centerDom = await getPageHtml(center);
    const CompType = getHtmlCompType(centerDom, null, this.options);
    const head = await getPageCss(CompType);
    window.workspace.window.closeMask();
    printPage(head, centerDom, this.options);
}
/**
 * 审计跟踪按钮
 * @memberof Les
 */
export function auditTrailEvent() {
    const tableName = this.options.instance ? 'T_CORE_LES_INSTANCE' : 'T_CORE_LES_TEMPLATE';
    const trail = Gikam.audit.trail;

    Gikam.getJson(
        Gikam.printf(Gikam.IFM_CONTEXT + '/secure/core/module/sys/{trail}/{tableName}', {
            tableName: tableName,
            trail: trail.indexOf('audit-trail') === -1 ? 'audit/audit-configs' : 'at-configs'
        })
    ).done(r => {
        if (
            (trail.indexOf('audit-trail') === -1 && r.auditable === '1') ||
            (trail.indexOf('audit-trail') !== -1 && r.auditTrail === '1')
        ) {
            const id = this.options.id;
            const store = this.store;
            this.auditModal = new Gikam.Vue({
                el: Gikam.createDom('div', document.body),
                components: {
                    lesModel: () => import('../vue/base/audit/audit.vue')
                },
                store: store,
                render() {
                    return <lesModel tableName={tableName} id={id} />;
                }
            });
        } else {
            Gikam.alert(Gikam.propI18N('GIKAM.AT.NO_ACTIVATED_CONFIG'));
        }
    });
}

/**
 * 装订线 按钮按钮
 * @memberof Les
 */
export function addCordEvent() {
    this.store.commit('updateCordStatus');
}
