import _toConsumableArray from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Gikam from 'gikam';
export default {
  props: {
    minHeight: {
      type: Number,
      default: 28
    },
    chooseParam: {
      type: Object,
      default: null
    },
    propReadonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null
    },
    chooseEventScope: Object
  },
  data: function data() {
    return {
      baseClass: 'sw-link-item',
      focused: false,
      inputValue: null,
      itemList: [],
      chooseItemList: [],
      readonly: this.propReadonly
    };
  },
  computed: {
    classList: function classList() {
      var classList = [this.baseClass];
      this.focused && classList.push("".concat(this.baseClass, "-focused"));
      return classList;
    },
    toolbarClass: function toolbarClass() {
      var classList = [];
      classList.push("".concat(this.baseClass, "-toolbar"));
      return classList;
    },
    styleObject: function styleObject() {
      var style = {
        minHeight: "".concat(this.minHeight, "px")
      };
      return style;
    },
    editorWrapperClass: function editorWrapperClass() {
      var classList = [];
      classList.push("".concat(this.baseClass, "-editor-wrapper"));
      return classList;
    },
    editorInputClass: function editorInputClass() {
      var classList = [];
      classList.push("".concat(this.baseClass, "-editor-input"));
      return classList;
    },
    linkItemClass: function linkItemClass() {
      var classList = [];
      classList.push("".concat(this.baseClass, "-link-item"));
      return classList;
    },
    linkWrapperClass: function linkWrapperClass() {
      var classList = [];
      classList.push("".concat(this.baseClass, "-link-wrapper"));
      return classList;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function handler(value) {
        if (Gikam.isNotEmpty(value)) {
          this.itemList = value.split(',');
        }
      }
    }
  },
  methods: {
    focusHandle: function focusHandle() {
      this.focused = true;
    },
    blurHandle: function blurHandle() {
      this.focused = false;
    },
    changeHandle: function changeHandle() {
      this.itemList.push(this.inputValue);
      this.inputValue = null;
      this.$emit('change', this.itemList.join(','));
    },
    clickHandle: function clickHandle() {
      if (this.readonly) {
        return;
      }

      this.$refs.input.focus();
      this.focused = true;
    },
    itemRemoveHandle: function itemRemoveHandle(index) {
      this.itemList.splice(index, 1);
      this.$emit('change', this.itemList.join(','));
    },
    itemClickHandle: function itemClickHandle(item, index) {
      this.$emit('itemClick', item, this.chooseItemList[index]);
    },
    chooseClickHandle: function chooseClickHandle() {
      var _this = this;

      var chooseConfig = null;
      var _this$chooseParam = this.chooseParam,
          category = _this$chooseParam.category,
          urlParam = _this$chooseParam.url,
          linkItemField = _this$chooseParam.linkItemField,
          onBeforeChoose = _this$chooseParam.onBeforeChoose;

      if (category) {
        chooseConfig = Gikam.choose.getConfig(category);
      }

      var _chooseConfig = chooseConfig,
          title = _chooseConfig.title,
          width = _chooseConfig.width,
          height = _chooseConfig.height;
      var url = urlParam || Gikam.IFM_CONTEXT + chooseConfig.url;
      var customData = onBeforeChoose === null || onBeforeChoose === void 0 ? void 0 : onBeforeChoose.call(this.chooseEventScope);
      this.modal = Gikam.create('modal', Object.assign({
        title: title,
        width: width,
        height: height
      }, {
        url: Gikam.addUrlParam(url, customData),
        onAfterClose: function onAfterClose(rows) {
          var _this$itemList;

          if (Gikam.isEmpty(rows)) {
            return;
          }

          _this.chooseItemList = rows;
          var items = rows.map(function (row) {
            return Gikam.getFieldValue(row, linkItemField);
          }).filter(function (item) {
            return !_this.itemList.includes(item);
          });

          (_this$itemList = _this.itemList).push.apply(_this$itemList, _toConsumableArray(items));
        }
      }));
    }
  }
};