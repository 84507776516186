<template>
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-1086.000000, -149.000000)" fill-rule="nonzero">
                <g transform="translate(1086.000000, 149.000000)">
                    <path
                        d="M7,13.3333333 C6.73333333,13.3333333 6.5,13.1 6.5,12.8333333 L6.5,1.16666667 C6.5,0.9 6.73333333,0.666666672 7,0.666666672 C7.26666667,0.666666672 7.5,0.9 7.5,1.16666667 L7.5,12.8333333 C7.5,13.1 7.26666667,13.3333333 7,13.3333333 Z"
                        fill="currentColor"
                    ></path>
                    <path
                        d="M12.8333333,7.5 L1.16666667,7.5 C0.9,7.5 0.666666672,7.26666667 0.666666672,7 C0.666666672,6.73333333 0.9,6.5 1.16666667,6.5 L12.8333333,6.5 C13.1,6.5 13.3333333,6.73333333 13.3333333,7 C13.3333333,7.26666667 13.1,7.5 12.8333333,7.5 Z"
                        fill="currentColor"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
