<template>
    <div
        class="cascade-select"
        @mousedown.stop
        :style="inputStyle"
        :class="{ readonly, invisible, active: activeFlag }"
        @click.stop="clickHandler"
        @select="focus"
    >
        <div class="cascade-select-input" v-if="formatterText || invisible">{{ formatterText }}</div>
        <div class="cascade-select-input cascade-select-placeholder" v-else>请选择</div>
        <div class="cascade-select-icon" v-if="!readonly && !invisible">
            <arrow-down v-bind="{ color: '#666666', width: '10px', height: '10px' }" />
        </div>
        <div class="validate-error-icon" v-if="!validateResult">
            <info-image
                v-bind="{ width: '16px', height: '16px' }"
                @mouseenter.stop="errorIconMouseenter"
                @mouseleave.stop="errorIconMouseleave"
            ></info-image>
        </div>
    </div>
</template>
<script>
import Gikam from 'gikam';
import BaseField from '../../baseField/baseField.vue';
import infoImage from '../../img/info.vue';

import Vue from 'vue';

import { getTextOnValue, getItemsByUrl } from './utils';

export default {
    name: 'cascadeSelectField',
    extends: BaseField,
    components: { infoImage },

    props: {
        options: Object,
        propValue: [String, Number],
        rowIndex: Number,
        cellIndex: Number,
        propReadonly: {
            type: Boolean,
            default: void 0
        },
        propInvisible: {
            type: Boolean,
            default: false
        },
        inputStyle: {
            type: Object,
            default: void 0
        },
        validateArg: {
            type: Object,
            default: void 0
        }
    },

    created() {
        if (Gikam.isEmpty(this.items) && this.options.url) {
            getItemsByUrl(this.options.url, 'root').done(rows => {
                this.items = rows;
            });
        }
    },

    data() {
        return {
            field: this.options.field,
            value: this.propValue?.split(',') || [],
            placeholder: Gikam.propI18N(this.options.placeholder),
            readonly: Gikam.isNotEmpty(this.propReadonly) ? this.propReadonly : this.options.readonly,
            invisible: this.propInvisible,
            focusFlag: false,
            activeFlag: false,
            items: this.options.items || [],
            text: getTextOnValue(this.options.items, this.propValue?.split(',') || []),
            validateResult: true,
            url: this.options.url,
            delimiter: this.options.delimiter || ','
        };
    },

    computed: {
        formatterText() {
            return this.options.formatter
                ? this.options.formatter(this.value.join(','), this.validateArg)
                : this.text.join(' / ');
        }
    },

    watch: {
        propValue(val) {
            if (val) {
                this.value = val.split(',');
                if (!this.url) {
                    this.text = getTextOnValue(this.items, this.value);
                } else {
                    this.text = this.formatterText.split(this.delimiter);
                }
            } else {
                this.value = [];
                this.text = [];
            }
        },

        propInvisible(val) {
            this.invisible = val;
        }
    },

    methods: {
        selectHandler(value, text, item) {
            const oldValue = text.toString();
            this.value = value;
            this.text = text;
            this.activeFlag = false;

            this.validateResult = this.validate(this.value.toString());
            if (Gikam.isTrue(this.validateResult)) {
                this.$emit(
                    'change',
                    this.field,
                    this.value.toString(),
                    this.rowIndex,
                    oldValue,
                    this.text.join(this.delimiter),
                    item
                );
            }
        },

        closePickerHandler() {
            this.activeFlag = false;
        },

        clickHandler() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }

            if (this.readonly) {
                return;
            }
            this.showItem();
        },

        focus() {
            this.$emit('saveCoordinate');
        },

        showItem() {
            this.activeFlag = true;
            const { items, value, text, $el, selectHandler, closePickerHandler, options } = this;

            new Vue({
                el: Gikam.createDom('div', window.top.document.body),
                components: {
                    picker: () => import('./selectPicker')
                },
                render() {
                    return (
                        <picker
                            target={$el}
                            propItems={items}
                            propValue={value}
                            propText={text}
                            propUrl={options.url}
                            optionLabel={options.optionLabel}
                            onselectNode={selectHandler}
                            onclose={closePickerHandler}
                        />
                    );
                }
            });
        },

        setItems(items) {
            this.items = Gikam.deepExtend(items);
            this.text = getTextOnValue(this.items, this.value);
        },

        //键盘控制光标移动前 单元格做的事情
        dumpActiveCell() {
            this.closePickerHandler();
        },

        //键盘控制光标移动后 单元格做的事情
        activeCell() {
            this.clickHandler();
        }
    }
};
</script>

<style lang="scss" scoped>
.cascade-select {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    background: #fff;
    display: flex;
    cursor: pointer;
    .cascade-select-input {
        flex: 1;
        display: flex;
        align-items: center;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        padding-left: 8px;
        color: rgba(0, 0, 0, 0.65);
    }
    .cascade-select-placeholder {
        flex: 1;
        color: rgba(0, 0, 0, 0.15);
    }
    .cascade-select-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 100%;
        transition: all 0.4s linear;
    }
    .validate-error-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 100%;
    }
}
.cascade-select.active {
    border-color: rgba(0, 122, 255, 0.5);
    .cascade-select-icon {
        transform: rotateZ(180deg);
    }
}
.cascade-select.readonly {
    background-color: #f4f4f4;
    cursor: text;
}

.cascade-select.invisible {
    background-color: transparent;
    border-color: transparent;
}
</style>
