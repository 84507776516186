<template>
    <div class="grid-body-lazy-loading" :class="{ visible }">
        <img src="./img/lazy-data-loading.png" />
    </div>
</template>

<script>
export default {
    props: {
        visible: Boolean
    }
};
</script>

<style lang="scss" scoped>
.grid-body-lazy-loading {
    height: 64px;
    background-color: rgba($color: #000000, $alpha: 0.5);
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    transition: opacity 0.3s;
    opacity: 0;
    pointer-events: none;

    &.visible {
        opacity: 1;
    }
}
</style>
