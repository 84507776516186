import Vue from 'vue';
const xss = require('xss');
const customXss = new xss.FilterXSS({
    whiteList: {
        span: ['style', 'contenteditable'],
        div: ['style'],
        p: [],
        sub: [],
        sup: [],
        font: ['color', 'size', 'face', 'contenteditable'],
        img: ['src', 'alt', 'title', 'width', 'height'],
        input: ['type', 'name', 'value', 'readonly', 'checked'],
        form: ['style'],
        'v:line': ['id', 'style', 'to'],
        select: [],
        option: ['value', 'selected'],
        u: [],
        ol: [],
        li: [],
        a: [],
        table: ['contenteditable', 'cellpadding', 'cellspacing', 'style', 'border'],
        tr: ['style'],
        td: ['style', 'colspan', 'rowspan'],
        th: ['style'],
        thead: ['style'],
        tbody: ['style'],
        tfoot: ['style']
    },

    css: {
        whiteList: {
            'background-color': true,
            color: true,
            width: true,
            height: true,
            display: true,
            'justify-content': true,
            'font-size': true,
            border: true,
            'text-align': true
        }
    },

    // svg图标的xss配置
    onTag(tag, html) {
        const allowTag = ['svg', 'path', 'rect', 'g', 'br'];
        if (allowTag.includes(tag.toLocaleLowerCase())) {
            return html;
        }
    }
});

Vue.mixin({
    methods: {
        $xss(text) {
            return customXss.process(text);
        }
    }
});
