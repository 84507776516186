import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default {
  install: function install() {
    var files = require.context('./', false, /filter\.js$/, 'sync');

    files.keys().forEach(function (key) {
      return files(key).default.call();
    });
  }
};