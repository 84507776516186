import { render, staticRenderFns } from "./sampling.vue?vue&type=template&id=65e4cb53&scoped=true&"
import script from "./sampling.vue?vue&type=script&lang=js&"
export * from "./sampling.vue?vue&type=script&lang=js&"
import style0 from "./sampling.vue?vue&type=style&index=0&id=65e4cb53&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e4cb53",
  null
  
)

export default component.exports