import _objectSpread from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import Gikam from 'gikam';
export default {
  props: {
    loading: Boolean,
    options: Object
  },
  computed: _objectSpread(_objectSpread({}, mapState(['columns', 'requireState', 'loadingMode'])), {}, {
    visible: function visible() {
      return Gikam.isEmpty(this.options.data) && this.loading === false;
    }
  })
};