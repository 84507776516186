//
//
//
//
//
//
//
import Gikam from '../../../core/gikam-core';
export default {
  props: {
    options: Object,
    propValue: String
  },
  data: function data() {
    return {
      field: this.options.field,
      value: Gikam.isEmpty(this.options.text) ? Gikam.isEmpty(this.propValue) ? this.options.value : this.propValue : this.options.text
    };
  },
  watch: {
    propValue: function propValue(value) {
      this.value = value;
    }
  },
  methods: {
    clickHandle: function clickHandle() {
      this.$emit('click', this.options.field, this.value);
    }
  }
};