<template>
    <div :class="formGroupClass" v-show="!hide" :style="formGroupStyle" :key="field.field">
        <template v-if="field.field !== undefined">
            <div class="title" :title="formatTitleTip(field)" :style="titleStyleObject">
                <span class="text" :class="textClassObject" :style="textStyleObject" @click="titleClickHandle">
                    {{ i18nTitle }}
                </span>
            </div>
            <div class="tip" v-if="field.tip" @mouseenter.stop="hoverTip($event, field)" @mouseleave.stop="leaveTip">
                <help-exclamation-img></help-exclamation-img>
            </div>
            <span class="colon" v-if="field.title">：</span>
            <div class="field">
                <form-item-editor :options="field" :formData="data" ref="editor" />
            </div>
            <form-group-edit-wrapper
                v-if="pageEditing"
                :options="field"
                :fields="fields"
                :active="active"
                ref="wrapper"
            ></form-group-edit-wrapper>
        </template>
    </div>
</template>

<script>
import Gikam from 'gikam';
import formItemEditor from './formItemEditor';
import { mapState } from 'vuex';

export default {
    props: {
        field: Object,
        columns: Number,
        data: Object,
        fields: Array,
        formOptions: Object
    },

    created() {
        this.form.formGroupManager[this.field.key || this.field.field] = this;
    },

    components: {
        formItemEditor,
        formGroupEditWrapper: () => import('./formGroupEditWrapper')
    },

    inject: ['form', '$window'],

    data() {
        return {
            tipPanelTimer: null,
            cleanTtipPanelTimer: null,
            tipPanel: null,
            pageEditActive: false,
            drag: false,
            active: false,
            hide: false
        };
    },

    computed: {
        ...mapState(['pageEditing', 'form']),

        i18nTitle() {
            return this.propI18N(this.field.title);
        },

        formGroupClass() {
            const classList = ['form-group', this.formOptions.layout];
            return classList;
        },

        colspan() {
            return this.field.colspan ?? 1;
        },

        formGroupStyle() {
            const style = {
                width: this.field.width ? `${this.field.width}px` : `${(this.colspan / this.columns) * 100}%`
            };
            return style;
        },

        titleStyleObject() {
            const style = {
                justifyContent: this.field.titleAlign === 'left' ? 'flex-start' : null
            };
            if (this.field.title) {
                if (this.formOptions.titleWidthAuto) {
                    style.width = 'auto';
                } else {
                    style.width = `${this.field.titleWidth ?? this.formOptions.titleWidth}px`;
                }
            }
            return style;
        },

        textClassObject() {
            const classList = [];
            const isNotEmpty = this.field.validators?.includes('notEmpty');
            isNotEmpty && classList.push(this.field.titleHighlightNotEmpty ? 'titleHighlight' : 'hasRedStar');
            return classList;
        },

        textStyleObject() {
            const style = {};
            const titleStyleFormatter = this.field.titleStyleFormatter;
            if (titleStyleFormatter) {
                return titleStyleFormatter(this.field.field, this.data);
            }
            return style;
        }
    },

    methods: {
        propI18N(text) {
            return Gikam.propI18N(text);
        },

        formatTitleTip(item) {
            const title = item.titleTip ? item.titleTip : item.title;
            return this.propI18N(title);
        },

        hoverTip(e, field) {
            this.tipPanelTimer = setTimeout(() => {
                this.showTipPanels(e, field);
            }, 500);
        },

        showTipPanels(event, { tip }) {
            const hoverPanel = () => {
                this.cleanTtipPanelTimer && clearTimeout(this.cleanTtipPanelTimer);
            };
            this.tipPanel = new Gikam.Vue({
                el: Gikam.createDom('div', document.body),
                components: {
                    tipPanel: () => import('../components/tipPanel.vue')
                },
                methods: {
                    close() {
                        this.$refs.vm.close();
                    },
                    hoverPanel: hoverPanel
                },
                render() {
                    return (
                        <tip-panel
                            ref="vm"
                            targetDom={event.target}
                            content={tip}
                            onleavePanel={this.close}
                            onhoverPanel={this.hoverPanel}
                        ></tip-panel>
                    );
                }
            });
        },

        leaveTip() {
            this.tipPanelTimer && clearTimeout(this.tipPanelTimer);
            if (this.tipPanel) {
                this.cleanTtipPanelTimer = setTimeout(() => {
                    this.tipPanel.close();
                    this.tipPanel = null;
                }, 500);
            }
        },

        titleClickHandle() {
            this.form.trigger('titleClick', this.field.field, this.i18nTitle);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../css/formFieldGroup.scss';
</style>
