<template>
    <div
        class="date-input"
        :class="{ 'validate-error': !validateResult, readonly: readonly, invisible: invisible }"
        @dblclick="dblclickHandle"
        @mousedown.stop
        @click="clickHandle"
    >
        <div v-if="readonly || invisible" class="readonly-text">{{ value }}</div>
        <template v-else>
            <input
                ref="input"
                :style="style"
                :name="options.field"
                v-model="value"
                :readonly="editable"
                autocomplete="off"
                @change="changeHandle"
            />
            <div class="date-input-icon" @click="focus"></div>
        </template>
        <div class="validate-error-icon" v-if="validateResult === false" :class="{ input: !readonly }">
            <info-image @mouseenter.stop="errorIconMouseenter" @mouseleave.stop="errorIconMouseleave"></info-image>
        </div>
    </div>
</template>
<script>
import Gikam from 'gikam';
import infoImage from '../../img/info.vue';
import BaseField from '../../baseField/baseField.vue';

const defaultOptions = {
    defaultHour: void 0,
    defaultMinute: void 0,
    isClickPop: false,
    editable: void 0,
    showToday: true,
    enableSecond: true,
    autoToggleOnTimeInput: false,
    style: {
        backgroundColor: '#fff'
    }
};

export default {
    name: 'dateField',
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        propValue: String,
        rowIndex: Number,
        cellIndex: Number,
        //编辑器中的边框等样式是否显示出来
        propInvisible: {
            type: Boolean,
            default: false
        },
        // 弹框显示时是否销毁其他下拉面板
        destroyOtherDrop: {
            type: Boolean,
            default: true
        }
    },
    components: { infoImage },
    extends: BaseField,
    computed: {
        editable() {
            const editable = this.options.editable || defaultOptions.editable;
            return editable === true ? false : 'readonly';
        },

        propHour() {
            return this.options.defaultHour;
        },

        propMinute() {
            return this.options.defaultMinute;
        }
    },

    data() {
        return {
            style: this.options.style || defaultOptions.style,
            validateResult: true,
            field: this.options.field,
            readonly: this.options.readonly,
            value: Gikam.isNotEmpty(this.propValue) ? this.propValue : this.options.value ? this.options.value : '',
            invisible: this.propInvisible,
            dateRange: this.options.dateRange,
            defaultHour: defaultOptions.defaultHour,
            defaultMinute: defaultOptions.defaultMinute,
            pickerValue: this.options.value,
            isClickPop: this.options.isClickPop || defaultOptions.isClickPop,
            showToday: this.options.showToday ?? defaultOptions.showToday
        };
    },

    watch: {
        value(val, oldVal) {
            if (val == oldVal) {
                return;
            }
            if (this.form || this.grid) {
                this.validateResult = this.validate();
            } else {
                this.validateResult = true;
            }
        },

        propValue(val) {
            this.pickerValue = val;
        },

        pickerValue(val, oldVal) {
            this.value = val;
            if (val == oldVal) {
                return;
            }
            if (this.form || this.grid) {
                this.validateResult = this.validate();
            } else {
                this.validateResult = true;
            }
            if (Gikam.isTrue(this.validateResult) && Gikam.DateUtils.toDate(val) != 'Invalid Date') {
                this.$emit('change', this.options.field, this.value, this.rowIndex, oldVal);
            }
        },

        propInvisible(val) {
            this.invisible = val;
        },

        'options.readonly': {
            handler(val) {
                this.readonly = val;
            }
        }
    },

    beforeDestroy() {
        this.datePicker = null;
    },

    methods: {
        // eslint-disable-next-line complexity
        popDatePicker() {
            const _this = this;
            if (this.datePicker) {
                return;
            }

            if (this.destroyOtherDrop === true && !Gikam.isTrue(this.propInvisible)) {
                Gikam.simulatedEvent(document, 'mousedown');
            }

            if (this.readonly) {
                return;
            }

            let datePicker = document.body.querySelector('.date-picker');
            Gikam.getInstance(datePicker) && Gikam.getInstance(datePicker).destroy();
            if (this.options.onBeforeSelect) {
                this.options.onBeforeSelect.apply(this.form || this.grid);
            }

            this.datePicker = Gikam.create('DatePicker', {
                dateInput: this,
                date:
                    Gikam.DateUtils.toDate(this.value) == 'Invalid Date'
                        ? new Date()
                        : Gikam.DateUtils.toDate(this.value),
                chooseDate:
                    Gikam.DateUtils.toDate(this.value) == 'Invalid Date'
                        ? new Date()
                        : Gikam.DateUtils.toDate(this.value),
                enableSecond: this.options.enableSecond ?? defaultOptions.enableSecond,
                autoToggleOnTimeInput: this.options.autoToggleOnTimeInput ?? defaultOptions.autoToggleOnTimeInput,
                type: this.options.type,
                isClickPop: this.isClickPop,
                defaultHour: this.formmatDefaultHour(Gikam.isNumber(this.propHour) ? this.propHour : this.defaultHour),
                defaultMinute: this.formmatDefaultMinute(
                    Gikam.isNumber(this.propMinute) ? this.propMinute : this.defaultMinute
                ),
                closeEvent: () => {
                    this.$refs.input?.focus();
                },
                onBeforeDaysChange(beginDate, endDate) {
                    return _this.options.onBeforeDaysChange
                        ? _this.options.onBeforeDaysChange(beginDate, endDate)
                        : Gikam.getDeferred().resolve();
                }
            });
        },

        setDateRange(dateRange) {
            this.dateRange = dateRange;
        },

        dblclickHandle() {
            this.$emit('dblclick', this);
        },

        dumpActiveCell() {
            this.datePicker && this.datePicker.destroy();
            this.$refs.input && this.$refs.input.blur();
        },

        activeCell() {
            if (this.invisible) {
                this.$emit('click', this);
            } else {
                this.focus();
            }
        },

        focus() {
            this.$refs.input && this.$refs.input.focus();
            this.popDatePicker();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (this.isClickPop) {
                this.focus();
            }
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        },

        override(props) {
            Gikam.extend(defaultOptions, props);
        },

        formmatDefaultHour(hour) {
            let pHour = parseInt(hour);
            if (pHour >= 0 && pHour <= 23) {
                return pHour % 10 === pHour ? '0' + pHour : '' + pHour;
            }
        },

        formmatDefaultMinute(minute) {
            let pMinute = parseInt(minute);
            if (pMinute >= 0 && pMinute <= 59) {
                return pMinute % 10 === pMinute ? '0' + pMinute : '' + pMinute;
            }
        },

        changeHandle(e) {
            if (this.form || this.grid) {
                this.validateResult = this.validate();
            } else {
                this.validateResult = true;
            }
            if (Gikam.DateUtils.toDate(e.target.value) != 'Invalid Date' && Gikam.isTrue(this.validateResult)) {
                this.$emit('change', this.options.field, this.value, this.rowIndex);
            } else {
                this.value = '';
            }
        }
    }
};
</script>

<style scoped>
.date-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    color: #666;
    font-size: 12px;
}

.filter .date-input {
    height: 24px;
}

.date-input.readonly {
    display: flex;
    align-items: center;
}

.date-input input,
.date-input .readonly-text {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-right: 30px;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
}

.date-input .readonly-text {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
}

.date-input.validate-error .readonly-text,
.date-input.validate-error input {
    border-color: #ff6e6e;
}

.date-input input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.date-input .date-input-icon {
    width: 30px;
    background: url(../../../../../img/date/date.svg) no-repeat center;
    cursor: pointer;
    margin-left: -30px;
    background-size: 18px;
}

.invisible .readonly-text {
    background-color: transparent;
    border: none;
}

.date-input .validate-error-icon {
    margin-left: -25px;
    display: flex;
    align-items: center;
}
.date-input.validate-error input {
    padding-right: 53px;
}
.date-input .validate-error-icon.input {
    margin-left: -48px;
}

.date-input .validate-error-icon svg {
    height: 20px;
    cursor: pointer;
}
</style>
