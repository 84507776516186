<template>
    <viewer-vue :options="options" :images="images" @inited="inited" ref="viewer" class="viewer">
        <template slot-scope="scope">
            <img v-for="src in scope.images" :src="src" :key="src" />
        </template>
    </viewer-vue>
</template>
<script>
import 'viewerjs/dist/viewer.css';
import ViewerVue from 'v-viewer/src/component.vue';

export default {
    name: 'viewer',
    components: {
        ViewerVue
    },
    props: {
        images: Array,
        imageRotate: Number
    },
    data() {
        return {
            options: {
                inline: true,
                button: false,
                navbar: false,
                title: false,
                toolbar: true,
                tooltip: false,
                movable: true,
                zoomable: true,
                rotatable: true,
                scalable: true,
                transition: true,
                fullscreen: true,
                keyboard: true
            }
        };
    },
    methods: {
        inited(viewer) {
            this.$viewer = viewer;
            this.$viewer.show();
            setTimeout(() => {
                this.$viewer.rotateTo(this.imageRotate);
            }, 1000);
        },

        resize() {
            setTimeout(() => {
                this.$viewer.resize();
            });
        }
    }
};
</script>
<style>
.viewer,
.viewer-toolbar > ul > li.viewer-next,
.viewer-toolbar > ul > li.viewer-play,
.viewer-toolbar > ul > li.viewer-prev {
    display: none;
}
</style>
