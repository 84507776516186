<template>
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 14 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-773.000000, -310.000000)" fill="currentColor">
                <g transform="translate(764.000000, 306.000000)">
                    <g
                        transform="translate(16.000000, 12.000000) rotate(90.000000) translate(-16.000000, -12.000000) translate(8.000000, 5.000000)"
                    >
                        <polygon points="7 0 9 0 9 14 7 14"></polygon>
                        <path
                            d="M13.5452556,5.22020058 L13.5452556,6.53378195 L9.5,6.53378195 L9.5,7.55244514 L13.5452556,7.55244514 L13.5452556,8.77979942 L15.2982064,7 L13.5452556,5.22020058 Z"
                            stroke="currentColor"
                        ></path>
                        <path
                            d="M2.45474445,5.22020058 L0.70179363,7 L2.45474445,8.77979942 L2.45474445,7.55244514 L6.5,7.55244514 L6.5,6.53378195 L2.45474445,6.53378195 L2.45474445,5.22020058 Z"
                            stroke="currentColor"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
