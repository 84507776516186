import Base from '../../base';
import Vue from 'vue';
import btn from '../vue/button.vue';
import Gikam from 'gikam';

export default class Button extends Base {
    constructor(options) {
        super('Button');
        this.initialize(options).init();
    }

    createViewModel() {
        const options = this.options;
        new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            components: { btn },
            render() {
                return <btn options={options}></btn>;
            }
        });
    }

    init() {
        this.createViewModel();
    }
}
