<template>
    <div class="ckeditor" :class="[{ 'validate-error': !validateResult }, options.type]">
        <div v-if="readonly && !maskClose" class="ckeditor-readOnly" :style="{ height: height + 'px' }">
            <scroll :innerPanel="true">
                <div class="html" v-html="$xss(value)" @click.prevent></div>
            </scroll>
        </div>
        <div v-else-if="readonly && maskClose" class="autoHeight">
            <div v-html="$xss(value)"></div>
        </div>
        <div
            v-else
            class="container"
            :class="{ 'validate-error': !validateResult && eventType === 'blur' }"
            :style="{ height: height + 'px' }"
        >
            <iframe :src="getSrc()" :name="`ckeditor-${this.field}`" ref="iframe"></iframe>
            <div class="validate-error-icon" v-if="validateResult === false && eventType === 'blur'" @click.stop>
                <info-image
                    width="20px"
                    height="20px"
                    @mouseenter.stop="errorIconMouseenter"
                    @mouseleave="errorIconMouseleave"
                ></info-image>
            </div>
        </div>
    </div>
</template>
<script>
import Gikam from 'gikam';
import BaseField from '../baseField/baseField.vue';
import infoImage from '../img/info.vue';
export default {
    props: ['options', 'propValue', 'rowIndex'],
    extends: BaseField,
    components: { infoImage },

    data() {
        return {
            validateResult: true,
            eventType: 'change',
            readonly: this.options.readonly,
            maskClose: Gikam.isFalse(this.options.mask) || false,
            field: this.options.field,
            value: this.propValue,
            height: this.options.height,
            buttonValidate: false,
            setDataState: false
        };
    },

    watch: {
        readonly(readonly) {
            if (Gikam.isFalse(readonly)) {
                this.$nextTick(() => {
                    this.initEditor();
                });
            }
        },

        propValue: {
            handler(val) {
                if (this.value === val) {
                    return;
                }

                if (val === undefined) {
                    this.setDataState = true;
                }
                this.value = val;
                if (this.iframe) {
                    Gikam.finalDelay(
                        `ckeditorSetData${this._uid}`,
                        () => {
                            const editor = this.iframe.ckeditor;
                            let status = editor.status;
                            if (status === 'ready') {
                                editor.setData(val === undefined ? '' : val);
                            } else {
                                Object.defineProperty(editor, 'status', {
                                    get() {
                                        return status;
                                    },
                                    set(value) {
                                        if (value === 'ready') {
                                            editor.setData(val === undefined ? '' : val);
                                        }
                                        status = value;
                                    }
                                });
                            }
                        },
                        200
                    );
                }
            }
        }
    },

    mounted() {
        this.initEditor();
    },

    beforeDestroy() {
        const iframeDom = this.$refs.iframe;
        if (iframeDom) {
            iframeDom.src = '';
        }
        this.iframe = null;
    },

    methods: {
        saveValue(val, type) {
            if (this.setDataState || val === undefined) {
                this.setDataState = false;
                return;
            }
            const _value = val.substr(-1, 1) === '\n' ? val.substr(0, val.length - 1) : val;
            if (this.options.type == 'script') {
                this.value = _value ? _value.replace(/<\/?p[^>]*>/gi, '') : '';
            } else {
                this.value = _value;
            }
            Gikam.finalDelay(
                'ckeditorEvent',
                () => {
                    this.eventType = type;
                    this.validateResult = this.validate();
                    if (Gikam.isTrue(this.validateResult)) {
                        this.$emit('change', this.options.field, this.value, this.rowIndex);
                        this.iframe && this.iframe.changeBorderColor('#d1d1d1');
                    } else {
                        type === 'blur' && this.iframe && this.iframe.changeBorderColor('#ff6e6e');
                    }
                },
                200
            );
        },

        errorIconMouseenter(event) {
            this.showErrorPanel(event);
        },

        errorIconMouseleave(event) {
            this.removeErrorPanel(event);
        },

        initEditor() {
            if (this.readonly) {
                return;
            }
            let iframe = this.$el.querySelector('iframe');
            iframe.onload = function() {
                //加载完毕初始化编辑页面
                let options = {
                    type: this.options.type === 'ckeditor' ? 'Ckeditor' : 'ScriptEditor',
                    options: this
                };
                this.iframe = iframe.contentWindow && iframe.contentWindow.init(options);
            }.bind(this);
        },

        getSrc() {
            // return Gikam.IFM_CONTEXT + '/ckeditor/index.html';
            return Gikam.IFM_CONTEXT + '/static/plugin/ckeditor/index.html';
        }
    }
};
</script>

<style scoped>
.ckeditor {
    height: 100%;
    width: 100%;
    min-height: 32px;
}

.ckeditor .ckeditor-readOnly {
    height: 200px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #f4f4f4;
    font-family: Microsoft YaHei, serif;
    font-size: 12px;
    position: relative;
    line-height: 28px;
}

.ckeditor .autoHeight {
    padding: 8px;
    font-family: Microsoft YaHei, serif;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    background-color: #f4f4f4;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
}

.ckeditor.script .ckeditor-readOnly {
    height: 30px;
}

.ckeditor > .container {
    border-radius: 4px;
    border: 1px solid transparent;
    box-sizing: content-box;
}

.ckeditor.script > .container {
    height: 30px;
}

.ckeditor > .container {
    height: 350px;
}

.ckeditor > .container > iframe {
    border: none;
    width: 100%;
    height: 100%;
}

.ckeditor > .container.validate-error {
    position: relative;
}

.ckeditor > .container > .validate-error-icon {
    position: absolute;
    right: 4px;
    top: 5px;
    display: flex;
    align-items: center;
}

.ckeditor > .container > .validate-error-icon svg {
    height: 20px;
    width: 20px;
    cursor: pointer;
}
</style>
