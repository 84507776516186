<template>
    <a-tooltip
        :placement="options.placement"
        :overlayClassName="options.className"
        :destroyTooltipOnHide="true"
        :visible="visible"
        @click="clickHandle"
        @mouseenter.native="mouseenterHandle"
        @mouseleave.native="mouseleaveHandle"
    >
        <span v-if="options.renderType === 'html'" slot="title" v-html="options.title"></span>
        <span v-else slot="title">{{ options.title }}</span>
        <slot></slot>
    </a-tooltip>
</template>

<script>
import Vue from 'vue';
import { Tooltip } from 'ant-design-vue';

Vue.use(Tooltip);

export default {
    props: {
        options: {
            default() {
                return {};
            },
            type: Object
        }
    },

    data() {
        return {
            visible: false,
            timeout: null
        };
    },

    methods: {
        mouseenterHandle() {
            this.timeout = setTimeout(() => {
                this.$emit('beforeDisplay');
                const { visible, title } = this.options;
                if (visible === false || !title) {
                    return;
                }
                this.visible = true;
            }, this.options.mouseEnterDelay || 500);
        },

        mouseleaveHandle() {
            clearTimeout(this.timeout);
            this.visible = false;
        },

        clickHandle() {
            this.mouseleaveHandle();
        }
    }
};
</script>
