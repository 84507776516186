import "core-js/modules/es.function.name.js";
import Gikam from 'gikam';
import Base from '@/gikam/js/components/base';
import Vue from 'vue';
export function addComponent(action) {
  var _action = action(),
      name = _action.name;

  var customComponent = function customComponent(options) {
    this.init = function () {
      this.model = new Vue(Object.assign(action(options), {
        el: Gikam.createDom('div', this.options.renderTo)
      }));
    };

    this.initialize(options, {});
    this.init();
  };

  customComponent.prototype = new Base(name);
  Gikam.component[name] = customComponent;
}