<template>
    <div
        class="text-addon-input"
        :class="textAddonClass"
        :style="inputStyle"
        @click="clickHandle"
        @select="focus"
        @mousedown.stop
    >
        <div v-if="readonly || invisible" class="readonly-text">{{ value }}</div>
        <input
            v-else
            ref="input"
            :name="field"
            v-model.lazy.trim="value"
            :placeholder="placeholder"
            @focus="focusHandler"
            @blur="blurHandler"
            :style="inputStyle"
            autocomplete="off"
        />

        <div class="addon" v-if="addon" v-html="addon" :style="options.addonStyle"></div>
    </div>
</template>
<script>
import Gikam from 'gikam';

export default {
    name: 'textAddonField',
    props: {
        options: Object,
        propValue: [String, Number],
        rowIndex: Number,
        cellIndex: Number,
        propReadonly: {
            type: Boolean,
            default: void 0
        },
        propInvisible: {
            type: Boolean,
            default: false
        },
        inputStyle: {
            type: Object,
            default: void 0
        }
    },

    computed: {
        addon() {
            return this.options.addon || '';
        },

        field() {
            return this.options.field;
        },

        placeholder() {
            return Gikam.propI18N(this.options.placeholder);
        },

        textAddonClass() {
            const classList = [{ active: this.focusState, readonly: this.readonly, invisible: this.invisible }];
            classList.push(this.options.addonPosition);
            return classList;
        }
    },

    data() {
        return {
            value: this.propValue,
            readonly: this.propReadonly || this.options.readonly,
            invisible: this.propInvisible,
            focusState: false
        };
    },

    watch: {
        value(val,oldVal) {
            this.$emit('change', this.options.field, val, this.rowIndex, {oldValue:oldVal});
        },

        propValue(val) {
            this.value = val;
        },

        propInvisible(val) {
            this.invisible = val;
        },

        propReadonly(val) {
            this.readonly = val;
        }
    },

    methods: {
        focusHandler() {
            this.focusState = true;
        },

        blurHandler() {
            this.focusState = false;
        },

        //键盘控制光标移动前 单元格做的事情
        dumpActiveCell() {
            this.$refs.input && this.$refs.input.blur();
        },

        //键盘控制光标移动后 单元格做的事情
        activeCell() {
            if (this.invisible) {
                this.$emit('click', this);
            } else {
                this.focus();
            }
        },

        //用于 cellEditor 的click事件处理当前单元格
        focus() {
            this.$emit('saveCoordinate');
            this.$refs.input && this.$refs.input.focus();
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (this.invisible) {
                this.$emit('click', this);
            }
        }
    }
};
</script>

<style scoped lang="scss">
.text-addon-input {
    width: 100%;
    height: 100%;
    padding: 0;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.65);
    background-color: #fff;
    display: flex;
    align-items: center;
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;

    &.left {
        flex-direction: row-reverse;

        > .addon {
            padding: 0 4px;
            background-color: #f4f4f4;
            border-right: 1px solid #d9d9d9;
        }
    }

    .readonly-text {
        color: inherit;
    }

    .readonly-text,
    input {
        flex: 1;
        padding-right: 4px;
        padding-left: 4px;
    }

    & > input {
        border: none;
        color: rgba(0, 0, 0, 0.65);
        font-family: 'Microsoft YaHei', serif;
        font-size: 12px;
        height: 100%;
        min-width: 0;
    }

    .addon {
        color: #666;
        font-family: 'Microsoft YaHei', serif;
        font-size: 12px;
        padding-right: 4px;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.filter {
    .text-addon-input {
        height: 24px;
    }
}

.text-addon-input.readonly {
    background: #f4f4f4;
    color: #666;
}

.text-addon-input.invisible {
    background-color: transparent;
    border: none;
}
</style>
