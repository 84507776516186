import _objectSpread from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
import LinkGroup from '@/gikam/js/components/link-group/link-group';
import { mapState } from 'vuex';
export default {
  components: {
    LinkGroup: LinkGroup
  },
  props: {
    options: Object,
    row: Object
  },
  computed: _objectSpread({}, mapState(['grid'])),
  methods: {
    itemClickHandle: function itemClickHandle(item) {
      var _item$onClick;

      (_item$onClick = item.onClick) === null || _item$onClick === void 0 ? void 0 : _item$onClick.call(this.grid, this.row);
    }
  }
};