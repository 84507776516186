<template>
    <select-field :options="filterOptions" :propValue="propValue" @change="changeHandle"></select-field>
</template>

<script>
import SelectSource from '@/gikam/js/core/selectSource';
import Gikam from 'gikam';
import { mapState } from 'vuex';

export default {
    props: {
        options: Object,
        propValue: [String, Number]
    },

    computed: {
        ...mapState(['grid', 'headRequestData'])
    },

    data() {
        let multiple = this.options?.filter?.multiple;
        multiple === undefined && (multiple = true);
        const filterOptions = Gikam.deepExtend(this.options, {
            multiple,
            search: true
        });
        delete filterOptions.selectedIndex;
        return { filterOptions };
    },

    async created() {
        await this.initItems();
    },

    methods: {
        async initItems() {
            let items = [];
            const filter = this.filterOptions.filter;
            if (filter && !filter.category) {
                items = await this.getItemsByAllData();
            } else {
                items = await SelectSource.getItems(this.filterOptions);
            }
            if (Gikam.isNotEmpty(items) && Gikam.isEmpty(items[0].value)) {
                items.shift();
            }
            this.$set(this.filterOptions, 'items', items);
        },

        changeHandle(fieldName, value, rowIndex, oldValue, text) {
            // 下拉框由于历史原因，之前会传EQ
            for (let headField in this.headRequestData) {
                if (headField.split('_')[0] === fieldName.split('_')[0]) {
                    delete this.headRequestData[headField];
                }
            }
            this.$emit('change', { value, text, match: 'IN' });
        },

        getItemsByAllData() {
            return new Promise(resolve => {
                const params = JSON.parse(this.grid.queryParams());
                params.p.op = this.filterOptions.field.replace('ext$.', '');
                Gikam.post(this.grid.options.url, JSON.stringify(params)).done(data => {
                    const items = data.optionsList.map(item => {
                        return { text: item, value: item };
                    });
                    resolve(items);
                });
            });
        }
    }
};
</script>
