import "core-js/modules/es.array.map.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Gikam from 'gikam';
export default {
  props: {
    value: Object,
    options: {
      type: Object,
      default: function _default() {
        return {
          readonly: false,
          items: [{
            field: 'field1',
            placeholder: null,
            width: '30%',
            readonly: false
          }, {
            field: 'field2',
            placeholder: null,
            readonly: false
          }]
        };
      }
    }
  },
  data: function data() {
    return {
      baseClass: 'input-group'
    };
  },
  computed: {
    itemClass: function itemClass() {
      var classList = ["".concat(this.baseClass, "-item-wrapper"), 'text-input'];
      return classList;
    },
    readonly: function readonly() {
      return this.options.readonly;
    },
    items: function items() {
      return this.options.items;
    },
    itemStyleList: function itemStyleList() {
      return this.options.items.map(function (item) {
        var style = {};

        if (item.width) {
          style.width = Gikam.isString(item.width) ? item.width : "".concat(item.width, "px");
        } else {
          style.flex = 1;
        }

        return style;
      });
    },
    readonlyList: function readonlyList() {
      var _this = this;

      return this.items.map(function (item) {
        var _ref, _this$readonly;

        return (_ref = (_this$readonly = _this.readonly) !== null && _this$readonly !== void 0 ? _this$readonly : item.readonly) !== null && _ref !== void 0 ? _ref : false;
      });
    }
  },
  methods: {
    itemChangeHandle: function itemChangeHandle(event, item) {
      if (this.options.readonly) {
        return;
      }

      this.$emit('change', {
        name: item.field,
        value: event.target.value,
        onChange: item.onChange
      });
    },
    selectChangeHandle: function selectChangeHandle(name, value, options) {
      this.$emit('change', {
        name: name,
        value: value,
        onChange: options.onChange
      });
    }
  }
};