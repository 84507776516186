import { I18N } from '@/gikam/i18n/I18N';
export function getTopButton(_ref) {
  var auditTrailEvent = _ref.auditTrailEvent,
      backEvent = _ref.backEvent,
      printEvent = _ref.printEvent,
      importEvent = _ref.importEvent,
      addCordEvent = _ref.addCordEvent;
  var buttonList = [];

  if (auditTrailEvent) {
    buttonList.push({
      type: 'button',
      text: I18N.prop('les.comment.button.audit'),
      icon: 'view-audit',
      onClick: auditTrailEvent
    });
  }

  if (addCordEvent) {
    buttonList.push({
      type: 'button',
      text: I18N.prop('les.comment.modal.add.cord'),
      icon: 'move-down',
      onClick: addCordEvent
    });
  }

  if (printEvent) {
    buttonList.push({
      type: 'button',
      text: I18N.prop('les.comment.button.print'),
      icon: 'print',
      color: 'warning',
      onClick: printEvent
    });
  }

  if (importEvent) {
    buttonList.push({
      type: 'button',
      text: I18N.prop('les.comment.button.export.pdf'),
      icon: 'view-audit',
      color: 'info',
      onClick: importEvent
    });
  }

  if (backEvent) {
    buttonList.push({
      type: 'button',
      text: I18N.prop('les.comment.button.back'),
      icon: 'back',
      color: 'danger',
      onClick: backEvent
    });
  }

  return buttonList;
}
export function getLayoutOptions() {
  return {
    id: 'les-content-center',
    north: {
      height: 0,
      title: void 0,
      shrinkTitle: []
    },
    west: {
      title: I18N.prop('les.comment.layout.title.grid.panel'),
      shrinkTitle: [],
      width: '200px',
      resize: true
    },
    east: {
      title: I18N.prop('les.comment.layout.title.operation.panel'),
      shrinkTitle: [],
      width: 0,
      resize: true
    },
    south: {
      height: 0,
      title: void 0,
      shrinkTitle: []
    },
    center: {}
  };
}
export function getOutLayoutOptions() {
  return {
    north: {
      height: 0,
      title: void 0,
      shrinkTitle: []
    },
    west: {
      title: void 0,
      shrinkTitle: [],
      width: 0,
      resize: true
    },
    east: {
      title: void 0,
      shrinkTitle: [],
      width: 0,
      resize: true
    },
    south: {
      height: 0,
      title: void 0,
      shrinkTitle: []
    },
    center: {}
  };
}
export function getAddModelOptions() {
  return {
    title: I18N.prop('les.comment.modal.add.content'),
    id: 'les-modal',
    width: 520,
    isModal: true,
    modal: {
      trigger: function trigger() {}
    }
  };
}
export function getWestTabOptions(isTemplate) {
  return {
    id: 'les-west-tab',
    show: true,
    showIndex: isTemplate ? 0 : 1,
    shrink: false,
    fill: true,
    panels: [{
      id: 'add',
      title: I18N.prop('les.comment.west.tab.add'),
      show: isTemplate ? true : false
    }, {
      id: 'section',
      title: I18N.prop('les.comment.west.tab.section')
    }, {
      id: 'annex',
      title: I18N.prop('les.comment.west.tab.annex')
    }]
  };
}
export function getEastTabOptions(isShowAnnexUpload, isShowComments) {
  return [{
    id: 'les-east-tab',
    show: true,
    showIndex: 0,
    shrink: false,
    fill: true,
    panels: [{
      id: 'section',
      title: I18N.prop('les.comment.east.tab.affair')
    }, {
      id: 'annex',
      title: I18N.prop('les.comment.east.tab.annex'),
      show: isShowAnnexUpload ? true : false
    }, {
      id: 'comments',
      title: I18N.prop('les.comment.east.tab.comments'),
      show: isShowComments ? true : false
    }]
  }];
}
export function getAuditModelOptions() {
  return {
    title: I18N.prop('les.comment.modal.audit'),
    id: 'audit-modal',
    isModal: true,
    modal: {
      trigger: function trigger() {}
    }
  };
}
export function getAuditLayoutOptions() {
  return {
    west: {
      title: void 0,
      shrinkTitle: [],
      width: '500px',
      resize: true
    },
    north: {
      height: 0,
      title: void 0,
      shrinkTitle: []
    },
    east: {
      title: void 0,
      shrinkTitle: [],
      width: '0',
      resize: true
    },
    south: {
      height: 0,
      title: void 0,
      shrinkTitle: []
    },
    center: {}
  };
}