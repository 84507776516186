<template>
    <div class="general-group-panel">
        <div class="general-group-panel-body">
            <ul>
                <li
                    class="item"
                    @click="generalHandle('refresh')"
                    @touchend="generalHandle('refresh')"
                    v-if="showToolBtn('refresh')"
                >
                    <refreshImg class="refresh" :color="'#007AFF'" />{{ $i18n('grid.generalButtonRefresh') }}
                </li>
                <li
                    class="item"
                    @click="generalHandle('import')"
                    @touchend="generalHandle('import')"
                    v-if="showToolBtn('import')"
                >
                    <importImg class="import" :color="'#29CF68'" />{{ $i18n('grid.generalButtonImport') }}
                </li>
                <li
                    class="item"
                    @click="generalHandle('export')"
                    @touchend="generalHandle('export')"
                    v-if="showToolBtn('export')"
                >
                    <exportImg class="export" :color="'#007AFF'" />{{ $i18n('grid.generalButtonExport') }}
                </li>
                <li class="item" @touchend="generalHandle('columnResize')" v-if="isMobile()">
                    <columnResizeImg class="column-resize" />
                    {{ $i18n('grid.generalButtonColumnResize') }}
                </li>
                <li class="item" @click="generalHandle('search')" @touchend="generalHandle('search')" v-if="propFilter">
                    <icon-close-round-o v-if="grid.options.filterOpen" :style="quickSearchIconStyle" />
                    <searchImg v-else class="search" :color="'#29CF68'" />
                    {{ searchText }}
                </li>

                <li class="item" @click="generalHandle('clear')" @touchend="generalHandle('clear')">
                    <clearImg class="clear" :color="'#F9762B'" />{{ $i18n('grid.generalButtonClear') }}
                </li>
                <li
                    class="item"
                    @click="generalHandle('generalQuery')"
                    @touchend="generalHandle('generalQuery')"
                    v-if="showToolBtn('generalQuery')"
                >
                    <generalSearchImg class="generalQuery" />{{ seniorSearchText }}
                </li>
                <li class="item" @click="generalHandle('config')" @touchend="generalHandle('config')" v-if="showConfig">
                    <configImg :color="'#007AFF'" />{{ $i18n('grid.generalButtonConfig') }}
                </li>
                <li
                    v-for="(item, index) in appendButtonsToGeneral"
                    :key="index"
                    @click="appendButtonClickHandle(item)"
                    class="general-button-group-apppend-item"
                >
                    <span v-if="item.icon" :class="['icon', `icon-${item.icon}`]"></span>
                    <img v-if="item.src" class="icon" alt="" />
                    {{ $i18n(item.text) }}
                </li>
                <li
                    class="item"
                    @click.stop="generalHandle('selectAll')"
                    @touchend="generalHandle('selectAll')"
                    @touchmove.stop
                    @mousedown.stop
                    v-if="showToolBtn('selectAll')"
                >
                    <selectAllImg class="selectAll" :color="'#007AFF'" />{{ $i18n('grid.generalButtonSelectAll') }}
                </li>
                <li
                    class="item"
                    @click.stop="generalHandle('copy')"
                    @touchend="generalHandle('copy')"
                    @touchmove.stop
                    @mousedown.stop
                    v-if="showToolBtn('copy')"
                >
                    <copyImg class="copy" :color="'#007AFF'" />{{ $i18n('grid.generalButtonCopy') }}
                </li>
                <li
                    class="item"
                    @click.stop="generalHandle('paste')"
                    @touchend="generalHandle('paste')"
                    @touchmove.stop
                    @mousedown.stop
                    v-if="showToolBtn('paste')"
                >
                    <pasteImg class="paste" :color="'#007AFF'" />{{ $i18n('grid.generalButtonPaste') }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Gikam from 'gikam';
import { mapState } from 'vuex';

export default {
    props: {
        generalButtonGroup: [Array, Boolean],
        propFilter: Boolean,
        clickDestroy: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            enterPanel: false,
            appendButtonsToGeneral: []
        };
    },

    computed: {
        ...mapState(['grid', 'page', 'lazyLoad']),

        searchText() {
            return !this.grid.options.filterOpen
                ? this.$i18n('grid.generalButtonSearchOpen')
                : this.$i18n('grid.generalButtonSearchClose');
        },

        seniorSearchText() {
            return this.grid.options.genericQuery || this.grid.model.$children[0].canShowGeneralQuery
                ? this.$i18n('grid.generalSeniorSearchClose')
                : this.$i18n('grid.generalSeniorSearchOpen');
        },

        showConfig() {
            return this.showToolBtn('config') && this.grid.options.id && Gikam?.pageDesignConfig?.enable !== 'true';
        },

        quickSearchIconStyle() {
            return { color: '#007aff', fontSize: '14px' };
        }
    },

    created() {
        this.appendButtonsToGeneral = this.grid.options.appendButtonsToGeneral;
    },

    methods: {
        isMobile() {
            return Gikam.isMobile();
        },

        generalHandle(name) {
            if (this.clickDestroy) {
                Gikam.simulatedEvent(document, 'mousedown');
                setTimeout(() => this.$emit('generalButtonClick', name));
            } else {
                this.$emit('generalButtonClick', name);
            }
        },

        showToolBtn(name) {
            if (this.generalButtonGroup === false) {
                return false;
            } else {
                return this.generalButtonGroup.indexOf(name) > -1;
            }
        },

        appendButtonClickHandle(item) {
            item.onClick?.call(this.grid);
            Gikam.simulatedEvent(document, 'mousedown');
        }
    }
};
</script>

<style scoped lang="scss">
.general-group-panel {
    > .general-group-panel-body {
        min-width: 110px;
        background-color: #fff;
        box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);
        border-radius: 4px;

        > ul {
            margin: 0;
            padding: 4px 0;

            > li {
                display: flex;
                align-items: center;
                font-size: 12px;
                white-space: nowrap;
                color: rgba(0, 0, 0, 0.65);
                height: 30px;
                padding: 0 8px;
                cursor: pointer;

                &:hover {
                    background-color: rgba(0, 122, 255, 0.08);
                }

                svg {
                    width: 14px;
                    margin-right: 6px;

                    &.refresh {
                        width: 16px;
                        margin-left: -2px;
                    }

                    &.column-resize {
                        height: 18px;
                    }

                    &.export {
                        width: 16px;
                        margin-left: -2px;
                    }

                    &.search {
                        width: 16px;
                        margin-left: -2px;
                    }

                    &.clear {
                        width: 12px;
                        margin-right: 7px;
                    }

                    &.copy {
                        width: 16px;
                        margin-left: -2px;
                    }

                    &.selectAll {
                        width: 16px;
                        margin-left: -2px;
                    }

                    &.paste {
                        width: 16px;
                        margin-left: -2px;
                    }

                    &.generalQuery {
                        width: 24px;
                        margin-right: -5px;
                        margin-top: 11px;
                    }
                }

                &.general-button-group-apppend-item {
                    > .icon {
                        margin-right: 8px;
                    }

                    > img {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
    }
}
</style>
