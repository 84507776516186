/* eslint-disable complexity */
/* eslint-disable no-useless-escape */
/* eslint-disable no-redeclare */
import Gikam from './gikam-core';
import idCardNoUtil from './validator-IDCard';
import numberUtils from '../utils/NumberUtils';
import { I18N } from '@/gikam/i18n/I18N.js';

export default {
    notEmpty: function(value) {
        if (Gikam.isEmpty(value, true) || value === '') {
            return {
                result: false,
                message: Gikam.propI18N('GIKAM.VALIDATOR.REQUIRED')
            };
        } else {
            return {
                result: true
            };
        }
    },

    strLength: function(v, rule) {
        if (Gikam.isEmpty(v)) {
            return { result: true };
        }
        var numArray = rule.split(','),
            min = numArray[0],
            max = numArray[1],
            minr,
            maxr,
            reg = /\(|\)|\[|\]/g,
            minValue = min.replace(reg, ''),
            maxValue = max.replace(reg, ''),
            value = Gikam.isEmpty(v) ? '' : v.toString(),
            minMessage =
                min.indexOf('[') === 0 ? I18N.prop('validator.greaterOrEqual') : I18N.prop('validator.greaterThan'),
            maxMessage = max.substr(-1) === ']' ? I18N.prop('validator.lessOrEqual') : I18N.prop('validator.lessThan'),
            r = {
                message:
                    I18N.prop('validator.pleaseFill') +
                    minValue +
                    I18N.prop('validator.and') +
                    maxValue +
                    I18N.prop('validator.character')
            },
            minr =
                min.indexOf('(') >= 0
                    ? value.length > minValue
                        ? true
                        : false
                    : value.length >= minValue
                    ? true
                    : false,
            maxr =
                max.substr(-1) === ')'
                    ? value.length < maxValue
                        ? true
                        : false
                    : value.length <= maxValue
                    ? true
                    : false;
        if (Gikam.isEmpty(minValue)) {
            maxr === true
                ? (r.result = true)
                : ((r.result = false),
                  (r.message =
                      I18N.prop('validator.pleaseFillData') +
                      maxMessage +
                      maxValue +
                      I18N.prop('validator.characters')));
        } else if (Gikam.isEmpty(maxValue)) {
            minr === true
                ? (r.result = true)
                : ((r.result = false),
                  (r.message =
                      I18N.prop('validator.pleaseFillData') +
                      minMessage +
                      minValue +
                      I18N.prop('validator.characters')));
        } else {
            minr === true && maxr === true ? (r.result = true) : (r.result = false);
        }
        return r;
    },

    numRange: function(value, rule) {
        if (Gikam.isEmpty(value)) {
            return { result: true };
        }
        const _ruleLength = rule.length ? rule.length - 1 : 0;

        if (['(', '['].indexOf(rule[0]) < 0 || [')', ']'].indexOf(rule[_ruleLength]) < 0) {
            return { result: true };
        }

        const delimit = [rule.slice(0, 1), rule.slice(_ruleLength)];
        const numArray = rule.slice(1, _ruleLength).split(',');

        const minDelimitMessage =
            delimit[0] === '(' ? I18N.prop('validator.greaterThan') : I18N.prop('validator.greaterOrEqual');
        const maxDelimitMessage =
            delimit[1] === ')' ? I18N.prop('validator.lessThan') : I18N.prop('validator.lessOrEqual');
        const minMessage = isNaN(parseInt(numArray[0])) ? '' : minDelimitMessage + numArray[0];
        const maxMessage = isNaN(parseInt(numArray[1])) ? '' : maxDelimitMessage + numArray[1];
        const message = `${I18N.prop('validator.pleaseInput')}${minMessage}${
            minMessage && maxMessage ? I18N.prop('validator.merge') : ''
        }${maxMessage}${I18N.prop('validator.number')}`;

        const leftRes = isNaN(parseInt(numArray[0])) ? '1' : numberUtils.minus(value, numArray[0].trim()).toString();
        const rightRes = isNaN(parseInt(numArray[1])) ? '1' : numberUtils.minus(numArray[1].trim(), value).toString();
        if (leftRes.charAt(0) === '-' || rightRes.charAt(0) === '-') {
            return {
                result: false,
                message: message
            };
        }

        const minCanEqual = delimit[0] === '(' ? false : true;
        const maxCanEqual = delimit[1] === ')' ? false : true;
        if (parseFloat(leftRes) === 0 && !minCanEqual) {
            return {
                result: false,
                message: message
            };
        }
        if (parseFloat(rightRes) === 0 && !maxCanEqual) {
            return {
                result: false,
                message: message
            };
        }

        return { result: true };
    },

    number: function(value) {
        if (Gikam.isEmpty(value)) {
            return { result: true };
        }
        return !isNaN(parseFloat(value)) && isFinite(value)
            ? { result: true }
            : { result: false, message: I18N.prop('validator.inputNumber') };
    },

    phone: function(value) {
        if (Gikam.isEmpty(value)) {
            return { result: true };
        }
        if (
            /^((00|\+)?(86(?:-| )))?((\d{11})|(\d{3}[- ]{1}\d{4}[- ]{1}\d{4})|((\d{2,4}[- ]){1}(\d{7,8}|(\d{3,4}[- ]{1}\d{4}))([- ]{1}\d{1,4})?))$/.test(
                value
            )
        ) {
            return {
                result: true
            };
        } else {
            return {
                result: false,
                message: I18N.prop('validator.inputPhone')
            };
        }
    },

    email: function(value) {
        if (Gikam.isEmpty(value)) {
            return { result: true };
        }
        if (
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                value
            )
        ) {
            return {
                result: true
            };
        } else {
            return {
                result: false,
                message: I18N.prop('validator.inputEmail')
            };
        }
    },

    remote: function(value, url, field, idValue) {
        if (Gikam.isEmpty(value)) {
            return { result: true };
        }
        const result = {
            result: false,
            message: I18N.prop('validator.inputRepeat')
        };
        const param = { id: Gikam.isEmpty(idValue) ? value : idValue };
        if (field !== 'id') {
            url += '/' + field + '/' + value;
        } else {
            param.id = value;
        }
        Gikam.postSync(Gikam.printf(Gikam.getContextUrl(url), param)).done(function(r) {
            result.result = r.valid;
            if (r.message) {
                result.message = r.message;
            }
        });
        return result;
    },

    unique: function(value, url, field, idValue, otherParam) {
        if (Gikam.isEmpty(value)) {
            return { result: true };
        }
        let param;
        if (field === 'id') {
            param = { id: value };
        } else {
            param = {
                id: idValue,
                [field]: value
            };
        }
        if (Gikam.isNotEmpty(otherParam.relateFields)) {
            otherParam.relateFields.forEach(field => {
                param[field] = Gikam.getFieldValue(otherParam.data, field) || '';
            });
        }
        var result = {
            result: false,
            message: I18N.prop('validator.inputRepeat')
        };
        Gikam.postSync(Gikam.getContextUrl(url), Gikam.getJsonWrapper({ vu: param })).done(function(r) {
            result.result = r.valid;
            if (r.message) {
                result.message = r.message;
            }
        });
        return result;
    },

    ip: function(value) {
        if (Gikam.isEmpty(value)) {
            return { result: true };
        }
        if (/^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(value)) {
            return {
                result: true
            };
        } else {
            return {
                result: false,
                message: I18N.prop('validator.inputIP')
            };
        }
    },

    integer: function(value) {
        if (Gikam.isEmpty(value)) {
            return { result: true };
        }
        if (/^([+-])?\d+$/.test(value)) {
            return {
                result: true
            };
        } else {
            return {
                result: false,
                message: Gikam.propI18N('GIKAM.VALIDATOR.INTEGER')
            };
        }
    },

    uppercase: function(value) {
        if (Gikam.isEmpty(value)) {
            return { result: true };
        }

        if (/^[A-Z]+$/.test(value)) {
            return {
                result: true
            };
        } else {
            return {
                result: false,
                message: Gikam.propI18N('GIKAM.VALIDATOR.UPPERCASE')
            };
        }
    },

    idCard: function(value) {
        if (idCardNoUtil.checkIdCardNo(value)) {
            return {
                result: true
            };
        } else {
            return {
                result: false,
                message: I18N.prop('validator.inputIdCard')
            };
        }
    }
};
