import "ant-design-vue/lib/radio/style/css";
import _Radio from "ant-design-vue/lib/radio";
import Vue from 'vue';
Vue.use(_Radio);
export default {
  props: {
    options: Object,
    eventScope: Object
  },
  data: function data() {
    var _this$options$default;

    return {
      value: (_this$options$default = this.options.defaultValue) !== null && _this$options$default !== void 0 ? _this$options$default : this.options.items[0].value
    };
  },
  computed: {
    items: function items() {
      return this.options.items || [];
    },
    size: function size() {
      var _this$options$size;

      return (_this$options$size = this.options.size) !== null && _this$options$size !== void 0 ? _this$options$size : 'small';
    },
    buttonStyle: function buttonStyle() {
      var _this$options$buttonS;

      return (_this$options$buttonS = this.options.buttonStyle) !== null && _this$options$buttonS !== void 0 ? _this$options$buttonS : 'solid';
    }
  },
  methods: {
    changeHandle: function changeHandle() {
      var _this$options$onChang;

      this.$emit('change', this.value);
      (_this$options$onChang = this.options.onChange) === null || _this$options$onChang === void 0 ? void 0 : _this$options$onChang.call(this.eventScope, this.value);
    }
  }
};