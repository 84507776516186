<template>
    <div
        v-show="!hiddenGrid"
        :id="options.id"
        :style="{ height: height }"
        :class="gridClass"
        @mouseleave="scrollTypeLeave"
        @contextmenu="contextmenuHandle"
        @mousedown="gridMousedownHandle"
    >
        <!-- 通用查询 -->
        <slide-transition>
            <grid-advanced-search v-if="canShowGeneralQuery" />
        </slide-transition>

        <!-- 工具栏 -->
        <grid-toolbar :options="options"></grid-toolbar>

        <!-- 表头 -->
        <div class="grid-header">
            <grid-header
                ref="header"
                :class="{ noDataHeader: !options.data || options.data.length === 0 }"
                :propFill="options.fill"
                :propFilter="options.filter"
                :propFilterOpen="options.filterOpen"
                :propColumnResize="options.columnResize"
                :propEditorInvisible="options.editorInvisible"
                :allChecked="!(!options.data || options.data.length === 0) && allChecked"
                :propCheckContinuous="options.checkContinuous"
                :showSortArrow="options.showSortArrow"
                :propOrderField="orderField"
                :propOrderType="orderType"
                @downFillValueEvent="downFillValueEvent"
                @filterClose="() => (this.grid.options.filterOpen = false)"
                @sortListGrid="sortGrid"
            ></grid-header>
            <GridHeadProgress v-if="gridHeadProgressVisible" :loading="loading" />
        </div>

        <div
            :class="gridBodyClass"
            v-show="options.showBody"
            ref="body"
            @click.capture="gridBodyClickHandle"
            v-observe-size="{ immediate: true, handler: sizeChangeHandle }"
        >
            <div
                v-if="dragLinkHandle && scrollAutomaticSlide"
                class="scroll-type-top"
                :class="{ top: scrollType === 'top' }"
                @mouseenter="scrollTypeHandle('top')"
                @mouseleave="scrollTypeLeave"
                @mouseup="scrollTypeUp"
            ></div>
            <div
                v-if="dragLinkHandle && scrollAutomaticSlide"
                class="scroll-type-bottom"
                :class="{ bottom: scrollType === 'bottom' }"
                @mouseenter="scrollTypeHandle('bottom')"
                @mouseleave="scrollTypeLeave"
                @mouseup="scrollTypeUp"
            ></div>
            <grid-row-fixed-top :options="options" :fixedIndex="rowFixedTop" v-if="showRowFixedTop" />
            <grid-row-fixed-bottom
                :options="options"
                :fixedIndex="rowFixedBottom"
                v-if="showRowFixedBottom && showVerticalScroll"
            />
            <vuescroll :ops="gridScrollOpttions" @handle-scroll="handleScroll" ref="vs">
                <table
                    :class="bodyTableClass"
                    :style="{ width: tableWidth }"
                    @mouseleave="dragEnterIndex = null"
                    v-observe-size="{ immediate: true, handler: tableSizeChangeHandle }"
                    ref="bodyTable"
                >
                    <thead>
                        <template v-for="(item, index) in columns">
                            <th
                                v-if="getFieldVisible(item) && getFieldHideHeader(item)"
                                :key="index"
                                :style="getCellWidth(item)"
                                :colspan="item.colspan"
                            ></th>
                        </template>
                    </thead>
                    <tbody>
                        <template v-for="(row, rowIndex) in options.data">
                            <tr :is="getRowType(row)" :rowData="row" :rowIndex="rowIndex" :key="getRowDataKey(row)">
                                <template v-for="(field, cellIndex) in columns">
                                    <td
                                        :key="field.field || cellIndex"
                                        :style="getTdStyleObject(field, row)"
                                        v-if="
                                            getFieldVisible(field) &&
                                                getFieldHideHeader(field) &&
                                                !(
                                                    columnsGroupMap[cellIndex + '-' + rowIndex] &&
                                                    columnsGroupMap[cellIndex + '-' + rowIndex].hidden
                                                )
                                        "
                                        :class="[
                                            { columnsBoder: cellIndex === columnsSelectIndex },
                                            getTdClassString(field, row)
                                        ]"
                                        :rowspan="
                                            columnsGroupMap[cellIndex + '-' + rowIndex]
                                                ? columnsGroupMap[cellIndex + '-' + rowIndex].len
                                                : 1
                                        "
                                        @dblclick="tdDblclickHandle(field, row, rowIndex)"
                                        @click="tdClickHandle(field, row, rowIndex)"
                                        @contextmenu="showGeneralButtonGroup"
                                    >
                                        <template v-if="renderByCellEditor(field)">
                                            <div class="body-cell" :style="{ textAlign: contentAlign(field) }">
                                                <grid-cell-editor
                                                    :options="field"
                                                    :row="row"
                                                    :rowIndex="getRowDataIndex(row)"
                                                    :cellIndex="cellIndex"
                                                    :ref="rowIndex + '_' + cellIndex"
                                                    :tooltipOptions="tooltipOptions"
                                                    :propInvisible="options.editorInvisible"
                                                ></grid-cell-editor>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <template v-if="field.editor && renderEditor(field, row, rowIndex)">
                                                <div class="body-cell">
                                                    <cellEditor
                                                        :options="renderEditor(field, row, rowIndex)"
                                                        :row="row"
                                                        :rowIndex="getRowDataIndex(row)"
                                                        :cellIndex="cellIndex"
                                                        :ref="rowIndex + '_' + cellIndex"
                                                    />
                                                </div>
                                            </template>
                                            <template v-else>
                                                <GridCellCheckbox
                                                    v-if="field.checkbox || field.radio"
                                                    :rowData="row"
                                                    :rowIndex="rowIndex"
                                                    :fieldOptions="field"
                                                />
                                                <!-- 序号 -->
                                                <div v-else-if="field.index" class="body-cell row-index-cell">
                                                    {{ getRowIndex(row) }}
                                                </div>
                                                <!--下拉框类型显示值-->
                                                <div
                                                    v-else-if="
                                                        field.type === 'select' || field.type === 'insertableSelect'
                                                    "
                                                    class="body-cell"
                                                >
                                                    <div
                                                        class="readonly-text"
                                                        :title="getCellTitle(field, rowIndex, row)"
                                                        v-html="getSelectText(field, row, rowIndex)"
                                                        :style="getReadonlyTextStyle(field)"
                                                    ></div>
                                                </div>
                                                <!--checkbox类型显示值-->
                                                <div v-else-if="field.type === 'checkbox'" class="body-cell">
                                                    <div
                                                        class="readonly-text"
                                                        v-html="getCheckboxText(field, row, rowIndex)"
                                                        :style="getReadonlyTextStyle(field)"
                                                    ></div>
                                                </div>
                                                <div v-else-if="field.type === 'richText'" class="body-cell">
                                                    <richTextField
                                                        class="readonly-text"
                                                        :propReadonly="true"
                                                        :options="{ field: field }"
                                                        :propValue="Gikam.getFieldValue(row, field.field)"
                                                        :rowIndex="rowIndex"
                                                        :style="getReadonlyTextStyle(field)"
                                                    ></richTextField>
                                                </div>
                                                <!-- 电子签名 -->
                                                <div v-else-if="field.type === 'sign'" class="body-cell">
                                                    <signField
                                                        :propValue="getFieldValue(row, field.field)"
                                                        :options="{ field: field }"
                                                        :propReadonly="true"
                                                    ></signField>
                                                </div>
                                                <!--simpleCheckbox-->
                                                <div v-else-if="field.type === 'simpleCheckbox'" class="body-cell">
                                                    <simple-checkbox-field
                                                        :propValue="getFieldValue(row, field.field)"
                                                        :options="{ field: field }"
                                                        :propReadonly="true"
                                                        key="readonly-simpleCheckbox"
                                                    />
                                                </div>
                                                <!-- 拖动 -->
                                                <div v-else-if="field.type === 'drag'" class="body-cell">
                                                    <div
                                                        class="drag"
                                                        :class="{ cur: dragDownIndex !== null }"
                                                        @mousemove="dragMove($event)"
                                                        @mouseup="dragUp(row, rowIndex)"
                                                        @mouseenter="dragEnter(row, rowIndex)"
                                                    >
                                                        <dragImg
                                                            v-bind="{
                                                                width: '14px',
                                                                height: '14px',
                                                                color: dragDownIndex === rowIndex ? '#007AFF' : '#999'
                                                            }"
                                                            @mousedown.native="dragDown(row, rowIndex, $event)"
                                                        />
                                                    </div>
                                                </div>
                                                <div v-else class="body-cell" :field="field.field">
                                                    <div
                                                        v-if="field.iconFormatter"
                                                        class="cell-icon"
                                                        :title="getCellTitle(field, rowIndex, row)"
                                                        :class="field.iconFormatter(row)"
                                                    ></div>
                                                    <cellTagField
                                                        v-else-if="field.tagFormatter"
                                                        :options="field.tagFormatter(row)"
                                                    ></cellTagField>

                                                    <div
                                                        v-else
                                                        class="readonly-text"
                                                        :style="
                                                            ({
                                                                lineHeight: checkReadonlyText(rowIndex, field, row)
                                                            },
                                                            getReadonlyTextStyle(field))
                                                        "
                                                    >
                                                        <span
                                                            class="text"
                                                            :title="getCellTitle(field, rowIndex, row)"
                                                            :style="
                                                                ({
                                                                    lineHeight: checkReadonlyText(rowIndex, field, row)
                                                                },
                                                                getReadonlyTextStyle(field))
                                                            "
                                                        >
                                                            <span v-if="field.renderType === 'text'">
                                                                {{ getReadonlyText(rowIndex, field, row) }}
                                                            </span>
                                                            <span v-else v-html="getReadonlyText(rowIndex, field, row)">
                                                            </span>
                                                        </span>
                                                        <span class="addon" v-if="field.addon">
                                                            {{ field.addon }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                    </td>
                                </template>
                            </tr>
                        </template>
                        <total-row :options="options" :loading="loading"></total-row>
                    </tbody>
                </table>
            </vuescroll>
            <grid-body-no-record :options="options" :loading="loading" />
            <grid-body-lazy-loading v-if="lazyLoad.enable" :visible="lazyLoad.loading" />
        </div>
        <grid-footer :showBody="options.showBody" v-show="options.showPagination" @pageChange="clean"></grid-footer>
        <gridFixed
            :options="options"
            v-show="showFixedColumns"
            :rowFixedTop="rowFixedTop"
            :showFixedColumns="showFixedColumns"
            :showRowFixedTop="showRowFixedTop"
            :propTransition="transition"
            :propFilterOpen="options.filterOpen"
            :fixedIndex="rowFixedTop"
            :propOrderType="orderType"
            :propOrderField="orderField"
            :propAllChecked="allChecked"
            :scrollBottom="scrollBottom"
            :bodyHeight="bodyHeight"
            @sortListGrid="sortGrid"
            @changeAllCheck="fixedChangeAllCheck"
        ></gridFixed>
        <div class="custom-content" v-if="options.customContent" v-html="$xss(options.customContent)"></div>
        <data-lock-mask v-if="locked" :options="options" :win="$window" />
        <grid-page-edit-wrapper
            v-if="showPageEditWrapper"
            :showPageEditWrapper.sync="showPageEditWrapper"
            :options="options"
        ></grid-page-edit-wrapper>
    </div>
</template>

<script>
import gridFixed from './gridFixed.vue';
import Gikam from '../../../core/gikam-core';
import gridHeader from './header/gridHeader.vue';
import Vue from 'vue';
import cellEditor from './cellEditor';
import gridFooter from './footer/gridFooter.vue';
import totalRow from './totalRow.vue';
import { mapState, mapMutations } from 'vuex';
import GridRowFixedTop from './gridRowFixedTop.vue';
import GridRowFixedBottom from './gridRowFixedBottom.vue';
import GridConfig from './gridConfig.js';
import GridBodyNoRecord from './body/GridBodyNoRecord.vue';
import GridBodyLazyLoading from './body/GridBodyLazyLoading.vue';
import GridCellEditor from './cell/gridCellEditor.vue';
import GridPageEditWrapper from './gridPageEditWrapper';
import gridToolbar from './toolbar/gridToolbar';
import SlideTransition from '../../transition/slideTransition.vue';
import vuescroll from 'vuescroll';
import GridBodyRow from './body/grid-body-row.vue';
import GridCellCheckbox from './cell/grid-cell-checkbox.vue';
import { GridUtils } from '../js/grid-utils';
import { CellOperation } from '../js/gridCellOperation.js';
import GridBodyRowGroup from './body/row-group/grid-body-row-group.vue';
import GridHeadProgress from './loading/grid-head-progress.vue';

export default {
    components: {
        gridFixed,
        gridToolbar,
        gridHeader,
        cellEditor,
        gridFooter,
        GridCellEditor,
        GridPageEditWrapper,
        vuescroll,
        cellTagField: {
            props: {
                options: {
                    type: Object,
                    default: () => {
                        return {
                            type: '',
                            value: ''
                        };
                    }
                }
            },
            template:
                '<div class="cell-tag" :class="options.type"><div class="cell-tag-text">{{options.value}}</div></div>'
        },
        totalRow,
        GridRowFixedTop,
        GridRowFixedBottom,
        dataLockMask: () => import('@/gikam/js/components/dataLockMask/vue/dataLockMask.vue'),
        GridBodyNoRecord,
        GridAdvancedSearch: () => import('./advancedSearch/advancedSearch.vue'),
        SlideTransition,
        GridBodyLazyLoading,
        GridBodyRow,
        GridCellCheckbox,
        GridBodyRowGroup,
        GridHeadProgress
    },

    props: {
        options: Object
    },

    data() {
        return {
            Gikam: Gikam,
            hiddenGrid: this.options.hidden,
            checkedNodes: [],
            selectedRow: [],
            loading: false,
            genericQueryList: [],
            showMoreBtn: false,
            editorInvisible: this.options.editorInvisible,
            dragDownIndex: null, // drag时按下去的index
            dragEnterIndex: null, //  drag时移入的index
            dragEnterType: null, // drag时移入的状态。top/bottom
            dragPageY: 0, // drag按下时的位置
            dragDownData: null, // drag按下时的数据
            scrollType: null, // top/bottom
            groupNum: 0, // 全选时类型为group的数量
            rowActived: false,
            minCheckedCell: null, //记录最小选中行
            transition: true, // 控制固定列滚动是否有动画
            scrollAutomaticSlide: false,
            scroll: Gikam.isMobile() || this.options.scroll === 'visible' ? 'DefaultScroll' : 'scroll',
            showRowFixedTop: false,
            showRowFixedBottom: false,
            rowFixedTop: [],
            rowFixedBottom: [],
            showVerticalScroll: false, // 是否存在纵向滚动条
            orderType: void 0,
            orderField: this.options.orderField,
            locked: false,
            tooltipOptions: {
                placement: 'bottomLeft',
                title: null,
                className: 'grid-tooltip',
                renderType: 'html',
                visible: true
            },
            showPageEditWrapper: false,
            canShowGeneralQuery: false,
            scrollBottom: false,
            bodyHeight: 'auto',
            range: document.createRange(),
            selection: '',
            selectionAll: '',
            copyData: ''
        };
    },

    computed: {
        ...mapState('chooseColumns', ['columnsSelectIndex']),

        ...mapState('columnsGroup', { columnsGroupMap: 'groupMap' }),

        ...mapState([
            'requireState',
            'checkedIdsStorage',
            'storageTurn',
            '$window',
            'borderRight',
            'columns',
            'pageEditing',
            'lazyLoad',
            'page',
            'defaultColumnWidth',
            'tableWidth',
            'autoWidthColumn',
            'borderBottom',
            'checkedRowKeys',
            'activeRowData',
            'grid',
            'checkContinuous',
            'pageNum',
            'pageSize',
            'loadingMode',
            'allChecked'
        ]),

        height() {
            if (this.options.noScroll === true) {
                return void 0;
            }
            if (this.options.fill) {
                return '100%';
            }
            const height = this.options.height;
            return Gikam.isNumber(height) ? height + 'px' : height;
        },

        maxRow() {
            return this.options.data.length - 1;
        },

        maxCol() {
            return this.columns.length - 1;
        },

        minCol() {
            return this.columns.filter(item => {
                return !item.field;
            }).length;
        },

        cellLineFeed() {
            return this.options.textLineFeed;
        },

        dragLinkHandle() {
            return this.columns.some(item => {
                return item.type === 'drag';
            });
        },

        showFixedColumns() {
            return this.columns.reduce((str, item) => {
                if (item.visible !== '0' && item.fixed == true) {
                    str += item.field || item.index || item.checkbox;
                }
                return str;
            }, '');
        },

        scrollTop() {
            return this.$store.state.scrollTop;
        },

        scrollLeft() {
            return this.$store.state.scrollLeft;
        },

        gridClass() {
            const classList = ['grid'];
            this.cellLineFeed && classList.push('line-feed');
            this.dragDownIndex !== null && classList.push('user-select');
            this.options.columnsFill && classList.push('columns-fill');
            this.options.scroll === 'visible' && classList.push('scroll-visible');
            this.borderRight && classList.push('body-right-border');
            !this.borderBottom && classList.push('body-bottom-no-border');
            classList.push(this.options.rootClass);
            return classList;
        },

        gridBodyClass() {
            const classList = ['grid-body'];
            this.options.noScroll && classList.push('noScroll');
            return classList;
        },

        rowRadio() {
            return Gikam.isNotEmpty(this.columns.filter(item => item.radio));
        },

        gridScrollOpttions() {
            return {
                bar: {
                    background: 'rgba(187,187,187,0.8)',
                    opacity: 0.8,
                    size: '8px',
                    onlyShowBarOnScroll: false,
                    keepShow: this.options.scroll === 'visible'
                },
                rail: {
                    size: '8px',
                    gutterOfSide: '0'
                },
                detectResize: true
            };
        },

        bodyTableClass() {
            const classList = ['grid-body-table'];
            this.scrollBottom && classList.push('scroll-bottom');
            return classList;
        },

        gridHeadProgressVisible() {
            return this.loadingMode === 'progress';
        }
    },

    methods: {
        ...mapMutations([
            'cleanCheckedTreeNodes',
            'setLazyPage',
            'setTableWidth',
            'setAutoWidthColumn',
            'setBorderRight',
            'changeScrollTop',
            'changeScrollLeft',
            'setBorderBottom',
            'setLazyLoading',
            'cleanDataBeforeRefresh',
            'updateColumnReadonly',
            'setAllChecked'
        ]),

        //向下赋值事件句柄
        downFillValueEvent(column) {
            const field = column.field;
            let gridData = this.grid.getData();
            if (!column.downFillTargetFields) {
                gridData.forEach(dataItem => (dataItem[field] = gridData[0][field]));
            } else {
                gridData.forEach(dataItem => {
                    column.downFillTargetFields.forEach(targetItem => {
                        dataItem[targetItem] = gridData[0][targetItem];
                    });
                });
            }
            this.grid.loadData(gridData);
            this.grid.selectLatestSelectedRow();
        },

        changeHiddenGridState(arg) {
            this.hiddenGrid = arg;
        },

        transitionChange(val) {
            this.transition = val;
        },

        getColumnTitle(field) {
            return Gikam.propI18N(field.title);
        },

        getRowGroupFormatter(row) {
            return this.options.group.formatter.call(this.grid, row);
        },

        getRowHidden(row) {
            let parentGroupRow;
            this.options.data.forEach(item => {
                if (item._groupIndex == row._parentGroupIndex) {
                    parentGroupRow = item;
                }
            });
            row._hidden = !parentGroupRow._expand;
            return row._hidden;
        },

        getChecked(row, single, cascadeCheck) {
            if (single) {
                return row.checked;
            }
            if (!cascadeCheck) {
                return row.checked;
            }
            if (this.options.cascadeChildrenCheck === true) {
                return row.checked;
            }

            let checkNum = this.getCheckNum(row);
            if (row.children && checkNum == 0) {
                row.checked = false;
            } else if (row.children && checkNum != 0) {
                row.checked = true;
            }
            return row.checked;
        },

        switchRowGroup(row) {
            row._expand = !row._expand;
            this.options.data
                .filter(subRow => {
                    return subRow._parentGroupIndex === row._groupIndex;
                })
                .forEach(item => {
                    item._hidden = !row._expand;
                });
        },

        checkReadonlyText(rowIndex, field, row) {
            let val = this.getReadonlyText(rowIndex, field, row);
            if (val && typeof val == 'string' && val.indexOf('<sup>') != -1) {
                return '20px';
            } else {
                return '24px';
            }
        },

        contentAlign(field) {
            return field.contentAlign ? field.contentAlign : this.options.contentAlign;
        },

        getReadonlyText(rowIndex, field, row) {
            let text = Gikam.getFieldValue(row, field.field);
            if (field.formatter) {
                return field.formatter(rowIndex, Gikam.getFieldValue(row, field.field), row);
            } else if (field.type === 'select' && Gikam.isNotEmpty(field.items) && text) {
                return field.items.find(item => item.value === text)?.text;
            }
            // 防止脚本注入
            return this.$xss(text);
        },

        getReadonlyTitle(rowIndex, field, row) {
            let title = this.getReadonlyText(rowIndex, field, row);
            if (Gikam.isEmpty(title)) {
                return '';
            }
            if (field.titleFormatter) {
                return field.titleFormatter(row);
            }
            return (title + '').replace(/<\/?[a-zA-Z]+[^><]*>/g, '');
        },

        getFieldValue(data, field) {
            return Gikam.getFieldValue(data, field);
        },

        renderEditor(field, row, rowIndex) {
            if (field.onBeforeEditorRender) {
                const editorRenderFlag = field.onBeforeEditorRender.call(this.grid, row, rowIndex);
                if (editorRenderFlag === false) {
                    return false;
                }
                if (editorRenderFlag === true) {
                    return field;
                }
                return Gikam.deepExtend(field, editorRenderFlag);
            }
            return field;
        },

        getSelections() {
            const selections = [];
            this.checkedRowKeys.forEach(key => {
                const rowData = Gikam.deepExtend(this.grid.dataMapper[key]);
                selections.push(rowData);
                GridUtils.removeAllRowVueState(rowData);
            });
            return selections;
        },

        getSelectText(field, rowData, rowIndex) {
            let selectText = '';
            let value = Gikam.getFieldValue(rowData, field.field);
            if (field.multiple && value && value.indexOf(',') > -1) {
                value = value.split(',');
                field.items.forEach(select => {
                    if (select.value && value.some(v => v === select.value)) {
                        const text = field.formatter
                            ? field.formatter(rowIndex, select.value, rowData, select.text)
                            : select.text;
                        selectText += text + ',';
                    }
                });
                return selectText.substr(0, selectText.length - 1);
            }
            if (field.remoteSearch) {
                return field.formatter ? field.formatter(rowIndex, value, rowData, selectText) : value;
            }
            selectText = field.items
                ? field.items?.filter(item => {
                      return String(item.value) === String(value);
                  })[0]?.text
                : '';
            if (field.formatter) {
                return field.formatter(rowIndex, value, rowData, selectText);
            }
            return this.$xss(selectText);
        },

        getSelectTextTitle(field, item, rowIndex) {
            return Gikam.getTextIgnoreHtml(this.getSelectText(field, item, rowIndex));
        },

        getCheckboxText(field, item) {
            const key = field.field;
            if (item[key]) {
                let jsonString = true;
                try {
                    JSON.parse(item[key]);
                } catch {
                    jsonString = false;
                }
                let text = '';
                if (jsonString) {
                    const value = JSON.parse(item[key]);
                    text = value.reduce((total, curr) => {
                        if (parseInt(curr.checked) === 1) {
                            total += curr.text + ',';
                        }
                        return total;
                    }, '');
                } else {
                    const values = item[key].split(',');
                    field.items.forEach(row => {
                        values.forEach(value => {
                            if (row.value === value) {
                                text += row.text + ',';
                            }
                        });
                    });
                }
                return text.substr(0, text.length - 1);
            } else {
                return '';
            }
        },

        clean() {
            this.cleanDataBeforeRefresh();
            if (!this.options.checkContinuous) {
                const state = this.grid.store.state;
                state.allChecked = false;
                state.checkedRowKeys = [];
            }
            this.cleanCheckedTreeNodes();
        },

        setFieldsReadonly(index, fields, isReadonly) {
            const viewRowDataIndex = GridUtils.getViewIndexByRowDataIndex(index, this.grid);
            for (let key in this.$refs) {
                const instance = this.$refs[key];
                const component = Array.isArray(instance) ? instance[0] : instance;
                if (
                    component &&
                    component.options &&
                    fields.indexOf(component.options.field) > -1 &&
                    parseInt(key.split('_')[0]) === viewRowDataIndex
                ) {
                    component.readonly = isReadonly;
                }
            }
        },

        getAllChildren(row, result) {
            const _this = this;
            if (row.children) {
                row.children.forEach(item => {
                    if (item.children) {
                        _this.getAllChildren(item, result);
                    }
                    result.push(item);
                });
            }
            return result;
        },

        handleScroll({ scrollTop, process: vProcess }, { scrollLeft }) {
            // window容器缓存dom
            if (!document.documentElement.contains(this.$el)) {
                return;
            }
            Gikam.isNotEmpty(this.options.data) && this.changeScrollTop(scrollTop);
            this.changeScrollLeft(scrollLeft);
            const body = this.$refs.body;
            const bodyTable = this.$refs.bodyTable;
            if (Math.abs(vProcess - 1) <= 0.05) {
                this.scrollBottomHandle();
                // 滚动条滚动到底部时，Table位置并未到底
                if (!this.lazyLoad.enable && body.offsetWidth < bodyTable.offsetWidth) {
                    this.scrollBottom = true;
                }
            } else {
                this.scrollBottom = false;
            }
            if (vProcess === 0) {
                this.scrollTopHandle();
            }
        },

        bodyKeyDown(e, direction) {
            this.$el.click();
            if (this.canPositionState === undefined) {
                const canPositionsList = [
                    'textInput',
                    'number',
                    'textarea',
                    'password',
                    'richText',
                    'year',
                    'time',
                    'date',
                    'choose',
                    'select',
                    'insertableSelect',
                    'comboBox',
                    'cron',
                    'textAddon'
                ];
                this.canPositionState = this.columns.some(item => {
                    return (
                        item.readonly !== true && (canPositionsList.indexOf(item.type) >= 0 || Gikam.isEmpty(item.type))
                    );
                });
            }
            if (Gikam.isFalse(this.canPositionState)) {
                return;
            }
            if (this.moving) {
                e.stopPropagation();
                return;
            }
            this.moving = true;
            const [x = 0, y = this.minCol] = this.$store.state.activeCoordinate;

            if (direction) {
                this.inputCursorMovement(x, y, direction);
            } else if (e.keyCode === 9 && e.shiftKey) {
                this.inputCursorMovement(x, y, 'left');
            } else if (e.keyCode === 9) {
                this.inputCursorMovement(x, y, 'right');
            }
        },

        inputIndexChange(obj, index) {
            if (obj.direction === obj.reduce) {
                return index - 1;
            } else if (obj.direction === obj.add) {
                return index + 1;
            } else {
                return index;
            }
        },

        getNextInput(x, y, direction) {
            let row = x;
            let column = y;
            if (x > this.maxRow) {
                row = 0;
                column = this.inputIndexChange({ direction, reduce: 'up', add: 'down' }, y);
            } else if (x < 0) {
                row = this.maxRow;
                column = this.inputIndexChange({ direction, reduce: 'up', add: 'down' }, y);
            } else {
                row = this.inputIndexChange({ direction, reduce: 'up', add: 'down' }, x);
            }

            if (y > this.maxCol) {
                column = this.minCol;
                row = this.inputIndexChange({ direction, reduce: 'left', add: 'right' }, x);
            } else if (y < this.minCol) {
                column = this.maxCol;
                row = this.inputIndexChange({ direction, reduce: 'left', add: 'right' }, x);
            } else {
                row === x && (column = this.inputIndexChange({ direction, reduce: 'left', add: 'right' }, y));
            }
            return [row, column];
        },

        inputCursorMovement(x, y, direction, oldCell) {
            oldCell && oldCell.dumpActiveCell instanceof Function && oldCell.dumpActiveCell();

            const [next_X, next_Y] = this.getNextInput(...arguments);
            if (this.judgeCanDoing(next_X, next_Y) === true && this.judgeGroupState(next_X, next_Y) !== true) {
                this.handleCursorPosition(next_X, next_Y);
                this.doneThing(next_X, next_Y, x, y);
            } else {
                const currentCell = this.$refs[x + '_' + y];
                this.inputCursorMovement(next_X, next_Y, direction, currentCell);
            }
        },

        //判断是否可以执行移动逻辑
        judgeCanDoing(x, y) {
            const nextCell = this.$refs[x + '_' + y];
            if (
                nextCell &&
                nextCell.readonly !== true &&
                [
                    'textInput',
                    'numberField',
                    'textareaField',
                    'passwordField',
                    'richTextField',
                    'yearField',
                    'timeField',
                    'dateField',
                    'chooseField',
                    'selectField',
                    'insertableSelectField',
                    'comboBoxField',
                    'cronField',
                    'textAddonField'
                ].indexOf(nextCell.$options.name) >= 0
            ) {
                return true;
            } else {
                return false;
            }
        },

        //带有带分组
        judgeGroupState(x, y) {
            const nextCell = this.$refs[x + '_' + y];
            if (Gikam.isNotEmpty(this.grid.options.group.fields) && nextCell.validateArg.id) {
                const _data = Gikam.deepExtend(this.options.data);
                return _data.filter(row => row.id === nextCell.validateArg.id)[0]._hidden;
            } else {
                return false;
            }
        },

        async doneThing(next_X, next_Y, x, y) {
            const currentCell = this.$refs[x + '_' + y];
            currentCell && currentCell.dumpActiveCell instanceof Function && currentCell.dumpActiveCell();
            await this.$nextTick();
            const nextCell = this.$refs[next_X + '_' + next_Y];
            //this.handleActiveCurrentRow(nextCell);
            await this.$nextTick();
            nextCell.activeCell instanceof Function && nextCell.activeCell();
            await nextCell.$nextTick();
            event.keyCode === 13 && this.grid.options.enterKeyDownActive && this.grid.activeRowByIndex(next_X);
            this.moving = false;
        },

        //处理光标向前和上下的位置变动
        handleCursorPosition(nextRowIndex, nextColumnIndex) {
            const fieldComp = this.$refs[nextRowIndex + '_' + nextColumnIndex];
            if (!fieldComp || !fieldComp.$el) return;
            const scroll = this.$refs.vs;
            if (Gikam.isEmpty(scroll)) {
                return false;
            }

            const cellDom = fieldComp.$el.parentNode.parentNode;
            const cellDomWidth = cellDom.offsetWidth;
            const cellDomHeight = cellDom.offsetHeight;
            const cellDomLeft = cellDom.offsetLeft;
            const cellDomTop = cellDom.offsetTop;
            const wrapperWidth = scroll.$el.offsetWidth;
            const wrapperHeight = scroll.$el.offsetHeight;

            this.transition = false;
            const { scrollLeft, scrollTop } = scroll.getPosition();

            if (cellDomLeft < scrollLeft || cellDomLeft + cellDomWidth > scrollLeft + wrapperWidth) {
                scroll.scrollTo({ x: cellDomLeft + cellDomWidth - wrapperWidth });
            }

            if (cellDomTop < scrollTop || cellDomTop + cellDomHeight > scrollTop + wrapperHeight) {
                scroll.scrollTo({ y: cellDomTop + cellDomHeight - wrapperHeight });
            }
            setTimeout(() => {
                this.transition = true;
            }, 0);
        },

        // 排序
        sortGrid(orderField, orderType) {
            this.orderType = orderType;
            this.orderField = orderField;
            if (!orderType || !orderField) {
                delete this.grid.options.order[this.grid.options.orderField];
                this.grid.refresh();
                return;
            }
            if (this.grid.options.orderField === orderField) {
                this.grid.options.order[orderField] = orderType;
            } else {
                this.grid.options.order = this.getOrderOption();
                this.grid.options.order[orderField] = orderType;
                this.grid.options.orderField = orderField;
            }
            this.grid.refresh();
        },

        getOrderOption() {
            const { order = {}, group } = this.grid.options;
            const map = {};
            if (group?.fields) {
                group.fields.forEach(ele => {
                    map[ele] = order[ele] || 'asc';
                });
            }
            return map;
        },

        /* 拖动的方法 */
        dragMove(e) {
            if (this.dragDownIndex === null) return;
            if (e.pageY - this.dragPageY > 0) {
                this.dragEnterType = 'bottom';
            }
            if (e.pageY - this.dragPageY < 0) {
                this.dragEnterType = 'top';
            }
            this.dragPageY = e.pageY;
        },

        dragDown(data, index, e) {
            this.scrollAutomaticSlide = true;
            this.dragDownIndex = index;
            this.dragPageY = e.pageY;
            this.dragDownData = data;
        },

        dragUp(data, index) {
            this.scrollAutomaticSlide = false;
            const rowDataList = this.grid.initialRowDataList;
            if (data && this.dragDownIndex !== null && this.dragDownIndex !== index) {
                // 排序逻辑
                this.options.data.splice(this.dragDownIndex, 1);
                rowDataList.splice(this.dragDownIndex, 1);
                for (let i = 0; i < this.options.data.length; i++) {
                    if (this.options.data[i].field === data.field) {
                        let index = { top: i, bottom: i + 1 }[this.dragEnterType];
                        this.options.data.splice(index, 0, this.dragDownData);
                        rowDataList.splice(index, 0, this.dragDownData);
                        break;
                    }
                }
            }
            this.dragDownIndex = null;
            this.dragEnterIndex = null;
            this.dragPageY = 0;
            this.dragEnterType = null;
            this.dragDownData = null;
        },

        dragEnter(data, index) {
            this.dragEnterIndex = index;
        },

        dragLeave() {
            if (this.dragDownIndex === null) {
                return;
            }
            this.dragUp();
        },

        // 滑条上下滚动事件
        scrollTypeHandle(type) {
            this.scrollType = type;
            if (type === 'top') {
                this.scrollTime && clearInterval(this.scrollTime);
                this.scrollTime = null;
                this.scrollTime = setInterval(() => {
                    this.$refs['vs'] && this.$refs['vs'].scrollBy({ dy: -100 }, 300);
                }, 300);
            }
            if (type === 'bottom') {
                this.scrollTime && clearInterval(this.scrollTime);
                this.scrollTime = null;
                this.scrollTime = setInterval(() => {
                    this.$refs['vs'] && this.$refs['vs'].scrollBy({ dy: 100 }, 300);
                }, 300);
            }
        },

        scrollTypeLeave() {
            this.scrollType = null;
            clearInterval(this.scrollTime);
            this.scrollTime = null;
        },

        scrollTypeUp() {
            this.dragUp();
        },

        getFieldVisible(options) {
            const visible = options.visible;
            return visible === undefined || visible === '1' || visible === true;
        },

        // 获取表头隐藏属性hideHeader
        getFieldHideHeader(field) {
            if (field.hideHeader) {
                return false;
            }
            return true;
        },

        getCellWidth(column) {
            const style = {};
            if (column === this.autoWidthColumn) {
                style.width = null;
            } else {
                style.width = `${parseFloat(column.width || this.defaultColumnWidth)}px`;
            }
            return style;
        },

        contextmenuHandle(event) {
            if (Gikam.isEmpty(this.$store.state.contextmenu)) {
                return;
            }
            event.preventDefault();
            const { contextmenu } = this.$store.state;
            new Vue({
                el: Gikam.createDom('div', document.body),
                components: {
                    contextmenuVue: () => import('./contextmenu/contextmenuPanel')
                },
                render() {
                    return <contextmenuVue target={event.target} contextmenu={contextmenu}></contextmenuVue>;
                }
            });
        },

        getReadonlyTextStyle(field) {
            return {
                textAlign: this.contentAlign(field),
                paddingRight: field.addon ? '5px' : ''
            };
        },

        // 处理是否需要固定行
        handleRowFixed() {
            if (Gikam.isTrue(this.options.firstRowFixed)) {
                this.rowFixedTop = [0];
                this.showRowFixedTop = true;
            }
            if (this.options.sum.fixed) {
                this.rowFixedBottom = [-1];
                this.showRowFixedBottom = true;
            }
            if (Gikam.isNotEmpty(this.options.rowFixed)) {
                const showRowFixedBottom = this.showRowFixedBottom;
                const showRowFixedTop = this.showRowFixedTop;
                this.options.rowFixed.forEach(item => {
                    if (item < 0 && !showRowFixedBottom) {
                        this.rowFixedBottom.push(item);
                        this.showRowFixedBottom = true;
                    } else if (item >= 0 && !showRowFixedTop) {
                        this.rowFixedTop.push(item);
                        this.showRowFixedTop = true;
                    }
                });
                this.rowFixedTop.sort((prev, next) => prev - next);
                this.rowFixedBottom.sort((prev, next) => prev - next);
            }
        },

        // 监听是否有纵向滚动条
        hasVerticalScroll(val) {
            this.showVerticalScroll = val;
        },

        showGeneralButtonGroup(e) {
            if (!this.options.contextMenuConfig) {
                return;
            }
            if (Gikam.generalGroupPanel) {
                Gikam.generalGroupPanel.destroy();
            }
            e.preventDefault();
            let { filter } = this.grid.options;
            const generalButtonGroup = ['selectAll', 'copy', 'paste', 'refresh', 'export', 'generalQuery'];
            this.grid.options.id && generalButtonGroup.push('config');
            const left = e.pageX + 'px';
            const panelHeight = generalButtonGroup.length * 25 + (filter === false ? 0 : 2 * 25);
            const maskPosition = top + panelHeight > window.innerHeight;
            const top = e.pageY + 'px';
            const _this = this;
            const zIndex = this.getMaxZIndex();
            Gikam.generalGroupPanel = new Vue({
                el: Gikam.createDom('div', document.body),
                store: this.$store,
                methods: {
                    destroy() {
                        if (Gikam.generalGroupPanel) {
                            Gikam.removeDom(Gikam.generalGroupPanel.$el);
                            Gikam.generalGroupPanel.$destroy();
                            Gikam.generalGroupPanel = null;
                        }
                    },
                    // eslint-disable-next-line complexity
                    generalClickHandle(name) {
                        this.destroy();
                        const ele = e.target;
                        const start = ele.selectionStart;
                        const end = ele.selectionEnd;

                        if (name === 'search') {
                            _this.grid.options.filterOpen = !_this.grid.options.filterOpen;
                        } else if (name === 'clear') {
                            _this.grid.model.$refs.vm.$refs.header.resetFilter();
                        } else if (name === 'refresh') {
                            _this.grid.refresh({}, true);
                        } else if (name === 'export') {
                            _this.grid.exportExcel(null, null, true);
                        } else if (name === 'config') {
                            new GridConfig(_this.$store);
                        } else if (name === 'columnResize') {
                            _this.grid._addMask();
                        } else if (name === 'generalQuery') {
                            _this.grid.options.genericQuery = !_this.grid.options.genericQuery;
                        } else if (name === 'selectAll') {
                            CellOperation.selectAll(_this, ele);
                        } else if (name === 'copy') {
                            CellOperation.copy(_this);
                        } else if (name === 'paste') {
                            CellOperation.paste(_this, ele, start, end);
                        }
                    }
                },
                components: { generalButtonPanel: () => import('./toolbar/generalButtonPanel.vue') },
                render() {
                    return (
                        <generalButtonPanel
                            onGeneralButtonClick={this.generalClickHandle}
                            propFilter={filter}
                            propEnterButton={_this.enterButton}
                            propMaskPosition={maskPosition}
                            generalButtonGroup={generalButtonGroup}
                            grid={_this.grid}
                            clickDestroy={false}
                            style={{
                                left,
                                top,
                                width: '100px',
                                height: panelHeight + 8 + 'px',
                                position: 'absolute',
                                zIndex
                            }}
                        />
                    );
                }
            });
        },

        fixedChangeAllCheck(val) {
            this.grid.store.state.allChecked = val;
        },

        getCellTitle(field, rowIndex, row) {
            if (field.formatter) {
                return;
            }
            let title = field.titleFormatter ? field.titleFormatter(row) : this.getReadonlyText(rowIndex, field, row);
            return title;
        },

        gridMousedownHandle() {
            if (this.pageEditing) {
                this.showPageEditWrapper = true;
            }
        },

        async scrollBottomHandle() {
            if (!this.lazyLoad.enable) {
                return;
            }
            const { pageNumber, totalPage, loading } = this.lazyLoad;
            if (loading) {
                return;
            }
            if (totalPage === 1) {
                return;
            }
            if (pageNumber === totalPage - 1) {
                return;
            }
            this.setLazyLoading(true);
            setTimeout(async () => {
                this.setLazyPage(1);
                await this.$nextTick();
                this.$refs.vs.scrollTo({ y: this.$refs.bodyTable.offsetHeight / 2 - this.$refs.body.clientHeight }, 0);
                this.setLazyLoading(false);
            });
        },

        async scrollTopHandle() {
            if (!this.lazyLoad.enable) {
                return;
            }
            const { pageNumber } = this.lazyLoad;
            if (pageNumber === 1) {
                return;
            }
            this.setLazyPage(-1);
            await this.$nextTick();
            this.$refs.vs.scrollTo({ y: this.$refs.bodyTable.offsetHeight / 2 }, 0);
        },

        setColumnsWidth() {
            const wrapperWidth = this.$refs.body.clientWidth;
            const noWidthColumns = [];
            let totalWidth = 0;
            this.columns.forEach(column => {
                if (column.checkbox || column.radio || column.index === true) {
                    this.$set(column, 'width', 50);
                }
                if (!column.width) {
                    noWidthColumns.push(column);
                    totalWidth += this.defaultColumnWidth;
                } else {
                    totalWidth += parseFloat(column.width);
                }
            });
            if (totalWidth <= wrapperWidth) {
                if (this.options.columnsFill) {
                    if (Gikam.isEmpty(noWidthColumns)) {
                        this.setAutoWidthColumn(this.columns[this.columns.length - 1]);
                    } else {
                        this.setAutoWidthColumn(noWidthColumns[noWidthColumns.length - 1]);
                    }
                    this.setTableWidth(`${wrapperWidth}px`);
                } else {
                    this.setTableWidth(`${totalWidth}px`);
                }
            }
        },

        sizeChangeHandle(param) {
            if (param?.width) {
                this.setColumnsWidth();
            }
            if (param?.height) {
                this.tableSizeChangeHandle(param);
            }
        },

        tableSizeChangeHandle(param) {
            const wrapper = this.$refs.body;
            const bodyTable = this.$refs.bodyTable;
            if (param?.width) {
                const wrapperWidth = wrapper.clientWidth;
                const tableWidth = bodyTable.clientWidth;
                this.setBorderRight(tableWidth < wrapperWidth ? true : false);
            }
            if (param?.height) {
                this.setBorderBottom(bodyTable.offsetHeight < wrapper.clientHeight ? true : false);
                this.bodyHeight = `${wrapper.clientHeight +
                    this.$refs.header.$el.clientHeight +
                    (this.options.filter && this.options.filterOpen ? 39.5 : 7)}px`;
            }
        },

        async initEditorSwitch() {
            await this.$nextTick();
            if (this.options.enableKeySwitchEditor) {
                this.$refs.body.addEventListener(
                    'keydown',
                    event => {
                        event.stopPropagation();
                        if (event.keyCode === 13 || event.keyCode === 40) {
                            this.bodyKeyDown(event, 'down');
                        }
                        if (event.keyCode === 38) {
                            this.bodyKeyDown(event, 'up');
                        }
                        if (event.keyCode === 9) {
                            this.bodyKeyDown(event);
                        }
                    },
                    true
                );
            }
        },

        gridBodyClickHandle(event) {
            this.pageEditing && event.stopPropagation();
        },

        getRowType(row) {
            return GridUtils.getRowType(row) === 'group' ? 'GridBodyRowGroup' : 'GridBodyRow';
        },

        getRowDataKey(rowData) {
            return GridUtils.getRowVueState(rowData, 'key');
        },

        // 临时方法，判断是否用cellEditor的最新编辑器渲染
        renderByCellEditor(fieldOptions) {
            const type = [
                'checkboxGroup',
                'radioGroup',
                'link',
                'tree',
                'year',
                'date',
                'dateTime',
                'linkGroup',
                'dateRange'
            ];
            return type.includes(fieldOptions.type);
        },

        watchChecks() {
            Object.defineProperty(this, 'checkeds', {
                get() {
                    return this.checkedRowKeys.map(key => {
                        const rowData = this.grid.dataMapper[key];
                        return GridUtils.getRowVueState(rowData, 'rowIndex');
                    });
                }
            });
        },

        getTdStyleObject(fieldOptions, rowData) {
            const style = { ...fieldOptions.styleFormatter?.call(this.grid, rowData) };
            return style;
        },

        getTdClassString(fieldOptions, rowData) {
            const style = fieldOptions.classFormatter?.call(this.grid, rowData);
            return style;
        },

        tdDblclickHandle(fieldOptions, rowData, rowIndex) {
            this.grid.trigger('cellDbClick', fieldOptions.field, rowData, rowIndex);
        },

        tdClickHandle(fieldOptions, rowData) {
            if (fieldOptions.type === 'link') {
                return;
            }
            const rowIndex = GridUtils.getRowVueState(rowData, 'rowIndex');
            this.grid.trigger('cellClick', fieldOptions.field, Gikam.deepExtend(rowData), rowIndex);
        },

        getRowIndex(rowData) {
            const rowIndex = GridUtils.getRowVueState(rowData, 'rowIndex') + 1;
            if (this.checkContinuous) {
                return (this.pageNum - 1) * this.pageSize + rowIndex;
            }
            return rowIndex;
        },

        getRowDataIndex(rowData) {
            return GridUtils.getRowVueState(rowData, 'rowIndex');
        },

        getMaxZIndex() {
            const arr = Array.prototype.slice.call(document.body.querySelectorAll('*'), 0);
            const index = arr.reduce((max, e) => Math.max(+window.getComputedStyle(e).zIndex || 0, max), 0);
            return index + 1;
        }
    },

    watch: {
        columns() {
            this.setColumnsWidth();
        }
    },

    mounted() {
        // Gikam.jQuery(window).mouseup(() => this.dragLeave());
        this.initEditorSwitch();
        // 没啥卵用，为了兼容以前写法
        this.watchChecks();

        this.$refs.body.addEventListener('mousemove', () => {
            this.selection = window.getSelection().toString();
        });
    }
};
</script>

<style lang="scss">
.grid {
    height: 100%;
    background-color: white;
    padding: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;

    > .grid-header {
        border-bottom: none;
        overflow: hidden;
        z-index: 1;
        box-shadow: 0 2px 3.6px 0.4px rgba(0, 0, 0, 0.06);
        position: relative;
    }

    > .grid-body {
        border: 1px solid #eee;
        flex: 1;
        overflow: auto;
        height: 0;
        border-top: none;
        position: relative;

        table {
            border-collapse: collapse;
            border-spacing: 0;
            table-layout: fixed;
            position: relative;
            border-bottom: 1px solid #eee;
            overflow: hidden;
        }

        .grid-body-table {
            > thead {
                th {
                    padding: 0;
                }
            }
        }

        &.noScroll {
            height: auto;
        }

        .__vuescroll.hasVBar.hasHBar {
            > .__panel {
                .__view {
                    // &::after {
                    //     content: '';
                    //     display: block;
                    //     height: 16px;
                    //     background-color: #fff;
                    //     border-top: 1px solid #eee;
                    //     margin-bottom: -1px;
                    // }
                    > table {
                        > tbody {
                            > tr:last-child {
                                td {
                                    padding-bottom: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.body-right-border {
        > .grid-body,
        > .grid-header {
            table {
                border-right: 1px solid #eee;
            }
        }
    }

    &.body-bottom-no-border {
        > .grid-body {
            table {
                border-bottom: none;
            }
        }
    }
}

.grid > .grid-body table > tbody > tr {
    transition: transform 0.2s ease-out;
}

.grid > .grid-body table > tbody > .hide {
    float: left;
    visibility: hidden;
    transform: translateY(-100%);
    height: 0;
}

.grid > .grid-body .body-cell.select-row-checkbox-cell,
.grid > .grid-body .body-cell.select-row-radio-cell,
.grid > .grid-body .body-cell.row-index-cell {
    display: flex;
    justify-content: center;
}

.grid > .grid-body table > tbody > tr:nth-child(odd) {
    background-color: rgba(11, 184, 148, 0.05);
}

.grid > .grid-body table > tbody > tr > td > .body-cell {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 8px;
    white-space: nowrap;
}

.grid > .grid-body table > tbody > tr > td > .body-cell p {
    margin: 0;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag > .cell-tag-text {
    height: 24px;
    line-height: 23px !important;
    padding: 0 8px;
    border-radius: 4px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.success,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.success > .cell-tag-text {
    color: #00d94d;
    background-color: #e5fbed;
    border: 1px solid #66e894;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.danger,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.danger > .cell-tag-text {
    color: #ff3b30;
    background-color: #ffe2e0;
    border: 1px solid #ff8983;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.info,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.info > .cell-tag-text {
    color: #007aff;
    background-color: #e5f1ff;
    border: 1px solid #66afff;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.warning,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.warning > .cell-tag-text {
    color: #ffba00;
    background-color: #fff8e5;
    border: 1px solid #ffd666;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.guard,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.guard > .cell-tag-text {
    color: #ff8936;
    background-color: #fff3eb;
    border: 1px solid #ffd886;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > a.cell-tag.fine,
.grid > .grid-body table > tbody > tr > td > .body-cell > .cell-tag.fine > .cell-tag-text {
    color: #00c4b4;
    background-color: #e5f9f7;
    border: 1px solid #66dcd2;
    padding: 0 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell:not(.select-row-checkbox-cell):not(.select-row-radio-cell) > * {
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 24px;
    line-height: 24px;
    width: 100% !important;
}

.grid > .grid-body table > tbody > tr > td > .body-cell .simple-checkbox {
    display: flex;
    justify-content: center;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text {
    width: 100%;
    display: block;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text .text {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .readonly-text .addon {
    width: 48px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.grid > .grid-body table > tbody > tr > td > .body-cell > .text-input > .readonly-text {
    /* 解决grid组件编辑录入的结果和不可编辑的内容不对齐的问题 */
    padding-left: 8px;
}

.grid .grid-body table tbody tr td .body-cell .number-input input {
    padding-left: 8px;
}
.grid .grid-body table tbody tr td .body-cell .number-input .readonly-text {
    padding-left: 8px;
}

.grid > .grid-body table > tbody > tr > td > .body-cell .select .select-container .placeholder {
    font-size: 12px;
}

.grid > .custom-content {
    color: rgba(0, 0, 0, 0.65);
    width: 100%;
    font-size: 14px;
}

.grid > .grid-body table > tbody > tr:hover {
    background-color: rgba(11, 184, 148, 0.08);
}

.grid > .grid-body table > tbody > tr.active {
    background-color: rgba(0, 122, 255, 0.16);
}

.grid .row-group > .body-cell-group {
    line-height: 32px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.grid .row-group > .body-cell-group > .switch {
    float: left;
    height: 100%;
    width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.grid [parent-group-index].hidden {
    display: none;
}

.grid.grid-fixed {
    position: absolute;
    left: 0;
    bottom: 44px;
    padding: 0 !important;
    height: auto;
    overflow: hidden;
}

.grid-fixed .grid-body {
    overflow: hidden;
}

.__rail-is-horizontal {
    z-index: 2 !important;
}

.grid.line-feed
    > .grid-body
    table
    > tbody
    > tr
    > td
    > .body-cell:not(.select-row-checkbox-cell):not(.select-row-radio-cell)
    > * {
    white-space: normal;
    height: auto !important;
    word-break: break-all;
}

.grid.line-feed
    > .grid-body
    table
    > tbody
    > tr
    > td
    > .body-cell:not(.select-row-checkbox-cell):not(.select-row-radio-cell)
    > .text-addon-input,
.grid.line-feed
    > .grid-body
    table
    > tbody
    > tr
    > td
    > .body-cell:not(.select-row-checkbox-cell):not(.select-row-radio-cell)
    > .color-picker,
.grid.line-feed
    > .grid-body
    table
    > tbody
    > tr
    > td
    > .body-cell:not(.select-row-checkbox-cell):not(.select-row-radio-cell)
    > .number-input {
    height: 20px;
}

.grid.line-feed
    > .grid-body
    table
    > tbody
    > tr
    > td
    > .body-cell:not(.select-row-checkbox-cell):not(.select-row-radio-cell)
    > .select {
    height: auto;
}

.grid.line-feed
    > .grid-body
    table
    > tbody
    > tr
    > td
    > .body-cell:not(.select-row-checkbox-cell):not(.select-row-radio-cell)
    > .color-picker
    .color-picker__choose {
    top: 0;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell > .readonly-text {
    height: auto;
    white-space: normal;
    overflow: visible;
    word-break: break-all;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell > .invisible > .readonly-text {
    height: auto;
    min-height: 24px;
    white-space: normal;
    overflow: visible;
    word-break: break-all;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell .readonly-text {
    height: 24px;
    white-space: normal;
    overflow: visible;
    word-break: break-all;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell {
    white-space: normal;
    line-height: 24px;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell > .readonly-text {
    padding: 0px;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell > .readonly-text .text {
    overflow: visible;
    white-space: normal;
    height: auto;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell > a {
    padding: 5px;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell .invisible .readonly-text {
    padding: 0px;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell .invisible .readonly-text .content-wrapper {
    white-space: normal;
}

.grid.line-feed > .grid-body table > tbody > tr > td > .body-cell .validate-error .readonly-text {
    margin-right: 24px;
}

.grid
    > .grid-body
    table
    > tbody
    > tr
    > td
    > .body-cell:not(.select-row-checkbox-cell):not(.select-row-radio-cell)
    > .color-picker
    .color-picker__choose {
    top: 2px;
}

.grid > .grid-body .body-cell > .drag {
    width: 100%;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* 拖拽的样式 */
.grid > .grid-body .body-cell > .drag svg {
    cursor: pointer;
}

.grid > .grid-body .body-cell > .drag.cur {
    cursor: pointer;
}

.grid > .grid-body table > tbody > tr td {
    position: relative;
}

.grid > .grid-body table > tbody > tr.drag-active td::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #007aff;
}

.grid > .grid-body table > tbody > tr.drag-active td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background-color: #007aff;
}

.grid > .grid-body table > tbody > tr.drag-active td:first-child {
    border-left: 1px solid #007aff;
}

.grid > .grid-body table > tbody > tr.drag-active td:last-child {
    border-right: 1px solid #007aff;
}

.user-select {
    user-select: none;
}

.grid > .grid-body table > tbody > tr.drag-border-top td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 2px;
    background-color: rgba(0, 122, 255, 0.6);
}

.grid > .grid-body table > tbody > tr.drag-border-bottom td::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 2px;
    background-color: rgba(0, 122, 255, 0.6);
}

.grid > .grid-body > .scroll-type-top,
.grid > .grid-body > .scroll-type-bottom {
    width: 100%;
    height: 8px;
    z-index: 3;
}

.grid > .grid-body > .scroll-type-top {
    position: absolute;
    top: 0;
    left: 0;
}

.grid > .grid-body > .scroll-type-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
}

.grid > .grid-body > .scroll-type-top.top {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(225, 225, 225, 0.1));
}

.grid > .grid-body > .scroll-type-bottom.bottom {
    background-image: linear-gradient(rgba(225, 225, 225, 0.1), rgba(0, 0, 0, 0.1));
}
.poptip-message-panel {
    word-break: break-all;
}

.poptip-message-panel a {
    flex: auto !important;
}

.poptip-message-panel span a {
    text-decoration: none !important;
    color: #333 !important;
}

/* 列边框 */
.grid > .grid-body table > tbody > tr:nth-last-child(1) > td.columnsBoder {
    border-bottom: 2px solid #007aff;
}
.grid > .grid-body table > tbody > tr > td.columnsBoder {
    position: relative;
}
.grid > .grid-body table > tbody > tr > td.columnsBoder::before {
    content: '';
    height: calc(100% + 2px);
    position: absolute;
    border-left: 2px solid #007aff;
    left: -1px;
    top: 0;
    overflow: hidden;
}

.grid > .grid-body table > tbody > tr > td.columnsBoder::after {
    content: '';
    height: calc(100% + 2px);
    position: absolute;
    border-left: 2px solid #007aff;
    right: -1px;
    top: 0;
    overflow: hidden;
}

@media screen and (max-width: 768px) {
    .grid {
        max-height: 500px;
    }
}

.grid-tooltip {
    font-size: 12px !important;
    p {
        margin: 0;
    }
}

.grid > .grid-body table > tbody > tr > td {
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    text-align: left;
    border-right: 1px solid #eee;

    &:last-child {
        border-right: none;
    }
}

.scroll-visible ::v-deep .vBarVisible.hBarVisible .scroll-bottom {
    margin-bottom: 8px;
}

.grid .grid-body table tbody tr td .body-cell .number-input input {
    padding-left: 8px;
}
.grid .grid-body table tbody tr td .body-cell .number-input .readonly-text {
    padding-left: 8px;
}

.grid-body-table {
    > tbody {
        > tr {
            > td {
                .body-cell {
                    .remote-select {
                        .sunway-select {
                            .ant-select-selection {
                                height: 20px;

                                .ant-select-selection__rendered {
                                    line-height: 18px;
                                }

                                &:active {
                                    box-shadow: none;
                                    margin-top: 1px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
