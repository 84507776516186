<!-- cron命令生成器组件 -->
<template>
    <div class="container" @mousedown.stop>
        <div class="cron-container">
            <!-- tab导航 -->
            <div class="cron-nav">
                <template v-for="item in tabText">
                    <span
                        :class="{ active: tabDefault === item.key }"
                        v-if="mode.includes(item.type)"
                        :key="item.key"
                        @click="tabChange(item.key)"
                    >
                        {{ item.key }}
                    </span>
                </template>
            </div>
            <div class="cron-choose">
                <scroll :innerPanel="true">
                    <!-- 秒 -->
                    <div class="cron-item" v-if="mode.includes('second')" v-show="tabDefault === '秒'">
                        <div class="choose-item" v-if="secondWildcard">
                            <input
                                type="radio"
                                name="seconds"
                                value="wildcard"
                                v-model="seconds.radio"
                                autocomplete="off"
                            />
                            <span>每秒允许的通配符[, - * /]</span>
                        </div>
                        <div class="choose-item">
                            <input
                                type="radio"
                                name="seconds"
                                value="period"
                                v-model="seconds.radio"
                                autocomplete="off"
                            />
                            <span>周期从</span>
                            <input
                                type="number"
                                max="58"
                                min="1"
                                autocomplete="off"
                                v-model="seconds.periodStart"
                                @change="minmaxValue(58, 1, seconds.periodStart, 'seconds', 'periodStart')"
                            />
                            <span>-</span>
                            <input
                                type="number"
                                max="59"
                                min="2"
                                autocomplete="off"
                                v-model="seconds.periodEnd"
                                @change="minmaxValue(59, 2, seconds.periodEnd, 'seconds', 'periodEnd')"
                            />
                            <span>秒</span>
                        </div>
                        <div class="choose-item">
                            <input
                                type="radio"
                                name="seconds"
                                autocomplete="off"
                                value="executeone"
                                v-model="seconds.radio"
                            />
                            <span>从</span>
                            <input
                                type="number"
                                max="59"
                                min="0"
                                autocomplete="off"
                                v-model="seconds.executeoneStart"
                                @change="minmaxValue(59, 0, seconds.executeoneStart, 'seconds', 'executeoneStart')"
                            />
                            <span>秒开始，每</span>
                            <input
                                type="number"
                                max="59"
                                min="1"
                                autocomplete="off"
                                v-model="seconds.executeoneEnd"
                                @change="minmaxValue(59, 1, seconds.executeoneEnd, 'seconds', 'executeoneEnd')"
                            />
                            <span>秒执行一次</span>
                        </div>
                        <div class="choose-item">
                            <input
                                type="radio"
                                name="seconds"
                                value="appoint"
                                autocomplete="off"
                                v-model="seconds.radio"
                            />
                            <span>指定</span>
                            <div class="appoint-checkbox">
                                <span class="item-checkbox" v-for="(item, index) in 60" :key="item">
                                    <input
                                        type="checkbox"
                                        :value="index"
                                        v-model="seconds.appoint"
                                        autocomplete="off"
                                    />
                                    <span>{{ index > 9 ? index : '0' + index }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- 分 -->
                    <div class="cron-item" v-if="mode.includes('minute')" v-show="tabDefault === '分'">
                        <div class="choose-item" v-if="minuteWildcard">
                            <input
                                type="radio"
                                name="minutes"
                                value="wildcard"
                                autocomplete="off"
                                v-model="minutes.radio"
                            />
                            <span>分钟允许的通配符[, - * /]</span>
                        </div>
                        <div class="choose-item">
                            <input
                                type="radio"
                                name="minutes"
                                value="period"
                                autocomplete="off"
                                v-model="minutes.radio"
                            />
                            <span>周期从</span>
                            <input
                                type="number"
                                max="58"
                                min="1"
                                autocomplete="off"
                                v-model="minutes.periodStart"
                                @change="minmaxValue(58, 1, minutes.periodStart, 'minutes', 'periodStart')"
                            />
                            <span>-</span>
                            <input
                                type="number"
                                max="59"
                                min="2"
                                autocomplete="off"
                                v-model="minutes.periodEnd"
                                @change="minmaxValue(59, 2, minutes.periodEnd, 'minutes', 'periodEnd')"
                            />
                            <span>分钟</span>
                        </div>
                        <div class="choose-item">
                            <input
                                type="radio"
                                name="minutes"
                                autocomplete="off"
                                value="executeone"
                                v-model="minutes.radio"
                            />
                            <span>从</span>
                            <input
                                type="number"
                                max="59"
                                min="0"
                                autocomplete="off"
                                v-model="minutes.executeoneStart"
                                @change="minmaxValue(59, 0, minutes.executeoneStart, 'minutes', 'executeoneStart')"
                            />
                            <span>分钟开始，每</span>
                            <input
                                type="number"
                                max="59"
                                min="1"
                                autocomplete="off"
                                v-model="minutes.executeoneEnd"
                                @change="minmaxValue(59, 1, minutes.executeoneEnd, 'minutes', 'executeoneEnd')"
                            />
                            <span>分钟执行一次</span>
                        </div>
                        <div class="choose-item">
                            <input
                                type="radio"
                                name="minutes"
                                value="appoint"
                                autocomplete="off"
                                v-model="minutes.radio"
                            />
                            <span>指定</span>
                            <div class="appoint-checkbox">
                                <span class="item-checkbox" v-for="(item, index) in 60" :key="item">
                                    <input
                                        type="checkbox"
                                        name="minutesCheckbox"
                                        :value="index"
                                        autocomplete="off"
                                        v-model="minutes.appoint"
                                    />
                                    <span>{{ index > 9 ? index : '0' + index }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- 时 -->
                    <div class="cron-item" v-if="mode.includes('hour')" v-show="tabDefault === '时'">
                        <div class="choose-item">
                            <input type="radio" name="hour" value="wildcard" v-model="hour.radio" autocomplete="off" />
                            <span>小时允许的通配符[, - * /]</span>
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="hour" value="period" v-model="hour.radio" autocomplete="off" />
                            <span>周期从</span>
                            <input
                                type="number"
                                max="23"
                                min="0"
                                autocomplete="off"
                                v-model="hour.periodStart"
                                @change="minmaxValue(23, 0, hour.periodStart, 'hour', 'periodStart')"
                            />
                            <span>-</span>
                            <input
                                type="number"
                                max="23"
                                min="2"
                                autocomplete="off"
                                v-model="hour.periodEnd"
                                @change="minmaxValue(23, 2, hour.periodEnd, 'hour', 'periodEnd')"
                            />
                            <span>小时</span>
                        </div>
                        <div class="choose-item">
                            <input
                                type="radio"
                                name="hour"
                                value="executeone"
                                v-model="hour.radio"
                                autocomplete="off"
                            />
                            <span>从</span>
                            <input
                                type="number"
                                max="23"
                                min="0"
                                autocomplete="off"
                                v-model="hour.executeoneStart"
                                @change="minmaxValue(23, 0, hour.executeoneStart, 'hour', 'executeoneStart')"
                            />
                            <span>小时开始，每</span>
                            <input
                                type="number"
                                max="23"
                                min="1"
                                autocomplete="off"
                                v-model="hour.executeoneEnd"
                                @change="minmaxValue(23, 1, hour.executeoneEnd, 'hour', 'executeoneEnd')"
                            />
                            <span>小时执行一次</span>
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="hour" value="appoint" v-model="hour.radio" autocomplete="off" />
                            <span>指定</span>
                            <div class="appoint-checkbox">
                                <div class="hour-AM">
                                    <span>AM：</span>
                                    <span class="item-checkbox" v-for="(item, index) in 12" :key="item">
                                        <input
                                            type="checkbox"
                                            name="hourCheckbox"
                                            :value="index"
                                            autocomplete="off"
                                            v-model="hour.appoint"
                                        />
                                        <span>{{ index > 9 ? index : '0' + index }}</span>
                                    </span>
                                </div>
                                <div class="hour-PM">
                                    <span>PM：</span>
                                    <template v-for="(item, index) in 24">
                                        <span class="item-checkbox" :key="item" v-if="index > 11">
                                            <input
                                                type="checkbox"
                                                name="hourCheckbox"
                                                :value="index"
                                                autocomplete="off"
                                                v-model="hour.appoint"
                                            />
                                            <span>{{ index > 9 ? index : '0' + index }}</span>
                                        </span>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 日 -->
                    <div class="cron-item" v-if="mode.includes('day')" v-show="tabDefault === '日'">
                        <div class="choose-item">
                            <input type="radio" name="days" value="wildcard" v-model="days.radio" autocomplete="off" />
                            <span>日允许的通配符[, - * / L W]</span>
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="days" value="noappoint" v-model="days.radio" autocomplete="off" />
                            <span>不指定</span>
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="days" value="period" v-model="days.radio" autocomplete="off" />
                            <span>周期从</span>
                            <input
                                type="number"
                                max="31"
                                min="1"
                                autocomplete="off"
                                v-model="days.periodStart"
                                @change="minmaxValue(31, 1, days.periodStart, 'days', 'periodStart')"
                            />
                            <span>-</span>
                            <input
                                type="number"
                                max="31"
                                min="2"
                                autocomplete="off"
                                v-model="days.periodEnd"
                                @change="minmaxValue(31, 2, days.periodEnd, 'days', 'periodEnd')"
                            />
                            <span>日</span>
                        </div>
                        <div class="choose-item">
                            <input
                                type="radio"
                                name="days"
                                value="executeone"
                                v-model="days.radio"
                                autocomplete="off"
                            />
                            <span>从</span>
                            <input
                                type="number"
                                max="31"
                                min="1"
                                autocomplete="off"
                                v-model="days.executeoneStart"
                                @change="minmaxValue(31, 1, days.executeoneStart, 'days', 'executeoneStart')"
                            />
                            <span>日开始，每</span>
                            <input
                                type="number"
                                max="31"
                                min="1"
                                autocomplete="off"
                                v-model="days.executeoneEnd"
                                @change="minmaxValue(31, 1, days.executeoneEnd, 'days', 'executeoneEnd')"
                            />
                            <span>天执行一次</span>
                        </div>
                        <!-- <div class="choose-item">
                            <input type="radio" name='days' value="workday" v-model="days.radio" /><span>每月</span>
                            <input type="number" max="31" min="1" v-model="days.workDay" @change="minmaxValue(31, 1, days.workDay, 'days', 'workDay')" /><span>号最近的那个工作日</span>
                        </div>-->
                        <div class="choose-item">
                            <input type="radio" name="days" value="lastday" v-model="days.radio" autocomplete="off" />
                            <span>本月最后一天</span>
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="days" value="appoint" v-model="days.radio" autocomplete="off" />
                            <span>指定</span>
                            <div class="appoint-checkbox">
                                <span class="item-checkbox" v-for="item in 31" :key="item">
                                    <input
                                        type="checkbox"
                                        name="riCheckbox"
                                        :value="item"
                                        autocomplete="off"
                                        v-model="days.appoint"
                                    />
                                    <span>{{ item > 9 ? item : '0' + item }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- 月 -->
                    <div class="cron-item" v-if="mode.includes('month')" v-show="tabDefault === '月'">
                        <div class="choose-item">
                            <input
                                type="radio"
                                name="month"
                                value="wildcard"
                                v-model="month.radio"
                                autocomplete="off"
                            />
                            <span>月允许的通配符[, - * /]</span>
                        </div>
                        <!-- <div class="choose-item">
                            <input
                                type="radio"
                                name="month"
                                value="noappoint"
                                autocomplete= "off"
                                v-model="month.radio"
                            />
                            <span>不指定</span>
                        </div> -->
                        <div class="choose-item">
                            <input type="radio" name="month" value="period" v-model="month.radio" autocomplete="off" />
                            <span>周期从</span>
                            <input
                                type="number"
                                max="12"
                                min="1"
                                autocomplete="off"
                                v-model="month.periodStart"
                                @change="minmaxValue(12, 1, month.periodStart, 'month', 'periodStart')"
                            />
                            <span>-</span>
                            <input
                                type="number"
                                max="12"
                                min="2"
                                autocomplete="off"
                                v-model="month.periodEnd"
                                @change="minmaxValue(12, 2, month.periodEnd, 'month', 'periodEnd')"
                            />
                            <span>月</span>
                        </div>
                        <div class="choose-item">
                            <input
                                type="radio"
                                name="month"
                                value="executeone"
                                autocomplete="off"
                                v-model="month.radio"
                            />
                            <span>从</span>
                            <input
                                type="number"
                                max="12"
                                min="1"
                                autocomplete="off"
                                v-model="month.executeoneStart"
                                @change="minmaxValue(12, 1, month.executeoneStart, 'month', 'executeoneStart')"
                            />
                            <span>月开始，每</span>
                            <input
                                type="number"
                                min="1"
                                autocomplete="off"
                                v-model="month.executeoneEnd"
                                @change="minmaxValue(Infinity, 1, month.executeoneEnd, 'month', 'executeoneEnd')"
                            />
                            <span>月执行一次</span>
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="month" value="appoint" v-model="month.radio" autocomplete="off" />
                            <span>指定</span>
                            <div class="appoint-checkbox">
                                <span class="item-checkbox" v-for="item in 12" :key="item">
                                    <input
                                        type="checkbox"
                                        name="monthCheckbox"
                                        autocomplete="off"
                                        :value="item"
                                        v-model="month.appoint"
                                    />
                                    <span>{{ item > 9 ? item : '0' + item }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- 周 -->
                    <div class="cron-item" v-if="mode.includes('week')" v-show="tabDefault === '周'">
                        <div class="choose-item">
                            <input type="radio" name="week" value="wildcard" v-model="week.radio" autocomplete="off" />
                            <span>周允许的通配符[, - * / L #]</span>
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="week" value="noappoint" v-model="week.radio" autocomplete="off" />
                            <span>不指定</span>
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="week" value="period" v-model="week.radio" autocomplete="off" />
                            <span>周期从星期</span>
                            <input
                                type="number"
                                max="7"
                                min="1"
                                autocomplete="off"
                                v-model="week.periodStart"
                                @change="minmaxValue(7, 1, week.periodStart, 'week', 'periodStart')"
                            />
                            <span>-</span>
                            <input
                                type="number"
                                max="7"
                                min="2"
                                autocomplete="off"
                                v-model="week.periodEnd"
                                @change="minmaxValue(7, 2, week.periodEnd, 'week', 'periodEnd')"
                            />
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="week" value="countweek" v-model="week.radio" autocomplete="off" />
                            <span>第</span>
                            <input
                                type="number"
                                max="5"
                                min="1"
                                autocomplete="off"
                                v-model="week.countWeek"
                                @change="minmaxValue(5, 1, week.countWeek, 'week', 'countWeek')"
                            />
                            <span>周的星期</span>
                            <input
                                type="number"
                                max="7"
                                min="1"
                                autocomplete="off"
                                v-model="week.whatDay"
                                @change="minmaxValue(7, 1, week.whatDay, 'week', 'whatDay')"
                            />
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="week" value="lastweek" v-model="week.radio" autocomplete="off" />
                            <span>本月最后一个星期</span>
                            <input
                                type="number"
                                max="7"
                                min="1"
                                autocomplete="off"
                                v-model="week.lastDay"
                                @change="minmaxValue(7, 1, week.lastDay, 'week', 'lastDay')"
                            />
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="week" value="appoint" v-model="week.radio" autocomplete="off" />
                            <span>指定</span>
                            <div class="appoint-checkbox">
                                <span class="item-checkbox" v-for="item in 7" :key="item">
                                    <input
                                        type="checkbox"
                                        name="weekCheckbox"
                                        autocomplete="off"
                                        :value="weekDay(item)"
                                        v-model="week.appoint"
                                    />
                                    <span>{{ item }}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- 年 -->
                    <div class="cron-item" v-if="mode.includes('year')" v-show="tabDefault === '年'">
                        <div class="choose-item">
                            <input
                                type="radio"
                                name="year"
                                value="nowildcard"
                                v-model="year.radio"
                                autocomplete="off"
                            />
                            <span>不指定允许的通配符[, - * /] 非必填</span>
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="year" value="peryear" v-model="year.radio" autocomplete="off" />
                            <span>每年</span>
                        </div>
                        <div class="choose-item">
                            <input type="radio" name="year" value="period" v-model="year.radio" autocomplete="off" />
                            <span>周期从</span>
                            <input
                                type="number"
                                max="3000"
                                min="2013"
                                autocomplete="off"
                                v-model="year.periodStart"
                                @change="minmaxValue(3000, 2013, year.periodStart, 'year', 'periodStart')"
                            />
                            <span>-</span>
                            <input
                                type="number"
                                max="3000"
                                min="2014"
                                autocomplete="off"
                                v-model="year.periodEnd"
                                @change="minmaxValue(3000, 2014, year.periodEnd, 'year', 'periodEnd')"
                            />
                        </div>
                        <div class="choose-item mb12">
                            <input type="radio" name="year" value="executeone" v-model="year.radio" />
                            <span>从</span>
                            <input
                                type="number"
                                max="3000"
                                min="2014"
                                autocomplete="off"
                                v-model="year.executeoneStart"
                                @change="minmaxValue(3000, 2014, year.executeoneStart, 'year', 'executeoneStart')"
                            />
                            <span>年开始，每</span>
                            <input
                                type="number"
                                max="100"
                                min="1"
                                autocomplete="off"
                                v-model="year.executeoneEnd"
                                @change="minmaxValue(100, 1, year.executeoneEnd, 'year', 'executeoneEnd')"
                            />
                            <span>年执行一次</span>
                        </div>
                    </div>
                </scroll>
            </div>
            <!-- 结果 -->
            <div class="cron-result-container">
                <div class="cron-result">
                    <span class="result-text expression">表达式字段：</span>
                    <div class="result-items">
                        <div class="result-item" v-for="item in tabText" :key="item.key">
                            <span v-if="item.key === '分'">分钟</span>
                            <span v-else-if="item.key === '时'">小时</span>
                            <span v-else-if="item.key === '周'">星期</span>
                            <span v-else>{{ item.key }}</span>
                            <input type="text" readonly v-model="item.value" autocomplete="off" />
                        </div>
                    </div>
                </div>
                <div class="cron-result">
                    <span class="result-text">Cron表达式：</span>
                    <input type="text" class="analysis-input" v-model="cronExpression" autocomplete="off" />
                    <span class="analysis-ul" @click="analysisUiFn">反解析到UI</span>
                </div>
                <!-- <div class="cron-result">
                        <span class="result-text runtime-Tit">最近5次运行时间：</span>
                        <span class="runtime-cont">
                            <p class="runtime">2019-09-03 10:32:33</p>
                            <p class="runtime">2019-09-03 10:32:33</p>
                            <p class="runtime">2019-09-03 10:32:33</p>
                            <p class="runtime">2019-09-03 10:32:33</p>
                            <p class="runtime">2019-09-03 10:32:33</p>
                        </span>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import Gikam from '../../../../core/gikam-core';

export default {
    name: 'cron',
    props: {
        value: String,
        mode: {
            type: Array,
            default: () => ['second', 'minute', 'hour', 'day', 'month', 'week', 'year']
        },
        minuteWildcard: Boolean,
        secondWildcard: Boolean
    },

    data() {
        const tabText = [
            {
                key: '秒',
                value: '0',
                type: 'second'
            },
            {
                key: '分',
                value: '0',
                type: 'minute'
            },
            {
                key: '时',
                value: '0',
                type: 'hour'
            },
            {
                key: '日',
                value: '*',
                type: 'day'
            },
            {
                key: '月',
                value: '*',
                type: 'month'
            },
            {
                key: '周',
                value: '?',
                type: 'week'
            },
            {
                key: '年',
                value: '',
                type: 'year'
            }
        ];

        let tabDefault = '秒';
        Gikam.each(tabText, (index, item) => {
            if (this.mode.includes(item.type)) {
                tabDefault = item.key;
                return false;
            }
        });
        return {
            tabDefault: tabDefault,
            cronExpression: this.value, // cron表达式
            tabText: tabText,
            seconds: {
                radio: 'appoint',
                periodStart: 1, // 周期从-开始
                periodEnd: 2, // 周期从-结束
                executeoneStart: 0, // 执行一次-开始
                executeoneEnd: 1, // 执行一次-结束
                appoint: [] //  指定
            },
            minutes: {
                radio: 'appoint',
                periodStart: 1,
                periodEnd: 2,
                executeoneStart: 0,
                executeoneEnd: 1,
                appoint: []
            },
            hour: {
                radio: 'appoint',
                periodStart: 0,
                periodEnd: 2,
                executeoneStart: 0,
                executeoneEnd: 1,
                appoint: []
            },
            days: {
                radio: 'wildcard',
                periodStart: 1,
                periodEnd: 2,
                executeoneStart: 1,
                executeoneEnd: 1,
                // workDay: 1, // 最近的工作日
                appoint: []
            },
            month: {
                radio: 'wildcard',
                periodStart: 1,
                periodEnd: 2,
                executeoneStart: 1,
                executeoneEnd: 1,
                appoint: []
            },
            week: {
                radio: 'noappoint',
                periodStart: 1,
                periodEnd: 2,
                countWeek: 1, // 第几周
                whatDay: 1, // 星期几
                lastDay: 2, // 最近的工作日
                appoint: []
            },
            year: {
                radio: 'nowildcard',
                periodStart: 2013,
                periodEnd: 2014,
                executeoneStart: 2014,
                executeoneEnd: 1
            }
        };
    },

    watch: {
        tabText: {
            handler(val) {
                this.cronExpressionFn(val);
            },
            deep: true
        },
        seconds: {
            handler(val) {
                let tabData = this.chooseRadioVal('seconds');
                this.chooseTabText(0, tabData[val.radio]);
            },
            deep: true
        },
        'seconds.radio'(val) {
            if (val === 'appoint' && this.seconds.appoint.length === 0) this.appointInitial('seconds', [0]);
        },
        'seconds.appoint'(val) {
            if (this.seconds.radio === 'appoint' && val.length === 0) this.chooseTabText(0, '?');
        },
        minutes: {
            handler(val) {
                let tabData = this.chooseRadioVal('minutes');
                this.chooseTabText(1, tabData[val.radio]);
            },
            deep: true
        },
        'minutes.radio'(val) {
            if (val === 'appoint' && this.minutes.appoint.length === 0) this.appointInitial('minutes', [0]);
        },
        'minutes.appoint'(val) {
            if (this.minutes.radio === 'appoint' && val.length === 0) this.chooseTabText(1, '?');
        },
        hour: {
            handler(val) {
                let tabData = this.chooseRadioVal('hour');
                this.chooseTabText(2, tabData[val.radio]);
            },
            deep: true
        },
        'hour.radio'(val) {
            if (val === 'appoint' && this.hour.appoint.length === 0) this.appointInitial('hour', [0]);
        },
        'hour.appoint'(val) {
            if (this.hour.radio === 'appoint' && val.length === 0) this.chooseTabText(2, '?');
        },
        days: {
            handler(val) {
                let tabData = this.chooseRadioVal('days');
                this.chooseTabText(3, tabData[val.radio]);
            },
            deep: true
        },
        'days.radio'(val) {
            if (val === 'appoint' && this.days.appoint.length === 0) this.appointInitial('days', [1]);
        },
        'days.appoint'(val) {
            if (this.days.radio === 'appoint' && val.length === 0) this.chooseTabText(3, '?');
        },
        month: {
            handler(val) {
                let tabData = this.chooseRadioVal('month');
                this.chooseTabText(4, tabData[val.radio]);
                if (this.month.executeoneEnd > 99999999) {
                    this.month.executeoneEnd = (this.month.executeoneEnd + '').slice(0, 8);
                }
            },
            deep: true
        },
        'month.radio'(val) {
            if (val === 'appoint' && this.month.appoint.length === 0) this.appointInitial('month', [1]);
        },
        'month.appoint'(val) {
            if (this.month.radio === 'appoint' && val.length === 0) this.chooseTabText(4, '?');
        },
        week: {
            handler(val) {
                let tabData = this.chooseRadioVal('week');
                this.chooseTabText(5, tabData[val.radio]);
            },
            deep: true
        },
        'week.radio'(val) {
            if (val === 'appoint' && this.week.appoint.length === 0) this.appointInitial('week', [2]);
        },
        'week.appoint'(val) {
            if (this.month.radio === 'appoint' && val.length === 0) this.chooseTabText(5, '?');
        },
        year: {
            handler(val) {
                let tabData = this.chooseRadioVal('year');
                this.chooseTabText(6, tabData[val.radio]);
            },
            deep: true
        }
    },

    methods: {
        // 点击tab
        tabChange(tab) {
            if (tab === '日' && this.week.radio !== 'noappoint') {
                Gikam.alert('请将周设置为“不指定”，再设置日');
                this.tabDefault = '周';
                return;
            }

            if (tab === '周' && this.days.radio !== 'noappoint') {
                Gikam.alert('请将日设置为“不指定”，再设置周');
                this.tabDefault = '日';
                return;
            }
            this.tabDefault = tab;
        },
        // tabText的value数据赋值
        chooseTabText(index, data) {
            this.$set(this.tabText[index], 'value', data);
        },
        // 选中指定，复选框无值，自动选中
        appointInitial(tab, array) {
            this.$set(this[tab], 'appoint', array);
        },
        // 反解析到UI
        analysisUiFn() {
            let valueArray = this.cronExpression.trim().split(' ');
            this.analysisUiStepFn('seconds', valueArray[0]);
            this.analysisUiStepFn('minutes', valueArray[1]);
            this.analysisUiStepFn('hour', valueArray[2]);
            this.analysisUiStepFn('days', valueArray[3]);
            this.analysisUiStepFn('month', valueArray[4]);
            this.analysisUiStepFn('week', valueArray[5]);
            this.analysisUiStepFn('year', valueArray[6]);
        },
        // eslint-disable-next-line complexity
        analysisUiStepFn(tab, value) {
            if (!value) {
                this[tab].radio = 'nowildcard';
                return;
            } else if ((tab === 'seconds' || tab === 'minutes' || tab === 'hour') && value.indexOf('*') > -1) {
                this[tab].radio = 'wildcard';
                return;
            } else if (tab !== 'year' && value.indexOf('*') > -1) {
                this[tab].radio = 'wildcard';
                return;
            } else if (value.indexOf('-') > -1) {
                this[tab].radio = 'period';
                this[tab].periodStart = value.split('-')[0];
                this[tab].periodEnd = value.split('-')[1];
                if (tab === 'week') {
                    this[tab].periodStart = this.reverseWeekDay(value.split('-')[0]);
                    this[tab].periodEnd = this.reverseWeekDay(value.split('-')[1]);
                }
                return;
            } else if (value.indexOf('/') > -1) {
                this[tab].radio = 'executeone';
                this[tab].executeoneStart = value.split('/')[0];
                this[tab].executeoneEnd = value.split('/')[1];
                return;
            } else if (value.indexOf('/') > -1) {
                this[tab].radio = 'executeone';
                this[tab].executeoneStart = value.split('/')[0];
                this[tab].executeoneEnd = value.split('/')[1];
                return;
            } else if (value === '?') {
                this[tab].radio = 'noappoint';
                return;
            }
            // else if (value.indexOf('W') > -1) {
            //     this[tab].radio = 'workday';
            //     this[tab].workDay = value.split('W')[0];
            //     return;
            // }
            else if (value === 'L') {
                this[tab].radio = 'lastday';
                return;
            } else if (value.indexOf('#') > -1) {
                this[tab].radio = 'countweek';
                this[tab].countWeek = value.split('#')[1];
                this[tab].whatDay = value.split('#')[0] - 1;
                return;
            } else if (value.length > 1 && value.indexOf('L') > -1) {
                this[tab].radio = 'lastweek';
                this[tab].lastDay = this.reverseWeekDay(value.split('L')[0]);
                return;
            } else if (tab === 'year' && value === '*') {
                this[tab].radio = 'peryear';
                return;
            } else {
                this[tab].radio = 'appoint';
                this[tab].appoint = value.split(',');
                return;
            }
        },
        // cron表达式赋值
        cronExpressionFn(val) {
            this.cronExpression = val
                .reduce((total, item) => {
                    return total + item.value + ' ';
                }, '')
                .trim();
        },
        // 选择radio的value
        chooseRadioVal(tab) {
            let period = this[tab].periodStart + '-' + this[tab].periodEnd;
            if (tab === 'week') {
                period = this.weekDay(this[tab].periodStart) + '-' + this.weekDay(this[tab].periodEnd);
            }
            return {
                wildcard: '*',
                period: period,
                executeone: this[tab].executeoneStart + '/' + this[tab].executeoneEnd,
                appoint: this[tab].appoint,
                noappoint: '?',
                // workday: this[tab].workDay + 'W',
                lastday: 'L',
                countweek: this.weekDay(this[tab].whatDay) + '#' + this[tab].countWeek,
                lastweek: this.weekDay(this[tab].lastDay) + 'L',
                nowildcard: '',
                peryear: '*'
            };
        },
        // 周做处理 周几+1，周7=1
        weekDay(day) {
            let D = day == 7 ? 1 : day + 1;
            return D;
        },
        // 反解析周
        reverseWeekDay(day) {
            let D = day == 1 ? 7 : day - 1;
            return D;
        },
        // 数值优化-最小值-最大值
        minmaxValue(max, min, num, tab, key) {
            let number = Math.round(num);
            if (number > max) {
                this[tab][key] = max;
            } else if (number < min) {
                this[tab][key] = min;
            } else {
                this[tab][key] = Math.round(number);
            }
        },

        // 判断指定是否选取数据
        appointDataType() {
            let hasAppointList = [this.seconds, this.minutes, this.hour, this.days, this.month, this.week];
            for (let i = 0; i < hasAppointList.length; i++) {
                if (hasAppointList[i].radio === 'appoint' && hasAppointList[i].appoint.length === 0) {
                    Gikam.alert('请为' + this.tabText[i].key + '指定具体值。');
                    return false;
                }
            }
            return true;
        }
    },

    mounted() {
        this.$nextTick(() => {
            !this.cronExpression && this.cronExpressionFn(this.tabText);
            this.cronExpression && this.analysisUiFn();
        });
    }
};
</script>

<style scoped>
.container {
    height: 100%;
    padding: 0 16px;
}

.cron-container,
.container .cron-nav {
    width: 100%;
    font-family: 'Microsoft YaHei', Serif;
}

.cron-container {
    height: 100%;
    border: 1px solid #eee;
    border-top: none;
}

/* tab */
.container .cron-nav {
    border: 1px solid #eee;
    border-bottom: none;
    background-color: #f5f7fa;
}

.container .cron-nav > span {
    display: inline-block;
    font-size: 12px;
    color: rgb(0, 0, 0, 0.65);
    height: 30px;
    padding: 0 16px;
    text-align: center;
    line-height: 30px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.container .cron-nav > span.active {
    background-color: #fff;
    box-shadow: 0 2px #007aff;
}

/* 中间主体 */
.cron-container .cron-choose {
    width: 100%;
    height: calc(100% - 137px);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: rgb(0, 0, 0, 0.65);
}

.cron-container .cron-choose .cron-item {
    padding: 12px 16px 0 16px;
}

.cron-container .cron-choose input[type='radio'] {
    vertical-align: middle;
    margin: 0 8px 2px 0;
    border: 1px solid #d9d9d9;
    cursor: pointer;
}

.cron-container .cron-choose .choose-item {
    margin-bottom: 12px;
}

.cron-container .cron-choose .choose-item input[type='number'] {
    width: 66px;
    height: 24px;
    color: rgb(0, 0, 0, 0.85);
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
    margin: 0 4px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.cron-container .cron-choose .choose-item:last-child {
    margin-bottom: 0;
}

.cron-container .cron-choose .choose-item .appoint-checkbox {
    width: 100%;
    padding: 12px 0 0 20px;
}

.cron-container .cron-choose .choose-item .appoint-checkbox .item-checkbox {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 12px;
}

.cron-container .cron-choose .choose-item .appoint-checkbox .item-checkbox input[type='checkbox'] {
    margin: 0 4px 2px 0;
    vertical-align: middle;
    border: 1px solid #d9d9d9;
    cursor: pointer;
}

/* 结果结果 */
.cron-container .cron-result-container {
    padding: 4px 16px 12px 16px;
    position: relative;
}

.cron-container .cron-result-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 16px;
    right: 0;
    width: calc(100% - 32px);
    height: 1px;
    background-color: #eee;
}

.cron-container .cron-result-container .cron-result {
    width: 100%;
    margin-bottom: 12px;
}

.cron-container .cron-result-container .cron-result:last-child {
    width: 100%;
    margin-bottom: 0;
}

/* .cron-container .cron-result-container .cron-result:last-child {
        margin-bottom: 0;
    } */

.cron-container .cron-result-container .cron-result .runtime-cont {
    display: inline-block;
    padding-left: 16px;
}

.cron-container .cron-result-container .cron-result .runtime-cont > p {
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(0, 0, 0, 0.65);
    margin: 0 0 8px 0;
}

.cron-container .cron-result-container .cron-result:first-child {
    width: 100%;
    height: 53px;
    margin-bottom: 12px;
}

.cron-container .cron-result-container .cron-result .result-items {
    display: flex;
    justify-content: space-between;
    padding-left: 107px;
    position: relative;
    top: -16px;
}

.cron-container .cron-result-container .cron-result .result-item {
    display: inline-block;
    margin-right: 8px;
    flex: 1;
}

.cron-container .cron-result-container .cron-result .result-item > span,
.cron-container .cron-result-container .cron-result input {
    display: inline-block;
    width: 100%;
    font-size: 12px;
    color: rgb(0, 0, 0, 0.65);
    text-align: center;
}

.cron-container .cron-result-container .cron-result .result-item > span {
    font-size: 12px;
}

.cron-container .cron-result-container .cron-result input {
    height: 24px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.cron-container .cron-result-container .cron-result .result-item > input {
    margin-top: 4px;
    text-align: center;
}

.cron-container .cron-result-container .cron-result .result-text {
    display: inline-block;
    width: 106px;
    font-family: Arial, Helvetica, sans-serif;
    text-align: right;
    color: rgb(0, 0, 0, 0.8);
    font-size: 12px;
}

.cron-container .cron-result-container .cron-result .result-text.expression {
    position: relative;
    top: 28px;
    z-index: 1;
}

.cron-container .cron-result-container .cron-result .result-text.runtime-Tit {
    position: relative;
    top: -87px;
}

.cron-container .cron-result-container .cron-result .analysis-ul {
    display: inline-block;
    width: 96px;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    color: #fff;
    text-align: center;
    margin-left: 8px;
    border-radius: 4px;
    background-color: #1b6fc4;
    cursor: pointer;
}

.cron-container .cron-result-container .cron-result .analysis-input {
    width: calc(100% - 218px);
    /* BFC */
    margin-left: 1px;
}

.cron-container .cron-result-container .cron-result input {
    padding: 1px 3px;
    text-align: left;
}

.mb12 {
    margin-bottom: 12px !important;
}
</style>
