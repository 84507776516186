<template>
    <div class="checkbox-group" :id="options.id" :class="rootClass">
        <div class="checkbox-group-panel" v-for="group in groupItems" :key="group[0]">
            <span class="checkbox-group-panel-title" v-if="group[0]">{{ group[0] }}</span>
            <div class="checkbox-group-panel-wrapper">
                <template v-for="item in group[1]">
                    <span class="checkbox-wrapper" :key="item.value">
                        <checkbox
                            :propChecked="getCheckboxChecked(item)"
                            :propReadonly="options.readonly"
                            :propValue="item.value"
                            @change="itemChangeHandle"
                        ></checkbox>
                        <span>{{ item.text }}</span>
                    </span>
                </template>
                <checkbox-group-other-input
                    v-if="otherInput && !isGroup"
                    :propReadonly="options.readonly"
                    :propChecked="otherChecked"
                    :value="otherInputVal"
                    @change="inputChangeHandle"
                >
                </checkbox-group-other-input>
            </div>
        </div>
        <checkbox-group-other-input
            v-if="otherInput && isGroup"
            :propReadonly="options.readonly"
            :propChecked="otherChecked"
            :value="otherInputVal"
            @change="inputChangeHandle"
        >
        </checkbox-group-other-input>
    </div>
</template>

<script>
import checkbox from './checkbox.vue';
import Gikam from 'gikam';
import CheckboxGroupHelper from '@/gikam/js/core/checkboxGroupHelper';
import checkboxGroupOtherInput from './checkboxGroupOtherInput';

export default {
    components: { checkbox, checkboxGroupOtherInput },

    props: {
        options: Object
    },

    data() {
        const { items, otherInput } = this.options;
        return {
            items,
            groupItems: null,
            otherInput,
            valueSplit: '__$$__',
            checkboxValue: null,
            checkboxValueArray: [],
            otherInputValue: null,
            field: this.options.field,
            isGroup: false
        };
    },

    created() {
        this.initItems();
    },

    computed: {
        rootClass() {
            const classList = [];
            return classList;
        },

        otherInputVal() {
            return this.otherInputValue ? JSON.parse(this.otherInputValue).other : '';
        },

        otherChecked() {
            return Gikam.isNotEmpty(this.otherInputVal);
        }
    },

    watch: {
        'options.value': {
            immediate: true,
            handler(value) {
                if (Gikam.isEmpty(value)) {
                    this.checkboxValue = null;
                    this.checkboxValueArray = [];
                    return;
                }
                if (value.toString().includes(this.valueSplit)) {
                    const valueArray = value.split(this.valueSplit);
                    this.checkboxValue = valueArray[0];
                    this.otherInputValue = valueArray[1];
                } else {
                    this.checkboxValue = value;
                    this.otherInputValue = null;
                }
                if (Gikam.isNotEmpty(this.checkboxValue)) {
                    this.checkboxValueArray = this.checkboxValue.split(',');
                }
            }
        },

        'options.category': {
            handler() {
                this.items = [];
                this.initItems();
            }
        }
    },

    methods: {
        async initItems() {
            if (Gikam.isEmpty(this.items)) {
                const { items, groupItems, otherInput, isGroup } = await CheckboxGroupHelper.getItems(this.options);
                this.items = items;
                this.groupItems = groupItems;
                this.otherInput = this.otherInput ?? otherInput;
                this.isGroup = isGroup;
            } else {
                this.groupItems = new Map();
                this.groupItems.set(null, this.items);
            }
        },

        getCheckboxChecked(item) {
            return Gikam.isNotEmpty(this.checkboxValueArray) && this.checkboxValueArray.includes(item.value);
        },

        itemChangeHandle(checked, itemValue) {
            const { field } = this.options;
            const valueArray = Gikam.isEmpty(this.checkboxValue) ? [] : this.checkboxValue.split(',');
            if (checked) {
                valueArray.push(itemValue);
            } else {
                const index = valueArray.findIndex(item => item.toString() === itemValue.toString());
                index > -1 && valueArray.splice(index, 1);
            }
            const value = this.generateValue(valueArray.join(','), false);
            this.$emit('change', field, value);
        },

        inputChangeHandle(e) {
            const inputValue = e.target.value;
            const value = this.generateValue(false, inputValue);
            this.$emit('change', this.field, value);
        },

        generateValue(checkboxValue, otherInputValue) {
            let ckValue = null;
            let otherValue = null;
            if (otherInputValue === false) {
                ckValue = Gikam.isEmpty(checkboxValue) ? null : checkboxValue;
                otherValue = this.otherInputValue;
            }
            if (checkboxValue === false) {
                if (Gikam.isNotEmpty(otherInputValue)) {
                    let otherInputObject = Gikam.isEmpty(this.otherInputValue) ? {} : JSON.parse(this.otherInputValue);
                    otherInputObject.other = otherInputValue;
                    otherValue = JSON.stringify(otherInputObject);
                }
                ckValue = this.checkboxValue;
            }
            let value = '';
            if (Gikam.isNotEmpty(ckValue)) {
                value += ckValue;
            }
            if (Gikam.isNotEmpty(otherValue)) {
                value += this.valueSplit + otherValue;
            }
            return value;
        }
    }
};
</script>

<style lang="scss" scoped>
.checkbox-group {
    padding: 4px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 25px;

    > .checkbox-group-panel {
        display: flex;

        > .checkbox-group-panel-title {
            padding-right: 8px;
            white-space: nowrap;

            &::before {
                content: '.';
                display: inline-block;
                position: relative;
                width: 6px;
                top: -3px;
            }

            &::after {
                content: ':';
                display: inline-block;
                text-align: right;
                width: 4px;
                top: -2px;
                position: relative;
            }
        }

        > .checkbox-group-panel-wrapper {
            display: flex;
            flex-flow: wrap;

            > .checkbox-wrapper {
                display: flex;
                align-items: center;

                > span {
                    padding: 0 4px;
                }
            }
        }
    }
}
</style>
