<template>
    <sign
        ref="editor"
        :options="options"
        :propValue="propValue"
        :dataId="dataId"
        :validateArg="validateArg"
        @click="clickHandle"
        @change="changeHandle"
    ></sign>
</template>

<script>
import sign from '@/gikam/js/components/template//fields/sign';
import { mapState } from 'vuex';

export default {
    props: {
        options: Object,
        propValue: String,
        validateArg: Object,
        dataId: [Number, String]
    },

    components: {
        sign
    },

    computed: {
        ...mapState(['form'])
    },

    mounted() {
        this.form.editorManager[this.options.field] = this.$refs.editor;
    },

    methods: {
        clickHandle(...args) {
            this.$emit.apply(this, ['click', ...args]);
        },

        changeHandle(...args) {
            this.$emit.apply(this, ['change', ...args]);
        }
    }
};
</script>
