<template>
    <div class="toolbar" v-show="toobarShow" ref="toolbar">
        <div class="operation" :style="operationStyle" v-observe-size="{ immediate: true, handler: sizeChangeHandle }">
            <template v-for="(item, index) in options.toolbar">
                <template v-if="item.type === 'btnGroup'">
                    <template v-for="(option, index) in item.items">
                        <div
                            class="item"
                            role="btnGroup"
                            :key="item.type + index"
                            :index="index"
                            v-if="option.hidden !== true"
                        >
                            <sw-button :options="option"></sw-button>
                        </div>
                    </template>
                </template>
                <div
                    class="item"
                    v-else-if="item.hidden !== true"
                    :key="item.type + index"
                    :role="item.type"
                    :index="index"
                >
                    <div v-if="item.type === 'form'" :ref="'toolbar_form_' + index" class="form-container"></div>
                    <sw-button
                        v-else-if="item.type === 'button'"
                        :options="item"
                        @hiddenChangeEvent="val => hiddenChangehandler(item, val)"
                    ></sw-button>
                    <drop-down-menu
                        v-else-if="item.type === 'dropDownMenu'"
                        :options="item"
                        :index="index"
                    ></drop-down-menu>
                    <sunway-caption v-else-if="item.type === 'caption'" :options="item"></sunway-caption>
                    <span
                        v-else
                        :is="item.type"
                        :options="item"
                        :eventScope="grid"
                        class="toolbar-operation-item"
                    ></span>
                </div>
            </template>
            <div class="item dropDownMenuMore" v-if="showMoreBtn">
                <dropDownMenu ref="dropDownMenuMore" :options="moreBtnOptions"></dropDownMenu>
            </div>
        </div>

        <div class="search">
            <div v-show="options.genericQuery" class="generic-query-btn" ref="generic-query-btn">
                <div class="drop" @click.stop="popGenericQueryPanel">
                    <div class="search-icon"></div>
                    <span class="text">{{ $i18n('grid.search') }}</span>
                    <div class="down-arrow"></div>
                </div>
                <div class="refresh-icon" @click="genericQueryReset"></div>
            </div>
        </div>

        <InputSearch v-if="commonSearch" class="grid-toolbar-common-search" @input="commonSearchInputHandle" />

        <span v-if="showTotalRecords" class="grid-toolbar-total-record">
            {{ $i18n('grid.page.totalRecord', { num: lazyLoad.totalRows.length }) }}
        </span>

        <general-button-group
            v-if="generalButtonGroupShow"
            :options="options.generalButtonGroup"
            :filter="options.filter"
        ></general-button-group>
    </div>
</template>

<script>
import Gikam from 'gikam';
import Vue from 'vue';
import SwButton from '@/gikam/js/components/button/vue/button';
import generalButtonGroup from './generalButtonGroup';
import { mapState, mapMutations } from 'vuex';
import InputSearch from '@/sunway/input/input-search.vue';
import RadioButtonGroup from '@/sunway/radio/radio-button-group';

export default {
    components: {
        SwButton,
        generalButtonGroup,
        InputSearch,
        RadioButtonGroup
    },

    props: {
        options: Object
    },

    data() {
        return {
            showMoreBtn: false,
            genericQueryResetFlag: false,
            moreBtnOptions: {
                icon: 'btn-more',
                text: this.$i18n('grid.toolbar.more'),
                fragment: null,
                items: []
            },
            wrapperWidth: 100
        };
    },

    computed: {
        ...mapState(['grid', 'commonSearch', 'fieldTypeMapper', 'columns', 'lazyLoad']),

        generalButtonGroupShow() {
            return this.options.generalButtonGroup !== false;
        },

        toobarShow() {
            const { toolbarHidden, toolbar } = this.options;
            if (toolbarHidden === true) {
                return false;
            }
            if (this.generalButtonGroupShow === false && Gikam.isEmpty(toolbar)) {
                return false;
            }
            return true;
        },

        operationStyle() {
            return { justifyContent: this.options.toolbarAlign === 'right' ? 'flex-end' : 'flex-start' };
        },

        showTotalRecords() {
            return this.lazyLoad.enable && this.lazyLoad.totalRows.length > 0;
        }
    },

    created() {
        this.grid.$gridToolbar = this;
    },

    mounted() {
        this.initToolbarForm();
    },

    beforeDestroy() {
        if (this.toolbarFrom) {
            this.toolbarFrom.$destroy();
            this.toolbarFrom = null;
        }
    },

    methods: {
        ...mapMutations(['setCommonSearchData']),

        sizeChangeHandle(size) {
            this.handleBtnMore(size);
        },

        genericQueryReset() {
            this.genericQueryResetFlag = true;
            this.options.requestData = this.$store.state.requestData;
            this.genericQueryList = [];
            this.grid.refresh();
        },

        // 更新toolbar顺序
        updateOrder(dom) {
            const fragment = document.createDocumentFragment();
            const toolbars = Array.from(dom.children).sort(
                (prev, cur) => prev.getAttribute('index') - cur.getAttribute('index')
            );
            toolbars.forEach(item => fragment.appendChild(item));
            dom.appendChild(fragment);
        },

        handleBtnMore({ width: wrapperWidth }) {
            this.wrapperWidth = wrapperWidth;
            this.showMoreBtn = false;
            // eslint-disable-next-line complexity
            this.$nextTick(() => {
                const $toolbar = this.$refs.toolbar;
                if (!$toolbar) return;
                const $btnContainer = $toolbar.querySelector('.operation');
                if (this.moreBtnOptions.fragment) {
                    for (let i = this.moreBtnOptions.fragment.length; i > 0; i--) {
                        $btnContainer.appendChild(this.moreBtnOptions.fragment[i - 1]);
                    }
                    this.updateOrder($btnContainer);
                    this.moreBtnOptions.fragment = null;
                }
                const children = $btnContainer.children;
                if (children.length === 0) {
                    return;
                }
                const btns = [];
                const others = [];
                for (let i = 0; i < children.length; i++) {
                    const type = children[i].getAttribute('role');
                    if (['button', 'dropDownMenu'].includes(type) && Gikam.jQuery(children[i]).outerWidth(true)) {
                        btns.push(children[i]);
                    } else {
                        others.push(children[i]);
                    }
                }
                if (btns.length === 0) return;
                let btnsWidth = 0;
                for (let i = 0; i < btns.length; i++) {
                    btnsWidth += Gikam.jQuery(btns[i]).outerWidth(true);
                }
                for (let i = 0; i < others.length; i++) {
                    btnsWidth += Gikam.jQuery(others[i]).outerWidth(true);
                }
                if (btnsWidth <= wrapperWidth) {
                    return;
                }
                const fragment = document.createDocumentFragment();
                const btn = btns[btns.length - 1];
                const items = [];
                items.unshift(this.options.toolbar[btn.getAttribute('index')]);
                btnsWidth -= Gikam.jQuery(btn).outerWidth(true);
                fragment.appendChild(btn);
                btns.pop();
                while (wrapperWidth - 89 < btnsWidth && btns.length > 0) {
                    btnsWidth -= Gikam.jQuery(btns[btns.length - 1]).outerWidth(true);
                    items.unshift(this.options.toolbar[btns[btns.length - 1].getAttribute('index')]);
                    fragment.appendChild(btns[btns.length - 1]);
                    btns.pop();
                }
                this.moreBtnOptions.fragment = [].slice.call(fragment.childNodes);
                this.moreBtnOptions.items = items;
                this.showMoreBtn = true;
            });
        },

        initToolbarForm() {
            if (Gikam.isEmpty(this.options.toolbar)) {
                return;
            }
            const _this = this;
            this.options.toolbar.forEach((item, index) => {
                if (item.type !== 'form') {
                    return;
                }
                item.fields.forEach(field => {
                    if (field.refreshGrid === false) {
                        return;
                    }
                    let change = field.onChange;
                    field.onChange = function(...args) {
                        const data = {};
                        const _val = this.getField(field.field).value;
                        data[field.field] = _val ? _val : '';
                        _this.$store.commit('refresh', {
                            requestData: data
                        });
                        change?.apply(this, args);
                    };
                });
                item.renderTo = this.$refs['toolbar_form_' + index][0];
                item.autoSave = false;
                const _rendered = item.onRendered;
                item.rendered = function(...args) {
                    _rendered?.apply(this, args);
                    _this.grid.refresh({
                        requestData: _this.toolbarFrom.getData()
                    });
                };
                this.toolbarFrom = Gikam.create('form', item);
            });
        },

        // 显示高级查询弹窗
        popGenericQueryPanel() {
            if (Gikam.genericQueryPicker) {
                return;
            }
            const rect = this.$refs['generic-query-btn'].getBoundingClientRect();
            const style = {
                right: window.screen.availWidth - rect.right + 'px',
                top: rect.bottom + 2 + 'px',
                width: '500px'
            };

            const _this = this;
            Gikam.genericQueryPicker = new Vue({
                el: Gikam.createDom('div', document.body),
                provide: {
                    grid: this.grid
                },
                components: { genericQueryPanel: () => import('../gridGeneralQueryPanel.vue') },
                methods: {
                    changeHandle(conditions) {
                        if (_this.genericQueryResetFlag) {
                            _this.genericQueryResetFlag = false;
                            return;
                        }
                        _this.genericQueryList = conditions;
                    },

                    close() {
                        if (Gikam.genericQueryPicker) {
                            Gikam.removeDom(Gikam.genericQueryPicker.$el);
                            Gikam.genericQueryPicker.$destroy();
                            Gikam.genericQueryPicker = null;
                        }
                    }
                },

                data() {
                    return {
                        options: {
                            style,
                            maxHeight: document.body.clientHeight - rect.bottom - 125,
                            conditions: _this.genericQueryList
                        }
                    };
                },
                render() {
                    return (
                        <genericQueryPanel
                            options={this.options}
                            onChange={this.changeHandle}
                            onReSet={_this.genericQueryReset}
                        ></genericQueryPanel>
                    );
                }
            });
        },

        commonSearchInputHandle: Gikam.debounce(function(value) {
            if (Gikam.isEmpty(value)) {
                this.setCommonSearchData(null);
                this.grid.refresh();
                return;
            }
            const fieldsName = this.columns
                .filter(item => {
                    if (!item.field) {
                        return false;
                    }
                    const type = this.fieldTypeMapper[item.field.replace('ext$', '')];
                    return type === 'string';
                })
                .map(item => item.field)
                .join(',');
            this.setCommonSearchData({
                name: fieldsName,
                value,
                caseSensitive: false
            });
            this.grid.refresh();
        }, 1000),

        hiddenChangehandler() {
            this.handleBtnMore({ width: this.wrapperWidth });
        }
    }
};
</script>

<style lang="scss">
.grid > .toolbar > .search {
    display: flex;
}

.grid > .toolbar > .search > .filter-dropdown-btn > .text {
    white-space: nowrap;
}

.grid > .toolbar > .operation > .item > .btn-container > .button {
    margin-right: 8px;
}

.search > .item > .form-container > .form > .panel {
    margin: 0;
}

.grid > .toolbar > .operation > .item > .form-container > .form .form-group .title {
    width: auto !important;
    padding-left: 16px;
}

.grid > .toolbar > .operation > .item > .form-container > .form .form-group:nth-child(1) .title {
    padding-left: 0;
}

.grid > .toolbar > .operation > .item > .form-container > .form .form-group {
    margin: 0;
    width: auto !important;
}

/*解决IE下toobar下formField跑版问题*/
.grid > .toolbar > .operation > .item > .form-container > .form .form-group .field {
    flex: none;
    display: flex;
}

.grid > .toolbar > .operation > .item > .form-container > .form .form-group .field .choose-input {
    min-width: 100px;
}

.operation > .item > .form-container > .form > .panel .select,
.operation > .item > .form-container > .form > .panel .text-input {
    height: 24px;
    width: 150px;
    min-height: 24px;
}

.operation > .item > .form-container > .form > .panel .select div,
.operation > .item > .form-container > .form > .panel .text-input div,
.operation > .item > .form-container > .form > .panel .text-input input {
    min-height: 24px;
    /* 解决IE浏览器下拉箭头错位问题 */
    height: 24px;
    display: flex;
    align-items: center;
}

.operation > .item > .form-container > .form {
    padding: 0;
}

.grid > .toolbar > .search > .generic-query-btn {
    display: flex;
    align-items: center;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding: 0 0 0 9px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    justify-content: space-between;
    cursor: pointer;
    margin-right: 10px;
}

.grid > .toolbar > .search > .filter-dropdown-btn > .text {
    white-space: nowrap;
}

.grid > .toolbar > .search > .generic-query-btn.active {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.grid > .toolbar > .search > .generic-query-btn > .drop {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
}

.grid > .toolbar > .search > .generic-query-btn > .drop > .search-icon {
    width: 12px;
    height: 12px;
    background: url(../../../../../img/grid-generic-query.png) no-repeat center;
    margin-right: 4px;
}

.grid > .toolbar > .search > .generic-query-btn > .refresh-icon {
    width: 34px;
    height: 100%;
    background: url(../../../../../img/grid-generic-refresh.png) no-repeat center;
    border-left: 1px solid #d9d9d9;
}

.grid > .toolbar > .search > .generic-query-btn > .refresh-icon.active {
    background: url(../../../../../img/grid-generic-refresh-blue.png) no-repeat center;
}

.grid > .toolbar > .search > .generic-query-btn > .drop > .down-arrow {
    width: 28px;
    height: 12px;
    background: url(../../../../../img/grid-generic-query-down-arrow.png) no-repeat center;
}

.grid > .toolbar > .operation > .item > .form-container > .form .form-group .field .checkbox-container {
    border: none;
}

.grid > .toolbar > .item.dropDownMenuMore {
    margin-left: 8px;
}

.grid .item.dropDownMenuMore {
    order: 1;
}

.toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .operation {
        height: 30px;
        align-items: center;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        width: 0;
        min-width: 0;
        overflow: hidden;

        > .item {
            > .caption-container {
                padding: 0 12px;
            }

            > .toolbar-operation-item {
                padding-right: 8px;
            }
        }
    }

    > .grid-toolbar-common-search {
        margin-right: 8px;
    }

    > .grid-toolbar-total-record {
        font-size: 12px;
        margin-right: 8px;
    }
}
</style>
