<template>
    <div class="upload-button" :name="field" :class="{ 'validate-error': !validateResult}">
        <div class="link-text">{{ text }}</div>
        <span class="icon upload-icon" @click="openUploadModal" v-show="!text" />
        <span class="icon delete-icon" @click="deleteFile" v-show="text" />
    </div>
</template>

<script>
import Gikam from 'gikam';
import BaseField from '../../baseField/baseField.vue';

export default {
    name: 'uploadButton',
    props: {
        options: Object,

        propReadonly: {
            type: Boolean,
            default: void 0
        },

        propInvisible: {
            type: Boolean,
            default: false
        }
    },

    extends: BaseField,

    data() {
        return {
            field: this.options.field,
            text: '',
            value: '',
            validateResult: true,
        };
    },

    methods: {
        openUploadModal() {
            const _this = this;
            Gikam.create('simpleUploader', {
                multiple: false,
                autoClose: true,
                onBeforeUpload() {
                    this.setOptions({
                        dbTable: _this.options.dbTable,
                        bizId: _this.options.bizId
                    });
                },
                onAfterClose(files) {
                    if (Gikam.isEmpty(files)) {
                        return;
                    }
                    _this.text = files[0].name;
                    _this.value = files[0].id;
                }
            });
        },

        deleteFile() {
            Gikam.del(this.options.deleteUrl, Gikam.getJsonWrapper(null, ['', [{ id: this.value }]])).done(() => {
                this.value = '';
                this.text = '';
            });
        }
    },

    watch: {
        value(val, oldVal) {
            this.validateResult = this.validate();
            if (Gikam.isTrue(this.validateResult)) {
                this.$emit('change', this.options.field, val, {oldValue: oldVal});
            }
        }
    }
};
</script>
<style scoped>
.upload-button {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-left: 8px;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    height: 100%;
    cursor: pointer;
    position: relative;
}

.upload-button.validate-error {
    border-color: #ff6e6e;
}

.upload-button:active {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.upload-button .link-text {
    width: 100%;
    display: flex;
    align-items: center;
}

.upload-button .icon {
    display: block;
    width: 24px;
    height: 17px;
    margin-right: 8px;
}

.upload-button .icon.upload-icon {
    background: no-repeat url(./img/upload.png);
}

.upload-button .icon.delete-icon {
    background: no-repeat url(./img/delete.png);
}
</style>
