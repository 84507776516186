import Gikam from '../../../core/gikam-core';

let grid = null;
export class GridColumn {
    constructor(grid, fieldName) {
        this.fieldName = fieldName;
        this.grid = grid;
    }

    /**
     * @description
     * @param {*} options
     * @returns
     * @memberof GridColumn
     * @public
     */
    setOptions(options) {
        const columns = this.grid.store.state.columns;
        const column = columns.filter(item => item.field === this.fieldName)[0];
        const def = Gikam.getDeferred();
        if (!column) {
            Gikam.error(`can not find column by ${this.fieldName}`);
            return;
        }
        if (column.type === 'select') {
            this.setSelectItems(column, options);
        } else {
            Object.assign(column, options);
        }
        return def;
    }

    /**
     * @description
     * @memberof GridColumn
     * @private
     */
    assignSelectItems(column) {
        this.grid.getData().forEach((row, i) => {
            const editor = this.grid.getField(i, column.field);
            editor && editor.setItems(column.items);
        });
        const headField = this.grid.getHeadField(column.field);
        headField && headField.setItems(column.items);
    }

    /**
     * @description
     * @param {*} options
     * @returns
     * @memberof GridColumn
     * @private
     */
    setSelectItems(column, options) {
        if (column.category && column.items) {
            delete column.items;
        }
        Object.assign(column, options);
        if (Gikam.isNotEmpty(options.items)) {
            this.assignSelectItems(column);
        } else {
            Gikam.select.cacheItems([column]).done(() => {
                this.assignSelectItems(column);
            });
        }
    }
}

const defaultFieldType = {
    string: 'text',
    double: 'number',
    long: 'number',
    localdate: 'date',
    localdatetime: 'dateTime'
};

function getFieldType(field, type) {
    field.type = defaultFieldType[type] || type;
    if (type === 'double') {
        field.isInteger = false;
    } else if (type === 'long') {
        field.isInteger = true;
    }
}

export function refreshColumnsByConfig(columns, hidden) {
    if (!Array.isArray(columns)) {
        return;
    }

    grid = Gikam.getComp(columns[0].blockId);
    grid.model.$children[0].changeHiddenGridState(!hidden);
    if (!hidden) {
        return;
    }

    const originColumns = grid.store.state.columns;
    const newColumns = originColumns.filter(item => !item.field);
    columns.forEach(item => {
        let column = originColumns.find(list => item.columnName === list.field);
        const _column = item.json;
        if (column) {
            column.visible = _column.visible;
            column.title = _column.title;
            if (_column.defaultField === '0') {
                getFieldType(column, _column.fieldType);
            }
        } else {
            column = _column;
            getFieldType(column, column.fieldType);
        }
        column.editor = _column.editor === true || _column.editor === '1' ? true : false;
        newColumns.push(column);
    });

    grid.store.commit('refreshColumns', newColumns);
}
