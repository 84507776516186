<template>
    <div :class="headerContainerClass">
        <table
            :style="tableStyleObject"
            :class="{ 'filter-open': filterOpen && filter, 'no-user-select': columnResizing }"
            @keydown.tab.stop.prevent
        >
            <thead>
                <draggable :list="columns" tag="tr" :move="moveHandle" group="gridFieldGroup" :disabled="!pageEditing">
                    <template v-for="(item, index) in columns">
                        <th
                            v-if="getFieldVisible(item) && getFieldHideHeader(item)"
                            :key="getHeaderKey(item)"
                            :style="getColumnStyle(item)"
                            :colspan="item.colspan"
                            :class="{ columnsBoder: index === columnsSelectIndex, resizing: resizing === item.field }"
                            @contextmenu.prevent="showGeneralButtonGroup(item, index, $event)"
                        >
                            <div v-if="propEditorInvisible && item.editor" class="editor-flag"></div>
                            <template v-if="item.checkbox || item.radio">
                                <badge
                                    class="corner-marker"
                                    v-if="selectNumVisible"
                                    :options="{ count: selectNum, limit: badgeOverflowCount, offset: offset }"
                                ></badge>
                                <div v-if="item.checkbox" class="all-checkbox-container">
                                    <checkbox
                                        @change="allCheckChangeHandle"
                                        :propChecked="allCheckedOptions.propChecked"
                                        :propHalfChecked="allCheckedOptions.propHalfChecked"
                                    ></checkbox>
                                </div>
                                <div class="filter" v-if="filter" v-show="filterOpen">
                                    <div class="container">
                                        <div class="icon" @click="closeFilter" :title="$i18n('grid.filter.close')">
                                            <cleanImg width="12px" height="12px" />
                                        </div>
                                        <div class="icon" @click="resetFilter" :title="$i18n('grid.filter.reset')">
                                            <clearImg width="12px" height="12px" color="#F9762B" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="item.index === true">
                                <div class="index-header-cell">{{ $i18n('grid.indexHeaderCell') }}</div>
                            </template>
                            <template v-else>
                                <i
                                    class="grid-header-resize"
                                    @mousedown="dragDown($event, item)"
                                    v-if="propColumnResize"
                                ></i>
                                <div
                                    :class="getTitleWrapperClass(item)"
                                    :style="{ justifyContent: alignMap[item.titleAlign] }"
                                    @click="sort($event, item, index), clickHandle($event, item, index)"
                                >
                                    <span
                                        :class="item | getLabelClass"
                                        v-html="(propI18N(item.title) + '').replace(/<\/?p>/g, '')"
                                        :title="formatTitleTip(item)"
                                    ></span>
                                </div>
                                <div
                                    class="filter"
                                    v-show="filter && filterOpen"
                                    :style="{ visibility: propFixedColumn ? 'hidden' : 'visible' }"
                                    @keyup.enter="changeFilter"
                                >
                                    <div v-if="item.filter === false || item.type === 'dateRange'"></div>
                                    <cell-editor
                                        v-else
                                        :options="item"
                                        :filterOriginalData="filterOriginalData"
                                        :filter="headerFilter"
                                        :requestFieldData="requestFieldData"
                                        :ref="item.field"
                                    ></cell-editor>
                                </div>
                                <i
                                    class="grid-mobile-resize"
                                    @touchstart="dragDown($event, item)"
                                    v-show="showMobileResize(item)"
                                ></i>
                                <div
                                    class="exchange-wrapper"
                                    v-if="showSortArrow && orderField === item.field && orderType"
                                >
                                    <div class="exchange-up" :class="{ active: orderType === 'asc' }"></div>
                                    <div class="exchange-down" :class="{ active: orderType === 'desc' }"></div>
                                </div>
                            </template>
                            <grid-heade-page-edit-wrapper
                                v-if="pageEditing"
                                :options="item"
                                :columns="columns"
                            ></grid-heade-page-edit-wrapper>
                        </th>
                    </template>
                </draggable>
            </thead>
        </table>
    </div>
</template>

<script>
import Gikam from 'gikam';
import { mapState, mapMutations } from 'vuex';
import badge from '@/gikam/js/components/badge/badge.vue';
import CellEditor from './gridHeaderCellEditor.vue';
import draggable from 'vuedraggable';
import gridHeadePageEditWrapper from './gridHeadePageEditWrapper';
import { GridUtils } from '../../js/grid-utils';

export default {
    props: {
        propFilter: Boolean,
        propFilterOpen: Boolean,
        propColumnResize: Boolean,
        propEditorInvisible: Boolean,
        propTotalChecked: {
            isShow: Boolean,
            num: Number
        },
        propShowCheckedNum: Boolean,
        propFixedColumn: {
            type: Boolean,
            default: false
        },
        showSortArrow: Boolean,
        propOrderField: String,
        propOrderType: String,
        propCheckContinuous: Boolean
    },

    components: { CellEditor, badge, draggable, gridHeadePageEditWrapper },

    inject: ['grid'],

    data() {
        return {
            filterOriginalData: {}, // 快捷查询的原始条件
            initX: null,
            resizeCell: null,
            columnResizing: false,
            orderField: void 0,
            orderType: void 0,
            // 移动端拖动改变列宽的标识
            showResizeField: [],
            resizing: false,
            headerFilter: true,
            alignMap: {
                left: 'flex-start',
                center: 'center',
                right: 'flex-end'
            },
            badgeOverflowCount: this.grid.options.badgeOverflowCount
        };
    },

    filters: {
        getLabelClass(field) {
            const isNotEmpty = field.validators && field.validators.indexOf('notEmpty') >= 0 ? true : false;
            if (isNotEmpty && field.titleHighlightNotEmpty) {
                return 'titleHighlight';
            } else if (isNotEmpty) {
                return 'hasRedStar';
            } else {
                return '';
            }
        }
    },

    computed: {
        ...mapState('chooseColumns', ['columnsSelectIndex']),

        ...mapState([
            'totalRecord',
            'chooseColumns',
            'borderRight',
            'headRequestData',
            'columns',
            'pageEditing',
            'page',
            'lazyLoad',
            'tableWidth',
            'defaultColumnWidth',
            'autoWidthColumn',
            'showCheckedNum',
            'grid',
            'checkedRowKeys',
            'allChecked',
            'checkedIdsStorage',
            'allCheckContinuousData'
        ]),

        selectNumVisible() {
            return (
                this.showCheckedNum &&
                (this.propCheckContinuous ? this.checkedIdsStorage.length > 1 : this.checkedRowKeys.length > 1)
            );
        },

        selectNum() {
            if (this.lazyLoad.enable) {
                return this.allChecked ? this.lazyLoad.totalRows.length : this.checkedRowKeys.length;
            } else {
                return this.propCheckContinuous ? this.checkedIdsStorage.length : this.checkedRowKeys.length;
            }
        },

        fields() {
            return this.columns.map(item => {
                if (item.field) {
                    return item.field;
                }
                return '';
            });
        },

        scrollLeft() {
            return -this.$store.state.scrollLeft + 'px';
        },

        filter() {
            return this.propFilter;
        },

        filterOpen() {
            return this.propFilterOpen;
        },

        requestData() {
            return this.$store.state.requestData;
        },

        //将条件转换为仅有field的映射
        requestFieldData() {
            const headRequestData = this.headRequestData;
            const data = {};
            const columns = this.columns.map(item => item.field);
            for (let field in headRequestData) {
                if (columns.includes(field)) {
                    data[field] = headRequestData[field];
                } else {
                    data[field.split('_')[0]] = headRequestData[field];
                }
            }
            return data;
        },

        headerContainerClass() {
            const classList = ['header-container'];
            this.borderRight && classList.push('header-right-border');
            return classList;
        },

        tableStyleObject() {
            return { width: this.tableWidth, left: this.scrollLeft };
        },

        allCheckedOptions() {
            if (this.grid.options.checkContinuous) {
                if (Gikam.isEmpty(this.checkedIdsStorage)) {
                    return {
                        propChecked: false,
                        propHalfChecked: false
                    };
                }
                if (this.checkedIdsStorage.length === this.allCheckContinuousData.length) {
                    return {
                        propChecked: true,
                        propHalfChecked: false
                    };
                }
            } else {
                if (Gikam.isEmpty(this.checkedRowKeys)) {
                    return {
                        propChecked: false,
                        propHalfChecked: false
                    };
                }
                if (this.checkedRowKeys.length === this.grid.initialRowDataList.length) {
                    return {
                        propChecked: true,
                        propHalfChecked: false
                    };
                }
            }

            return {
                propChecked: true,
                propHalfChecked: true
            };
        },

        offset() {
            return [this.selectNum > this.badgeOverflowCount ? 10 : 0, 0];
        }
    },

    methods: {
        ...mapMutations(['setHeadRequestData', 'refresh', '$window', 'setAllChecked']),

        showMobileResize(item) {
            return item.field && this.showResizeField.indexOf(item.field) > -1;
        },

        //右键表头展开操作弹窗事件
        showGeneralButtonGroup(field, index, event) {
            if (!field.generalButtonGroup) {
                return;
            }
            //不可编辑列阻止赋值
            if (!this.columns[index] || !this.columns[index].editor) {
                return;
            }
            const _this = this;
            new Gikam.Vue({
                el: Gikam.createDom('div', document.body),
                render() {
                    return (
                        <header-button-group
                            ref="vm"
                            target={event.target}
                            index={index}
                            buttonArray={field.generalButtonGroup}
                            ondownFillValueEvent={_this.downFillValueEvent}
                        ></header-button-group>
                    );
                },
                components: {
                    headerButtonGroup: () => import('../headerButtonGroup.vue')
                }
            });
        },

        //向下赋值按钮事件
        downFillValueEvent(index) {
            const column = this.columns[index];
            this.$emit('downFillValueEvent', column);
        },

        propI18N(text) {
            return Gikam.propI18N(text);
        },

        allCheckChangeHandle(checked) {
            this.setAllChecked(checked);
        },

        closeFilter() {
            this.$emit('filterClose');
        },

        changeFilter(e) {
            if (e) {
                e.target.blur && e.target.blur();
            }
        },

        resetFilter() {
            this.setHeadRequestData({});
            this.refresh();
        },

        dragDown(e, item) {
            e.preventDefault();
            this.initX = e.pageX || e.touches[0].pageX;
            this.columnResizing = true;
            this.resizeCell = item;
            this.resizeCell.width = parseInt(this.resizeCell.width);
            if (!this.resizeCell.width || this.resizeCell.width <= 0) {
                this.resizeCell.width = e.target.parentNode.offsetWidth;
            }
            this.grid.gridMask && (this.resizing = this.resizeCell.field);
            document.addEventListener('mousemove', this.dragMove, false);
            document.addEventListener('mouseup', this.dragUp, false);
            document.addEventListener('touchmove', this.dragMove, false);
            document.addEventListener('touchend', this.dragUp, false);
        },

        dragMove(e) {
            if (!this.columnResizing) return;
            const pageX = e.pageX || e.touches[0].pageX;
            let distance = pageX - this.initX;
            if (Math.abs(distance) < 3) return;
            if (distance < 0 && this.resizeCell.width < 30) return;
            distance && (this.resizeCell.width += distance);
            this.$store.commit('setTableWidth', `${parseFloat(this.tableWidth) + distance}px`);
            this.initX = pageX;
            GridUtils.fillTrHeight(this.grid);
        },

        dragUp() {
            this.initX = null;
            this.columnResizing = false;
            this.resizeCell = null;
            this.grid.gridMask && (this.resizing = false);
            document.removeEventListener('mousemove', this.dragMove, false);
            document.removeEventListener('mouseup', this.dragUp, false);
            document.removeEventListener('touchmove', this.dragMove, false);
            document.removeEventListener('touchend', this.dragUp, false);
            Gikam.finalDelay('saveColumns', this.saveColumns, 500);
        },

        saveColumns() {
            this.grid.trigger('columnsWidthChange', this.columns);
            if (Gikam.isEmpty(this.grid.options.id)) {
                return;
            }
            const url = Gikam.IFM_CONTEXT + '/core/module/sys/page-grid-field-configs';
            const { gridId, $window } = this.$store.state;
            const columnsConfig = this.columns
                .filter(item => !item.checkbox && item.index !== true && !item.radio)
                .map(item => {
                    return {
                        field: item.field,
                        width: parseInt(item.width),
                        visible: item.visible === '0' ? '0' : '1',
                        fixed: item.fixed == true ? '1' : '0'
                    };
                });
            return Gikam.postText(
                url,
                Gikam.getJsonWrapper(
                    {
                        gridId,
                        pageId: $window.getMainPageUrl()
                    },
                    ['', columnsConfig]
                )
            );
        },

        mobileColumnResize(index) {
            if (index === this.fields.length) {
                return;
            }
            this.showResizeField = [];
            this.fields[index] && this.showResizeField.push(this.fields[index]);
            this.fields[index - 1] && this.showResizeField.push(this.fields[index - 1]);
        },

        updateResizeField(flag) {
            this.showResizeField = [];
            if (flag) {
                for (let i = 0; i < this.fields.length; i++) {
                    if (this.fields[i]) {
                        this.showResizeField.push(this.fields[i]);
                        break;
                    }
                }
            }
        },

        // 排序
        // eslint-disable-next-line complexity
        sort(e, item, index) {
            if (this.grid.options.isSortByHeadClick === false) {
                return;
            }
            if (this.grid.gridMask && Gikam.isMobile()) {
                this.mobileColumnResize(index);
                return;
            }
            if (this.grid.options.columnsSelect && this.columnsSelectIndex !== index) {
                this.$store.commit('chooseColumns/changeColumnsSelectIndex', index);
                this.grid.trigger('columnActive', item.field, index);
                return;
            }
            if (item.sort === false) {
                return;
            }
            const sortField = item.field;
            let desc = void 0; // 非分组字段排序逻辑  asc -> desc -> void 0 取消排序 -> asc
            let unset = 'asc'; // 非分组字段 void 0 点击排序后转为 asc
            if (this.grid.group?.sortEnable !== false && this.grid.group?.fields.includes(sortField)) {
                desc = 'asc'; // 分组字段 不能取消排序 只能 asc -> desc -> asc
                unset = 'desc'; // 分组字段 void 0 点击排序后转为 desc
            }
            const oSortType =
                (this.orderField === sortField ? this.orderType : (this.grid.order || {})[sortField]) || 'unset';
            this.orderType = { unset, asc: 'desc', desc }[oSortType];

            this.orderField = sortField;
            this.$emit('sortListGrid', this.orderField, this.orderType);
        },

        // 表头点击事件
        clickHandle(e, item, index) {
            if (this.grid.options.isSortByHeadClick === false) {
                return;
            }
            this.grid.trigger('headerClick', item.field, index);
        },

        getHeaderKey(options) {
            if (options.index === true) {
                return 'indexKey';
            }
            if (options.checkbox) {
                return 'checkboxKey';
            }
            if (options.radio) {
                return 'radioKey';
            }
            return options.key || options.field || options.title;
        },

        getFieldVisible(options) {
            if (options.visible === undefined || options.visible === '1' || options.visible === true) {
                return true;
            }
            return false;
        },

        // 获取表头隐藏属性hideHeader
        getFieldHideHeader(field) {
            if (field.hideHeader) {
                return false;
            }
            return true;
        },

        getCellWidth(item) {
            return item.width ? 'width:' + (parseFloat(item.width) + 'px') : null;
        },

        formatTitleTip(item) {
            const title = item.titleTip ? item.titleTip : item.title;
            return (this.propI18N(title) + '').replace(/<[^>]*>|<\/[^>]*>/gm, ' ');
        },

        moveHandle() {
            return this.pageEditing;
        },

        getColumnStyle(column) {
            const style = {};
            if (column === this.autoWidthColumn) {
                style.width = null;
            } else {
                style.width = `${parseFloat(column.width || this.defaultColumnWidth)}px`;
            }
            return style;
        },

        getTitleWrapperClass(item) {
            return ['grid-header-title-wrapper', { 'is-sort': item.sort !== false }];
        }
    },

    watch: {
        propOrderField(val) {
            this.orderField = val;
        },

        propOrderType(val) {
            this.orderType = val;
        }
    }
};
</script>

<style lang="scss" scoped>
@import './css/gridHeader.scss';
</style>
