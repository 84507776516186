<template>
    <Modal :options="modal" ref="modal" v-show="!options.immediateUpload">
        <div class="uploader" slot="modal-body">
            <div class="container">
                <div
                    class="upload-container"
                    @dragstart.prevent.stop
                    @dragover.prevent.stop="dragoverHandle"
                    @drop.stop.prevent="dropHandle"
                    @dragend.stop.prevent
                >
                    <scroll>
                        <div class="item" v-for="file in fileItems" :key="file.targetId">
                            <div class="info">
                                <div class="upload-file"></div>
                                <div class="message">
                                    <div class="name">{{ file.name }}</div>
                                    <div class="file-info">
                                        <div class="size">{{ file.size }}KB</div>
                                        <div class="status" :class="file.state">{{ getFileState(file) }}</div>
                                        <div class="upload-error-icon" v-show="file.state === 'fail'" @click.stop>
                                            <info-image
                                                @mouseenter.stop="errorIconMouseenter"
                                                @mouseleave="errorIconMouseleave"
                                            ></info-image>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tool">
                                <div class="process" v-show="file.state === 'start'">
                                    <div class="process-value" :style="{ width: file.process + '%' }">
                                        <div class="process-bar"></div>
                                    </div>
                                    <div class="value-text">{{ file.process }}%</div>
                                </div>
                                <div class="upload-del" v-show="file.state === 'success'" @click="delServerFile(file)">
                                    <span class="icon icon-remove" style="font-size:18px"></span>
                                </div>
                                <div
                                    class="upload-reupload"
                                    v-show="file.state === 'fail'"
                                    @click="reupload(file)"
                                ></div>
                            </div>
                        </div>
                        <div v-if="!fileItems || fileItems.length === 0" class="uploader-file-empty">
                            <span>{{ $i18n('upload.dragOrClick') }}</span>
                            <span class="upload-icon"></span>
                            <span>{{ $i18n('upload.upload.file') }}</span>
                        </div>
                    </scroll>
                </div>

                <div
                    v-if="!(options.multiple === false && fileItems.length === 1)"
                    class="item upload-button"
                    @click="chooseFile"
                >
                    <span class="upload-icon"></span>
                    <span class="text">{{ $i18n('upload.addAttachments') }}</span>
                </div>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from '@/gikam/js/components/modal/vue/modal.vue';
import Gikam from '../../../core/gikam-core';
import jQuery from 'jquery';
import BaseField from '../baseField/baseField.vue';
import infoImage from '../img/info.vue';
import { ajaxSuccess } from '@/gikam/js/core/gikam-global-event';

export default {
    extends: BaseField,
    props: {
        options: Object
    },
    inject: {
        upload: {
            default: null
        }
    },
    data() {
        let _this = this;
        return {
            modal: {
                title: this.$i18n('upload.file.upload'),
                width: 500,
                height: 300,
                isModal: _this.options.popupIsModal,
                modal: {
                    trigger(event) {
                        if (event === 'afterClose') {
                            _this.upload.grid && _this.upload.grid.refresh();
                            _this.options.immediateUpload && top.workspace.window.closeMask();
                            _this.upload.trigger('afterClose', _this.fileItems);
                        }
                    }
                }
            },
            fileItems: [],
            error: [],
            files: [],
            limits: void 0
        };
    },
    methods: {
        getFileState(file) {
            const state = {
                start: this.$i18n('upload.tip.uploading'),
                success: this.$i18n('upload.tip.upload.succeeded'),
                fail: this.$i18n('upload.tip.upload.failed')
            };
            return file.state ? state[file.state] : '';
        },

        getFileType(type) {
            const mimeMapper = {
                image: 'image/*',
                audio: 'audio/*,video/*',
                pdf: 'application/pdf',
                xls: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                zip: 'aplication/zip',
                doc: 'application/msword',
                docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                txt: 'text/plain'
            };
            if (Array.isArray(type)) {
                return type.reduce((total, item) => {
                    return total + mimeMapper[item] + ',';
                }, '');
            } else {
                return mimeMapper[type];
            }
        },

        chooseFile() {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            this.options.multiple && input.setAttribute('multiple', 'multiple');
            this.options.accept && input.setAttribute('accept', this.getFileType(this.options.accept));
            input.addEventListener('change', event => this.selectFile(event.target.files));
            input.click();
        },

        selectFile(files) {
            let _this = this;
            Gikam.each(files, function() {
                _this.fileItems.push({
                    file: this,
                    targetId: _this.options.dbTable + '$' + _this.options.bizId,
                    name: this.name,
                    size: (this.size / 1024).toFixed(2),
                    bizCategory: _this.options.bizCategory,
                    process: 0,
                    state: 'start',
                    isFrontValiated: false
                });
            });
            if (!this.checkFileSize(files)) {
                return;
            }
            this.files.push(files);
            const accept = this.options.accept;
            if (Gikam.isString(accept)) {
                for (let i = 0; i < files.length; i++) {
                    if (accept === 'image') {
                        if (files[i].type.indexOf(accept) === -1) {
                            this.files = [];
                            _this.fileItems = [];
                            Gikam.alert(this.$i18n('upload.imageTypeError'));
                            return;
                        }
                    }
                }
            }

            let uploadFiles = this.fileItems.filter(item => item.state === 'start');
            if (_this.upload.trigger('beforeFilesUpload', uploadFiles)) {
                uploadFiles = uploadFiles.filter((file, index) => {
                    const data = _this.upload.trigger('beforeFileUpload', file);
                    if (Gikam.isTrue(data) || Gikam.isTrue(data?.result)) {
                        return true;
                    } else {
                        this.fileItems[index].state = 'fail';
                        this.fileItems[index].isFrontValiated = true;
                        _this.error.push({
                            message: data?.message
                        });
                        return false;
                    }
                });
                uploadFiles.length && _this.uploadToServer(uploadFiles);
            } else {
                _this.fileItems = _this.fileItems.slice(0, _this.fileItems.length - uploadFiles.length);
                this.files.pop();
            }
        },

        checkFileSize(files) {
            let limits = Math.ceil(this.limits * 1024 * 1024);
            if (limits && typeof limits === 'number') {
                for (let i = 0; i < files.length; i++) {
                    if (files[i].size > limits) {
                        this.files = [];
                        this.fileItems = [];
                        Gikam.alert(`${this.$i18n('upload.size.limit')}${this.limits}MB`);
                        return false;
                    }
                }
            }
            return true;
        },

        appendDataToForm(form, data) {
            Gikam.each(data, function(key, value) {
                Gikam.isNotEmpty(value) && form.append(key, value);
            });
        },

        uploadToServer: function(uploadFiles) {
            let file = uploadFiles[0];
            let form = new FormData();
            let xhr = new XMLHttpRequest();
            let def = jQuery.Deferred();
            let _this = this;
            let realFiles = this.files;

            if (Gikam.isEmpty(uploadFiles)) {
                return def;
            }

            this.options.immediateUpload && top.workspace.window.showMask();
            this.appendDataToForm(
                form,
                Gikam.extend(
                    {
                        file: file.file,
                        targetId: file.targetId,
                        name: file.name,
                        bizCategory: file.bizCategory,
                        scope: this.options?.scope
                    },
                    this.upload.uploadData
                )
            );
            xhr.open('post', this.options.server, true);
            xhr.upload.onprogress = function(e) {
                file.process = parseInt((e.loaded / e.total).toFixed(2) * 100);
                if (file.process === 100) {
                    file.state = 'complete';
                }
            };
            xhr.onreadystatechange = function() {
                if (this.readyState !== 4) return;
                if (this.status === 200) {
                    if (ajaxSuccess(this.responseText) === false) {
                        _this.$refs.modal.close();
                        return;
                    }
                    def.resolve(this.responseText, file, realFiles);
                    file.id = this.responseText;
                    file.state = 'success';
                    uploadFiles.shift();
                    if (Gikam.isNotEmpty(uploadFiles)) {
                        _this.uploadToServer(uploadFiles);
                    } else {
                        _this.upload.trigger('uploadSuccess', _this.fileItems);
                        _this.upload.options.autoClose && _this.$refs.modal.close();
                    }
                } else {
                    file.state = 'fail';
                    uploadFiles.shift();
                    if (this.response) {
                        _this.error.push(JSON.parse(this.response));
                    } else {
                        _this.error.push({
                            message: _this.$i18n('upload.tip.upload.failed')
                        });
                    }
                    if (_this.options.immediateUpload) {
                        const response = this.response
                            ? JSON.parse(this.response)
                            : { message: _this.$i18n('upload.tip.upload.failed') };
                        _this.options.immediateUpload && Gikam.alert(response.message);
                        _this.upload.options.autoClose && _this.$refs.modal.close();
                    }
                }
            };
            xhr.send(form);
            return def;
        },

        delServerFile: function(file) {
            let _this = this;
            Gikam.del(this.options.deleteUrl, Gikam.getJsonWrapper(null, ['', [{ id: file.id }]])).done(function() {
                _this.fileItems = _this.fileItems.filter(item => item.id !== file.id);
            });
        },

        reupload(file) {
            if (file.isFrontValiated) {
                return;
            }
            this.uploadToServer([file]);
        },

        errorIconMouseenter(event) {
            this.showErrorPanel(event);
        },

        errorIconMouseleave(event) {
            this.removeErrorPanel(event);
        },

        isSingleFile(event) {
            return new Promise(resolve => {
                const { files, items } = event.dataTransfer;
                const file = files[0] || items[0];
                if (!file) {
                    resolve(false);
                    return;
                }
                if (Gikam.isNotEmpty(file.type)) {
                    resolve(true);
                } else {
                    try {
                        const reader = new FileReader();
                        reader.readAsDataURL(file.slice(0, 10));
                        reader.addEventListener('error', () => {
                            resolve(false);
                        });
                        reader.addEventListener('load', () => {
                            resolve(true);
                        });
                    } catch {
                        resolve(false);
                    }
                }
            });
        },

        canUploadOnDrag(event) {
            const { files, items } = event.dataTransfer;
            if (this.options.multiple === false) {
                if (this.fileItems.length > 0) {
                    return false;
                }
                if (files.length > 1 || items.length > 1) {
                    Gikam.toast(this.$i18n('upload.single.file'));
                    return false;
                }
            }
            return true;
        },

        dropHandle(event) {
            if (!this.canUploadOnDrag(event)) {
                return;
            }
            const { files, items } = event.dataTransfer;
            if (files.length > 0) {
                this.selectFile(files);
                return;
            }
            if (items && items.length && items[0].webkitGetAsEntry) {
                const files = [];
                for (let i = 0; i < items.length; i++) {
                    files.push(items[i].getAsFile());
                }
                this.selectFile(files);
            }
        }
    },

    mounted() {
        if (this.options.immediateUpload) {
            this.options.autoClose = true;
            this.chooseFile();
        }
    },

    created() {
        Gikam.getConstantValue('FILE-SPACELIMIT').done(r => {
            this.limits = r.constantValue;
        });
    },

    components: { Modal, infoImage }
};
</script>

<style scoped lang="scss">
@mixin uploader-file-empty() {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    > .upload-icon {
        background-image: url(./img/upload.png);
        background-position: center;
        width: 16px;
        height: 16px;
        margin: 8px;
    }
}

.uploader {
    height: 100%;
    width: 100%;
    padding: 16px;
}

.uploader .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.upload-container {
    flex: 1;
    overflow-y: auto;

    .uploader-file-empty {
        @include uploader-file-empty();
    }
}

.uploader > .container .item {
    width: 100%;
    height: 48px;
    border: 1px solid #f4f4f4;
    border-bottom: none;
    background-color: #fff;
    font-size: 12px;
    position: relative;
    padding: 0 24px 0 16px;
}
.uploader > .container .item:last-child {
    border-bottom: 1px solid #f4f4f4;
}

.uploader > .container .item > .info {
    position: relative;
    float: left;
    width: 50%;
}

.uploader > .container .item > .info > .upload-file {
    position: absolute;
    width: 17px;
    height: 20px;
    top: 12px;
    background-image: url(./img/file.png);
}

.uploader > .container .item > .info > .message {
    padding-top: 8px;
    padding-left: 25px;
    line-height: 1;
}

.uploader > .container .item > .info > .message > .name {
    width: 100%;
    padding-bottom: 6px;
    color: rgba(0, 0, 0, 0.65);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.uploader > .container .item > .info > .message > .file-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.uploader > .container .item > .info > .message > .file-info > .size {
    margin-right: 35px;
    color: rgba(0, 0, 0, 0.5);
}

.uploader > .container .item > .info > .message > .file-info > .status {
    color: rgba(0, 0, 0, 0.85);
    width: 60px;
    word-break: break-all;
    white-space: nowrap;
}

.uploader > .container .item > .info > .message > .file-info > .status.fail {
    color: #ff3b30;
}

.uploader > .container .item > .info > .message > .file-info > .status.success {
    color: #52c41a;
}

.uploader > .container .item > .tool {
    width: 50%;
    float: left;
    overflow: hidden;
}

.uploader > .container .item > .tool > .process {
    position: relative;
    width: 100%;
    padding-right: 35px;
    margin-top: 15px;
}

.uploader > .container .item > .tool > .process > .value-text {
    position: absolute;
    right: 0;
    top: 1px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.65);
}

.uploader > .container .item > .tool > .process > .process-value {
    height: 20px;
    width: 100%;
    border-radius: 10px;
    background-color: #52c41a;
}

.uploader > .container .item > .tool > .process > .process-value > .process-bar {
    background-color: #d9d9d9;
    height: 100%;
    border-radius: 10px;
    width: 0;
}
.uploader > .container .item > .tool > .upload-del,
.uploader > .container .item > .tool > .upload-reupload {
    width: 16px;
    height: 16px;
    float: right;
    margin-top: 16px;
    cursor: pointer;
}

.uploader > .container .item > .tool > .upload-reupload {
    background-image: url(./img/reupload.png);
}

.uploader > .container > .item.upload-button {
    width: 100%;
    height: 28px;
    line-height: 28px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
    cursor: pointer;
    margin-top: 16px;
}

.uploader > .container > .item.upload-button > .text {
    font-size: 14px;
}

.uploader > .container > .item.upload-button > .upload-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(./img/upload.png);
    vertical-align: text-bottom;
    margin-right: 8px;
}
</style>
<style>
.uploader > input {
    display: none;
}

.uploader > .grid {
    padding: 0;
    padding-top: 8px;
}

.uploader > .grid > .toolbar {
    padding-left: 8px;
    padding-right: 8px;
}

.uploader > .grid > .grid-body,
.uploader > .grid > .grid-header {
    border-left: none;
    border-right: none;
}

.uploader > .grid > .grid-footer-container {
    padding-bottom: 0;
    border: none;
}

.uploader > .grid > .toolbar > .operation > .item > .btn-container > .button > .icon.icon-batch-download > svg {
    font-size: 12px;
}

.upload-error-icon svg {
    height: 20px;
    cursor: pointer;
}

.error-message-panel {
    position: absolute;
    background-color: #666;
    max-width: 220px;
    /* height: 30px; */
    word-break: break-all;
    word-wrap: break-word;
    border-radius: 4px;
    font-size: 12px;
    line-height: 22px;
    color: #fff;
    padding: 4px 8px;
    animation: fadeIn 0.3s;
    z-index: 11;
}

.error-message-panel::before {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #666;
    left: 10px;
    top: -2px;
    transform: rotate(45deg);
    border-radius: 2px;
}
</style>
