import Gikam from './gikam-core';
import jQuery from 'jquery';
import { I18N } from '@/gikam/i18n/I18N.js';
import { RsaEncryptUtils } from '@/gikam/js/utils/rsaEncryptUtils';
import { LoginUtils } from '../utils/loginUtils';

window.onbeforeunload = function(e) {
    if (!window.workspace) {
        return;
    }
    window.workspace.speakHandler && Gikam.stopSpeak(window.workspace.speakHandler);
    window.workspace.window?.destroyLockData();
    const confirmValue = window.workspace.onbeforeunload && window.workspace.onbeforeunload();
    if (confirmValue) {
        const _e = e || window.event;
        if (_e) {
            _e.returnValue = confirmValue;
        }
        return confirmValue;
    }
};

//销毁dropDownMenu组件
const dropDownDestroyHandle = function() {
    Gikam.dropDownMenuPicker && Gikam.dropDownMenuPicker.close();
};

//销毁grid下generalGroup组件
const generalGroupDestroyHandle = function() {
    Gikam.generalGroupPanel && Gikam.generalGroupPanel.destroy();
};
//销毁richText组件
const richTextDestroyHandle = function() {
    Gikam.textareaPicker && Gikam.textareaPicker.close();
};

//销毁grid高级搜索组件
const genericQueryDestroyHandle = function() {
    Gikam.genericQueryPicker && Gikam.genericQueryPicker.close();
};

//销毁chooseInput模拟title气泡
const poptipPanelDestroyHandle = function() {
    Gikam.poptipPanel && Gikam.poptipPanel.close instanceof Function && Gikam.poptipPanel.close();
};

//销毁下拉框组建
const selectDestroyHandle = function() {
    if (Gikam.selectPicker) {
        Gikam.selectPicker.close();
    }
    if (Gikam.insertablePicker) {
        Gikam.insertablePicker.close();
    }

    if (Gikam.comboboxPicker) {
        Gikam.comboboxPicker.combobox.cleanPicker();
    }
};

// //销毁日期选择组件
const dateDestroyHandle = function(event) {
    if (!Gikam.datePicker) {
        return;
    }
    // 点击的元素位于目标元素里或者在弹框里，都不销毁
    if (
        Gikam.datePicker.target === event.target ||
        Gikam.datePicker.target.contains(event.target) ||
        Gikam.datePicker.model.$el.contains(event.target)
    ) {
        return;
    }
    Gikam.datePicker.destroy();
};

// //销毁年选择组件
const yearDestroyHandle = function() {
    if (!Gikam.yearPicker) {
        return;
    }
    Gikam.yearPicker.destroy();
};

//销毁时间选择组件
const timeDestroyHandle = function() {
    if (Gikam.timePicker) {
        Gikam.timePicker.destroy();
    }
};

const cleanGlobalField = function(e) {
    if (Gikam.isEmpty(e)) {
        return (Gikam.globalActiveField = null);
    }
    //增加e.target.dataset判断，解决项目内手动调用document 事件 造成的影响
    if (e.target.tagName === 'INPUT' || (e.target.dataset && e.target.dataset.user === 'memoryGlobalField')) {
        return;
    }
    if (Gikam.globalActiveField) {
        return (Gikam.globalActiveField = null);
    }
};

document.addEventListener('click', function(e) {
    dropDownDestroyHandle();
    generalGroupDestroyHandle();
    dateDestroyHandle(e);
    yearDestroyHandle();
    timeDestroyHandle();
    cleanGlobalField(e);
    genericQueryDestroyHandle();
});

document.addEventListener('touchmove', dropDownDestroyHandle);
document.addEventListener('touchmove', generalGroupDestroyHandle);

const wheel = document.onmousewheel !== undefined ? 'mousewheel' : 'DOMMouseScroll';
document.addEventListener(wheel, function(e) {
    richTextDestroyHandle();
    poptipPanelDestroyHandle();
    selectDestroyHandle();
    dateDestroyHandle(e);
    yearDestroyHandle();
    timeDestroyHandle();
});

document.addEventListener('mousedown', function(e) {
    dateDestroyHandle(e);
    yearDestroyHandle();
    timeDestroyHandle();
    richTextDestroyHandle();
    selectDestroyHandle();
});

// 错误详情弹框
function createErrorMessage(response) {
    const message = Gikam.parseJson(response);
    if (!message) {
        return;
    }
    const info = {};
    const ajaxParam = Gikam.ajaxParamContainer[response.path];
    info.message = Gikam.propI18N(message.message);
    Gikam.isNotEmpty(message.stacktrace) &&
        (info.detailMessage = `${JSON.stringify(ajaxParam) || ''}\r\n${message.stacktrace}`);
    Gikam.create('ErrorAlert', info);
}

// 判断是否超时
function isSessionTimeout(response) {
    const unauthorized = 'core.security.login.unauthorized';
    if (typeof response === 'string' && (response.indexOf('<!DOCTYPE') === 0 || response.includes(unauthorized))) {
        return true;
    }
    if (typeof response === 'object' && response.message === unauthorized) {
        return true;
    }
    return false;
}

function ajaxPasswordSuccess(simpleModal) {
    Gikam.reLoginModal = null;
    simpleModal.modal.close();
    setTimeout(() => {
        const modal = Gikam.getLastModal();
        if (modal && modal.options.title !== '超时，请重新登录') {
            modal.window.pageUrl && modal.window.load(modal.window.pageUrl);
            return;
        }
        Gikam.window && Gikam.window.pageUrl && Gikam.window.load(Gikam.window.pageUrl);
        Gikam.renewInterval();
        if (Gikam.systemConfig.speedTestPeriod) {
            const worker = new Worker(process.env.BASE_URL + 'worker/network.worker.js');
            worker.postMessage({
                speedTestPeriod: Gikam.systemConfig.speedTestPeriod,
                IFM_CONTEXT: Gikam.IFM_CONTEXT
            });
            Gikam.aliveStatus.speed = worker;
        }
        Gikam.aliveStatus.keepAlive = true;
    }, 0);
}

/**
 * 请求成功
 * @param {*} response
 */
// eslint-disable-next-line complexity
export function ajaxSuccess(response) {
    if (isSessionTimeout(response)) {
        Gikam.removeInterval();

        window.workspace.window?.destroyLockData();

        if (Gikam.aliveStatus.speed) {
            Gikam.aliveStatus.speed.postMessage('end');
            Gikam.aliveStatus.speed.terminate();
            Gikam.aliveStatus.speed = null;
        }

        if (Gikam.aliveStatus.keepAlive) {
            Gikam.aliveStatus.keepAlive = false;
        }

        if (window !== top.window) {
            return;
        }

        if (Gikam.reLoginModal) {
            return;
        }

        if (!Gikam.loginUser.id) {
            location.href = '/';
            return;
        }

        if (Gikam.sessionTimeout && Gikam.isFunction(Gikam.sessionTimeout)) {
            Gikam.sessionTimeout();
            return;
        }

        if (Gikam.reLoginAlert) {
            return;
        }

        const login = async password => {
            //项目可自定制登录按钮逻辑
            const state = window.workspace.store.state;
            if (state.onTimeoutLogin) {
                state.onTimeoutLogin.call(this, password).then(() => {
                    ajaxPasswordSuccess(simpleModal);
                });
                return;
            }
            const publicKey = await RsaEncryptUtils.getPublicKey();
            Gikam.ajax({
                url: Gikam.IFM_CONTEXT + '/core/module/sys/login',
                type: 'post',
                beforeSend: xhr => {
                    xhr.setRequestHeader('SECURITY-RSA-KEY', publicKey);
                    xhr.setRequestHeader('relogin', 1);
                },
                data: {
                    id: LoginUtils.getLoginUserId(),
                    password: RsaEncryptUtils.encode(publicKey, password),
                    orgId: Gikam.loginUser.ext$.loginorgid,
                    roleId: Gikam.loginUser.ext$.loginroleid,
                    loginType: LoginUtils.getLoginType()
                }
            })
                .done(() => {
                    ajaxPasswordSuccess(simpleModal);
                })
                .fail(r => {
                    Gikam.alert(Gikam.propI18N(JSON.parse(r.responseText).message));
                });
        };

        const height = Gikam.isEmpty(LoginUtils.getLoginOrgId())
            ? Gikam.isEmpty(LoginUtils.getLoginRoleId())
                ? 200
                : 250
            : Gikam.isEmpty(LoginUtils.getLoginRoleId())
            ? 250
            : 300;

        const simpleModal = top.Gikam.createSimpleModal({
            title: I18N.prop('core.timeOut'),
            width: 400,
            height: height,
            showCloseBtn: false,
            showMaxBtn: false,
            toolbarFormatter() {
                return [
                    {
                        type: 'button',
                        text: I18N.prop('core.login'),
                        class: 'blue',
                        onClick() {
                            form.validate() && login(form.getData().password);
                        }
                    }
                ];
            }
        });

        Gikam.reLoginModal = simpleModal;

        //自定制掉线登录信息
        const state = window.workspace.store.state;
        const sessionGetUser = state.onSessionGetUser;
        const userId = sessionGetUser?.userId;
        const orgName = sessionGetUser?.orgName;
        const roleName = sessionGetUser?.roleName;

        const form = Gikam.create('form', {
            renderTo: simpleModal.layout.options.center.$dom,
            columns: 1,
            titleWidth: 50,
            autoSave: false,
            style: { padding: '16px 16px 0' },
            fields: [
                {
                    title: I18N.prop('core.userId'),
                    field: 'userId',
                    readonly: true,
                    value: userId ?? LoginUtils.getLoginUserId()
                },
                {
                    title: I18N.prop('core.password'),
                    field: 'password',
                    type: 'password',
                    validators: ['notEmpty'],
                    remembered: false,
                    onEnterKeyDown(value) {
                        form.validate() && login(value);
                    }
                },
                {
                    title: I18N.prop('core.orgName'),
                    field: 'orgName',
                    readonly: true,
                    value: orgName ?? Gikam.loginUser.ext$.loginorgname
                },
                {
                    title: I18N.prop('core.roleName'),
                    field: 'roleName',
                    readonly: true,
                    value: roleName ?? Gikam.loginUser.ext$.loginrolename
                }
            ],
            onRendered() {
                if (Gikam.isEmpty(LoginUtils.getLoginOrgId())) {
                    this.hideFields(['orgName']);
                }
                if (Gikam.isEmpty(LoginUtils.getLoginRoleId())) {
                    this.hideFields(['roleName']);
                }
            }
        });
        // 请求成功但是超时
        return false;
    }
    // 请求成功不超时
    return true;
}

jQuery.ajaxSetup({
    beforeSend(xhr) {
        if (!Gikam.isEmptyObject(Gikam.loginUser)) {
            xhr.setRequestHeader('sunway-remember-me', Gikam.loginUser.ext$['loginToken'.toLocaleLowerCase()]);
        }
        xhr.setRequestHeader('locale', Gikam.locale);
    },

    error(response) {
        if (!response.responseText) {
            return;
        }
        const responseObject = Gikam.parseJson(response.responseText);
        if (!responseObject) {
            return;
        }
        if (response.status === 410) {
            Gikam.reLoginAlert = Gikam.create('reLoginAlert', {
                title: I18N.prop('core.loginAbnormal'),
                message: I18N.prop('core.loginAbnormalMessage'),
                confirmText: I18N.prop('core.confirmText'),
                countdown: false,
                onClick: function() {
                    location.reload();
                }
            });
            return;
        }
        if (![400, 500].includes(responseObject.status)) {
            return;
        }
        if (
            Gikam.ignoreErrorUrl.some(item => {
                return responseObject.path.includes(item);
            })
        ) {
            return;
        }
        createErrorMessage(responseObject);
    },

    success: function(response) {
        ajaxSuccess(response);
    }
});
