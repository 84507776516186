<template>
    <div
        class="iframe"
        :id="options.id"
        v-show="!options.hidden"
        :style="{ height: getHeight(), minHeight: getMinHeight() }"
    >
        <iframe ref="iframe" :src="options.src" scrolling="no" @load="load"></iframe>
    </div>
</template>

<script>
export default {
    props: {
        options: Object
    },

    beforeDestroy() {
        this.$refs.iframe.src = '';
    },

    methods: {
        getHeight() {
            return this.options.height + (typeof this.options.height === 'string' ? '' : 'px');
        },
        getMinHeight() {
            return this.options.minHeight + (typeof this.options.minHeight === 'string' ? '' : 'px');
        },

        load() {
            this.$emit('load');
        }
    }
};
</script>

<style scoped>
.iframe {
    overflow: hidden;
}
.iframe iframe {
    border: none;
    width: 100%;
    height: 100%;
}
</style>
