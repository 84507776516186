import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Gikam from 'gikam';
export default {
  props: {
    width: {
      type: Number,
      default: 150
    }
  },
  data: function data() {
    return {
      focused: false,
      value: null
    };
  },
  computed: {
    styleObject: function styleObject() {
      return {
        width: "".concat(this.width, "px")
      };
    },
    classList: function classList() {
      return {
        focused: this.focused
      };
    },
    showCleanIcon: function showCleanIcon() {
      return Gikam.isNotEmpty(this.value);
    }
  },
  methods: {
    focusHandle: function focusHandle() {
      this.focused = true;
    },
    blurHandle: function blurHandle() {
      this.focused = false;
    },
    cleanClickHandle: function cleanClickHandle() {
      this.value = null;
    },
    changeHandle: function changeHandle() {
      this.$emit('change', this.value);
    },
    inputHandle: function inputHandle() {
      this.$emit('input', this.value);
    }
  }
};