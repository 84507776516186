//
//
//
//
//
//
//
//
import Input from './input.vue';
export default {
  components: {
    Input: Input
  },
  props: {
    position: {
      type: String,
      default: 'left'
    }
  },
  computed: {
    iconSlotName: function iconSlotName() {
      var mapper = {
        left: 'prefix',
        right: 'suffix'
      };
      return mapper[this.position];
    },
    classList: function classList() {
      var classList = ['input-search-wrapper', this.position];
      return classList;
    }
  },
  methods: {
    changeHandle: function changeHandle(value) {
      this.$emit('change', value);
    },
    inputHandle: function inputHandle(value) {
      this.$emit('input', value);
    }
  }
};