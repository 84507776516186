<template>
    <transition
        name="transition-slide"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
    >
        <slot></slot>
    </transition>
</template>

<script>
export default {
    methods: {
        beforeEnter() {
            const dom = this.getDom();
            dom.style.height = 0;
            dom.style.transition = 'height 0.3s ease-out';
        },

        enter() {
            const dom = this.getDom();
            dom.style.height = `${dom.scrollHeight}px`;
        },

        afterEnter() {
            const dom = this.getDom();
            dom.style.height = '';
            dom.style.transition = '';
        },

        beforeLeave() {
            const dom = this.getDom();
            dom.style.height = `${dom.scrollHeight}px`;
            dom.style.transition = 'height 0.3s ease-out';
            getComputedStyle(dom).height;
        },

        leave() {
            this.getDom().style.height = 0;
        },

        getDom() {
            return this.$slots.default ? this.$slots.default[0].elm : this.$el;
        }
    }
};
</script>
