<template>
    <svg
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        :width="width"
        :height="height"
    >
        <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="高级检索" transform="translate(0.277083, 0.010417)" :fill="color" fill-rule="nonzero">
                <path
                    d="M8.13333334,6.8 C6.26881722,6.8 4.73333335,5.26451613 4.73333335,3.39999999 C4.73333335,1.53548386 6.26881722,0 8.13333334,0 C9.99784947,0 11.5333333,1.53548387 11.5333333,3.39999999 C11.5333333,5.26451612 9.99784948,6.8 8.13333334,6.8 Z M8.13333335,1 C6.80853334,1 5.73333335,2.07519999 5.73333335,3.4 C5.73333335,4.7248 6.80853334,5.8 8.13333335,5.8 C9.45813335,5.8 10.5333333,4.7248 10.5333333,3.4 C10.5333333,2.07519999 9.45813335,1 8.13333335,1 Z"
                    id="形状"
                ></path>
                <path
                    d="M11.1312079,9.22831838 L8.15850111,7.43611502 C7.95850111,7.23611502 7.95850111,6.93611502 8.15850111,6.73611502 C8.35850111,6.53611502 8.65850111,6.53611502 8.85850111,6.73611502 L11.8312079,8.52831838 C12.0312079,8.72831838 12.0312079,9.02831838 11.8312079,9.22831838 C11.7645412,9.32831838 11.6312079,9.36165171 11.4978745,9.36165171 C11.3645412,9.36165171 11.2312078,9.32831838 11.1312079,9.22831838 Z"
                    id="路径"
                    transform="translate(9.994854, 7.973883) rotate(37.000000) translate(-9.994854, -7.973883) "
                ></path>
                <path
                    d="M0.5,0.1 L7.93544612,0.1 C8.20211279,0.1 8.43544612,0.333333328 8.43544612,0.6 C8.43544612,0.866666672 8.20211279,1.1 7.93544612,1.1 L0.5,1.1 C0.233333328,1.1 -1.02891917e-10,0.866666672 -1.02891917e-10,0.6 C-1.02891917e-10,0.333333328 0.233333328,0.1 0.5,0.1 Z M0.5,4.53333333 L3.26666667,4.53333333 C3.53333334,4.53333333 3.76666667,4.76666666 3.76666667,5.03333333 C3.76666667,5.3 3.53333334,5.53333333 3.26666667,5.53333333 L0.5,5.53333333 C0.233333328,5.53333333 -1.01863407e-10,5.3 -1.01863407e-10,5.03333333 C-1.01863407e-10,4.76666666 0.233333328,4.53333333 0.5,4.53333333 Z M0.5,9 L4.28184381,9 C4.54851049,9 4.78184381,9.23333333 4.78184381,9.5 C4.78184381,9.76666667 4.54851049,10 4.28184381,10 L0.5,10 C0.233333328,10 -1.01863407e-10,9.76666667 -1.01863407e-10,9.5 C-1.01863407e-10,9.23333333 0.233333328,9 0.5,9 Z"
                    id="形状"
                    transform="translate(4.217723, 5.050000) scale(1, -1) translate(-4.217723, -5.050000) "
                ></path>
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            type: String,
            default: '#007AFF'
        }
    }
};
</script>
