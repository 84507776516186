<template>
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g transform="translate(-480.000000, -312.000000)">
                <g transform="translate(480.000000, 312.000000)">
                    <rect id="Rectangle-12" fill="currentColor" x="0" y="0" width="20" height="20" rx="10"></rect>
                    <path
                        d="M10.0002703,16.2011622 C9.40635135,16.2011622 8.923,15.7181892 8.923,15.1242973 C8.923,14.5304054 9.40624324,14.0471622 10.0002973,14.0471622 C10.5940811,14.0471622 11.077,14.5303784 11.077,15.1242973 C11.077,15.7182162 10.5940541,16.2011622 10.0002703,16.2011622 Z M10,12.6434324 C9.61001233,12.6434324 9.29386486,12.327285 9.29386486,11.9372973 C9.29386486,10.4992703 10.4487568,9.5802973 11.213027,8.97213514 C11.3837838,8.83610811 11.5451351,8.70767568 11.637027,8.61581081 C12.07265,8.18256553 12.3168141,7.59303114 12.3150811,6.97864865 C12.3150811,5.70197297 11.2764054,4.66343243 9.99986486,4.66343243 C8.72332432,4.66343243 7.68464865,5.70197297 7.68464865,6.97864865 C7.68464865,7.36864378 7.36849513,7.6847973 6.9785,7.6847973 C6.58850487,7.6847973 6.27235135,7.36864378 6.27235135,6.97864865 C6.27235135,4.92332432 7.94437838,3.2512973 9.99986486,3.2512973 C12.0551892,3.2512973 13.7273514,4.92332432 13.7273514,6.97864865 C13.7273514,7.97427027 13.3396216,8.91027027 12.6357027,9.61418919 C12.4872162,9.76267568 12.3041351,9.90824324 12.0922703,10.0769459 C11.4745676,10.5684595 10.7058649,11.1802162 10.7058649,11.9371622 C10.7060083,12.1244504 10.6316996,12.3041149 10.4993049,12.4365857 C10.3669101,12.5690565 10.1872883,12.6434324 10,12.6434324 L10,12.6434324 Z"
                        fill="#FFFFFF"
                        fill-rule="nonzero"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {};
</script>
