import datePicker from './template/fields/date/datePicker.vue';
import Base from './base.js';
import GikamCore from 'gikam';
import Vue from 'vue';
import jQuery from 'jquery';

let defaultOptions = {
    dateInput: void 0,
    date: void 0,
    chooseDate: void 0,
    type: void 0
};

const Gikam = GikamCore.getTopGikam();

export default class DatePicker extends Base {
    constructor(options) {
        super();
        this.initialize(options, defaultOptions).init();
    }

    createModel() {
        const _this = this;
        const $dateInput = jQuery(this.options.dateInput.$el).children('input');
        const position = {
            position: 'absolute',
            top: $dateInput.offset().top + $dateInput.outerHeight() + 6
        };
        this.target = this.options.dateInput.$el.querySelector('.date-input-icon');

        if (this.options.isClickPop) {
            this.target = this.options.dateInput.$el;
        }

        let left = $dateInput.offset().left;

        const body = window.top.document.body;

        const wheel = document.onmousewheel ? 'mousewheel' : 'DOMMouseScroll';
        Gikam.simulatedEvent(document, wheel);
        let model = new Vue({
            el: jQuery('<date-picker :options="options"/>').appendTo(body)[0],
            components: {
                datePicker
            },
            data() {
                return {
                    options: _this.options
                };
            },
            mounted() {
                Gikam.setInstance(this.$el, _this);
                Gikam.datePicker = _this;
            },
            beforeDestroy() {
                Gikam.datePicker = null;
                this.target = null;
            }
        });

        if (left + jQuery(model.$el).width() < window.top.document.body.clientWidth) {
            position.left = left;
        } else {
            position.right = 8;
        }
        if (window !== window.top) {
            const iframeRect = window.top.document.body
                .querySelector(`[name='${window.name}']`)
                .getBoundingClientRect();
            position.right && (position.right += iframeRect.right);
            position.left && (position.left += iframeRect.left);
            position.top += iframeRect.top;
        }
        let $dom = jQuery(model.$el).css(position);

        if ($dom.outerHeight() + $dom.offset().top > jQuery(window.top).height()) {
            $dom.addClass('top').css({
                top: position.top - $dom.outerHeight() - 8 - $dateInput.outerHeight()
            });
        }
        return model;
    }

    destroy() {
        this.model && this.model.$children[0] && this.model.$children[0].destroy();
        Gikam.datePicker = null;
        this.options.dateInput.$refs.input?.blur();
    }

    init() {
        if (!this.options.date) {
            this.options.date = new Date();
        }
        this.model = this.createModel();
    }
}
