import Vuex from 'vuex';
import Gikam from 'gikam';
import { columnsMergeStore } from './columnsMerge';
import { getLeafCol, columnsAddHeight, getFieldWidthMap, getTotalWidth } from '../utils';

const scrollStore = {
    namespaced: true,
    state: {
        horizontal: 0
    },
    mutations: {
        changeX(state, number) {
            state.horizontal = number;
        }
    }
};

const selectRowStore = function() {
    return {
        namespaced: true,
        state: {
            selectMap: {}, //存储id:selectState对象
            checkAll: false
        },

        getters: {
            //返回选中id组成的数组
            getSelectIds(state) {
                const ids = [],
                    map = state.selectMap;
                for (const key in map) {
                    if (map[key] === true) {
                        ids.push(key);
                    }
                }
                return ids;
            }
        },
        mutations: {
            changeSelectMap(state, { id, selectState }) {
                if (!id && id !== 0) {
                    return;
                }
                state.selectMap = {
                    [id]: selectState
                };
            },
            updateSelectMap(state, { id, selectState }) {
                if (!id && id !== 0) {
                    return;
                }
                const oldMap = Gikam.deepExtend(state.selectMap);
                oldMap[id] = selectState;
                state.selectMap = oldMap;
            },
            changeCheckAll(state, boolean) {
                state.checkAll = boolean;
            }
        }
    };
};

const moveCursorStore = {
    namespaced: true,
    state: {
        keyDownField: [
            'text',
            'textInput',
            'number',
            'textarea',
            'password',
            'richText',
            'year',
            'time',
            'date',
            'choose',
            'select',
            'insertableSelect',
            'comboBox',
            'cron'
        ],
        currentCellRow: -1,
        currentCellCol: -1,
        moveDirectionCode: 0,
        isMoving: false,
        startCell: ''
    },
    getters: {
        currentCell(state) {
            return state.currentCellRow + '_' + state.currentCellCol;
        }
    },
    mutations: {
        changeCurrentCellRow(state, num) {
            state.currentCellRow = num;
        },
        changeCurrentCellCol(state, num) {
            state.currentCellCol = num;
        },
        changeMoveDirectionCode(state, code) {
            if (Gikam.isNotEmpty(code)) {
                state.moveDirectionCode = code;
            }
        },
        changeIsMoving(state, B) {
            state.isMoving = B;
        },
        changeStartCell(state, string) {
            state.startCell = string;
        }
    }
};

const gridBodyStore = function(options) {
    return {
        namespaced: true,
        state: {
            activeRowIndex: '',
            activeColIndex: '',
            cellClickEffectRow: options.cellClickEffectRow
        },
        mutations: {
            changeActiveRowIndex(state, { rowIndex, colIndex, type }) {
                if (type === 'cell' && !state.cellClickEffectRow) {
                    return;
                }
                state.activeRowIndex = rowIndex || 0;
                state.activeColIndex = colIndex || 0;
            }
        }
    };
};

const gridCellStore = {
    namespaced: true,
    state: {
        activeCellVue: null
    },
    mutations: {
        changeActiveCellVue(state, cellVue) {
            state.activeCellVue = cellVue;
        }
    }
};

const toolStore = function() {
    return {
        namespaced: true,
        state: {
            buttonIsInit: false
        },
        mutations: {
            changeStore(state, { key, value }) {
                state[key] = value;
            }
        }
    };
};

export function tableStore(Vue, defaultOptions, instant) {
    Vue.use(Vuex);
    const _options = Gikam.deepExtend(defaultOptions),
        gridBody = gridBodyStore(_options),
        moveCursor = Gikam.deepExtend(moveCursorStore),
        gridCell = Gikam.deepExtend(gridCellStore),
        scroll = Gikam.deepExtend(scrollStore);
    return new Vuex.Store({
        state: {
            defaultOptions: _options,
            columns: _options.columns,
            showTable: _options.showBody,
            data: [],
            canAutoSave: true,
            //存储表头 各级所占的高度
            headerHeightArr: [],
            //存储表头 各列所占宽度
            fieldWidthMap: {},
            //组件事件的总宽度
            allColsWidth: 0
        },
        getters: {
            tableHeight(state) {
                return state.defaultOptions.height;
            },
            columnGroup(state) {
                return state.defaultOptions.columnGroup;
            },
            fill(state) {
                return state.defaultOptions.defaultFill || state.defaultOptions.fill;
            },
            fieldChangeFlag(state) {
                return state.defaultOptions.fieldChangeFlag;
            },
            fillActiveValue(state) {
                return state.defaultOptions.fillActiveValue;
            },
            tableId(state) {
                return state.defaultOptions.id;
            },
            toolbarList(state) {
                return state.defaultOptions.toolbar.filter(item => item.type === 'button');
            },
            toolbarFormList(state) {
                return state.defaultOptions.toolbar.filter(item => item.type !== 'button');
            },
            group(state) {
                return state.defaultOptions.group;
            },
            noScroll(state) {
                return state.defaultOptions.noScroll;
            },
            hasAudit(state) {
                return state.defaultOptions.hasAudit;
            },
            activeOnCheck(state) {
                return state.defaultOptions.activeOnCheck;
            },
            checkOnActive(state) {
                return state.defaultOptions.checkOnActive;
            },
            activeCell(state) {
                return state.defaultOptions.activeCell;
            },
            cellClickEffectRow(state) {
                return state.defaultOptions.cellClickEffectRow;
            },
            leafColumns(state) {
                return getLeafCol(state.columns, []);
            }
        },
        mutations: {
            changeDefaultOptions(state, options) {
                state.defaultOptions = options;
                if (options.columnGroup) {
                    this.commit('columnsMerge/changeColumnGroup', options.columnGroup);
                    this.commit('columnsMerge/initColumnMergeMap', state.data);
                }
            },
            changeColumns(state, columns) {
                //计算表头高度
                const heightArr = columnsAddHeight(columns);
                this.commit('initHeaderHeight', heightArr);

                // 得到field-宽度 对象 并在叶子节点上挂在自己的所有父节点
                const fieldWidthMap = getFieldWidthMap(columns);
                this.commit('changeFieldWidthMap', fieldWidthMap);

                //获取总宽度
                const allColsWidth = getTotalWidth(columns);
                this.commit('changeAllColsWidth', allColsWidth);

                state.columns = columns;
            },

            changeData(state, data) {
                this.commit('columnsMerge/initColumnMergeMap', data);
                state.data = Gikam.deepExtend(data);
            },
            updateData(state, data) {
                state.data = Gikam.deepExtend(state.data, data);
            },
            changeCanAutoSave(state, B) {
                state.canAutoSave = B;
            },
            changeShowTable(state, boolean) {
                state.showTable = boolean;
            },
            initHeaderHeight(state, heightArr) {
                state.headerHeightArr = heightArr;
            },
            changeFieldWidthMap(state, fieldWidthMap) {
                state.fieldWidthMap = fieldWidthMap;
            },
            changeAllColsWidth(state, allColsWidth) {
                state.allColsWidth = allColsWidth;
            },
            refreshGrid(state, param) {
                instant.refresh({
                    requestData: param
                });
            }
        },
        modules: {
            gridBody,
            moveCursor,
            gridCell,
            scroll,
            selectRow: selectRowStore(),
            tool: toolStore(),
            columnsMerge: columnsMergeStore()
        }
    });
}
