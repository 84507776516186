import "ant-design-vue/lib/menu/style/css";
import _Menu from "ant-design-vue/lib/menu";
import "ant-design-vue/lib/dropdown/style/css";
import _Dropdown from "ant-design-vue/lib/dropdown";
import "core-js/modules/web.dom-collections.for-each.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Gikam from 'gikam';
import Vue from 'vue';
import ArrowDown from './img/ArrowDownO.vue';
Vue.use(_Dropdown);
Vue.use(_Menu);
export default {
  props: {
    options: Object
  },
  components: {
    ArrowDown: ArrowDown
  },
  data: function data() {
    return {
      caret: 'down',
      picker: void 0,
      items: this.options.items || [],
      field: this.options.field,
      maxHeight: 150,
      timer: null,
      enterButton: false,
      hidden: Gikam.isEmpty(this.options.hidden) ? false : this.options.hidden
    };
  },
  watch: {
    'options.items': function optionsItems(items) {
      this.items = items;
    }
  },
  mounted: function mounted() {
    this.$emit('rendered', this.field);
    Gikam.setInstance(this.$el, this);
  },
  methods: {
    getI18N: function getI18N(text) {
      return Gikam.propI18N(text);
    },
    meunClick: function meunClick(item) {
      item.onClick && item.onClick();
    },
    visibleChangeHandle: function visibleChangeHandle(val) {
      this.$forceUpdate();
      this.caret = val ? 'up' : 'down';
    },
    setItems: function setItems(items) {
      if (Gikam.isArray(items)) {
        this.items = items;
        return true;
      }

      return false;
    },
    show: function show(id) {
      if (id) {
        this.items.forEach(function (item) {
          if (item.id === id) {
            item.hidden = false;
          }
        });

        if (this.items.some(function (item) {
          return item.hidden === false;
        })) {
          this.hidden = false;
        }
      } else {
        this.hidden = false;
      }

      this.$emit('update');
    },
    hide: function hide(id) {
      if (id) {
        this.items.forEach(function (item) {
          if (item.id === id) {
            item.hidden = true;
          }
        });

        if (this.items.every(function (item) {
          return item.hidden === true;
        })) {
          this.hidden = true;
        }
      } else {
        this.hidden = true;
      }

      this.$emit('update');
    }
  }
};