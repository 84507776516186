<template>
    <svg
        width="17px"
        height="16px"
        viewBox="0 0 17 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>复制</title>
        <g id="MDM2.0" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="复制" transform="translate(0.990088, 0.000000)">
                <rect id="矩形备份-427" x="0" y="0" width="16" height="16"></rect>
                <path
                    d="M13.8460286,0.909090909 L13.9145294,0.910938261 C14.572191,0.946500349 15.0945134,1.49104059 15.0945134,2.15757576 L15.0945134,2.15757576 L15.0945134,11.2969697 L15.0926661,11.3654706 C15.057104,12.0231321 14.5125637,12.5454545 13.8460286,12.5454545 L13.8460286,12.5454545 L12.5486952,12.5450909 L12.5490589,13.8181818 C12.5490589,14.4771579 12.048241,15.0191618 11.4064606,15.0843381 L11.2763316,15.0909091 L2.18542251,15.0909091 C1.48251464,15.0909091 0.912695234,14.5210897 0.912695234,13.8181818 L0.912695234,13.8181818 L0.912695234,4.72727273 C0.912695234,4.02436486 1.48251464,3.45454545 2.18542251,3.45454545 L2.18542251,3.45454545 L3.45769523,3.45409091 L3.45814978,2.15757576 L3.45999713,2.08907489 C3.49555922,1.43141331 4.04009946,0.909090909 4.70663463,0.909090909 L4.70663463,0.909090909 L13.8460286,0.909090909 Z M11.2763316,4.54545455 L2.18542251,4.54545455 C2.0850071,4.54545455 2.00360433,4.62685732 2.00360433,4.72727273 L2.00360433,4.72727273 L2.00360433,13.8181818 C2.00360433,13.9185972 2.0850071,14 2.18542251,14 L2.18542251,14 L11.2763316,14 C11.376747,14 11.4581498,13.9185972 11.4581498,13.8181818 L11.4581498,13.8181818 L11.4581498,4.72727273 C11.4581498,4.62685732 11.376747,4.54545455 11.2763316,4.54545455 L11.2763316,4.54545455 Z M13.8460286,2 L4.70663463,2 L4.67831019,2.00253876 C4.60480135,2.015881 4.54905887,2.0802187 4.54905887,2.15757576 L4.54905887,2.15757576 L4.54869523,3.45409091 L11.2763316,3.45454545 C11.9353077,3.45454545 12.4773116,3.95536329 12.5424879,4.59714376 L12.5490589,4.72727273 L12.5486952,11.4540909 L13.8460286,11.4545455 L13.874353,11.4520067 C13.9478619,11.4386645 14.0036043,11.3743268 14.0036043,11.2969697 L14.0036043,11.2969697 L14.0036043,2.15757576 L14.0010656,2.12925132 C13.9877233,2.05574247 13.9233856,2 13.8460286,2 L13.8460286,2 Z"
                    id="形状结合"
                    fill="#007AFF"
                    fill-rule="nonzero"
                ></path>
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default {
    extends: baseSvgImg,
    props: {
        color: {
            default: '#007AFF',
            type: String
        }
    }
};
</script>

<style scoped>
svg {
    display: block;
}
</style>
