<template>
    <SunwayDatePicker 
        size="small" 
        @change="changeHandle" 
        v-model="value" 
        :options="{ ...options, type: 'RangePicker' }" />
</template>
<script>
import SunwayDatePicker from '@/sunway/date-picker/date-picker';
import Gikam from 'gikam';
import moment from 'moment';
import DateUtils from '@/gikam/js/utils/dateUtils';

export default {
    components: {
        SunwayDatePicker
    },

    props: {
        options: Object,
        propValue: String
    },

    data() {
        return {
            value: null
        };
    },

    watch: {
        propValue(value) {
            if (!value) {
                this.value = [null, null];
            }
        }
    },

    methods: {
        changeHandle(value) {
            let fromValue = null;
            let toValue = null;
            if (Gikam.isEmpty(value)) {
                fromValue = null;
                toValue = null;
            } else {
                fromValue = DateUtils.momentToString(moment(value[0]));
                toValue = DateUtils.momentToString(moment(value[1]));
            }
            const changeOptions = [];
            changeOptions.push({ value: fromValue, match: 'DGOE' });
            changeOptions.push({ value: toValue, match: 'DLOE' });
            this.$emit('change', changeOptions);
        }
    }
};
</script>
