<template>
    <div
        class="choose-input"
        :class="{ 'validate-error': !validateResult, readonly: readonly, invisible: invisible }"
        @dblclick="dblclickHandle"
        @mousedown.stop
        @click="clickHandle"
        @select="selectHandle"
    >
        <div v-if="readonly || invisible" class="readonly-text">
            <div class="content-wrapper" v-html="$xss(formatterValue)" :title="formatterValue"></div>
        </div>
        <template v-else>
            <input v-once type="text" ref="input" readonly key="1" :value="formatterValue" class="disabledInput" />
            <div v-if="editable" class="input" :name="options.field">
                <input
                    v-if="renderType === 'text'"
                    :name="options.field"
                    :value="formatterValue"
                    :placeholder="placeholder"
                    autocomplete="off"
                    @change="changeValue"
                />
                <div
                    v-else
                    class="content-wrapper content-input"
                    contenteditable="true"
                    v-html="$xss(formatterValue)"
                    @blur="changeDivValue"
                ></div>
            </div>
            <div v-else class="input content-readonly" :name="options.field">
                <div
                    class="content-wrapper placeholder"
                    v-if="placeholder"
                    v-html="$xss(placeholder)"
                    :title="placeholder"
                ></div>
                <div class="content-wrapper" v-else v-html="$xss(formatterValue)" :title="formatterValue"></div>
            </div>
            <div class="choose-input-icon" @click="choose">
                <singleChoose v-if="options.single" v-bind="{ color: '#666666', width: '14px', height: '14px' }" />
                <chooseImg v-else v-bind="{ color: '#666666', width: '14px', height: '14px' }" />
            </div>
            <div class="choose-input-clean" v-if="isShowClean" @click="cleanValue">
                <cleanImg v-bind="{ color: '#666666', width: '14px', height: '14px' }" />
            </div>
        </template>
        <div class="validate-error-icon" v-if="validateResult === false" @click.stop>
            <info-image @mouseenter.stop="errorIconMouseenter" @mouseleave.stop="errorIconMouseleave"></info-image>
        </div>
    </div>
</template>
<script>
import GikamCore from '../../../core/gikam-core';
import infoImage from '../img/info.vue';
import BaseField from '../baseField/baseField.vue';

const Gikam = GikamCore.getTopGikam();

const defaultOptions = {
    placeholder: '',
    renderType: 'text',
    formatter: null
};

export default {
    name: 'chooseField',
    props: {
        options: Object,
        propValue: String,
        propSetValueAfterChoose: Boolean,
        rowIndex: Number,
        cellIndex: Number,
        propReadonly: Boolean,
        propInvisible: {
            type: Boolean,
            default: false
        },
        isQueryPanel: {
            type: Boolean,
            dafault: false
        }
    },

    components: { infoImage },
    extends: BaseField,
    data() {
        return {
            validateResult: true,
            readonly: Gikam.isNotEmpty(this.propReadonly) ? this.propReadonly : this.options.readonly,
            field: this.options.field,
            value: this.propValue || this.options.value,
            setValueAfterChoose: this.propSetValueAfterChoose || false,
            invisible: this.propInvisible,
            placeholder: Gikam.propI18N(this.options.placeholder) || defaultOptions.placeholder,
            //判断是否为初始化之后的赋值
            setDataState: false
        };
    },

    inject: {
        form: {
            default: null
        },
        grid: {
            default: null
        }
    },

    computed: {
        isShowClean() {
            return this.value && this.options.cleanable !== false;
        },

        editable() {
            return this.options.editable;
        },

        formatterValue() {
            const formatter = this.options.formatter || defaultOptions.formatter;
            if (formatter) {
                return formatter.call(this.from || this.grid, this.field, this.value, this.rowIndex);
            } else {
                return this.value;
            }
        },

        targetFields() {
            if (!this.options.targetFields) {
                return [];
            }
            return this.options.targetFields.reduce((result, item) => {
                let targetField = item;
                let valueField = item;
                if (Gikam.isPlainObject(item)) {
                    targetField = Object.keys(item)[0];
                    valueField = item[targetField];
                }
                result.push({ targetField, valueField });
                return result;
            }, []);
        },

        renderType() {
            return this.options.renderType || defaultOptions.renderType;
        }
    },

    watch: {
        value(val, oldVal) {
            if (this.setDataState) {
                this.setDataState = false;
                return;
            }

            if (Gikam.isEmpty(val) && Gikam.isEmpty(oldVal)) {
                return;
            }

            if (this.form || this.grid) {
                this.validateResult = this.validate();
            } else {
                this.validateResult = true;
            }
            if (Gikam.isTrue(this.validateResult)) {
                this.$emit('change', this.options.field, val, this.rowIndex, oldVal);
            }
        },

        propValue(val) {
            if (val === undefined || this.value !== val) {
                this.setDataState = true;
            }
            this.validateResult = true;
            this.value = val;
        },

        propReadonly(readonly) {
            this.readonly = readonly;
        }
    },

    methods: {
        cleanValue() {
            if (Gikam.isNotEmpty(this.targetFields)) {
                this.cleanRelateFieldsValue();
            } else {
                if (!this.options.onClean) {
                    this.$emit('selfClean');
                    this.value = '';
                } else {
                    this.options.onClean.call(this.form || this.grid, this.options.field, this.value, this.rowIndex);
                }
            }
        },

        changeValue(e) {
            this.value = e.target.value;
        },

        changeDivValue(e) {
            this.value = e.target.innerText;
        },

        // eslint-disable-next-line complexity
        async choose() {
            if (this.options.onChoose) {
                this.modal = this.options.onChoose.apply(this.form || this.grid, [this.rowIndex]);
                return;
            }
            let category = this.options.category;
            if (this.options.getCategory) {
                category = this.options.getCategory();
            }
            let config = Gikam.choose.getConfig(category);
            let url = Gikam.IFM_CONTEXT + config.url;
            let customData = null;

            if (this.options.onBeforeChoose) {
                customData = this.options.onBeforeChoose.call(this.form || this.grid, this.rowIndex);
            }
            if (customData?.resolve) {
                await customData.done(data => {
                    customData = data;
                });
            }

            if (customData === false) {
                return;
            }

            const requestData = Gikam.deepExtend(customData);
            const _this = this;
            this.options.single && (requestData.single = 1);
            this.modal = Gikam.create('modal', {
                title: config.title,
                url: url + Gikam.param(requestData),
                width: config.width,
                height: config.height,
                isQueryPanel: this.isQueryPanel,
                onAfterClose: function(rows) {
                    if (_this.setValueAfterChoose && Gikam.isNotEmpty(rows)) {
                        _this.value = Gikam.getFieldValue(rows[0], _this.field);
                    }
                    _this.$emit('afterChoose', rows, _this.rowIndex, _this.targetFields);
                    if (!_this.isQueryPanel) {
                        _this.setRelateFieldsValue(rows);
                    }
                    _this.focus();
                },
                onBeforeClose() {
                    const { onBeforeModalClose } = this.options;
                    if (onBeforeModalClose) {
                        return onBeforeModalClose.call(this);
                    }
                    return true;
                }
            });
        },

        dblclickHandle() {
            this.$emit('dblclick', this);
        },

        errorIconMouseenter(event) {
            this.showErrorPanel(event);
        },

        errorIconMouseleave(event) {
            this.removeErrorPanel(event);
        },

        //给其他字段赋值
        setRelateFieldsValue(rows) {
            if (Gikam.isEmpty(rows) || Gikam.isEmpty(this.targetFields)) {
                return;
            }
            const data = Gikam.isArray(rows) ? rows[0] : rows;
            const valueObject = this.targetFields.reduce(
                (mapper, item) => {
                    let fieldValue = Gikam.getFieldValue(data, item.valueField);
                    if (fieldValue === undefined) {
                        fieldValue = '';
                    }
                    if (item.targetField.includes('ext$')) {
                        mapper.ext$[item.targetField.replace('ext$.', '')] = fieldValue;
                    } else {
                        mapper[item.targetField] = fieldValue;
                    }
                    return mapper;
                },
                { ext$: {} }
            );
            if (Gikam.isNotEmpty(this.rowIndex)) {
                valueObject.index = this.rowIndex;
            }
            (this.form || this.grid).setData(valueObject);
            this.options.onChange?.call(this.options.field, valueObject[this.options.field], this.rowIndex, this.value);
        },

        //清空相关字段值
        cleanRelateFieldsValue() {
            if (this.isQueryPanel) {
                this.value = '';
            }
            this.setRelateFieldsValue([{}]);
        },

        dumpActiveCell() {
            if (!this.invisible) {
                this.$refs.input && this.$refs.input.blur();
            }
            this.modal && this.modal.close();
        },

        activeCell() {
            if (!this.invisible) {
                this.focus();
            } else {
                this.$emit('click', this);
            }
        },

        focus() {
            this.$refs.input && this.$refs.input.focus();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        },

        override(props) {
            Gikam.extend(defaultOptions, props);
        },

        selectHandle() {
            this.$emit('saveCoordinate');
        }
    }
};
</script>

<style scoped>
.choose-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    position: relative;
}

.choose-input input,
.choose-input .input,
.choose-input .readonly-text {
    width: 100%;
    border: none;
}

.choose-input input,
.choose-input .content-wrapper.content-input,
.choose-input .content-readonly,
.choose-input .readonly-text {
    border: 1px solid #d9d9d9;
    padding-left: 8px;
    padding-right: 45px;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    font-family: 'Microsoft YaHei', serif;
    font-size: 12px;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
}

.choose-input .readonly-text {
    padding-right: 8px;
}

.choose-input .input >>> p {
    margin: 0;
}

.choose-input .content-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.choose-input .content-wrapper.content-input {
    width: 100%;
    line-height: 28px;
}

.choose-input .content-wrapper.placeholder {
    color: rgba(0, 0, 0, 0.15);
}

.choose-input .input >>> a,
.choose-input .readonly-text >>> a {
    color: #007aff;
    text-decoration: none;
}

.choose-input .readonly-text {
    background-color: #f4f4f4;
    display: flex;
    align-items: normal;
}

.choose-input input:focus,
.choose-input .content-wrapper.content-input:focus {
    border: 1px solid rgba(0, 122, 255, 0.5);
}

.choose-input.validate-error input,
.choose-input.validate-error .input {
    border-color: #ff6e6e;
}

.choose-input .choose-input-icon {
    width: 30px;
    cursor: pointer;
    margin-left: -30px;
    background-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.choose-input .choose-input-clean {
    visibility: hidden;
    position: absolute;
    right: 28px;
    width: 20px;
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.choose-input:hover .choose-input-clean {
    visibility: visible;
}

.invisible .readonly-text {
    background-color: transparent;
    border: none;
}

.choose-input .readonly-text >>> p {
    margin: 0;
    line-height: 30px;
}
</style>
<style>
@keyframes fadeIn {
    from {
        opacity: 0.3;
    }
    to {
        opacity: 1;
    }
}

.choose-input input.disabledInput {
    width: 0;
    opacity: 0;
    float: left;
    z-index: -1;
    border-width: 0;
    padding: 0;
    margin: 0;
    outline: none;
}
.choose-input input.disabledInput:focus {
    border: none;
}

.choose-input .validate-error-icon {
    margin-left: -45px;
    display: flex;
    align-items: center;
}

.choose-input .validate-error-icon svg {
    height: 20px;
    cursor: pointer;
}
</style>
