<template>
    <svg
        :width="width"
        :height="height"
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <desc>Created with Sketch.</desc>
        <g id="控件" stroke="none" stroke-width="1" :fill="color" fill-rule="evenodd">
            <g id="特殊字符">
                <rect
                    id="矩形备份"
                    fill-opacity="0"
                    :fill="color"
                    opacity="0"
                    transform="translate(8.000000, 8.000000) scale(1, -1) rotate(90.000000) translate(-8.000000, -8.000000) "
                    x="0"
                    y="0"
                    width="16"
                    height="16"
                ></rect>
                <path
                    d="M8.00258201,1.33 C11.8659932,1.33049507 15,4.33037131 14.5,8.33020629 C14.203526,10.7019004 13.3796711,12.1946624 11.9241987,13.3296971 L14.5,13.33 C14.7761424,13.33 15,13.5538576 15,13.83 L15,14.16 C15,14.4361424 14.7761424,14.66 14.5,14.66 L10.5,14.66 C10.2238576,14.66 10,14.4361424 10,14.16 L10,13.33 L10,13.33 C11.7368577,12.303172 12.7231235,11.0611611 13,8.33020629 C13.3605485,4.77395743 11.1231266,2.43842312 8.3587592,2.33409937 L8.16506744,2.33045381 L8,2.33345369 L7.999,2.332 L7.83751457,2.32995875 L7.64382281,2.3336043 C4.87945539,2.43792805 2.64203347,4.77346237 3.00258201,8.32971122 C3.27945852,11.060666 4.26572426,12.3026769 6.00258201,13.3295049 L6.00258201,13.3295049 L6,13.33 L6,14.16 C6,14.4361424 5.77614237,14.66 5.5,14.66 L5.5,14.66 L1.5,14.66 C1.22385763,14.66 1,14.4361424 1,14.16 L1,14.16 L1,13.83 C1,13.5538576 1.22385763,13.33 1.5,13.33 L1.5,13.33 L4.07838858,13.3292061 C2.62291292,12.1941709 1.79905635,10.7014081 1.50258201,8.32971122 C1.00258201,4.32987624 4.13658876,1.33 8.00258201,1.33 L8,1.33049507 L8.00258201,1.33 Z"
                    id="形状结合"
                    :fill="color"
                ></path>
            </g>
        </g>
    </svg>
</template>

<script>
import baseSvgImg from './baseSvgImg';
export default { extends: baseSvgImg };
</script>

<style scoped>
svg {
    display: block;
}
</style>
