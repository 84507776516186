<template>
    <a-badge v-bind="options" :overflowCount="options.limit" :offset="options.offset">
        <slot></slot>
    </a-badge>
</template>

<script>
import { Badge } from 'ant-design-vue';
import Vue from 'vue';

Vue.use(Badge);

export default {
    props: {
        options: Object
    }
};
</script>
