import Quagga from 'quagga';
function decodeSingle(file, callback) {
    Quagga.decodeSingle(
        {
            inputStream: {
                name: 'image',
                type: 'ImageStream',
                singleChannel: false
            },
            locator: {
                patchSize: 'medium',
                halfSample: false
            },
            numOfWorkers: 1,
            decoder: {
                readers: ['code_128_reader']
            },
            locate: true,
            src: URL.createObjectURL(file)
        },
        result => {
            if (result && result.codeResult) {
                callback(result.codeResult.code);
            } else {
                callback(this.$i18n('scan.code.fail.tip'));
            }
        },
        () => {
            callback(this.$i18n('scan.code.fail.tip'));
        }
    );
}

export function scanBarCode(callback) {
    const inputDom = document.createElement('input');
    inputDom.setAttribute('type', 'file');
    inputDom.setAttribute('accept', 'image/*');
    inputDom.setAttribute('capture', 'camera');
    inputDom.click();
    inputDom.addEventListener('change', e => {
        const files = e.target.files;
        if (files.length === 0) {
            return;
        }
        decodeSingle(files[0], callback);
    });
}
