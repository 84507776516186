import Gikam from '../core/gikam-core.js';
import Vue from 'vue';
import Base from './base';

let defaultOptions = {
    id: void 0,
    images: void 0,
    imageRotate: 0
};

export default class Viewer extends Base {
    constructor(props) {
        super(props);
        this.initialize(props, defaultOptions).init(props);
    }

    createModel() {
        const _this = this;
        this.model = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            components: { viewer: () => import('./template/viewer.vue') },
            methods: {
                sizeChangeHandle() {
                    this.$nextTick(() => {
                        this.$refs['viewer'].resize();
                    });
                }
            },
            render() {
                return (
                    <div style="height: 100%; position: relative">
                        <div
                            style="position: absolute;top: 0;left: 0;right: 0;bottom: 0;"
                            v-observe-size={this.sizeChangeHandle}
                        >
                            <viewer
                                images={_this.options.images}
                                imageRotate={_this.options.imageRotate}
                                ref="viewer"
                            ></viewer>
                        </div>
                    </div>
                );
            }
        });
    }

    init() {
        this.model = this.createModel();
    }

    onResize() {
        this.model.$children[0].resize();
    }
}
