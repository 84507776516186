<template>
    <svg
        width="1em"
        height="1em"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>仪器</title>
        <g id="结果录入" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="2.点击下拉选择框" transform="translate(-802.000000, -667.000000)" fill-rule="nonzero">
                <g id="设备仪器" transform="translate(802.000000, 667.000000)">
                    <rect id="矩形" fill="#000000" opacity="0" x="0" y="0" width="14" height="14"></rect>
                    <path
                        d="M2.45,6.65 C2.8364,6.65 3.15,6.3364 3.15,5.95 C3.15,3.8269 4.8769,2.1 7,2.1 C9.1231,2.1 10.85,3.8269 10.85,5.95 C10.85,6.3364 11.1636,6.65 11.55,6.65 C11.9364,6.65 12.25,6.3364 12.25,5.95 C12.25,3.0555 9.8952,0.7 7,0.7 C4.1048,0.7 1.75,3.0555 1.75,5.95 C1.75,6.3364 2.0636,6.65 2.45,6.65 Z"
                        id="路径"
                        fill="#1597A5"
                        opacity="0.8"
                    ></path>
                    <path
                        d="M7,3.395 C5.4558,3.395 4.2,4.6508 4.2,6.195 C4.2,6.5814 4.5136,6.895 4.9,6.895 C5.2864,6.895 5.6,6.5814 5.6,6.195 C5.6,5.4229 6.2279,4.795 7,4.795 C7.7721,4.795 8.4,5.4229 8.4,6.195 C8.4,6.5814 8.7136,6.895 9.1,6.895 C9.4864,6.895 9.8,6.5814 9.8,6.195 C9.8,4.6508 8.5442,3.395 7,3.395 Z"
                        id="路径"
                        fill="#1597A5"
                        opacity="0.9"
                    ></path>
                    <path
                        d="M12.6,7.7 L7.7,7.7 L7.7,6.685 C7.7,6.2986 7.3864,5.985 7,5.985 C6.6136,5.985 6.3,6.2986 6.3,6.685 L6.3,7.7 L1.4,7.7 C0.6265,7.7 0,8.3265 0,9.1 L0,11.9 C0,12.6735 0.6265,13.3 1.4,13.3 L12.6,13.3 C13.3735,13.3 14,12.6735 14,11.9 L14,9.1 C14,8.3265 13.3735,7.7 12.6,7.7 L12.6,7.7 Z M2.1,10.5 C1.7136,10.5 1.4,10.1864 1.4,9.8 C1.4,9.4136 1.7136,9.1 2.1,9.1 C2.4864,9.1 2.8,9.4136 2.8,9.8 C2.8,10.1864 2.4864,10.5 2.1,10.5 L2.1,10.5 Z M4.9,10.5 C4.5136,10.5 4.2,10.1864 4.2,9.8 C4.2,9.4136 4.5136,9.1 4.9,9.1 C5.2864,9.1 5.6,9.4136 5.6,9.8 C5.6,10.1864 5.2864,10.5 4.9,10.5 Z"
                        id="形状"
                        fill="#1597A5"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {};
</script>

<style></style>
