<template>
    <div
        class="password-input"
        :class="{ 'validate-error': !validateResult, readonly, invisible }"
        @click.stop="clickHandle"
        @select="focus"
    >
        <div v-if="readonly" class="readonly-text">{{ value }}</div>
        <template v-else>
            <input
                :class="{ empty: !rememberValue }"
                v-if="remembered"
                :name="options.field"
                v-model.lazy="rememberValue"
                :type="type"
                :placeholder="placeholder"
                autocomplete="off"
                ref="password"
                @input="inputHandle($event)"
                @keydown.enter="enterKeyDownHandle"
                @keydown="getCapsLock($event)"
                @focus="focusHandle"
                @blur="removeCapsLock"
            />
            <input
                v-else
                :class="{ empty: !pwValue }"
                :name="options.field"
                v-model="pwValue"
                type="text"
                ref="password"
                :placeholder="placeholder"
                autocomplete="off"
                @input="pwinputHandle()"
                @keyup="keyup($event)"
                @keydown="keydown($event)"
                @focus="focusHandle"
                @blur="changeHandle"
            />
            <div
                v-show="showCapsLock && isShowCapsLock && isCapsLock"
                class="caps-lock-tips"
                :title="$i18n('img.caps.lock')"
            >
                <icon-caps-lock></icon-caps-lock>
            </div>
            <div v-if="value || rememberValue" class="pw-icon" @click="changeType">
                <showPasswordImg :width="'12px'" :height="'12px'" :color="'#999'" v-if="this.type === 'password'" />
                <hidePasswordImg v-else :width="'12px'" :height="'12px'" :color="'#999'" />
            </div>
        </template>
    </div>
</template>
<script>
import showPasswordImg from '../../img/showPassword';
import hidePasswordImg from '../../img/hidePassword';
import iconCapsLock from '../../../icon/vue/IconCapsLock';
import Gikam from 'gikam';

export default {
    name: 'passwordField',
    props: {
        options: {
            type: Object
        },
        propValue: {
            type: [String, Number]
        },
        placeholder: {
            type: String
        },
        rowIndex: Number,
        cellIndex: Number,
        showCapsLock: {
            type: Boolean,
            default: false
        }
    },

    components: {
        showPasswordImg,
        hidePasswordImg,
        iconCapsLock
    },

    data() {
        return {
            validateResult: true,
            readonly: Gikam.isNotEmpty(this.propReadonly) ? this.propReadonly : this.options.readonly,
            field: this.options.field,
            valueIsChange: false,
            value: this.propValue,
            rememberValue: this.propValue,
            invisible: this.propInvisible,
            type: 'password',
            hideValue: '',
            selection: {
                start: 0,
                end: 0
            },
            remembered: Gikam.isNotEmpty(this.options.remembered) ? this.options.remembered : false,
            beforeCopyLen: 0,
            afterCopyLen: 0,
            isShowCapsLock: false,
            isCapsLock: false
        };
    },

    computed: {
        pwValue: {
            get() {
                if (this.type === 'password') {
                    this.value && this.updatedCursor();
                    return this.hiddenPw(this.value);
                }
                return this.value;
            },

            set(val) {
                !this.value && (this.value = '');
                if (this.type === 'password') {
                    if (this.hideValue != null) {
                        this.value = this.hideValue;
                        this.hideValue = null;
                    }
                    let newStr = val.replace(/•/g, '');
                    let start = val.indexOf(newStr);
                    if (newStr.length != 0) {
                        this.value = this.value.slice(0, start) + newStr + this.value.slice(start);
                    }
                } else {
                    this.value = val;
                }
            }
        }
    },

    watch: {
        rememberValue(val,oldVal) {
            this.$emit('change', this.options.field, this.rememberValue, this.rowIndex, {oldValue: oldVal});
        },

        propValue(val) {
            this.rememberValue = val;
            this.value = val;
        }
    },

    methods: {
        removeCapsLock() {
            this.isShowCapsLock = false;
        },

        changeHandle() {
            this.isShowCapsLock = false;
            this.$emit('change', this.options.field, this.value, this.rowIndex);
        },

        focusHandle() {
            this.isShowCapsLock = true;
            this.$emit('focus', this.options.field, this.value);
        },

        hiddenPw(val) {
            val = val || '';
            let hideValue = '';
            if (val.length > 0) {
                for (let i = 0; i < val.length; i++) {
                    hideValue = hideValue.concat('•');
                }
            }
            return hideValue;
        },

        getCapsLock(e) {
            if (e.keyCode === 20) {
                this.rememberValue = e.target.value;
            }
            if (e.getModifierState) {
                this.isCapsLock = e.getModifierState('CapsLock');
            }
        },

        keydown(e) {
            if (e.getModifierState) {
                this.isCapsLock = e.getModifierState('CapsLock');
            }
            let obj = e.currentTarget;
            let key = e.keyCode;
            if (this.type == 'password') {
                if (e.ctrlKey) {
                    if (key == 86) {
                        this.copyKeydown(obj);
                        return;
                    }
                    return;
                }
                if (key == 13) {
                    this.$emit('enterKeyDown', this.value, this.options.field);
                    Gikam.trigger(this.options, 'onEnterKeyDown', null, this.value);
                } else if (key == 8) {
                    // 点击Backspace时
                    this.backspaceKeydown(obj);
                } else if (key == 46) {
                    // 点击Delete时
                    this.deleteKeydown(obj);
                } else {
                    if (obj.selectionStart == obj.selectionEnd) {
                        // 获取光标位置
                        this.selection.start = obj.selectionStart + 1;
                        this.selection.end = obj.selectionEnd + 1;
                    } else {
                        // 选取多位输入新值
                        this.selection.start = obj.selectionStart + 1;
                        this.selection.end = this.selection.start;
                        this.hideValue =
                            this.value.substring(0, obj.selectionStart) +
                            this.value.substring(obj.selectionEnd, this.value.length);
                    }
                }
            }
        },

        // 密码状态时设置光标位置
        updatedCursor() {
            this.$nextTick(() => {
                this.$refs.password.selectionStart = this.selection.start;
                this.$refs.password.selectionEnd = this.selection.end;
            });
        },

        backspaceKeydown(obj) {
            // 获取光标位置
            if (this.value.length == obj.selectionStart) {
                // 光标在末尾时
                this.selection.start = obj.selectionStart;
                this.selection.end = obj.selectionEnd;
            } else {
                this.selection.start = obj.selectionStart - 1;
                this.selection.end = obj.selectionEnd - 1;
            }
            if (obj.selectionStart == obj.selectionEnd) {
                this.hideValue =
                    this.value.substring(0, obj.selectionStart - 1) +
                    this.value.substring(obj.selectionEnd, this.value.length);
            } else {
                // 选中多位删除时修改光标位置
                this.selection.start += 1;
                this.selection.end = this.selection.start;
                this.hideValue =
                    this.value.substring(0, obj.selectionStart) +
                    this.value.substring(obj.selectionEnd, this.value.length);
            }
        },

        copyKeydown(obj) {
            if (this.value) {
                // 密码为空时
                this.beforeCopyLen = this.value.length - Math.abs(obj.selectionStart - obj.selectionEnd);
                this.selection.start = obj.selectionStart;
                this.selection.end = obj.selectionEnd;
                this.hideValue =
                    this.value.substring(0, obj.selectionStart) +
                    this.value.substring(obj.selectionEnd, this.value.length);
            } else {
                this.beforeCopyLen = 0;
                this.selection.start = obj.selectionStart;
                this.selection.end = obj.selectionEnd;
                this.hideValue = '';
            }
        },

        deleteKeydown(obj) {
            // 获取光标位置
            if (this.value.length == obj.selectionStart) {
                // 光标在末尾时
                return;
            } else {
                this.selection.start = obj.selectionStart;
                this.selection.end = obj.selectionEnd;
            }
            if (obj.selectionStart == obj.selectionEnd) {
                this.hideValue =
                    this.value.substring(0, obj.selectionStart) +
                    this.value.substring(obj.selectionEnd + 1, this.value.length);
            } else {
                // 选中多位删除时修改光标位置
                this.selection.end = this.selection.start;
                this.hideValue =
                    this.value.substring(0, obj.selectionStart) +
                    this.value.substring(obj.selectionEnd, this.value.length);
            }
        },

        changeType() {
            this.type = this.type == 'password' ? 'text' : 'password';
        },

        inputHandle(event) {
            this.$emit('input', event.target.value);
        },

        enterKeyDownHandle(event) {
            const value = event.target.value;
            this.$emit('enterKeyDown', value, this.options.field);
            Gikam.trigger(this.options, 'onEnterKeyDown', value);
        },

        pwinputHandle() {
            this.$emit('input', this.value);
        },

        keyup(e) {
            let key = e.keyCode;
            if (e.ctrlKey && key == 86) {
                this.afterCopyLen = this.value.length;
                const len = Math.abs(this.afterCopyLen - this.beforeCopyLen);
                this.selection.start = this.selection.start + len;
                this.selection.end = this.selection.start;
                this.updatedCursor();
            }
        },

        dumpActiveCell() {
            if (!this.invisible) {
                this.$refs.password && this.$refs.password.blur();
            }
        },

        activeCell() {
            if (!this.invisible) {
                this.focus();
            } else {
                this.$emit('click', this);
            }
        },

        focus() {
            this.$refs.password && this.$refs.password.focus();
            this.$emit('saveCoordinate');
        },

        //点击时保存当前单元格的坐标
        clickHandle() {
            if (!this.invisible) {
                this.$emit('saveCoordinate');
            } else {
                this.$emit('click', this);
            }
        }
    },

    mounted() {
        setTimeout(() => {
            this.$refs.password?.blur();
        });
    }
};
</script>

<style scoped lang="scss">
.password-input {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: stretch;
    position: relative;

    &.readonly {
        color: #666;
        font-family: 'Microsoft YaHei', serif;
        font-size: 12px;
        align-items: center;
    }

    > input,
    > .readonly-text {
        width: 100%;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
        padding-left: 8px;
        color: rgba(0, 0, 0, 0.65);
        font-family: 'Microsoft YaHei', serif;
        font-size: 12px;
        height: 100%;
    }

    > .readonly-text {
        background-color: #f4f4f4;
        color: rgba(0, 0, 0, 0.45);
        display: flex;
        align-items: center;
    }

    &.validate-error {
        > input {
            border-color: #ff6e6e;
        }
    }

    > input {
        &.empty {
            color: rgba(0, 0, 0, 0.15);
            font-size: 14px;
        }

        &:focus {
            border: 1px solid rgba(0, 122, 255, 0.5);
            color: rgba(0, 0, 0, 0.65);
        }
    }

    > .caps-lock-tips {
        position: absolute;
        top: 0;
        right: 30px;
        margin: auto;
        width: 12px;
        height: 12px;
        color: #999;
        cursor: default;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    > .pw-icon {
        position: absolute;
        top: 0;
        right: 0;
        margin: auto;
        width: 30px;
        cursor: pointer;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

input::-webkit-input-placeholder,
input::-moz-input-placeholder,
input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.15);
    font-size: 14px;
}
</style>
