<template>
    <tr @click="clickHandle" :style="rowStyle" :class="classList" :data-index="rowIndex">
        <GridCellGroup :rowData="rowData" />
    </tr>
</template>

<script>
import { mapState } from 'vuex';
import { GridUtils } from '../../../js/grid-utils';
import GridCellGroup from './grid-cell-group.vue';

export default {
    components: {
        GridCellGroup
    },

    props: {
        rowData: Object,
        rowIndex: Number,
        gridOptions: Object
    },

    computed: {
        ...mapState(['grid']),

        rowStyle() {
            const gridOptions = this.grid.options;
            const style = { ...gridOptions.rowStyleFormatter?.call(this.grid, this.rowData) };
            return style;
        },

        classList() {
            const classList = [];
            if (GridUtils.getRowVueState(this.rowData, 'active')) {
                classList.push('active');
            }
            if (GridUtils.getRowVueState(this.rowData, 'checked')) {
                classList.push('checked');
            }
            if (GridUtils.getRowVueState(this.rowData, 'visible') === false) {
                classList.push('grid-body-row-hidden');
            }
            return classList;
        }
    },

    created() {
        const key = GridUtils.getRowVueState(this.rowData, 'key');
        this.grid.$rowManager[key] = this;
    },

    methods: {
        async clickHandle() {
            GridUtils.activeRowGroup(this.rowData, this.grid);
        }
    }
};
</script>

<style lang="scss" scoped>
.grid-body-row-hidden {
    display: none;
}
</style>
