import Base from '../../.././base.js';
import Gikam from '../../../../core/gikam-core';
import Vue from 'vue';

let defaultOptions = {
    id: void 0,
    fileIds: [],
    modal: void 0,
    imageRotate: 0
};

export default class BatchPreview extends Base {
    constructor(options) {
        super(options);
        this.initialize(options, defaultOptions).init();
    }

    init() {
        this.createModel();
    }

    createModel() {
        const _this = this;
        this.model = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            components: {
                batchPreview: () => import('../batchPreview.vue')
            },
            render() {
                return <batch-preview options={_this.options}></batch-preview>;
            }
        });
    }
}
