import Base from '../../../base';
import Gikam from 'gikam';
import Vue from 'vue';
import { getStore } from './store/getStore';
import { addNodeAnimation } from './animation/nodeAnimation';
import { Node } from 'topology-core/models/node';

let defaultOptions = {
    // 流程绘制初始数据
    data: null,
    // 是否只读
    readonly: false,
    // 配置图形
    configShape: [],
    // 连线，默认贝塞尔曲线
    lineName: 'polyline',
    // 连线起点，无默认图形
    fromArrowType: 'empty',
    // 连线起点，无默认图形
    toArrowType: 'empty',
    // 全屏按钮
    fullScreen: false,
    // 配置自定义图形
    customTools: [],
    // 给已有图形添加自定义属性
    commonTools: [],
    //画布背景色 六位数颜色
    bkColor: '',
    // 渲染完毕事件
    onRendered: () => {},
    // 节点渲染事件
    onNodeDbClick: () => {},
    // 鼠标进入节点事件
    onMoveInNode: () => {},
    // 鼠标进入连线事件
    onMoveInLine: () => {}
};

export default class TopologyDiagram extends Base {
    constructor(options) {
        super(options);
        this.initialize(options, defaultOptions).init();
        this.canvas = null;
    }

    createViewModel() {
        const _this = this;
        this.topology = new Vue({
            el: Gikam.createDom('div', this.options.renderTo),
            store: getStore(),
            render() {
                return (
                    <topology-diagram
                        ref="topologyDiagram"
                        options={this.options}
                        onMounted={this.mountedHandle}
                        onNodeDbClick={this.nodeDbClickHandle}
                        onMoveInNode={this.MoveInNodeHandle}
                        onMoveInLine={this.MoveInLineHandle}
                    ></topology-diagram>
                );
            },
            components: {
                topologyDiagram: () => import('../vue/topologyDiagram.vue')
            },
            data() {
                return {
                    options: _this.options
                };
            },
            watch: {
                'options.readonly'(val) {
                    this.options.readonly = val;
                }
            },
            methods: {
                mountedHandle(canvas) {
                    _this.bindInstance(_this.topology.$el);
                    Gikam.trigger(_this.options, 'onRendered', _this);
                    _this.canvas = canvas;
                    _this.setReadonly(_this.options.readonly);
                    _this.setBkColor(_this.options.bkColor);
                },

                nodeDbClickHandle(data) {
                    Gikam.trigger(_this.options, 'onNodeDbClick', _this, data);
                },

                MoveInNodeHandle(data) {
                    Gikam.trigger(_this.options, 'onMoveInNode', _this, data);
                },

                MoveInLineHandle(data) {
                    Gikam.trigger(_this.options, 'onMoveInLine', _this, data);
                }
            }
        });
    }

    /**
     * @Public
     * @param {json}
     * @returns
     */

    setData(data) {
        if (this.options.readonly) {
            data.locked = 1;
        }
        this.topology.$refs.topologyDiagram.initProcess(data);
    }

    /**
     * @Public
     * @param {Array}
     */
    setShapes(list) {
        this.topology.$refs.topologyDiagram.configShapeHandler(list);
    }

    /**
     * @Public
     * @param {Boolean}
     */
    setReadonly(readonly) {
        if (readonly === true) {
            this.canvas.lock(true);
            this.options.readonly = true;
            this.options.data = JSON.stringify(this.topology.$refs.topologyDiagram.canvas.data);
        } else {
            this.canvas.lock(false);
            this.options.readonly = false;
        }
    }

    /**画布背景色
     * @Public
     * @param {String}
     */
    setBkColor(color) {
        if (color) {
            const data = this.topology.$refs.topologyDiagram.canvas.data;
            data.bkColor = color;
            this.options.data = JSON.stringify(data);
        }
    }

    /**
     * @description 清空所有节点动画
     * @public
     * @memberof TopologyDiagram
     */
    cleanAllAnimation() {
        this.canvas.data.pens.forEach(node => {
            node.animateFrames = [];
            node.animateDuration = 0;
        });
        this.canvas.animate();
    }

    /**
     * @description 给节点添加动画
     * @param {*} callback
     * @param {*} animationType
     * @memberof TopologyDiagram
     */
    setNodeAnimation(callback, animationType) {
        this.canvas.data.pens.forEach(node => {
            if (!(node instanceof Node)) {
                return;
            }
            const result = callback.call(this.canvas, node);
            if (result === true) {
                addNodeAnimation(node, animationType);
            } else {
                node.fillStyle = '';
                node.strokeStyle = '';
                node.animateFrames = [];
                node.animateDuration = 0;
            }
        });
        this.canvas.animate();
    }

    /**
     * @description 设置节点信息
     *              strokeStyle：线条颜色, text：节点名称，fillStyle:背景色
     * @param {*} callback
     * @memberof TopologyDiagram
     */
    setNodeInfo(callback) {
        this.canvas.data.pens.forEach(node => {
            if (!(node instanceof Node)) {
                return;
            }
            callback.call(this.canvas, node);
        });
        this.canvas.render();
    }

    init() {
        this.createViewModel();
    }

    addNodeAnimation(node, animationType) {
        addNodeAnimation(node, animationType);
    }
}
