export function getColumnGroupRelationship(groupTree) {
    const map = {};
    baseGetRelationship(groupTree, map, []);
    return map;
}

const baseGetRelationship = function baseGetRelationship(list, map, shipArr) {
    list.forEach(item => {
        map[item.field] = shipArr;
        if (item.children) {
            const _shipArr = shipArr.slice();
            _shipArr.push(item.field);
            baseGetRelationship(item.children, map, _shipArr);
        }
    });
};

const getData = function(row, key) {
    if (key.indexOf('ext$.') === 0) {
        return row.ext$[key.replace('ext$.', '')];
    } else {
        return row[key];
    }
};

export function getColumnsGroupMap(columns, data, relationship) {
    if (data.length < 2) {
        return {};
    }
    const map = {};
    const fieldArr = Object.keys(relationship);
    columns.forEach((col, colIndex) => {
        if (!col.checkbox && !col.index && !col.radio && fieldArr.includes(col.field)) {
            const colMap = baseColumnsGroup(col.field, data, relationship);
            colMap.forEach((colMap, rowIndex) => {
                colMap && (map[colIndex + '-' + rowIndex] = colMap);
            });
        }
    });

    return map;
}

function baseColumnsGroup(field, rows, relationship) {
    const cascadeField = relationship[field] || [];

    const map = [];
    let baseValue = null;
    let pointer = {};
    let len = 0;

    rows.forEach((row, index) => {
        const _row =
            getData(row, field) +
            cascadeField.reduce((total, key) => {
                return total + getData(row, key);
            }, '');

        if (_row === baseValue) {
            len += 1;
            map[index] = {
                hidden: true
            };
        } else {
            //给上一个赋长度
            pointer.len = len;
            //从新设置变量
            baseValue = _row;
            pointer = map[index] = {
                hidden: false,
                len: 1
            };
            len = 1;
        }
    });
    pointer.len = len;
    baseValue = null;
    pointer = null;
    len = null;

    return map;
}
