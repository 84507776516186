import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
import Gikam from 'gikam';
import BaseField from '../../baseField/baseField.vue';
export default {
  name: 'fileField',
  props: {
    options: Object,
    propReadonly: {
      type: Boolean,
      default: void 0
    },
    propInvisible: {
      type: Boolean,
      default: false
    }
  },
  extends: BaseField,
  mounted: function mounted() {
    Gikam.setInstance(this.$el, this);
  },
  data: function data() {
    return {
      field: this.options.field,
      text: '',
      value: '',
      validateResult: true,
      fileItem: [],
      fileId: null,
      accept: []
    };
  },
  methods: {
    selectUploadFile: function selectUploadFile() {
      var fileInput = this.$refs.hideInput;
      this.options.accept && fileInput.setAttribute('accept', this.getFileType(this.options.accept));
      fileInput.addEventListener('change', this.changeFileFn);
      fileInput.click();
    },
    changeFileFn: function changeFileFn(e) {
      this.fileItem = e.target.files;
      this.text = e.target.files[0].name;
      this.value = e.target.files[0];
      this.$emit('change', this.field, this.text, this.value);
    },
    deleteFile: function deleteFile() {
      var fileInput = this.$refs.hideInput;
      fileInput.removeEventListener('change', this.changeFileFn);
      this.value = '';
      this.text = '';
      this.fileItem = [];

      if (this.$refs.hideInput.innerHTML) {
        this.$refs.hideInput.innerHTML = '';
      }

      this.$refs.hideInput.value = '';

      if (this.options.onDelete) {
        this.options.onDelete(this.fileId);
      } else {
        this.fileId && Gikam.del("".concat(Gikam.IFM_CONTEXT, "/core/module/item/files"), Gikam.getJsonWrapper({}, ['', [{
          id: this.fileId
        }]]));
      }
    },
    setText: function setText(text) {
      this.text = text;
    },
    setFileId: function setFileId(id) {
      this.fileId = id;
    },
    getFileType: function getFileType(type) {
      var mimeMapper = {
        image: 'image/*',
        audio: 'audio/*,video/*',
        pdf: 'application/pdf',
        xls: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        zip: 'aplication/zip',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        txt: 'text/plain'
      };

      if (Array.isArray(type)) {
        return type.reduce(function (total, item) {
          return total + mimeMapper[item] + ',';
        }, '');
      } else {
        return mimeMapper[type];
      }
    }
  }
};