import Vue from 'vue';

export default {
    install() {
        const files = require.context('@/gikam/js/components/icon/vue', true, /\.vue$/, 'lazy');
        files.keys().map(key => {
            const name = key.slice(key.lastIndexOf('/') + 1).replace('.vue', '');
            Vue.component(name, () => files(key));
        });
    }
};
