import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

function getState(formInstance) {
    const pageEditing = formInstance.$window?.pageEditing ?? false;
    return {
        pageEditing,
        $window: formInstance.$window,
        form: formInstance
    };
}

function getMutations() {
    return {
        setPageEditing(state, editing) {
            state.pageEditing = editing;
        }
    };
}

export function getStore(formInstance) {
    return new Vuex.Store({
        state: getState(formInstance),
        mutations: getMutations(formInstance)
    });
}
