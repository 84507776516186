<template>
    <div :class="rootClass">
        <div class="cell-icon" v-if="options.iconFormatter" :class="classList" @click="clickHandle"></div>
        <a href="javascript:;" v-html="value === 0 ? 0 : $xss(value)" @click="clickHandle"> </a>
    </div>
</template>

<script>
import Gikam from 'gikam';
import { mapState } from 'vuex';
import { GridUtils } from '../../../js/grid-utils';

export default {
    props: {
        options: Object,
        row: Object,
        rowIndex: Number
    },

    computed: {
        ...mapState(['$window', 'grid', 'textLineFeed']),

        classList() {
            return this.options.iconFormatter(this.row);
        },

        value() {
            const value = Gikam.getFieldValue(this.row, this.options.field);
            const formatterValue = this.options.formatter?.call(
                this.grid,
                this.rowIndex,
                value,
                Gikam.deepExtend(this.row)
            );
            return formatterValue ?? value ?? '-';
        },

        rootClass() {
            const classList = ['grid-link'];
            this.textLineFeed && classList.push('text-line-feed');
            return classList;
        }
    },

    methods: {
        clickHandle() {
            const rowIndex = GridUtils.getRowVueState(this.row, 'rowIndex');
            if (this.$window) {
                this.$window.model.storage = {
                    grid: this.grid,
                    index: rowIndex
                };
            }
            this.$emit('cellClick');
        }
    }
};
</script>

<style scoped lang="scss">
.grid-link {
    height: 100%;
    width: 100%;

    > .cell-icon {
        width: 25px;
        height: 25px;
    }

    > a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.text-line-feed {
        > a {
            white-space: normal;
        }
    }
}
</style>
