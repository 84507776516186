<template>
    <div
        component="layout"
        class="layout"
        :id="options.id"
        :class="{ 'no-user-select': resizing }"
        :style="layoutStyle"
    >
        <div class="north region" :style="{ height: northHeight }" :class="{ 'region-north': options.north.title }">
            <div
                v-if="shrinkType(options.north)"
                class="expand-collapse"
                :class="{
                    horizontal: northHeight === '30px' && initWH.northHeight,
                    'only-shrink-icon': !options.north.title && options.north.shrinkTitle.length > 0
                }"
            >
                <span class="expand-round">
                    <template
                        v-if="northHeight === '30px' && initWH.northHeight && options.north.shrinkTitle.length > 0"
                    >
                        <span :title="item" v-for="item in options.north.shrinkTitle" :key="item">{{ item }}</span>
                    </template>
                    <span v-else :title="options.north.title">{{ propI18N(options.north.title) }}</span>
                </span>
                <shrink-img
                    class="expand-icon"
                    v-bind="{ color: '#999', width: '16px', height: '16px' }"
                    @click.native="layoutShrink('northHeight')"
                />
            </div>
            <div
                class="body"
                ref="north"
                :class="{ shrink: options.north.title }"
                :style="{ padding: getPadding('north') }"
                :comp-id="options.id"
            ></div>
            <i
                class="north-center split"
                :class="{ 'drag-drop': resizing }"
                v-if="getCanResize('north')"
                @mousedown="dragDown($event, 'north')"
            ></i>
            <span
                class="north-center mobile-split"
                v-if="getCanResize('north')"
                @touchstart="dragDown($event, 'north')"
            >
            </span>
            <span class="north-center mobile-mode-split" @touchstart="mobileDragDown($event, 'northHeight')">
                <icon-drag-up-down />
            </span>
        </div>
        <div class="center-container">
            <div
                class="west region"
                :style="{ width: westWidth, height: westHeight }"
                :class="{ 'region-west': options.west.title }"
            >
                <div
                    v-if="shrinkType(options.west)"
                    class="expand-collapse"
                    :class="{ vertical: westWidth === '30px' && initWH.westWidth }"
                >
                    <div class="expand-round">
                        <span :title="options.west.title">{{ propI18N(options.west.title) }}</span>
                        <shrink-img
                            class="expand-icon expend"
                            v-bind="{ color: '#999', width: '16px', height: '16px' }"
                            @click.native="layoutShrink('westWidth')"
                        />
                    </div>
                </div>
                <div
                    class="body"
                    ref="west"
                    :comp-id="options.id"
                    :class="{ shrink: options.west.title }"
                    :style="{ padding: getPadding('west') }"
                >
                    <slot name="west"></slot>
                </div>
                <i
                    class="west-center split"
                    v-if="getCanResize('west')"
                    @mousedown="dragDown($event, 'west')"
                    :class="{ 'drag-drop': resizing }"
                ></i>
                <span
                    class="west-center mobile-split"
                    v-if="getCanResize('west')"
                    @touchstart="dragDown($event, 'west')"
                >
                    <icon-drag-left-right />
                </span>
                <span class="west-center mobile-mode-split" @touchstart="mobileDragDown($event, 'westHeight')">
                    <icon-drag-up-down />
                </span>
            </div>
            <div
                class="center region"
                :class="{
                    'region-center': options.east.title,
                    'in-panel-remove-left-border': options.west.width === '0px',
                    'in-panel-remove-right-border': options.east.width === '0px'
                }"
                :style="{ height: centerHeight }"
            >
                <div v-if="options.center.title" class="expand-collapse">
                    <div class="expand-round">
                        <span :title="options.center.title">{{ propI18N(this.options.center.title) }}</span>
                    </div>
                </div>
                <div
                    class="body"
                    ref="center"
                    :comp-id="options.id"
                    :class="{ shrink: options.center.title }"
                    :style="{ padding: getPadding('center') }"
                >
                    <slot name="center"></slot>
                </div>
                <i class="mask" :class="{ 'drag-drop': resizing }"></i>
                <span class="center mobile-mode-split" @touchstart="mobileDragDown($event, 'centerHeight')">
                    <icon-drag-up-down />
                </span>
            </div>
            <div
                class="east region"
                :style="{ width: eastWidth, height: eastHeight }"
                :class="{ 'region-east': options.east.title }"
            >
                <div
                    v-if="shrinkType(options.east)"
                    class="expand-collapse"
                    :class="{ vertical: eastWidth === '30px' && initWH.eastWidth }"
                >
                    <div class="expand-round">
                        <span :title="options.east.title">{{ propI18N(options.east.title) }}</span>
                        <shrink-img
                            class="expand-icon expend"
                            v-bind="{ color: '#999', width: '16px', height: '16px' }"
                            @click.native="layoutShrink('eastWidth')"
                        />
                    </div>
                </div>
                <div
                    class="body"
                    ref="east"
                    :comp-id="options.id"
                    :class="{ shrink: options.east.title }"
                    :style="{ padding: getPadding('east') }"
                >
                    <slot name="east"></slot>
                </div>
                <i
                    class="center-east split"
                    v-if="getCanResize('east')"
                    @mousedown="dragDown($event, 'east')"
                    :class="{ 'drag-drop': resizing }"
                ></i>
                <span
                    class="center-east mobile-split"
                    v-if="getCanResize('east')"
                    @touchstart="dragDown($event, 'east')"
                >
                    <icon-drag-left-right />
                </span>
                <span class="center-east mobile-mode-split" @touchstart="mobileDragDown($event, 'eastHeight')">
                    <icon-drag-up-down />
                </span>
            </div>
        </div>
        <div class="south region" :style="{ height: southHeight }" :class="{ 'region-south': options.south.title }">
            <div
                v-if="shrinkType(options.south)"
                class="expand-collapse"
                :class="{
                    horizontal: southHeight === '30px' && initWH.southHeight,
                    'only-shrink-icon': !options.south.title && options.south.shrinkTitle.length > 0
                }"
            >
                <span class="expand-round">
                    <template
                        v-if="southHeight === '30px' && initWH.southHeight && options.south.shrinkTitle.length > 0"
                    >
                        <span :title="item" v-for="item in options.south.shrinkTitle" :key="item">{{ item }}</span>
                    </template>
                    <span v-else :title="options.south.title">{{ propI18N(options.south.title) }}</span>
                </span>
                <shrink-img
                    class="expand-icon"
                    v-bind="{ color: '#999', width: '16px', height: '16px' }"
                    @click.native="layoutShrink('southHeight')"
                />
            </div>
            <div
                class="body"
                ref="south"
                :comp-id="options.id"
                :class="{ shrink: options.south.title }"
                :style="{ padding: getPadding('south') }"
            >
                <slot name="south"></slot>
            </div>
            <i
                class="center-south split"
                :class="{ 'drag-drop': resizing }"
                v-if="getCanResize('south')"
                @mousedown="dragDown($event, 'south')"
            ></i>
            <span
                class="center-south mobile-split"
                v-if="getCanResize('south')"
                @touchstart="dragDown($event, 'south')"
            >
                <icon-drag-up-down />
            </span>
        </div>
    </div>
</template>

<script>
import Gikam from 'gikam';
import IconDragLeftRight from '@/gikam/js/components/icon/vue/IconDragLeftRight';
import IconDragUpDown from '@/gikam/js/components/icon/vue/IconDragUpDown';

export default {
    name: 'layout',

    components: {
        IconDragLeftRight,
        IconDragUpDown
    },

    props: {
        options: Object
    },

    data() {
        return {
            northHeight: this.options.north.height,
            southHeight: this.options.south.height,
            westHeight: null,
            eastHeight: null,
            centerHeight: null,
            westWidth: this.options.west.width,
            eastWidth: this.options.east.width,
            initX: null,
            initY: null,
            resizing: false,
            direction: null,
            initWH: {} // 初始的宽高，里面存南北的高，和东西的宽
        };
    },

    computed: {
        layoutStyle() {
            const style = { height: `${this.options.height}px` };
            return style;
        }
    },

    watch: {
        'options.north.height'(value) {
            this.northHeight = value;
        },
        'options.south.height'(value) {
            this.southHeight = value;
        },
        'options.west.width'(value) {
            this.westWidth = value;
        },
        'options.east.width'(value) {
            this.eastWidth = value;
        },
        'options.northHeight'(value) {
            this.layoutCollapseOrExpand(value, 'northHeight');
        },
        'options.southHeight'(value) {
            this.layoutCollapseOrExpand(value, 'southHeight');
        },
        'options.westWidth'(value) {
            this.layoutCollapseOrExpand(value, 'westWidth');
        },
        'options.eastWidth'(value) {
            this.layoutCollapseOrExpand(value, 'eastWidth');
        }
    },

    methods: {
        // 是否可以收缩
        shrinkType(option) {
            return option && (option.title || option.shrinkTitle.length > 0);
        },

        dragDown(e, direction) {
            this.initX = e.pageX;
            this.initY = e.pageY;
            this.resizing = true;
            this.direction = direction;
            if (Gikam.isMobile()) {
                document.addEventListener('touchmove', this.dragMove, false);
                document.addEventListener('touchend', this.dragUp, false);
            } else {
                document.addEventListener('mousemove', this.dragMove, false);
                document.addEventListener('mouseup', this.dragUp, false);
            }
        },

        mobileDragDown(e, direction) {
            this[direction] = e.target.parentElement.parentElement.offsetHeight;
            this.initY = e.touches[0].pageY;
            this.direction = direction;
            document.addEventListener('touchmove', this.mobileDragMove, false);
            document.addEventListener('touchend', this.mobileDragUp, false);
        },

        // eslint-disable-next-line complexity
        dragMove(e) {
            if (!this.resizing) return;
            let northHeight = this.$refs.north.offsetHeight;
            let southHeight = this.$refs.south.offsetHeight;
            let centerHeight = this.$refs.center.offsetHeight;
            let centerWidth = this.$refs.center.offsetWidth;
            let westWidth = this.$refs.west.offsetWidth;
            let eastWidth = this.$refs.east.offsetWidth;
            if (this.direction === 'north' || this.direction === 'south') {
                const pageY = e.pageY || e.touches[0].pageY;
                let distance = pageY - this.initY;
                if (Math.abs(distance) < 3) return;
                if (this.direction === 'north') {
                    if (distance > 0 && centerHeight < 50) return;
                    if (distance < 0 && northHeight < 50) return;
                    this.northHeight = northHeight + distance + 'px';
                    northHeight + distance < 50 && (this.northHeight = '50px');
                }
                if (this.direction === 'south') {
                    if (distance < 0 && centerHeight < 50) return;
                    if (distance > 0 && southHeight < 50) return;
                    this.southHeight = southHeight - distance + 'px';
                }
                this.initY = pageY;
            } else if (this.direction === 'west' || this.direction === 'east') {
                const pageX = e.pageX || e.touches[0].pageX;
                let distance = pageX - this.initX;
                if (Math.abs(distance) < 3) return;
                if (this.direction === 'west') {
                    if (distance < 0 && westWidth < 50) return;
                    if (distance > 0 && centerWidth < 50) return;
                    this.westWidth = westWidth + distance + 'px';
                }
                if (this.direction === 'east') {
                    if (distance < 0 && centerWidth < 50) return;
                    if (distance > 0 && eastWidth < 50) return;
                    this.eastWidth = eastWidth - distance + 'px';
                }
                this.initX = pageX;
            }
        },

        mobileDragMove(e) {
            const distance = e.touches[0].pageY;
            if (Math.abs(distance) < 3) {
                return;
            }
            this[this.direction] += distance;
            this[this.direction] = this[this.direction] + 'px';
        },

        dragUp() {
            this.$emit('resize', this.direction);
            this.initX = null;
            this.initY = null;
            this.resizing = false;
            this.direction = null;
            document.removeEventListener('mousemove', this.dragMove, false);
            document.removeEventListener('mouseup', this.dragUp, false);
            document.removeEventListener('touchmove', this.dragMove, false);
            document.removeEventListener('touchend', this.dragUp, false);
        },

        mobileDragUp() {
            this.initY = null;
            this.direction = null;
            document.removeEventListener('touchmove', this.mobileDragMove, false);
            document.removeEventListener('touchend', this.mobileDragUp, false);
        },

        getPadding(direction) {
            return this.options[direction].padding || '';
        },

        getCanResize(direction) {
            if (direction === 'south' || direction === 'north') {
                return (
                    parseInt(this.options[direction].height) > 0 &&
                    (this.options[direction].resize || this.options.resize)
                );
            } else if (direction === 'east' || direction === 'west') {
                return (
                    parseInt(this.options[direction].width) > 0 &&
                    (this.options[direction].resize || this.options.resize)
                );
            }
        },

        // layout的展开和收缩
        layoutShrink(type) {
            if (this[type] === '30px' && this.initWH[type]) {
                this[type] = this.initWH[type];
                this.initWH[type] = void 0;
            } else {
                this.initWH[type] = this[type];
                this[type] = '30px';
            }

            this.$emit('resize', type, this[type]);
        },

        // layout是否默认收缩

        layoutDefaultHiddden() {
            const position = ['north', 'south', 'west', 'east'];
            const typeObj = { north: 'northHeight', south: 'southHeight', west: 'westWidth', east: 'eastWidth' };
            position.forEach(item => {
                if (this.options[item] && this.options[item].title && this.options[item].collapse) {
                    this.layoutShrink(typeObj[item]);
                }
            });
        },

        propI18N(text) {
            return Gikam.propI18N(text);
        },

        layoutCollapseOrExpand(value, position) {
            if (value === '') return;
            if (value === 'collapseRegin') {
                if (!(this[position] === '30px' && this.initWH[position])) {
                    this.initWH[position] = this[position];
                    this[position] = '30px';
                    this.$emit('resize', position, this[position]);
                }
            } else if (value === 'expandRegion') {
                if (this[position] === '30px' && this.initWH[position]) {
                    this[position] = this.initWH[position];
                    this.initWH[position] = void 0;
                    this.$emit('resize', position, this[position]);
                }
            }
            this.options[position] = '';
        }
    },

    mounted() {
        this.layoutDefaultHiddden();
    }
};
</script>

<style scoped>
.layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.layout .mobile-split,
.layout .mobile-mode-split {
    display: none;
    font-size: 14px;
    color: #007aff;
}

.layout[edit='layoutEditing'] {
    overflow: hidden;
}

.layout[edit='layoutEditing'] .mobile-mode-split {
    top: auto;
    bottom: -12px;
    right: auto;
    left: auto;
}

.layout[edit='layoutEditing'] .mobile-mode-split {
    display: none;
    position: absolute;
    left: 50%;
    bottom: -12px;
    margin-left: -16px;
    width: 32px;
    height: 24px;
    border-radius: 2px;
    z-index: 5;
    text-align: center;
    line-height: 28px;
    background-color: #fff;
    box-shadow: 0 0 2px #007aff;
}

.layout[edit='layoutEditing'] > .north > .mobile-split,
.layout[edit='layoutEditing'] > .south > .mobile-split {
    display: block;
    position: absolute;
    left: 50%;
    bottom: -12px;
    margin-left: -16px;
    width: 32px;
    height: 24px;
    border-radius: 2px;
    z-index: 2;
    text-align: center;
    line-height: 28px;
    background-color: #fff;
    box-shadow: 0 0 2px #007aff;
}

.layout[edit='layoutEditing'] > .south > .mobile-split {
    top: -12px;
    bottom: auto;
}

.layout[edit='layoutEditing'] > .center-container > .west > .mobile-split,
.layout[edit='layoutEditing'] > .center-container > .east > .mobile-split {
    display: block;
    position: absolute;
    top: 50%;
    right: -12px;
    margin-top: -16px;
    height: 32px;
    width: 24px;
    border-radius: 2px;
    z-index: 2;
    text-align: center;
    line-height: 34px;
    background-color: #fff;
    box-shadow: 0 0 2px #007aff;
}

.layout[edit='layoutEditing'] > .center-container > .east > .mobile-split {
    left: -12px;
    right: auto;
}

.layout[edit='layoutEditing'] .split {
    display: none;
}

.layout[edit='layoutEditing'] .body::after {
    position: absolute;
    content: '';
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    overflow: hidden;
    z-index: 1;
    border: 2px solid #007aff;
}

.center-container {
    display: flex;
    flex: 1;
    /* overflow: hidden; */
}

.layout > .center-container > .center {
    flex: 1;
}

.layout > .center-container > .center > .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
}

.layout > .center-container > .center > .mask.drag-drop {
    display: block;
}

.layout > .center-container > .center,
.layout > .center-container > .north,
.layout > .center-container > .south,
.layout > .center-container > .west,
.layout > .center-container > .east {
    position: relative;
}

.layout > .center-container > .region > .split.center-east,
.layout > .center-container > .region > .split.west-center {
    position: absolute;
    width: 8px;
    height: 100%;
    background-color: transparent;
    cursor: col-resize;
    z-index: 7;
}

.region > .split.west-center {
    right: -8px;
}

.region > .split.center-east {
    left: -8px;
}

.layout > .region > .split.north-center,
.layout > .region > .split.center-south {
    position: absolute;
    height: 8px;
    width: 100%;
    background-color: transparent;
    cursor: row-resize;
    z-index: 7;
}

.layout > .region > .split.north-center {
    bottom: -8px;
}

.layout > .region > .split.center-south {
    top: -8px;
}

.layout .region {
    position: relative;
}

.body {
    height: 100%;
    overflow: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.body.shrink {
    padding-top: 30px;
}

.no-user-select {
    user-select: none;
}

.west > .body >>> .tab {
    padding-right: 0;
}

.layout .region {
    transition: width 0.3s, height 0.3s;
    position: relative;
}

.layout .region.region-north {
    border: 1px solid #eee;
    border-bottom: none;
}

.layout .region.region-south {
    border: 1px solid #eee;
    border-top: none;
}

.layout .region.region-west {
    border: 1px solid #eee;
    border-right: none;
}

.layout .region.region-east {
    border: 1px solid #eee;
    border-left: none;
}

.layout .region.region-center {
    border: 1px solid #eee;
}

.layout.no-user-select .region {
    transition: none;
}

.layout.no-user-select .region .panel-body {
    overflow: hidden;
}
</style>

<style>
.layout > .center-container > .west > .body > .grid {
    padding-right: 0;
}

.layout > .center-container > .east > .body > .grid {
    padding-left: 0;
}

.layout .region > .split.drag-drop {
    /* 这里不加!important覆盖不了 */
    width: 100% !important;
    height: 100% !important;
}

/* 模块展开or收缩 */
.layout .region > .expand-collapse {
    width: 100%;
    height: 30px;
    padding: 0 8px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 6;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fafafa;
}

.layout .region > .expand-collapse.only-shrink-icon {
    width: 36px;
    position: absolute;
    top: 8px;
    left: auto;
    right: 0;
    background-color: transparent;
}

.layout .region > .expand-collapse.only-shrink-icon .expand-round {
    display: none;
}

.layout .region > .expand-collapse .expand-round {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.layout .region > .expand-collapse .expand-round span {
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
}

.layout .region > .expand-collapse.horizontal,
.layout .region > .expand-collapse.vertical {
    width: 100%;
    height: 100%;
    top: 0px;
    right: 0px;
    background-color: #fafafa;
}

.layout .region > .expand-collapse.vertical {
    display: block;
    padding: 8px 0;
    border-top: none;
}

.layout .region > .expand-collapse.horizontal .expand-round {
    display: inline-block;
    width: auto;
    max-width: 95%;
    /* border-radius: 0 15px 15px 0; */
    border-right: 1px solid #e6e6e6;
    position: relative;
    left: 0;
    background-color: #fff;
}

.layout .region > .expand-collapse.horizontal .expand-round span {
    display: inline-block;
    height: 100%;
    line-height: 28px;
    padding: 0 8px;
    border-right: 1px solid #eee;
}

.layout .region > .expand-collapse.horizontal .expand-round span:last-child {
    border-right: none;
}

.layout .region > .expand-collapse .expand-round .expand-icon.expend {
    transform: rotate(270deg);
}

.layout .region.west > .expand-collapse .expand-round .expand-icon.expend {
    transform: rotate(90deg);
}

.layout .region > .expand-collapse.horizontal .expand-icon {
    transform: rotate(180deg);
}

.layout .region > .expand-collapse.vertical .expand-round {
    height: auto;
    max-height: 100%;
    text-align: center;
    padding: 8px 4px 30px 4px;
    border-radius: 0 0 15px 15px;
    border-bottom: 1px solid #e6e6e6;
    position: relative;
    top: 0;
    align-items: stretch;
    background-color: #fff;
}

.layout .region > .expand-collapse.vertical .expand-round span {
    margin-right: 0;
    word-break: normal;
}

.layout .region > .expand-collapse.vertical .expand-round .expand-icon {
    position: absolute;
    left: 6px;
    bottom: 6px;
}

.layout .region > .expand-collapse.vertical .expand-round .expand-icon.expend {
    transform: rotate(90deg);
}

.layout .region.west > .expand-collapse.vertical .expand-round .expand-icon.expend {
    transform: rotate(270deg);
}

.layout .region > .expand-collapse .expand-round .expand-icon {
    transition: transform 0.5s;
}

.layout .region > .body.overflow {
    overflow: hidden;
}
</style>
