<template>
    <radio-group
        :options="options"
        :rowIndex="rowIndex"
        :cellIndex="cellIndex"
        class="grid-radio-group"
        :propValue="propValue"
        @change="changeHandle"
    >
    </radio-group>
</template>

<script>
import radioGroup from '@/gikam/js/components/template/fields/radioGroup/radioGroup.vue';

export default {
    props: {
        options: Object,
        propValue: [Number, String],
        rowIndex: Number,
        cellIndex: Number,
    },

    components: {
        radioGroup
    },

    watch: {
        propValue: {
            immediate: true,
            handler(value) {
                this.$set(this.options, 'value', value);
            }
        }
    },

    methods: {
        changeHandle(...args) {
            this.$emit.apply(this, ['change', ...args]);
        }
    }
};
</script>
