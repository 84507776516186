import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import BaseField from '@/gikam/js/components/template/baseField/baseField.vue';
import Gikam from 'gikam';
import Tooltip from '@/gikam/js/components/tooltip/Tooltip.vue';
import { mixin } from './js/script-input-mixin';
import('vue2-editor').then(function (Vue2Editor) {
  return Vue.use(Vue2Editor);
});
export default {
  extends: BaseField,
  components: {
    Tooltip: Tooltip
  },
  mixins: [mixin],
  props: {
    options: Object,
    rowIndex: Number,
    propValue: String,
    propInvisible: Boolean,
    propReadonly: Boolean
  },
  data: function data() {
    return {
      customToolbar: [[{
        script: 'super'
      }, {
        script: 'sub'
      }]],
      value: this.propValue,
      active: false,
      readonly: null,
      invisible: this.propInvisible,
      validateResult: true,
      field: this.options.field,
      focusValue: null
    };
  },
  mounted: function mounted() {
    this.$refs.editor.$el.querySelector('.ql-editor').style.minHeight = "".concat(this.minHeight, "px");
  },
  computed: {
    inputClass: function inputClass() {
      var classList = ['sw-script-input-editor'];
      this.active && classList.push('active');
      this.readonly && classList.push('readonly');
      this.invisible && classList.push('invisible');
      !this.validateResult && classList.push('validate-error');
      return classList;
    },
    tooltipOptions: function tooltipOptions() {
      return {
        placement: 'bottom',
        title: this.error.length === 0 ? null : Gikam.propI18N(this.error[0].message)
      };
    },
    minHeight: function minHeight() {
      var _this$options$minHeig;

      return (_this$options$minHeig = this.options.minHeight) !== null && _this$options$minHeig !== void 0 ? _this$options$minHeig : 75;
    }
  },
  watch: {
    propValue: function propValue(value) {
      if (value === undefined) {
        this.value = '';
      } else {
        this.value = value.indexOf('<p>') === 0 ? value : "<p>".concat(value, "</p>");
      }
    },
    propReadonly: {
      immediate: true,
      handler: function handler(value) {
        var _ref;

        this.readonly = (_ref = value !== null && value !== void 0 ? value : this.options.readonly) !== null && _ref !== void 0 ? _ref : false;
      }
    }
  },
  methods: {
    changeHandle: function changeHandle() {
      this.isTextChange = true;
    },
    clickHandle: function clickHandle() {
      this.invisible ? this.$emit('click', this) : this.$emit('saveCoordinate');
    },
    focusHandle: function focusHandle(event) {
      this.focusValue = this.value;

      if (!this.readonly) {
        this.active = true;
      }

      event.enable(!this.readonly);
    },
    blurHandle: function blurHandle() {
      if (this.value !== this.focusValue) {
        this.validateResult = this.validate();
        this.validateResult && this.$emit('change', this.value);
      }

      this.active = false;
    }
  }
};