/* eslint-disable no-prototype-builtins */
/* eslint-disable no-console */
/* eslint-disable no-undef */
import Vue from 'vue';
import jQuery from 'jquery';
import { parseObjectToString } from './ObjectParseHelper';
import { PageConfig } from '../components/pageConfig/pageConfig';
import formula from './formula';
import { Sequence } from './sequence';
import { I18N } from '@/gikam/i18n/I18N.js';
import {
    openFile,
    openOfficeFile,
    openFileByOffice,
    printFile,
    openOfficeFileByUrl,
    openExternalOfficeFile
} from './openOfficeFile';
import { addComponent } from './gikam-custom-component';
import SunwayChat from '@/sunway/chat/chat.vue';
import { TaskManager } from '@/gikam/js/components/task-manager/task-manager';
import { scanBarCode } from './scan-bar-code.js';

function formatterAjaxHeader(header) {
    const headers = {};
    if (!header) {
        return headers;
    }
    for (let key in header) {
        headers[key] = encodeURI(header[key]);
    }
    return headers;
}

const Gikam = {
    // 在线状态
    aliveStatus: {
        keepAlive: true
    },

    emptyFunction() {},

    IFM_CONTEXT: IFM_CONTEXT || '',

    systemConfig: {},

    componentManager: {},

    component: {},

    components: {},

    createdComponent: {},

    timeoutManager: {},

    lesComponents: {},

    baseLesVueComp: null,

    //组件配置信息，格式{grid:{'id':{columns:[]}}}
    compConfigManager: {},

    //登录用户
    loginUser: {},

    //当前点击的按钮名称
    buttonText: null,

    //存放实例化组件
    compInstanceContainer: {},

    //国际化
    locale: localStorage.getItem('locale') || 'zh-CN',

    //接口参数，用于错误详情时使用
    ajaxParamContainer: {},

    // 定时任务数组
    intervals: [],

    //弹窗数组
    mask: [],

    // 页面配置
    pageDesignConfig: {},

    // ajax配置
    ajaxConfig: {
        // 是否禁用Delete和Put方法,禁用后,自动使用post方法进行提交
        deleteAndPutDisabled: false
    },

    create: function(type, param) {
        const compConstructor = this.component[this.toInitialUpperCase(type)];
        if (!compConstructor) {
            throw new Error(e);
        }
        return new compConstructor(param);
    },

    createObject: function(parent, attr) {
        let property = {};
        if (!this.isEmptyObject(attr)) {
            this.each(attr, function(p, v) {
                property[p] = {
                    value: v
                };
            });
        }
        return Object.create(parent, property);
    },

    getComp: function(id) {
        if (!id) {
            return;
        }
        return this.window?.compInstanceContainer[id] || this.compInstanceContainer[id];
    },

    getAsyncComp: function(id) {
        const def = this.getDeferred();
        const comp = this.getInstance(id);
        if (comp && (!comp.$state || comp.$state === 'end')) {
            return def.resolve(comp);
        } else {
            this.createdComponent[id] = def;
        }
        return def;
    },

    setCompUrl: function() {
        let _this = this;
        this.each(arguments[0], function(compId, url) {
            const comp = _this.getComp(compId);
            comp &&
                comp.setOptions({
                    url: url
                });
        });
    },

    printf: function(s, o) {
        if (this.isEmpty(o)) {
            return s;
        }
        return s.replace(/{[\w]+}/g, match => {
            return o[match.replace(/[{}]/g, '')] ?? '';
        });
    },

    getText: function(url, header) {
        this.rememberAjaxParam({ url });
        return jQuery.ajax({
            url: url,
            dataType: 'text',
            type: 'get',
            beforeSend: xhr => {
                this.each(header, function(key) {
                    xhr.setRequestHeader(key, encodeURI(this));
                });
            }
        });
    },

    getTextSync: function(url) {
        this.rememberAjaxParam({ url });
        return jQuery.ajax({
            url: url,
            dataType: 'text',
            type: 'get',
            async: false
        });
    },

    getJson: function(url) {
        this.rememberAjaxParam({ url });
        return jQuery.ajax({
            url: url,
            dataType: 'json',
            type: 'get'
        });
    },

    getJsonSync: function(url) {
        this.rememberAjaxParam({ url });
        return jQuery.ajax({
            url: url,
            dataType: 'json',
            type: 'get',
            async: false
        });
    },

    post: function(url, data, header) {
        this.rememberAjaxParam({ url, data });
        return jQuery.ajax({
            url,
            data,
            dataType: 'json',
            type: 'post',
            contentType: 'application/json',
            headers: formatterAjaxHeader(header)
        });
    },

    postSync: function(url, data, header) {
        this.rememberAjaxParam({ url, data });
        return jQuery.ajax({
            url,
            data,
            dataType: 'json',
            type: 'post',
            async: false,
            contentType: 'application/json',
            headers: formatterAjaxHeader(header)
        });
    },

    postText: function(url, data, header) {
        this.rememberAjaxParam({ url, data });
        return jQuery.ajax({
            url,
            data,
            dataType: 'text',
            type: 'post',
            contentType: 'application/json',
            headers: formatterAjaxHeader(header)
        });
    },

    del: function(url, data, header) {
        this.rememberAjaxParam({ url, data });
        const requestUrl =
            this.ajaxConfig.deleteAndPutDisabled === 'true' ? this.addUrlParam(url, { _method: 'delete' }) : url;
        return jQuery.ajax({
            url: requestUrl,
            data: data,
            dataType: 'text',
            type: this.ajaxConfig.deleteAndPutDisabled === 'true' ? 'post' : 'delete',
            contentType: 'application/json',
            headers: formatterAjaxHeader(header)
        });
    },

    put: function(url, data, header) {
        this.rememberAjaxParam({ url, data });
        const requestUrl =
            this.ajaxConfig.deleteAndPutDisabled === 'true' ? this.addUrlParam(url, { _method: 'put' }) : url;
        return jQuery.ajax({
            url: requestUrl,
            data: data,
            dataType: 'text',
            type: this.ajaxConfig.deleteAndPutDisabled === 'true' ? 'post' : 'put',
            contentType: 'application/json',
            headers: formatterAjaxHeader(header)
        });
    },

    ajax(param) {
        return jQuery.ajax(param);
    },

    addUrlParam(url, param = {}) {
        if (!url.includes('?')) {
            url += '?';
        }
        for (const key in param) {
            url += `&${key}=${param[key]}`;
        }

        return url.replace('?&', '?');
    },

    toInitialUpperCase: function(s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    },

    toInitialLowerCase: function(s) {
        return s.charAt(0).toLowerCase() + s.slice(1);
    },

    getPageObject: function() {
        return Object.create(arguments[0]);
    },

    requirejs: function(scriptList) {
        let scripts = '';
        this.scriptManager = this.scriptManager || {};
        this.scriptContainer = this.scriptContainer || this.createDom('div', document.body);
        scriptList.forEach(item => {
            if (!this.scriptManager[item]) {
                scripts += `<script src="${IFM_CONTEXT + item}"></script>`;
                this.scriptManager[item] = true;
            }
        });
        jQuery(this.scriptContainer).html(scripts);
    },

    requireCss: function(linkList) {
        this.linkManager = this.linkManager || {};
        linkList.forEach(item => {
            if (!this.linkManager[item]) {
                const $link = jQuery(`<link href="${IFM_CONTEXT + item}" rel="stylesheet"/>`);
                $link.appendTo(document.head);
            }
        });
    },

    isEmpty: function(value, allowEmptyString) {
        if (typeof value === 'string') {
            value = value.trim();
        }
        return (
            value == null || (!allowEmptyString ? value === '' : false) || (Array.isArray(value) && value.length === 0)
        );
    },

    isNotEmpty: function(value, allowEmptyString) {
        return !this.isEmpty(value, allowEmptyString);
    },

    isTrue: function(value) {
        return value === true || (typeof value === 'string' && value.toLowerCase() === 'true');
    },

    isFalse: function(value) {
        return value === false || (typeof value === 'string' && value.toLowerCase() === 'false');
    },

    confirm: function(title, message, yes, no, yesText, noText) {
        this.create('confirm', {
            title: this.propI18N(title),
            subTitle: this.propI18N(message),
            onYesClick: yes,
            onNoClick: no,
            yesText: yesText,
            noText: noText
        });
    },

    alert: function(message, height, title) {
        this.create('Alert', {
            title: title,
            message: this.propI18N(message),
            height: height
        });
    },

    toast: function(message, duration, position) {
        this.create('toast', {
            message: this.propI18N(message),
            duration: duration,
            position: position
        });
    },

    override: function(comp, options) {
        if (comp) {
            const setDefaultOptions = comp.setDefaultOptions || comp.prototype.setDefaultOptions;
            if (setDefaultOptions) {
                setDefaultOptions(options);
            }
        } else {
            Object.assign(comp.prototype, options);
        }
    },

    getLastModal: function() {
        return this.getInstance(jQuery('body').children('.modal:last')[0]);
    },

    refreshGrids: function(mapper, data) {
        let _this = this;
        for (let gridId in mapper) {
            if (mapper.hasOwnProperty(gridId)) {
                _this.getAsyncComp(gridId).done(grid => {
                    grid.refresh({
                        url: _this.printf(mapper[gridId], data)
                    });
                });
            }
        }
    },

    refreshForms: function(mapper, data) {
        for (let formId in mapper) {
            if (mapper.hasOwnProperty(formId)) {
                this.getComp(formId).refresh({
                    url: this.printf(mapper[formId], data)
                });
            }
        }
    },

    param: function(o, questionMark) {
        let prev = questionMark === false ? '' : '?';
        let serialize = '';
        let _this = this;
        this.each(o, function(p, v) {
            if (_this.isNotEmpty(v)) {
                serialize += '&' + (p + '=' + v);
            }
        });
        return prev + serialize.replace('&', '');
    },

    extend: function() {
        return jQuery.extend.apply(jQuery, arguments);
    },

    isPlainObject: function() {
        return jQuery.isPlainObject(arguments[0]);
    },

    isEmptyObject: function() {
        return jQuery.isEmptyObject(arguments[0]);
    },

    each: function() {
        jQuery.each.apply(jQuery, arguments);
    },

    isNumber: function(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    },

    propI18N: function(key) {
        return this.component.I18N.prop(key) || key;
    },

    openGantt: function(ganttJsUrl, ganttDataUrl, title) {
        let src =
            IFM_CONTEXT +
            '/core/component/item/files/page/dhtmlx-gantt?ganttjsurl=' +
            encodeURI(ganttJsUrl) +
            '&ganttDataUrl=' +
            encodeURI(ganttDataUrl);
        this.create('modal', {
            title: title || 'this.MODAL.CHECK_GANTT',
            iframe: true,
            isFullScreen: true,
            src: src
        });
    },

    openChem: function(targetId, readonly, title) {
        let src =
            IFM_CONTEXT + '/core/component/item/files/page/chem-draw?targetId=' + targetId + '&readonly=' + readonly;

        this.create('modal', {
            title: title || 'this.MODAL.CHEM',
            iframe: true,
            isFullScreen: true,
            src: src
        });
    },

    async print(dom, css, styleText) {
        const $iframe = jQuery('<iframe src="" height="0" style="display: block; border: none;" ></iframe>').appendTo(
            'body'
        );
        const w = $iframe[0].contentWindow;

        setTimeout(() => {
            jQuery(w.document.body).append(dom.cloneNode(true));
            this.printOwn(w, $iframe, dom, css, styleText);
        }, 300);
    },

    async printOwn(w, $iframe, dom, css, styleText) {
        const promiseArr = [];
        jQuery.each(css, (index, item) => {
            if (item.lastIndexOf('.css') > -1) {
                const p = new Promise(resolve => {
                    Gikam.getText(item).done(res => {
                        resolve(res);
                    });
                });
                promiseArr.push(p);
            }
        });

        await Promise.all(promiseArr).then(res => {
            res.forEach(item => {
                const style = document.createElement('style');
                style.innerHTML = item;
                jQuery(w.document.head).append(style);
            });
        });

        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.innerHTML = styleText;
        jQuery(w.document.head).append(style);

        await new Promise(resolve => setTimeout(resolve, 100));
        w.print();
        $iframe.remove();
    },

    getInstance: function(dom) {
        if (!dom) {
            return;
        }
        if (this.isString(dom)) {
            return this.compInstanceContainer[dom];
        }
        if (dom instanceof jQuery) {
            dom = dom[0];
        }
        const id = dom.id || dom.getAttribute('comp-id');
        return this.compInstanceContainer[id];
    },

    setInstance(dom, object) {
        if (dom.id) {
            this.compInstanceContainer[dom.id] = object;
        }
    },

    removeDomStyle: function($dom, array) {
        array.forEach(function(item) {
            $dom.css(item, '');
        });
    },

    ignoreErrorUrl: ['/core/module/sys/todos', '/secure/core/module/sys/menus/icons/queries'],

    setPrototype: function(func, proto) {
        func.prototype = proto;
        return func;
    },

    extendClass: function(child, parent) {
        child.prototype = parent.prototype;
        return child;
    },

    deepExtend: function(...args) {
        if (args.length === 1 && Array.isArray(args[0])) {
            return this.extend(true, [], args[0]);
        }
        return jQuery.extend.apply(jQuery, [true, {}, ...args]);
    },

    /*
     *  将form对象的属性值拷贝覆盖到target对象,深度拷贝
     */
    deepAssign: function(target, from) {
        jQuery.extend(true, target, from);
    },

    download: function(url, fileName) {
        let downLoadUrl = url.replace(fileName, encodeURIComponent(fileName || ''));
        const a = document.createElement('a');
        a.href = downLoadUrl;
        a.download = fileName || decodeURI(downLoadUrl.substr(url.lastIndexOf('/') + 1));
        a.click();
    },

    getJsonWrapper() {
        let args = Array.prototype.slice.apply(arguments);
        let JsonWrapper = function() {
            let _this = this;
            args.forEach(function(item, i) {
                if (i === 0) {
                    item ? (_this.p = item) : null;
                } else {
                    _this['b' + (i === 1 ? '' : i - 1)] = item;
                }
            });
        };
        return JSON.stringify(new JsonWrapper());
    },

    getFieldValue: function(item, field) {
        if (!item || this.isEmpty(field)) {
            return;
        }
        if (field.startsWith('ext$') && item.ext$) {
            return item.ext$[field.replace('ext$.', '')];
        }
        return item[field];
    },

    setFieldValue(data, field, value) {
        if (field?.indexOf('ext$.') === 0) {
            if (!data.ext$) {
                data.ext$ = {};
            }
            Vue.set(data.ext$, field.replace('ext$.', ''), value);
        } else {
            Vue.set(data, field, value);
        }
    },

    openFile,
    openOfficeFile,
    openFileByOffice,
    printFile,
    openOfficeFileByUrl,
    openExternalOfficeFile,

    initOpenOfficeFile: openOfficeFile,

    getRequestParam: function(urlStr) {
        let url = urlStr ? urlStr.substr(urlStr.indexOf('?')) : location.search;
        let param = {};
        if (url.indexOf('?') !== -1) {
            url.substr(1)
                .split('&')
                .forEach(function(item) {
                    let array = item.split('=');
                    param[array[0]] = unescape(array[1]);
                });
        }
        return param;
    },

    argsToArray: function(args) {
        let argsArray = [];
        for (let i = 0; i < args.length; i++) {
            argsArray.push(args[i]);
        }
        return argsArray;
    },

    // eslint-disable-next-line complexity
    preInsert(options) {
        const def = jQuery.Deferred();

        //分区域展示的字段
        if (options.fields && this.isNotEmpty(options.fields) && this.isNotEmpty(options.fields[0].fields)) {
            options.fields = options.fields.reduce((total, item) => {
                return total.concat(item.fields);
            }, []);
        }

        const fields = options.fields
            ? options.fields
                  .filter(item => item.preInsert)
                  .map(item => {
                      let copyItem = this.deepExtend(item);
                      if (options.disableNotEmptyValidate !== true) {
                          if (!copyItem.validators) {
                              copyItem.validators = [];
                          }
                          if (!copyItem.validators.includes('notEmpty')) {
                              copyItem.validators.push('notEmpty');
                          }
                      }
                      if (options.displayReadonly !== false && copyItem.readonly === true) {
                          delete copyItem.readonly;
                      }
                      copyItem.colspan = 1;
                      return copyItem;
                  })
            : null;

        const insert = () => {
            modal && modal.window.showMask();
            const data = ['', [this.extend(form ? form.getData() : null, options.param)]];
            let bizId = null;

            //没有预新增字段直接新增
            this.postText(
                options.url,
                this.getJsonWrapper(form ? { t: getFieldInfoByData(form.getData()) } : null, data)
            )
                .then(id => {
                    bizId = id;
                    if (!form) {
                        return this.getDeferred().resolve();
                    }
                    return form.uploadSingleFile(options.dbTable, id);
                })
                .done(() => {
                    modal && modal.close();
                    modal && modal.window.closeMask();
                    def.resolve(bizId);
                })
                .fail(() => {
                    modal && modal.window.closeMask();
                });
        };

        //没有预新增字段，直接插入数据
        if (this.isEmpty(fields)) {
            insert();
            return def;
        }

        const highHeightTypes = ['textarea', 'image', 'checkbox'];
        const highTypeFields = fields.filter(item => {
            return highHeightTypes.indexOf(item.type) > -1;
        });

        /**
         * 118 = 108 + 10
         * 108:modal 头部和底部
         * 10 ：form  padding-top
         * 42: input 高30  + margin-bottom 12
         * 8: 多文本比单文本多出的高度
         */
        const modalHeight = fields.length * 42 + highTypeFields.length * 8 + 118;

        const modal = this.create('modal', {
            title: options.modalTitle,
            titleStyle: options.modalStyle,
            width: options.width || 400,
            height: options.height || modalHeight
        });

        const layout = this.create('layout', {
            renderTo: modal.window.$dom,
            center: {
                items: [
                    {
                        type: 'btnToolbar',
                        items: [
                            {
                                type: 'button',
                                text: I18N.prop('core.confirm'),
                                class: 'blue',
                                onClick() {
                                    if (form.validate()) {
                                        insert();
                                    }
                                }
                            },
                            {
                                type: 'button',
                                text: I18N.prop('core.cancel'),
                                onClick() {
                                    modal.close();
                                }
                            }
                        ]
                    }
                ]
            }
        });

        const form = this.create('form', {
            renderTo: layout.options.center.$dom,
            columns: options.columns || 1,
            titleWidth: options.titleWidth || 80,
            fields: fields,
            autoSave: false
        });

        if (options.onFormRendered) {
            options.onFormRendered.apply(form);
        }

        const getFieldInfoByData = data => {
            const fieldInfo = {};
            form.options.fields.forEach(fieldOptions => {
                if (
                    this.isNotEmpty(data[fieldOptions.field], true) &&
                    ['select', 'simpleCheckbox'].indexOf(fieldOptions.type) > -1
                ) {
                    const options = {
                        type: fieldOptions.type === 'simpleCheckbox' ? 'checkbox' : fieldOptions.type || 'text'
                    };
                    if (fieldOptions.type === 'select') {
                        options.items = fieldOptions.items;
                    }
                    fieldInfo[fieldOptions.field] = options;
                }
            });
            return fieldInfo;
        };

        return def;
    },

    /**
     * @description 控制台输出错误信息
     * @public
     * @param {String} message 错误信息
     */
    error(message) {
        console.error('[Gikam error info]:' + message);
    },

    /**
     * @description 控制台输出调试信息
     * @public
     * @param {String} message 信息
     */
    info(message) {
        console.info(message);
    },

    /**
     * @description 控制台输出警告信息
     * @public
     * @param {String} message 警告信息
     */
    warn(message) {
        console.warn('[this warn info]:' + message);
    },

    importExcel(options) {
        options.multiple = false;
        options.onAfterClose = files => {
            if (this.isEmpty(files)) {
                return;
            }
            workspace.window.showMask();
            this.post(
                this.printf(options.url, {
                    id: files[0].id
                })
            )
                .done(result => {
                    if (result.url) {
                        this.download(result.url);
                    } else if (result.message) {
                        this.alert(result.message);
                    }
                    if (options.onImportSuccess) {
                        options.onImportSuccess.call(null, files);
                    }
                })
                .always(() => {
                    workspace.window.closeMask();
                });
        };
        this.create('simpleUploader', options);
    },

    import(options) {
        this.importExcel(options);
    },

    /**
     * @description 设置所有组件默认参数,配置示例如下{ Grid:{page : false}}
     * @public
     */
    setCompConfig(config) {
        for (let key in config) {
            const comp = this.component[this.toInitialUpperCase(key)];
            if (comp) {
                this.override(comp, { defaultOptions: config[key] });
            } else {
                const methods = this.Vue.component(key).prototype.constructor.options.methods;
                methods.override && methods.override(config[key]);
            }
        }
    },

    /**
     * @description 设置组件对象配置信息
     * @param {Object} 配置信息,例如{grid:{gridId1:{columns:[]}},form:{formId1:{fields:[]}}}
     */
    setCompParam(param) {
        if (this.window?.pageEditing) {
            return;
        }
        this.compConfigManager = {};
        this.deepAssign(this.compConfigManager, this.isString(param) ? JSON.parse(param) : param);
    },

    setDomAttribute(dom, attribute) {
        for (let key in attribute) {
            dom.setAttribute(key, attribute[key]);
        }
    },

    createDom(domTag, renderToDom) {
        const dom = document.createElement(domTag);
        renderToDom.appendChild(dom);
        return dom;
    },

    removeDom(dom) {
        dom.parentNode?.removeChild(dom);
    },

    isArray(object) {
        return Array.isArray(object);
    },

    isString(str) {
        return typeof str === 'string';
    },

    isBoolean(str) {
        return typeof str === 'boolean';
    },

    /**
     * @description 是否是函数
     * @param {*} object
     * @returns {Boolean}
     */
    isFunction(object) {
        return typeof object === 'function';
    },

    /**
     * @description 清空组件数据方法，主要针对Form和Grid
     * @param {String} compIdArray 组件id集合
     * @returns
     */
    cleanCompData(compIdArray) {
        if (this.isEmpty(compIdArray)) {
            return;
        }
        compIdArray.forEach(compId => {
            const comp = this.getComp(compId);
            if (comp) {
                comp.cleanData();
            }
        });
    },

    /**
     * @description 触发对象中的方法，并指定方法的上下文和参数
     * @private
     * @param {Object} object 调用方法坐在的对象
     * @param {String} eventName 事件名称
     * @param {Object} scope 回调方法中的上下文
     * @param {*} args 回调方法中的参数
     */
    trigger(object, eventName, scope, ...args) {
        if (!object[eventName]) {
            return;
        }
        return object[eventName].apply(scope, args);
    },

    getElParam(p) {
        var param = {};
        for (var key in p) {
            if (key === '_') {
                continue;
            }
            param[key] = p[key][0];
        }
        return param;
    },

    /**
     * @description 过滤Tree类型数组
     * @public
     * @param {Array} treeList 存在children子节点类型的数组
     * @param {Function} callback 过滤回调函数
     * @param {Boolean} withAllChildren 是否包含所有子节点，如果为true，若父节点匹配，则所有子节点都匹配
     * @returns Tree类型数组
     */
    filterTree(treeList, callback, withAllChildren) {
        if (!Array.isArray(treeList)) {
            this.error('treeList expected array type');
            return;
        }

        const filterNode = nodeList => {
            return nodeList.filter(item => {
                if (withAllChildren && callback(item)) {
                    return true;
                }
                if (this.isNotEmpty(item.children)) {
                    item.children = filterNode(item.children);
                }
                if (this.isNotEmpty(item.children)) {
                    return true;
                } else {
                    return callback(item);
                }
            });
        };

        return filterNode(this.deepExtend(treeList));
    },

    /**
     * @description 过滤Tree类型数组,返回数组。所有符合条件的节点都存放到数组中，没有父子关系
     * @public
     * @param {Array} treeList 存在children子节点类型的数组
     * @param {Function} callback 过滤回调函数
     * @returns List
     */
    filterTreeList(treeList, callback) {
        if (!Array.isArray(treeList)) {
            this.error('treeList expected array type');
            return;
        }
        const resultList = [];
        const filterNode = nodeList => {
            nodeList.forEach(item => {
                if (this.isNotEmpty(item.children)) {
                    filterNode(item.children);
                }
                callback(item) && resultList.push(item);
            });
        };
        filterNode(treeList);
        return resultList;
    },

    /**
     * @description 对树类型数组进行遍历
     * @param {Array} treeList
     * @param {Function} callback
     */
    eachTree(treeList, callback) {
        if (!Array.isArray(treeList)) {
            this.error('treeList expected array type');
            return;
        }

        const eachNode = (nodeList, parentNode) => {
            return nodeList.forEach(item => {
                callback(item, parentNode);

                if (this.isNotEmpty(item.children)) {
                    eachNode(item.children, item);
                }
            });
        };

        return eachNode(treeList);
    },

    /**
     * @description 从Tree类型的数组中找到符合要求的一个节点
     */
    findTreeNode(treeList, callback) {
        if (!Array.isArray(treeList)) {
            this.error('treeList expected array type');
            return;
        }
        const findNode = (nodeList, parentNode) => {
            for (const node of nodeList) {
                if (callback(node, parentNode)) {
                    return node;
                }
                if (this.isNotEmpty(node.children)) {
                    const resultNode = findNode(node.children, node);
                    if (resultNode) {
                        return resultNode;
                    }
                }
            }
        };
        return findNode(treeList);
    },

    /**
     * @description 获取节点的所有父节点,节点中必须包含$parentNode
     * @public
     * @param {Object} node
     */
    getTreeParents(node) {
        const parents = [];

        const getParents = node => {
            if (!node.$parentNode) {
                return parents;
            }
            parents.push(node.$parentNode);
            getParents(node.$parentNode);
            return parents;
        };

        return getParents(node);
    },

    /**
     * @description 高频率执行延时操作时，只执行最后一次动作
     * @public
     * @param {String} flag 唯一标识一次延时，例如：searchInputTimeout
     * @param {Function} callback 执行方法
     * @param {Number} timeout
     */
    finalDelay(flag, callback, timeout) {
        if (this.timeoutManager[flag]) {
            clearTimeout(this.timeoutManager[flag]);
        }
        this.timeoutManager[flag] = setTimeout(() => {
            callback();
            delete this.timeoutManager[flag];
        }, timeout);
    },

    /**
     * @description 创建带有确认取消按钮，并且不含url的Modal
     * @param {Object}
     *        {title:'modal标题',onConfirm:()=>{},onCancel:()=>{},
     *        toolbarFormatter:(toolbar)=>{}}
     * @returns {Modal}
     */
    createSimpleModal(props) {
        const modal = this.create('modal', props);
        let toolbar = [];
        let confirmButton = {
            type: 'button',
            text: I18N.prop('core.confirm'),
            class: 'blue',
            isSaveButton: props.isSaveButton,
            onClick() {
                props.onConfirm();
            }
        };
        let cancelButton = {
            type: 'button',
            text: I18N.prop('core.cancel'),
            onClick() {
                props.onCancel();
            }
        };
        if (this.isTrue(props.btnPosReverse)) {
            toolbar = [confirmButton, cancelButton];
        } else {
            toolbar = [cancelButton, confirmButton];
        }
        if (props.toolbarFormatter) {
            toolbar = props.toolbarFormatter(toolbar);
        }
        const layout = this.create('layout', {
            renderTo: modal.window.$dom,
            center: {
                items: [
                    {
                        type: 'btnToolbar',
                        items: toolbar
                    }
                ]
            }
        });
        return { modal, layout };
    },

    /**
     * @description 经纬度选择
     * @param {Object}
     *        {title:'这是标题', width: 450, height: 150}
     * @returns Differed
     */
    pickLngLat(props = {}) {
        const def = this.jQuery.Deferred();
        let { modal, layout } = this.createSimpleModal({
            title: props.title || I18N.prop('map.pickLngLat'),
            width: props.width || 600,
            height: props.height || 450,
            onAfterClose: () => {
                let chosenPoints = mapInstance.pickLngLat();
                def.resolve(chosenPoints);
            },
            onConfirm: () => {
                let chosenPoints = mapInstance.pickLngLat();
                if (chosenPoints.length > 0 && chosenPoints[0].adcode) {
                    Gikam.confirm(
                        I18N.prop('map.sure'),
                        I18N.prop('map.location') +
                            chosenPoints[0].name +
                            '<br/><br/>' +
                            I18N.prop('map.address') +
                            chosenPoints[0].cityname +
                            chosenPoints[0].adname +
                            chosenPoints[0].address +
                            '<br/><br/>' +
                            I18N.prop('map.coordinate') +
                            '[' +
                            chosenPoints[0].location.lng +
                            ', ' +
                            chosenPoints[0].location.lat +
                            ']',
                        () => {
                            modal.close();
                        }
                    );
                } else {
                    modal.close();
                }
            },
            onCancel: () => {
                mapInstance.clearMarks();
            }
        });

        let mapInstance = this.create('map', {
            renderTo: layout.options.center.$dom,
            multiple: Gikam.isEmpty(props.multiple) ? false : props.multiple,
            placeSearch: props.placeSearch,
            coordinate: props.coordinate,
            newMarker: true
        });

        return def;
    },

    /**
     * @description 是否是手机端
     * @public
     * @returns Boolean
     */
    isMobilePhone() {
        return document.body.clientWidth <= 768;
    },

    /**
     * @description 是否是平板
     * @public
     * @returns Boolean
     */
    isPad() {
        return document.body.clientWidth <= 1366 && document.body.clientWidth > 768;
    },

    /**
     * @description 解析json字符串，如果不是json字符串格式，则返回空对象
     * @public
     * @returns Object
     */
    parseJson(jsonStr) {
        try {
            return JSON.parse(jsonStr);
        } catch (error) {
            return jsonStr || null;
        }
    },

    /**
     * @description 复写类中的方法
     * @public
     * @param {Object} scope 方法的上下文
     * @param {String} method 要复写的方法名称
     * @param {Function} before 执行原有方法之前的回调
     * @param {Function} after 执行完原有方法之后的回调
     * @param {Function} override 新复写方法
     */
    overrideClassMethod(clazz, methodName, before, after, override) {
        let oldMethod = clazz.prototype[methodName];
        clazz.prototype[methodName] = function(...args) {
            before && before.apply(this, args);
            override && (oldMethod = override);
            const ret = oldMethod.apply(this, args);
            after && after.apply(this, args);
            return ret;
        };
    },

    /**
     *
     * @description 动画显示
     * @public
     * @param {*} dom
     * @param {*} speed
     * @param {*} easing
     * @param {*} fn
     */
    slideDown(dom, speed, easing, fn) {
        jQuery(dom).slideDown(speed, easing, fn);
    },

    /**
     * @description 动画隐藏
     * @public
     * @param {*} dom
     * @param {*} speed
     * @param {*} easing
     * @param {*} fn
     */
    slideUp(dom, speed, easing, fn) {
        jQuery(dom).slideUp(speed, easing, fn);
    },

    /**
     * @description 将对象转为String
     * @public
     * @param {Object} object
     * @returns
     */
    parseObjectToString,

    /**
     * @description 页面配置
     * @private
     */
    PageConfig,

    getDeferred() {
        return jQuery.Deferred();
    },

    /**
     * @description 公式方法
     * @public
     * @param {Object} object
     * @returns
     */
    formula,

    sequence: new Sequence(),

    /**
     * @description 文字转语音方法
     * @public
     * @param { text, rate, lang, volume, pitch } object
     * @param  text 要合成的文字内容，字符串
     * @param  rate 读取的语速 0.1~10  正常1
     * @param  lang 读取时的语言
     * @param  volume  声音的音量 0~1  正常1
     * @param  pitch  声音的音高 0~2  正常1
     * @param voiceSingleNum 读取数字时是否按照单个数字字符读
     * @returns SpeechSynthesisUtterance
     */
    speak({ text, speechRate, lang, volume, pitch, voiceSingleNum }, endEvent, startEvent) {
        if (!window.SpeechSynthesisUtterance) {
            this.warn(I18N.prop('core.speakWarn'));
            return;
        }

        if (this.isEmpty(text)) {
            return;
        }

        if (voiceSingleNum && this.isNotEmpty(text)) {
            text = text.toString().replace(/\d/g, matchChar => ` ${matchChar}`);
        }
        const speechInstance = new SpeechSynthesisUtterance();
        speechInstance.text = text;
        speechInstance.rate = speechRate || 1;
        speechInstance.lang = lang || 'zh-CN';
        speechInstance.volume = volume || 1;
        speechInstance.pitch = pitch || 1;
        speechInstance.onend = function() {
            endEvent && endEvent();
        };
        speechInstance.onstart = function() {
            startEvent && startEvent();
        };
        speechSynthesis.speak(speechInstance);
        return speechInstance;
    },

    /**
     * @description 关闭读取语音的方法
     * @public
     * @param SpeechSynthesisUtterance 对象实例
     * @returns
     */
    stopSpeak(speechInstance) {
        if (speechInstance instanceof SpeechSynthesisUtterance) {
            speechSynthesis.cancel(speechInstance);
        }
    },

    /**
     * @description 判断传入文件后缀或完整文件名是否被sunwayoffice支持
     * @public
     * @param fileInfo 完整文件名或文件后缀
     * @returns
     */
    isSunwayOfficeSupport(fileInfo) {
        let tempSuffix = fileInfo;
        if (fileInfo.indexOf('.') !== -1) {
            let tempArray = fileInfo.split('.');
            tempSuffix = tempArray[tempArray.length - 1];
        }
        const supportType = [
            'doc',
            'docm',
            'docx',
            'dot',
            'dotm',
            'dotx',
            'epub',
            'fodt',
            'htm',
            'html',
            'mht',
            'odt',
            'ott',
            'pdf',
            'rtf',
            'txt',
            'djvu',
            'xps',
            'csv',
            'fods',
            'ods',
            'ots',
            'xls',
            'xlsm',
            'xlsx',
            'xlt',
            'xltm',
            'xltx',
            'fodp',
            'odp',
            'otp',
            'pot',
            'potm',
            'potx',
            'pps',
            'ppsm',
            'ppsx',
            'ppt',
            'pptm',
            'pptx'
        ];
        return supportType.indexOf(tempSuffix.toLowerCase()) !== -1;
    },

    /**
     * @description 模拟事件
     * @public
     * @param node 需要触发的dom,默认为全局document
     * @param string 需要触发的事件名称
     */
    simulatedEvent(dom = document, eventName) {
        try {
            dom.dispatchEvent(new Event(eventName));
        } catch {
            const evt = document.createEvent('HTMLEvents');
            evt.initEvent(eventName, false, true);
            dom.dispatchEvent(evt);
        }
    },

    getDomSize(dom) {
        const $dom = this.jQuery(dom);
        return {
            width: $dom.width(),
            height: $dom.height(),
            outerWidth: $dom.outerWidth(),
            outerHeight: $dom.outerHeight()
        };
    },

    getTextIgnoreHtml(text) {
        return text ? text.replace(/<[^<>]+>/g, '') : text;
    },

    /**
     * @description 浏览器发送Notification通知
     * @public
     * @param Notification
     * @returns
     */
    sendNotification(title, body, icon, menuId, callback) {
        let tit = title ? title : '实验室通知';
        // 先检查浏览器是否支持
        if (!('Notification' in window)) {
            // IE浏览器不支持发送Notification通知!
        } else {
            if (Notification.permission !== 'denied') {
                //判断是否授权,没授权先授权在通知
                Notification.requestPermission(function(permission) {
                    // 如果用户同意，就可以向他们发送通知
                    if (permission === 'granted') {
                        notificationConfig();
                    }
                });
            } else {
                //已授权直接通知
                notificationConfig();
            }
        }

        function notificationConfig() {
            if (this.notice_) {
                return;
            }
            this.notice_ = new Notification(tit, {
                icon,
                body
            });
            this.notice_.onclick = function() {
                //单击消息提示框，进入浏览器页面
                menuId && window.workspace.activeNodeById(menuId);
                callback && callback();
                this.notice_ = void 0;
            };
            this.notice_.onclose = function() {
                this.notice_ = void 0;
            };
        }
    },

    /**
     * @description 获取错误信息
     * @public
     * @returns String
     */
    getI18NErrorMessage(message) {
        try {
            const messageObject = JSON.parse(message);
            if (messageObject.stacktrace && messageObject.message) {
                return this.propI18N(messageObject.message);
            }
        } catch (e) {
            return this.propI18N(message);
        }
    },

    /**
     * @description 判断是否是移动端设备
     * @public
     * @returns Boolean
     */
    isMobile() {
        return !!window.top.navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i);
    },

    /**
     * @description 判断是否是IE浏览器
     * @public
     * @returns Boolean
     */
    isIE() {
        return !!window.ActiveXObject || 'ActiveXObject' in window;
    },

    /**
     * @description 判断是否是Edge浏览器
     * @public
     * @returns Boolean
     */
    isEdge() {
        return navigator.userAgent.indexOf('Edge') > -1;
    },

    /**
     * @description 密码加载方式
     */
    encryptPassword(password) {
        return password;
    },

    /**
     * @description 将文本内容下载
     */
    downloadText(fileName, text) {
        const url = window.URL || window.webkitURL || window;
        const blob = new Blob([text]);
        const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
        save_link.href = url.createObjectURL(blob);
        save_link.download = fileName;
        save_link.click();
    },

    /**
     * @private
     * @description
     */
    rememberAjaxParam(param) {
        const { data = null, url } = param;
        this.ajaxParamContainer[param.url.replace(this.IFM_CONTEXT, '')] = { data, url };
    },

    /**
     * @description 给方法增加定时器，并保存到Gikam中
     * @param (Function, Number) callback 方法， time时间间隔
     */
    addInterVal(callback, time) {
        let intervalId = setInterval(() => {
            callback && callback();
        }, time);
        Gikam.intervals.push({
            intervalId: intervalId,
            callback: callback,
            time: time
        });
    },

    /**
     * @description 统一清空Gikam中的定时器
     */
    removeInterval() {
        if (Gikam.isNotEmpty(Gikam.intervals)) {
            Gikam.intervals.forEach(item => {
                clearInterval(item.intervalId);
            });
        }
    },

    /**
     * @description 统一恢复Gikam中的定时器的方法
     */
    renewInterval() {
        if (Gikam.isNotEmpty(Gikam.intervals)) {
            Gikam.intervals.forEach(item => {
                item.callback && item.callback();
                this.addInterVal(item.callback, item.time);
            });
        }
    },

    /**
     * @description 获取上下文url
     * @param {*} url
     * @returns
     */
    getContextUrl(url) {
        const _url = url.toLowerCase();
        if (_url.indexOf('http://') === 0 || _url.indexOf('https://') === 0) {
            return url;
        }
        return this.IFM_CONTEXT + url;
    },

    /**
     * @description 获取文件上下文url
     * @param {*} url
     * @returns
     */
    getFileContextUrl(url, type) {
        const _url = url.toLowerCase();
        if (_url.startsWith('http://') || _url.startsWith('https://')) {
            return url;
        }
        if (type === 'sunwayoffice') {
            return top.Gikam.sunwayoffice.downloadUrl + url;
        }
        return top.Gikam.file.downloadUrl + url;
    },

    /**
     * @description 获取浏览器路径参数
     * @param {*} url
     * @returns Map
     */
    getLocationSearchUrl(url) {
        if (!url) {
            return new Map();
        }
        return new Map(
            url
                .trim()
                .slice(1)
                .split('&')
                .map(item => item.split('='))
        );
    },

    /**
     * @description 获取树层深
     * @param {*} data
     * @param {*} childrenKey
     * @returns level
     */
    getLevelNum(list, key) {
        const baseFun = function baseFun(node, level, arr) {
            node.forEach(item => {
                if (item[key]?.length) {
                    baseFun(item[key], level + 1, arr);
                } else {
                    arr.push(level);
                }
            });
        };

        const levelArr = [];
        baseFun(list, 1, levelArr);

        return levelArr.sort((a, b) => b - a)[0];
    },

    /**
     * @description 执行间隔时间
     * @param {*} time
     * @returns Promise
     */
    sleep(time) {
        return new Promise(resolve => setTimeout(resolve, time * 1000));
    },

    /**
     * @description 打开遮罩
     * @param {dom} mask 存放区域
     * @returns maskName
     */
    showMask(text, parentDom = document.body, options = {}) {
        if (options.closeOther !== false) {
            this.cleanMask();
        }
        const message = text ? this.propI18N(text) : '';
        const mask = new Vue({
            el: Gikam.createDom('div', parentDom),
            data() {
                return {
                    subscribe: [],
                    ready: false
                };
            },
            components: {
                swMask: () => import('@/gikam/js/components/template/mask/mask.vue')
            },
            methods: {
                readyHandle() {
                    this.ready = true;
                    this.subscribe.forEach(watch => watch(this));
                    this.subscribe = [];
                },

                onReady(callback) {
                    this.ready ? callback(this) : this.subscribe.push(callback);
                },

                destroy() {
                    this.onReady(() => {
                        this.$destroy();
                        Gikam.removeDom(this.$el);
                    });
                }
            },
            render() {
                return (
                    <sw-mask onReady={this.readyHandle} mode={options.mode}>
                        {message}
                    </sw-mask>
                );
            }
        });
        this.mask.push(mask);
        return new Promise(resolve => mask.onReady(resolve));
    },

    /**
     * @description 关闭指定的遮罩
     * @param {name} mask-name
     * @returns  Gikam
     */
    closeMask(mask) {
        mask && mask.destroy();
        return this;
    },

    /**
     * @description 清除所有的遮罩
     * @returns  Gikam
     */
    cleanMask() {
        for (let i = this.mask.length - 1; i > -1; i--) {
            this.closeMask(this.mask.pop());
        }
        return this;
    },

    /**
     * @description 对Tree类型数据，进行map遍历
     * @static
     * @param {any[]} list
     * @param {(item: any) => void} callback
     * @returns {any[]}
     * @memberof ObjectUtils
     */
    mapTree(list, callback, childrenName = 'children') {
        return list.map(item => {
            const formatterItem = callback(item);
            if (Array.isArray(item.children)) {
                formatterItem[childrenName] = this.mapTree(item[childrenName], callback, childrenName);
            }
            return formatterItem;
        });
    },

    /**
     * @public 换行符使用<br>
     * @description 添加水印
     */
    watermark({ text = '', fontSize = 16, rotate = 340, color = 'rgba(0,0,0,0.18)', textAlign = 'center' }) {
        if (this.watermarkVue) {
            this.removeDom(this.watermarkVue.$el);
            this.watermarkVue.$destroy();
        }
        this.watermarkVue = new Vue({
            el: this.createDom('div', document.body),
            components: {
                watermark: () => import('@/gikam/js/components/watermark/vue/watermark')
            },
            render() {
                return (
                    <watermark
                        text={text}
                        fontSize={fontSize}
                        rotate={rotate}
                        color={color}
                        textAlign={textAlign}
                    ></watermark>
                );
            }
        });
    },

    addComponent(action) {
        addComponent(action);
    },

    getTopGikam() {
        if (window.__$single__) {
            return this;
        }
        if (top.window !== window) {
            return top.Gikam;
        }
        return this;
    },

    debounce(callback, timeout) {
        let timer = null;
        return function(...args) {
            timer && clearTimeout(timer);
            timer = setTimeout(() => {
                callback.apply(this, args);
            }, timeout);
        };
    },

    getCurrentWindow() {
        return this.window || this.getLastModal()?.window;
    },

    /**
     * @description 将dom元素转换为完整html，并带有css样式
     * @param {*} dom
     * @returns
     */
    transferDomToHtml(dom) {
        const defer = this.getDeferred();
        const href = [
            '/static/gikam/main/css/chunk-common.css',
            '/static/gikam/main/css/chunk-vendors.css',
            '/static/gikam/main/css/workspace.css',
            '/static/gikam/extend/css/extend.css'
        ];
        const promiseArr = [];
        href.forEach(href => {
            promiseArr.push(
                new Promise(resolve => {
                    Gikam.getText(this.IFM_CONTEXT + href).done(res => resolve(res));
                })
            );
        });
        let totalCssContents = '';
        jQuery(document.head)
            .children('style')
            .each(function() {
                totalCssContents += jQuery(this).html();
            });
        Promise.all(promiseArr).then(cssContents => {
            cssContents.forEach(contents => (totalCssContents += contents));
            const domHtml = jQuery(dom)
                .parent()
                .html();
            defer.resolve(
                '<!DOCTYPE html>\n' +
                    '<html>' +
                    `<head><style type="text/css">${totalCssContents}</style></head>` +
                    `<body>${domHtml}</body>` +
                    '</html>'
            );
        });
        return defer;
    },

    /**
     * @description 聊天窗口
     */
    chat(options) {
        new Vue({
            el: this.createDom('chat', document.body),
            components: {
                SunwayChat
            },
            render() {
                return <SunwayChat attrs={options} />;
            }
        });
    },

    openTaskManager() {
        TaskManager.openTaskManager();
    },

    /**
     * @description 手机拍照识别条形码二维码功能
     */
    codeRecognition(callback) {
        const url = this.IFM_CONTEXT;
        const isHttp = url.split(':')[0];
        if (isHttp === 'http') {
            scanBarCode(callback);
        } else {
            const modal = this.create('modal', { id: 'cusModal', showMaxBtn: false, showMinBtn: false });
            const _this = this;
            new Vue({
                el: modal.window.$dom,
                components: {
                    HttpsCodeReader: () => import('@/gikam/js/components/code-reader/https-code-reader.vue')
                },
                render() {
                    return (
                        <https-code-reader
                            callback={callback}
                            onSuccess={() => {
                                _this.getComp('cusModal').close();
                            }}
                        />
                    );
                }
            });
        }
    }
};

export default Gikam;
