<template>
    <ButtonGroup
        ref="editor"
        :options="options"
        :propValue="propValue"
        :propReadonly="readonly"
        @change="changeHandle"
        @afterChoose="afterChooseHandle"
        @selfClean="selfCleanHandle"
    ></ButtonGroup>
</template>

<script>
import ButtonGroup from '@/gikam/js/components/template/fields/buttonGroup/buttonGroup';
import { mapState } from 'vuex';

export default {
    components: {
        ButtonGroup
    },

    props: {
        options: Object,
        propValue: [Number, String],
        dataId: [Number, String],
        formData: Object
    },

    data() {
        return {
            readonly: this.options.readonly ?? false
        };
    },

    computed: {
        ...mapState(['form'])
    },

    mounted() {
        this.form.editorManager[this.options.field] = this.$refs.editor;
    },

    methods: {
        changeHandle(...args) {
            this.$emit.apply(this, ['change', ...args]);
        },

        afterChooseHandle(...args) {
            this.$emit.apply(this, ['afterChoose', ...args]);
        },

        selfCleanHandle() {
            this.formData[this.options.field] = '';
        }
    }
};
</script>
