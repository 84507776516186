<template>
    <div :class="rootClass" :style="wrapperStyle" @mousedown="mousedownHandle" ref="wrapper">
        <div class="content-container">
            <slot></slot>
        </div>
        <div
            v-for="(stick, index) in sticks"
            :key="index"
            class="vdr-stick"
            :class="['vdr-stick-' + stick]"
            :style="vdrStick(stick)"
            @mousedown.stop.prevent="stickDownHandle(stick, $event)"
            @touchstart.stop.prevent="stickDownHandle(stick, $event)"
        ></div>
        <div v-if="dragging" class="drag-resize-mask"></div>
    </div>
</template>

<script>
const styleMapping = {
    y: {
        t: 'top',
        m: 'marginTop',
        b: 'bottom'
    },
    x: {
        l: 'left',
        m: 'marginLeft',
        r: 'right'
    }
};

export default {
    props: {
        options: {
            default() {
                return {
                    width: 0,
                    height: 0,
                    left: 0,
                    top: 0
                };
            }
        },
        parentScaleX: {
            type: Number,
            default: 1
        },
        parentScaleY: {
            type: Number,
            default: 1
        },
        stickSize: {
            type: Number,
            default: 8
        }
    },

    data() {
        return {
            sticks: ['tl', 'tm', 'tr', 'mr', 'br', 'bm', 'bl', 'ml'],
            wrapperStyle: { width: 0, height: 0, left: 0, top: 0 },
            timer: null,
            dragging: false,
            activeStick: null,
            startPosition: {
                x: null,
                y: null
            },
            vdrStick(stick) {
                const stickStyle = {
                    width: `${this.stickSize / this.parentScaleX}px`,
                    height: `${this.stickSize / this.parentScaleY}px`
                };
                stickStyle[styleMapping.y[stick[0]]] = `${this.stickSize / this.parentScaleX / -2}px`;
                stickStyle[styleMapping.x[stick[1]]] = `${this.stickSize / this.parentScaleX / -2}px`;
                return stickStyle;
            },
            StickMapper: {
                tr: [1, -1, 0, 1],
                tm: [0, -1, 0, 1],
                tl: [-1, -1, 1, 1],
                ml: [-1, 0, 1, 0],
                mr: [1, 0, 0, 0],
                bl: [-1, 1, 1, 0],
                bm: [0, 1, 0, 0],
                br: [1, 1, 0, 0]
            },
            isResizable: true,
            isDraggable: true
        };
    },

    computed: {
        rootClass() {
            const classList = ['vdr'];
            if (this.dragging) {
                classList.push('dragging');
            }
            if (this.isResizable === false) {
                classList.push('resize-disabled');
            }
            return classList;
        }
    },

    methods: {
        mousedownHandle(event) {
            if (this.isDraggable === false) {
                return;
            }
            Object.assign(this.startPosition, {
                x: event.pageX,
                y: event.pageY
            });
            document.documentElement.addEventListener('mouseup', this.mouseupHandle);
            document.documentElement.addEventListener('mousemove', this.mousemoveHandle);
            this.$emit('dragBegin');
        },

        mouseupHandle() {
            Object.assign(this.startPosition, {
                x: null,
                y: null
            });
            this.dragging = false;
            this.activeStick = null;
            document.documentElement.removeEventListener('mouseup', this.mouseupHandle);
            document.documentElement.removeEventListener('mousemove', this.mousemoveHandle);
            this.$emit('dragStop', this.getWrapperSize());
        },

        mousemoveHandle(event) {
            if (this.isDraggable === false) {
                return;
            }
            if (this.startPosition.x === null) {
                return;
            }
            if (this.isEffectiveDistance(event)) {
                this.dragging = true;
                const { x, y } = this.startPosition;
                this.wrapperStyle.left = `${parseFloat(this.wrapperStyle.left) + event.pageX - x}px`;
                this.wrapperStyle.top = `${parseFloat(this.wrapperStyle.top) + event.pageY - y}px`;
                this.startPosition.x = event.pageX;
                this.startPosition.y = event.pageY;
            }
        },

        // 是否有效距离
        isEffectiveDistance(event) {
            const { x, y } = this.startPosition;
            return Math.sqrt((event.pageX - x) ** 2 + (event.pageY - y) ** 2) > 5;
        },

        stickDownHandle(stick, event) {
            if (this.isResizable === false) {
                return;
            }
            Object.assign(this.startPosition, {
                x: event.pageX,
                y: event.pageY
            });
            this.activeStick = stick;
            document.documentElement.addEventListener('mouseup', this.stickMouseupHandle);
            document.documentElement.addEventListener('mousemove', this.stickMousemoveHandle);
        },

        stickMousemoveHandle(event) {
            if (this.isResizable === false) {
                return;
            }
            if (this.startPosition.x === null) {
                return;
            }
            const stick = this.activeStick;
            if (this.isEffectiveDistance(event)) {
                this.dragging = true;
                const { x, y } = this.startPosition;
                const { width, height, left, top } = this.wrapperStyle;
                const offsetX = event.pageX - x;
                const offsetY = event.pageY - y;
                this.wrapperStyle.width = `${parseFloat(width) + offsetX * this.StickMapper[stick][0]}px`;
                this.wrapperStyle.height = `${parseFloat(height) + offsetY * this.StickMapper[stick][1]}px`;
                this.wrapperStyle.left = `${parseFloat(left) + offsetX * this.StickMapper[stick][2]}px`;
                this.wrapperStyle.top = `${parseFloat(top) + offsetY * this.StickMapper[stick][3]}px`;
                this.startPosition.x = event.pageX;
                this.startPosition.y = event.pageY;
            }
        },

        stickMouseupHandle() {
            Object.assign(this.startPosition, {
                x: null,
                y: null
            });
            this.dragging = false;
            this.activeStick = null;
            document.documentElement.removeEventListener('mouseup', this.stickMouseupHandle);
            document.documentElement.removeEventListener('mousemove', this.stickMousemoveHandle);
            this.$emit('resizeStop', this.getWrapperSize());
        },

        getWrapperSize() {
            const { width, height, left, top } = this.wrapperStyle;
            return {
                width: parseFloat(width),
                height: parseFloat(height),
                left: parseFloat(left),
                top: parseFloat(top)
            };
        }
    },

    watch: {
        options: {
            immediate: true,
            deep: true,
            handler(options) {
                Object.assign(this.wrapperStyle, {
                    width: `${options.width}px`,
                    height: `${options.height}px`,
                    left: `${options.left}px`,
                    top: `${options.top}px`
                });
                this.isResizable = options.isResizable ?? true;
                this.isDraggable = options.isDraggable ?? true;
            }
        }
    }
};
</script>

<style scoped lang="scss">
.vdr {
    position: absolute;
    box-sizing: border-box;

    &.dragging {
        user-select: none;
        cursor: move;
        transition: none !important;
    }

    > .content-container {
        height: 100%;
    }

    > .drag-resize-mask {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        user-select: none;
        z-index: 9999;
    }

    &.resize-disabled {
        > .vdr-stick {
            display: none;
        }
    }
}

.vdr.active:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    outline: 1px dashed #d6d6d6;
}

.vdr-stick {
    box-sizing: border-box;
    position: absolute;
    font-size: 1px;
    background: #ffffff;
    border: 1px solid #6c6c6c;
    box-shadow: 0 0 2px #bbb;
}

.inactive .vdr-stick {
    display: none;
}

.vdr-stick-tl,
.vdr-stick-br {
    cursor: nwse-resize;
}

.vdr-stick-tm,
.vdr-stick-bm {
    left: 50%;
    cursor: ns-resize;
}

.vdr-stick-tr,
.vdr-stick-bl {
    cursor: nesw-resize;
}

.vdr-stick-ml,
.vdr-stick-mr {
    top: 50%;
    cursor: ew-resize;
}

.vdr-stick.not-resizable {
    display: none;
}

.content-container {
    display: block;
    position: relative;
}
</style>
