import buttonToolbar from './template/buttonToolbar.vue';
import Vue from 'vue';
import Base from './base.js';
import Gikam from 'gikam';

let defaultOptions = {
    renderTo: void 0,
    $root: void 0,
    position: 'top'
};

export default class BtnToolbar extends Base {
    constructor(param) {
        super();
        this.$lastChild = true;
        this.initialize(param, defaultOptions).init();
    }

    createModel() {
        let _this = this;
        let container = null;
        if (this.options.$root && this.options.$root.classList.contains('body')) {
            const window = this.options.$window;
            window && window.showHeader();
            container = window.$toolbar;
        } else {
            container = this.options.renderTo;
        }
        return new Vue({
            el: Gikam.createDom('div', container),
            components: { buttonToolbar },
            data() {
                return {
                    options: _this.options
                };
            },
            render() {
                return <buttonToolbar options={_this.options} window={_this.options.$window}></buttonToolbar>;
            }
        });
    }

    onResize() {
        if (this.model && this.model.$children) {
            this.model.$children[0].onResize();
        }
    }

    init() {
        this.model = this.createModel();
    }
}
