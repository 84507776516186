import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    options: Object,
    propValue: String,
    rowIndex: Number,
    propReadonly: Boolean,
    beforeChange: Function
  },
  data: function data() {
    return {
      validateResult: true,
      readonly: this.propReadonly || this.options.readonly,
      field: this.options.field,
      value: this.propValue
    };
  },
  computed: {
    checkboxClass: function checkboxClass() {
      var classList = [{
        checked: "".concat(this.value) === '1'
      }];

      if ("".concat(this.value) === '2') {
        classList.push('half-checked');
      }

      return classList;
    }
  },
  watch: {
    value: function value(cal, oldVal) {
      this.$emit('dataChange', this.options.field, "".concat(this.value), this.rowIndex, "".concat(oldVal));
    },
    propValue: function propValue(val) {
      this.value = "".concat(val);
    }
  },
  methods: {
    clickHandle: function clickHandle() {
      var _this$beforeChange;

      if (((_this$beforeChange = this.beforeChange) === null || _this$beforeChange === void 0 ? void 0 : _this$beforeChange.call()) === false) {
        return;
      }

      var oldValue = "".concat(this.value);
      this.value = "".concat(this.value) === '1' ? '0' : '1';
      this.$emit('change', this.options.field, "".concat(this.value), this.rowIndex, "".concat(oldValue));
    },
    styleFormatter: function styleFormatter() {
      if (this.options.styleFormatter) {
        return this.options.styleFormatter();
      }
    }
  }
};