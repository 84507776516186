<template>
    <div :class="rootClass" v-show="show">
        <a-progress :percent="percent" :show-info="false" size="small" />
    </div>
</template>

<script>
import Vue from 'vue';
import { Progress } from 'ant-design-vue';
import { mapState } from 'vuex';

Vue.use(Progress);

const initStep = 5;

const range = {
    0: 6,
    10: 5,
    20: 4,
    30: 3,
    40: 2,
    50: 1,
    60: 0.5,
    70: 0.1,
    80: 0.05,
    90: 0.01
};

export default {
    data() {
        return {
            rootClass: 'grid-head-progress',
            show: true,
            percent: 0,
            frequency: 30,
            step: initStep,
            timeout: null
        };
    },

    computed: {
        ...mapState(['requireState'])
    },

    watch: {
        requireState(value) {
            if (value) {
                this.show = true;
                this.percentHandle();
            } else {
                this.percent = 100;
                setTimeout(() => {
                    this.percent = 0;
                    this.show = false;
                    clearTimeout(this.timeout);
                }, 500);
            }
        }
    },

    beforeDestroy() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    },

    methods: {
        percentHandle() {
            this.percent += this.step;
            this.step = range[parseInt(this.percent / 10) * 10];
            if (this.percent > 95) {
                this.step = 0.001;
            }
            if (this.percent > 98) {
                this.step = 0.0001;
            }
            if (this.percent > 100) {
                this.percent = 100;
            }
            if (this.requireState) {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                }
                this.timeout = setTimeout(() => this.percentHandle(), this.frequency);
            }
        }
    }
};
</script>

<style lang="scss">
$rootClass: 'grid-head-progress';
$height: 2px;

.#{$rootClass} {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    bottom: $height;
    height: $height;
    display: flex;
    align-items: center;

    > .ant-progress {
        div {
            height: $height !important;
            line-height: $height;
        }
    }
}
</style>
