import _defineProperty from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _objectSpread from "D:/workspace/js/hotfix/sunway-web-ui/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Gikam from 'gikam';
export default {
  props: {},
  data: function data() {
    return {
      formItems: []
    };
  },
  computed: _objectSpread({}, mapGetters(['toolbarFormList'])),
  created: function created() {
    this.formItems = Gikam.deepExtend(this.toolbarFormList);
  },
  methods: {
    changeHandler: function changeHandler(field, val) {
      var fieldOptions = Gikam.deepExtend(this.toolbarFormList).filter(function (item) {
        return item.field === field;
      })[0] || {};

      if (fieldOptions.refreshGrid !== false) {
        this.$store.commit('refreshGrid', _defineProperty({}, field, val));
      }

      fieldOptions.onChange.apply(fieldOptions, arguments);
    }
  }
};