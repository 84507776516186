<template>
    <a-select
        class="sunway-select"
        show-search
        :value="value"
        :placeholder="options.placeholder"
        :filter-option="false"
        :not-found-content="fetching ? undefined : null"
        :show-arrow="false"
        :disabled="readonly"
        @search="searchHandle"
        @change="changeHandle"
    >
        <a-spin v-if="fetching" slot="notFoundContent" size="small" />
        <a-select-option v-for="item in items" :key="item.value">
            {{ item.text }}
        </a-select-option>
    </a-select>
</template>
<script>
import { Select } from 'ant-design-vue';
import Vue from 'vue';
import Gikam from 'gikam';

Vue.use(Select);

export default {
    props: {
        options: Object,
        scope: Object,
        propValue: [String, Number],
        propReadonly: {
            type: Boolean,
            default: void 0
        }
    },

    data() {
        this.lastFetchId = 0;
        this.searchHandle = Gikam.debounce(this.searchHandle, 800);
        return {
            items: [],
            value: [],
            fetching: false,
            readonly: Gikam.isNotEmpty(this.propReadonly) ? this.propReadonly : this.options.readonly
        };
    },

    watch: {
        propValue: {
            immediate: true,
            handler(value) {
                this.value = value;
            }
        },

        propReadonly: {
            immediate: true,
            handler(value) {
                this.readonly = value;
            }
        }
    },

    methods: {
        searchHandle(value) {
            if (!this.options.onSearch) {
                Gikam.error('not found onSearch event');
                return;
            }
            this.lastFetchId += 1;
            const fetchId = this.lastFetchId;
            this.items = [];
            this.fetching = true;
            const defer = this.options.onSearch.call(this.scope, value);
            defer.done(itemList => {
                if (fetchId !== this.lastFetchId) {
                    return;
                }
                this.items = itemList;
                if (Gikam.isEmpty(this.items)) {
                    this.items = [{ text: value, value }];
                }
                this.fetching = false;
            });
        },

        changeHandle(value) {
            const item = this.items.find(item => item.value === value);
            Object.assign(this, {
                value,
                items: [],
                fetching: false
            });
            this.$emit('change', this.options.field, value, Gikam.deepExtend(item));
        }
    }
};
</script>
